import { SELECTED_RATE_NAME, SELECTED_SERVICE_NAME, SELECTED_VENDOR_JOB_NAME } from "./BreadcrumsActionTypes"

export const selectedRateName = (name:string) => {
    return {
        type: SELECTED_RATE_NAME,
        payload: name
    }
}

export const selectedServiceName = (name:string) => {
    return {
        type: SELECTED_SERVICE_NAME,
        payload: name
    }
}

export const selectedVendorJobName = (name:string) => {
    return {
        type: SELECTED_VENDOR_JOB_NAME,
        payload: name
    }
}
