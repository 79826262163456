import React from 'react';

export function MInvoicePending() {
    return <React.Fragment>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.2992 9H11V11.4702L12.365 12.6496L11 13.829V16.2992H16.2992V13.829L14.9342 12.6496L16.2992 11.4702V9ZM11.3465 11.3233V9.33265H15.9536V9.33178H11.3456V11.3225L11.3465 11.3233ZM12.8816 12.6497L11.3456 13.9767V15.9674H11.3465V13.9776L12.8825 12.6505L12.8816 12.6497ZM15.9536 13.9768V13.9767L14.4186 12.6504L14.4185 12.6505L15.9536 13.9768ZM12.1145 15.3039H15.1856V14.1427L15.1865 14.1435V15.3047H12.1145V15.3039ZM12.4592 14.2904L13.6496 13.2619L14.84 14.2904V14.9721H12.4592V14.2904ZM15.1856 9.99621V11.1566L13.6496 12.4837L12.1145 11.1574V11.1575L13.6505 12.4846L15.1865 11.1575V9.99621H15.1856ZM14.84 11.0088L13.6496 12.0374L12.4592 11.0088V10.3271H14.84V11.0088Z" fill="#A0A5B9" />
            <path d="M14.625 1.5H3.375C2.325 1.5 1.5 2.325 1.5 3.375V14.625C1.5 15.675 2.325 16.5 3.375 16.5H9V15H3.375C3.15 15 3 14.85 3 14.625V3.375C3 3.15 3.15 3 3.375 3H14.625C14.85 3 15 3.15 15 3.375V7H16.5V3.375C16.5 2.325 15.675 1.5 14.625 1.5Z" fill="#A0A5B9" />
            <path d="M12 4.875H4.5V6.375H12V4.875Z" fill="#A0A5B9" />
            <path d="M9 7.875H4.5V9.375H9V7.875Z" fill="#A0A5B9" />
        </svg>


    </React.Fragment>;
}


