
import { put, takeEvery } from "redux-saga/effects";

import { gql } from 'apollo-boost';
import { client } from "src/app/hooks";
import { GET_RELEASE_NOTES_FETCH } from './ReleaseNotesActionType'

const query = gql`
query getAllReleaseNotes($page: Int) {
    getAllReleaseNotes(page: $page) {
      items {
        featureName
        featureType
        featureDescription
        createdAt
      }
      total
    }
  }
  
    `;

function* getReleaseNotesFetch(variables: any): any {
    try {


        const releaseNotes = yield client.query({ query, variables, fetchPolicy: "no-cache" });
        yield put({ type: 'GET_RELEASE_NOTES_SUCCESS', response: releaseNotes.data.getAllReleaseNotes });

    } catch (error) {
        console.log('error', error);
        // yield put({ type: 'API_CALL_FAILURE', error });
    }
}

export default function* releaseNotesSaga() {
    yield takeEvery(GET_RELEASE_NOTES_FETCH, getReleaseNotesFetch);

}