import React, { Fragment } from 'react'

export function MFileDrive() {
    return (
        <Fragment>


            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_45_5700)">
                    <path d="M1.29492 17.9808C1.96276 17.6065 2.36318 17.0171 2.55952 16.1521C3.22244 13.2299 3.90506 10.3157 4.57853 7.39792C4.84946 6.22442 5.40118 5.69456 6.37585 5.69456C9.82202 5.69189 13.2689 5.71945 16.715 5.67589C17.6088 5.66433 18.2154 6.57469 17.9297 7.7482C17.2471 10.5548 16.62 13.3828 15.9677 16.201C15.6989 17.3638 15.062 17.9942 14.119 17.9959C9.91069 18.0022 5.70238 17.9995 1.49408 17.9986C1.42793 17.9986 1.36178 17.987 1.29492 17.9808Z" fill="#A0A5B9" />
                    <path d="M15.7386 4.73329C15.5774 4.73329 15.4381 4.73329 15.2988 4.73329C12.3325 4.73329 9.36632 4.7324 6.3994 4.73329C5.09328 4.73329 4.2291 5.54941 3.86386 7.12653C3.18476 10.0585 2.50566 12.9905 1.83008 15.9234C1.69496 16.5119 1.42895 16.9138 0.933529 17.0338C0.431769 17.1556 0.0186801 16.752 0.0053092 16.1021C-0.00524674 15.5838 0.00319801 15.0655 0.00319801 14.5472C0.00319801 10.3999 0.00319801 6.25262 0.00319801 2.10534C0.00390174 0.762033 0.589405 0.00992099 1.64711 0.00636491C2.9842 0.00103078 4.32058 0.0161441 5.65696 0.000141764C6.34028 -0.00785942 6.86597 0.322856 7.30228 0.986066C8.36703 2.60497 7.88005 2.33471 9.57463 2.34982C11.0757 2.36316 12.576 2.34982 14.0771 2.35338C15.2221 2.35694 15.8512 3.23884 15.7386 4.73329Z" fill="#A0A5B9" />
                </g>
                <defs>
                    <clipPath id="clip0_45_5700">
                        <rect width="18" height="18" fill="white" />
                    </clipPath>
                </defs>
            </svg>

        </Fragment>
    )
}
