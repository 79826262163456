import React from 'react'
import InputBase from '@mui/material/InputBase';

export function MInputBase(props: any) {
    return (
        <InputBase {...props}
        // placeholder={props.placeholder as string}
        />
    )
}
