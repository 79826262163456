import React from 'react';
import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';
import { MBox } from 'src/app/material-ui';

const useStyles = makeStyles(() =>
  createStyles({
    errorMsg:{
        fontSize: 12,
        color:'#f44336',
        left: 0,
        margin: 0,
        // bottom: '-17px',
        // position: 'absolute'
    },
  }));

export function FormErrorMessage (props:any){
    const classes  = useStyles();

    return (
        <MBox  className={classes.errorMsg} component="span" {...props}>{props.message}</MBox>
    )
}