import React, { Fragment } from 'react'

export function MCreditCard() {
    return (
        <Fragment>

            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3721 32.4531L3.77017 20.6644C2.79586 19.0053 3.30568 16.8555 4.91442 15.8391L20.9452 5.79123C22.554 4.78645 24.6385 5.31221 25.6242 6.95959L29.7933 14.0398L31.1301 16.505" fill="#F43735" />
                <path d="M33.3172 34.7078H14.5447C12.6641 34.7078 11.146 33.1422 11.146 31.2028V20.0099C11.146 18.0705 12.6641 16.5049 14.5447 16.5049H33.3172C35.1978 16.5049 36.716 18.0705 36.716 20.0099V31.2028C36.716 33.1422 35.1978 34.7078 33.3172 34.7078Z" fill="#F9F9F9" />
                <path d="M36.716 20.5125H11.146V24.1694H36.716V20.5125Z" fill="#383838" />
                <path d="M34.3706 29.2282H31.4024C31.2438 29.2282 31.1191 29.0997 31.1191 28.9361C31.1191 28.7726 31.2438 28.644 31.4024 28.644H34.3706C34.5292 28.644 34.6538 28.7726 34.6538 28.9361C34.6538 29.0997 34.5292 29.2282 34.3706 29.2282Z" fill="#303030" />
                <path d="M34.3706 30.5368H31.4024C31.2438 30.5368 31.1191 30.4083 31.1191 30.2447C31.1191 30.0812 31.2438 29.9526 31.4024 29.9526H34.3706C34.5292 29.9526 34.6538 30.0812 34.6538 30.2447C34.6538 30.4083 34.5292 30.5368 34.3706 30.5368Z" fill="#303030" />
                <path d="M34.3706 31.9038H31.4024C31.2438 31.9038 31.1191 31.7752 31.1191 31.6117C31.1191 31.4481 31.2438 31.3196 31.4024 31.3196H34.3706C34.5292 31.3196 34.6538 31.4481 34.6538 31.6117C34.6538 31.7752 34.5292 31.9038 34.3706 31.9038Z" fill="#303030" />
                <path d="M21.9079 26.0034C20.673 26.0034 19.6081 26.7745 19.1436 27.8728C18.6791 26.7745 17.6142 26.0034 16.3793 26.0034C14.7139 26.0034 13.3657 27.3938 13.3657 29.1112C13.3657 30.8287 14.7139 32.2191 16.3793 32.2191C17.6142 32.2191 18.6791 31.4479 19.1436 30.3497C19.6081 31.4479 20.673 32.2191 21.9079 32.2191C23.5733 32.2191 24.9215 30.8287 24.9215 29.1112C24.9215 27.3938 23.5733 26.0034 21.9079 26.0034Z" fill="#FFC601" />
                <path d="M33.3167 35H14.5443C12.5164 35 10.8623 33.2942 10.8623 31.2029V20.01C10.8623 17.9187 12.5164 16.2129 14.5443 16.2129H33.3167C35.3447 16.2129 36.9987 17.9187 36.9987 20.01V31.2029C36.9987 33.2942 35.3447 35 33.3167 35ZM14.5443 16.7971C12.8223 16.7971 11.4288 18.2341 11.4288 20.01V31.2029C11.4288 32.9788 12.8223 34.4158 14.5443 34.4158H33.3167C35.0388 34.4158 36.4323 32.9788 36.4323 31.2029V20.01C36.4323 18.2341 35.0388 16.7971 33.3167 16.7971H14.5443Z" fill="#303030" />
                <path d="M36.7155 20.8044H11.1455C10.9869 20.8044 10.8623 20.6759 10.8623 20.5123C10.8623 20.3487 10.9869 20.2202 11.1455 20.2202H36.7155C36.8741 20.2202 36.9987 20.3487 36.9987 20.5123C36.9987 20.6759 36.8741 20.8044 36.7155 20.8044Z" fill="#303030" />
                <path d="M36.7155 24.4614H11.1455C10.9869 24.4614 10.8623 24.3329 10.8623 24.1693C10.8623 24.0057 10.9869 23.8772 11.1455 23.8772H36.7155C36.8741 23.8772 36.9987 24.0057 36.9987 24.1693C36.9987 24.3329 36.8741 24.4614 36.7155 24.4614Z" fill="#303030" />
                <path d="M12.2228 33.7623L12.2104 33.75" stroke="#303030" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11.3726 32.745C11.2819 32.745 11.1913 32.6982 11.1346 32.6165L3.53276 20.8278C2.47915 19.0285 3.03428 16.6918 4.76764 15.5936L20.7984 5.54572C22.5318 4.47083 24.7976 5.03164 25.8626 6.80754L30.0317 13.8878L31.3686 16.3647C31.4479 16.5049 31.3912 16.6801 31.2553 16.7619C31.1193 16.8437 30.9494 16.797 30.8701 16.6451L29.5332 14.1798L25.3641 7.11131C24.4691 5.60414 22.5431 5.12511 21.0703 6.03643L5.0622 16.0959C3.60074 17.0189 3.12491 19.0051 4.00859 20.524L11.5991 32.301C11.6898 32.4412 11.6558 32.6165 11.5198 32.7099C11.4745 32.745 11.4179 32.7567 11.3612 32.7567L11.3726 32.745Z" fill="#303030" />
                <path d="M5.85505 21.5754C5.77575 21.5754 5.70777 21.5403 5.65113 21.4819C5.60581 21.4235 4.42757 20.0915 4.5522 18.6545C4.60884 17.9768 4.93739 17.4043 5.51518 16.937L12.0861 12.801C12.222 12.7192 12.392 12.7543 12.4713 12.8945C12.5506 13.0347 12.5053 13.2099 12.3807 13.2917L5.83239 17.416C5.39055 17.7665 5.15264 18.1871 5.10732 18.7012C5.00536 19.8812 6.04765 21.073 6.05898 21.0846C6.16094 21.2015 6.16094 21.3884 6.03632 21.4936C5.97967 21.5403 5.9117 21.5754 5.84372 21.5754H5.85505Z" fill="#303030" />
                <path d="M13.6951 12.474C13.6045 12.474 13.5251 12.4273 13.4685 12.3572C13.3778 12.2287 13.4118 12.0417 13.5365 11.9483L14.2165 11.4459C14.3411 11.3524 14.5225 11.3875 14.6131 11.516C14.7038 11.6445 14.6698 11.8314 14.5451 11.9249L13.8651 12.4273C13.8651 12.4273 13.7631 12.4857 13.6951 12.4857V12.474Z" fill="#303030" />
                <path d="M29.3296 16.7967C29.239 16.7967 29.137 16.75 29.0916 16.6565L27.2443 13.5838C27.165 13.4436 27.199 13.2683 27.335 13.1865C27.471 13.1047 27.641 13.1398 27.7203 13.28L29.5676 16.3528C29.647 16.493 29.613 16.6682 29.477 16.75C29.4316 16.785 29.375 16.7967 29.3296 16.7967Z" fill="#303030" />
                <path d="M16.368 32.2077C18.026 32.2077 19.3702 30.8215 19.3702 29.1115C19.3702 27.4016 18.026 26.0154 16.368 26.0154C14.7099 26.0154 13.3657 27.4016 13.3657 29.1115C13.3657 30.8215 14.7099 32.2077 16.368 32.2077Z" fill="#F43735" />
            </svg>



        </Fragment>
    )
}
