import React from 'react'
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

import Avatar from '@mui/material/Avatar';


const useStyles = makeStyles(() =>
  createStyles({

    avatar: {
      textTransform: 'uppercase',
      color: '#3a3a3a',
      backgroundColor: '#ebf1fd',
      '&.MuiAvatar-root': {
        fontSize: 14,
      },
    },
  }));

export function MAvatar(props: any) {
  const  classes  = useStyles();

  return (
    <Avatar {...props} className={`${classes.avatar} ${props.className ? props.className : ''}`} />
  )

}