import React, { Fragment } from 'react'

export function MPaypal() {
    return (
        <Fragment>

            <svg width="85" height="28" viewBox="0 0 85 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M11.0712 6.23608C13.8376 6.23608 16.3903 7.68144 16.0411 11.2836C15.6177 15.5647 13.2365 17.9355 9.47942 17.9457H6.1986C5.72658 17.9457 5.5001 18.3147 5.37521 19.0771L4.74022 22.9647C4.64497 23.5518 4.3317 23.8413 3.86815 23.8413H0.815923C0.326974 23.8413 0.157639 23.5417 0.265589 22.871L2.79077 7.28799C2.91565 6.52149 3.2141 6.23608 3.7602 6.23608H11.0712ZM6.09488 14.578H8.58408C10.1377 14.5229 11.1728 13.4853 11.2765 11.6118C11.34 10.456 10.5293 9.63033 9.24024 9.63645H6.8971L6.09488 14.5678V14.578ZM24.3426 22.6467C24.622 22.4041 24.9078 22.2757 24.8654 22.5774L24.766 23.2991C24.7152 23.6762 24.8697 23.876 25.2359 23.876H27.9473C28.4045 23.876 28.6268 23.6986 28.7411 23.0177L30.4048 12.9104C30.4873 12.4028 30.3603 12.1541 29.9603 12.1541H26.9758C26.7069 12.1541 26.5778 12.2988 26.5059 12.6943L26.3958 13.3059C26.3386 13.6321 26.1841 13.6871 26.0402 13.3609C25.5343 12.2071 24.2432 11.6893 22.4419 11.7301C18.2551 11.8239 15.4336 14.8817 15.133 18.7999C14.8981 21.8354 17.1566 24.2164 20.1326 24.2164C22.2895 24.2164 23.2568 23.6048 24.3426 22.6467ZM22.0736 21.0933C20.2702 21.0933 19.015 19.7111 19.2775 18.013C19.5399 16.3149 21.2227 14.9327 23.024 14.9327C24.8252 14.9327 26.0825 16.3087 25.8201 18.013C25.5576 19.7173 23.8749 21.0933 22.0736 21.0933ZM35.7261 12.1235H32.985C32.4156 12.1235 32.1849 12.5312 32.3669 13.0327L35.7832 22.6671L32.4156 27.2478C32.1362 27.6311 32.3521 27.9817 32.7479 27.9817H35.8425C36.0229 28.0016 36.2055 27.971 36.3684 27.8936C36.5313 27.8163 36.6676 27.6954 36.7611 27.5454L47.2661 13.0307C47.59 12.5842 47.4375 12.1154 46.9084 12.1154H43.9832C43.4815 12.1154 43.2804 12.307 42.9926 12.7086L38.6089 18.8244L36.6595 12.6903C36.5431 12.3172 36.2531 12.1215 35.7261 12.1215V12.1235Z" fill="#113984" />
                <path fillRule="evenodd" clipRule="evenodd" d="M58.2009 6.23608C60.9695 6.23608 63.5222 7.68144 63.1729 11.2836C62.7496 15.5647 60.3684 17.9355 56.6113 17.9457H53.3283C52.8563 17.9457 52.6277 18.3147 52.505 19.0771L51.87 22.9647C51.7747 23.5518 51.4614 23.8413 50.9979 23.8413H47.9457C47.4588 23.8413 47.2895 23.5417 47.3974 22.871L49.9226 7.28799C50.0475 6.52149 50.346 6.23608 50.8899 6.23608H58.2009ZM53.2267 14.578H55.7138C57.2696 14.5229 58.3046 13.4853 58.4083 11.6118C58.4718 10.456 57.6611 9.63033 56.3721 9.63645H54.0289L53.2267 14.5678V14.578ZM71.4681 22.6467C71.7475 22.4041 72.0312 22.2757 71.991 22.5774L71.8915 23.2991C71.8407 23.6762 71.9952 23.876 72.3593 23.876H75.0728C75.53 23.876 75.7523 23.6986 75.8645 23.0177L77.5366 12.9104C77.6213 12.4028 77.4943 12.1541 77.0921 12.1541H74.1161C73.8473 12.1541 73.716 12.2988 73.6462 12.6943L73.5361 13.3059C73.479 13.6321 73.3244 13.6871 73.1805 13.3609C72.6746 12.2071 71.3835 11.6893 69.5822 11.7301C65.387 11.8239 62.5655 14.8817 62.2628 18.7999C62.0299 21.8354 64.2884 24.2164 67.2623 24.2164C69.4213 24.2164 70.3865 23.6048 71.4745 22.6467H71.4681ZM69.197 21.0933C67.3957 21.0933 66.1405 19.7111 66.403 18.013C66.6654 16.3149 68.3482 14.9327 70.1495 14.9327C71.9507 14.9327 73.2123 16.3087 72.9498 18.013C72.6873 19.7173 71.0046 21.0933 69.2033 21.0933H69.197ZM81.7128 23.8943H78.5886C78.5339 23.897 78.4793 23.8879 78.4287 23.8679C78.3781 23.8478 78.3328 23.8171 78.296 23.7781C78.2591 23.7392 78.2317 23.6928 78.2157 23.6424C78.1997 23.592 78.1955 23.5388 78.2034 23.4866L80.955 6.6968C80.9792 6.58034 81.044 6.47535 81.1388 6.39932C81.2335 6.32328 81.3524 6.28078 81.4757 6.27889H84.6063C84.6619 6.27632 84.7173 6.28578 84.7687 6.30658C84.82 6.32738 84.8658 6.35898 84.9027 6.39907C84.9397 6.43916 84.9668 6.48671 84.9822 6.53823C84.9976 6.58976 85.0007 6.64394 84.9915 6.6968L82.2398 23.4866C82.2139 23.6014 82.1483 23.7042 82.0536 23.7783C81.959 23.8524 81.8411 23.8933 81.7191 23.8943H81.7128Z" fill="#009EE3" />
            </svg>

        </Fragment>
    )
}
