import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import React from 'react';
import { FormattedMessage } from "react-intl";
import { useDispatch } from 'react-redux';
import { MBox, MButton } from 'src/app/material-ui';
import { showComingSoonModal } from 'src/app/store';
import useStyles from './ComingSoonModalStyle';


export default function ComingSoonModal(props: any) {
    const classes = useStyles({});
    const dispatch = useDispatch();

    return (
        <div className={classes.filesWrapper}>
            <Dialog className={classes.dialogWrapper} open={props.modalOpen} onClose={() => dispatch(showComingSoonModal(false))}>
                <DialogContent className={classes.dialogContent}>
                    <MBox p={3} textAlign="center" height="100%">
                        <MBox fontSize={30} fontFamily="Poppins" textAlign="center">
                            <FormattedMessage id="coming.soon" />
                        </MBox>
                        <MBox mt={1.5} mb={3} fontSize={16} color="#637381">
                            <FormattedMessage id="working.hard.this.page" />
                        </MBox>
                    </MBox>
                </DialogContent>
                <DialogActions className={classes.dialogBtn}>
                    <MBox pt={1} pb={1} pr={2}>
                        <MButton variant="contained" color="primary" onClick={() => dispatch(showComingSoonModal(false))}>
                            <FormattedMessage id="close" />
                        </MButton>
                    </MBox>
                </DialogActions>
            </Dialog>
        </div>
    )
}
