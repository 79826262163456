import { SET_AUTH, SET_CHAT_NOTIFICATION, SET_CURRENT_USER_DATA } from "./AuthActionTypes";
import { RESET_STORE } from '../resetStateAction';

interface IState {
    isLogin: boolean,
    token: string,
    currentUser: {
        id: number,
        username: string,
        selectedOrgId: number,
        firstName: string,
        lastName: string,
        profilePicture: string,
        lang: any,
        defaultRole: string,
        isProfile: number,
        isPrivate: boolean,
        isChatNotification: boolean,
        city: any,
        state: any
        country: any,
        postalCode: any,
        address: any,
        timeZone: any,
        isEmailEnable: any,
        rates: any,
        slug: string,
        isBuyPackageFlag: boolean
    }
}

interface IAction {
    type: string,
    payload: string
}

const initialState: IState = {
    isLogin: false,
    token: '',
    currentUser: {
        id: 0,
        username: '',
        selectedOrgId: 0,
        firstName: '',
        lastName: '',
        profilePicture: '',
        lang: null,
        defaultRole: '',
        isProfile: 0,
        isPrivate: false,
        isChatNotification: false,
        city: null,
        state: null,
        country: null,
        postalCode: '',
        address: '',
        timeZone: '',
        isEmailEnable: null,
        rates: null,
        slug: '',
        isBuyPackageFlag: false
    }
}

const authReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case SET_AUTH:
            return {
                ...state,
                isLogin: action.payload ? true : false,
                token: action.payload
            }
        case SET_CURRENT_USER_DATA:
            return {
                ...state,
                currentUser: action.payload
            }
        case SET_CHAT_NOTIFICATION:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    isChatNotification: false
                }
            }
        case RESET_STORE:
            return initialState
        default:
            return state
    }
}

export default authReducer;