import React, { Fragment } from 'react'

export function MMasterCard() {
    return (
        <Fragment>

            <svg width="60" height="36" viewBox="0 0 60 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_6477_25115)">
                    <path d="M24.0537 18C24.0537 8.05953 32.1014 0 42.0273 0C51.9531 0 60.0008 8.05953 60.0008 18C60.0008 27.9405 51.9531 36 42.0273 36C32.1014 36 24.0537 27.9405 24.0537 18Z" fill="#F79F14" />
                    <path d="M29.9825 4.59405C29.9825 4.59405 29.0377 5.41482 28.4116 6.22419H31.6102C31.6102 6.22419 32.703 7.40975 33.1128 8.29892H26.8636C26.8636 8.29892 26.2603 9.25649 25.8505 10.0545H34.1144C34.1144 10.0545 34.8657 11.468 35.0365 12.2204H24.9854C24.9854 12.2204 24.6439 13.2008 24.4959 13.9189H35.5032C35.8105 15.2869 36.539 19.311 35.0251 23.7568H25.0309C25.1903 24.304 25.4635 25.0222 25.7253 25.5579H34.308C33.9665 26.2761 33.4542 27.1881 33.1697 27.6441H26.8749C27.205 28.1571 27.74 28.9436 28.0815 29.3084H31.9517C31.2232 30.3116 29.9939 31.349 29.9939 31.349C26.8067 34.2331 22.595 35.9772 17.9735 35.9772C8.04768 35.9772 0 27.9177 0 17.9772C0 8.03673 8.05907 0 17.9849 0C22.595 0 26.8067 1.74414 29.9825 4.60545V4.59405Z" fill="#EC0014" />
                    <path d="M21.1266 15.4349L20.8421 17.0537L19.2712 17.0081C19.2712 17.0081 18.4858 17.2133 18.4858 17.5439C18.4858 17.8744 19.0663 18.2962 19.9428 18.6724C20.8193 19.0486 20.8535 20.086 20.7738 20.7927C20.6941 21.4995 20.5689 22.9587 18.0305 23.0385C16.5849 23.0841 15.7881 22.7877 15.7881 22.7877L16.1182 21.1233C16.1182 21.1233 17.985 21.6249 18.3948 21.3285C18.8045 21.0435 19.3054 20.542 18.52 20.2114C17.7346 19.8808 16.4483 19.345 16.4483 18.0112C16.4483 16.6775 16.9833 15.9365 17.8142 15.5603C18.6452 15.1841 20.0908 15.2297 21.1266 15.4349Z" fill="#1E306D" />
                    <path d="M8.66314 22.8448H6.8305L7.90049 16.5408L5.6353 22.8106H4.36042L4.06447 16.2901L2.94895 22.8448H1.21875L2.85788 13.2122H4.95233L5.69222 18.7638L8.13953 13.2122H10.3023L8.66314 22.8448Z" fill="#1E306D" />
                    <path d="M22.7664 22.9815C22.1745 22.9815 21.7419 22.8447 21.4915 22.5939C21.2411 22.3203 21.1045 21.9213 21.1045 21.3969C21.1045 21.2601 21.1159 21.1233 21.1386 21.0093C21.15 20.8725 21.1842 20.7243 21.1956 20.5419L22.2997 14.3748H24.0185L23.7681 15.5831H25.3731L25.0999 17.1107H23.4949L23.051 19.7326C23.0282 19.9036 22.994 20.0746 22.9599 20.2684C22.9371 20.4394 22.9144 20.6103 22.9144 20.7357C22.9144 21.0093 22.9599 21.1803 23.0737 21.2715C23.1762 21.3627 23.3469 21.4311 23.5746 21.4311C23.6656 21.4311 23.7681 21.4083 23.9161 21.3741C24.0527 21.3171 24.1892 21.2829 24.2689 21.2145H24.4169L24.1437 22.7535C23.9502 22.8333 23.7453 22.8675 23.529 22.9017C23.3128 22.9473 23.0623 22.9587 22.7664 22.9587V22.9815Z" fill="#1E306D" />
                    <path d="M35.0941 17.6238C34.7184 17.3388 33.8875 17.3958 33.7054 17.9886L32.8858 22.8562H31.167L32.4077 15.6061H34.1265L34.0924 16.1874C34.4225 15.8569 34.7867 15.5833 35.7088 15.6061C35.7657 15.6061 35.151 16.689 35.1055 17.6238H35.0941Z" fill="#1E306D" />
                    <path d="M38.3381 23.0386C37.3933 23.0386 36.6307 22.708 36.1184 22.0696C35.5948 21.4312 35.333 20.5079 35.333 19.3109C35.333 18.3647 35.4468 17.5098 35.6745 16.746C35.9022 15.9708 36.2323 15.321 36.6307 14.7625C37.0291 14.2267 37.4958 13.7935 38.0535 13.4857C38.5885 13.1779 39.1804 13.0183 39.7837 13.0183C40.2618 13.0183 40.7171 13.0981 41.1497 13.2349C41.3545 13.3033 41.7529 13.4971 41.7529 13.4971L41.3545 15.7884C41.161 15.5376 40.9561 15.3324 40.7513 15.1956C40.4553 15.0018 40.1024 14.8878 39.704 14.8878C38.9983 14.8878 38.4064 15.2754 37.9511 16.0392C37.4844 16.8144 37.2567 17.7948 37.2567 18.9917C37.2567 19.7669 37.3819 20.3255 37.6437 20.6675C37.8942 20.998 38.2698 21.169 38.7479 21.169C39.1918 21.169 39.6016 21.055 39.9886 20.8157C40.1707 20.7017 40.3529 20.5763 40.5236 20.4281L40.1252 22.6168C40.1252 22.6168 39.9203 22.7194 39.8179 22.7536C39.5788 22.8448 39.3625 22.9018 39.1804 22.9702C38.9755 23.0044 38.7023 23.05 38.3381 23.05V23.0386Z" fill="#1E306D" />
                    <path d="M12.8975 22.5939C12.8292 22.6509 12.7609 22.6851 12.704 22.7307C12.5218 22.8333 12.3625 22.9017 12.2031 22.9587C12.0438 22.9929 11.8275 23.0385 11.5543 23.0385C11.1104 23.0385 10.7461 22.8675 10.4729 22.5369C10.1884 22.1949 10.0518 21.7503 10.0518 21.2031C10.0518 20.6218 10.1542 20.143 10.3477 19.7326C10.5412 19.345 10.8486 19.0144 11.247 18.7864C11.6226 18.5584 12.0551 18.3988 12.556 18.3076L12.9089 18.2506C13.3186 18.1708 13.7512 18.1138 14.2179 18.1024C14.2179 18.0682 14.2179 18.0226 14.2293 17.9656C14.2407 17.9086 14.2407 17.8516 14.2407 17.7718C14.2407 17.4641 14.1382 17.2475 13.9333 17.1107C13.717 16.9967 13.4097 16.9397 12.9999 16.9397H12.9089C12.6584 16.9625 12.3739 17.0195 12.0551 17.1335C11.7137 17.2703 11.4632 17.3615 11.2925 17.4641H11.1331L11.3836 15.7427C11.5771 15.6629 11.8958 15.5945 12.3056 15.5147C12.5105 15.4577 12.704 15.4349 12.8975 15.4121C13.1137 15.3779 13.3414 15.3779 13.5463 15.3779C14.3886 15.3779 14.9919 15.5261 15.3903 15.8225C15.7887 16.1075 15.9708 16.5749 15.9708 17.2133C15.9708 17.2931 15.9708 17.4071 15.9595 17.5439C15.9481 17.6807 15.9367 17.7946 15.9139 17.9086L15.0716 22.8447H13.3642L13.4894 22.0695C13.3869 22.1607 13.2503 22.2861 13.1024 22.4229C13.0227 22.4799 12.943 22.5369 12.8747 22.5939H12.8975ZM12.8975 19.5844C12.7153 19.6186 12.556 19.6756 12.4308 19.7326C12.2373 19.8238 12.0893 19.9606 11.9868 20.143C11.873 20.314 11.8275 20.5534 11.8275 20.8383C11.8275 21.0891 11.8958 21.2601 12.0324 21.3627C12.169 21.4653 12.3625 21.5223 12.6243 21.5223C12.7153 21.5223 12.795 21.4995 12.8975 21.4653C12.9885 21.4425 13.0682 21.4083 13.1707 21.3627C13.3528 21.2487 13.5349 21.1119 13.7057 20.9523L13.9675 19.4134C13.6715 19.4476 13.3756 19.4932 13.1137 19.5502C13.0455 19.5502 12.9772 19.573 12.8975 19.5844Z" fill="#1E306D" />
                    <path d="M27.7518 16.8486C27.5242 16.8828 27.3079 16.9968 27.1371 17.1906C26.8981 17.4642 26.716 17.8061 26.5794 18.2735H28.8901C28.8901 18.1937 28.9015 18.1367 28.9015 18.0569C28.9015 17.9999 28.9129 17.9201 28.9129 17.8631C28.9129 17.5098 28.8218 17.2704 28.6738 17.0652C28.5145 16.8942 28.3096 16.7916 28.0022 16.7916C27.9112 16.7916 27.8201 16.8144 27.729 16.8258L27.7518 16.8486ZM27.7518 21.4084H28.0136C28.3779 21.4084 28.7421 21.3286 29.0836 21.1576C29.4137 20.9866 29.7097 20.7928 29.9487 20.5763H30.1422L29.8121 22.4458C29.482 22.6396 29.1291 22.7878 28.7649 22.8904C28.4575 22.9816 28.1161 23.0272 27.7518 23.05H27.5925C26.6704 23.05 25.9761 22.7764 25.4752 22.2178C24.9744 21.682 24.7354 20.9296 24.7354 19.9607C24.7354 19.2995 24.8264 18.6839 24.9744 18.1481C25.1338 17.5895 25.3728 17.1108 25.6687 16.6776C25.9647 16.2672 26.3176 15.9594 26.7615 15.7086C27.0688 15.5604 27.3989 15.435 27.7404 15.378C27.8998 15.3552 28.0478 15.3552 28.2071 15.3552C29.0039 15.3552 29.5958 15.5718 30.017 16.0164C30.4154 16.4382 30.6089 17.0994 30.6089 17.9429C30.6089 18.2279 30.5975 18.5243 30.552 18.8093C30.5065 19.0829 30.4495 19.3679 30.3813 19.6415H26.3972V19.8011C26.3972 20.3027 26.5338 20.6902 26.7957 20.9752C27.0119 21.2146 27.3193 21.3628 27.729 21.397L27.7518 21.4084Z" fill="#1E306D" />
                    <path d="M43.8581 22.5939C43.7898 22.6509 43.7101 22.6851 43.6418 22.7307C43.4711 22.8333 43.3003 22.9017 43.141 22.9587C42.9816 22.9929 42.7653 23.0385 42.5035 23.0385C42.0482 23.0385 41.6953 22.8675 41.4108 22.5369C41.1262 22.1949 41.001 21.7503 41.001 21.2031C41.001 20.6218 41.1034 20.143 41.2969 19.7326C41.4904 19.345 41.7978 19.0144 42.1734 18.7864C42.5377 18.5584 42.9816 18.3988 43.4938 18.3076C43.6077 18.2848 43.7215 18.2734 43.8467 18.2506C44.2451 18.1708 44.689 18.1138 45.133 18.1024C45.1443 18.0682 45.1443 18.0226 45.1671 17.9656C45.1671 17.9086 45.1785 17.8516 45.1785 17.7718C45.1785 17.4641 45.0647 17.2475 44.8712 17.1107C44.6549 16.9967 44.3475 16.9397 43.9264 16.9397H43.8467C43.5735 16.9625 43.2889 17.0195 42.993 17.1335C42.6515 17.2703 42.4011 17.3615 42.2303 17.4641H42.071L42.31 15.7427C42.5035 15.6629 42.8222 15.5945 43.2434 15.5147C43.4369 15.4577 43.6418 15.4349 43.8467 15.4121C44.063 15.3779 44.2679 15.3779 44.4955 15.3779C45.3378 15.3779 45.9411 15.5261 46.3395 15.8225C46.7266 16.1075 46.9201 16.5749 46.9201 17.2133C46.9201 17.2931 46.9201 17.4071 46.9087 17.5439C46.8973 17.6807 46.8859 17.7946 46.8632 17.9086L46.0208 22.8447H44.3134L44.4386 22.0695C44.3248 22.1607 44.1996 22.2861 44.0402 22.4229L43.8239 22.5939H43.8581ZM43.8581 19.5844C43.676 19.6186 43.5166 19.6756 43.3686 19.7326C43.1865 19.8238 43.0271 19.9606 42.9247 20.143C42.8222 20.314 42.7653 20.5534 42.7653 20.8383C42.7653 21.0891 42.8336 21.2601 42.9816 21.3627C43.1068 21.4653 43.3117 21.5223 43.5621 21.5223C43.6532 21.5223 43.7442 21.4995 43.8467 21.4653C43.9264 21.4425 44.0174 21.4083 44.0971 21.3627C44.2906 21.2487 44.4728 21.1119 44.6321 20.9523L44.9053 19.4134C44.598 19.4476 44.3134 19.4932 44.0402 19.5502C43.9719 19.5502 43.9036 19.573 43.8467 19.5844H43.8581Z" fill="#1E306D" />
                    <path d="M51.1097 17.6238C50.7341 17.3388 49.9031 17.3958 49.721 17.9886L48.9014 22.8562H47.1826L48.4233 15.6061H50.1422L50.108 16.1874C50.4381 15.8569 50.8024 15.5833 51.7244 15.6061C51.7813 15.6061 51.1666 16.689 51.1211 17.6238H51.1097Z" fill="#1E306D" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M54.4777 17.1336C54.3866 17.1678 54.307 17.2362 54.2387 17.2932C54.011 17.4414 53.8289 17.6808 53.6695 17.9544C53.5443 18.228 53.4305 18.5358 53.3622 18.9348C53.2711 19.3224 53.237 19.7099 53.237 20.0747C53.237 20.5193 53.328 20.8271 53.4646 21.0209C53.624 21.2261 53.8516 21.3287 54.1476 21.3287C54.2614 21.3287 54.3753 21.3059 54.4891 21.2489C54.546 21.2261 54.5915 21.2147 54.6484 21.1691C54.8192 21.0779 54.9785 20.9753 55.1265 20.8385L55.7412 17.2704C55.6388 17.2134 55.5363 17.1564 55.3769 17.1108C55.2404 17.0538 55.1038 17.031 54.9899 17.031C54.8078 17.031 54.6484 17.0652 54.4891 17.1222L54.4777 17.1336ZM54.4777 22.4572C54.3525 22.5712 54.2045 22.6852 54.0565 22.7878C53.9199 22.8676 53.772 22.9474 53.6468 22.9816C53.4988 23.0158 53.328 23.0386 53.1459 23.0386C52.6223 23.0386 52.2011 22.7992 51.8938 22.3661C51.5978 21.8987 51.4385 21.2489 51.4385 20.4167C51.4385 19.7213 51.5068 19.0602 51.6775 18.4674C51.8369 17.8746 52.0645 17.3274 52.3491 16.8714C52.6337 16.404 52.9638 16.0621 53.3394 15.7885C53.7037 15.5377 54.0793 15.4009 54.4777 15.3895H54.5346C54.8306 15.3895 55.0924 15.4237 55.2973 15.5035C55.5135 15.5833 55.7412 15.7201 55.9689 15.8911L56.3559 13.3604H58.0747L56.5039 22.8562H54.785L54.9102 22.1039C54.7509 22.2407 54.6143 22.3547 54.4777 22.4686V22.4572Z" fill="#1E306D" />
                    <path d="M21.4681 15.1844L21.1835 16.8031L19.6013 16.7575C19.6013 16.7575 18.8159 16.9627 18.8159 17.2933C18.8159 17.6239 19.3964 18.0457 20.2615 18.4105C21.138 18.7866 21.1722 19.824 21.0925 20.5308C21.0128 21.2376 20.8876 22.6967 18.3606 22.7765C16.9036 22.8221 16.1182 22.5257 16.1182 22.5257L16.4483 20.8614C16.4483 20.8614 18.3151 21.363 18.7248 21.0666C19.1346 20.7816 19.6355 20.28 18.8501 19.9494C18.0646 19.6188 16.7784 19.0716 16.7784 17.7493C16.7784 16.4269 17.3134 15.6745 18.1443 15.2984C18.9753 14.9222 20.4209 14.9678 21.4567 15.173L21.4681 15.1844Z" fill="white" />
                    <path d="M9.00396 22.5941H7.17132L8.24131 16.2787L5.97612 22.5485H4.71262L4.40529 16.0279L3.30115 22.5941H1.55957L3.1987 12.9614H5.30453L6.03304 18.5016L8.48035 12.9614H10.6431L9.00396 22.5941Z" fill="white" />
                    <path d="M23.1082 22.7195C22.5163 22.7195 22.0837 22.5827 21.8333 22.3319C21.5715 22.0583 21.4463 21.6593 21.4463 21.135C21.4463 20.9982 21.4577 20.8614 21.4804 20.7474C21.4918 20.6106 21.526 20.4624 21.5374 20.28L22.6415 14.1128H24.3603L24.1099 15.3212H25.7149L25.4417 16.8487H23.8367L23.3928 19.4706C23.37 19.6416 23.3358 19.8126 23.3017 20.0064C23.2789 20.1774 23.2562 20.3598 23.2562 20.4738C23.2562 20.7474 23.3017 20.9184 23.4155 21.0096C23.518 21.1008 23.6887 21.1692 23.9164 21.1692C24.0074 21.1692 24.1099 21.1464 24.2579 21.1122C24.4058 21.0552 24.531 21.021 24.6107 20.9526H24.7473L24.4741 22.5029C24.2806 22.5827 24.0757 22.6169 23.8595 22.6511C23.6432 22.6853 23.3928 22.7081 23.0968 22.7081L23.1082 22.7195Z" fill="white" />
                    <path d="M35.4359 17.3615C35.0602 17.0765 34.2293 17.1335 34.0472 17.7263L33.2276 22.5939H31.5088L32.7495 15.3324H34.4683L34.4342 15.9138C34.7643 15.5832 35.1285 15.3096 36.0506 15.3324C36.1075 15.3324 35.4928 16.4153 35.4473 17.3501L35.4359 17.3615Z" fill="white" />
                    <path d="M38.6916 22.7876C37.7354 22.7876 36.9842 22.457 36.4719 21.8186C35.9483 21.1803 35.6865 20.2569 35.6865 19.0599C35.6865 18.1138 35.8004 17.2588 36.028 16.495C36.2557 15.7198 36.5858 15.0701 36.9728 14.5115C37.3712 13.9757 37.8379 13.5425 38.3956 13.2347C38.9306 12.9269 39.5112 12.7673 40.1258 12.7673C40.6039 12.7673 41.0706 12.8471 41.4918 12.9839C41.6967 13.0523 42.0951 13.2461 42.0951 13.2461L41.6967 15.5374C41.5032 15.2867 41.2983 15.0815 41.0934 14.9447C40.7974 14.7509 40.4332 14.6369 40.0462 14.6369C39.3404 14.6369 38.7485 15.0245 38.2932 15.7996C37.8265 16.5748 37.5988 17.5552 37.5988 18.7521C37.5988 19.5273 37.7241 20.0859 37.9859 20.4279C38.2363 20.7585 38.6119 20.9295 39.09 20.9295C39.5339 20.9295 39.9437 20.8155 40.3307 20.5875C40.5129 20.4621 40.695 20.3367 40.8657 20.1999L40.4673 22.3886C40.4673 22.3886 40.2624 22.4912 40.16 22.5254C39.921 22.6166 39.7047 22.6736 39.5225 22.7306C39.329 22.7648 39.0445 22.8104 38.6802 22.8104L38.6916 22.7876Z" fill="white" />
                    <path d="M13.2389 22.3433C13.1706 22.4003 13.1023 22.4345 13.0454 22.4801C12.8633 22.5713 12.7039 22.6511 12.5446 22.7081C12.3852 22.7423 12.1803 22.7879 11.8958 22.7879C11.4518 22.7879 11.0876 22.6169 10.8144 22.2863C10.5298 21.9329 10.3818 21.4884 10.3818 20.9526C10.3818 20.3712 10.4843 19.8924 10.6778 19.482C10.8713 19.0944 11.1786 18.7638 11.577 18.5359C11.9413 18.3079 12.3852 18.1483 12.8861 18.0571L13.2389 18.0001C13.6487 17.9203 14.0813 17.8633 14.548 17.8405C14.548 17.8063 14.548 17.7607 14.5593 17.7037C14.5707 17.6467 14.5707 17.5897 14.5707 17.5099C14.5707 17.2021 14.4683 16.9855 14.2634 16.8487C14.0471 16.7347 13.7398 16.6777 13.33 16.6777H13.2389C12.9885 16.7005 12.7039 16.7575 12.3852 16.8715C12.0437 17.0083 11.7933 17.0995 11.6226 17.2021H11.4632L11.7136 15.4808C11.9071 15.401 12.2259 15.3212 12.6356 15.2528C12.8292 15.1958 13.034 15.173 13.2276 15.1502C13.4438 15.116 13.6715 15.116 13.8764 15.116C14.7187 15.116 15.322 15.2756 15.7204 15.5606C16.1188 15.8455 16.3009 16.3129 16.3009 16.9513C16.3009 17.0311 16.3009 17.1451 16.2895 17.2819C16.2782 17.4187 16.2554 17.5327 16.244 17.6467L15.4017 22.5827H13.6942L13.8195 21.8075C13.717 21.8987 13.5804 22.0241 13.4324 22.1609C13.3528 22.2179 13.2731 22.2749 13.2048 22.3319L13.2389 22.3433ZM13.2389 19.3338C13.0568 19.368 12.8974 19.425 12.7722 19.4934C12.5787 19.5846 12.4308 19.7214 12.3283 19.8924C12.2145 20.0634 12.1689 20.3028 12.1689 20.5878C12.1689 20.8386 12.2372 21.0096 12.3625 21.1122C12.499 21.2034 12.6926 21.2718 12.9544 21.2718C13.034 21.2718 13.1251 21.249 13.2276 21.2148C13.3186 21.192 13.3983 21.1578 13.5007 21.1236C13.6829 21.0096 13.865 20.8728 14.0357 20.7132L14.2862 19.1742C13.9902 19.2084 13.6942 19.254 13.4324 19.311C13.3641 19.311 13.2845 19.3338 13.2162 19.3452L13.2389 19.3338Z" fill="white" />
                    <path d="M28.0936 16.5866C27.866 16.6208 27.6497 16.7462 27.4789 16.9286C27.2399 17.2022 27.0464 17.5442 26.9212 18.0116H29.2319C29.2319 17.9318 29.2433 17.8748 29.2433 17.795C29.2433 17.738 29.2547 17.6582 29.2547 17.6012C29.2547 17.2478 29.1636 16.997 29.0156 16.8032C28.8563 16.6322 28.6514 16.5296 28.344 16.5296C28.2644 16.5296 28.1619 16.5524 28.0708 16.5638L28.0936 16.5866ZM28.0936 21.1465H28.3554C28.7197 21.1465 29.0839 21.0667 29.4254 20.8957C29.7555 20.7247 30.0515 20.5309 30.2905 20.3143H30.484L30.1539 22.1838C29.8238 22.3776 29.4709 22.5372 29.1067 22.6284C28.7993 22.7196 28.4579 22.7652 28.0936 22.788H27.9342C27.0122 22.788 26.3179 22.5144 25.817 21.9558C25.3162 21.4201 25.0771 20.6677 25.0771 19.6987C25.0771 19.0375 25.1682 18.422 25.3162 17.8862C25.4755 17.3276 25.7146 16.8488 26.0105 16.4156C26.3065 16.0166 26.6594 15.6974 27.1033 15.4467C27.4106 15.2871 27.7407 15.1731 28.0822 15.1161C28.2416 15.0933 28.3896 15.0933 28.5489 15.0933C29.3457 15.0933 29.9376 15.2985 30.3588 15.743C30.7572 16.1648 30.9507 16.826 30.9507 17.6696C30.9507 17.9546 30.9393 18.251 30.8938 18.5359C30.8483 18.8095 30.7913 19.0945 30.7231 19.3681H26.739V19.5163C26.739 20.0179 26.8756 20.4055 27.1374 20.6905C27.3537 20.9299 27.6611 21.0781 28.0708 21.1123L28.0936 21.1465Z" fill="white" />
                    <path d="M44.1999 22.3433C44.1316 22.4003 44.0633 22.4345 43.9836 22.4801C43.8129 22.5713 43.6421 22.6511 43.4828 22.7081C43.3234 22.7423 43.1071 22.7879 42.8453 22.7879C42.39 22.7879 42.0371 22.6169 41.7526 22.2863C41.468 21.9329 41.3428 21.4884 41.3428 20.9526C41.3428 20.3712 41.4452 19.8924 41.6387 19.482C41.8322 19.0944 42.1396 18.7638 42.5152 18.5359C42.8795 18.3079 43.3234 18.1483 43.8356 18.0571C43.9495 18.0343 44.0633 18.0229 44.1885 18.0001C44.5869 17.9203 45.0308 17.8633 45.4861 17.8405C45.4975 17.8063 45.4975 17.7607 45.5089 17.7037C45.5089 17.6467 45.5203 17.5897 45.5203 17.5099C45.5203 17.2021 45.4065 16.9855 45.2016 16.8487C44.9853 16.7347 44.678 16.6777 44.2682 16.6777H44.1771C43.9039 16.7005 43.6193 16.7575 43.3234 16.8715C42.9819 17.0083 42.7201 17.0995 42.5607 17.2021H42.4014L42.6404 15.4808C42.8339 15.401 43.1527 15.3212 43.5738 15.2528C43.7673 15.1958 43.9722 15.173 44.1657 15.1502C44.382 15.116 44.5869 15.116 44.8259 15.116C45.6683 15.116 46.2716 15.2756 46.67 15.5606C47.057 15.8455 47.2505 16.3129 47.2505 16.9513C47.2505 17.0311 47.2505 17.1451 47.2391 17.2819C47.2277 17.4187 47.205 17.5327 47.1936 17.6467L46.3512 22.5827H44.6438L44.7804 21.8075C44.6666 21.8987 44.5414 22.0241 44.382 22.1609L44.1657 22.3319L44.1999 22.3433ZM44.1999 19.3338C44.0177 19.368 43.8584 19.425 43.7218 19.4934C43.5397 19.5846 43.3803 19.7214 43.2779 19.8924C43.1754 20.0634 43.1185 20.3028 43.1185 20.5878C43.1185 20.8386 43.1868 21.0096 43.3348 21.1122C43.46 21.2034 43.6649 21.2718 43.9153 21.2718C43.995 21.2718 44.0974 21.249 44.1999 21.2148C44.2909 21.192 44.3706 21.1578 44.4503 21.1236C44.6438 21.0096 44.8146 20.8728 44.9967 20.7132L45.2699 19.1742C44.9625 19.2084 44.678 19.254 44.4048 19.311C44.3365 19.311 44.2682 19.3338 44.2113 19.3452L44.1999 19.3338Z" fill="white" />
                    <path d="M51.4505 17.3615C51.0749 17.0765 50.2439 17.1335 50.0618 17.7263L49.2423 22.5939H47.5234L48.7642 15.3324H50.483L50.4488 15.9138C50.7789 15.5832 51.1432 15.3096 52.0652 15.3324C52.1221 15.3324 51.5074 16.4153 51.4619 17.3501L51.4505 17.3615Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M54.8309 16.8716C54.7398 16.9058 54.6601 16.9742 54.5918 17.0312C54.3642 17.1794 54.1821 17.4188 54.0227 17.6924C53.8975 17.966 53.7837 18.2737 53.7154 18.6727C53.6243 19.0603 53.5901 19.4479 53.5901 19.8127C53.5901 20.2573 53.6812 20.5651 53.8178 20.7589C53.9772 20.9755 54.2048 21.0666 54.5008 21.0666C54.6146 21.0666 54.7284 21.0438 54.8423 20.9868C54.8992 20.9641 54.9447 20.9527 55.0016 20.9071C55.1724 20.8159 55.3317 20.7133 55.4797 20.5765L56.0944 17.0084C55.9919 16.9514 55.8781 16.8944 55.7301 16.8488C55.5822 16.7918 55.4569 16.769 55.3431 16.769C55.161 16.769 55.0016 16.8032 54.8423 16.8602L54.8309 16.8716ZM54.8309 22.1952C54.7057 22.3092 54.5577 22.4232 54.3983 22.5258C54.2617 22.6056 54.1138 22.674 53.9885 22.7196C53.8406 22.7538 53.6698 22.7766 53.4877 22.7766C52.9641 22.7766 52.5429 22.5486 52.2356 22.104C51.9396 21.6366 51.7803 20.9869 51.7803 20.1547C51.7803 19.4593 51.8486 18.7981 52.0193 18.2053C52.1787 17.6126 52.4063 17.0654 52.6909 16.6094C52.9755 16.142 53.3056 15.8 53.6812 15.5264C54.0568 15.2756 54.4211 15.1388 54.8195 15.116H54.8764C55.1724 15.116 55.4342 15.1502 55.6391 15.23C55.8553 15.3098 56.083 15.4466 56.3107 15.6176L56.6977 13.0869H58.4165L56.8456 22.5828H55.1268L55.252 21.8304C55.0927 21.9672 54.9561 22.0812 54.8309 22.1952Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_6477_25115">
                        <rect width="60" height="36" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </Fragment>
    )
}
