import React from 'react'

function SvgFile() {
    return (
        <div>
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="15" cy="15" r="15" fill="#6CDBA6" fillOpacity="0.3" />
                <circle cx="15" cy="15" r="10" fill="#6CDBA6" />
                <path d="M13.4986 19.2095L10.3936 16.1045L11.8086 14.6895L13.4986 16.3845L18.4386 11.4395L19.8536 12.8545L13.4986 19.2095Z" fill="white" />
            </svg>

        </div>
    )
}

export default SvgFile
