import { GET_ALL_ORGANIZATIONS, SELECT_ORGANIZATION, SELECT_ORGANIZATION_LOADING } from "./OrganizationsActionTypes";

export const getAllOrganizations = (organizations: any) => {

    return {
        type: GET_ALL_ORGANIZATIONS,
        payload: organizations
    }
}

export const selectOrganization = (orgId: any) => {
    return {
        type: SELECT_ORGANIZATION,
        payload: orgId
    }
}

export const selectOrganizationLoading = (isLoading: any) => {
    return {
        type: SELECT_ORGANIZATION_LOADING,
        payload: isLoading
    }
}