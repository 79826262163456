import React, { Fragment } from 'react'

export function MClock() {
  return (
    <Fragment>

      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_40_1065)">
          <path d="M-0.000179786 13.9868C0.0230196 6.24347 6.31502 -0.0247872 14.0421 7.36967e-05C21.7343 0.0249346 28.0363 6.35452 28.0015 14.0166C27.965 21.823 21.6614 28.0465 13.8366 28.0001C6.22885 27.9553 -0.0233792 21.6257 -0.000179786 13.9868ZM14.0073 2.54914C7.69207 2.54914 2.5501 7.6738 2.54844 13.9686C2.54512 20.3081 7.66722 25.4543 13.9741 25.4527C20.3142 25.451 25.4479 20.3214 25.4512 13.9852C25.4545 7.68872 20.3109 2.54914 14.0073 2.54914Z" fill="#7E889A" />
          <path d="M12.7352 10.1798C12.7352 8.8887 12.7253 7.59594 12.7402 6.30483C12.7468 5.68662 13.2323 5.17614 13.8239 5.10985C14.4768 5.03692 15.0502 5.39989 15.2159 6.01644C15.264 6.19544 15.2656 6.38935 15.2656 6.57664C15.2689 8.77268 15.2706 10.9671 15.2623 13.1631C15.2623 13.3802 15.3236 13.5311 15.4777 13.6835C16.5449 14.7376 17.6038 15.8017 18.6627 16.8641C19.0189 17.2221 19.1598 17.648 19.0206 18.1436C18.8847 18.6259 18.5599 18.9259 18.0777 19.0402C17.6402 19.143 17.2409 19.0336 16.9244 18.7187C15.6401 17.4458 14.3641 16.1647 13.0882 14.8835C12.8214 14.615 12.7286 14.2802 12.7302 13.9056C12.7369 12.6642 12.7319 11.4212 12.7319 10.1798C12.7319 10.1798 12.7336 10.1798 12.7352 10.1798Z" fill="#7E889A" />
        </g>
        <defs>
          <clipPath id="clip0_40_1065">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>

    </Fragment>
  )
}
