import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MBox, MButton, MDialog, MDialogActions, MDialogContent, MLink } from 'src/app/material-ui';
import useStyles from './DisclaimerStyles';
import { DisclaimerIcon } from 'src/app/material-ui/icons';

interface MyComponentProps {
    fullWidth: boolean;
    maxWidth: string;
    open: boolean;
    onClose: () => void;
    onCancel: () => void;
    onApprove: () => void;
    message: string;
    description: string;
}

export default function Disclaimer(props: MyComponentProps) {
    const { fullWidth, maxWidth, open, onClose, onCancel, onApprove, message, description } = props;
    const classes = useStyles({});

    return (
        <div className={classes.mainWrapper}>
            <MDialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={onClose}
                className={classes.mainWrapper}
                aria-labelledby="customized-dialog-title"
            >
                <MBox className={classes.iconWrapper}>
                    <DisclaimerIcon />
                </MBox>
                <MDialogContent className={classes.contentBox}>
                    <MBox display="flex" alignItems="center" flexDirection="column" pt={6.8} height="100%" width="100%">

                        <MBox fontSize={24} fontWeight={700} color="#3A3A3A" fontFamily="Poppins" textAlign="center">
                            <FormattedMessage id="#" defaultMessage={message} />
                        </MBox>
                        <MBox fontSize={14} mt={1.2} color="#6B778C" fontFamily="Poppins" textAlign="center">
                            <FormattedMessage id="#" defaultMessage={description} />
                        </MBox>

                    </MBox>
                </MDialogContent>
                <MDialogActions>
                    <MBox pt={1} pb={1} pr={3}>
                        <MLink color="#3A3A3A" cursor="pointer" onClick={onCancel}>
                            <FormattedMessage id="no" />
                        </MLink>
                        <MButton sx={{ marginLeft: 1 }} variant="contained" size="small" color="primary" onClick={onApprove}>
                            <FormattedMessage id="yes" />
                        </MButton>
                    </MBox>
                </MDialogActions>
            </MDialog>
        </div>
    );
}
