import React, { Fragment } from 'react'

export function MImport() {
  return (
    <Fragment>

      <svg width="18" height="18" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_122_1503)">
          <path d="M7.5 3.75C7.5 1.68164 9.18164 0 11.25 0H20.625V7.5C20.625 8.53711 21.4629 9.375 22.5 9.375H30V26.25C30 28.3184 28.3184 30 26.25 30H11.25C9.18164 30 7.5 28.3184 7.5 26.25V19.6875H17.7012L15.416 21.9727C14.8652 22.5234 14.8652 23.4141 15.416 23.959C15.9668 24.5039 16.8574 24.5098 17.4023 23.959L22.0898 19.2715C22.6406 18.7207 22.6406 17.8301 22.0898 17.2852L17.4023 12.5977C16.8516 12.0469 15.9609 12.0469 15.416 12.5977C14.8711 13.1484 14.8652 14.0391 15.416 14.584L17.7012 16.8691H7.5V3.75ZM7.5 16.875V19.6875H1.40625C0.626953 19.6875 0 19.0605 0 18.2812C0 17.502 0.626953 16.875 1.40625 16.875H7.5ZM30 7.5H22.5V0L30 7.5Z" fill="#6B778C" />
        </g>
        <defs>
          <clipPath id="clip0_122_1503">
            <rect width="30" height="30" fill="white" />
          </clipPath>
        </defs>
      </svg>

    </Fragment>
  )
}
