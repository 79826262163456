import { put, takeEvery } from 'redux-saga/effects';
import { FETCH_ASSIGNED_JOB_FILES_REQUEST, FETCH_JOBS_REQUEST, FETCH_JOB_FILES_REQUEST, FETCH_JOB_LANGUAGES_REQUEST, FETCH_LINGUIST_PROFILE_DATA_REQUEST, FETCH_PROFILE_DATA_REQUEST } from './JobActionTypes';
import { fetchJobsSuccess, fetchJobsFailure, fetchJobFilesSuccess, fetchJobFilesFailure, fetchProfileDataSuccess, fetchProfileDataFailure, fetchAssignedJobFilesSuccess, fetchAssignedJobFilesFailure, fetchJobLanguagesSuccess, fetchJobLanguagesFailure, fetchLinguistProfileDataSuccess, fetchLinguistProfileDataFailure } from './JobActions';
import { gql } from 'apollo-boost';
import { client } from "src/app/hooks";


const ALL_OPEN_PROJECT = gql`
query getVendorOpenJobList($status:String,$encryptedId:String, $page:ID,$clientName:String,$langIds:String,$serviceIds:String,$jobTitle:String,$statusValues:String,$projectId:String,$orgId: ID,$jobId: Int) {
  getVendorOpenJobList(status:$status,encryptedId:$encryptedId, page:$page,clientName:$clientName,langIds:$langIds,serviceIds:$serviceIds,jobTitle:$jobTitle,statusValues:$statusValues,projectId:$projectId,orgId: $orgId,jobId: $jobId) {
      totalProjects
      openProjectInfo{
          id
          encryptedId
          customerStatus
          quoteRequestId
          createdAt
          quoteResponseId
          projectId
          encryptedProjectId
          catProjectId
          isPO
          isPOSent
          isPOApproved
          styleGuideId
          isPOReview
          POReviewBy
          wordCount
          POReviewByObject{
              name
          }
          targetLanguage{
              id
              name
              code
          }
          services{
              name
              id
          }
          translatedServices{
              swId
              id
              name
          }
          jobTitle
          jobId
          jobDescription
          jobInstruction
          jobIndustry
          sourceLanguage
          sourceLanguagesName
          status
          deadline
          bidProposalDeadline
          langServicePercentages {
              file
              langName
              langId
              langCode
              langFileId
              services {
                  langName
                  name
                  percentage
                  color
                  fileLangId
                  swId
                  id
              }
          }
          proposalSent
          requestedMinutes
          quoteResponseId
          completeDate
          invoiceId
          invoiceStatus
          isAnalysisExist
          linguistBatchExist
          combinations
          assignedBy{
              firstName  
          }
          invoices {
              id
              status
              statusLabel
          }
          refFiles {
             id
             name
             fileSize
             size    
             createdAt               
          }
      }
  }
}`;

const GET_ALL_JOB_FILES = gql`
    query getJobFiles($jobId:Int,$projectId:ID,$tagId: String,$fileName: String,$quoteRequestId:Int) {
        getJobFiles( jobId:$jobId, projectId:$projectId,tagId: $tagId,fileName: $fileName, quoteRequestId: $quoteRequestId) {
           tags
           file {
                id
                langFileId
                createdAt
                name
                editorData
                catFileId
                isOnline
                size
                translatedPercentage
                untranslatedPercentage
                reviewedPercentage
                start
                end
                servicesProgress{
                  swId
                  percentage
                }
                analysis {
                    total
                    new
                    subWords
                    hundred
                    fifty_seventyFour
                    seventyFive_ninetyEight
                    eightyFive_NinetyFour
                    ninetyFive_NinetyNine
                    repetition
                    crossFileRepetitions
                }
           }
        }
    }
`;

const GET_ALL_ASSIGNED_JOB_FILES = gql`
    query getJobFiles($jobId:Int,$projectId:ID,$tagId: String,$fileName:String,$languageId:Int) {
        getJobFiles( jobId:$jobId, projectId:$projectId,tagId: $tagId,fileName:$fileName,languageId: $languageId) {
           tags
           isAllOnline
           invoice
           file {
                id
                langFileId
                createdAt
                name
                editorData
                catFileId
                isOnline
                size
                translatedPercentage
                untranslatedPercentage
                reviewedPercentage
                start
                end
                servicesProgress{
                  swId
                  percentage
                }
                analysis {
                    total
                    new
                    subWords
                    hundred
                    fifty_seventyFour
                    seventyFive_ninetyEight
                    eightyFive_NinetyFour
                    ninetyFive_NinetyNine
                    repetition
                    crossFileRepetitions
                }
           }
        }
    }
`;

const GET_JOB_LANG = gql`
query GetJobLanguage($jobId:Int, $page:Int,$stats:Int) {
    getJobLanguage(jobId:$jobId, page:$page, stats:$stats) {
        totalLanguage
        language{
            code
            projectLangId
            id
            name
            createdAt
            stats
            wordCount
            translatedPercentage
            untranslatedPercentage
            reviewedPercentage
        }
    }
}
`;

const GET_PROFILE_DATA = gql`
query getVendorProfile {
  getVendorProfile {
      vendor_profile_id
      vendor_website
      vendor_phone
      vendor_logo
      vendor_founded
      vendor_company_size
      vendor_company_name
      vendor_address
      vendor_about_us
      vendor_org_url
  }
}

`;
const GET_LINGUIST_PROFILE_DATA = gql`
    query getLinguistProfile {
        getLinguistProfile {
            user_id
        }
    }
`;

// GET ALL JOBS

function* fetchJobs(action: any): any {
  const variables = action.payload || {};
  try {

    const jobs = yield client.query({ query: ALL_OPEN_PROJECT, variables, fetchPolicy: "no-cache" });

    yield put(fetchJobsSuccess(jobs.data));

  } catch (error) {
    yield put(fetchJobsFailure(error));
  }
}

// let ongoingRequest:any = null;

// function* fetchJobs(action: any) {
//     const variables:any = action.payload || {};
//
//     // Cancel any ongoing request
//     if (ongoingRequest && variables.cancel == 1) {
//         yield cancel(ongoingRequest);
//     } else {
//
//         try {
//             // Start new request
//             ongoingRequest = yield fork(fetchJobsRequest, variables);
//             const jobs:any = fetchJobsRequest;
//             yield put(fetchJobsSuccess(jobs.data));
//         } catch (error) {
//             yield put(fetchJobsFailure(error));
//         } finally {
//             // if (yield cancelled()) {
//             // Handle cancellation if needed
//             // }
//         }
//     }
// }

// function* fetchJobsRequest(variables:any) {
//     return yield call(() => client.query({ query: ALL_OPEN_PROJECT, variables, fetchPolicy: "no-cache" }));
// }

// export default function* watchFetchJobs() {
//     yield takeEvery('FETCH_JOBS_REQUEST', fetchJobs);
// }

// GET ALL OPEN JOB FILES

function* fetchJobFiles(action: any): any {
  const openJobvariables = action.payload || {};
  try {

    const openJobFiles = yield client.query({ query: GET_ALL_JOB_FILES, variables: openJobvariables, fetchPolicy: "no-cache" });

    yield put(fetchJobFilesSuccess(openJobFiles.data));

  } catch (error) {
    yield put(fetchJobFilesFailure(error));
  }
}

// GET ALL ASSIGNED JOB FILES

function* fetchAssignedJobFiles(action: any): any {
  const assignedJobvariables = action.payload || {};
  try {

    const jobFiles = yield client.query({ query: GET_ALL_ASSIGNED_JOB_FILES, variables: assignedJobvariables, fetchPolicy: "no-cache" });

    yield put(fetchAssignedJobFilesSuccess(jobFiles.data));

  } catch (error) {
    yield put(fetchAssignedJobFilesFailure(error));
  }
}

// GET ALL JOB LANGUAGES

function* fetchJobLanguages(action: any): any {
  const jobLanguagesvariables = action.payload || {};
  try {

    const jobLanguages = yield client.query({ query: GET_JOB_LANG, variables: jobLanguagesvariables, fetchPolicy: "no-cache" });

    yield put(fetchJobLanguagesSuccess(jobLanguages.data));

  } catch (error) {
    yield put(fetchJobLanguagesFailure(error));
  }
}

// GET PROFILE DATA

function* getProfileData(): any {
  try {

    const profileData = yield client.query({ query: GET_PROFILE_DATA, fetchPolicy: "no-cache" });

    yield put(fetchProfileDataSuccess(profileData.data));

  } catch (error) {
    yield put(fetchProfileDataFailure(error));
  }
}

// GET LINGUIST PROFILE DATA

function* getLinguistProfileData(): any {
  try {

    const LinguistProfileData = yield client.query({ query: GET_LINGUIST_PROFILE_DATA, fetchPolicy: "no-cache" });

    yield put(fetchLinguistProfileDataSuccess(LinguistProfileData.data));

    // check store updated sucessfully
    // yield select(store => console.log(store.jobs) );

  } catch (error) {
    yield put(fetchLinguistProfileDataFailure(error));
  }
}

// for multiple generator function
export default function* jobsSaga() {
  yield takeEvery(FETCH_JOBS_REQUEST, fetchJobs);
  yield takeEvery(FETCH_JOB_FILES_REQUEST, fetchJobFiles);
  yield takeEvery(FETCH_ASSIGNED_JOB_FILES_REQUEST, fetchAssignedJobFiles);
  yield takeEvery(FETCH_JOB_LANGUAGES_REQUEST, fetchJobLanguages);
  yield takeEvery(FETCH_PROFILE_DATA_REQUEST, getProfileData);
  yield takeEvery(FETCH_LINGUIST_PROFILE_DATA_REQUEST, getLinguistProfileData);

}