/* eslint-disable */
import TextField from "@mui/material/TextField/TextField";
import Autocomplete from "@mui/lab/Autocomplete/Autocomplete";
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { MKeyboardArrowDownRoundedIcon } from 'src/app/material-ui/icons/keyboard-rounded-icon/KeyboardArrowDownRoundedIcon';
import useStyles from './UnitSelectStyles';
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";


export function UnitSelect(props: any) {

    const classes: any = useStyles({});
    const intl = useIntl();
    const appUserRole = useSelector((store: any) => store.userRole.userRole);
    const [data, setData]: any = useState([
    ]);


    useEffect(() => {
        setData([
            { id: 1, name: intl.formatMessage({ id: "words" }) },
            { id: 2, name: intl.formatMessage({ id: "minutes" }) },
            { id: 3, name: intl.formatMessage({ id: "pages" }) }
        ]);
    }, []);

    useEffect(() => {
        if (props.selectedService && props.selectedService.service.isMatchRate == 1 && appUserRole === 'ROLE_VENDOR') {
            setData([
                { id: 1, name: intl.formatMessage({ id: "words" }) },
                { id: 2, name: intl.formatMessage({ id: "minutes" }) },
                { id: 3, name: intl.formatMessage({ id: "pages" }) },
                { id: 4, name: intl.formatMessage({ id: "chargeable.words" }) }
            ]);
        } else {
            setData([
                { id: 1, name: intl.formatMessage({ id: "words" }) },
                { id: 2, name: intl.formatMessage({ id: "minutes" }) },
                { id: 3, name: intl.formatMessage({ id: "pages" }) }
            ]);
        }
    }, [props.selectedService]);


    return (
        <div className="sourceLangSelect">
            <Autocomplete
                {...props}
                id="units"
                size="small"
                limitTags={1}
                popupIcon={<MKeyboardArrowDownRoundedIcon />}
                className={clsx(classes.inputWidth, classes.sourceLangSelect, classes[props.size ? props.size : 'sizeMd'])}
                options={data && data}
                value={props.value && props.value || null}
                defaultValue={props.defaultValue && props.defaultValue}
                getOptionLabel={(option: any) => option.name}
                disableClearable={true}
                onChange={(event: any, value: any) => {
                    props.setFieldValue(props.name, value)

                    if (value.id === 4 && props.selectedService && Number(props.selectedService.price) > 0) {
                        props.setSelectedService(props.selectedService);
                        props.setShowAddMatchRateModal(true);
                        props.setServiceIndex(props.index);
                        props.setIsEdit(0);
                    }
                }}
                renderInput={params => <TextField {...params}

                    placeholder="Select unit"
                    variant="outlined" color="secondary" />}
            />
        </div>
    )
}
