import React, { Fragment } from 'react'

export function MInvoice() {
    return (
        <Fragment>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.44 21L13.8 18L14.9136 16.84L16.44 18.43L19.8864 14.84L21 16.25L16.44 21ZM12.408 21H4.92C3.8544 21 3 20.1 3 19V5C3 3.89 3.8544 3 4.92 3H18.36C18.8692 3 19.3576 3.21071 19.7176 3.58579C20.0777 3.96086 20.28 4.46957 20.28 5V12.8C19.4352 12.29 18.4464 12 17.4 12L16.44 12.08V11H6.84V13H14.2224C12.6672 14.07 11.64 15.91 11.64 18C11.64 19.09 11.9184 20.12 12.408 21ZM11.64 15H6.84V17H11.64V15ZM16.44 7H6.84V9H16.44" fill="#A7ADBB" />
            </svg>

        </Fragment>
    )
}
