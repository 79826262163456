/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Container, Draggable } from "react-smooth-dnd";
import {
  MBox,
  MButton,
  MDrawer,
  MSkeleton,
} from "src/app/material-ui";
import { gql } from "apollo-boost";
import "./ServiceDndStyles.css";
import { useQuery } from "@apollo/react-hooks";
import { useSelector } from "react-redux";
import useStyles from "./ServiceDndStyle";
import ErrorBoundary from 'src/app/components/error-boundry/ErrorBoundry';
import { FormattedMessage } from "react-intl";
import SettingsIcon from "@mui/icons-material/Settings";
import TranslateIcon from "@mui/icons-material/Translate";
import { MAddIcon, MQualityAssurance } from "src/app/material-ui/icons";
import AvailableServices from "./AvailableServices";
import { Draggable as MDraggable } from "src/app/components";
import "./ServiceDndStyles.css";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

const GET_ALL_SERVICES = gql`
  query getAllServices {
    getAllServices {
      id
      name
    }
  }
`;

function ServiceDndList(props: any) {
  try {
    const classes = useStyles();
    const userRole = useSelector((store: any) => store.userRole.userRole);
    const [services, setServices]: any = useState([]);
    const [value, setValue] = React.useState("template");
    const [openWorkFlow, setOpenWorkFlow]: any = useState(true);
    const [addAvalibleServices, setAddAvalibleServices]: any = useState(false);
    const [hidden, setHidden]: any = useState(false);

    const [state, setState] = useState({
      scene: {
        type: "container",
        children: [
          {
            id: `serviceList`,
            children: [],
          },
          {
            id: `serviceList2`,
            children: [],
          },
          {
            id: `serviceList3`,
            children: [],
          },
          {
            id: `selected`,
            children: [],
          },
        ],
      },
    });



    const { data: servicesList, loading: serviceLoading } =
      useQuery(GET_ALL_SERVICES);

    useEffect(() => {
      if (props.selectedService) {
        const scene: any = state.scene;
        scene.children[3] = {
          ...scene.children[3],
          children: props.selectedService,
        };
      }
    }, [props.selectedService]);

    useEffect(() => {
      if (props.setSelectedService && state?.scene?.children) {
        props.setSelectedService(state.scene.children[3].children);
      }
    }, [state]);

    useEffect(() => {
      if (props.services && props.services.length > 0) {
        setServices(props.services);

        // alert('here')
      } else {
        setServices(servicesList && servicesList.getAllServices);
      }
      return () => {
        setServices([]);
      };
    }, [props.services, servicesList]);

    useEffect(() => {
      if (services && services?.length > 0) {
        const allServices = [...services];
        const serviceInThreeParts = splitToChunks(allServices, 3);
        const scene: any = state.scene;
        scene.children[0] = {
          ...scene.children[0],
          children: serviceInThreeParts[2],
        };
        scene.children[1] = {
          ...scene.children[1],
          children: serviceInThreeParts[1],
        };
        scene.children[2] = {
          ...scene.children[2],
          children: serviceInThreeParts[0],
        };
        setState({ scene });
      }

    }, [services]);

    const splitToChunks = (array: Array<any>, parts: number) => {
      const result = [];
      for (let i = parts; i > 0; i--) {
        result.push(array.splice(0, Math.ceil(array.length / i)));
      }
      return result;
    };



    const getCardPayload = (columnId: any, index: any) => {
      return state.scene.children.filter((p: any) => p?.id === columnId)[0]
        .children[index];
    };

    const onCardDrop = (columnId: any, dropResult: any, columnName: string) => {
      if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
        const scene = Object.assign({}, state.scene);
        const column = scene.children.filter((p: any) => p.id === columnId)[0];
        const columnIndex = scene.children.indexOf(column);
        const newColumn = Object.assign({}, column);
        newColumn.children = applyDrag(
          newColumn.children,
          dropResult,
          columnName
        );
        scene.children.splice(columnIndex, 1, newColumn);
        setState({ scene });
        if (typeof props.setDisable === "function") {
          props.setDisable(true);
        }
      }
    };

    const removeItem = (index: number) => {
      const scene: any = state.scene;
      scene.children[3] = {
        ...scene.children[3],
        children: scene.children[3].children.filter(
          (i: any, Iindex: number) => Iindex !== index
        ),
      };

      setState({ scene });
      if (typeof props.setDisable === "function") {
        props.setDisable(true);
      }
    };

    const applyDrag = (arr: any, dragResult: any, columnName: string) => {
      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;
      const result = [...arr];
      let itemToAdd = payload;
      if (columnName !== "listColumn") {
        if (removedIndex !== null) {
          itemToAdd = result.splice(removedIndex, 1)[0];
        }
        if (addedIndex !== null) {
          result.splice(addedIndex, 0, itemToAdd);
        }
      }
      return result;
    };

    const handleChangeLang = (method: string) => {
      // let servicesList = []
      let desire: any = [];
      // for (let i = 0; i < services.lenth; i++) {

      if (method === "mtpe") {
        desire = ["Machine Translation", "Proofreading"];
      } else if (method === "standard") {
        desire = ["Translation"];
      } else if (method === "professional") {
        desire = ["Translation", "Proofreading"];
      } else if (method === "premium") {
        desire = ["Machine Translation", "Proofreading", "Quality Assurance"];
      }

      const filterServices =
        services &&
        services.filter((element: any) => desire.includes(element.name));

      const sortedServices: any = [];
      let filteredService;
      for (let i = 0; i < desire.length; i++) {
        filteredService = filterServices.find(
          (ele: any) => ele.name == desire[i]
        );
        if (filteredService) {
          sortedServices.push(filteredService);
        }
      }

      props.setSelectPreprocessing(sortedServices);
      props.setSelectedService(sortedServices);
      if (state?.scene?.children && state.scene.children[3].children) {
        state.scene.children[3].children = sortedServices;
      }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue((event.target as HTMLInputElement).value);
    };
    const handleChooseWorkflow = (method: string) => {
      setOpenWorkFlow(method === "template");
    };

    return (
      <div style={{ width: "100%" }}>
        {//@ts-ignore
          <Container
            orientation="horizontal"
            dragHandleSelector=".column-drag-handle"
            dropPlaceholder={{
              animationDuration: 150,
              showOnTop: true,
              className: "cards-drop-preview",
            }}
          >
            <MBox>
              <MBox>
                <MBox display="flex">
                  <MBox flex="0 0 35%" fontSize={18} fontFamily="Poppins">
                    {userRole === "ROLE_CLIENT" ? (
                      <FormattedMessage id="services" />
                    ) : (
                      <FormattedMessage id="selected.services" />
                    )}
                  </MBox>
                  <MBox flex="0 0 65%" fontSize={18} fontFamily="Poppins">
                    <FormattedMessage id="available.services" />
                  </MBox>
                </MBox>

                {/* {(appUserRole === 'ROLE_VENDOR') && (location.pathname === "/vendor/rates") ?
              null : */}

                <MBox
                  fontSize={18}
                  fontFamily="Poppins"
                  className="serviceListItems"
                  flex="0 0 65%"
                >
                  <MBox
                    display="flex"
                    justifyContent="space-between"
                    mb={3}
                    className="gap-10"
                  ></MBox>
                  <MBox className={classes.SelectWorkflowOptions}>
                    <MBox>
                      <MBox
                        display="flex"
                        justifyContent="space-between"
                        className={classes.workFlowWrap}
                        mt={3}
                      >
                        <MBox className={classes.dragBox} flex="0 0 35%">
                          {//@ts-ignore
                            <Container
                              orientation="vertical"
                              groupName="col"
                              style={{
                                overflow: "auto",
                                padding: 28,
                                minHeight: 357,
                                maxHeight: 357,
                                overflowX: "hidden",
                                border: "1px dashed #dcddde",
                                backgroundColor: "#fefefe",
                                borderRadius: 8,
                                minWidth: 277,
                              }}
                              onDrop={(e) =>
                                onCardDrop(
                                  state.scene.children[3].id,
                                  e,
                                  "selectedItemsColumn"
                                )
                              }
                              getChildPayload={(index) =>
                                getCardPayload(state.scene.children[3].id, index)
                              }
                              lockAxis={"y"}
                              dragClass="card-ghost"
                              dropClass="card-ghost-drop"
                              dropPlaceholder={{
                                animationDuration: 150,
                                showOnTop: true,
                                className: "drop-preview",
                              }}
                            >
                              {state?.scene?.children[3]?.children &&
                                state.scene.children[3].children.length < 1 && (
                                  <MBox>
                                    <MBox className={classes.emptyCaseWrap}>
                                      <MBox
                                        className={classes.dragIconWrap}
                                        height="100%"
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        <img
                                          className={classes.dragIcon}
                                          src="/assets/images/drag-drop-icon.svg"
                                        />
                                        <MBox className={classes.dragBoxTxt}>
                                          <FormattedMessage id="drag.drop.service" />
                                        </MBox>
                                      </MBox>
                                    </MBox>
                                  </MBox>
                                )}
                              {state?.scene?.children[3]?.children &&
                                state.scene.children[3].children.map(
                                  (card: any, index: number) => {
                                    return (
                                      //@ts-ignore
                                      <Draggable key={card.id}>
                                        <div key={card.id} {...card.props}>
                                          <MDraggable
                                            isSelected={true}
                                            text={card.name}
                                            totalJobs={card.totalJobs}
                                            counter={index + 1}
                                            mb={1}
                                            removeItem={() => removeItem(index)}
                                          />
                                        </div>
                                      </Draggable>

                                    );
                                  }
                                )}
                            </Container>
                          }
                        </MBox>

                        <MBox className="serviceListItems" flex="0 0 65%">
                          <MBox
                            display="flex"
                            flexWrap="wrap"
                            justifyContent="space-between"
                          >
                            {!serviceLoading && (
                              <>
                                <MBox
                                  className={classes.servicesContainer}
                                  flex={
                                    services && services.length > 2
                                      ? "0 0 32%"
                                      : services && services.length === 2
                                        ? "0 0 48%"
                                        : "0 0 100%"
                                  }
                                >
                                  {//@ts-ignore
                                    <Container
                                      orientation="vertical"
                                      groupName="col"
                                      behaviour="copy"
                                      onDrop={(e) =>
                                        onCardDrop(
                                          state.scene.children[2].id,
                                          e,
                                          "listColumn"
                                        )
                                      }
                                      getChildPayload={(index) =>
                                        getCardPayload(
                                          state.scene.children[2].id,
                                          index
                                        )
                                      }
                                      // lockAxis={'x'}
                                      dragClass="card-ghost"
                                      dropClass="card-ghost-drop"
                                      dropPlaceholder={{
                                        animationDuration: 150,
                                        showOnTop: true,
                                        className: "drop-preview",
                                      }}
                                    >
                                      {state?.scene?.children[2]?.children &&
                                        state.scene.children[2].children.map(
                                          (card: any) => {
                                            return (
                                              //@ts-ignore
                                              <Draggable key={card.id}>
                                                <MBox {...card.props}>
                                                  <MDraggable
                                                    isSelected={false}
                                                    text={card.name}
                                                    mb={1}
                                                  />
                                                </MBox>
                                              </Draggable>
                                            );
                                          }
                                        )}
                                    </Container>
                                  }
                                </MBox>
                                <MBox
                                  className={classes.servicesContainer}
                                  flex={
                                    services && services.length > 2
                                      ? "0 0 32%"
                                      : services && services.length === 2
                                        ? "0 0 48%"
                                        : "0 0 100%"
                                  }
                                >
                                  {//@ts-ignore
                                    <Container
                                      orientation="vertical"
                                      groupName="col"
                                      behaviour="copy"
                                      onDrop={(e) =>
                                        onCardDrop(
                                          state.scene.children[1].id,
                                          e,
                                          "listColumn"
                                        )
                                      }
                                      getChildPayload={(index) =>
                                        getCardPayload(
                                          state.scene.children[1].id,
                                          index
                                        )
                                      }
                                      // lockAxis={'x'}
                                      dragClass="card-ghost"
                                      dropClass="card-ghost-drop"
                                      dropPlaceholder={{
                                        animationDuration: 150,
                                        showOnTop: true,
                                        className: "drop-preview",
                                      }}
                                    >
                                      {state?.scene?.children[1]?.children &&
                                        state.scene.children[1].children.map(
                                          (card: any) => {
                                            return (
                                              //@ts-ignore
                                              <Draggable key={card.id}>
                                                <div {...card.props}>
                                                  <MDraggable
                                                    isSelected={false}
                                                    text={card.name}
                                                    mb={1}
                                                  />
                                                </div>
                                              </Draggable>
                                            );
                                          }
                                        )}
                                    </Container>
                                  }
                                </MBox>
                                <MBox
                                  className={classes.servicesContainer}
                                  flex={
                                    services && services.length > 2
                                      ? "0 0 32%"
                                      : services && services.length === 2
                                        ? "0 0 48%"
                                        : "0 0 100%"
                                  }
                                >
                                  {//@ts-ignore
                                    <Container
                                      orientation="vertical"
                                      groupName="col"
                                      behaviour="copy"
                                      onDrop={(e) =>
                                        onCardDrop(
                                          state.scene.children[0].id,
                                          e,
                                          "listColumn"
                                        )
                                      }
                                      getChildPayload={(index) =>
                                        getCardPayload(
                                          state.scene.children[0].id,
                                          index
                                        )
                                      }
                                      // lockAxis={'x'}
                                      dragClass="card-ghost"
                                      dropClass="card-ghost-drop"
                                      dropPlaceholder={{
                                        animationDuration: 150,
                                        showOnTop: true,
                                        className: "drop-preview",
                                      }}
                                    >
                                      {state?.scene?.children[0]?.children &&
                                        state.scene.children[0].children.map(
                                          (card: any) => {
                                            return (
                                              //@ts-ignore
                                              <Draggable key={card.id}>
                                                <div {...card.props}>
                                                  <MDraggable
                                                    isSelected={false}
                                                    text={card.name}
                                                    mb={1}
                                                  />
                                                </div>
                                              </Draggable>
                                            );
                                          }
                                        )}
                                    </Container>
                                  }
                                </MBox>
                              </>
                            )}
                            {serviceLoading &&
                              [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                                (_, index: number) => (
                                  <MBox key={"box" + index} width="32%" mb={1}>
                                    <MDraggable
                                      isSelected={false}
                                      text={<MSkeleton width={140} height={19} />}
                                      mb={1}
                                    />
                                  </MBox>
                                )
                              )}
                          </MBox>
                        </MBox>
                      </MBox>
                    </MBox>
                  </MBox>
                </MBox>

                {/* } */}
              </MBox>
            </MBox>
            {/* will use in future */}
            <MDrawer
              open={addAvalibleServices}
              variant="temporary"
              anchor="right"
              // className={classes.fileUploadDrawer}
              onClose={() => {
                setAddAvalibleServices(false);
              }}
            >
              <AvailableServices />
            </MDrawer>
          </Container>
        }
        {hidden && (
          <MBox className={classes.SelectWorkflowOptions}>
            <MBox fontSize={14} fontFamily="Poppins" className="textCapitalize">
              <FormattedMessage id="workflow" />
            </MBox>
            <MBox className={classes.selectOptionWrapper}>
              <MBox>
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="template"
                    control={
                      <Radio
                        size="small"
                        onClick={() => handleChooseWorkflow("template")}
                      />
                    }
                    label="Template"
                  />
                  <FormControlLabel
                    value="custom"
                    control={
                      <Radio
                        size="small"
                        onClick={() => handleChooseWorkflow("custom")}
                      />
                    }
                    label="Custom"
                  />
                </RadioGroup>
              </MBox>
              <MBox>
                <MButton
                  variant="text"
                  startIcon={<MAddIcon style={{ marginRight: -7 }} />}
                  style={{ width: 161 }}
                // onClick={() => props.history.push('/create-project')}
                >
                  <FormattedMessage id="add.custom" />
                </MButton>
              </MBox>
            </MBox>
            {openWorkFlow ? (
              <MBox>
                <MBox mb={3}>
                  <FormattedMessage id="predefined" />
                </MBox>
                <MBox
                  display="flex"
                  justifyContent="space-between"
                  mb={3}
                  className="gap-10"
                >
                  <MBox className={classes.packagesContainer}>
                    <MBox
                      className={"textUppercase"}
                      py={1}
                      textAlign="center"
                      color="#909090"
                      fontSize={16}
                      fontWeight={600}
                      borderBottom="1px solid #e5e5e5"
                    >
                      <FormattedMessage id="mtpe" />
                    </MBox>
                    <MBox py={2} px={1.25} flex="1">
                      <MBox
                        fontSize={12}
                        color="#adaeaf"
                        display="flex"
                        alignItems="center"
                      >
                        <SettingsIcon />
                        &nbsp;
                        <FormattedMessage id="machine.translation" />
                      </MBox>
                      <MBox
                        mt={1.25}
                        fontSize={12}
                        color="#adaeaf"
                        display="flex"
                        alignItems="center"
                      >
                        <SettingsIcon />
                        &nbsp;
                        <FormattedMessage id="proof.reading" />
                      </MBox>
                    </MBox>
                    <MBox
                      p={"10px 6px 15px 6px"}
                      color="#909090"
                      fontSize={12}
                      borderTop="1px solid #e5e5e5"
                    >
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce venenatis consequat tempus.
                    </MBox>
                    <MBox display="flex" justifyContent="center">
                      <MButton
                        className={classes.selectServicesBtn}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleChangeLang("mtpe");
                        }}
                      >
                        Click to select
                      </MButton>
                    </MBox>
                    <MBox></MBox>
                  </MBox>
                  <MBox className={classes.packagesContainer}>
                    <MBox
                      className={"textUppercase"}
                      py={1}
                      textAlign="center"
                      color="#909090"
                      fontSize={16}
                      fontWeight={600}
                      borderBottom="1px solid #e5e5e5"
                    >
                      <FormattedMessage id="standard" />
                    </MBox>
                    <MBox py={2} px={1.25} flex="1">
                      <MBox
                        fontSize={12}
                        color="#adaeaf"
                        display="flex"
                        alignItems="center"
                      >
                        <TranslateIcon />
                        &nbsp;
                        <FormattedMessage id="translation" />
                      </MBox>
                    </MBox>
                    <MBox
                      p={"10px 6px 15px 6px"}
                      color="#909090"
                      fontSize={12}
                      borderTop="1px solid #e5e5e5"
                    >
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce venenatis consequat tempus.
                    </MBox>
                    <MBox display="flex" justifyContent="center">
                      <MButton
                        className={classes.selectServicesBtn}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleChangeLang("standard");
                        }}
                      >
                        Click to select
                      </MButton>
                    </MBox>

                    <MBox></MBox>
                  </MBox>
                  <MBox className={classes.packagesContainer}>
                    <MBox
                      className={"textUppercase"}
                      py={1}
                      textAlign="center"
                      color="#909090"
                      fontSize={16}
                      fontWeight={600}
                      borderBottom="1px solid #e5e5e5"
                    >
                      <FormattedMessage id="professional" />
                    </MBox>
                    <MBox py={2} px={1.25} flex="1">
                      <MBox
                        fontSize={12}
                        color="#adaeaf"
                        display="flex"
                        alignItems="center"
                      >
                        <TranslateIcon />
                        &nbsp;
                        <FormattedMessage id="translation" />
                      </MBox>
                      <MBox
                        mt={1.25}
                        fontSize={12}
                        color="#adaeaf"
                        display="flex"
                        alignItems="center"
                      >
                        <SettingsIcon />
                        &nbsp;
                        <FormattedMessage id="proof.reading" />
                      </MBox>
                    </MBox>
                    <MBox
                      p={"10px 6px 15px 6px"}
                      color="#909090"
                      fontSize={12}
                      borderTop="1px solid #e5e5e5"
                    >
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce venenatis consequat tempus.
                    </MBox>
                    <MBox display="flex" justifyContent="center">
                      <MButton
                        className={classes.selectServicesBtn}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleChangeLang("professional");
                        }}
                      >
                        Click to select
                      </MButton>
                    </MBox>
                    <MBox></MBox>
                  </MBox>
                  <MBox className={classes.packagesContainer}>
                    <MBox
                      className={"textUppercase"}
                      py={1}
                      textAlign="center"
                      color="#909090"
                      fontSize={16}
                      fontWeight={600}
                      borderBottom="1px solid #e5e5e5"
                    >
                      <FormattedMessage id="premium" />
                    </MBox>
                    <MBox py={2} px={1.25} flex="1">
                      <MBox
                        fontSize={12}
                        color="#adaeaf"
                        display="flex"
                        alignItems="center"
                      >
                        <SettingsIcon />
                        &nbsp;
                        <FormattedMessage id="machine.translation" />
                      </MBox>
                      <MBox
                        mt={1.25}
                        fontSize={12}
                        color="#adaeaf"
                        display="flex"
                        alignItems="center"
                      >
                        <SettingsIcon />
                        &nbsp;
                        <FormattedMessage id="proof.reading" />
                      </MBox>
                      <MBox
                        mt={1.25}
                        fontSize={12}
                        color="#adaeaf"
                        display="flex"
                        alignItems="center"
                      >
                        <SettingsIcon />
                        <MQualityAssurance />
                        &nbsp;
                        <FormattedMessage id="qa" />
                      </MBox>
                    </MBox>
                    <MBox
                      p={"10px 6px 15px 6px"}
                      color="#909090"
                      fontSize={12}
                      borderTop="1px solid #e5e5e5"
                    >
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce venenatis consequat tempus.
                    </MBox>
                    <MBox display="flex" justifyContent="center">
                      <MButton
                        className={classes.selectServicesBtn}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleChangeLang("premium");
                        }}
                      >
                        Click to select
                      </MButton>
                    </MBox>
                    <MBox></MBox>
                  </MBox>
                </MBox>
              </MBox>
            ) : (
              <MBox>
                <MBox>
                  <FormattedMessage id="available.services" />
                </MBox>
                <MBox
                  display="flex"
                  justifyContent="space-between"
                  className={classes.workFlowWrap}
                  mt={3}
                >
                  <MBox className={classes.dragBox} flex="0 0 35%">
                    {  //@ts-ignore
                      <Container
                        orientation="vertical"
                        groupName="col"
                        style={{
                          overflow: "auto",
                          padding: 28,
                          minHeight: 357,
                          maxHeight: 357,
                          overflowX: "hidden",
                          border: "1px dashed #dcddde",
                          backgroundColor: "#fefefe",
                          borderRadius: 8,
                          minWidth: 277,
                        }}
                        onDrop={(e) =>
                          onCardDrop(
                            state.scene.children[3].id,
                            e,
                            "selectedItemsColumn"
                          )
                        }
                        getChildPayload={(index) =>
                          getCardPayload(state.scene.children[3].id, index)
                        }
                        lockAxis={"y"}
                        dragClass="card-ghost"
                        dropClass="card-ghost-drop"
                        dropPlaceholder={{
                          animationDuration: 150,
                          showOnTop: true,
                          className: "drop-preview",
                        }}
                      >
                        {state?.scene?.children[3]?.children &&
                          state.scene.children[3].children.length < 1 && (
                            <MBox>
                              <MBox className={classes.emptyCaseWrap}>
                                <MBox
                                  className={classes.dragIconWrap}
                                  height="100%"
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <img
                                    className={classes.dragIcon}
                                    src="/assets/images/drag-drop-icon.svg"
                                  />
                                  <MBox className={classes.dragBoxTxt}>
                                    <FormattedMessage id="drag.drop.service" />
                                  </MBox>
                                </MBox>
                              </MBox>
                            </MBox>
                          )}
                        {state?.scene?.children[3]?.children &&
                          state.scene.children[3].children.map(
                            (card: any, index: number) => {
                              return (
                                //@ts-ignore
                                <Draggable key={card.id}>
                                  <div key={card.id} {...card.props}>
                                    <MDraggable
                                      isSelected={true}
                                      text={card.name}
                                      totalJobs={card.totalJobs}
                                      counter={index + 1}
                                      mb={1}
                                      removeItem={() => removeItem(index)}
                                    />
                                  </div>
                                </Draggable>
                              );
                            }
                          )}
                      </Container>
                    }
                  </MBox>

                  <MBox className="serviceListItems" flex="0 0 65%">
                    <MBox
                      display="flex"
                      flexWrap="wrap"
                      justifyContent="space-between"
                    >
                      {!serviceLoading && (
                        <>
                          <MBox
                            className={classes.servicesContainer}
                            flex={
                              services && services.length > 2
                                ? "0 0 32%"
                                : services && services.length === 2
                                  ? "0 0 48%"
                                  : "0 0 100%"
                            }
                          >
                            { //@ts-ignore
                              <Container
                                orientation="vertical"
                                groupName="col"
                                behaviour="copy"
                                onDrop={(e) =>
                                  onCardDrop(
                                    state.scene.children[2].id,
                                    e,
                                    "listColumn"
                                  )
                                }
                                getChildPayload={(index) =>
                                  getCardPayload(state.scene.children[2].id, index)
                                }
                                // lockAxis={'x'}
                                dragClass="card-ghost"
                                dropClass="card-ghost-drop"
                                dropPlaceholder={{
                                  animationDuration: 150,
                                  showOnTop: true,
                                  className: "drop-preview",
                                }}
                              >
                                {state?.scene?.children[2]?.children &&
                                  state.scene.children[2].children.map(
                                    (card: any) => {
                                      return (
                                        //@ts-ignore
                                        <Draggable key={card.id}>
                                          <MBox {...card.props}>
                                            <MDraggable
                                              isSelected={false}
                                              text={card.name}
                                              mb={1}
                                            />
                                          </MBox>
                                        </Draggable>
                                      );
                                    }
                                  )}
                              </Container>
                            }
                          </MBox>
                          <MBox
                            className={classes.servicesContainer}
                            flex={
                              services && services.length > 2
                                ? "0 0 32%"
                                : services && services.length === 2
                                  ? "0 0 48%"
                                  : "0 0 100%"
                            }
                          >
                            { //@ts-ignore
                              <Container
                                orientation="vertical"
                                groupName="col"
                                behaviour="copy"
                                onDrop={(e) =>
                                  onCardDrop(
                                    state.scene.children[1].id,
                                    e,
                                    "listColumn"
                                  )
                                }
                                getChildPayload={(index) =>
                                  getCardPayload(state.scene.children[1].id, index)
                                }
                                // lockAxis={'x'}
                                dragClass="card-ghost"
                                dropClass="card-ghost-drop"
                                dropPlaceholder={{
                                  animationDuration: 150,
                                  showOnTop: true,
                                  className: "drop-preview",
                                }}
                              >
                                {state?.scene?.children[1]?.children &&
                                  state.scene.children[1].children.map(
                                    (card: any) => {
                                      return (
                                        //@ts-ignore
                                        <Draggable key={card.id}>
                                          <div {...card.props}>
                                            <MDraggable
                                              isSelected={false}
                                              text={card.name}
                                              mb={1}
                                            />
                                          </div>
                                        </Draggable>
                                      );
                                    }
                                  )}
                              </Container>
                            }
                          </MBox>
                          <MBox
                            className={classes.servicesContainer}
                            flex={
                              services && services.length > 2
                                ? "0 0 32%"
                                : services && services.length === 2
                                  ? "0 0 48%"
                                  : "0 0 100%"
                            }
                          >
                            { //@ts-ignore
                              <Container
                                orientation="vertical"
                                groupName="col"
                                behaviour="copy"
                                onDrop={(e) =>
                                  onCardDrop(
                                    state.scene.children[0].id,
                                    e,
                                    "listColumn"
                                  )
                                }
                                getChildPayload={(index) =>
                                  getCardPayload(state.scene.children[0].id, index)
                                }
                                // lockAxis={'x'}
                                dragClass="card-ghost"
                                dropClass="card-ghost-drop"
                                dropPlaceholder={{
                                  animationDuration: 150,
                                  showOnTop: true,
                                  className: "drop-preview",
                                }}
                              >
                                {state?.scene?.children[0]?.children &&
                                  state.scene.children[0].children.map(
                                    (card: any) => {
                                      return (
                                        //@ts-ignore
                                        <Draggable key={card.id}>
                                          <div {...card.props}>
                                            <MDraggable
                                              isSelected={false}
                                              text={card.name}
                                              mb={1}
                                            />
                                          </div>
                                        </Draggable>
                                      );
                                    }
                                  )}
                              </Container>
                            }
                          </MBox>
                        </>
                      )}
                      {serviceLoading &&
                        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                          (_, index: number) => (
                            <MBox key={"box" + index} width="32%" mb={1}>
                              <MDraggable
                                isSelected={false}
                                text={<MSkeleton width={140} height={19} />}
                                mb={1}
                              />
                            </MBox>
                          )
                        )}
                    </MBox>
                  </MBox>
                </MBox>
              </MBox>
            )}
          </MBox>
        )}
      </div>
    );

  } catch (e) {
    return (<div style={{ width: "100%" }}>
      <ErrorBoundary>
      </ErrorBoundary>
    </div>)
  }

}

export default ServiceDndList;

