import React, { useEffect, useState } from 'react';
import ServiceDndList from 'src/app/components/services-dnd/ServiceDndList';
import * as Yup from 'yup';
import { MBox, MButton, MCircularProgress, MForm, MFormik, MFormLabel, MGrid, MTextField, MAppBar, MToolbar, MIconButton, MLink } from 'src/app/material-ui';
import useStyles from './EditServicesStyles';
import { gql } from "apollo-boost";
import { selectedServiceName, showAlert } from "src/app/store";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const GET_VENDOR_PACKAGE = gql`
    query getAllPackages($packageId:Int) {
        getAllPackages(packageId:$packageId) {
        packages{
           id
          name
          description
        vendorPackagesServices {
          id
          name
          priority
        }
        }
    
   }
    }
`;

export default function EditServices(props: any) {
    const classes = useStyles({});
    const [selectedService, setSelectedService]: any = useState();
    const dispatch = useDispatch();
    const [vendorPackages, setVendorPackages]: any = useState('');
    let ADD_VENDOR_SERVICE;

    const userRole = useSelector((store: any) => store.auth.currentUser.defaultRole);

    // const SelectedOrgId = useSelector((store: any) => store.orgs.selectedOrgId);
    const SelectedOrgId: any = window.localStorage.getItem('selectedOrgId');

    if (props.Services !== "Add") {
        ADD_VENDOR_SERVICE = gql`
            mutation setVendorPackage($name: String!, $description: String,$serviceData: [ServicesInput],$packageId:Int,$orgId: Int,$role: String) {
                setVendorPackage(name: $name, description:$description, serviceData:$serviceData,packageId: $packageId, orgId: $orgId, role:$role)
            }
        `;
    } else {
        ADD_VENDOR_SERVICE = gql`
            mutation setVendorPackage($name: String!, $description: String,$serviceData: [ServicesInput],$orgId: Int,$role: String) {
                setVendorPackage(name: $name, description:$description, serviceData:$serviceData, orgId: $orgId, role:$role)
            }
        `;
    }

    const [getVendorPackage] = useLazyQuery(GET_VENDOR_PACKAGE, {
        fetchPolicy: 'no-cache',
        onCompleted: (data: any) => {
            dispatch(selectedServiceName(data.getAllPackages.packages[0].name));
            setVendorPackages(data.getAllPackages.packages[0]);
            setSelectedService(data.getAllPackages.packages[0].vendorPackagesServices);
        },
    });

    const [addVendorService, { loading: addLoading }] = useMutation(ADD_VENDOR_SERVICE, {
        onCompleted: () => {
            props.setGetRefresh(!props.getRefresh);
            let msg;
            if (props.Services !== "Add") {
                msg = 'Service Updated Successfully';
            } else {
                msg = 'Service Added Successfully';
            }

            dispatch(showAlert({
                message: msg,
                messageType: 'success',
                showAlertMessage: true
            }));
            if (typeof (props.GetpackageName) !== 'undefined') {
                props.GetpackageName();
            }
        }
    });

    useEffect(() => {
        if (props.Services !== "Add") {
            getVendorPackage({ variables: { packageId: props.selectServiceId } })
        }

    }, [props.Services, props.selectServiceId, getVendorPackage]);

    const intl = useIntl();
    const runningUnitTest = process.env.JEST_WORKER_ID !== undefined;

    return (
        <MBox mt={-1} className="addServicesPage">
            {/* <BreadcrumbsNavigation/> */}
            {/* {
                   props.Services == "Add" ||  url == 'add-services' ?
                    <BreadcrumbsNavigationCustom
                    isloading={false}
                    breadcrumbs={
                                    [
                                        {
                                            type: "services",
                                            link: "/vendor/services"
                                        },
                                        {
                                            type: "Add services",
                                            link: "/"
                                        },
                                    
                                    ]
                                }
                    />
                    :
                    <BreadcrumbsNavigationCustom
                    isloading={false}
                    breadcrumbs={
                                    [
                                        {
                                            type: "services",
                                            link: "/vendor/services"
                                        },
                                        {
                                            type: "Update services",
                                            link: "/"
                                        },
                                    
                                    ]
                                }
                    />


                } */}

            <MGrid container>
                <MGrid item xs={12}>

                </MGrid>

                <MGrid item xs={12}>
                    <MAppBar className='drawerAppBar'>
                        <MToolbar>
                            <MBox display="inline-block">
                                <MIconButton onClick={() => props.setAddServiceOpen(false)}>
                                    <ArrowBackIcon />
                                </MIconButton>
                            </MBox>
                            <MBox width="100%" fontSize={18} fontFamily="Poppins">
                                {props.Services !== "Add" ? <FormattedMessage id="update.services"
                                /> : <FormattedMessage id="add.service" />}
                            </MBox>
                        </MToolbar>
                    </MAppBar>
                    <MBox color="palette.secondary" className={classes.formBox} >

                        <MFormik
                            enableReinitialize="true"

                            initialValues={{
                                name: vendorPackages && vendorPackages.name,
                                desc: vendorPackages && vendorPackages.description
                            }}
                            validationSchema={Yup.object({
                                name: Yup.string().required(intl.formatMessage({ id: "name.required.field" })),
                                desc: Yup.string().required(intl.formatMessage({ id: "description.required.field" })),
                            })}
                            onSubmit={(values: any) => {
                                props.setAddServiceOpen(false);
                                const data: any = {
                                    name: values.name,
                                    description: values.desc,
                                    orgId: parseInt(SelectedOrgId),
                                    role: userRole === 'ROLE_VENDOR' ? 'vendor' : 'linguist'
                                };
                                if (props.selectServiceId && props.selectServiceId) {
                                    data.packageId = props.selectServiceId;
                                }

                                const dataItems: any = [];
                                selectedService.forEach((item: any, index: number) => {
                                    dataItems.push({ serviceId: parseInt(item.id), priority: index + 1 })
                                });
                                data.serviceData = dataItems;
                                addVendorService({
                                    variables: data
                                });

                            }}>
                            {() => {
                                return (
                                    <MBox py={5} px={7.5}>
                                        <MForm>
                                            <MBox>
                                                {/* <MBox mb={1}>
                                              <FormattedMessage id="name"/></MFormLabel>
                                            </MBox> */}
                                                <MBox mb={1}>
                                                    <MFormLabel className={classes.formLabel} component="legend"
                                                        required><FormattedMessage id="name" /></MFormLabel>
                                                </MBox>

                                                <MTextField
                                                    name="name"
                                                    placeholder={intl.formatMessage({ id: "name" })}
                                                    variant="outlined"
                                                    color="secondary"
                                                    size="small"
                                                    disabled={false}
                                                    fullWidth
                                                    className={classes.inputWidth}
                                                />
                                            </MBox>
                                            <MBox my={4.5}>
                                                <MBox mb={1}>
                                                    <MFormLabel className={classes.formLabel}
                                                        component="legend" required><FormattedMessage id="description" /></MFormLabel>
                                                </MBox>
                                                <MTextField
                                                    name="desc"
                                                    variant="outlined"
                                                    color="secondary"
                                                    size="small"
                                                    className={[classes.inputWidth]}
                                                    placeholder={intl.formatMessage({ id: "type.words" }) + "..."}
                                                    rowsMax="4"
                                                    multiline
                                                    rows="3"
                                                />
                                            </MBox>
                                            <ServiceDndList selectedService={selectedService} setSelectedService={setSelectedService} />
                                            <MBox display="flex" mt={3} mb={12} >
                                                <MBox className={classes.footerBottom}>

                                                    <MLink
                                                        href="#"
                                                        color='#3a3a3a'
                                                        component='button'

                                                        sx={{ marginRight: 1 }}
                                                        onClick={() => props.setAddServiceOpen(false)}
                                                    >
                                                        <FormattedMessage id="cancel" />
                                                    </MLink>
                                                    <MButton
                                                        disabled={runningUnitTest ? false : addLoading || (selectedService && selectedService.length < 1)}
                                                        className="btnMedium"
                                                        color="primary"
                                                        variant="contained"
                                                        type="submit"
                                                    >
                                                        {addLoading && <MCircularProgress color="secondary" size={24} />}
                                                        {(props.Services !== "Add") ? 'Update' : <FormattedMessage id="create" />}
                                                    </MButton>


                                                </MBox>
                                            </MBox>
                                        </MForm>
                                    </MBox>
                                )
                            }}
                        </MFormik>

                    </MBox>
                </MGrid>
            </MGrid>
        </MBox>
    )
}

