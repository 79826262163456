import React, { Fragment } from 'react'

export function MCheckIcon() {
    return (
        <Fragment>

            <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.5013 1.83333L5.5013 11.8333L0.917969 7.24999L2.09297 6.07499L5.5013 9.47499L14.3263 0.658325L15.5013 1.83333Z" fill="#16C68E" />
            </svg>

        </Fragment>
    )
}
