
import { put, takeEvery } from "redux-saga/effects";

import { gql } from 'apollo-boost';
import { client } from "src/app/hooks";
import { GET_LSP_FETCH } from "./SuppliersActionTypes";

const query = gql`
query getClientVendors($name:String,$page:ID,$orgId: ID, $service:String, $role:String, $resourceId:String) {
    getClientVendors(name: $name, page:$page, orgId: $orgId, service: $service, role:$role, resourceId:$resourceId) {
        total
        clientVendors{
            id
            companyName
            role
            profilePicture
            resourceId
            totalProject
            userProfileId
            vendor_url_slug
            rating
            isVendorProfilePublic
            isLinguistProfilePublic
            role
            doneJobCount
            contact_email
            contact_person
            ssoUserName
            services{
                name
            }
        }
    }
}
    `;

function* getLspFetch(variables: any): any {
    try {

        const lspList = yield client.query({ query, variables, fetchPolicy: "no-cache" });



        yield put({ type: 'GET_LSP_SUCCESS', response: lspList.data.getClientVendors });

    } catch (error) {
        console.log('error', error);
        // yield put({ type: 'API_CALL_FAILURE', error });
    }
}

export default function* suppliersSaga() {
    yield takeEvery(GET_LSP_FETCH, getLspFetch);
    //   yield takeEvery(GET_RECENT_PROJECT_FETCH, getProjectFetch);
}