
import { put, takeEvery } from "redux-saga/effects";
import { GET_PROJECT_FETCH, GET_RECENT_PROJECT_FETCH } from "./ProjectsActionTypes";
import { gql } from 'apollo-boost';
import { client } from "src/app/hooks";

const query = gql`
    query Organizations($id: ID,$projectId: ID, $name: String,$customerName: String, $sourceLanguage: String,$page: ID ,$createdDate: String,,$batchName: String,$tagId:[ID]){
      organizations(id: $id,projectId: $projectId, name: $name ,customerName: $customerName, sourceLanguage: $sourceLanguage,page: $page,
          createdDate: $createdDate,tagId:$tagId,batchName:$batchName) {
          totalProjectCount
          projects {
              id
              name
              projectId
              createdBy
              createdById
              createdAt
              status
              statusLabel
              customerName
              openJobs
              doneJobs
              sl {
                  code
                  id
                  name
              }targetLang {
              code
              id
              name
          }

          }
      }
  }
    `;

function* getProjectFetch(variables: any): any {
  try {
    const projectList = yield client.query({ query, variables, fetchPolicy: "no-cache" });

    if (variables.type == 'GET_RECENT_PROJECT_FETCH') {
      yield put({ type: 'GET_RECENT_PROJECT_SUCCESS', response: projectList.data.organizations });
    } else {
      yield put({ type: 'GET_PROJECT_SUCCESS', response: projectList.data.organizations });
    }
  } catch (error) {
    console.log('error', error);
    // yield put({ type: 'API_CALL_FAILURE', error });
  }
}

export default function* projectSaga() {
  yield takeEvery(GET_PROJECT_FETCH, getProjectFetch);
  yield takeEvery(GET_RECENT_PROJECT_FETCH, getProjectFetch);
}