import React, { Fragment } from 'react'

export  function MergeTmIcon() {
  return (
    <Fragment>

          <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.3334 12.3332C12.9445 12.3332 12.566 12.295 12.198 12.2186C11.8299 12.1422 11.4723 12.0346 11.125 11.8957C11.9445 11.1457 12.5868 10.3089 13.0521 9.38525C13.5174 8.46164 13.75 7.49984 13.75 6.49984C13.75 5.49984 13.5174 4.53803 13.0521 3.61442C12.5868 2.69081 11.9445 1.854 11.125 1.104C11.4723 0.965115 11.8299 0.857476 12.198 0.781087C12.566 0.704698 12.9445 0.666504 13.3334 0.666504C14.9584 0.666504 16.3368 1.23248 17.4688 2.36442C18.6007 3.49637 19.1667 4.87484 19.1667 6.49984C19.1667 8.12484 18.6007 9.50331 17.4688 10.6353C16.3368 11.7672 14.9584 12.3332 13.3334 12.3332ZM10 11.2915C9.23615 10.7637 8.62851 10.0832 8.17712 9.24984C7.72574 8.4165 7.50004 7.49984 7.50004 6.49984C7.50004 5.49984 7.72574 4.58317 8.17712 3.74984C8.62851 2.9165 9.23615 2.23595 10 1.70817C10.7639 2.23595 11.3716 2.9165 11.823 3.74984C12.2743 4.58317 12.5 5.49984 12.5 6.49984C12.5 7.49984 12.2743 8.4165 11.823 9.24984C11.3716 10.0832 10.7639 10.7637 10 11.2915ZM6.66671 12.3332C5.04171 12.3332 3.66324 11.7672 2.53129 10.6353C1.39935 9.50331 0.833374 8.12484 0.833374 6.49984C0.833374 4.87484 1.39935 3.49637 2.53129 2.36442C3.66324 1.23248 5.04171 0.666504 6.66671 0.666504C7.0556 0.666504 7.43407 0.704698 7.80212 0.781087C8.17018 0.857476 8.52782 0.965115 8.87504 1.104C8.0556 1.854 7.41324 2.69081 6.94796 3.61442C6.48268 4.53803 6.25004 5.49984 6.25004 6.49984C6.25004 7.59706 6.47574 8.61442 6.92712 9.55192C7.37851 10.4894 8.00004 11.2846 8.79171 11.9373C8.45837 12.0623 8.11462 12.1596 7.76046 12.229C7.40629 12.2984 7.04171 12.3332 6.66671 12.3332Z" fill="white" />
          </svg>

    </Fragment>
  )
}
