/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from "react-intl";
import { MBox, MButton, MCircularProgress, MDivider, MLink, MSkeleton } from 'src/app/material-ui';

import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import { loadStripe } from '@stripe/stripe-js';
import { useStripe, useElements, PaymentElement, Elements } from '@stripe/react-stripe-js';
import theme from 'src/app/material-ui/ThemeConfig';

const GET_ALL_GATEWAYS = gql`
    query getActivePaymentGateways {
        getActivePaymentGateways {
            code
            name
            clientId
        }
    }
`;

const GET_STRIPE_SECRET = gql`
    query getStripeSecret($isNewCard:Int,$amt: Float) {
        getStripeSecret(isNewCard:$isNewCard,amt: $amt) {
            clientSecret
        }
    }
`;


export default function AddCardForm(props: any) {

    const [stripePromise, setStripePromise]: any = useState();
    // const selectedOrgId = window.localStorage.getItem('selectedOrgId');
    const [stripeForm, setStripeForm]: any = useState();
    const [options, setOptions]: any = useState();

    const [errorMessage, setErrorMessage]: any = useState('');
    // const [dialog, setDialog] = useState(false);

    const StripeForm = () => {
        const stripe = useStripe();
        const elements: any = useElements();
        const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);

        const handleSubmit = async (event: any) => {
            // We don't want to let default form submission happen here,
            // which would refresh the page.
            event.preventDefault();

            if (!stripe || !elements) {
                // Stripe.js has not yet loaded.
                // Make sure to disable form submission until Stripe.js has loaded.
                return;
            }

            setIsFormSubmitted(true);

            const result: any = await stripe.confirmPayment({
                //`Elements` instance that was used to create the Payment Element
                elements,
                confirmParams: {
                    return_url: (window.location.origin + '/my-account#manage-credit-cards'),
                },
                redirect: 'if_required'
            });

            if (result.error.message) {
                // This point will only be reached if there is an immediate error when
                // confirming the payment. Show error to your customer (for example, payment
                // details incomplete)
                setErrorMessage(result.error.message);

                setIsFormSubmitted(false);
            } else {
                props.setOnlineTransactionDetails(result);
                props.setIsNewCard(1);
            }
        };

        return <>
            <PaymentElement />
            {errorMessage && setErrorMessage}

            <MBox display='flex' color='#6B778C' mt={3.2} mb={5}>
                <MBox>
                    <FormattedMessage id='Fund amount' />: <span>${props.fundsAmt}</span>
                </MBox>
                <MBox sx={{ paddingLeft: 5 }}>
                    <MDivider sx={{ margin: 'auto 8px', height: 20, width: 2 }} orientation="vertical" flexItem />
                </MBox>
                <MBox>
                    <FormattedMessage id='card.fee' />: <span>${props.fee}</span>
                </MBox>
                <MBox sx={{ paddingLeft: 5 }}>
                    <MDivider sx={{ margin: 'auto 8px', height: 20, width: 2 }} orientation="vertical" flexItem />
                </MBox>
                <MBox>
                    <FormattedMessage id='total.payable.amount' />: <span>${props.amt}</span>
                </MBox>
            </MBox>

            <MBox display='flex' color justifyContent='flex-end'>
                <MLink href="#" color='#3a3a3a' component='button' onClick={() => props.setAddFundsDrawer(false)}
                    sx={{ fontSize: 14 }}>
                    <FormattedMessage id='cancel' /></MLink>
                {process.env.REACT_APP_ENVIRONMENT != 'production' &&
                    <MLink underline='none' href="#" color='#3a3a3a' component='button' sx={{ fontSize: 14, marginLeft: 1 }}>
                        <MButton type="button" onClick={handleSubmit} disabled={isFormSubmitted}
                            sx={{ backgroundColor: theme.palette.secondary.dark }} variant="contained">
                            {isFormSubmitted && <MCircularProgress color="secondary" size={24} />} <FormattedMessage
                                id='pay.now' />
                        </MButton>
                    </MLink>
                }
            </MBox>

        </>;
    };

    const [getAllGateways] = useLazyQuery(GET_ALL_GATEWAYS, {
        fetchPolicy: 'no-cache',
        onCompleted: (data: any) => {
            setStripePromise(loadStripe(data.getActivePaymentGateways.find((item: any) => item.code == 'stripe').clientId));
        }
    });

    const [getStripeSecret] = useLazyQuery(GET_STRIPE_SECRET, {
        variables: { isNewCard: props.isNew === 1 ? 1 : 0, amt: props.amt ? props.amt : 0 },
        fetchPolicy: 'no-cache',
        onCompleted: (data: any) => {
            setOptions({
                // passing the client secret obtained from the server
                clientSecret: data.getStripeSecret.clientSecret,
            })
        }
    });


    useEffect(() => {
        getAllGateways();
        setStripeForm(<StripeForm />);
    }, []);

    useEffect(() => {
        if (stripePromise) {
            getStripeSecret();
        }
    }, [stripePromise]);

    return (
        <MBox>
            <MBox mb={3}>
                {stripePromise && options && stripeForm ?
                    <Elements stripe={stripePromise} options={options}>
                        {stripeForm}
                    </Elements> :
                    <>
                        <MBox className="paletteTextSecondaryColor" pb={2} display="flex" >
                            <MBox width={'50%'} mr={1} border="1px solid #e6e6e6" p={1} sx={{ borderRadius: '5px', background: '#fff' }}>
                                <MSkeleton variant="text" animation="wave" width={'25px'} height={15} sx={{ fontSize: '1rem', marginBottom: '5px', background: '#f3f3f485', borderRadius: '5px' }} />
                                <MSkeleton animation="wave" width={'120px'} height={15} sx={{ background: '#f3f3f485', borderRadius: '5px' }} />
                            </MBox>
                            <MBox width={'50%'} border="1px solid #e6e6e6" p={1} sx={{ borderRadius: '5px', background: '#fff' }}>
                                <MSkeleton variant="text" animation="wave" width={'20px'} height={15} sx={{ fontSize: '1rem', marginBottom: '5px', background: '#f3f3f485', borderRadius: '5px' }} />
                                <MSkeleton animation="wave" width={'120px'} height={15} mr={1} sx={{ background: '#f3f3f485', borderRadius: '5px' }} />
                            </MBox>
                        </MBox>
                        <MBox className="paletteTextSecondaryColor" display="block" >
                            <MSkeleton variant="text" animation="wave" width={'50px'} height={10} sx={{ fontSize: '1rem', marginBottom: '5px', background: '#f3f3f485', borderRadius: '5px' }} />
                            <MBox width={'100%'} height={40} border="1px solid #e6e6e6" p={1} sx={{ borderRadius: '5px', background: '#fff' }}>
                                <MSkeleton animation="wave" width={'120px'} height={15} sx={{ background: '#f3f3f485', borderRadius: '5px' }} />
                            </MBox>
                        </MBox>
                        <MBox className="paletteTextSecondaryColor" pb={2} display="flex" background='#fff' mt={2} >
                            <MBox width={'50%'} mr={1}>
                                <MSkeleton variant="text" animation="wave" width={'25px'} height={10} sx={{ fontSize: '1rem', marginBottom: '5px', background: '#f3f3f485', borderRadius: '5px' }} />
                                <MBox width={'100%'} border="1px solid #e6e6e6" p={1} sx={{ borderRadius: '5px', background: '#fff' }} height={40}>
                                    <MSkeleton animation="wave" width={'120px'} height={15} sx={{ background: '#f3f3f485', borderRadius: '5px' }} />
                                </MBox>
                            </MBox>
                            <MBox width={'50%'}>
                                <MSkeleton variant="text" animation="wave" width={'25px'} height={10} sx={{ fontSize: '1rem', marginBottom: '5px', background: '#f3f3f485', borderRadius: '5px' }} />
                                <MBox mr={1} border="1px solid #e6e6e6" p={1} sx={{ borderRadius: '5px', background: '#fff' }} height={40}>
                                    <MSkeleton animation="wave" width={'120px'} height={15} sx={{ background: '#f3f3f485', borderRadius: '5px' }} />
                                </MBox>
                            </MBox>
                        </MBox>
                        <MBox className="paletteTextSecondaryColor" pb={2} display="flex" background='#fff' mt={2} >
                            <MSkeleton variant="text" animation="wave" width={'50%'} height={15} sx={{ fontSize: '1rem', marginBottom: '5px', background: '#f3f3f485', borderRadius: '5px' }} />
                        </MBox>

                        <MBox mb={2} flex justifyContent="left" >
                            <MBox display='flex' justifyContent='flex-end' alignItems='center'>
                                <MBox mr={1}>
                                    <MSkeleton width="78px" height="40px" animation="wave" sx={{ background: '#f3f3f485', borderRadius: '5px' }} />
                                </MBox>
                                <MBox ml={1}>
                                    <MSkeleton width="78px" height="40px" animation="wave" sx={{ background: '#f3f3f485', borderRadius: '5px' }} />
                                </MBox>
                            </MBox>
                        </MBox>
                    </>}
            </MBox>
        </MBox>

    )
}