import React, { Fragment } from 'react'

export function MChinessSimplified() {
    return (
        <Fragment>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.41418 0.189569C9.02541 0.426452 8.85318 0.866139 8.85318 1.62139V2.17905H4.94024C0.635494 2.17905 0.459614 2.20156 0.154664 2.79101C-0.0515547 3.18974 -0.0515547 3.46112 0.154664 3.85985C0.474835 4.47868 0.389971 4.47181 7.6509 4.47181C13.8899 4.47181 14.2745 4.48244 14.2738 4.65419C14.2725 4.9562 13.8727 6.52893 13.5815 7.37673C12.9812 9.12495 11.6737 11.3536 10.4815 12.6612L9.91899 13.2781L9.46433 12.769C8.28071 11.4437 6.9872 9.32306 6.45122 7.82923C6.0734 6.77613 5.41085 6.40345 4.68189 6.83386C4.02257 7.22322 4.01006 7.81339 4.63039 9.24647C5.39219 11.0062 6.40952 12.6249 7.68812 14.1118C7.98379 14.4557 8.22618 14.7632 8.2267 14.7951C8.22827 14.8916 6.34405 16.0985 5.65126 16.4447C4.67647 16.9319 3.58741 17.3447 2.48938 17.6434C1.31608 17.9624 1.03271 18.2047 1.03625 18.8855C1.0397 19.5248 1.49759 20 2.11009 20C2.49313 20 4.07314 19.522 5.29335 19.0369C6.56715 18.5306 7.84044 17.8233 9.04991 16.9504C9.64834 16.5186 9.96539 16.3493 10.0546 16.414C11.1381 17.2014 12.6341 18.1311 13.4769 18.541C14.6945 19.1329 16.8504 19.8773 17.6074 19.967C18.0959 20.0248 18.1451 20.0077 18.4932 19.6597C18.7936 19.3595 18.8618 19.2134 18.8618 18.8699C18.8618 18.2107 18.5664 17.9572 17.4543 17.6617C16.249 17.3416 15.379 17.0119 14.2745 16.4571C13.1678 15.901 11.6681 14.9114 11.6681 14.7372C11.6681 14.6652 11.7437 14.5436 11.8361 14.467C12.1459 14.2099 13.1972 12.8692 13.7488 12.0275C14.8968 10.276 15.9198 7.78953 16.2999 5.82662C16.3942 5.33941 16.4941 4.83521 16.5217 4.70629C16.5704 4.47962 16.612 4.47181 17.7724 4.47181C19.1508 4.47181 19.5969 4.34018 19.8453 3.85985C20.0516 3.46112 20.0516 3.18974 19.8453 2.79101C19.5404 2.20156 19.3645 2.17905 15.0598 2.17905H11.1468V1.60253C11.1468 0.86343 10.9342 0.360275 10.5346 0.153719C10.1181 -0.0615916 9.80994 -0.0516913 9.41418 0.189569Z" fill="#6B778C" />
            </svg>
        </Fragment>
    )
}
