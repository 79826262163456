/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MBox,
  MButton,
  MTooltip,
  MCircularProgress,
  MTextField,
  MFormik,
  MForm,
  MTableContainer,
  MTable,
  MTableHead,
  MTableCell,
  MTableRow,
  MTableBody,
  MPaper,
  MLink,
  MDrawer,
  MAppBar,
  MToolbar,
  MIconButton,
  MRadio,
  MDialog,
  MDialogTitle,
  MDialogContent,
  MDialogActions,
} from "src/app/material-ui";

import useStyles from "./ModalStyles";
import * as Yup from "yup";
import { gql } from "apollo-boost";
import { showAlert } from "src/app/store";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { FormattedMessage, useIntl } from "react-intl";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckPermissions from "src/app/hooks/permissions/CheckPermissions";
import AddMatchRates from "../../match-rates/AddMatchRates";
import EditMatchRates from "../../match-rates/EditMatchRates";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";



const CREATE_MATCH_RATE = gql`
  mutation createMatchRate(
    $id: Int
    $orgId: Int
    $name: String
    $new: Float
    $fifty_seventyFour: Float
    $seventyFive_ninetyEight: Float
    $eightyFive_NinetyFour: Float
    $ninetyFive_NinetyNine: Float
    $hundred: Float
    $repetition: Float
    $crossFileRepetitions: Float
  ) {
    createMatchRate(
      id: $id
      orgId: $orgId
      name: $name
      new: $new
      fifty_seventyFour: $fifty_seventyFour
      seventyFive_ninetyEight: $seventyFive_ninetyEight
      eightyFive_NinetyFour: $eightyFive_NinetyFour
      ninetyFive_NinetyNine: $ninetyFive_NinetyNine
      hundred: $hundred
      repetition: $repetition
      crossFileRepetitions: $crossFileRepetitions
    )
  }
`;

const DELETED_MATCH_RATE = gql`
  mutation deleteMatchRate($matchRateId: Int) {
    deleteMatchRate(matchRateId: $matchRateId)
  }
`;
const VONDOR_MATCH_RATE = gql`
  query getMatchRate($page: ID, $name: String, $orgId: Int) {
    getMatchRate(page: $page, name: $name, orgId: $orgId) {
      MatchRate {
        id
        name
        new
        fifty_seventyFour
        seventyFive_ninetyEight
        eightyFive_NinetyFour
        ninetyFive_NinetyNine
        hundred
        repetition
        crossFileRepetitions
      }
      totalMatchRate
    }
  }
`;


export default function AddMatchRateModal({
  modalOpen,
  setModalOpen,
  selectedService,
  servicesMatchRate,
  setServicesMatchRate,
  serviceIndex,
  setFieldValue,
  isEdit,
  jobAnalysis,
  jobQuoteDetails,
  draftDetail,
}: any) {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const loading = false;
  const [matchRatesModal, setMatchRatesModal] = useState(0);
  const [rows, setRows]: any = useState([]);
  const [matchRates, setMatchRates] = useState([]);
  const [selectedMatchRate, setSelectedMatchRate]: any = useState(null);
  const [targetLanguageNames, setTargetLanguageNames]: any = useState("");
  const [percentRates, setPercentRates]: any = useState([]);
  const [words, setWords]: any = useState([]);
  const [zeroWordError, setZeroWordError]: any = useState(0);
  const selectedOrgId = useSelector((store: any) => store.orgs.selectedOrgId);
  const organizations = useSelector((store: any) => store.orgs.organizations);
  const [mathchRateCreate, setMatchRateCreate] = useState(false);
  const [mathchRateList, setMatchRateList] = useState(false);
  const [mathchRateView, setMatchRateView] = useState(false);
  const [mathchRateEdit, setMatchRateEdit] = useState(false);
  const [mathchRateDelete, setMatchRateDelete] = useState(false);
  const [openMatchRateDrawer, setOpenMatchRateDrawer]: any = useState(false);
  const [openEditMatchRateDrawer, setOpenEditMatchRateDrawer]: any = useState(false);
  const [matchRateId, setMatchRateId]: any = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [vendorMatchRate, setVendorMatchRate]: any = React.useState([]);
  const [page, setPage]: any = useState(1);
  const [totalPages, setTotalPages]: any = useState(0);
  // const [matchRateName, setMatchRateName]: any = useState("");
  const [selectedMatchRateId, setSelectedMatchRateId] = useState(0);
  const [selectedMatchRateData, setSelectedMatchRateData] = useState(false);
  const [isFullWidth, setIsFullWidth] = React.useState(false);
  const [matchRateUpdateSuccessfully, setMatchRateUpdateSuccessfully]: any = React.useState();
  const [matchRateAddedSuccessfully, setMatchRateAddedSuccessfully]: any = React.useState();


  const unitSelectArr = [
    { id: 1, name: intl.formatMessage({ id: "words" }) },
    { id: 2, name: intl.formatMessage({ id: "minutes" }) },
    { id: 3, name: intl.formatMessage({ id: "pages" }) },
    { id: 4, name: intl.formatMessage({ id: "chargeable.words" }) },
  ];

  const handleModalClose = (index: any, setFieldValue: any) => {
    setFieldValue(`serviceForms.${index}.unit`, { id: 1, name: "Words" });
    setModalOpen(false);
  };

  const [vendorMatchRates, { loading: MatchRateLoading }] = useLazyQuery(
    VONDOR_MATCH_RATE,
    {
      variables: {
        page: page,
        //     name: matchRateName,
        orgId: selectedOrgId,
      },
      fetchPolicy: "no-cache",
      onCompleted: (data: any) => {
        setMatchRates(data && data.getMatchRate.MatchRate);

        if (data && data.getMatchRate.MatchRate.length == 1) {
          setSelectedMatchRate(data && data.getMatchRate.MatchRate[0]);
        }
        setTotalPages(Math.ceil(data.getMatchRate.totalMatchRate / 10));
        if (page > 1 && data.getMatchRate.MatchRate.length == 0) {
          setPage(page - 1);
        }
      },
    } as any
  );

  const [createMatchRate, { loading: createLoading }] = useMutation(
    CREATE_MATCH_RATE,
    {
      fetchPolicy: "no-cache",
      onCompleted: () => {
        vendorMatchRates();
        setMatchRatesModal(0);
        dispatch(showAlert({
          message: matchRateId ? String(matchRateUpdateSuccessfully) : String(matchRateAddedSuccessfully),
          messageType: 'success',
          showAlertMessage: true,
        }));
      }, onError: ({ graphQLErrors }: any) => {
        dispatch(showAlert({
          message: graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message && graphQLErrors[0].message,
          messageType: 'error',
          showAlertMessage: true

        }));
      }
    }
  );

  const [deleteMatchRate, { loading: deleteLoading }] = useMutation(
    DELETED_MATCH_RATE,
    {
      onCompleted: () => {
        vendorMatchRates();
        dispatch(
          showAlert({
            message: "Match Rate Delete ",
            messageType: "success",
            showAlertMessage: true,
          })
        );
        handleClose();
      },
    }
  );

  useEffect(() => {
    setMatchRateCreate(
      CheckPermissions("ROLE_MATCH_RATE_CREATE", selectedOrgId, organizations)
    );
    setMatchRateList(
      CheckPermissions("ROLE_MATCH_RATE_LIST", selectedOrgId, organizations)
    );
    setMatchRateView(
      CheckPermissions("ROLE_MATCH_RATE_VIEW", selectedOrgId, organizations)
    );
    setMatchRateEdit(
      CheckPermissions("ROLE_MATCH_RATE_EDIT", selectedOrgId, organizations)
    );
    setMatchRateDelete(
      CheckPermissions("ROLE_MATCH_RATE_DELETE", selectedOrgId, organizations)
    );
  }, [selectedOrgId, organizations]);

  useEffect(() => {
    if (openMatchRateDrawer || openEditMatchRateDrawer) {
      setIsFullWidth(true);
    } else {
      setIsFullWidth(false);
    }
  }, [openMatchRateDrawer, openEditMatchRateDrawer]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const dialogCloseYes = () => {
    deleteMatchRate({
      variables: {
        matchRateId: matchRateId,
      },
    });
  };

  useEffect(() => {
    vendorMatchRates();
  }, [vendorMatchRates, page, selectedOrgId, matchRatesModal]);

  useEffect(() => {
    if (draftDetail && matchRates.length > 0 && modalOpen) {
      const selectedResult =
        draftDetail.quoteServices[serviceIndex] &&
          draftDetail.quoteServices[serviceIndex].matchRate
          ? matchRates.find(
            (el: any) =>
              el.id === draftDetail.quoteServices[serviceIndex].matchRate.id
          )
          : null;
      setSelectedMatchRate(selectedResult);

      if (
        draftDetail.quoteServices[serviceIndex] &&
        draftDetail.quoteServices[serviceIndex] &&
        draftDetail.quoteServices[serviceIndex].matchRateDetails
      ) {
        setWords([
          JSON.parse(draftDetail.quoteServices[serviceIndex].matchRateDetails)[
            "new"
          ].word,
          JSON.parse(draftDetail.quoteServices[serviceIndex].matchRateDetails)[
            "fifty_seventyFour"
          ].word,
          JSON.parse(draftDetail.quoteServices[serviceIndex].matchRateDetails)[
            "seventyFive_ninetyEight"
          ].word,
          JSON.parse(draftDetail.quoteServices[serviceIndex].matchRateDetails)[
            "eightyFive_NinetyFour"
          ].word,
          JSON.parse(draftDetail.quoteServices[serviceIndex].matchRateDetails)[
            "ninetyFive_NinetyNine"
          ].word,
          JSON.parse(draftDetail.quoteServices[serviceIndex].matchRateDetails)[
            "hundred"
          ].word,
          JSON.parse(draftDetail.quoteServices[serviceIndex].matchRateDetails)[
            "repetition"
          ].word,
          JSON.parse(draftDetail.quoteServices[serviceIndex].matchRateDetails)[
            "crossFileRepetitions"
          ].word,
        ]);
      } else {
        setWords(["", "", "", "", "", "", "", ""]);
      }
    }
  }, [draftDetail, matchRates, modalOpen]);

  useEffect(() => {
    if (selectedService && matchRates.length > 0 && modalOpen) {
      const selectedResult =
        selectedService && selectedService.matchRateId
          ? matchRates.find((el: any) => el.id === selectedService.matchRateId)
          : null;
      setSelectedMatchRate(selectedResult);

      if (selectedService && selectedService.matchRateDetails) {
        setWords([
          JSON.parse(selectedService.matchRateDetails)["new"].word,
          JSON.parse(selectedService.matchRateDetails)["fifty_seventyFour"]
            .word,
          JSON.parse(selectedService.matchRateDetails)[
            "seventyFive_ninetyEight"
          ].word,
          JSON.parse(selectedService.matchRateDetails)["eightyFive_NinetyFour"]
            .word,
          JSON.parse(selectedService.matchRateDetails)["ninetyFive_NinetyNine"]
            .word,
          JSON.parse(selectedService.matchRateDetails)["hundred"].word,
          JSON.parse(selectedService.matchRateDetails)["repetition"].word,
          JSON.parse(selectedService.matchRateDetails)["crossFileRepetitions"]
            .word,
        ]);
        //
        // setPercentRates([
        //     JSON.parse(selectedService.matchRateDetails)['new'].ratePercentage,
        //     JSON.parse(selectedService.matchRateDetails)['fifty_seventyFour'].ratePercentage,
        //     JSON.parse(selectedService.matchRateDetails)['seventyFive_ninetyEight'].ratePercentage,
        //     JSON.parse(selectedService.matchRateDetails)['eightyFive_NinetyFour'].ratePercentage,
        //     JSON.parse(selectedService.matchRateDetails)['ninetyFive_NinetyNine'].ratePercentage,
        //     JSON.parse(selectedService.matchRateDetails)['hundred'].ratePercentage,
        //     JSON.parse(selectedService.matchRateDetails)['repetition'].ratePercentage,
        //     JSON.parse(selectedService.matchRateDetails)['crossFileRepetitions'].ratePercentage
        // ]);
      } else {
        // setWords([
        //     '',
        //     '',
        //     '',
        //     '',
        //     '',
        //     '',
        //     '',
        //     ''
        // ]);
      }
    }
  }, [selectedService, matchRates, modalOpen]);

  useEffect(() => {
    if (
      isEdit == 0 &&
      draftDetail &&
      matchRates.length > 0 &&
      selectedService &&
      jobQuoteDetails &&
      jobQuoteDetails.moduleServices.length > 0
    ) {
      const newData: any = [];
      if (
        draftDetail &&
        draftDetail.quoteServices &&
        draftDetail.quoteServices.length > 0
      ) {
        draftDetail.quoteServices.forEach(
          (singleService: any, index: number) => {
            if (singleService.matchRate) {
              const selectedMatchRate: any = matchRates.find(
                (el: any) => el.id === singleService.matchRate.id
              );
              const singleSelectedSer = {
                service: jobQuoteDetails.moduleServices.find(
                  (ser: any) => ser.name === singleService.serviceName
                ),
                targetLanguages: singleService.targetLanguages,
                number: singleService.words,
                unit: unitSelectArr.find(
                  (un: any) =>
                    un.name.toLowerCase() === singleService.unit.toLowerCase()
                ),
                price: Number(singleService.unitPrice),
                subTotal: Number(singleService.subTotal),
              };
              const formValues: any = {
                tag: "",
                percentOfRate: [
                  singleService.matchRateDetails
                    ? JSON.parse(singleService.matchRateDetails)["new"]
                      .ratePercentage
                    : 0,
                  singleService.matchRateDetails
                    ? JSON.parse(singleService.matchRateDetails)[
                      "fifty_seventyFour"
                    ].ratePercentage
                    : 0,
                  singleService.matchRateDetails
                    ? JSON.parse(singleService.matchRateDetails)[
                      "seventyFive_ninetyEight"
                    ].ratePercentage
                    : 0,
                  singleService.matchRateDetails
                    ? JSON.parse(singleService.matchRateDetails)[
                      "eightyFive_NinetyFour"
                    ].ratePercentage
                    : 0,
                  singleService.matchRateDetails
                    ? JSON.parse(singleService.matchRateDetails)[
                      "ninetyFive_NinetyNine"
                    ].ratePercentage
                    : 0,
                  singleService.matchRateDetails
                    ? JSON.parse(singleService.matchRateDetails)["hundred"]
                      .ratePercentage
                    : 0,
                  singleService.matchRateDetails
                    ? JSON.parse(singleService.matchRateDetails)["repetition"]
                      .ratePercentage
                    : 0,
                  singleService.matchRateDetails
                    ? JSON.parse(singleService.matchRateDetails)[
                      "crossFileRepetitions"
                    ].ratePercentage
                    : 0,
                ],
                words: [
                  singleService.matchRateDetails
                    ? JSON.parse(singleService.matchRateDetails)["new"].word
                    : 0,
                  singleService.matchRateDetails
                    ? JSON.parse(singleService.matchRateDetails)[
                      "fifty_seventyFour"
                    ].word
                    : 0,
                  singleService.matchRateDetails
                    ? JSON.parse(singleService.matchRateDetails)[
                      "seventyFive_ninetyEight"
                    ].word
                    : 0,
                  singleService.matchRateDetails
                    ? JSON.parse(singleService.matchRateDetails)[
                      "eightyFive_NinetyFour"
                    ].word
                    : 0,
                  singleService.matchRateDetails
                    ? JSON.parse(singleService.matchRateDetails)[
                      "ninetyFive_NinetyNine"
                    ].word
                    : 0,
                  singleService.matchRateDetails
                    ? JSON.parse(singleService.matchRateDetails)["hundred"].word
                    : 0,
                  singleService.matchRateDetails
                    ? JSON.parse(singleService.matchRateDetails)["repetition"]
                      .word
                    : 0,
                  singleService.matchRateDetails
                    ? JSON.parse(singleService.matchRateDetails)[
                      "crossFileRepetitions"
                    ].word
                    : 0,
                ],
              };
              newData.push({
                index: index,
                values: formValues,
                matchRate: selectedMatchRate,
                selectedService: singleSelectedSer,
                totalAmount: calculateAllServicesAmount(
                  formValues,
                  singleSelectedSer
                ),
                totalWords: calculateTotalWords(formValues),
              });
            }
          }
        );
      }
      setServicesMatchRate(newData);
    }
  }, [draftDetail, matchRates, selectedService, jobQuoteDetails]);

  function createData(
    matchType: string,
    percentOfRate: any,
    rate: any,
    words: string,
    subTotal: number,
    index: number
  ) {
    return { matchType, percentOfRate, rate, words, subTotal, index };
  }

  // function setModal() {
  //   setMatchRatesModal(1);
  // }

  const draftWords = (fieldName: string) => {
    if (
      draftDetail &&
      draftDetail.quoteServices[serviceIndex] &&
      draftDetail.quoteServices[serviceIndex].matchRateDetails != ""
    ) {
      if (fieldName === "New") {
        return JSON.parse(
          draftDetail.quoteServices[serviceIndex] &&
          draftDetail.quoteServices[serviceIndex].matchRateDetails
        )["new"].word;
      } else if (fieldName === "50%-74%") {
        return JSON.parse(
          draftDetail.quoteServices[serviceIndex] &&
          draftDetail.quoteServices[serviceIndex].matchRateDetails
        )["fifty_seventyFour"].word;
      } else if (fieldName === "75%-84%") {
        return JSON.parse(
          draftDetail.quoteServices[serviceIndex] &&
          draftDetail.quoteServices[serviceIndex].matchRateDetails
        )["seventyFive_ninetyEight"].word;
      } else if (fieldName === "85%-94%") {
        return JSON.parse(
          draftDetail.quoteServices[serviceIndex] &&
          draftDetail.quoteServices[serviceIndex].matchRateDetails
        )["eightyFive_NinetyFour"].word;
      } else if (fieldName === "95%-99%") {
        return JSON.parse(
          draftDetail.quoteServices[serviceIndex] &&
          draftDetail.quoteServices[serviceIndex].matchRateDetails
        )["ninetyFive_NinetyNine"].word;
      } else if (fieldName === "100%") {
        return JSON.parse(
          draftDetail.quoteServices[serviceIndex] &&
          draftDetail.quoteServices[serviceIndex].matchRateDetails
        )["hundred"].word;
      } else if (fieldName === "Repetitions%") {
        return JSON.parse(
          draftDetail.quoteServices[serviceIndex] &&
          draftDetail.quoteServices[serviceIndex].matchRateDetails
        )["repetition"].word;
      } else if (fieldName === "Cross File Repetitions%") {
        return JSON.parse(
          draftDetail.quoteServices[serviceIndex] &&
          draftDetail.quoteServices[serviceIndex].matchRateDetails
        )["crossFileRepetitions"].word;
      }
    } else {
      return "";
    }
  };

  function getServiceRateByIndex(index: any) {
    let obj = null;
    servicesMatchRate.forEach((row: any) => {
      if (row.index == index) {
        obj = row.values;
      }
    });

    return obj;
  }

  if (typeof jobAnalysis != "undefined" && jobAnalysis.length > 0) {
    jobAnalysis[0]["New"] = jobAnalysis[0]["new"];
    jobAnalysis[0]["50%-74%"] = jobAnalysis[0]["fifty_seventyFour"];
    jobAnalysis[0]["75%-84%"] = jobAnalysis[0]["seventyFive_ninetyEight"];
    jobAnalysis[0]["85%-94%"] = jobAnalysis[0]["eightyFive_NinetyFour"];
    jobAnalysis[0]["95%-99%"] = jobAnalysis[0]["ninetyFive_NinetyNine"];
    jobAnalysis[0]["100%"] = jobAnalysis[0]["hundred"];
    jobAnalysis[0]["Repetitions%"] = jobAnalysis[0]["repetition"];
    jobAnalysis[0]["Cross File Repetitions%"] =
      jobAnalysis[0]["crossFileRepetitions"];
  }

  useEffect(
    function () {
      const getServiceRate: any = getServiceRateByIndex(serviceIndex);
      if (servicesMatchRate && getServiceRate != null) {
        let newRate: any = 0;
        if (
          typeof getServiceRate.percentOfRate != "undefined" &&
          typeof getServiceRate.percentOfRate[0] != "undefined"
        ) {
          newRate = (
            selectedService.price *
            (parseFloat(getServiceRate.percentOfRate[0]) / 100)
          ).toFixed(3);
        }

        let fiftySeventyFourRate: any = 0;
        if (
          typeof getServiceRate.percentOfRate != "undefined" &&
          typeof getServiceRate.percentOfRate[1] != "undefined"
        ) {
          fiftySeventyFourRate = (
            selectedService.price *
            (parseFloat(getServiceRate.percentOfRate[1]) / 100)
          ).toFixed(3);
        }

        let seventyFiveNinetyEight: any = 0;
        if (
          typeof getServiceRate.percentOfRate != "undefined" &&
          typeof getServiceRate.percentOfRate[2] != "undefined"
        ) {
          seventyFiveNinetyEight = (
            selectedService.price *
            (parseFloat(getServiceRate.percentOfRate[2]) / 100)
          ).toFixed(3);
        }

        let eightyFiveNinetyFour: any = 0;
        if (
          typeof getServiceRate.percentOfRate != "undefined" &&
          typeof getServiceRate.percentOfRate[3] != "undefined"
        ) {
          eightyFiveNinetyFour = (
            selectedService.price *
            (parseFloat(getServiceRate.percentOfRate[3]) / 100)
          ).toFixed(3);
        }

        let ninetyFiveNinetyNine: any = 0;
        if (
          typeof getServiceRate.percentOfRate != "undefined" &&
          typeof getServiceRate.percentOfRate[4] != "undefined"
        ) {
          ninetyFiveNinetyNine = (
            selectedService.price *
            (parseFloat(getServiceRate.percentOfRate[4]) / 100)
          ).toFixed(3);
        }

        let hundred: any = 0;
        if (
          typeof getServiceRate.percentOfRate != "undefined" &&
          typeof getServiceRate.percentOfRate[5] != "undefined"
        ) {
          hundred = (
            selectedService.price *
            (parseFloat(getServiceRate.percentOfRate[5]) / 100)
          ).toFixed(3);
        }

        let repetition: any = 0;
        if (
          typeof getServiceRate.percentOfRate != "undefined" &&
          typeof getServiceRate.percentOfRate[6] != "undefined"
        ) {
          repetition = (
            selectedService.price *
            (parseFloat(getServiceRate.percentOfRate[6]) / 100)
          ).toFixed(3);
        }

        let crossFileRepetitions: any = 0;
        if (
          typeof getServiceRate.percentOfRate != "undefined" &&
          typeof getServiceRate.percentOfRate[7] != "undefined"
        ) {
          crossFileRepetitions = (
            selectedService.price *
            (parseFloat(getServiceRate.percentOfRate[7]) / 100)
          ).toFixed(3);
        }

        let newWord: any = 0;
        if (
          typeof getServiceRate.words != "undefined" &&
          typeof getServiceRate.words[0] != "undefined"
        ) {
          newWord = getServiceRate.words[0];
        }

        let fiftySeventyFourWord: any = 0;
        if (
          typeof getServiceRate.words != "undefined" &&
          typeof getServiceRate.words[1] != "undefined"
        ) {
          fiftySeventyFourWord = getServiceRate.words[1];
        }

        let seventyFiveNinetyEightWord: any = 0;
        if (
          typeof getServiceRate.words != "undefined" &&
          typeof getServiceRate.words[2] != "undefined"
        ) {
          seventyFiveNinetyEightWord = getServiceRate.words[2];
        }

        let eightyFiveNinetyFourWord: any = 0;
        if (
          typeof getServiceRate.words != "undefined" &&
          typeof getServiceRate.words[3] != "undefined"
        ) {
          eightyFiveNinetyFourWord = getServiceRate.words[3];
        }

        let ninetyFiveNinetyNineWord: any = 0;
        if (
          typeof getServiceRate.words != "undefined" &&
          typeof getServiceRate.words[4] != "undefined"
        ) {
          ninetyFiveNinetyNineWord = getServiceRate.words[4];
        }

        let hundredWord: any = 0;
        if (
          typeof getServiceRate.words != "undefined" &&
          typeof getServiceRate.words[5] != "undefined"
        ) {
          hundredWord = getServiceRate.words[5];
        }

        let repetitionWord: any = 0;
        if (
          typeof getServiceRate.words != "undefined" &&
          typeof getServiceRate.words[6] != "undefined"
        ) {
          repetitionWord = getServiceRate.words[6];
        }

        let crossFileRepetitionsWord: any = 0;
        if (
          typeof getServiceRate.percentOfRate != "undefined" &&
          typeof getServiceRate.words[7] != "undefined"
        ) {
          crossFileRepetitionsWord = getServiceRate.words[7];
        }

        if (selectedMatchRate) {
          const r = [
            createData("New", selectedMatchRate.new, newRate, newWord, 0, 0),
            createData(
              "50%-74%",
              selectedMatchRate.fifty_seventyFour,
              fiftySeventyFourRate,
              fiftySeventyFourWord,
              0,
              1
            ),
            createData(
              "75%-84%",
              selectedMatchRate.seventyFive_ninetyEight,
              seventyFiveNinetyEight,
              seventyFiveNinetyEightWord,
              0,
              2
            ),
            createData(
              "85%-94%",
              selectedMatchRate.eightyFive_NinetyFour,
              eightyFiveNinetyFour,
              eightyFiveNinetyFourWord,
              0,
              3
            ),
            createData(
              "95%-99%",
              selectedMatchRate.ninetyFive_NinetyNine,
              ninetyFiveNinetyNine,
              ninetyFiveNinetyNineWord,
              0,
              4
            ),
            createData(
              "100%",
              selectedMatchRate.hundred,
              hundred,
              hundredWord,
              0,
              5
            ),
            createData(
              "Repetitions%",
              selectedMatchRate.repetition,
              repetition,
              repetitionWord,
              0,
              6
            ),
            createData(
              "Cross File Repetitions%",
              selectedMatchRate.crossFileRepetitions,
              crossFileRepetitions,
              crossFileRepetitionsWord,
              0,
              7
            ),
          ];

          setPercentRates([
            getServiceRate.percentOfRate[0],
            getServiceRate.percentOfRate[1],
            getServiceRate.percentOfRate[2],
            getServiceRate.percentOfRate[3],
            getServiceRate.percentOfRate[4],
            getServiceRate.percentOfRate[5],
            getServiceRate.percentOfRate[6],
            getServiceRate.percentOfRate[7],
          ]);

          setWords([
            getServiceRate.words[0],
            getServiceRate.words[1],
            getServiceRate.words[2],
            getServiceRate.words[3],
            getServiceRate.words[4],
            getServiceRate.words[5],
            getServiceRate.words[6],
            getServiceRate.words[7],
          ]);

          setRows(r);
        }
      } else if (selectedMatchRate) {
        const newRate = (
          selectedService.price *
          (parseFloat(selectedMatchRate.new) / 100)
        ).toFixed(3);
        const fiftySeventyFourRate = (
          selectedService.price *
          (parseFloat(selectedMatchRate.fifty_seventyFour) / 100)
        ).toFixed(3);
        const seventyFiveNinetyEight = (
          selectedService.price *
          (parseFloat(selectedMatchRate.seventyFive_ninetyEight) / 100)
        ).toFixed(3);
        const eightyFiveNinetyFour = (
          selectedService.price *
          (parseFloat(selectedMatchRate.eightyFive_NinetyFour) / 100)
        ).toFixed(3);
        const ninetyFiveNinetyNine = (
          selectedService.price *
          (parseFloat(selectedMatchRate.ninetyFive_NinetyNine) / 100)
        ).toFixed(3);
        const hundred = (
          selectedService.price *
          (parseFloat(selectedMatchRate.hundred) / 100)
        ).toFixed(3);
        const repetition = (
          selectedService.price *
          (parseFloat(selectedMatchRate.repetition) / 100)
        ).toFixed(3);
        const crossFileRepetitions = (
          selectedService.price *
          (parseFloat(selectedMatchRate.crossFileRepetitions) / 100)
        ).toFixed(3);

        const r = [
          createData("New", selectedMatchRate.new, newRate, "", 0, 0),
          createData(
            "50%-74%",
            selectedMatchRate.fifty_seventyFour,
            fiftySeventyFourRate,
            "",
            0,
            1
          ),
          createData(
            "75%-84%",
            selectedMatchRate.seventyFive_ninetyEight,
            seventyFiveNinetyEight,
            "",
            0,
            2
          ),
          createData(
            "85%-94%",
            selectedMatchRate.eightyFive_NinetyFour,
            eightyFiveNinetyFour,
            "",
            0,
            3
          ),
          createData(
            "95%-99%",
            selectedMatchRate.ninetyFive_NinetyNine,
            ninetyFiveNinetyNine,
            "",
            0,
            4
          ),
          createData("100%", selectedMatchRate.hundred, hundred, "", 0, 5),
          createData(
            "Repetitions%",
            selectedMatchRate.repetition,
            repetition,
            "",
            0,
            6
          ),
          createData(
            "Cross File Repetitions%",
            selectedMatchRate.crossFileRepetitions,
            crossFileRepetitions,
            "",
            0,
            7
          ),
        ];

        setPercentRates([
          selectedMatchRate.new,
          selectedMatchRate.fifty_seventyFour,
          selectedMatchRate.seventyFive_ninetyEight,
          selectedMatchRate.eightyFive_NinetyFour,
          selectedMatchRate.ninetyFive_NinetyNine,
          selectedMatchRate.hundred,
          selectedMatchRate.repetition,
          selectedMatchRate.crossFileRepetitions,
        ]);
        if (jobAnalysis.length > 0) {
          setWords([
            jobAnalysis[0]["New"],
            jobAnalysis[0]["50%-74%"],
            jobAnalysis[0]["75%-84%"],
            jobAnalysis[0]["85%-94%"],
            jobAnalysis[0]["95%-99%"],
            jobAnalysis[0]["100%"],
            jobAnalysis[0]["Repetitions%"],
            jobAnalysis[0]["Cross File Repetitions%"],
          ]);
        }

        setRows(r);
      }
    },
    [selectedMatchRate, servicesMatchRate, selectedService]
  );

  function calculateTotalWords(values: any) {
    let totalWords = 0;
    if (values && values.words && typeof values.words != "undefined") {
      for (let i = 0; i < values.words.length; i++) {
        if (typeof values.words[i] != "undefined" && values.words[i] != "") {
          totalWords += parseInt(values.words[i]);
        }
      }
    }

    return totalWords;
  }

  function calculateSubTotal(values: any, row: any) {
    const index = row.index;
    const percentRateValue =
      selectedService.price * (values.percentOfRate[index] / 100);
    let words = 0;
    if (values && values.words && typeof values.words[index] != "undefined") {
      words = values.words[index];
    }

    const subTotal = percentRateValue * words;

    return subTotal.toFixed(3);
  }

  function calculateTotalAmount(values: any) {
    let totalAmount = 0;
    if (values && values.words && typeof values.words != "undefined") {
      for (let i = 0; i < values.words.length; i++) {
        if (
          typeof values.words[i] != "undefined" &&
          values.words[i] != "" &&
          values.percentOfRate[i] != ""
        ) {
          totalAmount +=
            values.words[i] *
            (selectedService.price * (values.percentOfRate[i] / 100));
        }
      }
    }
    return totalAmount.toFixed(3);
  }

  function calculateAllServicesAmount(values: any, selectedSer: any) {
    let totalAmount = 0;
    if (values && values.words && typeof values.words != "undefined") {
      for (let i = 0; i < values.words.length; i++) {
        if (
          typeof values.words[i] != "undefined" &&
          values.words[i] != "" &&
          values.percentOfRate[i] != ""
        ) {
          totalAmount +=
            values.words[i] *
            (selectedSer.price * (values.percentOfRate[i] / 100));
        }
      }
    }
    return totalAmount.toFixed(3);
  }

  useEffect(() => {
    if (selectedService && selectedService.targetLanguages) {
      const targetLanguagesN = selectedService.targetLanguages
        .map((language: any) => language)
        .join(", ");
      setTargetLanguageNames(targetLanguagesN);
    }
  }, [selectedService]);



  useEffect(() => {
    // You can set state after component is mounted or when the message changes
    const matchRateUpdate = intl.formatMessage({ id: 'match.rate.update.successfully', defaultMessage: 'Match Rate updated Successfully' });
    setMatchRateUpdateSuccessfully(matchRateUpdate);
    const matchRateAdd = intl.formatMessage({ id: 'match.rate.added.successfully', defaultMessage: 'Match Rate updated Successfully' });
    setMatchRateAddedSuccessfully(matchRateAdd);
  }, []);

  return (
    <MBox pl={2} pr={2}>
      {/* <Dialog
        className={classes.languageModal}
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        aria-labelledby="add match rates"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="dialog-title">
          <MBox fontFamily="Poppins" fontSize={18}>
            {selectedService &&
              selectedService.service &&
              selectedService.service.name}
            <MBox
              component="span"
              fontFamily="Poppins"
              className="paletteTextSecondaryColor"
            >
              ({targetLanguageNames && targetLanguageNames})
            </MBox>
          </MBox>
        </DialogTitle>

        <MFormik
          enableReinitialize="true"
          initialValues={{
            tag: "",
            percentOfRate: percentRates,
            words: words,
          }}
          validationSchema={Yup.object({})}
          onSubmit={(values: any) => {
            if (calculateTotalWords(values) > 0) {
              setZeroWordError(0);
              if (!selectedMatchRate && isEdit == 1) {
                const newServiceRates = [];

                for (let i = 0; i < servicesMatchRate.length; i++) {
                  if (
                    servicesMatchRate[i].index == serviceIndex &&
                    selectedMatchRate
                  ) {
                    newServiceRates.push(servicesMatchRate[i]);
                  }
                }

                setServicesMatchRate(newServiceRates);
              } else if (selectedMatchRate) {
                const newServices = [];
                const oldServices = servicesMatchRate;
                for (let i = 0; i < oldServices.length; i++) {
                  if (
                    oldServices[i].index != serviceIndex &&
                    oldServices[i].matchRate == selectedMatchRate
                  ) {
                    newServices.push(oldServices[i]);
                  }
                }
                const wrds = calculateTotalWords(values);
                const serviceValues = {
                  index: serviceIndex,
                  values: values,
                  matchRate: selectedMatchRate,
                  selectedService: selectedService,
                  totalAmount: calculateTotalAmount(values),
                  totalWords: calculateTotalWords(values),
                };

                newServices.push(serviceValues);

                setServicesMatchRate(newServices);
                setFieldValue(`serviceForms.${serviceIndex}.number`, wrds);
              }

              setModalOpen(false);
            } else {
              setZeroWordError(1);
            }
          }}
        >
          {({ values }: any) => {
            return (
              <MForm>
                <DialogContent sx={{ padding: 0 }}>
                  <MBox>
                    <MBox p={3}>
                      <MBox mb={5}>
                        <MBox mb={3}>
                          <MBox mb={2} fontFamily="Poppins">
                            <FormattedMessage id="select.match.rate" />
                          </MBox>
                          <MBox display="flex" alignItems="center">
                            <MAutoComplete
                              id="tmxfile"
                              options={matchRates}
                              disabled={
                                matchRatesModal && matchRatesModal == 1
                                  ? true
                                  : false
                              }
                              label={"Select match rate"}
                              getOptionLabel={(option: any) => option.name}
                              disableClearable={true}
                              defaultValue={selectedMatchRate}
                              sx={{ width: 397 }}
                              onChange={(event: any, value: any) => {
                                setSelectedMatchRate(value);
                                setFieldValue(
                                  `serviceForms.${serviceIndex}.matchRateId`,
                                  value.id
                                );
                              }}
                            />
                            {matchRates &&
                              matchRates.length < 1 &&
                              matchRatesModal == 0 && (
                                <MBox ml={1.25}>
                                  <MButton
                                    className="btnSmall"
                                    onClick={setModal}
                                    color="primary"
                                    variant="contained"
                                  >
                                    <FormattedMessage id="add.match.rates" />
                                  </MButton>
                                </MBox>
                              )}
                          </MBox>
                        </MBox>
                      </MBox>
                      {matchRatesModal && matchRatesModal == 1 ? (
                        <MBox>
                          <MBox maxWidth={500}>
                            <MFormik
                              validateOnChange
                              enableReinitialize="true"
                              initialValues={{
                                name: "",
                                new: "",
                                Fifty_seventyFour: "",
                                SeventyFive_ninetyEight: "",
                                EightyFive_NinetyFour: "",
                                NinetyFive_NinetyNine: "",
                                Hundred: "",
                                repetition: "",
                                crossFileRepetitions: "",
                              }}
                              validationSchema={Yup.object({
                                name: Yup.string().required("required"),
                                new: Yup.string()
                                  .required("required")
                                  .matches(
                                    /^[0-9][0-9]?$|^100$/,
                                    "Enter valid percentage"
                                  ),
                                Fifty_seventyFour: Yup.string()
                                  .required("required")
                                  .matches(
                                    /^[0-9][0-9]?$|^100$/,
                                    "Enter valid percentage"
                                  ),
                                SeventyFive_ninetyEight: Yup.string()
                                  .required("required")
                                  .matches(
                                    /^[0-9][0-9]?$|^100$/,
                                    "Enter valid percentage"
                                  ),
                                NinetyFive_NinetyNine: Yup.string()
                                  .required("required")
                                  .matches(
                                    /^[0-9][0-9]?$|^100$/,
                                    "Enter valid percentage"
                                  ),
                                Hundred: Yup.string()
                                  .required("required")
                                  .matches(
                                    /^[0-9][0-9]?$|^100$/,
                                    "Enter valid percentage"
                                  ),
                                repetition: Yup.string()
                                  .required("required")
                                  .matches(
                                    /^[0-9][0-9]?$|^100$/,
                                    "Enter valid percentage"
                                  ),
                                crossFileRepetitions: Yup.string()
                                  .required("required")
                                  .matches(
                                    /^[0-9][0-9]?$|^100$/,
                                    "Enter valid percentage"
                                  ),
                              })}
                              onSubmit={(values: any, { resetForm }: any) => {
                                createMatchRate({
                                  variables: {
                                    name: values.name,
                                    new: values.new,
                                    fifty_seventyFour: values.Fifty_seventyFour,
                                    seventyFive_ninetyEight:
                                      values.SeventyFive_ninetyEight,
                                    eightyFive_NinetyFour:
                                      values.EightyFive_NinetyFour,
                                    ninetyFive_NinetyNine:
                                      values.NinetyFive_NinetyNine,
                                    hundred: values.Hundred,
                                    repetition: values.repetition,
                                    crossFileRepetitions:
                                      values.crossFileRepetitions,
                                  },
                                });

                                resetForm();
                              }}
                            >
                              {({ resetForm, submitForm }: any) => (
                                <MForm>
                                  <MBox mb={5}>
                                    <MBox mb={2} fontFamily="Poppins">
                                      <FormattedMessage id="name" />
                                    </MBox>
                                    <MBox>
                                      <MTextField
                                        fullWidth
                                        name="name"
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        disabled={false}
                                        placeholder="Name"
                                      />
                                    </MBox>
                                  </MBox>

                                  <MBox>
                                    <MBox mb={2} fontFamily="Poppins">
                                      <FormattedMessage id="add.match.rates" />
                                    </MBox>
                                    <MBox
                                      className="inputsRow"
                                      display="flex"
                                      justifyContent="space-between"
                                      alignItems="center"
                                      mb={2}
                                    >
                                      <MBox
                                        maxWidth="230px"
                                        display="flex"
                                        alignItems="center"
                                      >
                                        <MBox
                                          width="60px"
                                          mr={1.2}
                                          textAlign="right"
                                        >
                                          <FormattedMessage id="new" />
                                        </MBox>
                                        <MBox width="160px" position="relative">
                                          <MTextField
                                            fullWidth
                                            type="number"
                                            name="new"
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            disabled={false}
                                            placeholder="100%"
                                            inputProps={{
                                              max: "100",
                                              min: "0",
                                              step: "any",
                                            }}
                                          />
                                        </MBox>
                                      </MBox>
                                      <MBox
                                        maxWidth="230px"
                                        display="flex"
                                        alignItems="center"
                                      >
                                        <MBox
                                          width="60px"
                                          mr={1.2}
                                          textAlign="right"
                                        >
                                          <FormattedMessage id="fifty.to.seventyfourpercent" />
                                        </MBox>
                                        <MBox width="160px">
                                          <MTextField
                                            fullWidth
                                            type="number"
                                            name="Fifty_seventyFour"
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            disabled={false}
                                            placeholder="60%"
                                            inputProps={{
                                              max: "100",
                                              min: "0",
                                              step: "any",
                                            }}
                                          />
                                        </MBox>
                                      </MBox>
                                    </MBox>
                                    <MBox
                                      className="inputsRow"
                                      display="flex"
                                      justifyContent="space-between"
                                      alignItems="center"
                                      mb={2}
                                    >
                                      <MBox
                                        maxWidth="230px"
                                        display="flex"
                                        alignItems="center"
                                      >
                                        <MBox
                                          width="60px"
                                          mr={1.2}
                                          textAlign="right"
                                        >
                                          <FormattedMessage id="seventyfive.to.eightyfour" />
                                        </MBox>
                                        <MBox width="160px">
                                          <MTextField
                                            fullWidth
                                            type="number"
                                            name="SeventyFive_ninetyEight"
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            disabled={false}
                                            placeholder="80%"
                                            inputProps={{
                                              max: "100",
                                              min: "0",
                                              step: "any",
                                            }}
                                          />
                                        </MBox>
                                      </MBox>
                                      <MBox
                                        maxWidth="230px"
                                        display="flex"
                                        alignItems="center"
                                      >
                                        <MBox
                                          width="60px"
                                          mr={1.2}
                                          textAlign="right"
                                        >
                                          <FormattedMessage id="eightyfive.to.ninetyfour" />
                                        </MBox>
                                        <MBox width="160px">
                                          <MTextField
                                            fullWidth
                                            type="number"
                                            name="EightyFive_NinetyFour"
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            disabled={false}
                                            placeholder="90%"
                                            inputProps={{
                                              max: "100",
                                              min: "0",
                                              step: "any",
                                            }}
                                          />
                                        </MBox>
                                      </MBox>
                                    </MBox>
                                    <MBox
                                      className="inputsRow"
                                      display="flex"
                                      justifyContent="space-between"
                                      alignItems="center"
                                      mb={2}
                                    >
                                      <MBox
                                        maxWidth="230px"
                                        display="flex"
                                        alignItems="center"
                                      >
                                        <MBox
                                          width="60px"
                                          mr={1.2}
                                          textAlign="right"
                                        >
                                          <FormattedMessage id="ninetyfive.to.ninetynine" />
                                        </MBox>
                                        <MBox width="160px">
                                          <MTextField
                                            fullWidth
                                            type="number"
                                            name="NinetyFive_NinetyNine"
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            disabled={false}
                                            placeholder="97%"
                                            inputProps={{
                                              max: "100",
                                              min: "0",
                                              step: "any",
                                            }}
                                          />
                                        </MBox>
                                      </MBox>
                                      <MBox
                                        maxWidth="230px"
                                        display="flex"
                                        alignItems="center"
                                      >
                                        <MBox
                                          width="60px"
                                          mr={1.2}
                                          textAlign="right"
                                        >
                                          <FormattedMessage id="hundered.percent" />
                                        </MBox>
                                        <MBox width="160px">
                                          <MTextField
                                            fullWidth
                                            type="number"
                                            name="Hundred"
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            disabled={false}
                                            placeholder="100%"
                                            inputProps={{
                                              max: "100",
                                              min: "0",
                                              step: "any",
                                            }}
                                          />
                                        </MBox>
                                      </MBox>
                                    </MBox>
                                    <MBox
                                      className="inputsRow"
                                      display="flex"
                                      justifyContent="space-between"
                                      alignItems="center"
                                    >
                                      <MBox
                                        maxWidth="230px"
                                        display="flex"
                                        alignItems="center"
                                      >
                                        <MBox
                                          width="60px"
                                          mr={1.2}
                                          sx={{ whiteSpace: "nowrap" }}
                                          textAlign="right"
                                        >
                                          <FormattedMessage id="repetition" />
                                        </MBox>
                                        <MBox width="160px">
                                          <MTextField
                                            fullWidth
                                            type="number"
                                            name="repetition"
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            disabled={false}
                                            placeholder="0%"
                                            inputProps={{
                                              max: "100",
                                              min: "0",
                                              step: "any",
                                            }}
                                          />
                                        </MBox>
                                      </MBox>
                                      <MBox
                                        maxWidth="230px"
                                        display="flex"
                                        alignItems="center"
                                      >
                                        <MBox width="60px" mr={1.2}>
                                          <FormattedMessage id="cross.file.repetitions" />
                                        </MBox>
                                        <MBox width="160px">
                                          <MTextField
                                            fullWidth
                                            type="number"
                                            name="crossFileRepetitions"
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            disabled={false}
                                            placeholder="0%"
                                            inputProps={{
                                              max: "100",
                                              min: "0",
                                              step: "any",
                                            }}
                                          />
                                        </MBox>
                                      </MBox>
                                    </MBox>
                                    <MBox
                                      display="flex"
                                      alignItems="center"
                                      mt={5}
                                    >
                                      <MButton
                                        className="btnSmall backgroundColorGrey"
                                        color="primary"
                                        variant="contained"
                                        disabled={createLoading}
                                        onClick={() => {
                                          submitForm();
                                        }}
                                      >
                                        {createLoading && (
                                          <MCircularProgress
                                            color="secondary"
                                            size={24}
                                          />
                                        )}
                                        +<FormattedMessage id="add" />
                                      </MButton>
                                      <MBox ml={2}>
                                        <MLink
                                          color="inherit"
                                          href="#"
                                          onClick={() => {
                                            resetForm();
                                          }}
                                        >
                                          <FormattedMessage id="cancel" />
                                        </MLink>
                                      </MBox>
                                    </MBox>
                                  </MBox>
                                </MForm>
                              )}
                            </MFormik>
                          </MBox>
                        </MBox>
                      ) : (
                        ""
                      )}

                      {selectedMatchRate && (
                        <MTableContainer component={MPaper}>
                          <MTable
                            aria-label="match rates table"
                            className={classes.matchRateTable}
                          >
                            <MTableHead>
                              <MTableRow>
                                <MTableCell>
                                  <MBox fontFamily="Poppins" align="center">
                                    <FormattedMessage id="match.types" />
                                  </MBox>
                                </MTableCell>
                                <MTableCell align="center">
                                  <MBox fontFamily="Poppins">
                                    <FormattedMessage id="percent.rate" />
                                  </MBox>
                                </MTableCell>
                                <MTableCell align="center">
                                  <MBox fontFamily="Poppins">
                                    <FormattedMessage id="rate" />
                                  </MBox>
                                </MTableCell>
                                <MTableCell align="center">
                                  <MBox fontFamily="Poppins">
                                    <FormattedMessage id="words" />
                                  </MBox>
                                </MTableCell>
                                <MTableCell align="center">
                                  <MBox fontFamily="Poppins">
                                    <FormattedMessage id="subtotal" />
                                  </MBox>
                                </MTableCell>
                              </MTableRow>
                            </MTableHead>
                            <MTableBody>
                              {selectedMatchRate &&
                                rows &&
                                rows.map((row: any) => (
                                  <MTableRow key={row && row.matchType}>
                                    <MTableCell
                                      component="th"
                                      scope="row"
                                      align="center"
                                    >
                                      {row && row.matchType}
                                    </MTableCell>
                                    <MTableCell align="center">
                                      <MBox display="flex" alignItems="center">
                                        <MBox>
                                          <MTextField
                                            fullWidth
                                            type="number"
                                            value={
                                              values.percentOfRate &&
                                              typeof values.percentOfRate[
                                                row.index
                                              ] != "undefined" &&
                                              values.percentOfRate[row.index]
                                            }
                                            name={`percentOfRate.${row.index}`}
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            disabled={false}
                                            placeholder={row.percentOfRate}
                                            defaultValue={row.percentOfRate}
                                            inputProps={{
                                              max: "100",
                                              min: "0",
                                              step: "any",
                                            }}
                                          />
                                        </MBox>
                                        <MBox>&nbsp;&nbsp;%</MBox>
                                      </MBox>
                                    </MTableCell>
                                    <MTableCell align="center">
                                      {selectedService &&
                                        values.percentOfRate &&
                                        typeof values.percentOfRate[
                                          row.index
                                        ] != "undefined" &&
                                        (
                                          selectedService.price *
                                          (values.percentOfRate[row.index] /
                                            100)
                                        ).toFixed(3)}
                                    </MTableCell>
                                    <MTableCell align="center">
                                      <MBox display="flex" alignItems="center">
                                        <MBox>
                                          <MTextField
                                            fullWidth
                                            type="number"
                                            name={`words.${row.index}`}
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            disabled={false}
                                            placeholder={row.words}
                                            defaultValue={
                                              draftDetail
                                                ? draftWords(row.matchType)
                                                : jobAnalysis &&
                                                  jobAnalysis[0] &&
                                                  jobAnalysis[0][
                                                    row && row.matchType
                                                  ]
                                            }
                                            inputProps={{
                                              min: "0",
                                              step: "any",
                                            }}
                                          />
                                        </MBox>
                                      </MBox>
                                    </MTableCell>
                                    <MTableCell align="center">
                                      $
                                      {values && calculateSubTotal(values, row)}
                                    </MTableCell>
                                  </MTableRow>
                                ))}

                              {selectedMatchRate && (
                                <MTableRow>
                                  <MTableCell colSpan={4} align="right">
                                    <MBox fontFamily="Poppins" pr="70px">
                                      {calculateTotalWords(values)}
                                    </MBox>
                                  </MTableCell>
                                  <MTableCell align="center">
                                    <MBox fontFamily="Poppins">
                                      ${calculateTotalAmount(values)}
                                    </MBox>
                                  </MTableCell>
                                </MTableRow>
                              )}
                            </MTableBody>
                          </MTable>
                        </MTableContainer>
                      )}
                    </MBox>
                  </MBox>
                </DialogContent>

                <DialogActions sx={{ borderTop: "1px solid #dbdbdb" }}>
                  {zeroWordError == 1 && (
                    <MBox sx={{ color: "#f44336" }}>
                      <FormattedMessage id="total.must.greater.zero" />
                    </MBox>
                  )}
                  <MBox pt={1} pb={1} pr={2} display="flex" alignItems="center">
                    <MBox mr={2}>
                      <MLink
                        href="javascript:void(0);"
                        color="secondary"
                        onClick={() =>
                          handleModalClose(serviceIndex, setFieldValue)
                        }
                        sx={{ color: "#3a3a3a" }}
                      >
                        <FormattedMessage id="cancel" />
                      </MLink>
                    </MBox>
                    <MButton
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={loading}
                      sx={{ width: 96, marginLeft: 8 }}
                    >
                      {loading && (
                        <MCircularProgress color="primary" size={24} />
                      )}{" "}
                      <FormattedMessage id="save" />
                    </MButton>
                  </MBox>
                </DialogActions>
              </MForm>
            );
          }}
        </MFormik>
      </Dialog> */}

      <MDrawer
        open={modalOpen}
        variant="temporary"
        anchor="right"
        className={`${classes.fileUploadDrawer} ${isFullWidth ? "fullWidth" : ""
          }`}
        onClose={() => setModalOpen(false)}
      >
        <MAppBar className="drawerAppBar">
          <MToolbar sx={{ gap: "5px" }}>
            <MBox display="inline-block">
              <MIconButton onClick={() => { setModalOpen(false); setSelectedMatchRate(null); setServicesMatchRate([]); setSelectedMatchRateId(0) }}>
                <ArrowBackIcon />
              </MIconButton>
            </MBox>
            <MBox display="inline-block">
              <MBox fontFamily="Poppins" fontSize={18}>
                <MBox width="max-content">
                  <FormattedMessage id="chargeable.words" />
                </MBox>
              </MBox>
            </MBox>
            <MBox display="flex">
              <ArrowRightAltIcon />
            </MBox>
            <MBox width="100%" fontSize={18} fontFamily="Poppins">
              <MBox fontFamily="Poppins" fontSize={18}>
                {selectedService?.targetLanguages?.[0]?.name}
                <MBox
                  component="span"
                  fontFamily="Poppins"
                  className="paletteTextSecondaryColor"
                >
                  ({selectedService?.targetLanguages?.[0]?.code})
                </MBox>
              </MBox>
            </MBox>
            {mathchRateCreate && (
              <MBox>
                <MButton
                  className="btnsmall"
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setOpenMatchRateDrawer(true);
                    setMatchRateId(0);
                  }}
                  sx={{ boxShadow: "none", width: "max-content" }}
                >
                  <FormattedMessage id="add.match.rates" />
                </MButton>
              </MBox>
            )}
          </MToolbar>
        </MAppBar>

        <MBox sx={{ margin: "20px 50px 70px" }}>
          {matchRates &&
            matchRates.map((val: any, idx: number) => (
              <MBox
                key={idx}
                className={classes.vendorListView}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                py={2}
                px={3}
                onClick={() => {
                  // if (projectSettingEdit) {
                  //     setDetailOpen(true);
                  //     setStyleGuideDrawerId(val.id)
                  // }
                }}
              >
                <MBox
                  display="flex"
                  alignItems="center"
                  flex="2 1 0"
                  overflow="hidden"
                >
                  <MBox
                    className={classes.checkbox}
                    display="flex"
                    alignItems="end"
                    mr={2}
                  >
                    <MRadio
                      // disabled={!projectSettingEdit}
                      color="primary"
                      value={val.id}
                      checked={val.id == selectedMatchRate?.id}
                      name="tag"
                      onChange={() => {
                        setSelectedMatchRateId(val?.id);
                        setSelectedMatchRate(val);
                        setSelectedMatchRateData(true);
                        const targetDiv =
                          document.getElementById("detailMatchRate");
                        targetDiv?.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                        });
                        // setDetailOpen(false);
                      }}
                    />
                  </MBox>
                  <MBox overflow="hidden">
                    <MBox
                      className="textOverflow"
                      color="rgb(57, 57, 57)"
                      fontSize={14}
                      fontFamily="Poppins"
                    >
                      {val.name}
                    </MBox>
                  </MBox>
                </MBox>

                <MBox display="flex" justifyContent="flex-end" flex="2 1 0">
                  <MBox
                    fontSize={14}
                    fontFamily="Poppins"
                    color="rgba(0, 0, 0, 0.54)"
                  >
                    {mathchRateEdit && (
                      <MIconButton
                        onClick={() => {
                          setVendorMatchRate([val]);
                          setOpenEditMatchRateDrawer(true);
                          setMatchRateId(val.id);

                        }}
                      >
                        <MTooltip
                          arrow
                          placement="top"
                          title={<FormattedMessage id="update" />}
                        >
                          <EditIcon />
                        </MTooltip>
                      </MIconButton>
                    )}
                    {mathchRateDelete && (
                      <MIconButton
                        onClick={() => {
                          handleClickOpen();
                          setMatchRateId(val.id);
                        }}
                      >
                        <MTooltip
                          arrow
                          placement="top"
                          title={<FormattedMessage id="delete" />}
                        >
                          <DeleteIcon />
                        </MTooltip>
                      </MIconButton>
                    )}
                  </MBox>
                </MBox>
              </MBox>
            ))}

          {/* {totalPages > 1 ?
                            <MBox mt={10} display="flex" justifyContent="flex-end">
                                <MPagination count={totalPages} page={page && page} onChange={handlePageChange} />
                            </MBox>
                            : " "} */}
        </MBox>
        <MBox id="detailMatchRate">
          {selectedMatchRate && (
            <MBox pl={2} pr={2} mt={5} mb={10} id="detailMatchRate">
              <MFormik
                enableReinitialize="true"
                initialValues={{
                  tag: "",
                  percentOfRate: percentRates,
                  words: words,
                }}
                validationSchema={Yup.object({})}
                onSubmit={(values: any) => {
                  if (calculateTotalWords(values) > 0) {
                    setZeroWordError(0);
                    if (!selectedMatchRate && isEdit == 1) {
                      const newServiceRates = [];

                      for (let i = 0; i < servicesMatchRate.length; i++) {
                        if (
                          servicesMatchRate[i].index == serviceIndex &&
                          selectedMatchRate
                        ) {
                          newServiceRates.push(servicesMatchRate[i]);
                        }
                      }

                      setServicesMatchRate(newServiceRates);
                    } else if (selectedMatchRate) {
                      const newServices = [];
                      const oldServices = servicesMatchRate;
                      for (let i = 0; i < oldServices.length; i++) {
                        if (
                          oldServices[i].index != serviceIndex &&
                          oldServices[i].matchRate == selectedMatchRate
                        ) {
                          newServices.push(oldServices[i]);
                        }
                      }
                      const wrds = calculateTotalWords(values);
                      const serviceValues = {
                        index: serviceIndex,
                        values: values,
                        matchRate: selectedMatchRate,
                        selectedService: selectedService,
                        totalAmount: calculateTotalAmount(values),
                        totalWords: calculateTotalWords(values),
                      };

                      newServices.push(serviceValues);

                      setServicesMatchRate(newServices);
                      setFieldValue(`serviceForms.${serviceIndex}.number`, wrds);
                    }

                    setModalOpen(false);
                    setSelectedMatchRate(null);
                    setSelectedMatchRateData(false);
                    setSelectedMatchRateId(0);
                  } else {
                    setZeroWordError(1);
                  }
                }}
              >
                {({ values }: any) => {
                  return (
                    <MForm>
                      <MBox>
                        {selectedMatchRate && (
                          <MTableContainer component={MPaper}>
                            <MTable
                              aria-label="match rates table"
                              className={classes.matchRateTable}
                            >
                              <MTableHead>
                                <MTableRow>
                                  <MTableCell>
                                    <MBox fontFamily="Poppins" align="center">
                                      <FormattedMessage id="match.types" />
                                    </MBox>
                                  </MTableCell>
                                  <MTableCell align="center">
                                    <MBox fontFamily="Poppins">
                                      <FormattedMessage id="percent.rate" />
                                    </MBox>
                                  </MTableCell>
                                  <MTableCell align="center">
                                    <MBox fontFamily="Poppins">
                                      <FormattedMessage id="rate" />
                                    </MBox>
                                  </MTableCell>
                                  <MTableCell align="center">
                                    <MBox fontFamily="Poppins">
                                      <FormattedMessage id="words" />
                                    </MBox>
                                  </MTableCell>
                                  <MTableCell align="center">
                                    <MBox fontFamily="Poppins">
                                      <FormattedMessage id="subtotal" />
                                    </MBox>
                                  </MTableCell>
                                </MTableRow>
                              </MTableHead>
                              <MTableBody>
                                {selectedMatchRate &&
                                  rows &&
                                  rows.map((row: any, idx: number) => (
                                    <MTableRow key={idx}>
                                      <MTableCell
                                        component="th"
                                        scope="row"
                                        align="center"
                                      >
                                        {row && row.matchType}
                                      </MTableCell>
                                      <MTableCell align="center">
                                        <MBox display="flex" alignItems="center">
                                          <MBox>
                                            <MTextField
                                              fullWidth
                                              type="number"
                                              value={
                                                values.percentOfRate &&
                                                typeof values.percentOfRate[
                                                row.index
                                                ] != "undefined" &&
                                                values.percentOfRate[row.index]
                                              }
                                              name={`percentOfRate.${row.index}`}
                                              variant="outlined"
                                              color="secondary"
                                              size="small"
                                              disabled={false}
                                              placeholder={row.percentOfRate}
                                              defaultValue={row.percentOfRate}
                                              inputProps={{
                                                max: "100",
                                                min: "0",
                                                step: "any",
                                              }}
                                            />
                                          </MBox>
                                          <MBox>&nbsp;&nbsp;%</MBox>
                                        </MBox>
                                      </MTableCell>
                                      <MTableCell align="center">
                                        {selectedService &&
                                          values.percentOfRate &&
                                          typeof values.percentOfRate[
                                          row.index
                                          ] != "undefined" &&
                                          (
                                            selectedService.price *
                                            (values.percentOfRate[row.index] /
                                              100)
                                          ).toFixed(3)}
                                      </MTableCell>
                                      <MTableCell align="center">
                                        <MBox display="flex" alignItems="center">
                                          <MBox>
                                            <MTextField
                                              fullWidth
                                              type="number"
                                              name={`words.${row.index}`}
                                              variant="outlined"
                                              color="secondary"
                                              size="small"
                                              disabled={false}
                                              placeholder={row.words}
                                              defaultValue={
                                                draftDetail
                                                  ? draftWords(row.matchType)
                                                  : jobAnalysis &&
                                                  jobAnalysis[0] &&
                                                  jobAnalysis[0][
                                                  row && row.matchType
                                                  ]
                                              }
                                              inputProps={{
                                                min: "0",
                                                step: "any",
                                              }}
                                            />
                                          </MBox>
                                        </MBox>
                                      </MTableCell>
                                      <MTableCell align="center">
                                        $
                                        {values && calculateSubTotal(values, row)}
                                      </MTableCell>
                                    </MTableRow>
                                  ))}

                                {selectedMatchRate && (
                                  <MTableRow>
                                    <MTableCell colSpan={4} align="right">
                                      <MBox fontFamily="Poppins" pr="70px" fontWeight='bold'>
                                        Total: {calculateTotalWords(values)}
                                      </MBox>
                                    </MTableCell>
                                    <MTableCell align="center">
                                      <MBox fontFamily="Poppins" fontWeight='bold'>
                                        ${calculateTotalAmount(values)}
                                      </MBox>
                                    </MTableCell>
                                  </MTableRow>
                                )}
                              </MTableBody>
                            </MTable>
                          </MTableContainer>
                        )}
                      </MBox>

                      {zeroWordError == 1 && (
                        <MBox sx={{ color: "#f44336" }}>
                          <FormattedMessage id="total.must.greater.zero" />
                        </MBox>
                      )}

                      <MBox
                        className={classes.drawerFotter}
                        display="flex"
                        justifyContent="flex-end"
                        mt={3}
                      >
                        <MBox mt={0.75} mr={2}>
                          <MLink
                            className="linkGrey"
                            href="javascript:void(0)"
                            onClick={() => {
                              setModalOpen(false);
                              handleModalClose(serviceIndex, setFieldValue);
                              setSelectedMatchRate(null);
                            }}
                          >
                            <FormattedMessage id="cancel" />
                          </MLink>
                        </MBox>
                        <MButton
                          type="submit"
                          color="primary"
                          variant="contained"
                          disabled={loading}
                          style={{ minWidth: 95 }}
                          onClick={() => {
                            setModalOpen(false);
                            handleModalClose(serviceIndex, setFieldValue);
                            setSelectedMatchRateData(false);
                            setSelectedMatchRateId(0);
                            setSelectedMatchRate(null);
                          }}
                        >
                          <FormattedMessage id="save" />
                        </MButton>
                      </MBox>
                    </MForm>
                  );
                }}
              </MFormik>
            </MBox>
          )}
        </MBox>
      </MDrawer>

      <MDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <MDialogTitle id="alert-dialog-title">
          <FormattedMessage id="are.sure" />
        </MDialogTitle>
        <MDialogContent dividers>
          <MBox mr={9}>
            <FormattedMessage id="clicking.selected.rate.deleted" />
          </MBox>
        </MDialogContent>
        <MDialogActions>
          <MBox display="flex" alignItems="center">
            <MBox mr={2}>
              <MLink
                href="javascript:void(0);"
                onClick={handleClose}
                color="inherit"
              >
                <FormattedMessage id="no" />
              </MLink>
            </MBox>
            <MButton
              disabled={deleteLoading}
              className="btnSmall"
              onClick={dialogCloseYes}
              variant="contained"
              color="primary"
              autoFocus
            >
              {deleteLoading && (
                <MCircularProgress color="secondary" size={24} />
              )}
              <FormattedMessage id="yes" />
            </MButton>
          </MBox>
        </MDialogActions>
      </MDialog>

      <AddMatchRates
        openMatchRateDrawer={openMatchRateDrawer}
        setOpenMatchRateDrawer={setOpenMatchRateDrawer}
        createMatchRate={createMatchRate}
        createLoading={createLoading}
      />
      <EditMatchRates
        openEditMatchRateDrawer={openEditMatchRateDrawer}
        setOpenEditMatchRateDrawer={setOpenEditMatchRateDrawer}
        createMatchRate={createMatchRate}
        createLoading={createLoading}
        MatchRateLoading={MatchRateLoading}
        vendorMatchRate={vendorMatchRate}
        setVendorMatchRate={setVendorMatchRate}
        matchRateId={matchRateId}
        setMatchRateId={setMatchRateId}
      />

      {/* Match rate Added/Update Message Start */}
      <FormattedMessage id="match.rate.update.successfully" />
      <FormattedMessage id="match.rate.added.successfully" />
      {/* Match rate Update Message End */}
    </MBox>
  );
}
