export const REFRESH_SIDEBAR_PROJECTS = 'REFRESH_SIDEBAR_PROJECTS';
export const SELECTED_PROJECT_ID = 'SELECTED_PROJECT_ID';
export const SELECTED_PROJECT_NAME = 'SELECTED_PROJECT_NAME';
export const SET_USER_ROLES = 'SET_USER_ROLES';
export const SET_PROJECT_EVENT_SOURCE = 'SET_PROJECT_EVENT_SOURCE';
export const SET_PROJECT_FILE_EVENT_SOURCE = 'SET_PROJECT_FILE_EVENT_SOURCE';

//project Api actions

export const GET_PROJECT_FETCH = 'GET_PROJECT_FETCH';
export const GET_RECENT_PROJECT_FETCH = 'GET_RECENT_PROJECT_FETCH';
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS';
export const GET_RECENT_PROJECT_SUCCESS = 'GET_RECENT_PROJECT_SUCCESS';
