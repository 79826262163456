import React, { useEffect, useState } from 'react';
import useStyles from './FolderHierarchyStyles';
import { MBox, MIconButton, MCheckbox } from 'src/app/material-ui';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import TextField from '@mui/material/TextField'
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { alpha } from '@mui/material/styles';
import { withStyles } from 'tss-react/mui';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import Collapse from '@mui/material/Collapse';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useMutation } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import { gql } from 'apollo-boost';
import { FormErrorMessage } from '../form-error-message/FormErrorMessage';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import { useIntl } from "react-intl";

const CREATE_FOLDER = gql`
    mutation createTags($name:String!, $orgId:Int!, $parentTagId:Int, $moduleName:String, $moduleId:String) {
        createTags(name:$name, orgId:$orgId, parentTagId:$parentTagId, moduleName:$moduleName, moduleId:$moduleId) {
            last
            name
            projectCount
            id
            createdAt
        }
    }
`;

interface IFiles {
    id: number,
    name: string
}

interface IDirectories {
    id?: number,
    name: string,
    files: Array<IFiles>,
    directories: Array<IDirectories>
}

interface IFolderHierarchy {
    data: Array<IDirectories>,
    checkBox: boolean,
    newFolder?: boolean,
    setNewFolder?: (val: any) => void,
    setSelectedFolder?: (val: any) => void,
    selectedFolder?: string | number,
    setFieldValue?: (key: any, val: any) => void,
    fieldName?: string
}

interface IDataInTreeView {
    data: IDirectories,
    allData: Array<IDirectories>,
    checkBox: boolean,
    setCheckedId: (val: any) => void,
    checkedId: Array<number>,
    setSelectedFolder?: (val: any) => void,
    selectedFolder?: string | number,
    setNewFolder?: (val: any) => void,
    createFolder?: (val: any) => void,
}


function TransitionComponent(props: any) {
    return (
        <Collapse {...props} />
    );
}

const StyledTreeItem = withStyles(
    (props: any) => <TreeItem {...props} TransitionComponent={TransitionComponent} />,
    (theme) => ({
        group: {
            marginLeft: 7,
            paddingLeft: 18,
            borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
        },
    })
);


const DataInTreeView = ({
    setCheckedId,
    checkedId,
    checkBox,
    setSelectedFolder,
    selectedFolder,
    setNewFolder,
    data, }: IDataInTreeView) => {

    const classes = useStyles({});

    // const checkRootDir = (allFilesChecked: Array<number>) => {
    // let checkRootFolder:Array<boolean> = [];
    // allData[0].files.forEach((f:IFiles) => {
    //     checkRootFolder[checkRootFolder.length] = allFilesChecked.find((id:Number) => id === f.id)?true:false;
    // });
    // allData[0].directories.forEach((d:IDirectories) => {
    //     checkRootFolder[checkRootFolder.length] = allFilesChecked.find((id:Number) => id === d.id)?true:false;
    //     d.files.forEach((f:IFiles) => {
    //         checkRootFolder[checkRootFolder.length] = allFilesChecked.find((id:Number) => id === f.id)?true:false;
    //     });
    // });
    // if(checkRootFolder.find((val:boolean) => val === false) === undefined){
    //     allFilesChecked.push(-1);
    // }
    // }


    return (
        <>
            <StyledTreeItem
                className={classes.treeItem}
                nodeId={`${data.name}${data.id ? data.id : -1}nodeId`}
                label={
                    <MBox
                        display="flex"
                        onClick={(event: any) => {
                            if (checkBox) {
                                event.stopPropagation();
                            }
                            if (setNewFolder && setSelectedFolder) {
                                setNewFolder(false)
                                setSelectedFolder(data.id ? data.id : -1);
                            }
                        }}
                    >
                        {checkBox &&
                            <MBox className={classes.checkboxWrap} >
                                <MCheckbox
                                    checked={checkedId.find((id: number) => id === Number(data.id ? data.id : -1)) ? true : false}
                                    onChange={(event: any, value: any) => {
                                        let allFilesChecked: Array<number> = [...checkedId];
                                        if (value) {
                                            allFilesChecked.push(data.id ? data.id : -1);
                                            data.files.forEach((file: IFiles) => allFilesChecked.push(file.id));
                                            // checkRootDir(allFilesChecked)
                                        } else {
                                            allFilesChecked = allFilesChecked.filter((id: number) => id !== Number(data.id ? data.id : -1));
                                            data.files.forEach((file: IFiles) => {
                                                allFilesChecked = allFilesChecked.filter((id: number) => id !== Number(file.id));
                                            });
                                        }
                                        setCheckedId(allFilesChecked.filter((v, i, a) => a.indexOf(v) === i));
                                    }}
                                />
                            </MBox>
                        }
                        <FolderOpenIcon style={{ color: (selectedFolder === (data.id ? data.id : -1)) ? '#3f74e8' : '#6B778C', marginRight: 8 }} />
                        <MBox
                            fontFamily={(selectedFolder === (data.id ? data.id : -1)) ? 'Poppins' : 'Poppins'}
                            sx={{ color: (selectedFolder === (data.id ? data.id : -1)) ? '#3f74e8' : '#6B778C' }}
                        >
                            {/* {JSON.stringify(data)} */}
                            {data.name}
                        </MBox>
                    </MBox>
                }>
            </StyledTreeItem>
        </>
    )
}

export default function FolderHierarchy({ checkBox, newFolder, setFieldValue, fieldName, selectedFolder, setSelectedFolder, setNewFolder, data }: IFolderHierarchy) {
    const classes = useStyles({});
    // const dispatch = useDispatch();
    const [checkedId, setCheckedId] = useState<Array<number>>([]);
    const selectedOrgId = useSelector((store: any) => store.orgs.selectedOrgId);
    const projectId = useSelector((store: any) => store.projects.selectedProjectId);
    const [folderName, setFolderName] = useState('');
    const [formErrors, setFormErrors]: any = useState({})
    const [isSubmit, setIsSubmit] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')

    const [createFolder] = useMutation(CREATE_FOLDER, {
        fetchPolicy: 'no-cache'
    });
    const intl = useIntl();
    useEffect(() => {
        let filterFileId = [...checkedId]
        if (data && setFieldValue) {
            filterFileId = filterFileId.filter((val: number | undefined) => val !== undefined);
            filterFileId = filterFileId.filter((val: number | undefined) => val !== -1);
            data.forEach((dir: IDirectories) => {
                filterFileId = filterFileId.filter((id: number) => id !== (dir.id ? dir.id : -1));
            });
            setFieldValue(fieldName, filterFileId)
        }
    }, [checkedId]);

    const validationSchema = Yup.object().shape({
        folderName: Yup.string().required(intl.formatMessage({ id: "folder.name.required" })),

    });


    useEffect(() => {
        validationSchema.validate({ folderName: folderName }, { abortEarly: false })
            .then(() => setFormErrors({ inner: [] }))
            .catch(err => setFormErrors(err));
    }, [folderName]);

    return (
        <>
            <TreeView
                id="treeViewParent"
                className={classes.root}
                defaultCollapseIcon={<IndeterminateCheckBoxIcon className={classes.treeActionButton} />}
                defaultExpandIcon={<AddBoxIcon className={classes.treeActionButton} />}
            >
                {data && data.map((dir: IDirectories) => (
                    <DataInTreeView
                        key="data"
                        setSelectedFolder={setSelectedFolder}
                        selectedFolder={selectedFolder}
                        setNewFolder={setNewFolder}
                        data={dir}
                        allData={data}
                        checkBox={checkBox}
                        createFolder={createFolder}
                        setCheckedId={setCheckedId}
                        checkedId={checkedId}
                    />
                ))}
            </TreeView>
            {newFolder &&
                <MBox position="relative">
                    <FormattedMessage id="folder.name">
                        {folderName =>
                            <TextField
                                variant="outlined"
                                size="small"
                                placeholder={String(folderName)}
                                className={classes.newFolderInput}
                                onInput={(e: any) => {
                                    setFolderName(e?.target?.value)
                                }}
                            />
                        }
                    </FormattedMessage>
                    <MBox display="inline-block" maxWidth="90px" verticalAlign="middle" className={classes.newFolderActions}>
                        <MIconButton variant="contained">
                            <DoneIcon sx={{ fill: '#16c68e !important' }} fontSize="small" onClick={() => {
                                const checkIndex = data.findIndex((x: any) => x.name === folderName);
                                if (checkIndex < 0) {
                                    if (formErrors && (formErrors.inner.length > 0)) {
                                        setIsSubmit(true);
                                    } else {
                                        createFolder({
                                            variables: {
                                                name: folderName,
                                                parentTagId: null,
                                                moduleName: 'project',
                                                moduleId: projectId,
                                                orgId: selectedOrgId
                                            }
                                        }).then((res: any) => {

                                            data.push({
                                                id: Number(res.data.createTags.id),
                                                name: res.data.createTags.name,
                                                directories: [],
                                                files: []
                                            })
                                            if (setNewFolder) {
                                                setNewFolder(false);
                                            }
                                            setFolderName('');
                                            setIsSubmit(false);
                                        })
                                    }
                                } else {
                                    // setIsSubmit(true);
                                    setErrorMsg('Folder already exist!');
                                }
                            }} />
                            {errorMsg && errorMsg ?

                                <MBox className={classes.error}>
                                    <FormattedMessage id="folder.exist" />
                                </MBox>

                                : ''

                            }

                        </MIconButton>
                        <MIconButton variant="contained" color="default">
                            <CloseIcon fontSize="small" sx={{ fill: '#f44336 !important' }} onClick={() => {
                                if (setNewFolder) {
                                    setNewFolder(false);
                                    setIsSubmit(false);
                                    setFolderName('');
                                    setErrorMsg('');
                                }
                            }} />

                        </MIconButton>
                    </MBox>
                    {isSubmit && formErrors && (formErrors.inner.find((err: any) => err.path === 'folderName') !== undefined) &&
                        <MBox className={classes.errorMessage}>
                            <FormErrorMessage message={formErrors.inner.find((err: any) => err.path === 'folderName').message} />
                        </MBox>
                    }
                </MBox>
            }

        </>
    )
}