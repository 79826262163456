import { makeStyles } from '@mui/styles';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles((theme) =>
({
  emptyCaseWrap: {
    backgroundColor: "#f4f5f7",
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    overflow: "hidden",
  },

  dragIconWrap: {},

  dragIcon: {
    width: 50,
    animation: `$iconDragEffect .8s ease .5s infinite alternate`,
    transformOrigin: "bottom center",
    transform: "rotate(30deg)",
  },
  dndContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '16px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    boxShadow: theme.shadows[2],
    minHeight: '200px',
  },
  dndItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px',
    backgroundColor: theme.palette.grey[100],
    borderRadius: '4px',
    boxShadow: theme.shadows[1],
    transition: 'background-color 0.3s ease',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
    '&:active': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  workFlowWrap: {
    [theme.breakpoints.down('md')]: {
      flexDirection: "column",
    },
  },

  dragBox: {
    maxHeight: 360,
    paddingRight: 32,
    [theme.breakpoints.down('md')]: {
      paddingRight: 0,
      marginBottom: 24,
    },
  },

  dragBoxTxt: {
    marginTop: 12,
    color: "#909090",
    fontSize: 16,
    [theme.breakpoints.down('xl')]: {
      fontSize: 14,
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 13,
    },
  },

  dragAbleTxt: {
    marginBottom: 24,
    // marginTop: '-47px',
    fontSize: 18,
    [theme.breakpoints.down('md')]: {
      marginTop: 10,
    },
  },

  servicesContainer: {
    boxShadow: "0px 0px 5px rgba(193, 199, 208, 0.26)",

    backgroundColor: "#fff",
    borderRadius: 5,
    flex: 1,
  },

  packagesContainer: {
    boxShadow: "0px 0px 5px rgba(193, 199, 208, 0.26)",

    backgroundColor: "#f5f5f5",
    borderRadius: 5,
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },

  selectServicesContainer: {
    backgroundColor: "#f5f6f7",
    /* max-height: 169px, */
    height: 131,
    borderRadius: 5,
    boxShadow: "0px 0px 5px rgba(193, 199, 208, 0.26)",
    "& > .MuiBox-root": {
      backgroundColor: "#ffff",
    },
  },

  selectServicesBtn: {
    boxShadow: "none",
    // backgroundColor: 'black',
    // borderRadius: '5px 5px 0px 0px',
    // width: '100%',
    marginBottom: 15,
    fontSize: 12,
    padding: "5px 10px",
  },

  "@keyframes iconDragEffect": {
    "0%": {
      transform: "rotate(30deg)",
    },
    "100%": {
      transform: "rotate(-10deg)",
    },
  },

  SelectWorkflowOptions: {
    "& .MuiFormGroup-root": {
      flexDirection: "row",
    },
  },

  selectOptionWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: 410,
    "& .MuiSvgIcon-root": {
      height: 15,
      width: 15,
    },
    "& .MuiTypography-root": {
      fontSize: 12,
    },
    "& .MuiButtonBase-root": {
      color: "#959595",
      textDecoration: "none",
      textTransform: "none",
      fontSize: "12px",
      "&:hover": {
        backgroundColor: "transparent",
        textDecoration: "underline",
      },
    },
  }
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;
