import { FETCH_JOBS_REQUEST, FETCH_JOBS_SUCCESS, FETCH_JOBS_FAILURE } from './JobActionTypes';


interface IAction {
    type: string,
    payload: any,
    response: any,
    action: any,
}
interface IState {
    jobsList: any,
    jobLoading: boolean,
    error: any
}
const initialState: IState = {
    jobsList: null,
    jobLoading: false,
    error: null
};

export default function JobReducer(state = initialState, action: IAction) {
    switch (action.type) {
        case FETCH_JOBS_REQUEST:
            return {
                ...state,
                jobLoading: true,
                error: null
            };
        case FETCH_JOBS_SUCCESS:
            return {
                ...state,
                jobsList: action.payload,
                jobLoading: false
            };
        case FETCH_JOBS_FAILURE:
            return {
                ...state,
                jobLoading: false,
                error: action.payload
            };
        default:
            return state;
    }
}