import React, { Fragment } from 'react'

export function MTotalIcon() {
    return (
        <Fragment>

            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <path d="M21.2501 9.98229C22.3213 9.11882 23.4247 7.95791 23.9844 6.54576C24.2767 5.80879 24.1818 4.97247 23.733 4.30827C23.2705 3.62327 22.5031 3.21436 21.6821 3.21436H14.3184C13.4975 3.21436 12.7301 3.62327 12.2675 4.30827C11.8182 4.97247 11.725 5.80879 12.0161 6.54576C12.5759 7.95676 13.6793 9.11825 14.7505 9.98229C9.03615 12.1118 4.5 19.4284 4.5 25.3929C4.5 29.47 7.80273 32.7858 11.8637 32.7858H24.1363C28.1973 32.7858 31.5 29.47 31.5 25.3929C31.5 19.4284 26.9639 12.1118 21.2495 9.98229H21.2501ZM21.7028 5.63552C21.0695 7.23364 19.133 8.584 17.9974 9.22105C16.8647 8.58919 14.9352 7.24982 14.3167 5.67883L21.7023 5.63552H21.7028ZM24.1352 30.3213H11.8637C9.15638 30.3213 6.95475 28.111 6.95475 25.3929C6.95475 18.9328 12.7353 11.8392 18.0003 11.8392C23.2653 11.8392 29.0458 18.9328 29.0458 25.3929C29.0458 28.111 26.8442 30.3213 24.1369 30.3213H24.1357H24.1352ZM17.9986 30.3213C17.3209 30.3213 16.7715 29.7692 16.7715 29.0894V27.8574H16.4424C15.1319 27.8574 13.9106 27.1499 13.2542 26.0103C12.9153 25.4212 13.1155 24.6687 13.7023 24.3273C14.2862 23.986 15.0387 24.187 15.3787 24.7772C15.5973 25.1567 16.0058 25.3935 16.443 25.3935H19.2262C19.9022 25.3935 20.4533 24.8402 20.4533 24.1616C20.4533 23.696 20.1208 23.3016 19.6629 23.2253L15.9293 22.6004C14.2834 22.3255 13.0879 20.9087 13.0879 19.2332C13.0879 17.1949 14.7396 15.5367 16.7697 15.5367V14.3048C16.7697 13.6244 17.3197 13.0728 17.9968 13.0728C18.674 13.0728 19.2239 13.625 19.2239 14.3048V15.5367H19.553C20.8623 15.5367 22.0848 16.2454 22.7412 17.385C23.0801 17.9741 22.8799 18.7266 22.2931 19.068C21.7074 19.4093 20.9567 19.2083 20.6167 18.6181C20.3969 18.2375 19.9896 18.0018 19.5524 18.0018H16.7692C16.0932 18.0018 15.5421 18.5551 15.5421 19.2338C15.5421 19.6993 15.8746 20.0938 16.3325 20.17L20.0661 20.7949C21.712 21.0698 22.9075 22.4866 22.9075 24.1621C22.9075 26.2004 21.2558 27.8586 19.2257 27.8586V29.0905C19.2257 29.7709 18.6757 30.3225 17.9986 30.3225V30.3213Z" fill="#A2A4B9"/>
            </svg>

        </Fragment>
    )
}
