/* eslint-disable */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';

import * as serviceWorker from './serviceWorker';
import App from './app/App';
import store from './app/store/store';
import './index.css';

import ThemeConfig from './app/material-ui/ThemeConfig';

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme { }
}

const container: any = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Provider store={store}>
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={ThemeConfig}>
            <CssBaseline />
            <Router>
                <App />
            </Router>
        </ThemeProvider>
    </StyledEngineProvider>
</Provider>);
serviceWorker.unregister();
