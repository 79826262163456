/* eslint-disable */
import React, { useEffect } from 'react'
import AnimatedText from 'react-animated-text-content';
import { MBox } from 'src/app/material-ui';
import { MRedirectLogo } from 'src/app/material-ui/icons';
import { gql } from 'apollo-boost';
import { useMutation } from "@apollo/react-hooks";
import {
  sideBarShow,
} from 'src/app/store';
import { useDispatch } from 'react-redux';
import { headerShow } from "src/app/store/show-sidebar/ShowSideBarReducer";


const SET_PACKAGE_AND_GET_PAYMENT_LINK = gql`
  mutation SetPackageAndGetPaymentLink($packageId: Int!, $role: String) {
    setPackageAndGetPaymentLink(packageId: $packageId, role: $role)
  }
`;

export default function Redirect() {
  const dispatch = useDispatch();

  // const urlParts = window.location.href.split('/');
  // const lastPart = urlParts.pop() || ''; // Fallback to empty string if undefined
  //
  // let [packageId, role] = lastPart.split('?');
  // role = role.split('=')[1];
  const url = new URL(window.location.href);

  // Extract the packageId (last part of the pathname)
  const urlParts = url.pathname.split('/');
  const packageId = urlParts.pop() || ''; // Ensure it's never undefined

  // Extract the role from query parameters
  const params = new URLSearchParams(url.search);
  const role = params.get("role") || ''; // Default to empty string if not found

  // let packageId:any = window.location.href.split('/').pop();
  //
  // let role:any = window.location.href.split('/')[5];
  // const isInteger = (value:any) => {
  //     console.log("value", typeof value);

  // let packageId:any = window.location.href.split('/').pop();
  //
  // let role:any = window.location.href.split('/')[5];
  // const isInteger = (value:any) => {
  //     console.log("value", typeof value);

  //     return Number.isInteger(value);
  // };

  useEffect(() => {

    // alert(role);
    // alert(packageId);

    // if(isInteger(packageId)) {
    dispatch(sideBarShow(false));
    dispatch(headerShow(false));
    createCustomerPO({
      variables: {
        packageId: Number(packageId),
        role: role
      }
    });
    // }
  }, [packageId]);

  const [createCustomerPO] = useMutation(SET_PACKAGE_AND_GET_PAYMENT_LINK, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const paymentLink = data.setPackageAndGetPaymentLink;

      if (paymentLink) {
        window.location.href = paymentLink;

      }
    },
  });

  return (
    <MBox
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="86vh"
      flexDirection="column"
      sx={{
        overflow: 'hidden', // Prevent scrollbar
        margin: 0,
        padding: 0,
        position: 'relative', // Make sure positioning is correct
        width: '100%', // Full width
      }}
    >
      <MRedirectLogo />

      <AnimatedText
        type="words" // animate words or chars
        animation={{
          x: '200px',
          y: '-20px',
          scale: 1.1,
          ease: 'ease-in-out',
        }}
        animationType="wave"
        interval={0.10}
        duration={0.8}
        tag="p"
        className="animated-paragraph"
        includeWhiteSpaces
        threshold={0.1}
        rootMargin="20%"
      >
        LOADING. . . .
      </AnimatedText>
    </MBox>
  )
}
