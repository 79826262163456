
export const FETCH_JOBS_REQUEST = 'FETCH_JOBS_REQUEST';
export const FETCH_JOBS_SUCCESS = 'FETCH_JOBS_SUCCESS';
export const FETCH_JOBS_FAILURE = 'FETCH_JOBS_FAILURE';

export const FETCH_JOB_FILES_REQUEST = 'FETCH_JOB_FILES_REQUEST';
export const FETCH_JOB_FILES_SUCCESS = 'FETCH_JOB_FILES_SUCCESS';
export const FETCH_JOB_FILES_FAILURE = 'FETCH_JOB_FILES_FAILURE';

export const FETCH_ASSIGNED_JOB_FILES_REQUEST = 'FETCH_ASSIGNED_JOB_FILES_REQUEST';
export const FETCH_ASSIGNED_JOB_FILES_SUCCESS = 'FETCH_ASSIGNED_JOB_FILES_SUCCESS';
export const FETCH_ASSIGNED_JOB_FILES_FAILURE = 'FETCH_ASSIGNED_JOB_FILES_FAILURE';

export const FETCH_JOB_LANGUAGES_REQUEST = 'FETCH_JOB_LANGUAGES_REQUEST';
export const FETCH_JOB_LANGUAGES_SUCCESS = 'FETCH_JOB_LANGUAGES_SUCCESS';
export const FETCH_JOB_LANGUAGES_FAILURE = 'FETCH_JOB_LANGUAGES_FAILURE';

export const FETCH_PROFILE_DATA_REQUEST = 'FETCH_PROFILE_DATA_REQUEST';
export const FETCH_PROFILE_DATA_SUCCESS = 'FETCH_PROFILE_DATA_SUCCESS';
export const FETCH_PROFILE_DATA_FAILURE = 'FETCH_PROFILE_DATA_FAILURE';

export const FETCH_LINGUIST_PROFILE_DATA_REQUEST = 'FETCH_LINGUIST_PROFILE_DATA_REQUEST';
export const FETCH_LINGUIST_PROFILE_DATA_SUCCESS = 'FETCH_LINGUIST_PROFILE_DATA_SUCCESS';
export const FETCH_LINGUIST_PROFILE_DATA_FAILURE = 'FETCH_LINGUIST_PROFILE_DATA_FAILURE';