import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tabsWrapper: {
            backgroundColor: 'transparent',
        },
        tabsBar: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            '& svg': {
                fill: 'black',
            },
        },
        tabs: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            borderBottom: 'none',
        },
        panelWrapper: {
            backgroundColor: '#fff',
            // minHeight: 500,
            boxShadow: '0px 5px 5px 0px rgba(193, 199, 208, 0.26)',
            borderRadius: 5,
        },
        panelWr: {
            backgroundColor: '#fff',
            // minHeight: 500,
            boxShadow: 'none',
            borderRadius: 5,
            border: '1px solid #E8E8E8',
            marginTop: 0,
            marginBottom: 0
        },
        tab: {
            textTransform: 'capitalize',
            fontSize: '14px',
            padding: 0
        },
        tabNavLink: {
            color: '#3a3a3a',
            textDecoration: 'none',
            '& button': {
                opacity: 1
            },
            [theme.breakpoints.only('md')]: {
                minWidth: 130,
            },
            [theme.breakpoints.down('xl')]: {
                minWidth: 112,
            },
            '& .MuiTab-root': {
                [theme.breakpoints.only('md')]: {
                    minWidth: 130,
                },
                [theme.breakpoints.down('xl')]: {
                    minWidth: 112,

                },
                [theme.breakpoints.up('sm')]: {
                    minWidth: 160,

                },
            },

        },
        formCustomer: {
            border: '1px solid #E8E8E8',
            backgroundColor: '#FDFDFD',
        },
        overflow: {
            backgroundColor: '#fff',
            // minHeight: 500,
            boxShadow: '0px 5px 5px 0px rgba(193, 199, 208, 0.26)',
            borderRadius: 5,
            border: '1px solid #ECEDF0',
            // '& >.MuiBox-root': {
            //     border: '1px solid #ECEDF0',
            //     '&:first-child': {
            //         overflowX: 'hidden',
            //         overflowY: 'auto',
            //         maxHeight: 600,
            //         padding: '0px !important',
            //         '& >.MuiBox-root': {
            //             padding: 24
            //         }
            //     }

            // }
        },
        overflowScroll: {
            minHeight: 600,
            overflowX: 'hidden',
            //   overflowY: 'auto',
        }

    }));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;