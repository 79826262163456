import React, { Fragment } from 'react'

export function MApproved(props: any) {
    return (
        <Fragment>
            {/*<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
            {/*<path d="M5 20H19V18H5V20ZM19 9H15V3H9V9H5L12 16L19 9Z" fill="#A7ADBB" />*/}
            {/*</svg>*/}
            <svg {...props} xmlns="http://www.w3.org/2000/svg" width="22" height="13" viewBox="0 0 22 13" fill="none">
                <path d="M0 7.58495L5.28946 13L6.62366 11.6244L1.34366 6.21908L0 7.58495ZM20.6563 0L10.6452 10.2586L6.70882 6.21908L5.3557 7.58495L10.6452 13L22 1.37556L20.6563 0ZM16.6443 1.37556L15.3101 0L9.30151 6.15127L10.6452 7.51714L16.6443 1.37556Z" fill={props.color ? "#16C68E" : "#A7ADBB"} />
            </svg>
        </Fragment>
    )
}
