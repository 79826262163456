/* eslint-disable*/
import TextField from "@mui/material/TextField/TextField";
import Autocomplete from "@mui/lab/Autocomplete/Autocomplete";
import clsx from 'clsx';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { MKeyboardArrowDownRoundedIcon } from 'src/app/material-ui/icons/keyboard-rounded-icon/KeyboardArrowDownRoundedIcon';
import useStyles from './UnitSelectStyles';


export function UnitSelect(props: any) {
    const intl = useIntl();
    const classes: any = useStyles({});
    const [data, setData]: any = useState([]);
    const words = intl.formatMessage({ id: 'words' });
    const minutes = intl.formatMessage({ id: 'minutes' });
    const pages = intl.formatMessage({ id: 'pages' });
    const matchRates = intl.formatMessage({ id: 'match.rates' });
    //  const [matchRates, setMatchRates]: any = useState();
    useEffect(() => {
        setData([
            { id: 1, name: words },
            { id: 2, name: minutes },
            { id: 3, name: pages }
        ]);
    }, []);
    useEffect(() => {
        if (props.selectedService && props.selectedService.service && props.selectedService.service.isMatchRate === 1) {
            setData([
                { id: 1, name: words },
                { id: 2, name: minutes },
                { id: 3, name: pages },
                { id: 4, name: matchRates }
            ]);
        } else {
            setData([
                { id: 1, name: words },
                { id: 2, name: minutes },
                { id: 3, name: pages }
            ]);
        }
    }, [props.selectedService]);
    return (
        <div className="sourceLangSelect">
            <FormattedMessage id="select.unit">
                {selectUnit =>
                    <Autocomplete
                        {...props}
                        id="units"
                        size="small"
                        limitTags={1}
                        popupIcon={<MKeyboardArrowDownRoundedIcon />}
                        className={clsx(classes.inputWidth, classes.sourceLangSelect, classes[props.size ? props.size : 'sizeMd'])}
                        options={data && data}
                        value={props.value && props.value}
                        defaultValue={props.defaultValue && props.defaultValue}
                        getOptionLabel={(option: any) => option.name}
                        disableClearable={true}
                        onChange={(event: SyntheticEvent, value: any) => {
                            props.setFieldValue(props.name, value)

                            if (value.id === 4 && props.selectedService /*&& Number(props.selectedService.price) > 0*/) {
                                props.setSelectedService(props.selectedService);
                                props.setShowAddMatchRateModal(true);
                                props.setServiceIndex(props.index);
                                props.setIsEdit(0);
                            }
                            event?.preventDefault();
                        }}
                        renderInput={params => <TextField {...params} label={String(selectUnit)} placeholder={String(selectUnit)} variant="outlined" color="secondary" />}
                    />
                }
            </FormattedMessage>

            {/* Setting state to use state value in object */}
            {/* <FormattedMessage id="words">
                {words => setWords(words)}
            </FormattedMessage>
            <FormattedMessage id="minutes">
                {minutes => setMinutes(minutes)}
            </FormattedMessage>
            <FormattedMessage id="pages">
                {pages => setPages(pages)}
            </FormattedMessage>
            <FormattedMessage id="match.rates.templates">
                {matchRates => setMatchRates(matchRates)}
            </FormattedMessage> */}
        </div>
    )
}
