import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';
// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles((theme?: Theme) =>
  createStyles({
    inputWidth: {
      width: '100%',
    },

    sourceLangSelect: {
      "&.MuiSelect-outlined": {
        borderColor: theme?.palette.secondary.main,
      },
      '& .MuiInputBase-colorSecondary.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '2px solid #6b778c'
      },
    },
    redBorder: {
      // "& fieldset":{
      //   borderColor: '#f44336 !important'
      // }
    }
  }));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;