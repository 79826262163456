import React, {Fragment} from 'react'

export function MTAmount() {
    return (
        <Fragment>


            <svg width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17.2501 6.98229C18.3213 6.11882 19.4247 4.95791 19.9844 3.54576C20.2767 2.80879 20.1818 1.97247 19.733 1.30827C19.2705 0.623273 18.5031 0.214355 17.6821 0.214355H10.3184C9.49751 0.214355 8.73007 0.623273 8.26754 1.30827C7.81824 1.97247 7.72504 2.80879 8.01614 3.54576C8.5759 4.95676 9.6793 6.11825 10.7505 6.98229C5.03615 9.11177 0.5 16.4284 0.5 22.3929C0.5 26.47 3.80273 29.7858 7.86369 29.7858H20.1363C24.1973 29.7858 27.5 26.47 27.5 22.3929C27.5 16.4284 22.9639 9.11177 17.2495 6.98229H17.2501ZM17.7028 2.63552C17.0695 4.23364 15.133 5.584 13.9974 6.22105C12.8647 5.58919 10.9352 4.24982 10.3167 2.67883L17.7023 2.63552H17.7028ZM20.1352 27.3213H7.86369C5.15638 27.3213 2.95475 25.111 2.95475 22.3929C2.95475 15.9328 8.73525 8.83916 14.0003 8.83916C19.2653 8.83916 25.0458 15.9328 25.0458 22.3929C25.0458 25.111 22.8442 27.3213 20.1369 27.3213H20.1357H20.1352ZM13.9986 27.3213C13.3209 27.3213 12.7715 26.7692 12.7715 26.0894V24.8574H12.4424C11.1319 24.8574 9.91056 24.1499 9.25416 23.0103C8.91532 22.4212 9.11552 21.6687 9.70231 21.3273C10.2862 20.986 11.0387 21.187 11.3787 21.7772C11.5973 22.1567 12.0058 22.3935 12.443 22.3935H15.2262C15.9022 22.3935 16.4533 21.8402 16.4533 21.1616C16.4533 20.696 16.1208 20.3016 15.6629 20.2253L11.9293 19.6004C10.2834 19.3255 9.0879 17.9087 9.0879 16.2332C9.0879 14.1949 10.7396 12.5367 12.7697 12.5367V11.3048C12.7697 10.6244 13.3197 10.0728 13.9968 10.0728C14.674 10.0728 15.2239 10.625 15.2239 11.3048V12.5367H15.553C16.8623 12.5367 18.0848 13.2454 18.7412 14.385C19.0801 14.9741 18.8799 15.7266 18.2931 16.068C17.7074 16.4093 16.9567 16.2083 16.6167 15.6181C16.3969 15.2375 15.9896 15.0018 15.5524 15.0018H12.7692C12.0932 15.0018 11.5421 15.5551 11.5421 16.2338C11.5421 16.6993 11.8746 17.0938 12.3325 17.17L16.0661 17.7949C17.712 18.0698 18.9075 19.4866 18.9075 21.1621C18.9075 23.2004 17.2558 24.8586 15.2257 24.8586V26.0905C15.2257 26.7709 14.6757 27.3225 13.9986 27.3225V27.3213Z"
                    fill="#A2A4B9"/>
            </svg>


        </Fragment>
    )
}
