import React, { Fragment } from 'react'

export function MInfo() {
    return (
        <Fragment>

            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.66536 4.99992H6.33203V3.66658H7.66536V4.99992ZM7.66536 10.3333H6.33203V6.33325H7.66536V10.3333ZM6.9987 0.333252C6.12322 0.333252 5.25631 0.50569 4.44747 0.840722C3.63864 1.17575 2.90371 1.66682 2.28465 2.28587C1.03441 3.53612 0.332031 5.23181 0.332031 6.99992C0.332031 8.76803 1.03441 10.4637 2.28465 11.714C2.90371 12.333 3.63864 12.8241 4.44747 13.1591C5.25631 13.4941 6.12322 13.6666 6.9987 13.6666C8.76681 13.6666 10.4625 12.9642 11.7127 11.714C12.963 10.4637 13.6654 8.76803 13.6654 6.99992C13.6654 6.12444 13.4929 5.25753 13.1579 4.4487C12.8229 3.63986 12.3318 2.90493 11.7127 2.28587C11.0937 1.66682 10.3588 1.17575 9.54992 0.840722C8.74108 0.50569 7.87418 0.333252 6.9987 0.333252Z" fill="#6B778C" />
            </svg>

        </Fragment>
    )
}

