import React, { Fragment } from 'react'

export function MPayoneerIcon() {
    return (
        <Fragment>

            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="18" height="18" rx="3" fill="#F64D25"/>
                <path d="M16.2123 5.43943C16.2123 5.43943 17.2317 6.11744 16.2123 6.2438C16.2123 6.2438 12.4035 6.66734 9.56851 9.2103C9.56851 9.2103 5.31697 12.7685 3.72185 16.6666C3.72185 16.6666 3.19074 16.6666 3.36839 15.9041C3.54512 15.1416 4.60823 11.7942 9.34714 7.68435C9.34714 7.68435 13.9093 3.70171 16.2123 5.43856V5.43943ZM4.12177 5.94837C4.16549 6.88085 5.00724 10.4391 5.00724 10.4391C5.31697 11.1598 5.58298 10.4391 5.58298 10.4391C6.60145 7.51529 4.52077 5.82114 4.52077 5.82114C3.98876 5.52396 4.12177 5.9475 4.12177 5.9475" fill="white"/>
            </svg>

        </Fragment>
    )
}