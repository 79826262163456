import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputWidth: {
      width: 493,
    },
    teamSelect: {
      "&.MuiSelect-outlined": {
        borderColor: theme.palette.secondary.main,
      },
      '& .MuiOutlinedInput-input': {
        padding: '10px 15px'
      },
      '& .MuiInputLabel-formControl': {
        top: '-7px'
      },
      '& .MuiInputLabel-shrink': {
        top: '0px'
      },
      '&:hover': {
        borderColor: theme.palette.secondary.main,
      },
    },
    teamSelectLabel: {
      backgroundColor: '#fff',
      padding: '0 5px',
    },
    redBorder: {
      // "& fieldset":{
      //   borderColor: '#f44336 !important'
      // }
    }
  }));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;