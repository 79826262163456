import React from 'react';

export function MMinimizeIcon(props: any) {
    return <React.Fragment>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.6654 8.66659H3.33203V7.33325H12.6654V8.66659Z" fill={(props && props?.fill) ? props.fill : "#F44336"} />
        </svg>

    </React.Fragment>;
}


