import React, { ComponentType } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PublicRoute: any = (
    { component: Component, ...rest }:
        { component: ComponentType<any> }
) => {
    // const history = useHistory();
    const isLogin = useSelector((store: any) => store.auth.isLogin);
    const appUserRole = useSelector((store: any) => store.userRole.userRole);

    return (
        <Route
            {...rest}
            render={props =>
                isLogin ? (
                    <>
                        {appUserRole == 'ROLE_CLIENT' &&
                            <Redirect to={{ pathname: "/projects" }} />
                        }
                        {appUserRole == 'ROLE_VENDOR' &&
                            <Redirect to={{ pathname: "/vendor/open-jobs" }} />
                        }
                        {appUserRole == 'ROLE_TRANSLATOR' &&
                            <Redirect to={{ pathname: "linguist/open-jobs" }} />

                        }{appUserRole == 'ROLE_FINANCE_USER' &&
                            <Redirect to={{ pathname: "/manage-payment" }} />

                        }
                    </>
                ) : (
                    <Component {...props} />
                )
            }
        />
    );
}

export default PublicRoute;