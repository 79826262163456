import React, { Fragment } from 'react'

export function DisclaimerIcon() {
    return (
        <Fragment>


            <svg width="163" height="102" viewBox="0 0 163 102" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="47" width="163" height="4" fill="#B22424" />
                <circle cx="81" cy="51" r="51" fill="#FFAA00" />
                <g opacity="0.19" clipPath="url(#clip0_339_5690)" filter="url(#filter0_f_339_5690)">
                    <path d="M76.8102 75.3394C70.7772 75.3394 64.7417 75.3474 58.7088 75.3394C51.4485 75.3287 47.4842 68.5525 51.0119 62.1588C57.0687 51.1764 63.1629 40.218 69.2038 29.2276C70.8944 26.1551 73.381 24.3661 76.8767 24.342C80.431 24.318 82.9655 26.1069 84.6828 29.2276C90.7237 40.2154 96.8178 51.1764 102.877 62.1535C106.402 68.5419 102.435 75.3233 95.1778 75.334C89.0544 75.3447 82.9309 75.334 76.8102 75.334V75.3394ZM79.8027 48.2724C79.8027 47.0262 79.8027 45.7801 79.8027 44.534C79.8027 44.4457 79.8027 44.3548 79.8027 44.2666C79.7334 42.3466 78.5886 41.0978 76.9193 41.1138C75.2979 41.1299 74.1318 42.3519 74.1025 44.1917C74.0626 46.9059 74.0573 49.6201 74.1025 52.3343C74.1345 54.1687 75.3192 55.3453 76.9752 55.3319C78.6286 55.3185 79.7414 54.142 79.7947 52.2781C79.8346 50.9438 79.8027 49.6094 79.8027 48.275V48.2724ZM76.9806 63.8863C78.5274 63.8702 79.7707 62.6321 79.7893 61.0865C79.8106 59.4955 78.5088 58.1959 76.9113 58.2119C75.3725 58.2279 74.1212 59.4794 74.0999 61.0197C74.0786 62.6081 75.3778 63.8997 76.9806 63.8836V63.8863Z" fill="black" />
                    <path d="M79.8005 48.2727C79.8005 49.607 79.8324 50.9441 79.7925 52.2758C79.7366 54.1396 78.6237 55.3162 76.9731 55.3295C75.3197 55.3429 74.135 54.1663 74.1004 52.3319C74.0525 49.6177 74.0578 46.9035 74.1004 44.1893C74.127 42.3496 75.2958 41.1302 76.9172 41.1115C78.5865 41.0954 79.7313 42.3442 79.8005 44.2642C79.8032 44.3525 79.8005 44.4434 79.8005 44.5316C79.8005 45.7777 79.8005 47.0239 79.8005 48.27V48.2727Z" fill="#FFAA00" />
                    <path d="M76.9786 63.8887C75.3758 63.9047 74.0766 62.6131 74.0979 61.0247C74.1192 59.4818 75.3705 58.233 76.9094 58.2169C78.5068 58.2009 79.8087 59.4978 79.7874 61.0916C79.7661 62.6372 78.5254 63.8753 76.9786 63.8913V63.8887Z" fill="#FFAA00" />
                </g>
                <g clipPath="url(#clip1_339_5690)">
                    <path d="M80.8733 71.8145C75.1102 71.8145 69.3446 71.8222 63.5815 71.8145C56.6459 71.8042 52.8589 65.3047 56.2288 59.1719C62.0148 48.6378 67.8364 38.1267 73.6071 27.5848C75.2221 24.6377 77.5975 22.9218 80.9369 22.8987C84.3322 22.8756 86.7534 24.5915 88.3938 27.5848C94.1645 38.1241 99.9861 48.6378 105.775 59.1668C109.142 65.2944 105.352 71.7991 98.4195 71.8093C92.5699 71.8196 86.7203 71.8093 80.8733 71.8093V71.8145ZM83.732 45.8522C83.732 44.657 83.732 43.4617 83.732 42.2665C83.732 42.1818 83.732 42.0946 83.732 42.01C83.6658 40.1684 82.5722 38.9705 80.9776 38.9859C79.4287 39.0013 78.3147 40.1735 78.2868 41.9382C78.2486 44.5416 78.2435 47.145 78.2868 49.7484C78.3173 51.5079 79.4491 52.6365 81.031 52.6237C82.6104 52.6108 83.6735 51.4823 83.7243 49.6945C83.7625 48.4146 83.732 47.1347 83.732 45.8548V45.8522ZM81.0361 60.8289C82.5137 60.8135 83.7014 59.6259 83.7192 58.1434C83.7396 56.6173 82.4959 55.3707 80.9699 55.3861C79.4999 55.4015 78.3046 56.6019 78.2842 58.0793C78.2639 59.6028 79.505 60.8417 81.0361 60.8263V60.8289Z" fill="#B32424" />
                    <path d="M83.7296 45.8515C83.7296 47.1314 83.7601 48.4138 83.722 49.6912C83.6686 51.4789 82.6055 52.6075 81.0286 52.6203C79.4492 52.6331 78.3175 51.5046 78.2844 49.745C78.2386 47.1416 78.2437 44.5382 78.2844 41.9348C78.3098 40.1701 79.4263 39.0005 80.9752 38.9826C82.5699 38.9672 83.6635 40.165 83.7296 42.0066C83.7321 42.0913 83.7296 42.1785 83.7296 42.2631C83.7296 43.4584 83.7296 44.6536 83.7296 45.8489V45.8515Z" fill="#FFAA00" />
                    <path d="M81.0333 60.831C79.5023 60.8464 78.2612 59.6075 78.2815 58.0839C78.3018 56.604 79.4972 55.4062 80.9672 55.3908C82.4932 55.3754 83.7369 56.6194 83.7165 58.1481C83.6962 59.6306 82.511 60.8182 81.0333 60.8335V60.831Z" fill="#FFAA00" />
                </g>
                <defs>
                    <filter id="filter0_f_339_5690" x="46.7051" y="21.3418" width="60.4766" height="57" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_339_5690" />
                    </filter>
                    <clipPath id="clip0_339_5690">
                        <rect width="54.4773" height="51" fill="white" transform="translate(49.7051 24.3418)" />
                    </clipPath>
                    <clipPath id="clip1_339_5690">
                        <rect width="52.0408" height="48.9184" fill="white" transform="translate(54.9805 22.8984)" />
                    </clipPath>
                </defs>
            </svg>


        </Fragment>
    )
}
