import { all, fork } from 'redux-saga/effects';
import findNewSuppliersSaga from './newSupplier/FindNewSuppliersSaga';
import notificationSaga from './notification/NotificationSaga';
import peopleSaga from './peoples/PeopleSaga';
import projectSaga from './projects/projectSaga';
import suppliersSaga from './suppliers/SuppliersSaga'
import inviteAdminSaga from './invitedAdmins/AdminSaga'
import projectTaskSaga from './projectTask/projectTaskSaga';
import releaseNotesSaga from './releaseNotes/releaseNotesSaga';
import JobsSaga from './jobs/JobSaga';
import customersSaga from './customers/CustomerSaga';
import rateSaga from './rates/RatesSaga';
import sourceLanguageSaga from './sourceLanguage/SourceLanguageSaga';
import acceptRejectRateSaga from './rates/AcceptRejectRateSaga';
export default function* rootSaga() {

    yield all([fork(projectSaga)]);
    yield all([fork(peopleSaga)]);
    yield all([fork(notificationSaga)]);
    yield all([fork(suppliersSaga)]);
    yield all([fork(findNewSuppliersSaga)]);
    yield all([fork(inviteAdminSaga)]);
    yield all([fork(projectTaskSaga)]);
    yield all([fork(releaseNotesSaga)]);
    yield all([fork(JobsSaga)]);
    yield all([fork(customersSaga)]);
    yield all([fork(rateSaga)]);
    yield all([fork(sourceLanguageSaga)]);
    yield all([fork(acceptRejectRateSaga)]);
}