import React, { useState, useEffect } from "react";
import useStyles from "./MobileHeaderStyles";
import { useSelector } from "react-redux";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { MGrid } from "src/app/material-ui";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import { VendorSidebar, LinguistSidebar, SidebarNew } from "../../../components/";
type Anchor = "right";

function MobileHeader() {

    const isAdmin = useSelector((store: any) => store.orgs.isAdmin);
    const isOwner = useSelector((store: any) => store.orgs.isOwner);
    const [organizations, setOrganization]: any = useState();
    const appUserRole = useSelector((store: any) => store.userRole.userRole);
    const storeSidebarType = useSelector((store: any) => store.sideBar.sidebarType);
    // const dispatch = useDispatch();
    const orgs = useSelector((store: any) => store.orgs.organizations);
    // const [setAnchorEl] = useState<null | HTMLElement>(null);
    // const [setAnchorEl] = React.useState<null | HTMLElement>(null);
    const classes = useStyles();
    // const { handelRedirect } = useRoleRedirect(props);
    const isProjectAdmin = useSelector((store: any) => store.orgs.isProjectAdmin);
    const projectId = useSelector((store: any) => store.projects.selectedProjectId);
    const [isVendorMenu, setIsVendorMenu]: any = useState(false);
    const [isOrgFilesMenu, setIsOrgFilesMenu]: any = useState(false);
    const history = useHistory();
    const location = useLocation();

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    // const handleClick = (event: MouseEvent<HTMLElement>) => {
    //     setAnchorEl(event.currentTarget);
    // };

    useEffect(() => {
        if (orgs && orgs.length > 0) {
            setOrganization(orgs);
        }
    }, [orgs]);
    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === "keydown" &&
                    ((event as React.KeyboardEvent).key === "Tab" ||
                        (event as React.KeyboardEvent).key === "Shift")
                ) {
                    return;
                }

                setState({ ...state, [anchor]: open });
            };
    // const switchRole = (role: string) => {
    //     setAnchorEl(null);
    //     if (role !== "") {
    //         dispatch(setUserRole(role));
    //         dispatch(setSidebarType(role));
    //         // localStorage.setItem('user_role',role);
    //         handelRedirect(role);
    //     }
    // };

    const list = (anchor: Anchor) => (
        <div
            className={clsx(classes.paperAnchorRight, {
                [classes.test]: anchor === "right" || anchor === "bottom",
            })}
            role="presentation"
        // onClick={toggleDrawer(anchor, false)}
        // onKeyDown={toggleDrawer(anchor, false)}
        // sx={{backgroundColor: 'red'}}
        >
            <MGrid className={classes.logoContainer}>
                {/* <MGrid item xs={12} className={classes.topLogo}>
                    <img src="/assets/images/marshub-logo.svg" sx={{marginLeft: 15}} alt="logo" width="140px"/>
                    </MGrid> */}
                {storeSidebarType === "ROLE_CLIENT" ? (
                    <SidebarNew />
                ) : storeSidebarType === "projectBar" &&
                    appUserRole === "ROLE_CLIENT" ? (
                    <SidebarNew />
                ) : storeSidebarType === "ROLE_VENDOR" &&
                    appUserRole === "ROLE_VENDOR" ? (
                    <VendorSidebar />
                ) : storeSidebarType === "ROLE_TRANSLATOR" ? (
                    <LinguistSidebar />
                ) : (
                    ""
                )}
            </MGrid>
        </div>
    );

    useEffect(() => {
        if (
            window.location.pathname === "/vendors" ||
            window.location.pathname === "/find-vendor"
        ) {
            setIsVendorMenu(true);
        } else {
            setIsVendorMenu(false);
        }

        if (window.location.pathname === "/files") {
            setIsOrgFilesMenu(true);
        } else {
            setIsOrgFilesMenu(false);
        }
    }, [window.location.pathname]);





    return (
        <MGrid container alignItems="center" justifyContent="space-between">
            {(["right"] as Anchor[]).map((anchor) => (
                <React.Fragment key={anchor}>
                    <MGrid item>
                        <img
                            src="/assets/images/marshub-logo.svg"
                            alt="logo"
                            width="140px"
                        />
                    </MGrid>
                    <MGrid item>
                        <IconButton
                            aria-label="delete"
                            onClick={toggleDrawer(anchor, true)}
                            aria-haspopup="true"
                            color="primary"
                            size="large">
                            <MenuIcon />
                        </IconButton>
                    </MGrid>
                    <MGrid container alignItems="center">
                        {organizations && organizations.length > 0 && (
                            <>
                                {appUserRole === "ROLE_CLIENT"
                                    ?
                                    (
                                        <>
                                            {(isAdmin || isProjectAdmin || isOwner) && (
                                                <>
                                                    {(location.pathname === "/manage-admins" || location.pathname === "/manage-admins-invite")
                                                        &&
                                                        (
                                                            <MGrid container justifyContent="center" item>

                                                                <NavLink
                                                                    exact
                                                                    className={classes.menuLink}
                                                                    to="/manage-admins"
                                                                >
                                                                    <FormattedMessage id="manage.people" />
                                                                </NavLink>

                                                                <NavLink
                                                                    exact
                                                                    className={classes.menuLink}
                                                                    to="/manage-admins-invite"
                                                                >
                                                                    <FormattedMessage id="invited.people" />
                                                                </NavLink>

                                                            </MGrid>
                                                        )
                                                    }

                                                    {(location.pathname === "/projects/file/" + projectId || location.pathname === "/projects/tasks/" + projectId || location.pathname === "/projects/settings/" + projectId) && (
                                                        <MGrid container justifyContent="center" item>
                                                            <NavLink
                                                                exact
                                                                className={classes.menuLink}
                                                                to={"/projects/file/" + projectId}
                                                            >
                                                                <FormattedMessage id="files" />
                                                            </NavLink>

                                                            <NavLink
                                                                exact
                                                                className={classes.menuLink}
                                                                to={"/projects/tasks/" + projectId}
                                                            >
                                                                <FormattedMessage id="tasks" />
                                                            </NavLink>


                                                            <NavLink
                                                                exact
                                                                className={classes.menuLink}
                                                                to={"/projects/settings/" + projectId}
                                                            >
                                                                <FormattedMessage id="settings" />
                                                            </NavLink>
                                                        </MGrid>
                                                    )}

                                                    {isVendorMenu === true && (
                                                        <MGrid item container justifyContent="center">

                                                            <NavLink
                                                                exact
                                                                className={classes.menuLink}
                                                                to={"/vendors"}
                                                            >
                                                                <FormattedMessage id="my.vendor.linguist" />
                                                            </NavLink>

                                                            <NavLink
                                                                exact
                                                                className={classes.menuLink}
                                                                to={"/find-vendor"}
                                                            >
                                                                <FormattedMessage id="find.new.suppliers" />
                                                            </NavLink>

                                                        </MGrid>
                                                    )}

                                                    {location.pathname === "/vendor/linguist" || location.pathname === "/find-linguist"
                                                        &&
                                                        (
                                                            <MGrid container item justifyContent="center">

                                                                <NavLink
                                                                    exact
                                                                    className={classes.menuLink}
                                                                    to={"/vendor/linguist"}
                                                                >
                                                                    <FormattedMessage id="my.linguist" />
                                                                </NavLink>

                                                                <NavLink
                                                                    exact
                                                                    className={classes.menuLink}
                                                                    to={"/find-linguist"}
                                                                >
                                                                    <FormattedMessage id="find.new.linguist" />
                                                                </NavLink>

                                                            </MGrid>
                                                        )
                                                    }

                                                    {isOrgFilesMenu === true && (
                                                        <MGrid container item justifyContent="center">

                                                            <NavLink
                                                                exact
                                                                className={classes.menuLink}
                                                                to={"/files"}
                                                            >
                                                                <FormattedMessage id="source.files" />
                                                            </NavLink>

                                                        </MGrid>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )
                                    :
                                    (appUserRole === "ROLE_VENDOR" && (
                                        <>
                                            {isVendorMenu === true && (
                                                <MGrid container item justifyContent="center">

                                                    <NavLink
                                                        exact
                                                        className={classes.menuLink}
                                                        to={"/vendors"}
                                                    >
                                                        <FormattedMessage id="my.vendor.linguist" />
                                                    </NavLink>

                                                    <NavLink
                                                        exact
                                                        className={classes.menuLink}
                                                        to={"/find-vendor"}
                                                    >
                                                        <FormattedMessage id="find.new.suppliers" />
                                                    </NavLink>

                                                </MGrid>
                                            )}

                                            {projectId && projectId !== '0' && (
                                                <>
                                                    {localStorage.getItem("selectedType") === "task" ? (
                                                        <MGrid container item justifyContent="center">

                                                            <NavLink
                                                                exact
                                                                className={classes.menuLink}
                                                                to={"/my-jobs/files/" + projectId}
                                                            >
                                                                <FormattedMessage id="files" />
                                                            </NavLink>

                                                            <NavLink
                                                                exact
                                                                className={classes.menuLink}
                                                                to={"/my-jobs/files/tasks/" + projectId}
                                                            >
                                                                <FormattedMessage id="tasks" />
                                                            </NavLink>

                                                        </MGrid>
                                                    ) : (
                                                        <MGrid container item justifyContent="center">

                                                            <NavLink
                                                                exact
                                                                className={classes.menuLink}
                                                                to={"/projects/file/" + projectId}
                                                            >
                                                                <FormattedMessage id="files" />
                                                            </NavLink>

                                                            <NavLink
                                                                exact
                                                                className={classes.menuLink}
                                                                to={"/projects/tasks/" + projectId}
                                                            >
                                                                <FormattedMessage id="tasks" />
                                                            </NavLink>
                                                        </MGrid>
                                                    )}

                                                    {localStorage.getItem("selectedType") !== "task" ? (

                                                        <NavLink
                                                            exact
                                                            className={classes.menuLink}
                                                            to={"/projects/settings/" + projectId}
                                                        >
                                                            <FormattedMessage id="settings" />
                                                        </NavLink>

                                                    ) : (
                                                        ""
                                                    )}

                                                </>
                                            )}

                                            {(!projectId || projectId === '0') &&
                                                (history.location.pathname === "/vendor/open-jobs" ||
                                                    history.location.pathname === "/vendor/my-jobs") && (
                                                    <MGrid item container justifyContent="center">

                                                        <NavLink
                                                            exact
                                                            className={classes.menuLink}
                                                            to="/vendor/open-jobs"
                                                        >

                                                            <FormattedMessage id="open.jobs" />
                                                        </NavLink>

                                                        <NavLink
                                                            exact
                                                            className={classes.menuLink}
                                                            to="/vendor/my-jobs"
                                                        >

                                                            <FormattedMessage id="assigned.jobs" />
                                                        </NavLink>

                                                    </MGrid>
                                                )}

                                            {(!projectId || projectId === '0') &&
                                                (location.pathname === "/vendor/rate/request" ||
                                                    location.pathname === "/vendor/client/rates" ||
                                                    location.pathname === "/vendor/rates/client" ||
                                                    location.pathname === "/vendor/rates/add-rates" ||
                                                    location.pathname === "/vendor/rates") && (
                                                    <MGrid item container justifyContent="center">

                                                        <NavLink
                                                            exact
                                                            className={classes.menuLink}
                                                            to="/vendor/rate/request"
                                                        >
                                                            <FormattedMessage id="rate.request" />
                                                        </NavLink>

                                                        <NavLink
                                                            exact
                                                            className={classes.menuLink}
                                                            to="/vendor/rates"
                                                        >
                                                            <FormattedMessage id="rate" />
                                                        </NavLink>
                                                    </MGrid>
                                                )
                                            }
                                        </>
                                    )
                                    )
                                }
                            </>
                        )}

                        {appUserRole === "ROLE_TRANSLATOR"
                            &&
                            (
                                <>
                                    {location.pathname === "/linguist/general/rates" ||
                                        location.pathname === "/linguist/client/rates" ||
                                        location.pathname === "/linguist/vendor/rates"
                                        ?
                                        (
                                            <MGrid container item justifyContent="center">

                                                <NavLink
                                                    exact
                                                    className={classes.menuLink}
                                                    to="/linguist/general/rates"
                                                >
                                                    <FormattedMessage id="general" />
                                                </NavLink>

                                                <NavLink
                                                    exact
                                                    className={classes.menuLink}
                                                    to="/linguist/client/rates"
                                                >
                                                    <FormattedMessage id="client" /> /
                                                    <FormattedMessage id="vendor" />
                                                </NavLink>

                                            </MGrid>
                                        ) : ("")
                                    }

                                    {location.pathname === "/linguist/my-jobs" ||
                                        location.pathname === "/linguist/open-jobs"
                                        ?
                                        (
                                            <MGrid item container justifyContent="center">

                                                <NavLink
                                                    exact
                                                    className={classes.menuLink}
                                                    to="/linguist/open-jobs"
                                                >
                                                    <FormattedMessage id="open.jobs" />
                                                </NavLink>

                                                <NavLink
                                                    exact
                                                    className={classes.menuLink}
                                                    to="/linguist/my-jobs"
                                                >
                                                    <FormattedMessage id="assigned.jobs" />
                                                </NavLink>

                                            </MGrid>
                                        ) : ("")
                                    }
                                </>
                            )
                        }

                    </MGrid>
                    < Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        className={classes.test}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))
            }
        </MGrid >
    );
}
export default MobileHeader;


// import React from "react";
// import { StyleSheet, css } from "aphrodite";

// import "./style.css";

// const styles = StyleSheet.create({
//   heading: {
//     textAlign: "right",
//     backgroundColor: "red",
//     "@media (max-width: 767px)": {
//       textAlign: "center",
//       backgroundColor: "green"
//     },

//   }
// });

// export default function App() {
//   return (
//     <div className={css(styles.heading)}>
//       <h1>Hello aphrodite!</h1>
//     </div>
//   );
// }
