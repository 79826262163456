import { Theme } from '@mui/material/styles';

import { makeStyles } from '@mui/styles';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles((theme: Theme) => ({

    contentBox: {
        backgroundColor: 'white',
        // boxShadow: '0px 5px 5px 0px rgba(193, 199, 208, 0.26)',
        borderRadius: 5,
        '& .MuiFormHelperText-root.Mui-error': {
            minWidth: 120,
            textTransform: 'initial'
        }
    },
    hr: {
        borderBottom: "1px solid #dbdbdb",
    },
    greyUppercaseText: {
        color: theme.palette.text.secondary,
        textTransform: "uppercase",
        paddingRight: 0,
    },
    colorLightGrey: {
        color: "#a0a5b9",
    },
    inputWhite: {
        backgroundColor: "#fff",
        width: '100%',
        "& input": {
            backgroundColor: "#fff",
            padding: '9.2px 10.5px',
            fontSize: 14,
            '&::placeholder': {
                fontSize: 14,
                color: '#6b778c',
                opacity: 1
            }
        },
        '& textarea::placeholder': {
            color: '#6b778c',
            opacity: 1,
            fontSize: 14,
            '&::placeholder': {
                fontSize: 14,
            }
        }
    },
    smallInput: {
        // "& input":{
        //   maxHeight: 35,
        //   boxSizing: "border-box"
        // },
    },

    headerLeftCol: {
        "& > div": {
            width: "calc(50% - 6px)",
        },
    },
    headerRightCol: {
        "& > div": {
            // width: "calc(50% - 15px)",
        },
    },
    leftCol: {
        "& > div": {
            width: "calc(50% - 6px)",
        }
    },
    rightCol: {
        // "& > div":{
        //   width: "calc(50% - 33px)",
        // },
    },
    bgLightGrey: {
        backgroundColor: "#f7f9fa",
    },
    discountInput: {
        width: 74,
        "& input": {
            padding: '3.2px 10.5px',
            fontSize: 12,
            '&::placeholder': {
                fontSize: 13,
            }
        },
    },
    deleteIcon: {
        color: '#6b778c',
        cursor: 'pointer',
        transition: 'all .3s',
        '&:hover': {
            color: 'rgb(63, 116, 232)'
        }
    },
    addRowButton: {
        backgroundColor: '#e5f1f7',
        padding: 8,
        borderRadius: 4,
        color: theme.palette.secondary.main,
        display: 'inline-block',
        minWidth: 70,
        cursor: 'pointer',
    },
    autoCompleteSizeSm: {
        backgroundColor: '#fff',
        '& .MuiFormLabel-root': {
            fontSize: 14,
            marginTop: '-2px'
        },
        '& .MuiAutocomplete-inputRoot': {
            minHeight: 35,
            paddingTop: '3px !important'
        },
        '& .MuiAutocomplete-input': {
            fontSize: 14
        }
    },
    editorBox: {
        minHeight: 115,
        border: '1px solid #ddd',

        "& .MuiGrid-spacing-xs-1": {
            borderBottom: "1px solid #ddd",
            margin: 0,
            width: "100%",
        },
        "& .MuiContainer-root.MuiContainer-maxWidthLg": {
            padding: 0,
        },
        "& .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1.MuiGrid-justify-xs-space-evenly .MuiGrid-root.MuiGrid-item:nth-child(5)": {
            display: "none",
        }
    },
    editorWrap: {
        '& #h4Heading, & #h5Heading, & #h6Heading, & #strikeThrough': {
            display: 'none'
        },
        '& .MuiGrid-item': {
            padding: 0
        },
        '& .MuiButtonBase-root': {
            padding: '12px 7px'
        }
    },

    formLabel: {
        fontSize: 14,
        color: theme.palette.text.primary,
    },
    instructionWrap: {
        '& img': {
            maxWidth: '80%'
        }
    },
    footerBottom: {
        position: 'fixed',
        bottom: "0%",
        right: 0,
        width: '85%',
        borderTop: '1px solid #DCDDDE',
        backgroundColor: '#fff',
        paddingRight: 36,
        paddingTop: 16,
        paddingBottom: 16,
        display: 'flex',
        justifyContent: 'flex-end'
    },
    taskList: {
        '& .MuiAutocomplete-input': {
            padding: '0px !important'
        },


    },
    radioBtn: {
        fontSize: 13,
        color: '#A0A5B9',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',

        '& .MuiFormControlLabel-root': {
            width: '100%'
        },
        '& .MuiTypography-root': {
            display: 'inline-block',
            overflow: ' hidden',
            whiteSpace: 'nowrap',
            color: '#3A3A3A',
            fontFamily: 'Poppins',
            fontWeight: 400,
            textOverflow: 'ellipsis',
            maxWidth: '7vw',

        },
        '& .MuiBox-root': {
            '&:nth-child(2)': {
                textOverflow: 'ellipsis',
                maxWidth: '3.604vw',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
            }

        }
    },
    radioOptions: {
        '& .MuiSvgIcon-root': {
            height: 18,
            width: 18
        }
    }
    ,
    menuList: {
        '& .MuiPaper-root': {}
    },
    poDropdown: {
        backgroundColor: '#F5F6F7',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '4px',
        paddingLeft: 8,
        minWidth: 166,
        '& .MuiTypography-root': {
            fontSize: 14
        },
        '& .MuiSvgIcon-root': {
            height: 20,
            width: 20
        }

    },
    dropdownMenu: {
        width: '100%',
        '& .MuiAvatar-root': {
            height: 15,
            width: 15
        }
    },
    subCol: {
        '@media (max-width:1700px)': {
            minWidth: 217,
            maxWidth: 217,
            textAlign: 'right'

        },
    },
    jobTasks: {
        width: '100%',
        maxWidth: 220,

        backgroundColor: '#F5F6F7',
        padding: '8px 9px',
        // flex: '0 0 30.333333%',
        marginRight: 8,
        marginBottom: 5,
        borderRadius: 4,
        '&:last-child': {
            marginRight: '0px !important'
        }

    },
    taskName: {
        fontSize: 12,
        color: '#42526E',
        fontFamily: 'Poppins',
        fontWeight: 600,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',

        overflow: 'hidden',
    },
    ratesList: {
        boxShadow: '0px 0px 32px 0px rgba(214, 215, 226, 0.3)',
        backgroundColor: '#fff',
        border: '1px solid rgb(244, 245, 247)',
        borderRadius: 4,
        cursor: 'pointer',
        padding: '20px',
        fontSize: 14,
        '& a': {
            color: theme.palette.text.secondary,
            textDecoration: 'none'
        }
    },
    menuTransaction: {
        '& .MuiPaper-root': {
            top: '175px !important',
            left: '1465px !important',
            width: '171px !important'
        },
        '& .MuiMenuItem-root': {
            fontSize: '12px !important',
            fontFamily: 'Poppins'
        }

    },
    invoiceLabel: {
        width: '114px',
        height: '32px',
        backgroundColor: '#ffffff',
        fontWeight: 500,
        fontSize: 22,
        color: '#FFE8B3',
        padding: '0px 8px 0px 20px ',
        boxSizing: 'border-box',
        position: 'absolute',
        right: '80px'

    },
    invoiceID: {
        // width: '148px',
        // height: '32px',
        background: '#FFC34B',
        borderRadius: '4px',
        fontWeight: 500,
        fontSize: 18,
        color: '#ffffff',
        padding: '3px 11px 3px 11px',
        boxSizing: 'border-box',
        position: 'absolute',
        top: '94px',
        left: '88px'
    }
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;