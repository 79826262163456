import React, { Fragment } from 'react'

export function MDownloadRequest(props: any) {
    return (
        <Fragment>

            <svg {...props} width="20" height="20" viewBox="0 0 20 20"  xmlns="http://www.w3.org/2000/svg">
                <path {...props} d="M9.14583 18.292C8.09028 18.1809 7.10764 17.8857 6.19792 17.4066C5.28819 16.9274 4.5 16.3163 3.83333 15.5732C3.16667 14.8302 2.64236 13.9795 2.26042 13.0212C1.87847 12.0628 1.6875 11.0489 1.6875 9.97949C1.6875 7.82671 2.39931 5.96213 3.82292 4.38574C5.24653 2.80935 7.02778 1.9031 9.16667 1.66699V3.33366C7.48611 3.56977 6.09722 4.3163 5 5.57324C3.90278 6.83019 3.35417 8.29894 3.35417 9.97949C3.35417 11.66 3.90278 13.1288 5 14.3857C6.09722 15.6427 7.47917 16.3892 9.14583 16.6253V18.292ZM9.97917 14.167L5.79167 9.95866L6.97917 8.77116L9.14583 10.9378V5.83366H10.8125V10.9378L12.9583 8.79199L14.1458 10.0003L9.97917 14.167ZM10.8125 18.292V16.6253C11.4097 16.542 11.9826 16.3823 12.5312 16.1462C13.0799 15.91 13.5903 15.6114 14.0625 15.2503L15.2708 16.4587C14.6181 16.9725 13.9167 17.3857 13.1667 17.6982C12.4167 18.0107 11.6319 18.2087 10.8125 18.292ZM14.1042 4.70866C13.6181 4.34755 13.1007 4.04894 12.5521 3.81283C12.0035 3.57671 11.4306 3.41699 10.8333 3.33366V1.66699C11.6528 1.75033 12.4375 1.94824 13.1875 2.26074C13.9375 2.57324 14.6319 2.98644 15.2708 3.50033L14.1042 4.70866ZM16.4375 15.2503L15.2708 14.0628C15.6319 13.5906 15.9236 13.0802 16.1458 12.5316C16.3681 11.983 16.5208 11.41 16.6042 10.8128H18.3125C18.2014 11.6323 17.9931 12.4205 17.6875 13.1774C17.3819 13.9344 16.9653 14.6253 16.4375 15.2503ZM16.6042 9.14616C16.5208 8.54894 16.3681 7.97602 16.1458 7.42741C15.9236 6.8788 15.6319 6.36838 15.2708 5.89616L16.4375 4.70866C16.9653 5.33366 17.3889 6.02463 17.7083 6.78158C18.0278 7.53852 18.2292 8.32671 18.3125 9.14616H16.6042Z"/>
            </svg>

        </Fragment>
    )
}
