/* eslint-disable */
import React, { useEffect, useState } from 'react'
// import { classnames } from 'tss-react/tools/classnames'
import { useMutation } from "@apollo/react-hooks";
import { gql } from 'apollo-boost';
import { useDispatch, } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Autocomplete } from "@mui/material";
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel } from '@mui/material';
import BilingualSkeleton from './BilingualSkeleton';
import { showAlert } from "../../../../store";
import { MBox, MLink } from 'src/app/material-ui'
import useStyles from './BilingualStyles';
import { MButton } from 'src/app/material-ui/button/Button';
import { MBilingual, MSettings } from 'src/app/material-ui/icons';
import { EmptyContent } from 'src/app/components';


interface IProps {
    targetLanguages?: any,
    bilingual?: string | any,
    setBilingual?: string | any,
    setTlLangCode?: string | any,
    setReferenceMapping?: string | any,
    referenceMapping?: string | any,
    setSourceLanguageMapping?: string | any,
    sourceLanguageMapping?: string | any,
    langCode?: string | any,
    setLangCode?: string | any,
    project?: any,
    setChecked?: boolean | any,
    checked?: boolean | any,
    setBilingualLanguageModal?: boolean | any,
    setIsFullWidth?: any,
    bilingualLanguageFileMetaId?: any,
    selectedFileObj?: any,
    projectLanguagesLoading?: any,
    sourceLanguages?: any,
    getProjectData?: any,

}
interface Option {
    label: string;
    code: string;

    // Add other properties if needed
}

const SAVE_LANG_MAPPING = gql`
    mutation globalFileMapping($fileId: String, $langId:ID, $sourceLanguageMapping: String,$targetLanguageMapping: TargetLanguageMapping, $referenceMapping: String, $firstRecordAsHeader: Boolean) {
        globalFileMapping(fileId: $fileId, langId: $langId, sourceLanguageMapping: $sourceLanguageMapping, targetLanguageMapping: $targetLanguageMapping, referenceMapping: $referenceMapping, firstRecordAsHeader: $firstRecordAsHeader)
    }
`;

export default function Bilingual({ getProjectData, sourceLanguages, selectedFileObj, bilingualLanguageFileMetaId, setIsFullWidth, targetLanguages, bilingual, setBilingual, referenceMapping, setReferenceMapping, sourceLanguageMapping, setSourceLanguageMapping, langCode, setLangCode, project, checked, setChecked, setBilingualLanguageModal, projectLanguagesLoading }: IProps) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const intl = useIntl();
    const [selectOptions, setSelectOptions] = useState<string>('default');
    const [selectedOption, setSelectedOption] = useState<Option | any>({});
    const [selectedLang, setSelectedLang] = useState<Option | any>({});
    const [selectedReference, setSelectedReference] = useState<Option | any>({ label: "C" });
    const [selectedSource, setSelectedSource] = useState<Option | any>({ label: "A" });

    const [selectedSourceLang, setSelectedSourceLang]: any = useState(null);
    const [selectedTargetLang, setSelectedTargetLang]: any = useState(null);


    // const [option, setOption] = useState([]);
    //checkbox
    const [checkedBox, setCheckedBox] = React.useState(true);
    // State to keep track of selected options for each Autocomplete
    const [selectedOptions, setSelectedOptions]: any = useState([]);
    // const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const handleClick = (options: string) => {
        setSelectOptions(options)
    };

    const [saveLangMapping, { loading: saveLangMappingLoading }] = useMutation(SAVE_LANG_MAPPING, {
        fetchPolicy: 'no-cache',
        onCompleted: (data: any) => {

            dispatch(showAlert({
                message: data && data.globalFileMapping,
                messageType: 'success',
                showAlertMessage: true
            }));
        },

    });

    const handleSubmit = () => {


        if (sourceLanguages && sourceLanguages.length > 0) {
            const targetLanguageMapping = { code: selectedTargetLang?.code, column: selectedOption?.label };
            saveLangMapping({
                variables: {
                    fileId: bilingualLanguageFileMetaId.toString(),
                    langId: selectedSourceLang?.id,
                    sourceLanguageMapping: selectedSource?.label,
                    targetLanguageMapping: targetLanguageMapping,
                    firstRecordAsHeader: checkedBox,
                    referenceMapping: selectedReference?.label,
                }
            });

            if (typeof getProjectData == 'function') {
                getProjectData();
            }
        }

        if (selectedOption) {

            let bilingualMappingN = bilingual.filter((it: any) => it.fileMetaId !== bilingualLanguageFileMetaId);

            bilingualMappingN.push({ fileMetaId: bilingualLanguageFileMetaId, label: selectedOption?.label });

            setBilingual(bilingualMappingN);

            // setBilingual(selectedOption?.label); // Save the label of selected option
            // setLangCode(selectedLang);


            let langCodeMappingN = langCode.filter((it: any) => it.fileMetaId !== bilingualLanguageFileMetaId);

            langCodeMappingN.push({ fileMetaId: bilingualLanguageFileMetaId, label: selectedLang });

            setLangCode(langCodeMappingN);


            let referenceMappingN = referenceMapping.filter((it: any) => it.fileMetaId !== bilingualLanguageFileMetaId);

            referenceMappingN.push({ fileMetaId: bilingualLanguageFileMetaId, label: selectedReference?.label });

            setReferenceMapping(referenceMappingN);


            let sourceLanguageMappingN = sourceLanguageMapping.filter((it: any) => it.fileMetaId !== bilingualLanguageFileMetaId);

            sourceLanguageMappingN.push({ fileMetaId: bilingualLanguageFileMetaId, label: selectedSource?.label });

            setSourceLanguageMapping(sourceLanguageMappingN);
            // setSourceLanguageMapping({fileMetaId: bilingualLanguageFileMetaId, label: selectedSource?.label});

            let checkedMappingN = checked.filter((it: any) => it.fileMetaId !== bilingualLanguageFileMetaId);

            checkedMappingN.push({ fileMetaId: bilingualLanguageFileMetaId, label: checkedBox });

            setChecked(checkedMappingN);

            // setChecked(checkedBox);
            if (setIsFullWidth) {
                setIsFullWidth(false);
            }
        }

        setBilingualLanguageModal(false)
    };

    useEffect(() => {
        // console.log('selectedFileObj',selectedFileObj)
        if (selectedFileObj && typeof selectedFileObj != "undefined") {
            // setSelectedOptions([{code: 'ar-SA', val: {label: 'B'}}]);
            // setSelectedTargetLang({id: 6, name: 'ar-SA',code: 'ar-SA'});
            // setSelectedLang({id: 6, name: 'ar-SA',code: 'ar-SA'});

            if (selectedFileObj?.sourceMapping) {
                setSelectedSource({ label: selectedFileObj?.sourceMapping })
            } else {
                setSelectedSource(null)
            }

            // alert(selectedFileObj?.targetMapping);
            if (selectedFileObj?.targetMapping) {
                setSelectedOptions([{ code: selectedFileObj?.targetLanguage?.code, val: { label: selectedFileObj?.targetMapping } }])
            } else {
                setSelectedOptions([])
            }

            if (selectedFileObj?.sourceLanguage) {
                setSelectedSourceLang(selectedFileObj?.sourceLanguage);
            } else {
                setSelectedSourceLang(null);
            }

            if (selectedFileObj?.targetLanguage) {
                setSelectedTargetLang(selectedFileObj?.targetLanguage);
                setSelectedLang(selectedFileObj?.targetLanguage);
            } else {
                setSelectedTargetLang(null);
                setSelectedLang(null);
            }

        } else if (bilingual.length > 0) {
            let existingOBj = bilingual.find((it: any) => it.fileMetaId == bilingualLanguageFileMetaId);
            let existingLangOBj = langCode.find((it: any) => it.fileMetaId == bilingualLanguageFileMetaId)?.label;

            if (existingLangOBj) {
                setSelectedOptions([{ code: existingLangOBj, val: existingOBj }]);
                setSelectedOption(existingOBj);
                setSelectedLang(existingLangOBj);
            }
            // setSelectOptions
        }
    }, [bilingual, selectedFileObj]);

    const handleChange = (event: any, newValue: any, tl: any) => {
        if (tl?.code) {
            setSelectedOptions([{ code: tl.code, val: newValue }]);
            setSelectedOption(newValue);
            setSelectedLang(tl.code);
        }
    };
    const handleReferenceChange = (event: any, newValue: any) => {
        setSelectedReference(newValue);


    };
    const handleSourceChange = (event: any, newValue: any) => {
        setSelectedSource(newValue);


    };
    //checkbox function
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedBox(event.target.checked);

    };
    const options = [...Array(26)].map((_, i) => ({ label: (i + 10).toString(36).toUpperCase() }));

    return (
        <div>
            <MBox className={classes.infoText}>
                <FormattedMessage id='bilingual.info' />

            </MBox>

            <MBox display='flex' className={'gap-10'}>
                <MButton startIcon={<MSettings />} className={`${classes.btnSelection} ${selectOptions === 'default' ? classes.active : ''}`} onClick={() => handleClick('default')}>Default</MButton>
                <MButton startIcon={<MBilingual />} className={`${classes.btnSelection} ${selectOptions === 'bilingual' ? classes.active : ''}`} onClick={() => handleClick('bilingual')}>Bilingual</MButton>

            </MBox>

            {selectOptions === 'default' &&
                <>
                    <EmptyContent isContent={false} text={'Please select Bilingual to proceed further'} />
                    {/*<EmptyContent />*/}
                </>
            }
            {selectOptions === 'bilingual' &&
                <>
                    {projectLanguagesLoading ?

                        <BilingualSkeleton />
                        :
                        <>
                            {targetLanguages && targetLanguages.length >= 0 ?
                                <MBox mb={9}>

                                    <MBox fontSize={22} mb={1} mt={3} fontFamily='Poppins Medium'>
                                        <FormattedMessage id='columns.mapping' />
                                    </MBox>


                                    <MBox fontSize={14} mb={1} fontFamily='Poppins' display='flex' alignItems='center' maxWidth={'75%'} width='100%' flexWrap='wrap'>
                                        <FormattedMessage id='column.source' />&nbsp;<span><MLink href={process.env.REACT_APP_API_END_POINT + '/download/file?downloadType=single&fileType=samplebilingual'}>Click here</MLink></span><FormattedMessage id='#' defaultMessage='These adjustments will appear in the CAT tool under the Source and Target Language Columns section.' />
                                    </MBox>
                                    <MBox mb={1} mt={3}>
                                        <FormControlLabel control={
                                            <Checkbox
                                                checked={checkedBox}
                                                onChange={handleCheckboxChange}
                                                inputProps={{ 'aria-label': 'controlled' }}

                                                sx={{
                                                    padding: '5px !important'
                                                }}
                                            />}
                                            label="Click the checkbox if your uploaded XLSX file contains a header. This will ensure the header is ignored in the CAT tool."
                                        />
                                    </MBox>
                                    <MBox fontSize={18} my={2} fontFamily='Poppins'>
                                        <FormattedMessage id='source.language' />
                                    </MBox>
                                    <MBox display='flex' maxWidth='45%' className={'gap-10'}>

                                        {!project && sourceLanguages && sourceLanguages.length > 0 ? <MBox flex='2 1 0'>
                                            <Autocomplete
                                                // className={classes.lang}
                                                id="combo-box-demo"
                                                getOptionLabel={(option: any) => option.name + ' (' + option.code + ')'}
                                                options={sourceLanguages}
                                                defaultValue={selectedSourceLang ? selectedSourceLang : null} // Set default value here
                                                onChange={(event, value) => setSelectedSourceLang(value)} // Pass index to identify which Autocomplete is changing
                                                sx={{
                                                    width: "100%",
                                                    '& .MuiInputBase-root': {
                                                        padding: '4px !important'
                                                    }
                                                }}
                                                size='small'
                                                renderInput={(params) => <TextField placeholder={intl.formatMessage({ id: "select.source.language" })} {...params} />}
                                            />
                                        </MBox> : <MBox className={classes.lang} flex='2 1 0'>
                                            {project && project.sl && project.sl.name} ({project?.sl?.code})
                                        </MBox>}


                                        <MBox display='flex' flex='1 1 1' minWidth='32%'>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(option: any) => option.label}
                                                options={options}
                                                defaultValue={selectedSource ? selectedSource : null} // Set default value here
                                                onChange={(event, value) => handleSourceChange(event, value)} // Pass index to identify which Autocomplete is changing
                                                sx={{
                                                    width: "100%",
                                                    '& .MuiInputBase-root': {
                                                        padding: '4px !important'
                                                    }
                                                }}
                                                size='small'
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </MBox>
                                    </MBox>
                                    <MBox fontSize={18} mb={1} fontFamily='Poppins' my={2}>
                                        <FormattedMessage id='target.language' />
                                    </MBox>

                                    {/*{selectedOptions && JSON.stringify(selectedOptions)}*/}

                                    {!project && sourceLanguages && sourceLanguages.length > 0 && <MBox display='flex' maxWidth='45%' className={'gap-10'}><MBox flex='2 1 0'>
                                        <Autocomplete
                                            // className={classes.lang}
                                            id="combo-box-demo"
                                            getOptionLabel={(option: any) => option.name + ' (' + option.code + ')'}
                                            options={sourceLanguages}
                                            defaultValue={selectedTargetLang ? selectedTargetLang : null} // Set default value here
                                            onChange={(event, value) => setSelectedTargetLang(value)} // Pass index to identify which Autocomplete is changing
                                            sx={{
                                                width: "100%",
                                                '& .MuiInputBase-root': {
                                                    padding: '4px !important'
                                                }
                                            }}
                                            size='small'
                                            renderInput={(params) => <TextField placeholder={'Select Target Language'} {...params} />}
                                        />
                                    </MBox>
                                        <MBox display='flex' flex='1 1 1' minWidth='32%' maxHeight={"20px"}>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                getOptionLabel={(option: any) => option.label}
                                                options={options}
                                                value={(selectedOptions && selectedOptions.find((it: any) => it.code == selectedTargetLang?.code)) ? selectedOptions.find((it: any) => it.code == selectedTargetLang?.code).val : null} // Use selected option from state
                                                // value={(selectedOptions && selectedOptions.find((it: any) => it.code == item.code)) ? 'ha' : 'nhi'} // Use selected option from state
                                                onChange={(event, value) => handleChange(event, value, selectedTargetLang)} // Pass index to identify which Autocomplete is changing
                                                sx={{
                                                    width: "100%",
                                                    '& .MuiInputBase-root': {
                                                        padding: '4px !important'
                                                    }
                                                }}
                                                size='small'
                                                renderInput={(params) => <TextField {...params} label='-' />}
                                            />
                                        </MBox>
                                    </MBox>
                                    }

                                    {/*{project && JSON.stringify(project)}*/}
                                    {targetLanguages.map((item: any, index: any) => (
                                        <MBox key={index} display='flex' maxWidth='45%' className={'gap-10'} mb={1}>
                                            {/*{selectedOptions && JSON.stringify(selectedOptions.find((it:any) => it.code == item.code))}*/}

                                            <MBox className={classes.lang} flex='2 1 0'>
                                                {item.name} ({item.code})

                                            </MBox>
                                            <MBox display='flex' flex='1 1 0'>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    getOptionLabel={(option: any) => option.label}
                                                    options={options}
                                                    value={(selectedOptions && selectedOptions.find((it: any) => it.code == item.code)) ? selectedOptions.find((it: any) => it.code == item.code).val : null} // Use selected option from state
                                                    // value={(selectedOptions && selectedOptions.find((it: any) => it.code == item.code)) ? 'ha' : 'nhi'} // Use selected option from state


                                                    onChange={(event, value) => handleChange(event, value, item)} // Pass index to identify which Autocomplete is changing
                                                    sx={{
                                                        width: "100%",
                                                        '& .MuiInputBase-root': {
                                                            padding: '4px !important'
                                                        }
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label='-' />}
                                                />
                                            </MBox>
                                        </MBox>
                                    )

                                    )}
                                    <MBox fontSize={18} my={2} fontFamily='Poppins'>
                                        <FormattedMessage id='information' />
                                    </MBox>
                                    <MBox display='flex' maxWidth='45%' className={'gap-10'} mb={1}>
                                        <MBox className={classes.lang} flex='2 1 0'>
                                            Reference
                                        </MBox>
                                        <MBox display='flex' flex='1 1 0'>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                getOptionLabel={(option: any) => option.label}
                                                options={options}
                                                defaultValue={options[2]} // Set default value here
                                                onChange={(event, value) => handleReferenceChange(event, value)}
                                                sx={{
                                                    width: "100%",
                                                    '& .MuiInputBase-root': {
                                                        padding: '4px !important'
                                                    }
                                                }}
                                                size='small'
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </MBox>
                                    </MBox>
                                    <MBox display="flex">
                                        <MBox className={classes.footerBottom}>

                                            {/*{selectedSource && JSON.stringify(selectedSource)}*/}

                                            <MButton className="btnSmall" color="primary"
                                                disabled={(!selectedSource) ? true : false} variant="contained" type="submit" onClick={handleSubmit} >

                                                {/* {addLoading && <MCircularProgress color="primary" size={24} />} */}
                                                <FormattedMessage id="save" />
                                            </MButton>
                                        </MBox>
                                    </MBox>

                                </MBox>
                                :
                                <EmptyContent />
                            }
                        </>
                    }

                </>
            }

        </div>
    )
}
