import { RESET_STORE } from '../resetStateAction';

interface IAction {
    type: string,
    payload: any
}

interface IState {
    userRole: string
}

const SET_USER_ROLE = "SET_USER_ROLE";
const SHOW_USER_ROLE = "SHOW_USER_ROLE";

const initialState: IState = {
    userRole: ''
};

export const setUserRole = (value: string) => {
    localStorage.setItem('user_role', value);
    return {
        type: SET_USER_ROLE,
        payload: value
    }
};

export const userRoleShow = (value: boolean) => {
    return {
        type: SHOW_USER_ROLE,
        payload: value
    }
}

const showUserRole = (state = initialState, action: IAction) => {
    switch (action.type) {
        case SHOW_USER_ROLE:
            return {
                ...state,
                userRole: action.payload
            }
        case SET_USER_ROLE:
            return {
                ...state,
                userRole: action.payload
            }
        case RESET_STORE:
            return initialState
        default:
            return state
    }
}

export default showUserRole;