import { Theme } from "@mui/material/styles";

import createStyles from "@mui/styles/createStyles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(((theme: Theme) =>
  createStyles({
    logoBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      minWidth: 163,
      ...theme.mixins.toolbar,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      "& a": {
        // height: 21,
      },
    },

    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },

    mainNav: {
      listStyle: "none",
      "& li": {
        marginRight: "19.5px",
      },
      "& li:hover a": {
        color: theme.palette.secondary.main,
      },
      "& li a.active": {
        color: theme.palette.primary.main,
      },
    },
    menuLink: {
      color: "#3a3a3a",
      textDecoration: "none !important",
      height: 32,
      padding: "5px 10px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      transition: "all .3s",
      borderRadius: 3,
      fontSize: 15,
      fontFamily: "Poppins",
      "& svg": {
        color: "#98a0af",
        marginRight: -6,
      },
      "&:hover,&.activeSubMenu": {
        color: "#ffaa00 !important",
        // backgroundColor: 'rgba(222,235,255,0.9)',
        "& svg": {
          color: "#ffaa00",
        },
      },
      "&.active": {
        position: "relative",
        // color: '#ffaa00 !important',
        "&::after": {
          content: "''",
          width: "100%",
          position: "absolute",
          bottom: -19,
          left: 0,
          height: 3,
          backgroundColor:theme.palette.primary.main,
          borderTopLeftRadius: 1,
          borderTopRightRadius: 1,
        },
        "& svg": {
          color: "#ffaa00",
        },
      },
    },

    logoutLink: {
      color: "#f44336",
      padding: "5px 8px",
      "&:hover": {
        color: theme.palette.error.main,
        backgroundColor: "#fddedf",
      },
    },

    userDropdown: {
      minWidth: 195,
      "& ul": {
        minWidth: 195,
        "& .MuiListItem-button": {
          borderRadius: 4,
          margin: theme.spacing(0, 1),
        },
      },
    },
    avatarBox: {
      borderBottom: "1px solid #d8d9db",
      outline: "none",
      "& .MuiAvatar-root": {
        width: "28px",
        height: "28px",
        display: "inline-block",
      },
    },
    userAvatarWrapper: {
      "& .MuiAvatar-root": {
        width: "28px",
        height: "28px",
        display: "inline-block",
      },
    },
    headerIcon: {
      color: '#6b778c',
    },
    dotBagde: {
      position: "absolute !important" as any,
      top: 10,
      right: 7,
      "& .MuiBadge-badge": {
        width: 10,
        height: 10,
        top: 6,
        right: 7,
        borderRadius: 25,
        color: "#ff0102",
        backgroundColor: theme.palette.primary.main,
        border: "2px solid #fff",
      },
    },
    roleWrap: {
      borderTop: "1px solid #d8d9db",
      borderBottom: "1px solid #d8d9db",
    },
    selectedRole: {
      textDecoration: "none",
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    jobMenuWrap: {
      marginTop: 45,
      "& .MuiMenu-paper": {
        border: "none",
        boxShadow: "0 0 10px #dee0de !important",
        overflow: "visible",
        marginLeft: -11,
        "&::before": {
          top: -12,
          left: 15,
          width: 0,
          height: 0,
          content: "''",
          display: "inline-block",
          position: "absolute",
          borderLeft: "7px solid transparent",
          borderRight: "7px solid transparent",
          borderBottom: "12px solid #fff",
          filter: "drop-shadow(0 -4px 2px #f5f5f5)",
        },
      },
    },
    selectedJobMenu: {
      textDecoration: "none",
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    langSwith: {
      display: "flex",
      justifyContent: "space-around",
      marginRight: "2vw",
      "& .MuiButtonBase-root": {
        transition: " left 250ms ease-in-out, right 250ms ease-in-out",
        "&:hover": {
          borderRadius: "8px !important",
        },
        "& .MuiTypography-root": {
          fontSize: 14,
          fontFamily: "Poppins",
          fontWeight: 500,
          color: "#6B778C",
        },
        "& .MuiDivider-root": {
          margin: "0px 13px",
          backgroundColor: "#D9D9D9",
          width: 2,
        },
      },
    },
    notificationLable: {
      "& .MuiList-padding": {
        padding: "0px !important",
        width: "100% !important",
      },
      "& .MuiButtonBase-root": {
        flexDirection: "column",
        padding: "0px !important",
      },
      "& .MuiListItem-root": {
        marginBottom: 3,
        padding: 50,
      },
    },
    headerNotification: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      padding: "20px 20px",
      borderBottom: "1px solid #F2F2F4",
    },
    notificationBar: {
      fontFamily: "Poppins !important",
      color: "#6B778C !important",
      fontSize: "12",
      "&:hover": {
        cursor: "pointer",
      },
    },
    notifications: {
      padding: "8px 20px",

      "& .MuiSvgIcon-root": {
        fill: "#FFAA00",
        height: 8,
        width: 8,
      },
      "& span": {
        color: "#A2A4B9",
        fontFamily: "Poppins",
      },
    },
    unRead: {
      backgroundColor: "#FBFBFB",
      "&:hover": {
        backgroundColor: "#FFFDEF",
        "& $markButton": {
          border: "1px solid #DCDCDC",
        },
      },
    },
    read: {},
    infoBox: {
      boxShadow: "0px 0px 32px 0px rgba(214, 215, 226, 0.3)",
      transition: "all .3s",
      backgroundColor: "#fff",
      margin: "0px 19px",
    },
    markButton: {
      borderRadius: "100%",
      // padding: 7,
      display: "flex",
      height: 24,
      alignItems: "center",
      width: 24,
      justifyContent: "center",
      textTransform: "uppercase",
    },
    badgeBug: {
      backgroundColor: "#faa819",
      color: "#fff",
      fontWeight: 600,
      padding: "1px 10px",
      fontSize: 11,
      borderRadius: 1000,
    },
  })));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;
