import React from 'react'
import { FormattedMessage } from 'react-intl'
import { MBox, MGrid } from 'src/app/material-ui'
import useStyles from './TransactionDetailsStyle'

export default function BankTransferDetails({ clientTransactionData, BankTransferDetails, hideSendTo }: any) {
    const classes = useStyles({})
    return (
        <>
            {clientTransactionData &&
                <>
                    <MBox className={classes.transferDetails}>

                        <MBox className={classes.header} fontSize={18} fontFamily='Poppins' color='#6B778C'>
                            {clientTransactionData && clientTransactionData.description && JSON.parse(clientTransactionData.description).paymentName === 'bank' ?
                                <FormattedMessage id='bank.transfer.details' />
                                :
                                <>
                                    {clientTransactionData.description && typeof clientTransactionData.description == "object" && JSON.parse(clientTransactionData.description).paymentName === 'paypal' ?
                                        <FormattedMessage id='paypal.details' />
                                        :
                                        <>
                                            {clientTransactionData.description && typeof clientTransactionData.description == "object" && JSON.parse(clientTransactionData.description).paymentName === 'Pioneer' ?
                                                <FormattedMessage id='payoneer.details' />
                                                :
                                                ''
                                            }
                                        </>

                                    }
                                </>

                            }

                            {BankTransferDetails || hideSendTo ? '' :
                                <MBox>
                                    <FormattedMessage
                                        id='send.to' /> {clientTransactionData && clientTransactionData.resourceName}
                                </MBox>
                            }

                        </MBox>

                        {clientTransactionData && clientTransactionData.description && JSON.parse(clientTransactionData.description).paymentName === 'bank' ?
                            <MBox>

                                <MGrid container>
                                    <MBox>
                                        <MGrid item xs={5}>
                                            <FormattedMessage id='beneficiary.bank.name' />
                                        </MGrid>
                                        <MGrid item xs={8}>
                                            {clientTransactionData && clientTransactionData.description && JSON.parse(clientTransactionData.description).beneficiaryBank ? JSON.parse(clientTransactionData.description).beneficiaryBank : 'N/A'}

                                        </MGrid>

                                    </MBox>
                                    <MBox>
                                        <MGrid item xs={5}>
                                            <FormattedMessage id='beneficiary.bank.account.number.iban' />
                                        </MGrid>
                                        <MGrid item xs={8}>
                                            {clientTransactionData && clientTransactionData.description && JSON.parse(clientTransactionData.description).iban ? JSON.parse(clientTransactionData.description).iban : 'N/A'}

                                        </MGrid>
                                    </MBox>
                                    <MBox>
                                        <MGrid item xs={5}>
                                            <FormattedMessage id='beneficiary.bank.address' />
                                        </MGrid>
                                        <MGrid item xs={8}>
                                            {clientTransactionData && clientTransactionData.description && JSON.parse(clientTransactionData.description).beneficiaryBankAddress ? JSON.parse(clientTransactionData.description).beneficiaryBankAddress : 'N/A'}
                                        </MGrid>

                                    </MBox>
                                    <MBox>
                                        <MGrid item xs={5}>
                                            <FormattedMessage id='beneficiary.bank.country' />
                                        </MGrid>
                                        <MGrid item xs={8}>
                                            {clientTransactionData && clientTransactionData.description && JSON.parse(clientTransactionData.description).beneficiaryCountry}

                                        </MGrid>
                                    </MBox>
                                    <MBox>
                                        <MGrid item xs={5}>
                                            <FormattedMessage id='ntn' />
                                        </MGrid>
                                        <MGrid item xs={8}>
                                            {clientTransactionData && clientTransactionData.description && JSON.parse(clientTransactionData.description).ntn ? JSON.parse(clientTransactionData.description).ntn : 'N/A'}
                                        </MGrid>

                                    </MBox>
                                    <MBox>
                                        <MGrid item xs={5}>
                                            <FormattedMessage id='beneficiary.name' />
                                        </MGrid>
                                        <MGrid item xs={8}>
                                            {clientTransactionData && clientTransactionData.description && JSON.parse(clientTransactionData.description).beneficiaryName}

                                        </MGrid>

                                    </MBox>
                                    <MBox>
                                        <MGrid item xs={5}>
                                            <FormattedMessage id='beneficiary.bank.swift.address.bic' />
                                        </MGrid>
                                        <MGrid item xs={8}>
                                            {clientTransactionData && clientTransactionData.description && JSON.parse(clientTransactionData.description).beneficiaryBIC}

                                        </MGrid>

                                    </MBox><MBox>
                                        <MGrid item xs={5}>
                                            <FormattedMessage id='beneficiary.address' />
                                        </MGrid>
                                        <MGrid item xs={8}>
                                            {clientTransactionData && clientTransactionData.description && JSON.parse(clientTransactionData.description).beneficiaryAddress}

                                        </MGrid>

                                    </MBox>

                                </MGrid>

                            </MBox>
                            :
                            <>
                                {clientTransactionData && clientTransactionData.description && JSON.parse(clientTransactionData.description).paymentName === 'paypal' ?
                                    <MBox>

                                        <MGrid container>
                                            <MBox>
                                                <MGrid item xs={5}>
                                                    <FormattedMessage id='paypal.email' />
                                                </MGrid>
                                                <MGrid item xs={8} sx={{ textTransform: "none" }}>
                                                    {clientTransactionData && clientTransactionData.description && JSON.parse(clientTransactionData.description).email ? JSON.parse(clientTransactionData.description).email : 'N/A'}

                                                </MGrid>

                                            </MBox>


                                        </MGrid>

                                    </MBox>


                                    :
                                    <>
                                        {clientTransactionData.description && JSON.parse(clientTransactionData.description).paymentName === 'Pioneer' ?
                                            <MBox>

                                                <MGrid container>
                                                    <MBox>
                                                        <MGrid item xs={5}>
                                                            <FormattedMessage id='payoneer.email' />
                                                        </MGrid>
                                                        <MGrid item xs={8} sx={{ textTransform: "none" }}>
                                                            {clientTransactionData && clientTransactionData.description && JSON.parse(clientTransactionData.description).email ? JSON.parse(clientTransactionData.description).email : 'N/A'}

                                                        </MGrid>

                                                    </MBox>


                                                </MGrid>

                                            </MBox>
                                            :
                                            ''
                                        }
                                    </>


                                }
                            </>
                        }

                    </MBox>
                </>
            }
        </>
    )
}
