import React from "react";
import { FormattedDate, FormattedTime } from "react-intl";




const timeZone = (createdAt: any, currentUser: any, isTime: any) => {

    if (currentUser.timeZone && isTime) {
        return <>
            <FormattedDate value={createdAt} timeZone={currentUser.timeZone && JSON.parse(currentUser.timeZone).regionName} day="2-digit" month="long" year="numeric" />
            &nbsp;<FormattedTime value={createdAt && createdAt} timeZone={currentUser.timeZone && JSON.parse(currentUser.timeZone).regionName} />
        </>
    }
    else if (currentUser.timeZone && !isTime) {
        return <FormattedDate value={createdAt} timeZone={currentUser.timeZone && JSON.parse(currentUser.timeZone).regionName} day="2-digit" month="long" year="numeric" />
    }
    else if ((currentUser.timeZone === "" && isTime) || (!('timeZone' in currentUser) && isTime)) {
        return <>
            <FormattedDate value={createdAt} day="2-digit" month="long" year="numeric" />
            &nbsp;<FormattedTime value={createdAt && createdAt} />
        </>
    }
    else if ((currentUser.timeZone === "" && !isTime) || ((!('timeZone' in currentUser) && !isTime))) {
        return <FormattedDate value={createdAt} day="2-digit" month="long" year="numeric" />
    }


}
export default timeZone;