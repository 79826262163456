import React, { Fragment } from 'react'

export function MProposal() {
    return (
        <Fragment>

            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2ZM10 19H7V17H10V19ZM10 16H7V14H10V16ZM10 13H7V11H10V13ZM14 19H11V17H14V19ZM14 16H11V14H14V16ZM14 13H11V11H14V13ZM13 9V3.5L18.5 9H13Z" fill="#A7ADBB" />
            </svg>
        </Fragment>
    )
}
