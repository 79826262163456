import { useMutation } from "@apollo/react-hooks";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { gql } from "apollo-boost";
import { History } from "history";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { EmptyContent } from "src/app/components";
import {
  MBox,
  MButton,
  MCircularProgress,
  MIconButton,
  MLink,
  MSkeleton,
} from "src/app/material-ui";
import { MDialogActions } from "src/app/material-ui/dialog-actions/DialogActions";
import { MDialogContent } from "src/app/material-ui/dialog-content/DialogContent";
import { MDialogTitle } from "src/app/material-ui/dialog-title/DialogTitle";
import { MDialog } from "src/app/material-ui/dialog/Dialog";
import { MAddIcon, MCreateIcon, MDeleteIcon } from "src/app/material-ui/icons";
import { showAlert } from "src/app/store";
import { default as RateEditModel } from "../../screens/linguist/rates/add-rates/RateEditModel";
import useStyles from "./RatesStyles";

const DELETED_VENDORS_RATE = gql`
  mutation removeVendorRates($rateId: String!) {
    removeVendorRates(rateId: $rateId)
  }
`;

interface IProps {
  currentTab: string;
  setRatesData: (val: any) => void;
  vendorRatesData: () => void;
  rates: IRate[] | undefined;
  loading: boolean;
  history: History;
}

interface IRate {
  id: number;
  packageName: string;
  packageId: number;
  sourceLanguage: ILanguage;
  targetLanguages: Array<ILanguage>;
  status: string;
  price: number;
  subject: string;
  clients: {
    id: number;
    username: string;
  };
}

interface ILanguage {
  id: number;
  name: string;
  code: string;
}

function Rates({
  currentTab,
  setRatesData,
  rates,
  loading,
  vendorRatesData,
  history,
}: IProps) {
  const classes = useStyles({});

  const [open, setOpen] = React.useState(false);
  const [rateDelete, setRateDeleted] = useState(0);
  const [rateEditModal, setRateEditModal] = useState(false);
  const [editRateId, setEditRateId] = useState(0);

  const appUserRole = useSelector((store: any) => store.userRole.userRole);
  const dispatch = useDispatch();

  const [deleteVendorRates, { loading: deleteLoading }] = useMutation(
    DELETED_VENDORS_RATE,
    {
      fetchPolicy: "no-cache",
      onCompleted: () => {
        if (rates) {
          setRatesData(rates.filter((i: IRate) => i.id !== rateDelete));
        }
        dispatch(
          showAlert({
            message: "Rate Delete successfully",
            messageType: "success",
            showAlertMessage: true,
          })
        );
        handleClose();
      },
    }
  );
  useEffect(() => {
    vendorRatesData();
  }, [vendorRatesData, rateEditModal]);
  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "#6b778c",
      fontSize: 12,
      filter: "drop-shadow(0px 8px 16px rgba(214,215,226,0.57))",
      border: "1px solid #f4f5f7",
      borderRadius: 0,
      padding: "20px 15px",
      maxWidth: 230,
    },
    arrow: {
      color: theme.palette.common.white,
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const dialogCloseYes = () => {
    deleteVendorRates({
      variables: {
        rateId: rateDelete,
      },
    });
  };

  const moreTargetText = (targetLan: ILanguage[]) => {
    const langNameArr: Array<string> = [];
    targetLan.forEach((lang: ILanguage, index: number) => {
      if (index !== 0) {
        langNameArr.push(lang.name);
      }
    });
    return langNameArr.join(", ");
  };

  return (
    <div className="vendorratesScreen">
      <RateEditModel
        isDrawer={rateEditModal}
        setIsDrawer={setRateEditModal}
        editRateId={editRateId}
        getVendorsRateDetails={vendorRatesData}
      />

      <MDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <MDialogTitle id="alert-dialog-title">
          <FormattedMessage id="are.sure" />
        </MDialogTitle>
        <MDialogContent dividers>
          <MBox mr={9}>
            <FormattedMessage id="clicking.selected.rate.deleted" />
          </MBox>
        </MDialogContent>
        <MDialogActions>
          <MBox mr={1}>
            <MLink href="#" onClick={handleClose} color="inherit">
              <FormattedMessage id="no" />
            </MLink>
          </MBox>
          <MButton
            disabled={deleteLoading}
            onClick={dialogCloseYes}
            variant="contained"
            color="primary"
            autoFocus
          >
            {deleteLoading && <MCircularProgress color="primary" size={24} />}
            <FormattedMessage id="yes" />
          </MButton>
        </MDialogActions>
      </MDialog>
      <MBox>
        {rates && rates.length < 1 && !loading && (
          <EmptyContent
            isContent={true}
            text={
              appUserRole === "ROLE_TRANSLATOR"
                ? "Vendor rates are specifically created for prospective vendors. You will be able to create rates for vendors when they request them."
                : "Client rates are specifically created for prospective clients. You will be able to create rates for clients when they request them."
            }
            buttonProps={{
              startIcon: <MAddIcon sx={{ marginRight: -7 }} />,
              onClick: () =>
                history.push(
                  appUserRole === "ROLE_TRANSLATOR"
                    ? "/linguist/rates/add-rates"
                    : "/vendor/rates/add-rates"
                ),
              text: <FormattedMessage id="add.rate" />,
            }}
          />
        )}
        {rates &&
          rates.map((vendorRates: IRate, idx: number) => (
            <MBox
              key={idx}
              className={classes.ratesList}
              px={2}
              mb={1.25}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MBox flexBasis="53%">
                <MBox fontFamily="Poppins" fontSize={14} color="#393939" mb={0}>
                  {vendorRates.packageName}
                </MBox>
                <MBox fontSize={13} fontFamily="Poppins" color="#6b778c">
                  {vendorRates.subject}
                  <FiberManualRecordIcon className={classes.dotIcon} />
                  {vendorRates.sourceLanguage.name}
                  {vendorRates.targetLanguages.length !== 0 && (
                    <>
                      <FiberManualRecordIcon className={classes.dotIcon} />
                      {vendorRates.targetLanguages[0].name}
                    </>
                  )}
                  {vendorRates.targetLanguages &&
                    vendorRates.targetLanguages.length > 1 && (
                      <LightTooltip
                        arrow
                        title={moreTargetText(
                          vendorRates.targetLanguages &&
                          vendorRates.targetLanguages
                        )}
                        placement="top"
                      >
                        <NavLink to="#">
                          &nbsp;
                          {vendorRates.targetLanguages &&
                            vendorRates.targetLanguages.length - 1}
                          &nbsp;
                          <FormattedMessage id="more" />
                        </NavLink>
                      </LightTooltip>
                    )}
                  <FiberManualRecordIcon className={classes.dotIcon} />
                  {vendorRates.price}
                  <FormattedMessage id="per.word" />
                </MBox>
              </MBox>
              {currentTab === "client" && (
                <MBox
                  minWidth="15%"
                  flexBasis="15%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <MBox className={`${classes.status} ${vendorRates.status}`}>
                    {vendorRates.status}
                  </MBox>
                </MBox>
              )}
              <MBox className={classes.actionWrap}>
                <MIconButton
                  aria-label="edit"
                  onClick={() => {
                    setRateEditModal(true);
                    setEditRateId(vendorRates.id);
                  }}
                // vendorRates.id
                >
                  <MCreateIcon />
                </MIconButton>

                {vendorRates.status !== "approved" && (
                  <MIconButton
                    aria-label="delete"
                    onClick={() => {
                      handleClickOpen();
                      setRateDeleted(vendorRates.id);
                    }}
                  >
                    <MDeleteIcon />
                  </MIconButton>
                )}
              </MBox>
            </MBox>
          ))}
        {(loading || !rates) &&
          [0, 1, 2].map((_, index: number) => (
            <MBox
              key={"box" + index}
              className={classes.ratesList}
              px={2}
              mb={1.25}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MBox flexBasis="50%">
                <MBox mb={1.5}>
                  <MSkeleton height={20} width={150} />
                </MBox>
                <MSkeleton width={200} height={16} />
              </MBox>
              <MBox
                minWidth="15%"
                flexBasis="15%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <MSkeleton width={76} height={28} />
              </MBox>
              <MBox>
                <MSkeleton width={80} height={24} />
              </MBox>
            </MBox>
          ))}
      </MBox>
    </div>
  );
}

export default Rates;
