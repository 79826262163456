import React from 'react'

export function MBriefcase() {
    return (



        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.668 3.99984C16.1513 3.99984 16.543 4.1665 16.8513 4.4915C17.168 4.83317 17.3346 5.20817 17.3346 5.6665V14.8332C17.3346 15.2915 17.168 15.6665 16.8513 16.0082C16.543 16.3332 16.1513 16.4998 15.668 16.4998H2.33464C1.8513 16.4998 1.45964 16.3332 1.1513 16.0082C0.834635 15.6665 0.667969 15.2915 0.667969 14.8332V5.6665C0.667969 5.20817 0.834635 4.83317 1.1513 4.4915C1.45964 4.1665 1.8513 3.99984 2.33464 3.99984H5.66797V2.33317C5.66797 1.84984 5.83464 1.45817 6.1513 1.14984C6.45964 0.833171 6.8513 0.666504 7.33464 0.666504H10.668C11.1513 0.666504 11.543 0.833171 11.8513 1.14984C12.168 1.45817 12.3346 1.84984 12.3346 2.33317V3.99984H15.668ZM2.33464 5.6665V14.8332H15.668V5.6665H2.33464ZM10.668 3.99984V2.33317H7.33464V3.99984H10.668ZM9.0013 6.49984C9.49858 6.49984 9.9755 6.69738 10.3271 7.04901C10.6788 7.40064 10.8763 7.87756 10.8763 8.37484C10.8763 9.4165 10.0346 10.2498 9.0013 10.2498C8.50402 10.2498 8.02711 10.0523 7.67548 9.70066C7.32385 9.34903 7.1263 8.87212 7.1263 8.37484C7.1263 7.33317 7.96797 6.49984 9.0013 6.49984ZM12.7513 13.9998H5.2513V13.0665C5.2513 12.0248 6.91797 11.1915 9.0013 11.1915C11.0846 11.1915 12.7513 12.0248 12.7513 13.0665V13.9998Z" fill="#42526E" />
        </svg>

    )
}

