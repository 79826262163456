
import { RESET_STORE } from '../resetStateAction';
import { GET_MANAGE_ADMIN_FETCH, GET_MANAGE_ADMIN_SUCCESS } from "./PeoplesActionType";

interface IAction {
    type: string,
    payload: any,
    response: any,
    action: any,
    adminList: any

}

interface IState {
    peopleLoading: boolean,
    adminList: any

}

const initialState: IState = {
    peopleLoading: false,
    adminList: []

};


const peopleReducer = (state = initialState, action: IAction) => {

    switch (action.type) {

        case GET_MANAGE_ADMIN_FETCH:
            return {
                ...state,
                peopleLoading: true
            }
        case GET_MANAGE_ADMIN_SUCCESS:
            return {

                ...state,
                adminList: action.response,
                peopleLoading: false
            }

        case RESET_STORE:
            return initialState;
        default:
            return state;
    }
};

export default peopleReducer;