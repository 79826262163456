import { makeStyles } from '@mui/styles';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
const useStyles = makeStyles(() =>
({
    mainWrapper: {

        '& .MuiPaper-root': {
            overflow: 'unset',


        },

    },

    iconWrapper: {
        position: 'absolute',
        top: '-50px',

        zIndex: 2,
        left: '50%',
        transform: 'translate(-50%, 0px)',
    },

    contentBox: {
        borderBottom: "2px solid #ECECEC",
        position: 'relative',
        overflow: 'unset',


    }
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;