import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles(() =>
    createStyles({
        paymentCard: {
            width: '100%',
            maxWidth: '732px',
            // margin: '60px auto 0 auto',
            backgroundColor: '#FDFDFD',
            border: '1px solid #E8E8E8',
            borderRadius: 5,
            padding: 48,
            transition: 'all 0.5s cubic-bezier(1, -0.26, 1, 1)',
        },
        creditCard: {
            backgroundColor: '#E5F1F7'
        },
        defaultStatus: {
            fontSize: 12,
            minWidth: 76,
            height: 28,
            padding: 15,
            borderRadius: 14,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textTransform: "capitalize",
            backgroundColor: '#DFF5EB',
            color: '#16C68E'
        },
        cardDetails: {
            border: '1px solid #F2F2F4',
            borderRadius: 4,
            display: 'flex',
            padding: 14,
            // justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#fff',
            '& .MuiRadio': {
                '&$checked': {
                    color: 'green',
                }
            },
        },
        cardBorder: {
            border: '1px solid #F2F2F4',
            padding: 14,
            backgroundColor: '#fff',
            borderRadius: 5,
            marginRight: 16,
        },
        cardDetailsCenter: {

            justifyContent: 'center',

        },
        paymentBox: {

            border: '1px solid #F9E2E2',
            borderRadius: 4,
            padding: '23px 0',
            textAlign: 'center',
            margin: '16px 0 48px 0',
            backgroundColor: '#FFF2F2',
            color: '#D29999'

        },
        balanceBox: {
            backgroundColor: '#E5F1F7',
            border: '1px solid #DDE9EF',
            borderRadius: 4,
            padding: '36px 0',
            textAlign: 'center',
            // margin: '16px 0 48px 0'
        },
        BankCard: {
            width: '100%',
            maxWidth: '732px',
            margin: '60px auto 60px auto',
        },
        bankTransferCard: {

            backgroundColor: '#FDFDFD',
            border: '1px solid #E8E8E8',
            borderRadius: 5,
            padding: 48,
            transition: 'all 0.5s cubic-bezier(1, -0.26, 1, 1)',
            '& .MuiBox-root': {
                '&:nth-child(2)': {
                    '& >.MuiBox-root': {
                        // '&:nth-child(2)': {
                        //     display: 'none'
                        //
                        // }
                    }

                }

            },
            '& .dzu-input': {
                display: 'none'
            },
            '& img': {
                width: '55px !important'
            },
            '& .dzu-dropzone': {
                maxWidth: 370,
                width: '100%',
                padding: '14px 0px',
            },
            '& textarea': {
                border: '1px solid #A0A5B9',
                borderRadius: 4,
                fontSize: 14,
                padding: 10,
                fontFamily: "Poppins",
            },
            '& textarea::placeholder': {


                color: '#6B778C !important',
                fontSize: 14,
                // padding: 10,
                fontFamily: "Poppins",

                // '&placeholder': {
                //     color: 'red !important',
                //     fontSize: 58,
                // }
            }
        },

        DropZoneInput: {
            fontFamily: "Poppins",
            textAlign: 'left',
            color: '#3a3a3a',
            cursor: 'pointer',
            fontSize: '14px'
        },
        DropZoneDetailInput: {
            fontFamily: "Poppins",
            textAlign: 'left',
            color: '#6B778C',
            cursor: 'pointer',
            fontSize: '12px'
        },
        detailsCard: {
            // border: '1px solid #E8E8E8',

            // '& >.MuiBox-root': {
            //     '&:nth-child(1)': {
            //         // backgroundColor: '#F7F8FC',
            //         // padding: '16px 24px',
            //         borderRadius: 4,
            //         color: '#6B778C',
            //         fontFamily: "Poppins",
            //         fontSize: 18

            //     }
            // },
            '& .MuiGrid-container': {
                flexDirection: 'column',

                '& .MuiGrid-grid-xs-3': {
                    fontFamily: "Poppins",
                    color: '#3A3A3A !important'
                },
                '& .MuiGrid-grid-xs-9': {
                    fontFamily: "Poppins",
                    color: '#758094',
                    textTransform: 'UpperCase'
                },
                '& .MuiBox-root': {
                    padding: '16px 24px',
                    borderStyle: 'solid',
                    borderColor: '#E8E8E8',
                    borderWidth: '0px 0px 1px 0px',
                    display: 'flex',
                    alignItems: 'center',
                }


            },

        },
        preview: {
            width: '25%',
            '& img': {
                width: '100% !important',
                height: '100%',
                borderRadius: 5,


            }
        },
        imgUploader: {
            border: '1px dashed lightgrey',
            width: '65%',
            padding: '14px 0px',
            maxWidth: '370px'
        },
        footerBottom: {
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'fixed',
            bottom: 0,
            right: 0,
            width: '85%',
            padding: '20px 35px 20px 0px',
            backgroundColor: '#fff',
            borderTop: '1px solid #dbdbdb',

        },
    }));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;