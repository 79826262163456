
import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    contentBox: {
      backgroundColor: 'white',
      // boxShadow: '0px 5px 5px 0px rgba(193, 199, 208, 0.26)',
      borderRadius: 5,
      // marginBottom:85,
      '& .MuiFormHelperText-root.Mui-error': {
        minWidth: 120,
        textTransform: 'initial'
      }
    },
    inputLabel: {
      "& label": {
        top: "-2px",
        fontSize: "14px"
      },
      // paddingLeft: "10px !important"
    },
    fileUploadDrawer: {
      "& .MuiPaper-root.MuiDrawer-paper": {
        minWidth: "85%",
        maxWidth: "85%",
      },
      '&.fullWidth': {
        "& .MuiPaper-root.MuiDrawer-paper": {
          transform: 'translateX(-18%) !important'
        }
      },
    },

    hr: {
      borderBottom: "1px solid #dbdbdb",
    },
    greyUppercaseText: {
      color: theme.palette.text.secondary,
      textTransform: "uppercase",
    },
    colorLightGrey: {
      color: "#a0a5b9",
    },
    inputWhite: {
      backgroundColor: "#fff",
      "& input": {
        backgroundColor: "#fff",
        // padding: '9.2px 8px',
        padding: '8px 6px 11px 6px',
        fontSize: 14,
        '&::placeholder': {
          fontSize: '12px !important',
          color: '#6b778c',
          opacity: 0.7,
        }
      },
      '& textarea::placeholder': {
        color: '#6b778c',
        opacity: 1,
        fontSize: 14,
        '&::placeholder': {
          fontSize: 14,
        }
      }
    },
    smallInput: {
      // "& input":{
      //   maxHeight: 35,
      //   boxSizing: "border-box"
      // },
    },

    headerLeftCol: {
      "& > div": {
        width: "calc(50% - 6px)",
      },
    },
    headerRightCol: {
      "& > div": {
        // width: "calc(50% - 15px)",
      },
    },
    leftCol: {
      "& > div": {
        width: "calc(50% - 6px)",
      }
    },
    rightCol: {
      // "& > div":{
      //   width: "calc(50% - 33px)",
      // },
    },
    bgLightGrey: {
      backgroundColor: "#f7f9fa",
      padding: 13,
    },
    discountInput: {
      width: 74,
      "& input": {
        padding: '3.2px 10.5px',
        fontSize: 12,
        '&::placeholder': {
          fontSize: 13,
        }
      },
    },
    deleteIcon: {
      color: '#6b778c',
      cursor: 'pointer',
      transition: 'all .3s',
      '&:hover': {
        color: 'rgb(63, 116, 232)'
      }
    },
    addRowButton: {
      backgroundColor: '#e5f1f7',
      padding: 8,
      borderRadius: 4,
      color: theme.palette.secondary.main,
      display: 'inline-block',
      minWidth: 70,
      cursor: 'pointer',
    },
    autoCompleteSizeSm: {
      "& .Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #6b778c",
        },
      },
      // backgroundColor: "#f5f6f7",s
      borderRadius: 3,
      "& .MuiFormLabel-root": {
        fontSize: 13,
        marginTop: "-3px",
        // transform: 'translate(8px, 12px) scale(1)',
      },
      // "& .MuiAutocomplete-tagSizeSmall": {
      //   width: "65%",
      // },
      // '& .MuiAutocomplete-input': {
      //   fontSize: 14
      // },
      "& .MuiAutocomplete-inputRoot": {
        minHeight: 32,
        paddingTop: "0px !important",
      },
      // "& .MuiAutocomplete-input": {
      //   fontSize: 14,
      //   flexGrow: 0,
      //   minWidth: "auto",
      // },
      // '& .MuiOutlinedInput-notchedOutline': {
      //     border: '1px solid #6b778c'
      // },
      "& .MuiInputBase-root": {
        padding: "1px !important",
      },
      // '& legend': {
      //     width: 0
      // },
      // "& fieldset": {
      //   border: "none",
      // },
    },
    textArea: {
      fontSize: 14,
      fontFamily: 'Poppins',
      padding:"5px !important",
      border: '1px solid #ddd',
      borderRadius: 4,
      width:'100%',
      minHeight: 28,
      '&:focus': {
        // outline: '2px solid #3f74e8',
        border: '2px solid #3f74e8 !important',
        outline: 'none'
        
    }
  },
    editorBox: {
      minHeight: 115,
      border: '1px solid #ddd',

      "& .MuiGrid-spacing-xs-1": {
        borderBottom: "1px solid #ddd",
        margin: 0,
        width: "100%",
      },
      "& .MuiContainer-root.MuiContainer-maxWidthLg": {
        padding: 0,
      },
      "& .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1.MuiGrid-justify-xs-space-evenly .MuiGrid-root.MuiGrid-item:nth-child(5)": {
        display: "none",
      }
    },
    editorWrap: {
      '& #h4Heading, & #h5Heading, & #h6Heading, & #strikeThrough': {
        display: 'none'
      },
      '& .MuiGrid-item': {
        padding: 0
      },
      '& .MuiButtonBase-root': {
        padding: '12px 7px'
      }
    },

    formLabel: {
      fontSize: 14,
      color: theme.palette.text.primary,
    },
    instructionWrap: {
      '& img': {
        maxWidth: '80%'
      }
    },
    footerBottom: {
      position: 'fixed',
      width: '85%',
      bottom: 0,
      right: 0,
      borderTop: '1px solid #dbdbdb',
      backgroundColor: '#fff',
      paddingRight: 36
    },
    taskList: {
      '& .MuiAutocomplete-input': {
        padding: '0px !important'
      },


    },
    radioBtn: {
      fontSize: 13,
      color: '#A0A5B9',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',

      '& .MuiFormControlLabel-root': {
        width: '100%'
      },
      '& .MuiTypography-root': {
        display: 'inline-block',
        overflow: ' hidden',
        whiteSpace: 'nowrap',
        color: '#3A3A3A',
        fontFamily: 'Poppins',
        fontWeight: 400,
        textOverflow: 'ellipsis',
        maxWidth: '7vw',

      },
      '& .MuiBox-root': {
        '&:nth-child(2)': {
          textOverflow: 'ellipsis',
          maxWidth: '3.604vw',
          overflow: 'hidden',
          whiteSpace: 'nowrap'
        }

      }
    },
    hideerrorcontaine: {
      '& .MuiFormHelperText-contained': {
        display: 'none',
      },
    },
    radioOptions: {
      '& .MuiSvgIcon-root': {
        height: 18,
        width: 18
      }
    }
  }));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;