import React, { Fragment } from 'react'

export function MDollar() {
    return (
        <Fragment>

            <svg width="28" height="28" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_40_1083)">
                    <path d="M26.66 9.0016C25.317 6.15885 23.1058 3.90921 20.266 2.49375C16.4012 0.56802 11.9611 0.501463 8.08748 2.31035C4.1946 4.12811 1.38883 7.57431 0.386034 11.766C0.272146 12.2437 0.207068 12.7333 0.143468 13.2051C0.113887 13.4254 0.0843062 13.6458 0.0502879 13.8647C0.0428926 13.9121 0.0325392 13.9579 0.0236649 14.0038L0.00739528 14.0807L0 15.8555L0.00295811 15.9058C0.0251439 16.0685 0.0458507 16.2327 0.0680366 16.3954C0.113887 16.7459 0.159738 17.1068 0.217421 17.4648C0.646347 20.1078 1.96123 22.6814 3.91802 24.7106C5.87629 26.7414 8.40104 28.1495 11.0293 28.6745C11.4553 28.7603 11.8886 28.8165 12.3072 28.8697C12.5099 28.8964 12.711 28.9215 12.9136 28.9511C12.9403 28.9555 12.9654 28.9629 12.992 28.9703C13.0142 28.9762 13.0349 28.9822 13.0571 28.9866L13.1 28.9969H14.8734L14.8985 28.994C15.0553 28.9718 15.2136 28.9526 15.3703 28.9319C15.7091 28.889 16.0596 28.8431 16.4057 28.7854C21.9314 27.8596 26.4529 23.557 27.6569 18.0815C28.3431 14.974 27.997 11.834 26.66 9.0016ZM25.5522 15.0036C25.5522 18.0845 24.3453 20.9864 22.1548 23.1739C19.9702 25.3555 17.0772 26.5565 14.0067 26.5565C13.9993 26.5565 13.9933 26.5565 13.986 26.5565C7.62157 26.5447 2.44488 21.3635 2.44488 15.0051C2.44488 11.9242 3.65179 9.0223 5.84227 6.83478C8.02683 4.65317 10.9199 3.45218 13.9904 3.45218C13.9963 3.45218 14.0037 3.45218 14.0096 3.45218C20.374 3.46401 25.5522 8.64514 25.5522 15.0036Z" fill="#7E889A" />
                    <path d="M13.9739 12.7816C13.045 12.7609 12.2833 12.0332 12.2419 11.1266C12.2197 10.6459 12.3854 10.1859 12.7093 9.83091C13.0228 9.48777 13.4473 9.2881 13.9029 9.26887C14.3865 9.24964 14.8391 9.41086 15.1823 9.72738C15.5313 10.0483 15.7354 10.498 15.7561 10.9905C15.7872 11.7374 16.3137 12.2654 17.0148 12.2447C17.3402 12.2359 17.639 12.1027 17.8549 11.8735C18.0871 11.6265 18.2084 11.2848 18.1966 10.9121C18.1596 9.82351 17.7558 8.87248 16.9971 8.0871C16.5164 7.59162 15.9203 7.22777 15.2207 7.00443V6.99408C15.2222 6.7811 15.2222 6.57255 15.2192 6.364C15.2074 5.66145 14.6883 5.12603 14.0108 5.12012C14.0064 5.12012 14.0035 5.12012 13.999 5.12012C13.3127 5.12012 12.7891 5.65701 12.7788 6.37436C12.7758 6.58438 12.7773 6.79293 12.7773 7.00295V7.01183C11.6177 7.36089 10.6682 8.19212 10.1653 9.30289C9.65799 10.4225 9.66982 11.6945 10.1964 12.792C10.9374 14.3361 12.2596 15.1777 14.0197 15.2265C14.9604 15.2531 15.7236 15.9897 15.7547 16.9052C15.7887 17.8755 15.0432 18.6993 14.0922 18.7407C13.613 18.7614 13.1589 18.6002 12.8143 18.2837C12.4638 17.9613 12.2596 17.5131 12.2389 17.0191C12.2079 16.2737 11.6843 15.7442 10.9817 15.7649C10.6519 15.7738 10.3517 15.9069 10.1357 16.1391C9.90499 16.3861 9.78519 16.7277 9.80146 17.099C9.85766 18.4982 10.4508 19.606 11.563 20.3884C11.8647 20.6014 12.202 20.7597 12.5288 20.9135C12.6102 20.9519 12.693 20.9904 12.7773 21.0318C12.7758 21.23 12.7744 21.4504 12.7803 21.6722C12.7951 22.3467 13.3246 22.8821 13.9857 22.8895C13.9901 22.8895 13.9946 22.8895 13.999 22.8895C14.669 22.8895 15.2044 22.3541 15.2192 21.6663C15.2237 21.4563 15.2222 21.2477 15.2222 21.0288C15.2222 21.0214 15.2222 21.0126 15.2222 21.0052C15.2252 21.0037 15.2296 21.0037 15.2325 21.0022C16.4868 20.6162 17.4718 19.6888 17.9348 18.4553C18.3933 17.2365 18.2661 15.9069 17.5857 14.8065C16.7752 13.4975 15.5594 12.8156 13.9739 12.7816Z" fill="#7E889A" />
                </g>
                <defs>
                    <clipPath id="clip0_40_1083">
                        <rect width="28" height="29" fill="white" />
                    </clipPath>
                </defs>
            </svg>

        </Fragment>
    )
}
