import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
 
    ratesList:{
      // boxShadow: '0px 0px 32px 0px rgba(214, 215, 226, 0.3)',
      backgroundColor: "#fff",
      border: "1px solid #f4f5f7",
      height: 75,
      borderRadius: 5,
      filter: 'drop-shadow(0 0 16px rgba(214,215,226,0.3))',
      '& a':{
        color: theme.palette.secondary.main,
        textDecoration: 'none'
      },
    },
    dotIcon:{
      fontSize: 10,
      color: '#bfc1cd',
      margin: '0 12px -1px'
    },
    status:{
      fontSize: 12,
      width: 76,
      height: 28,
      borderRadius: 14,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textTransform: "capitalize",
      '&.created':{
          backgroundColor:'#f9f5e7',
          color: '#e69900',
      },
      '&.rejected':{
          backgroundColor:'#ffe8e8',
          color: '#ff0102',
      },
      '&.approved':{
          backgroundColor:'#dff5eb',
          color: '#16c68e',
      },
      '&.pending':{
          backgroundColor:'#e5f1f7',
          color: theme.palette.secondary.main
      },
    },
 
    actionWrap:{
      '& button':{
        padding: 5,
      },
      '& svg':{
        color: '#a0a5b9'
      }
    },
   
  
  }));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;