import React, { Fragment } from 'react'

export function MInstalled() {
    return (
        <Fragment>


            <svg width="22" height="13" viewBox="0 0 22 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.602 8.76L12.014 10.172L20.48 1.706L21.894 3.121L12.014 13.001L5.65 6.636L7.064 5.222L9.189 7.347L10.602 8.76ZM10.604 5.932L15.556 0.979004L16.966 2.389L12.014 7.342L10.604 5.932ZM7.777 11.587L6.364 13L0 6.636L1.414 5.222L2.827 6.635L2.826 6.636L7.777 11.587Z" fill="#16C68E" />
            </svg>


        </Fragment>
    )
}
