import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles((theme?: Theme) =>
  createStyles({
    inputWidth:{
      width: '100%',
    },
    // instructions:{
    //   color: '#a0a5b9',
    // },
    sourceLangSelect:{
      "&.MuiSelect-outlined":{
        borderColor: theme?.palette.secondary.main,
      },
    },
    redBorder:{
      // "& fieldset":{
      //   borderColor: '#f44336 !important'
      // }
    },
    sizeMd:{
      '& .MuiAutocomplete-inputRoot':{
        minHeight: 40, 
      }
    },
  
  }));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;