function CheckPermissions(permissionName: any, selectedOrgId: any, organizations: any) {
  let isAllow = false;
  if (organizations?.length) {
    organizations?.map((org: any) => {
      if (org.id == selectedOrgId) {
        org?.permissions?.map((p: any) => {
          if (p.name == permissionName && p.granted == true) {
            isAllow = true;
          }
        });
      }
    });
  }

  return isAllow;
}
export default CheckPermissions;