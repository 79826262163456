import React, { Fragment } from 'react'

export function MEssential() {
    return (
        <Fragment>

            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 0C13.7 0 13.4 0.15 13.1 0.3L1.25 6.9C0.8 7.2 0.5 7.65 0.5 8.25V21.75C0.5 22.35 0.8 22.8 1.25 23.1L13.1 29.7C13.4 29.85 13.7 30 14 30C14.3 30 14.6 29.85 14.9 29.7L16.25 28.95C15.8 28.05 15.65 27 15.5 25.95V15.9L24.5 10.8V16.5C25.55 16.5 26.6 16.65 27.5 16.95V8.25C27.5 7.65 27.2 7.2 26.75 6.9L14.9 0.3C14.6 0.15 14.3 0 14 0ZM14 3.3L23 8.25L20 9.9L11.15 4.8L14 3.3ZM8.15 6.45L17 11.7L14 13.35L5 8.25L8.15 6.45ZM3.5 10.8L12.5 15.9V25.95L3.5 20.85V10.8ZM27.95 20.7L22.55 26.1L20.15 23.7L18.5 25.5L22.7 30L29.9 22.8L27.95 20.7Z" fill="#A2A4B9"/>
            </svg>

        </Fragment>

)
}