import React from 'react';
import {
    MBox,
    MDrawer,
    MAppBar,
    MToolbar,
    MIconButton
} from 'src/app/material-ui';
import SendQuote from "../../screens/vendor/send-quote/SendQuote";
import useStyles from '../job-listing/JobListingStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FormattedMessage } from 'react-intl';

const ViewDraft = (props: any) => {
    const classes = useStyles();
    const { getAllJobs, setSendDrawer, sendDrawer, quoteResponseId, setViewDraftOpen } = props;
    return (
        <>
            <MDrawer
                open={props.viewDraftOpen}
                variant="temporary"
                anchor='right'
                className={classes.fileUploadDrawer}
                onClose={() => setViewDraftOpen(false)}
            >
                <MAppBar className='drawerAppBar'>
                    <MToolbar>
                        <MBox display="inline-block">
                            <MIconButton onClick={() => setViewDraftOpen(false)}>
                                <ArrowBackIcon />
                            </MIconButton>
                        </MBox>
                        <MBox width="100%" fontSize={18} fontFamily="Poppins">
                            <FormattedMessage id="view.draft" />
                        </MBox>
                    </MToolbar>
                </MAppBar>
                <SendQuote getAllJobs={getAllJobs} setSendDrawer={setSendDrawer} sendDrawer={sendDrawer} quoteResponseId={quoteResponseId} setViewDraftOpen={setViewDraftOpen} />
            </MDrawer>
        </>

    )
}
export default ViewDraft;