import React, { Fragment } from 'react'

export function MPaypalIcon() {
    return (
        <Fragment>

            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M8.83751 5.06042H14.1926C17.0677 5.06042 18.1501 6.49151 17.9828 8.59398C17.7078 12.0677 15.5735 13.9854 12.7411 13.9854H11.3118C10.9234 13.9854 10.6622 14.2368 10.5571 14.9231L9.94336 18.9052C9.90327 19.1634 9.76504 19.3129 9.55769 19.3333H6.20008C5.88353 19.3333 5.77156 19.0954 5.8545 18.5817L7.90446 5.8147C7.9874 5.3037 8.27077 5.06042 8.83751 5.06042Z" fill="#009EE3" />
                <path fillRule="evenodd" clipRule="evenodd" d="M5.4887 0.666626H10.8465C12.367 0.666626 14.1516 0.714193 15.3487 1.75387C16.149 2.44699 16.5692 3.55327 16.4725 4.7438C16.1435 8.76933 13.6954 11.0226 10.4111 11.0226H7.76674C7.31749 11.0226 7.01891 11.3162 6.89174 12.1099L6.15359 16.7307C6.10659 17.0297 5.97389 17.2064 5.7389 17.2267H2.43105C2.06473 17.2267 1.9348 16.9549 2.03018 16.3542L4.40774 1.54186C4.50312 0.94931 4.83349 0.666626 5.4887 0.666626Z" fill="#113984" />
                <path fillRule="evenodd" clipRule="evenodd" d="M6.97656 11.641L7.91238 5.8147C7.99532 5.3037 8.27869 5.06042 8.84544 5.06042H14.1936C15.0796 5.06042 15.797 5.19633 16.3583 5.4464C15.8192 9.02887 13.4637 11.0185 10.377 11.0185H7.73406C7.39125 11.0185 7.12585 11.1939 6.97656 11.641Z" fill="#172C70" />
            </svg>

        </Fragment>
    )
}