import React, { Fragment } from 'react'

export function MPayment() {
    return (
        <Fragment>

            <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.25 18.5V55.5H41.07C40.3917 53.4342 40.0833 51.3683 40.0833 49.3333H21.5833C21.5833 45.9417 18.8392 43.1667 15.4167 43.1667V30.8333C18.8392 30.8333 21.5833 28.0892 21.5833 24.6667H52.4167C52.4167 28.0892 55.1917 30.8333 58.5833 30.8333V31.0183C60.6492 31.0183 62.715 31.3883 64.75 32.0667V18.5H9.25ZM37 27.75C31.7583 27.8425 27.75 31.7583 27.75 37C27.75 42.2417 31.7583 46.065 37 46.25C38.1717 46.25 39.3742 46.0033 40.515 45.5408C41.3475 42.1492 42.735 38.9425 46.1575 35.7975C45.7875 31.6967 41.9025 27.6575 37 27.75ZM66.6925 37.8325L54.76 49.8575L50.5975 45.6333L46.25 50.0117L54.7292 58.5833L71.0092 42.18L66.6925 37.8325Z" fill="#DEDEDE" />
            </svg>

        </Fragment>
    )
}
