import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles((theme?: Theme) =>
    createStyles({
        inputWidth:{
            width: '100%',
        },
        instructions:{
            color: '#a0a5b9',
        },
        langSelect:{
            "&.MuiSelect-outlined":{
                borderColor: theme?.palette.secondary.main,
            },
            "& .redBorder":{
                // "& fieldset":{
                //     borderColor: '#f44336'
                // }
            }
        },
      
    }));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;