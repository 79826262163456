import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sidebarWrap: {
      // paddingTop: 20,
      '& .MuiBox-root': {
        padding: '0px 0px',
      },
      '& .MuiBox-root-111': {
        padding: '0px 0px',
      },
      // '& .MuiBox-root-117':{
      //   padding: '0px 0px !important',
      //   '& .MuiBox-root-118':{
      //     // margin:'50px 0px 18px 35px',
      //     color: '#6B778C !important',
      //     fontSize: 16,
      //     fontWeight: 600,
      //     textTransform: 'uppercase',

      //   },

      // },

      // "&.vendor":{
      //   paddingTop: 80,
      // }
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      border: '2px solid #cfd3db',
      width: '100%',
      marginTop: 2,
      minHeight: 35,
      '& input': {
        fontSize: 12
      },
      '&:hover': {
        borderColor: theme.palette.secondary.main,
      },
    },
    searchIcon: {
      position: 'absolute',
      right: 5,
      top: 5,
      pointerEvents: 'none',
      '& svg': {
        fontSize: '20px',
        color: '#6f7b8f',
      },
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: '8px 32px 9px 8px',
      transition: theme.transitions.create('width'),
      width: '100%',
    },
    subheader: {
      backgroundColor: theme.palette.background.paper,
      paddingRight: 0,
      paddingLeft: 0,
    },
    listItem: {
      // maxWidth:350,
      // minWidth:350,
      padding: theme.spacing(1, 1),
      marginLeft: -8,
      "&:hover .MuiAvatar-root": {
        backgroundColor: theme.palette.secondary.main,
        color: "#fff",
      },
      "&:hover .MuiListItemText-root span": {
        color: theme.palette.secondary.main,
      },
    },
    listItemText: {
      margin: 0,
      width: 110,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      '& span': {
        fontSize: 14,
        textTransform: "capitalize",
      },
    },
    viewAllLink: {
      color: theme.palette.secondary.main,
      lineHeight: 2,
      textDecoration: 'none',
    },
    avatarWrap: {
      "& .MuiAvatar-root": {
        transition: "ease .3s",
        fontSize: 14,
        textTransform: 'uppercase',
        backgroundColor: "#e6edfd",
        color: "#2c2c2c",
      },
    },
    footer: {
      textAlign: 'center',
      padding: theme.spacing(2, 3),
      backgroundColor: '#fafafa',
    },
    createProjectBtn: {
      minWidth: 196,
    },

    // client sidebar
    projectAvatar: {
      width: "100%",
      height: "100%",
      // alignItems: "start",
      // backgroundColor: "transparent",
      "& .MuiAvatar-img": {
        height: "auto",
      }
    },
    workFlowBox: {
      cursor: 'pointer',
      "& .workFlowRow": {
        position: "relative",
      },

      "& .directionImgFirstCase": {
        width: 7,
        height: 8,
        position: "absolute",
        top: 4,
        left: "-20px",
        background: "url('/assets/images/work-flow-direction-single-case.png') no-repeat",
      },

      "& .directionImgMultipleCase": {
        width: 7,
        height: 30,
        position: "absolute",
        bottom: 4,
        left: "-20px",
        background: "url('/assets/images/work-flow-direction-multiple-case.png') no-repeat",
      },
    },
    projectSidebarFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#fff',
      width: 'calc(16.5% - 1px)',
      padding: '15px !important',
      position: 'fixed',
      boxShadow: '0px -1px 10px rgba(220, 221, 222, 0.8)',
      bottom: 0,
      '& .footerAvatar': {
        width: 36,
        height: 36
      }
    },
    userDropdown: {
      minWidth: 195,
      "& ul": {
        minWidth: 195,
        '& .MuiListItem-button': {
          borderRadius: 4,
          margin: theme.spacing(0, 1),
        }
      },
    },
    menuLink: {
      color: theme.palette.text.primary,
      textDecoration: 'none',
    },
    sidebarLink: {
      color: theme.palette.text.primary,
      textDecoration: 'none',
    },
    logoutLink: {
      "color": "#f44336",
      "&:hover": {
        color: theme.palette.error.main,
        backgroundColor: "#fddedf",
      }
    },
    // sideBarLinks: {
    //   '& .MuiListItem-gutters': {
    //     paddingLeft: "50px !important",
    //   },
    //   '& .MuiButtonBase-root': {

    //     '& .MuiListItemIcon-root': {
    //      '& .MuiSvgIcon-root':{
    //        fill: '#6B778C',
    //        height:22,
    //        width: 22,
    //      },

    //     },
    //     '& .MuiListItemText-root': {
    //       '& .MuiTypography-root': {
    //         color: '#6B778C',
    //         fontSize: 16,
    //       },
    //     },

    //   },
    //   '& a.active': {
    //     textDecoration: 'none',
    //     backgroundColor: '#FFF8ED',
    //     position: 'relative',
    //     '& .MuiListItemIcon-root': {
    //       '& .MuiSvgIcon-root': {
    //         fill: ' #FAA819',
    //       },
    //     },
    //   },
    //   '& .active::before': {
    //     content: "''",
    //     width: 4,
    //     height: 45,
    //     position: 'absolute',
    //     left: 0,
    //     backgroundColor: '#FAA819',
    //     display: 'inline-block'
    //   },
    //   '& .MuiSvgIcon-root': {
    //     fill: '#C4C9D1',
    //     height: 20,

    //   },

    // },
    sideBarLinks: {
      padding: '0px',
      '& .MuiListItem-gutters': {
        paddingLeft: "50px !important",
      },
      '& .MuiButtonBase-root': {
        marginBottom: 4,

        '& .MuiListItemIcon-root': {
          minWidth: 38,
          '& .MuiSvgIcon-root': {
            fill: '#FF5100',
            height: 22,
            width: 22,
          },

        },
        '& .MuiListItemText-root': {
          '& .MuiTypography-root': {
            color: '#FF5100',
            fontSize: 16,
          },
        },

      },
      '& a.active': {
        textDecoration: 'none',
        backgroundColor: '#FFBE00 !important',
        position: 'relative',
        '& .MuiListItemIcon-root': {
          '& .MuiSvgIcon-root': {
            fill: ' #FAA819',
          },
        },
        '& .MuiListItemText-root': {
          '& .MuiTypography-root': {
            color: '#FAA819',
            fontWeight: 600,
          },
        }
      },
      '& .active::before': {
        content: "''",
        width: 4,
        height: 45,
        position: 'absolute',
        left: 0,
        backgroundColor: '#ffbe00',
        display: 'inline-block'
      },
      '& .MuiSvgIcon-root': {
        fill: '#C4C9D1',
        height: 20,

      },

    },

    generalLinks: {
      padding: '0px',
      '& .MuiBox-root': {
        margin: '50px 0px 18px 35px',
        color: '#6B778C !important',
        fontSize: 16,
        fontWeight: 600,
        textTransform: 'uppercase',
      }
    },
    logoBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 163,
      marginTop: 33,
      ...theme.mixins.toolbar,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      '& a': {
        // height: 21,
      },
    },

    // sideBarLinks:{
    //   '& .MuiListItem-root':{
    //     paddingTop: 5,
    //     paddingBottom: 6,
    //     paddingLeft: 12
    //   },
    //   '& .MuiListItemIcon-root':{
    //     minWidth: 42
    //   },
    //   '& a.active':{
    //     textDecoration: 'none',
    //     backgroundColor: 'rgba(0, 0, 0, 0.04)',
    //   },
    // },
    // generalLinks:{
    //   '& .MuiTypography-root':{
    //     fontSize: 14
    //   }
    // },
    // logoBox: {
    //   display: 'flex',
    //   alignItems: 'center',
    //   justifyContent: 'flex-start',
    //   minWidth: 163,
    //   ...theme.mixins.toolbar,
    //   transition: theme.transitions.create(['width', 'margin'], {
    //     easing: theme.transitions.easing.sharp,
    //     duration: theme.transitions.duration.enteringScreen,
    //   }),
    //   '& a':{
    //       // height: 21,
    //   },
    // },
    orgName: {
      color: '#6B778C !important',
      margin: '18px 0px 18px 27px',
      fontSize: 16,
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    roleWrap: {
      borderTop: '1px solid #d8d9db',
      borderBottom: '1px solid #d8d9db',
    },
    selectedRole: {
      textDecoration: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  }));


// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;