import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';
function pxToRem(value: any) {
    return `${value / 16}rem`;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            '&.MuiAvatar-root': {
                width: 32,
                height: 32,
                // border: '1px solid #d8d9db',
                borderRadius: '50%',
                marginRight: 11,
                [theme.breakpoints.down('xl')]: {
                    height: 25,
                    width: 25,
                },
            }
        },
        avatarDropdown: {
            '& ul': {
                padding: 0,
            },
            '& h6': {
                lineHeight: 'normal',
            },
        },
        // orgName:{
        //     whiteSpace: 'nowrap',
        //     color: '#3a3a3a',
        //     "& .MuiTypography-root":{
        //         // display:" block",
        //         fontSize:18,
        //         color: '#171B1E',
        //         fontWeight: 600,
        //         // height: 23,
        //         // lineHeight: 1,
        //         maxWidth: 145,
        //         overflow: 'hidden',
        //         textOverflow: 'ellipsis',
        //     }
        // },
        orgName: {
            whiteSpace: 'nowrap',
            color: '#3a3a3a',
        },
        orgShow: {
            // display:" block",
            fontSize: 18,
            color: 'rgb(58, 58, 58)',
            fontWeight: 400,
            // height: 23,
            // lineHeight: 1,
            maxWidth: 170,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontFamily: 'sans-serif',
        },
        orghidden: {
            // display:" block",
            fontSize: 18,
            color: 'rgb(58, 58, 58)',
            fontWeight: 400,
            // height: 23,
            // lineHeight: 1,
            maxWidth: 150,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontFamily: 'sans-serif',
            [theme.breakpoints.down('xl')]: {
                fontSize: pxToRem(12),
                maxWidth: 75
            },
            [theme.breakpoints.only('md')]: {
                maxWidth: 43
            }
        },
        drawerClose: {},

        orgNameDisable: {

        },
        btnWrapper: {
            border: '1px solid #DCDDDE',
            borderRadius: '30px !important',
            width: 240,
            maxWidth: 240,
            // height: 45,
            textAlign: 'left',
            justifyContent: 'flex-start',
            // paddingLeft: '7px !important',
            // paddingBottom: '1px !important',
            display: 'flex',
            alignItems: 'center',
            // marginTop: 13,
            // [theme.breakpoints.down('xl')]: {
            //     width: 144,
            // },
            "& .orgOpener": {
                // height: 41,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                padding: "8px 0px 8px 10px!important",
                borderRadius: "0!important",
                "&.MuiButton-root:hover": {
                    backgroundColor: 'transparent',
                },
                "&.singleOrg": {
                    cursor: "default",
                },
                "& .MuiButton-label": {
                    display: 'flex !important',
                    alignItems: 'center !important',
                }
            },
        },
        btnWrapperMobile: {
            border: '1px solid #DCDDDE',
            borderRadius: '30px !important',
            width: '100%',
            maxWidth: 240,
            // height: 45,
            textAlign: 'left',
            justifyContent: 'flex-start',
            // paddingLeft: '7px !important',
            // paddingBottom: '1px !important',
            display: 'flex',
            alignItems: 'center',
            marginTop: 13,
            // [theme.breakpoints.down('sm')]: {
            //     width: 144,
            // },
            "& .orgOpener": {
                // height: 41,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                padding: "8px 0px 8px 10px!important",
                borderRadius: "0!important",
                "&.MuiButton-root:hover": {
                    backgroundColor: 'transparent',
                },
                "&.singleOrg": {
                    cursor: "default",
                },
                "& .MuiButton-label": {
                    display: 'flex !important',
                    alignItems: 'center !important',
                }
            },
        },
        dropdownIcon: {
            color: "#6b778c",
            position: "absolute",
            // top: 8,
            right: 12,
        },
        projectType: {
            color: "#6b778c",
            lineHeight: 1,
            marginTop: 5,
        },
        viewAllLink: {
            color: theme.palette.secondary.main,
            lineHeight: 2,
            textDecoration: 'none',
        },
        subheader: {
            backgroundColor: theme.palette.background.paper,
            borderBottom: '1px solid #eee',
            padding: theme.spacing(2, 3),
            outline: 'none',
            '& div div div': {
                position: 'relative',
            },
        },
        topArrow: {
            position: 'absolute',
            bottom: '0',
            right: 1,
            width: 0,
            height: 0,
            zIndex: 9999999999,
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderBottom: '11px solid #f1e7e7',
        },
        listItem: {
            // maxWidth:350,
            // minWidth:350,
            borderBottom: '1px solid #eee',
            padding: 16,
            paddingLeft: 7,
            paddingRight: 15,
            '& .MuiAvatar-root': {
                width: 32,
                height: 32

            },
        },
        footer: {
            padding: '20px 24px',
            backgroundColor: '#fafafa',
            '& div': {
                width: '100%',
                textAlign: 'center',
                textTransform: 'uppercase',
            },
        },
        simpleNavLink: {
            color: "#3a3a3a",
            textDecoration: "none",
        },
        ownerBadge: {
            '&.MuiChip-root': {
                height: 24,
                fontSize: '12px',
                borderRadius: 2,
                textTransform: 'capitalize',
                cursor: 'pointer'
            }
        },
        orgTitle: {
            maxWidth: 100,
            minWidth: 140,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        drawerSm: {
            marginTop: 100,
            // '& .btnWrapper':{
            //     backgroundColor: 'green'
            // }
        },
        btnWrapperDrawer: {
            [theme.breakpoints.down('xl')]: {
                width: 144,
            },
            '& .MuiTypography-root': {
                [theme.breakpoints.down('xl')]: {
                    fontSize: pxToRem(14),
                    maxWidth: 82
                },
            }
        }

    }));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;