import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';

function pxToRem(value: any) {
    return `${value / 16}rem`;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sidebarWrap: {


            // [theme.breakpoints.up('xl')]: {
            //   paddingTop: 20,
            // },
            '& .MuiBox-root-65': {
                padding: '0px 0px',
                marginBottom: 8,
            },
            // "&.vendor":{
            //   paddingTop: 80,
            // }
        },
        // testTwo:{
        //   '& .generalLinks':{

        //   }
        // },


        roleWrap: {
            borderTop: '1px solid #d8d9db',
            borderBottom: '1px solid #d8d9db',
        },
        selectedRole: {
            textDecoration: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
        test: {},
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha(theme.palette.common.white, 0.15),
            border: '2px solid #cfd3db',
            width: '100%',
            marginTop: 2,
            minHeight: 35,
            '& input': {
                fontSize: 12
            },
            '&:hover': {
                borderColor: theme.palette.secondary.main,
            },
        },
        searchIcon: {
            position: 'absolute',
            right: 5,
            top: 5,
            pointerEvents: 'none',
            '& svg': {
                fontSize: '20px',
                color: '#6f7b8f',
            },
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: '8px 32px 9px 8px',
            transition: theme.transitions.create('width'),
            width: '100%',
        },
        subheader: {
            backgroundColor: theme.palette.background.paper,
            paddingRight: 0,
            paddingLeft: 0,
            marginBottom: 0,
            '& .logoSmall': {
                top: '-35px !important',

            },
        },
        listItem: {
            // maxWidth:350,
            // minWidth:350,
            padding: theme.spacing(1, 1),
            marginLeft: -8,
            "&:hover .MuiAvatar-root": {
                backgroundColor: theme.palette.secondary.main,
                color: "#fff",
            },
            "&:hover .MuiListItemText-root span": {
                color: theme.palette.secondary.main,
            },
        },
        listItemText: {
            margin: 0,
            width: 110,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            '& span': {
                fontSize: 14,
                textTransform: "capitalize",
            },
        },
        viewAllLink: {
            color: theme.palette.secondary.main,
            lineHeight: 2,
            textDecoration: 'none',
        },
        avatarWrap: {
            "& .MuiAvatar-root": {
                transition: "ease .3s",
                fontSize: 14,
                textTransform: 'uppercase',
                backgroundColor: "#e6edfd",
                color: "#2c2c2c",
            },
        },
        footer: {
            textAlign: 'center',
            padding: theme.spacing(2, 3),
            backgroundColor: '#fafafa',
        },
        createProjectBtn: {
            minWidth: 196,
        },

        // client sidebar
        projectAvatar: {
            width: "100%",
            height: "100%",
            // alignItems: "start",
            // backgroundColor: "transparent",
            "& .MuiAvatar-img": {
                height: "auto",
            }
        },
        workFlowBox: {
            cursor: 'pointer',
            "& .workFlowRow": {
                position: "relative",
            },

            "& .directionImgFirstCase": {
                width: 7,
                height: 8,
                position: "absolute",
                top: 4,
                left: "-20px",
                background: "url('/assets/images/work-flow-direction-single-case.png') no-repeat",
            },

            "& .directionImgMultipleCase": {
                width: 7,
                height: 30,
                position: "absolute",
                bottom: 4,
                left: "-20px",
                background: "url('/assets/images/work-flow-direction-multiple-case.png') no-repeat",
            },
        },
        projectSidebarFooter: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#fff',
            width: '16.5%',
            maxWidth: 349,
            padding: 15,
            position: 'fixed',
            boxShadow: '0px -1px 10px rgb(220 221 222 / 80%)',
            bottom: 0,
            // '@media (max-width:1280px)': {
            //   width: '15.5%',
            // },
            // '@media (min-width:1281px) and (max-width:1920px)': {
            //   width: '16.5%',
            //   maxWidth: 350,
            // },
            '& .footerAvatar': {
                width: 36,
                height: 36,
                '& .MuiBox-root': {
                    fontWeight: 'bold !important',
                },
            },

            '& >.MuiBox-root:nth-child(2n)': {
                [theme.breakpoints.down('xl')]: {
                    display: 'none',
                },
            }
        },
        userDropdown: {
            minWidth: 195,
            "& ul": {
                minWidth: 195,
                '& .MuiListItem-button': {
                    borderRadius: 4,
                    margin: theme.spacing(0, 1),
                }
            },
        },
        menuLink: {
            color: theme.palette.text.primary,
            textDecoration: 'none',
        },
        sidebarLink: {
            color: theme.palette.text.primary,
            textDecoration: 'none',

            // '& .MuiButtonBase-root .active': {
            //   backgroundColor: '#FFF8ED',
            // }

        },
        logoutLink: {
            "color": "#f44336",
            "&:hover": {
                color: theme.palette.error.main,
                backgroundColor: "#fddedf",
            }
        },

        sideBarLinks: {
            // '& .MuiListItem-gutters': {
            //   paddingLeft: "35px !important",
            // },
            '& .MuiButtonBase-root': {
                marginBottom: 4,

                [theme.breakpoints.between('md', 'xl')]: {
                    paddingLeft: 0,
                    whiteSpace: "break-spaces",
                },
                [theme.breakpoints.down('xl')]: {
                    paddingLeft: 0
                },
                [theme.breakpoints.down('xl')]: {
                    paddingTop: 4,
                    paddingBottom: 4,

                },
                '&.active ': {
                    backgroundColor: '#F6F6F6',
                    // '&:after': {
                    //     content: "'dd'",
                    //     width: 4,
                    //     height: 45,
                    //     position: 'absolute',
                    //     borderRightWidth: 0,
                    //     // backgroundColor: '#FAA819',
                    //     display: 'inline-block'
                    // }
                },





                '& .MuiListItemIcon-root': {
                    display: 'flex',
                    justifyContent: 'center',
                    [theme.breakpoints.down('xl')]: {
                        paddingLeft: 0,

                    },

                    // minWidth: 38,
                    '& .MuiSvgIcon-root': {
                        fill: '#FF5100',
                        height: 24,
                        width: 24,
                        // [theme.breakpoints.down('lg')]: {
                        //   height: 15,
                        //   width: 15
                        // },
                    },

                },
                '& .MuiListItemText-root': {
                    '& .MuiTypography-root': {
                        color: '#FF5100',
                        fontSize: 16,
                        fontFamily: 'Poppins',
                        // [theme.breakpoints.down('lg')]: {
                        //   fontSize: pxToRem(14)
                        // },

                        [theme.breakpoints.down('xl')]: {

                            whiteSpace: 'break-spaces',
                            fontSize: pxToRem(14)
                        },
                        [theme.breakpoints.down('xl')]: {

                            whiteSpace: 'break-spaces',
                            // fontSize: pxToRem(12)
                        }

                    },
                },

            },
            '& .active': {
                textDecoration: 'none',
                backgroundColor: '#FFF8ED',
                position: 'relative',
                '& .MuiListItemIcon-root': {
                    '& .MuiSvgIcon-root': {
                        fill: ' #FF5100 !important',
                    },
                },
                '& .MuiListItemText-root': {
                    '& .MuiTypography-root': {
                        color: '#FF5100 !important',
                        fontWeight: 600,
                        '&:before': {
                            // content: '"arrow_forward_ios"',
                            color: '#C4C9D1',
                            fontFamily: 'Material Icons',
                            width: 12,
                            height: 7,
                            position: 'absolute',
                            right: 0,
                            padding: '0px 45px',
                            display: 'inline-block',
                            fontWeight: 100,

                        }
                    },
                },
                '& svg': {
                    '& path': {
                        fill: "#FF5100 !important"
                    }

                }
            },
            '& .active::before': {
                content: "''",
                width: 4,
                height: '100%',
                position: 'absolute',
                left: 0,
                backgroundColor: '#FF5100',
                display: 'inline-block'
            },
            '& .MuiSvgIcon-root': {
                fill: '#C4C9D1',
                height: 20,

            },


        },

        generalLinks: {
            padding: '0px',
            '& .MuiBox-root': {
                margin: '15px 0px 18px 27px',
                color: '#6B778C !important',
                fontSize: 16,
                fontWeight: 600,
                textTransform: 'uppercase',
                [theme.breakpoints.down('xl')]: {

                    whiteSpace: 'break-spaces',
                    margin: '15px 0px 3px 27px',
                    fontSize: pxToRem(14)

                },

            },

        },
        orgName: {


        },
        drawerSm: {
            '& .btnWrapper': {
                backgroundColor: 'green'
            }
        },
        logoBox: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: 163,
            '& .logoFull': {
                // [theme.breakpoints.down('lg')]: {
                //     height: '75px !important',
                //     width: '95px !important'
                // },
                ...theme.mixins.toolbar,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),

                '& a': {
                    // height: 21,
                },

            },
        },

        footerDetails: {
            '& .MuiBox-root:nth-child(2n)': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whitespace: 'nowrap',
            }

        },
        hiddenMenu: {
            // [theme.breakpoints.down('sm')]: {
            //   display: 'none'
            // }
        },

        sidebarAvatar: {

            [theme.breakpoints.down('xl')]: {
                // fontSize: pxToRem(14)
            },
            [theme.breakpoints.down('xl')]: {

                // fontSize: pxToRem(12)
            },
            fontSize: '14px',

            fontFamily: "sans-serif",
            color: "#3a3a3a",
            fontWeight: 700,
            textTransform: 'capitalize',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whitespace: 'nowrap',

        },
        customerLogo: {
            width: 240,
            // border: '1px solid #DCDDDE',
            display: 'flex',
            maxwidth: 240,
            textAlign: 'left',
            alignItems: 'center',
            borderRadius: '30px!important',
            padding: 0,
            '& .MuiAvatar-root': {
                height: 32,
                width: 32,
            },
        },
        orgShow: {
            // display:" block",
            fontSize: 18,
            color: 'rgb(58, 58, 58)',
            fontWeight: 400,
            // height: 23,
            // lineHeight: 1,
            maxWidth: 170,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontFamily: 'sans-serif',
        },

    }));


// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;