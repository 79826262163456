import React, { useEffect, useState } from 'react';
import { gql } from "apollo-boost";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useDispatch, useSelector } from "react-redux";
import { EmptyContent } from 'src/app/components';
import {
    MAvatar,
    MBox,
    MButton,
    MCircularProgress,
    MIconButton,
    MForm,
    MFormik,
    MFormLabel,
    MGrid,
    MSkeleton,
    MTextField,
    MTypography,
    MRadio,
    MPagination,
    MLink, MHidden
} from 'src/app/material-ui';

import useStyles from './InvitedVendorStyle';
import { showAlert } from "src/app/store";
import { FormattedDate } from 'react-intl';
import { MScheduleIcon, MSendClock } from "src/app/material-ui/icons";
import * as Yup from 'yup';
import AddIcon from '@mui/icons-material/Add';
import { FormattedMessage } from "react-intl";

import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
interface InvitedVendorProps {
    setInvitedSuppliers?: ((value: boolean) => void);
    invitedSuppliers?: boolean;
}

const GET_INVITED_CLIENT_VENDOR = gql`
  query getClientInvitedVendors($orgId: Int!,$page: Int,$emailOrResourceId:String){
    getClientInvitedVendors(orgId: $orgId,page:$page,emailOrResourceId:$emailOrResourceId){
        InvitedVendor {
            id
            email
            resourceId
            status
            createdAt
            role
            isDisabled
        }
        total
        
    }
  }
`;

const INVITE_VENDOR = gql`
  mutation inviteVendor($email:String,$orgId:Int,$role:String,$reSend:Int,$userId:Int,$resourceId:String){
    inviteVendor(email:$email,orgId:$orgId,role:$role,reSend:$reSend,userId:$userId,resourceId:$resourceId) 
  }
`;

export default function InvitedVendor({ setInvitedSuppliers, invitedSuppliers }: InvitedVendorProps) {

    const classes = useStyles({});
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages]: any = useState(0);
    const selectedOrgId = useSelector((store: any) => store.orgs.selectedOrgId);
    const storeOrgs = useSelector((store: any) => store.orgs.organizations);
    const isOwner = useSelector((store: any) => store.orgs.isOwner);
    const isAdmin = useSelector((store: any) => store.orgs.isAdmin);
    const inviteUserErrors = '';
    const [invitedClientVendorData, setInvitedClientVendorData] = useState([]);
    const currentUser = useSelector((store: any) => store.auth.currentUser);

    const [getInvitedClientVendors, { loading: invitedClientVendorsLoading }] = useLazyQuery(GET_INVITED_CLIENT_VENDOR, {
        variables: { orgId: selectedOrgId, page: page, emailOrResourceId: '' },
        fetchPolicy: 'no-cache',
        onCompleted: (data: any) => {
            setInvitedClientVendorData(data && data.getClientInvitedVendors.InvitedVendor)
            setTotalPages(Math.ceil(data && data.getClientInvitedVendors.total / 10));
            // setInvitedSuppliers(true)


        }, onError: ({ graphQLErrors }: any) => {


            dispatch(showAlert({
                message: graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message && graphQLErrors[0].message,
                messageType: 'error',
                showAlertMessage: true

            })

            );
        }
    });

    const checkIsMT = () => {
        let isMT = 0;
        const orgObj = storeOrgs.find((it: any) => it.id == selectedOrgId);

        if (orgObj && orgObj.vendorProfile && orgObj.vendorProfile.vendor_is_mt) {
            isMT = 1;
        }

        return isMT;
    }

    const handlePageChange = (event: any, value: any) => {
        setPage(value);
        event?.preventDefault();
    };
    useEffect(() => {
        if (selectedOrgId) {
            getInvitedClientVendors();

        }
    }, [getInvitedClientVendors, selectedOrgId,page]);

    useEffect(() => {
        if (currentUser && checkIsMT() == 1) {
            setRole('linguist');
        }
    }, [currentUser, storeOrgs]);

    const [inviteVendor, { loading: inviteVendorloading }] = useMutation(INVITE_VENDOR, {
        onCompleted: (data: any) => {
            getInvitedClientVendors();
            //it will update the list of invited suppliers
            if (typeof setInvitedSuppliers != "undefined" && setInvitedSuppliers && invitedSuppliers === false) {
                setInvitedSuppliers(true)
            }

            dispatch(showAlert({
                message: data.inviteVendor,
                messageType: 'success',
                showAlertMessage: true
            }))
        }, onError: ({ graphQLErrors }: any) => {

            dispatch(showAlert({
                message: graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message && graphQLErrors[0].message,
                messageType: 'error',
                showAlertMessage: true
            }));
        }
    });
    const resendInvitedEmail = (data: any) => {
        inviteVendor({
            variables: {
                orgId: selectedOrgId,
                email: data && data.email,
                role: data && data.role,
                userId: data && data.userId,
                reSend: data && data.reSend
            }
        });
    }
    const trimValue = (setFieldValue: any, value: any, fieldName: any) => {
        const str = value.trim().replace(/\s+/g, " ");
        setFieldValue(fieldName, str);
    }
    //radio buttons
    const [role, setRole] = React.useState('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRole((event.target as HTMLInputElement).value);
    };
    const validationSchema = Yup.object().shape({
        vendorEmail: Yup.string()
            .email('Invalid email format')
            .matches(/^[A-Z0-9._+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Invalid email format'),
        resourceId: Yup.string(),
    }).test('at-least-one', 'Either Email or Resource ID is required', function (values) {
        return values.vendorEmail || values.resourceId;
    });
    return (
        <MBox pt={1}>
            {/* <MGrid container> */}
            <MBox>
                <MBox>
                    <FormattedMessage id="email.must.valid">
                        {() => (
                            <FormattedMessage id="valid.email">
                                {() => (
                                    <MFormik
                                        validateOnChange
                                        initialValues={{
                                            vendorEmail: "",
                                            resourceId: ""
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={(values: any, resetForm: any) => {
                                            inviteVendor({
                                                variables: {
                                                    orgId: selectedOrgId,
                                                    email: values.vendorEmail,
                                                    role: role && role,
                                                    resourceId: values.resourceId
                                                }
                                            });
                                            resetForm();
                                        }}
                                    >
                                        {({ setFieldValue, values, touched, errors }: any) => (
                                            <MForm>
                                                <MBox display="flex" alignItems="center" className={classes.formInvite}>
                                                    <MBox mb={3}>
                                                        {currentUser && checkIsMT() == 1 && role == 'vendor' ? (
                                                            <>
                                                                <MBox mb={1}>

                                                                    <MFormLabel className={classes.formLabel} component="legend" required={!(currentUser && checkIsMT() === 1)}>
                                                                        <FormattedMessage id="enter.suppliers.email" />
                                                                    </MFormLabel>
                                                                </MBox>

                                                                <MBox onBlur={() => trimValue(setFieldValue, values.vendorEmail, 'vendorEmail')}>
                                                                    <FormattedMessage id="enter.suppliers.email">
                                                                        {vendorEmail => (
                                                                            <MTextField
                                                                                name="vendorEmail"
                                                                                variant="outlined"
                                                                                color="secondary"
                                                                                size="small"
                                                                                value={values.vendorEmail}
                                                                                className={classes.inputWidth}
                                                                                placeholder={vendorEmail}
                                                                                disabled={(isOwner || isAdmin) ? false : true}
                                                                                error={touched.vendorEmail && !!errors.vendorEmail}
                                                                                helperText={touched.vendorEmail && errors.vendorEmail}
                                                                            />
                                                                        )}
                                                                    </FormattedMessage>
                                                                </MBox>
                                                            </>
                                                        ) : <>

                                                            {
                                                                checkIsMT() == 0 &&

                                                                <>
                                                                    <MBox mb={1}>

                                                                        <MFormLabel className={classes.formLabel} component="legend" required={!(currentUser && checkIsMT() === 1 && role === 'linguist')}>
                                                                            <FormattedMessage id="enter.suppliers.email" />
                                                                        </MFormLabel>
                                                                    </MBox>

                                                                    <MBox onBlur={() => trimValue(setFieldValue, values.vendorEmail, 'vendorEmail')}>
                                                                        <FormattedMessage id="enter.suppliers.email">
                                                                            {vendorEmail => (
                                                                                <MTextField
                                                                                    name="vendorEmail"
                                                                                    variant="outlined"
                                                                                    color="secondary"
                                                                                    size="small"
                                                                                    value={values.vendorEmail}
                                                                                    className={classes.inputWidth}
                                                                                    placeholder={vendorEmail}
                                                                                    disabled={(isOwner || isAdmin) ? false : true}
                                                                                    error={touched.vendorEmail && !!errors.vendorEmail}
                                                                                    helperText={touched.vendorEmail && errors.vendorEmail}
                                                                                />
                                                                            )}
                                                                        </FormattedMessage>
                                                                    </MBox>
                                                                </>
                                                            }

                                                        </>
                                                        }
                                                        {currentUser && checkIsMT() === 1 && role === 'linguist' && (
                                                            <>

                                                                <MBox mb={1}>
                                                                    <MFormLabel className={classes.formLabel} component="legend">
                                                                        <FormattedMessage id="enter.resource.id" />
                                                                    </MFormLabel>
                                                                </MBox>
                                                                <MBox onBlur={() => trimValue(setFieldValue, values.resourceId, 'resourceId')}>
                                                                    <FormattedMessage id="enter.resource.id">
                                                                        {resourceId => (
                                                                            <MTextField
                                                                                name="resourceId"
                                                                                variant="outlined"
                                                                                color="secondary"
                                                                                size="small"
                                                                                value={values.resourceId}
                                                                                className={classes.inputWidth}
                                                                                placeholder={resourceId}
                                                                                disabled={(isOwner || isAdmin) ? false : true}
                                                                                error={touched.resourceId && !!errors.resourceId}
                                                                                helperText={touched.resourceId && errors.resourceId}
                                                                            />
                                                                        )}
                                                                    </FormattedMessage>
                                                                </MBox>
                                                            </>
                                                        )}
                                                        <MBox className={classes.inviteError}>
                                                            <div dangerouslySetInnerHTML={{ __html: inviteUserErrors && inviteUserErrors }} />
                                                        </MBox>
                                                    </MBox>
                                                    <MBox mx={2}>
                                                        <MBox mb={1}>
                                                            <MFormLabel className={classes.formLabel} component="legend">
                                                                <FormattedMessage id="please.choose.supplier" />
                                                            </MFormLabel>
                                                        </MBox>
                                                        <MBox display='flex'>
                                                            <MBox className={classes.radioGroup}>
                                                                <FormControl component="fieldset">
                                                                    <RadioGroup aria-label="role" name="role" value={role} onChange={handleChange}>
                                                                        <MBox>
                                                                            <MRadio value="linguist" color="secondary" disabled={(role === 'linguist' && inviteVendorloading) && true} />
                                                                            <FormattedMessage id="linguist.side" />
                                                                        </MBox>
                                                                        <MBox>
                                                                            <MRadio value="vendor" color="secondary" disabled={(role === 'vendor' && inviteVendorloading) && true} />
                                                                            <FormattedMessage id="vendor" />
                                                                        </MBox>
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </MBox>
                                                        </MBox>
                                                    </MBox>
                                                    <MBox>
                                                        <MButton
                                                            className="btnSmall"
                                                            color="primary"
                                                            variant="contained"
                                                            type="submit"
                                                            disabled={inviteVendorloading || (values.vendorEmail === "" && values.resourceId === "") || role === ""}
                                                        >
                                                            {inviteVendorloading && <MCircularProgress color="secondary" size={24} />}
                                                            <AddIcon className={classes.addIconStyle} />
                                                            <FormattedMessage id="invite" />
                                                        </MButton>
                                                    </MBox>
                                                </MBox>
                                            </MForm>
                                        )}
                                    </MFormik>
                                )}
                            </FormattedMessage>
                        )}
                    </FormattedMessage>
                </MBox>
            </MBox>

            <MBox mb={2}>
                <MFormik

                    enableReinitialize="true"
                    initialValues={{
                        emailOrResourceId: '',
                    }}
                    onSubmit={(values: any) => {

                        if (values.emailOrResourceId !== '') {
                            getInvitedClientVendors({
                                variables: {
                                    orgId: selectedOrgId, page: 1,
                                    emailOrResourceId: values.emailOrResourceId
                                }
                            });
                        }

                    }}>
                    {({ setFieldValue, values, resetForm }: any) => (
                        <MForm>
                            <MBox maxWidth={755} display="flex">
                                <MBox maxWidth={300} mr={1}>
                                    <FormControl
                                        className={classes.seachInput}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    >
                                        {currentUser && checkIsMT() == 1 ?
                                            <InputLabel htmlFor="searchInput">
                                                <FormattedMessage id="resource.id" />
                                            </InputLabel> :
                                            <InputLabel htmlFor="searchInput">
                                                <FormattedMessage id="email" />
                                            </InputLabel>
                                        }
                                        <OutlinedInput
                                            id="searchInput"
                                            value={values.emailOrResourceId}
                                            onChange={(e: any) => {
                                                setFieldValue('emailOrResourceId', e.target.value)
                                            }}
                                        />
                                    </FormControl>
                                </MBox>
                                <MBox display="flex">
                                    <MButton
                                        className="btnSmall"
                                        color="primary"
                                        // disabled={jobListingLoading}
                                        variant="contained"
                                        type="submit"
                                    // sx={{ height: 32 }}
                                    >
                                        <FormattedMessage id="search" />
                                        {/*{tbLoading &&*/}
                                        {/*<MCircularProgress color="secondary" size={24} />*/}
                                        {/*}*/}
                                    </MButton>

                                    {/*{(values.emailOrResourceId) &&*/}
                                    <MButton color="secondary"
                                        onClick={function () {
                                            resetForm();
                                            setFieldValue('emailOrResourceId', '')
                                            getInvitedClientVendors({
                                                variables: {
                                                    orgId: selectedOrgId, page: page, emailOrResourceId: ''
                                                }
                                            });
                                        }}>

                                        <FormattedMessage id="clear.all" />
                                    </MButton>
                                    {/*}*/}
                                </MBox>
                            </MBox>
                        </MForm>
                    )}
                </MFormik>
            </MBox>
            <MGrid item xs={12}>
                <MBox>
                    <MBox>
                        {invitedClientVendorData && (invitedClientVendorData.length < 1) && !invitedClientVendorsLoading &&
                            <MBox sx={{ marginTop: '10%' }}>
                                <EmptyContent />
                            </MBox>
                        }
                        {!invitedClientVendorsLoading && invitedClientVendorData && invitedClientVendorData.map((clientVendor: any) => (
                            <>
                                {clientVendor.email !== "" &&
                                    <>
                                        <MHidden mdUp>
                                            <MBox mb={1} className={classes.uploadedFilesList} py={1} pl={1} borderRadius={4} border={1} borderColor="#f4f5f7">
                                                <MBox>
                                                    <MBox flex="3 1 0">
                                                        <MBox display="flex" justifyContent="flex-start" alignItems="center" minWidth={0}>
                                                            <MBox>
                                                                <MAvatar>
                                                                    <img src="/assets/images/avatar.jpg" alt="logo" width="40px" />
                                                                </MAvatar>
                                                            </MBox>
                                                            <MBox item sx={{ overflow: 'hidden' }} >
                                                                <MBox ml={3}>
                                                                    <MBox fontFamily="Poppins" textAlign="left" fontSize={14}
                                                                        color={'#3a3a3a'} className={classes.textOverflow}>
                                                                        {clientVendor?.resourceId ? clientVendor.resourceId : clientVendor.email}
                                                                    </MBox>
                                                                </MBox>
                                                            </MBox>

                                                        </MBox>
                                                    </MBox>
                                                </MBox>
                                                <MBox display="flex" justifyContent="space-between" alignItems="center" my={1}>
                                                    <MBox flex="1 1 0">
                                                        <MBox display="flex">
                                                            <MBox pr={2}>
                                                                {clientVendor.status === 4 &&
                                                                    <MBox className={`${classes.status} profilePending`}>
                                                                        <MBox >
                                                                            <FormattedMessage id="profile.pending" />
                                                                        </MBox>
                                                                    </MBox>
                                                                }
                                                                {clientVendor.status === 2 &&
                                                                    <MBox className={`${classes.status} rejected`}>
                                                                        <MBox >
                                                                            Invalid
                                                                        </MBox>
                                                                    </MBox>
                                                                }
                                                                {clientVendor.status === 3 &&
                                                                    <MBox className={`${classes.status} profilePending`}>
                                                                        <MBox >
                                                                            <FormattedMessage id="profile.pending" />
                                                                        </MBox>
                                                                    </MBox>
                                                                } {clientVendor.status === 0 &&
                                                                    <MBox className={`${classes.status} pending`}>
                                                                        <MBox >
                                                                            <FormattedMessage id="pending" />
                                                                        </MBox>
                                                                    </MBox>
                                                                }
                                                                {clientVendor.status === 5 &&
                                                                    <MBox className={`${classes.status} registered`}>
                                                                        <MBox >
                                                                            <FormattedMessage id="registered" />
                                                                        </MBox>
                                                                    </MBox>
                                                                }
                                                            </MBox>
                                                        </MBox>
                                                    </MBox>
                                                    <MBox flex="1 1 0">
                                                        <MBox display="flex" justifyContent="flex-end">
                                                            <MBox pr={2}>
                                                                {clientVendor.createdAt !== null
                                                                    ?
                                                                    <MBox>

                                                                        <MBox fontFamily="Poppins" textAlign="left" className={classes.assignDate}
                                                                            fontSize={14} color={'#3a3a3a'}>
                                                                            <FormattedDate value={clientVendor.createdAt} day="2-digit"
                                                                                month="long" year="numeric" />
                                                                        </MBox>

                                                                    </MBox>
                                                                    :
                                                                    ''
                                                                }
                                                            </MBox>
                                                        </MBox>
                                                    </MBox>
                                                    <MBox flex="1 1 0">

                                                        {!clientVendor.isDisabled == true && clientVendor && clientVendor.status === 0 &&

                                                            <MBox display="flex" justifyContent="flex-end">
                                                                <MBox pr={1}>
                                                                    <MLink underline="none" onClick={() => {
                                                                        const data: any = {
                                                                            reSend: 1,
                                                                            email: clientVendor.email,
                                                                            userId: clientVendor.id,
                                                                            role: clientVendor && clientVendor.role,
                                                                        };
                                                                        resendInvitedEmail(data)
                                                                    }}>
                                                                        <MButton className={classes.sendClock}
                                                                            color="primary"
                                                                            disabled={inviteVendorloading}
                                                                            variant="outlined"
                                                                            sx={{ height: 34, border: '1px solid #ff6b00' }}

                                                                        >
                                                                            <MIconButton>
                                                                                <MSendClock />
                                                                            </MIconButton>
                                                                            <FormattedMessage id="resend" />
                                                                        </MButton>
                                                                    </MLink>
                                                                </MBox>
                                                            </MBox>
                                                        }
                                                    </MBox>


                                                </MBox>

                                            </MBox>
                                        </MHidden>

                                        <MHidden smDown>

                                            <MBox mb={1} className={classes.uploadedFilesList} px={2} py={2} borderRadius={4} border={1} borderColor="#f4f5f7">
                                                <MGrid container direction="row" justifyContent="space-between" alignItems="center">
                                                    <MGrid item xs={12} sm={5}>
                                                        <MBox display="flex" justifyContent="flex-start" alignItems="center" minWidth={0}>
                                                            <MGrid item xs={1} lg={1} xl={1}>
                                                                <MAvatar>
                                                                    <img src="/assets/images/avatar.jpg" alt="logo" width="40px" />
                                                                </MAvatar>
                                                            </MGrid>
                                                            <MGrid item sx={{ overflow: 'hidden' }} >
                                                                <MBox ml={3}>
                                                                    <MBox fontFamily="Poppins" textAlign="left" fontSize={14}
                                                                        color={'#3a3a3a'} className={classes.textOverflow}>
                                                                        {/*{clientVendor.email}*/}
                                                                        {clientVendor?.resourceId ? clientVendor.resourceId : clientVendor.email}

                                                                    </MBox>
                                                                </MBox>
                                                            </MGrid>

                                                        </MBox>
                                                    </MGrid>

                                                    <MGrid item xs={2} sm={2}>
                                                        <MBox display="flex">
                                                            <MBox pr={2}>
                                                                {clientVendor.status === 4 &&
                                                                    <MBox className={`${classes.status} profilePending`}>
                                                                        <MBox >
                                                                            <FormattedMessage id="profile.pending" />
                                                                        </MBox>
                                                                    </MBox>
                                                                }
                                                                {clientVendor.status === 2 &&
                                                                    <MBox className={`${classes.status} rejected`}>
                                                                        <MBox >
                                                                            Invalid
                                                                        </MBox>
                                                                    </MBox>
                                                                }
                                                                {clientVendor.status === 3 &&
                                                                    <MBox className={`${classes.status} profilePending`}>
                                                                        <MBox >
                                                                            <FormattedMessage id="profile.pending" />
                                                                        </MBox>
                                                                    </MBox>
                                                                } {clientVendor.status === 0 &&
                                                                    <MBox className={`${classes.status} pending`}>
                                                                        <MBox >
                                                                            <FormattedMessage id="pending" />
                                                                        </MBox>
                                                                    </MBox>
                                                                }
                                                                {clientVendor.status === 5 &&
                                                                    <MBox className={`${classes.status} registered`}>
                                                                        <MBox >
                                                                            <FormattedMessage id="registered" />
                                                                        </MBox>
                                                                    </MBox>
                                                                }
                                                            </MBox>
                                                        </MBox>
                                                    </MGrid>
                                                    <MGrid item xs={12} sm={3}>
                                                        <MBox display="flex" justifyContent="center">
                                                            <MBox pr={2}>
                                                                {clientVendor.createdAt !== null
                                                                    ?
                                                                    <MBox>
                                                                        <MTypography className={classes.sourceText} component="span"
                                                                            variant="caption">
                                                                            <MScheduleIcon className="fa fa-plus-circle"
                                                                                fontSize="inherit" />
                                                                            <span>
                                                                                <FormattedMessage id="invited.at" />
                                                                            </span>
                                                                        </MTypography>
                                                                        <MBox fontFamily="Poppins" textAlign="left"
                                                                            fontSize={14} color={'#3a3a3a'}>
                                                                            <FormattedDate value={clientVendor.createdAt} day="2-digit"
                                                                                month="long" year="numeric" />
                                                                        </MBox>

                                                                    </MBox>
                                                                    :
                                                                    ''
                                                                }
                                                            </MBox>
                                                        </MBox>
                                                    </MGrid>

                                                    <MGrid item xs={12} sm={2}>

                                                        {clientVendor && clientVendor.status === 0 &&

                                                            <MBox display="flex" justifyContent="flex-end">
                                                                <MBox pr={1}>
                                                                    <MLink underline="none" onClick={() => {
                                                                        const data: any = {
                                                                            reSend: 1,
                                                                            email: clientVendor.email,
                                                                            userId: clientVendor.id,
                                                                            role: clientVendor && clientVendor.role,
                                                                        };
                                                                        resendInvitedEmail(data)
                                                                    }}>
                                                                        <MButton className={classes.sendClock}
                                                                            color="primary"
                                                                            disabled={inviteVendorloading}
                                                                            variant="outlined"
                                                                            sx={{ height: 34, border: '1px solid #ff6b00' }}

                                                                        >
                                                                            <MIconButton>
                                                                                <MSendClock />
                                                                            </MIconButton>
                                                                            <FormattedMessage id="resend" />
                                                                        </MButton>
                                                                    </MLink>
                                                                </MBox>
                                                            </MBox>
                                                        }
                                                    </MGrid>
                                                </MGrid>

                                            </MBox>
                                        </MHidden>
                                    </>
                                }
                            </>
                        ))}
                        {invitedClientVendorsLoading &&
                            [0, 1, 2].map(() => (
                                <>
                                    <MHidden mdUp>
                                        <MBox mb={1} className={classes.uploadedFilesList} py={1} pl={1} borderRadius={4} border={1} borderColor="#f4f5f7">
                                            <MBox>
                                                <MBox flex="3 1 0">
                                                    <MBox display="flex" justifyContent="flex-start" alignItems="center" minWidth={0}>
                                                        <MBox>
                                                            <MSkeleton variant="circular" height={25} width={25} />
                                                        </MBox>
                                                        <MBox item sx={{ overflow: 'hidden' }} >
                                                            <MBox ml={3}>
                                                                <MBox fontFamily="Poppins" textAlign="left" fontSize={14}
                                                                    color={'#3a3a3a'} className={classes.textOverflow}>
                                                                    <MSkeleton variant="text" height={25} width='55vw' />
                                                                </MBox>
                                                            </MBox>
                                                        </MBox>

                                                    </MBox>
                                                </MBox>
                                            </MBox>
                                            <MBox display="flex" justifyContent="space-between" alignItems="center" my={1}>
                                                <MBox flex="1 1 0">
                                                    <MBox display="flex">
                                                        <MBox pr={2}>

                                                            <MSkeleton className={`${classes.status} profilePending`}>
                                                                <MBox >
                                                                    <MSkeleton height={25} width='20vw' />
                                                                </MBox>
                                                            </MSkeleton>

                                                        </MBox>
                                                    </MBox>
                                                </MBox>
                                                <MBox flex="1 1 0">
                                                    <MBox display="flex" justifyContent="flex-end">
                                                        <MBox pr={2}>
                                                            <MBox>
                                                                <MBox fontFamily="Poppins" textAlign="left" className={classes.assignDate}
                                                                    fontSize={14} color={'#3a3a3a'}>
                                                                    <MSkeleton height={25} width='20vw' />
                                                                </MBox>

                                                            </MBox>

                                                        </MBox>
                                                    </MBox>
                                                </MBox>
                                                <MBox flex="1 1 0">



                                                    <MBox display="flex" justifyContent="flex-end">
                                                        <MBox pr={1}>
                                                            <MSkeleton height={25} width='20vw' />
                                                        </MBox>
                                                    </MBox>

                                                </MBox>


                                            </MBox>

                                        </MBox>
                                    </MHidden>
                                    <MHidden smDown>
                                        <MBox mb={1} className={classes.uploadedFilesList} px={2} py={2} borderRadius={4} border={1} borderColor="#f4f5f7">
                                            <MGrid container direction="row" justifyContent="space-between" alignItems="center">
                                                <MGrid item xs={12} sm={5}>
                                                    <MBox ml={1} display="flex" justifyContent="flex-start" alignItems="center">
                                                        <MGrid item xs={1} lg={1} xl={1}>
                                                            <MAvatar>
                                                                <img src="/assets/images/avatar.jpg" alt="logo" width="40px" />
                                                            </MAvatar>
                                                        </MGrid>
                                                        <MGrid item>
                                                            <MBox ml={3}>
                                                                <MBox fontFamily="Poppins" textAlign="left" fontSize={14}
                                                                    color={'#3a3a3a'}>
                                                                    <MSkeleton height={19} width={160} />
                                                                </MBox>
                                                            </MBox>
                                                        </MGrid>

                                                    </MBox>
                                                </MGrid>
                                                <MGrid item xs={12} sm={2}>
                                                    <MBox display="flex">
                                                        <MBox> <MSkeleton variant="text" height={19} width={50} /></MBox>
                                                    </MBox>
                                                </MGrid>
                                                <MGrid item xs={12} sm={3}>
                                                    <MBox display="flex" justifyContent="flex-end">
                                                        <MBox pr={2}>
                                                            <MBox>
                                                                <MTypography className={classes.sourceText} component="span" variant="caption">
                                                                    <MScheduleIcon className="fa fa-plus-circle" fontSize="inherit" />
                                                                    <span>
                                                                        <FormattedMessage id="invited.at" />
                                                                    </span>
                                                                </MTypography>
                                                                <MBox mt={.5}>
                                                                    <MSkeleton height={19} width={50} />
                                                                </MBox>
                                                            </MBox>
                                                        </MBox>
                                                    </MBox>
                                                </MGrid>
                                                <MGrid item xs={12} sm={2}>

                                                </MGrid>
                                            </MGrid>
                                        </MBox>
                                    </MHidden>
                                </>
                            ))}
                    </MBox>
                    {totalPages > 1
                        ?
                        <MBox mt={3} display="flex" justifyContent="flex-end">
                            <MPagination count={totalPages} page={page && page}
                                onChange={handlePageChange} />
                        </MBox>
                        :
                        " "
                    }
                </MBox>
            </MGrid>
            {/* </MGrid> */}
        </MBox>
    );
}
