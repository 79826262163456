/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { MBox, MDrawer, MFormLabel, MIconButton, MSkeleton } from 'src/app/material-ui';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { FormattedMessage } from 'react-intl';
import useStyles from './PaymentDrawerStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { PayPalButton } from "@repeatgg/react-paypal-button-v2";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";

import { gql } from "apollo-boost";
import clsx from 'clsx';
import { MBankTransfer, MCreditCard, MPaypalIcon, MPaypalText, } from 'src/app/material-ui/icons';
import CreditCard from 'src/app/screens/vendor/job-detail/paymentComponent/CreditCard';
import Credit from 'src/app/screens/vendor/job-detail/paymentComponent/Credits';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import { showAlert } from "../../../store";
import { useDispatch } from "react-redux";
import BankTransfer from "../../vendor/job-detail/paymentComponent/BankTransfer";

const GET_ALL_GATEWAYS = gql`
    query getActivePaymentGateways($amt:String,$invoiceId:Int,$orgId:Int)  {
        getActivePaymentGateways(amt: $amt,invoiceId:$invoiceId,orgId:$orgId) {
            code
            name
            clientId
            fee
        }
    }
`;

const GET_ALL_ORG_PAYMENT_CARDS = gql`
    query getOrgPaymentCards($orgId:Int) {
        getOrgPaymentCards(orgId:$orgId) {
            id
            number
            type
            expiryMonth
            isDefault
            expiryYear
            createdAt
            updatedAt
        }
    }
`;

const ADD_FUNDS = gql`
    mutation addFunds($isNewCard: Int, $orgId: ID, $amt: Float, $paymentInformation: String,$cardId : Int,$transactionDetails: String) {
        addFunds(isNewCard: $isNewCard, orgId: $orgId, amt: $amt, paymentInformation: $paymentInformation, cardId: $cardId, transactionDetails: $transactionDetails)
    }
`;

export default function AddFunds({ getAllGateways, getClientTransaction, addFundsDrawer, setAddFundsDrawer, walletBalance, setPaymentMethod, paymentMethod, setRefreshProjects, refreshProjects }: any) {
    const classes: any = useStyles();
    // const [paymentMethod, setPaymentMethod]: any = useState('');
    const [allGateways, setAllGateways]: any = useState([]);
    const [allPaymentCards, setAllPaymentCards]: any = useState([]);
    const [defaultCard, setDefaultCard]: any = useState({});
    const [selectedCard, setSelectedCard]: any = useState(null);
    const [paypalFee, setPaypalFee]: any = useState(null);
    const [paypalClientId, setPaypalClientId]: any = useState(null);
    const selectedOrg = window.localStorage.getItem('selectedOrgId');
    const [selectedGateway, setSelectedGateway]: any = useState({});
    const [fundsAmt, setFundsAmt]: any = useState(0);
    const [isNewCard, setIsNewCard]: any = useState(0);
    const [onlineTransactionDetails, setOnlineTransactionDetails]: any = useState({});
    const [loadingPaypalBtn, setLoadingPaypalBtn]: any = useState(false);
    const [mhWallet, setMhWallet]: any = useState(false);

    //to check for input field(enter amount) validation

    const [validationAmount, setValidationAmount]: any = useState(true);


    const type = 'mhWallet'
    const dispatch = useDispatch();

    //function to handle login for amount validation

    const handlePaymentValidation = () => {
        setValidationAmount(false)
    }

    const handlePaymentMethod = (method: string) => {
        setPaymentMethod(method);
        if (method === 'bank_transfer') {
            setMhWallet(true)
        }
    };

    const [addFunds, { }] = useMutation(ADD_FUNDS, {
        fetchPolicy: 'no-cache',
        onCompleted: () => {
            dispatch(showAlert({
                message: 'Funds added successfully',
                messageType: 'success',
                showAlertMessage: true
            }));


            getClientTransaction();
            setAddFundsDrawer(false);
            setFundsAmt(0);

            if (getAllGateways) {

                getAllGateways();
            }

        }
    });


    const [getAllGatewaysC] = useLazyQuery(GET_ALL_GATEWAYS, {
        variables: { amt: String(fundsAmt), orgId: Number(selectedOrg) },
        fetchPolicy: 'no-cache',
        onCompleted: (data: any) => {
            setAllGateways(data.getActivePaymentGateways);
            setPaypalFee(data.getActivePaymentGateways.find((item: any) => item.code == 'paypal').fee);
            setPaypalClientId(data.getActivePaymentGateways.find((item: any) => item.code == 'paypal').clientId);
            // setStripePromise(loadStripe(data.getActivePaymentGateways.find((item: any) => item.code == 'stripe').clientId));
        }
    });

    const [getAllOrgPaymentCards, { }] = useLazyQuery(GET_ALL_ORG_PAYMENT_CARDS, {
        variables: { orgId: Number(selectedOrg) },
        fetchPolicy: 'no-cache',
        onCompleted: (data: any) => {
            setAllPaymentCards(data.getOrgPaymentCards);
            setDefaultCard(data.getOrgPaymentCards.find((item: any) => item.isDefault == 1));
        }
    });


    const dialogCloseYes = () => {

        addFunds({
            variables: {
                transactionDetails: onlineTransactionDetails && JSON.stringify(onlineTransactionDetails),
                cardId: JSON.stringify(onlineTransactionDetails) == "{}" ? (selectedCard && selectedCard.id) : 0,
                amt: parseFloat(fundsAmt),
                orgId: selectedOrg,
                isNewCard: isNewCard,
                paymentInformation: ''
            }
        });

    };
    const setPaypalBtn = () => {
        setLoadingPaypalBtn(true)

    }
    useEffect(() => {
        if (selectedOrg) {
            getAllGatewaysC();
        }
        // setStripeForm(<StripeForm/>);
    }, [selectedOrg, fundsAmt]);

    useEffect(() => {
        if (selectedOrg) {
            getAllOrgPaymentCards();
        }
    }, [selectedOrg]);

    useEffect(() => {
        if (fundsAmt > 0 && JSON.stringify(onlineTransactionDetails) != "{}") {
            dialogCloseYes();
            // alert(JSON.stringify(onlineTransactionDetails));
        }
    }, [onlineTransactionDetails]);

    return (
        <>
            <MDrawer
                className={classes.fileUploadDrawer}
                open={addFundsDrawer}
                variant="temporary"
                anchor={'right'}
            >

                <AppBar className='drawerAppBar'>
                    <Toolbar>
                        <MBox width="100%" display="flex" justifyContent="space-between" alignItems="center">
                            <MBox display="flex">
                                <MBox display="inline-block" mr={1}>
                                    <MIconButton onClick={() => setAddFundsDrawer(false)}>
                                        <ArrowBackIcon />
                                    </MIconButton>
                                </MBox>
                                <MBox fontSize={18} fontFamily="Poppins" mt={1.25}>
                                    <FormattedMessage id="add.funds" />
                                </MBox>

                            </MBox>

                        </MBox>
                    </Toolbar>
                </AppBar>

                <MBox className={classes.paymentContainer}>

                    {(paymentMethod == '' || paymentMethod == 'paypal' || paymentMethod == 'marshub_credits') &&
                        <>

                            <MBox className={classes.withdrawAmount} mb={3}>
                                <MBox display='flex' justifyContent='space-between' mb={1}>
                                    <MBox>
                                        <MFormLabel className={classes.formLabel} component="legend" required mt={3.2} mb={2}>
                                            <FormattedMessage id="specify.your.withdrawal.amount" />
                                        </MFormLabel>

                                    </MBox>
                                    <MBox>
                                        <FormattedMessage id='mh.wallet' /> : <span style={{ fontFamily: 'Poppins', fontSize: 15 }}>${walletBalance && walletBalance.fee}</span>
                                    </MBox>
                                </MBox>
                                <MBox display='flex' justifyContent='space-between'>
                                    <TextField
                                        type='number'
                                        name="withdrawAmount"
                                        id="component-error"
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        disabled={false}
                                        fullWidth
                                        aria-describedby="component-error-text"
                                        placeholder='Enter payment Amount'
                                        onChange={(event: any) => {
                                            if (event.target.value >= 1) {
                                                setFundsAmt(event.target.value);
                                                setValidationAmount(true)
                                            } else {
                                                setFundsAmt(0)
                                                setValidationAmount(false)
                                            }
                                            // setFieldValue("withdrawAmount", event.target.value);
                                        }}


                                    // className={classes.seachInput}

                                    />
                                </MBox>

                                {validationAmount ?
                                    ''
                                    :
                                    <FormHelperText error id="component-error-text">Please Enter valid amount *</FormHelperText>


                                }
                            </MBox>
                            <MBox fontSize={14} color='#6B778C' fontFamily="Poppins" mb={3}>
                                <FormattedMessage id='choose.your.payment.method.below' />
                            </MBox>
                            <MBox display='flex' flexDirection='column' className={classes.paymentOptions}>

                                {/* {JSON.stringify(allGateways.map((item: any) => item.code))} */}



                                {fundsAmt > 0 && allGateways && allGateways.map((item: any) => (
                                    <>

                                        {item.code == 'paypal' && paypalFee > 0 && paypalClientId &&

                                            <>
                                                {!loadingPaypalBtn &&

                                                    <MSkeleton animation="wave" width={730} height={55} />

                                                }

                                                <MBox position='relative'>
                                                    <PayPalButton style={{
                                                        layout: 'vertical',
                                                        color: 'silver',
                                                        shape: 'rect',
                                                        label: 'paypal', width: "100%", height: 50, border: 'none !important',
                                                    }}
                                                        amount={parseFloat(fundsAmt) + paypalFee}
                                                        onButtonReady={setPaypalBtn}
                                                        // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                                                        onSuccess={(details: any) => {

                                                            addFunds({
                                                                variables: {
                                                                    transactionDetails: details && JSON.stringify(details),
                                                                    cardId: 0,
                                                                    amt: parseFloat(fundsAmt),
                                                                    orgId: selectedOrg,
                                                                    paymentInformation: ''
                                                                }
                                                            });
                                                        }}
                                                        options={{
                                                            // component: 'buttons',
                                                            disableFunding: "credit,card",
                                                            clientId: paypalClientId,
                                                            // disableCard: "yes"
                                                        }}
                                                    />
                                                    <MBox color='#6c788c' fontSize={12} sx={{ position: 'absolute', right: 11, bottom: 11, zIndex: 10000000000000 }}>
                                                        {item.fee > 0 && <><FormattedMessage
                                                            id={item.code !== 'marshub_credits' ? 'Fee' : 'Balance'} /> :
                                                            ${item.fee}</>}
                                                    </MBox>

                                                </MBox>
                                            </>
                                        }

                                        {item.code != 'paypal' &&
                                            <MBox width='100%' onClick={() => {
                                                handlePaymentMethod(item.code);
                                                setSelectedGateway(item)
                                            }} className={clsx(classes[paymentMethod == 'paypal' ? "active" : "hide"])}>


                                                <MBox className={classes.cardBalance} color='#6B778C' display='flex'
                                                    width='100%' alignItems='center' justifyContent="center">

                                                    <MIconButton pl={1} display='flex' className={classes.paymentMode} >
                                                        <MBox color='#6B778C' display='flex'
                                                            width='100%' className={"marginLeft"} alignItems='center'>
                                                            {item.code === 'paypal' && <MPaypalIcon />}
                                                            {item.code === 'stripe' && <MCreditCard />}
                                                            {item.code === 'bank_transfer' && <MBankTransfer />}
                                                            <MBox fontSize={14} color='#3a3a3a' fontFamily="Poppins" pl={1}>
                                                                <FormattedMessage id={item.name} />
                                                            </MBox>
                                                            <MBox fontSize={12} sx={{ position: 'absolute', right: 11, bottom: 5 }}>
                                                                {item.fee > 0 && <><FormattedMessage
                                                                    id={item.code !== 'marshub_credits' ? 'Fee' : 'Balance'} /> :
                                                                    ${item.fee}</>}
                                                            </MBox>
                                                        </MBox>
                                                    </MIconButton>

                                                </MBox>


                                            </MBox>
                                        }

                                    </>
                                ))}
                                {fundsAmt < 1 &&
                                    <>

                                        <MBox display='flex' className={classes.disabled}
                                            width='100%' alignItems='center' justifyContent="center" onClick={handlePaymentValidation}>

                                            <MPaypalText />
                                        </MBox>
                                        <MBox display='flex' className={`${classes.disabled} ${classes.paymentMode} `} mt={0.5}
                                            width='100%' alignItems='center' justifyContent="center" onClick={handlePaymentValidation}>

                                            <MBox fontSize={14} color='#3a3a3a' fontFamily="Poppins" pl={1}>
                                                <MCreditCard />
                                            </MBox>
                                            <MBox fontSize={14} color='#3a3a3a' fontFamily="Poppins" pl={1}>
                                                <FormattedMessage id="credit.card" />
                                            </MBox>
                                        </MBox><MBox display='flex' className={`${classes.disabled} ${classes.paymentMode} `} mt={0.5}
                                            width='100%' alignItems='center' justifyContent="center" onClick={handlePaymentValidation}>

                                            <MBox fontSize={14} color='#3a3a3a' fontFamily="Poppins" pl={1}>
                                                <MBankTransfer />
                                            </MBox>
                                            <MBox fontSize={14} color='#3a3a3a' fontFamily="Poppins" pl={1}>
                                                <FormattedMessage id="bank.transfer" />
                                            </MBox>
                                        </MBox>

                                    </>
                                }

                            </MBox>
                        </>
                    }

                    {/*if the use clicks credit card option then it will show credit card component*/}
                    {paymentMethod == 'stripe' &&
                        <CreditCard fundsAmt={fundsAmt} setAddFundsDrawer={setAddFundsDrawer} setIsNewCard={setIsNewCard} setOnlineTransactionDetails={setOnlineTransactionDetails}
                            selectedCard={selectedCard} setSelectedCard={setSelectedCard}
                            selectedGateway={selectedGateway} defaultCard={defaultCard} allCards={allPaymentCards}
                            updateInvoice={dialogCloseYes} amt={fundsAmt} />
                    }
                    {/*if the use clicks marshub credits card option then it will show marshub credits component*/}
                    {paymentMethod == 'marshub_credits' &&
                        <Credit />
                    }
                    {paymentMethod == 'bank_transfer' &&
                        <BankTransfer setRefreshProject={setRefreshProjects} refreshProjects={refreshProjects} setPaymentMethod={setPaymentMethod} mhWallet={mhWallet} type={type} setViewDetailOpenModal={setAddFundsDrawer} fundsAmt={fundsAmt} />
                    }

                </MBox>



            </MDrawer></>
    )
}
