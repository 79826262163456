import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import useStyles from './LanguageSelectStyles';
import { useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Autocomplete } from "@mui/material";
import TextField from '@mui/material/TextField';
import { MKeyboardArrowDownRoundedIcon } from 'src/app/material-ui/icons/keyboard-rounded-icon/KeyboardArrowDownRoundedIcon';

interface Language {
    defaultValue?: any,
    sourceLanguages?: any,
    sl?: any,
    label: string,
    name?: string,
    placeholder?: string,
    setFieldValue?: any,
    className?: any,
    disableClearable?: boolean,
    size?: string,
    multiple?: any,
    limitTags?: number
}


const SOURCE_LANGUAGE_GQL = gql`
    {
        sourceLanguages {
            code
            id
            name
        }
    }
`;

export function LanguageSelect(props: Language) {
    const classes: any = useStyles({});


    const [language, setLanguage] = useState([]);
    const [getLanuages] = useLazyQuery(SOURCE_LANGUAGE_GQL, {
        fetchPolicy: 'no-cache',
        onCompleted: (data: any) => {
            setLanguage(data && data.sourceLanguages);
        }
    });

    useEffect(() => {
        if (props.sourceLanguages) {
            setLanguage(props.sourceLanguages);
        } else {
            getLanuages()
        }
    }, [props.sourceLanguages, getLanuages]);

    const autocomplete = (value?: any) => {
        return (
            <div className="langSelect">
                <Autocomplete
                    {...props}
                    limitTags={1}
                    autoComplete={false}
                    id="language"
                    size="small"
                    popupIcon={<MKeyboardArrowDownRoundedIcon />}
                    className={clsx(classes.inputWidth, classes.langSelect, props.className ? props.className : '', classes[props.size ? props.size : 'sizeMd'])}
                    defaultValue={value && value}
                    options={language && language}
                    getOptionLabel={(option: any) => option.name}
                    onChange={(event: any, value: any) => {
                        if (props.setFieldValue) {
                            props.setFieldValue(props.name, value ? value : '')
                        } else if (props.sl) {
                            if (value) {
                                props.sl(value && value.code);
                            } else {
                                props.sl(false);
                            }
                        }
                    }}
                    renderInput={params => <TextField {...params}
                        label={props.label}
                        variant="outlined" color="secondary" />}
                />
            </div>

        )
    }

    return (
        <>
            {props.defaultValue &&
                autocomplete(props.defaultValue)
            }
            {!props.defaultValue &&
                autocomplete()
            }
        </>
    )
}