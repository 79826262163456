import React from 'react';

export function MInvoiceReview() {
    return <React.Fragment>

        {/*<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
            {/*<path d="M12.75 13.5C13.17 13.5 13.5 13.83 13.5 14.25C13.5 14.67 13.17 15 12.75 15C12.33 15 12 14.67 12 14.25C12 13.83 12.33 13.5 12.75 13.5ZM12.75 11.25C10.7025 11.25 8.955 12.495 8.25 14.25C8.955 16.005 10.7025 17.25 12.75 17.25C14.7975 17.25 16.545 16.005 17.25 14.25C16.545 12.495 14.7975 11.25 12.75 11.25ZM12.75 16.125C12.2527 16.125 11.7758 15.9275 11.4242 15.5758C11.0725 15.2242 10.875 14.7473 10.875 14.25C10.875 13.7527 11.0725 13.2758 11.4242 12.9242C11.7758 12.5725 12.2527 12.375 12.75 12.375C13.2473 12.375 13.7242 12.5725 14.0758 12.9242C14.4275 13.2758 14.625 13.7527 14.625 14.25C14.625 14.7473 14.4275 15.2242 14.0758 15.5758C13.7242 15.9275 13.2473 16.125 12.75 16.125ZM6.9525 15H4.5V3H9.75V6.75H13.5V9.8025C14.025 9.8625 14.52 9.99 15 10.17V6L10.5 1.5H4.5C4.10218 1.5 3.72064 1.65804 3.43934 1.93934C3.15804 2.22064 3 2.60218 3 3V15C3 15.3978 3.15804 15.7794 3.43934 16.0607C3.72064 16.342 4.10218 16.5 4.5 16.5H7.875C7.5 16.0575 7.1925 15.5475 6.9525 15Z" fill="#6665DD" />*/}
        {/*</svg>*/}

        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.8008 12.1C12.4753 12.1 12.1631 12.2264 11.933 12.4515C11.7028 12.6765 11.5735 12.9817 11.5735 13.3C11.5735 13.6182 11.7028 13.9235 11.933 14.1485C12.1631 14.3736 12.4753 14.5 12.8008 14.5C13.1263 14.5 13.4384 14.3736 13.6686 14.1485C13.8988 13.9235 14.0281 13.6182 14.0281 13.3C14.0281 12.9817 13.8988 12.6765 13.6686 12.4515C13.4384 12.2264 13.1263 12.1 12.8008 12.1ZM12.8008 15.3C12.2583 15.3 11.738 15.0893 11.3544 14.7142C10.9708 14.3391 10.7553 13.8304 10.7553 13.3C10.7553 12.7696 10.9708 12.2608 11.3544 11.8858C11.738 11.5107 12.2583 11.3 12.8008 11.3C13.3433 11.3 13.8635 11.5107 14.2471 11.8858C14.6307 12.2608 14.8462 12.7696 14.8462 13.3C14.8462 13.8304 14.6307 14.3391 14.2471 14.7142C13.8635 15.0893 13.3433 15.3 12.8008 15.3ZM12.8008 10.3C10.7553 10.3 9.00851 11.544 8.30078 13.3C9.00851 15.056 10.7553 16.3 12.8008 16.3C14.8462 16.3 16.5931 15.056 17.3008 13.3C16.5931 11.544 14.8462 10.3 12.8008 10.3Z" fill="#6665DD"/>
            <path d="M14.625 1.5H3.375C2.325 1.5 1.5 2.325 1.5 3.375V14.625C1.5 15.675 2.325 16.5 3.375 16.5H6V15H3.375C3.15 15 3 14.85 3 14.625V3.375C3 3.15 3.15 3 3.375 3H14.625C14.85 3 15 3.15 15 3.375V8H16.5V3.375C16.5 2.325 15.675 1.5 14.625 1.5Z" fill="#6665DD"/>
            <path d="M12 4.875H4.5V6.375H12V4.875Z" fill="#6665DD"/>
            <path d="M9 7.875H4.5V9.375H9V7.875Z" fill="#6665DD"/>
        </svg>

    </React.Fragment>;
}


