import React, { Fragment } from 'react'

export function MProgressCheck() {
    return (
        <Fragment>

            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.0008 2.40369V2.42369V4.42369C17.3908 4.96369 20.5008 8.95369 19.9608 13.3437C19.5008 16.9837 16.6408 19.8737 13.0008 20.3037V22.3037C18.5008 21.7537 22.5008 16.8737 21.9508 11.3737C21.5008 6.62369 17.7308 2.87369 13.0008 2.40369ZM11.0008 2.43369C9.05078 2.62369 7.19078 3.37369 5.67078 4.63369L7.10078 6.11369C8.22078 5.21369 9.57078 4.63369 11.0008 4.43369V2.43369ZM4.26078 6.04369C3.00078 7.56369 2.25078 9.41369 2.05078 11.3737H4.05078C4.24078 9.95369 4.80078 8.60369 5.69078 7.47369L4.26078 6.04369ZM15.5008 8.87369L10.6208 13.7537L8.50078 11.6337L7.44078 12.6937L10.6208 15.8737L16.5608 9.93369L15.5008 8.87369ZM2.06078 13.3737C2.26078 15.3337 3.03078 17.1837 4.27078 18.7037L5.69078 17.2737C4.81078 16.1437 4.24078 14.7937 4.06078 13.3737H2.06078ZM7.10078 18.7437L5.67078 20.1137C7.18078 21.3737 9.04078 22.1637 11.0008 22.3737V20.3737C9.58078 20.1937 8.23078 19.6237 7.10078 18.7437Z" fill="#A2A7BB" />
            </svg>

        </Fragment>
    )
}
