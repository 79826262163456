import React from 'react';
import {
    MBox,
    MSkeleton,
} from 'src/app/material-ui';
import useStyles from './SendQuoteStyles';


export default function SendQuoteSkeleton() {
    const classes = useStyles({});

    return (
        <MBox color="palette.secondary" className={classes.contentBox} py={3} px={4}>
            <MBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <MBox fontFamily="Poppins" fontSize={24} color='#393939' mb={1}>
                    <MSkeleton height='100%' width={150} />
                </MBox>
                <MBox display="flex">
                    <MBox display="flex" mr={6}>
                        <MBox sx={{ color: '#a0a5b9', marginRight: 15 }}>
                            <MSkeleton height={20} width={100} />
                        </MBox>
                    </MBox>
                    <MBox display="flex">
                        <MBox sx={{ color: '#a0a5b9', marginRight: 15 }}>
                            <MSkeleton height={20} width={100} />
                        </MBox>
                    </MBox>
                </MBox>
            </MBox>
            <MBox display="flex" alignItems="center" justifyContent="space-between">
                <MBox display="flex" alignItems="center">
                    <MBox mr={1.75}>
                        <MSkeleton variant='circle' height={40} width={40} />
                    </MBox>
                    <MBox color="palette.secondary" maxWidth="737px">
                        <MSkeleton height={20} width={100} />
                    </MBox>
                </MBox>
                <MBox>
                    <MSkeleton height={20} width={100} />
                </MBox>

            </MBox>
            <MBox className={classes.hr} my={5}></MBox>
            <MBox mb={4} pl={2} display="flex">
                <MBox minWidth={320} maxWidth={320}>
                    <MBox mb={1.8} className={classes.greyUppercaseText}>
                        <MSkeleton height='100%' width='50%' />
                    </MBox>
                    <MBox>
                        <MSkeleton height='100%' width='50%' />
                    </MBox>
                </MBox>
                <MBox minWidth={320} maxWidth={320}>
                    <MBox mb={1.8} className={classes.greyUppercaseText}>
                        <MSkeleton height='100%' width='50%' />
                    </MBox>
                    <MBox>
                        <MSkeleton height='100%' width='50%' />
                    </MBox>
                </MBox>
            </MBox>
            <MBox display="flex" justifyContent="space-between"
                alignItems="center">
                <MBox width="42.4%" display="flex" justifyContent="space-between" p={2}
                    className={classes.headerLeftCol}>
                    <MBox className={classes.greyUppercaseText}>
                        <MSkeleton height='100%' width='50%' />
                    </MBox>
                    <MBox className={classes.greyUppercaseText}>
                        <MSkeleton height='100%' width='50%' />
                    </MBox>
                </MBox>
                <MBox width="57.5%">
                    <MBox display="flex" justifyContent="space-between" p={2} pl={0}
                        className={classes.headerRightCol} alignItems="center">
                        <MBox className={classes.greyUppercaseText} maxWidth={110}
                            minWidth={110} pr="5px">
                            <MSkeleton height='100%' width='100%' />
                        </MBox>
                        <MBox display="flex" minWidth={320} justifyContent="space-between">
                            <MBox className={classes.greyUppercaseText} minWidth={155}
                                maxWidth={155}>
                                <MSkeleton height='100%' width='50%' />
                            </MBox>
                            <MBox textAlign="left" className={classes.greyUppercaseText}
                                minWidth={155} maxWidth={155}>
                                <MSkeleton height='100%' width='50%' />
                            </MBox>
                        </MBox>
                        <MBox className={classes.greyUppercaseText} maxWidth={70} minWidth={70}>
                            <MSkeleton height='100%' width='100%' />
                        </MBox>
                    </MBox>
                </MBox>
            </MBox>
            <MBox mb={1}>
                <MSkeleton height={67} width={'100%'} />
            </MBox>
            <MBox mb={2}>
                <MSkeleton height={38} width={101} />
            </MBox>
            <MBox pt={2}>
                <MBox display="flex" justifyContent="space-between">
                    <MBox width="50%">
                        <MBox mb={1} display="flex" justifyContent="space-between"
                            alignItems="center" pb={1} pr={2}>
                            <MBox className={classes.colorLightGrey}>
                                <MSkeleton height='100%' width={100} />
                            </MBox>
                        </MBox>
                        <MBox>
                            <MSkeleton height={245} width={530} />
                        </MBox>
                    </MBox>

                    <MBox width="45%">
                        <MBox display="flex" justifyContent="space-between"
                            alignItems="center" pb={1} pr={2}>
                            <MBox className={classes.colorLightGrey}>
                                <MSkeleton height='100%' width={100} />
                            </MBox>
                            <MBox><MSkeleton height={18} width={40} /></MBox>
                        </MBox>

                        <MBox display="flex" justifyContent="space-between"
                            alignItems="center" py={1} pr={2}>
                            <MBox width="59%" className={`${classes.bgLightGrey}`}
                                py={1} px={2} display="flex"
                                justifyContent="space-between" alignItems="center">
                                <MBox width="50%"
                                    className={classes.colorLightGrey}>
                                    <MSkeleton height='100%' width={100} />
                                </MBox>
                                <MBox width="50%" textAlign="right">
                                    <MSkeleton height='100%' width={100} />
                                </MBox>
                            </MBox>
                            <MBox><MSkeleton height={18} width={40} /></MBox>
                        </MBox>

                        <MBox display="flex" justifyContent="space-between"
                            alignItems="center" py={1} pr={2}>
                            <MBox width="59%" className={`${classes.bgLightGrey}`}
                                py={1} px={2} display="flex"
                                justifyContent="space-between" alignItems="center">
                                <MBox width="50%" className={classes.colorLightGrey}
                                    borderRadius={4}>
                                    <MSkeleton height='100%' width={100} />
                                </MBox>
                                <MBox width="50%" textAlign="right">
                                    <MSkeleton height='100%' width={100} />
                                </MBox>
                            </MBox>
                            <MBox>
                                <MSkeleton height={18} width={40} />
                            </MBox>
                        </MBox>
                        <MBox display="flex" justifyContent="space-between" alignItems="center" py={1} pr={2}>
                            <MBox className={classes.colorLightGrey}>
                                <MSkeleton height='100%' width={100} />
                            </MBox>
                            <MBox textAlign="right"><MSkeleton height={18}
                                width={40} /></MBox>
                        </MBox>
                        <MBox display="flex" justifyContent="space-between"
                            alignItems="center" py={1} pr={2}>
                            <MBox className={classes.colorLightGrey}>
                                <MSkeleton height='100%' width={100} />
                            </MBox>
                            <MBox fontSize={22} color="secondary.main"
                                fontFamily="Poppins Bold"><MSkeleton height={29} width={70} /></MBox>
                        </MBox>
                    </MBox>
                </MBox>
            </MBox>
            <MBox className={classes.hr} mt={3} mb={5}></MBox>
            <MBox display="flex" justifyContent="flex-end" width="100%">
                <MBox pb={1}>
                    <MSkeleton height={36} width={136} />
                </MBox>
            </MBox>
        </MBox>
    )
}
