/* eslint-disable */
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    MBox,
    MButton, MLink, MCircularProgress
} from 'src/app/material-ui';

import { MInsufficientBalance } from '../../../../material-ui/icons/index';
import useStyles from './PaymentStyles';

import AddFunds from 'src/app/screens/client-screens/payments/AddFunds';
import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import theme from 'src/app/material-ui/ThemeConfig';

const GET_CLIENT_TRANSACTION = gql`
    query getClientTransactions($orgId:Int,$page: ID,$transactionId:String,$jobTitle:String,$vendorName:String,$toDate: String,$fromDate: String){
        getClientTransactions(orgId:$orgId,page: $page,transactionId:$transactionId,jobTitle:$jobTitle,vendorName:$vendorName,toDate: $toDate,fromDate: $fromDate) {
            month {
                month
                transactions {
                    id
                    moduleName
                    userId
                    isWithdraw
                    userName
                    totalAmount
                    discountedAmount
                    taxAmount
                    balance
                    createdAt
                    status
                    discountPercentage
                    taxPercentage
                    resourceName
                    batchId
                    quoteId
                    batchName
                    paymentSource
                    invoice{
                        id
                    }
                }
            }
            walletBalance
            total
        }
    }`;
export default function Credit(props: any) {
    const classes = useStyles({});

    const [paymentMethod, setPaymentMethod]: any = useState('paypal');
    // const [walletBalance, setWalletBalance]: any = useState(0)
    const [clientTransactionData, setClientTransactionData]: any = useState();
    const selectedOrg = window.localStorage.getItem('selectedOrgId');

    // const { fileType }: any = useContext(FileContext);
    // const [languages, setLanguages] = useState([]);
    const handleAddFunds = () => {
        props.setAddFundsDrawer(true)
    };

    const [getClientTransaction, { loading: transactionLoading }] = useLazyQuery(GET_CLIENT_TRANSACTION, {
        fetchPolicy: 'no-cache',
        variables: {
            page: 1,
            // transactionId: transactionId,
            // toDate: createdToDate,
            // fromDate: createdFromDate,
            // vendorName: vendor,
            // jobTitle: jobTitle,
            orgId: selectedOrg
        },
        onCompleted: (data: any) => {
            setClientTransactionData(data && data.getClientTransactions.month);
            // setTotalPages(data && Math.ceil(data.getClientTransactions.total / 10));
            props.setWalletBalance(data && data.getClientTransactions.walletBalance);
        }
    });

    return (
        <div>
            <MBox className={classes.paymentCard}>
                <MBox mb={3}>
                    <MBox fontFamily='Poppins' fontSize={22} color='#3a3a3a' mb={3}>
                        <FormattedMessage id='marsHub.credits' />
                    </MBox>
                    <MBox fontFamily='Poppins' fontSize={14} color='#6B778C'>
                        <FormattedMessage id='want.to.add.more.credits' />? <span>
                            <MLink color='secondary' onClick={handleAddFunds}> <FormattedMessage id='click.here' /></MLink>
                        </span>
                    </MBox>

                </MBox>

                <AddFunds getAllGateways={typeof props.getAllGateways != "undefined" ? props.getAllGateways : null} getClientTransaction={getClientTransaction} setPaymentMethod={setPaymentMethod} paymentMethod={paymentMethod} addFundsDrawer={props.addFundsDrawer} setAddFundsDrawer={props.setAddFundsDrawer} walletBalance={props.walletBalance} />

                {/* selected = {props.selectedGateway && JSON.stringify(props.selectedGateway)} */}
                {/*if the balance is zero then it will shows insufficient balance*/}
                {props.selectedGateway && (props.selectedGateway.fee - props.selectedJob.invoiceBalance) < 0 ?
                    <>

                        <MBox className={classes.paymentBox} display='flex' justifyContent='center' alignItems='center'>
                            <MBox mr={0.75}>
                                <MInsufficientBalance />
                            </MBox>
                            <MBox fontFamily='Poppins' fontSize={14} color='#D29999' mr={0.75}>
                                <FormattedMessage id='insufficient.balance' />
                            </MBox>
                        </MBox>
                        <MBox display='flex' justifyContent='flex-end'>
                            <MLink href="#" color='#3a3a3a' component='button' onClick={props.handleAcceptRejectClose} sx={{ fontSize: 14 }}>
                                <FormattedMessage id='cancel' />
                            </MLink>
                        </MBox>


                    </>
                    :
                    <>
                        <MBox className={classes.balanceBox} display='flex' justifyContent='center' alignItems='center'>

                            <MBox fontFamily='Poppins' fontSize={14} color='#6B778C' mr={0.75}>
                                <FormattedMessage id='total.amount.you.will.pay' /> :
                            </MBox>
                            <MBox fontFamily='Poppins' fontSize={24} color='#3F74E8'>
                                {/*{props.selectedGateway && props.selectedGateway.fee} =*/}
                                {/*{props.selectedGateway && (props.selectedGateway.fee - props.selectedJob.invoiceBalance)}  ==*/}
                                ${(props.selectedJob && props.selectedJob.invoiceBalance)}

                            </MBox>


                        </MBox>

                        {process.env.REACT_APP_ENVIRONMENT != 'production' && <MBox display='flex' color justifyContent='flex-end' my={2}>
                            <MLink href="#" onClick={() => { props.setPaymentMethod('') }} color='#3a3a3a' component='button' sx={{ fontSize: 14 }} >
                                <FormattedMessage id='cancel' />
                            </MLink>
                            <MLink underline='none' href="#" color='#3a3a3a' component='button' sx={{ fontSize: 14, marginLeft: 1 }} >
                                <MButton type="submit"
                                    sx={{ backgroundColor: theme.palette.secondary.dark }} variant="contained" disabled={props.invoiceLoading} onClick={() => { props.updateInvoice() }}>
                                    {props.invoiceLoading && <MCircularProgress color="secondary" size={24} />}<FormattedMessage id='pay.now' />
                                </MButton>
                            </MLink>
                        </MBox>}

                    </>


                }

            </MBox>
        </div >
    )
}
