import React, { Fragment } from 'react'

export function MEnglish() {
    return (
        <Fragment>

            <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.81168 2.14139V4.94166H6.59917V7.00069H2.81168V9.85861H7.75692V12H0V0H7.75692V2.14139H2.81168Z" fill="#6B778C" />
                <path d="M20 0V12H18.5445C18.3295 12 18.1476 11.9671 17.9987 11.9012C17.8554 11.8298 17.7121 11.709 17.5687 11.5388L11.8875 4.38161C11.9095 4.59574 11.9233 4.80439 11.9288 5.00755C11.9399 5.20522 11.9454 5.3919 11.9454 5.5676V12H9.48101V0H10.953C11.0743 0 11.1763 0.00549066 11.259 0.016472C11.3417 0.0274533 11.4161 0.0494163 11.4823 0.0823609C11.5484 0.109815 11.6118 0.150995 11.6725 0.205902C11.7331 0.26081 11.802 0.334934 11.8792 0.428277L17.6101 7.63487C17.5825 7.40425 17.5632 7.18188 17.5522 6.96774C17.5412 6.74811 17.5356 6.54221 17.5356 6.35003V0H20Z" fill="#6B778C" />
            </svg>

        </Fragment>
    )
}
