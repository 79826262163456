import { SELECTED_RATE_NAME, SELECTED_SERVICE_NAME, SELECTED_VENDOR_JOB_NAME } from "./BreadcrumsActionTypes";
import {RESET_STORE} from '../resetStateAction';

interface IAction {
    type: string,
    payload: any
}

interface IState {
    selectedRateName:string,
    selectedServiceName:string,
    selectedVendorJobName: string
}

const initialState: IState = {
    selectedRateName: '',
    selectedServiceName: '',
    selectedVendorJobName: ''
};

const breadcrumsReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case SELECTED_RATE_NAME:
            return {
                ...state,
                selectedRateName: action.payload
            }
        case SELECTED_SERVICE_NAME:
            return {
                ...state,
                selectedServiceName: action.payload
            }
        case SELECTED_VENDOR_JOB_NAME:
            return {
                ...state,
                selectedVendorJobName: action.payload
            }
        case RESET_STORE:
            return initialState;
        default:
            return state;
    }
};

export default breadcrumsReducer;