import React from 'react'


export function MGithubIcon() {
    return (

        <svg width="118" height="114" viewBox="0 0 118 114" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M59.1623 0C51.4898 0 43.8925 1.51095 36.804 4.44659C29.7156 7.38222 23.2749 11.6851 17.8496 17.1094C6.89278 28.0644 0.737305 42.9225 0.737305 58.4151C0.737305 84.2346 17.5053 106.14 40.7 113.91C43.6213 114.377 44.5561 112.566 44.5561 110.989V101.117C28.3723 104.622 24.9253 93.289 24.9253 93.289C22.2377 86.5128 18.4401 84.702 18.4401 84.702C13.1234 81.0802 18.8491 81.197 18.8491 81.197C24.6916 81.6059 27.7881 87.2138 27.7881 87.2138C32.8711 96.0929 41.4595 93.4642 44.7898 92.0623C45.3156 88.2653 46.8346 85.695 48.4705 84.2346C35.5002 82.7742 21.8872 77.7505 21.8872 55.4944C21.8872 49.0103 24.1073 43.8114 27.9049 39.6639C27.3207 38.2035 25.2758 32.1283 28.4892 24.2423C28.4892 24.2423 33.3969 22.6651 44.5561 30.2006C49.1716 28.9155 54.1962 28.2729 59.1623 28.2729C64.1284 28.2729 69.153 28.9155 73.7686 30.2006C84.9277 22.6651 89.8354 24.2423 89.8354 24.2423C93.0488 32.1283 91.0039 38.2035 90.4197 39.6639C94.2173 43.8114 96.4374 49.0103 96.4374 55.4944C96.4374 77.809 82.766 82.7158 69.7372 84.1762C71.8405 85.9871 73.7686 89.5504 73.7686 94.983V110.989C73.7686 112.566 74.7034 114.435 77.683 113.91C100.878 106.082 117.587 84.2346 117.587 58.4151C117.587 50.7439 116.076 43.1479 113.14 36.0606C110.204 28.9734 105.9 22.5337 100.475 17.1094C95.0498 11.6851 88.609 7.38222 81.5206 4.44659C74.4321 1.51095 66.8348 0 59.1623 0Z" fill="black" />
        </svg>

    )
}
export function GithubIcon() {
    return (

        <svg width="47" height="45" viewBox="0 0 47 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.5799 0.0134277C20.6025 0.0134277 17.6543 0.599768 14.9035 1.73897C12.1528 2.87817 9.65339 4.54793 7.54806 6.6529C3.29616 10.9041 0.907471 16.6699 0.907471 22.682C0.907471 32.7015 7.41445 41.2022 16.4154 44.2171C17.549 44.3985 17.9118 43.6957 17.9118 43.0837V39.2527C11.6315 40.6128 10.2938 36.2151 10.2938 36.2151C9.25091 33.5856 7.7772 32.8828 7.7772 32.8828C5.71402 31.4774 7.93591 31.5227 7.93591 31.5227C10.2031 31.6814 11.4048 33.8576 11.4048 33.8576C13.3773 37.3032 16.7101 36.2831 18.0025 35.7391C18.2065 34.2656 18.796 33.2682 19.4308 32.7015C14.3975 32.1348 9.11487 30.1853 9.11487 21.5486C9.11487 19.0324 9.97643 17.0148 11.4501 15.4054C11.2234 14.8387 10.4299 12.4811 11.6769 9.42088C11.6769 9.42088 13.5813 8.80883 17.9118 11.7331C19.7029 11.2344 21.6527 10.985 23.5799 10.985C25.507 10.985 27.4568 11.2344 29.248 11.7331C33.5784 8.80883 35.4829 9.42088 35.4829 9.42088C36.7298 12.4811 35.9363 14.8387 35.7096 15.4054C37.1833 17.0148 38.0448 19.0324 38.0448 21.5486C38.0448 30.208 32.7395 32.1121 27.6836 32.6788C28.4998 33.3815 29.248 34.7643 29.248 36.8725V43.0837C29.248 43.6957 29.6107 44.4211 30.767 44.2171C39.7679 41.1795 46.2522 32.7015 46.2522 22.682C46.2522 19.7051 45.6658 16.7574 44.5264 14.0071C43.387 11.2568 41.717 8.75787 39.6117 6.6529C37.5063 4.54793 35.0069 2.87817 32.2562 1.73897C29.5055 0.599768 26.5572 0.0134277 23.5799 0.0134277Z" fill="black" />
        </svg>

    )
}
export function Repository() {
    return (


        <svg width="35" height="33" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_31_225)">
                <path d="M4.6663 7.05955C4.6663 5.59816 4.66532 4.1898 4.6663 2.78243C4.66825 1.08435 5.73838 0.00107228 7.41905 0.00107228C14.1378 9.01642e-05 20.8556 9.01642e-05 27.5744 0.00107228C29.2609 0.00107228 30.3311 1.07845 30.3321 2.77653C30.334 5.22692 30.3321 7.6773 30.3321 10.1277C30.3321 10.2622 30.3321 10.3968 30.3321 10.5893C30.484 10.5893 30.6232 10.5863 30.7625 10.5903C31.4898 10.6089 32.2279 10.5549 32.9417 10.6649C34.1335 10.8495 34.9904 11.9053 34.9924 13.1251C35.0021 18.8832 35.0031 24.6414 34.9924 30.3995C34.9894 31.7991 33.8677 32.9049 32.4821 32.9403C32.4217 32.9422 32.3604 32.9423 32.3 32.9423C22.4351 32.9423 12.5701 32.9432 2.70519 32.9413C1.29814 32.9413 0.256243 32.079 0.0313102 30.7335C0.00112437 30.5537 0.00209811 30.3671 0.00112437 30.1845C0.000150638 23.3961 -0.000823099 16.6087 0.00112437 9.82126C0.00112437 8.14773 1.07808 7.06249 2.73538 7.05955C3.36441 7.05758 3.99442 7.05955 4.6663 7.05955ZM27.9688 10.5883C27.9834 10.5107 27.9961 10.4753 27.9961 10.441C27.997 7.86881 27.9961 5.29665 28.0009 2.72448C28.0019 2.4269 27.8607 2.3503 27.5949 2.3503C20.8654 2.35422 14.1369 2.35422 7.40737 2.34931C7.11233 2.34931 6.99353 2.45145 6.99548 2.75591C7.00521 4.12793 6.99937 5.49994 7.00132 6.87196C7.00132 6.92892 7.01982 6.98589 7.03248 7.05856C7.58361 7.05856 8.11625 7.06347 8.64986 7.05758C9.43274 7.04874 10.092 7.32766 10.6421 7.88943C11.4279 8.69084 12.2283 9.47653 13.0073 10.2848C13.2186 10.5048 13.4319 10.5952 13.7386 10.5942C18.3541 10.5844 22.9696 10.5873 27.5851 10.5873C27.7049 10.5883 27.8257 10.5883 27.9688 10.5883Z" fill="#424242" />
                <path d="M19.286 5.88288C20.9745 5.88288 22.6639 5.8819 24.3524 5.88288C25.1284 5.88386 25.6601 6.35823 25.664 7.05062C25.6689 7.74498 25.1382 8.23505 24.3689 8.23505C20.955 8.23702 17.5411 8.23702 14.1272 8.23505C13.3598 8.23505 12.8282 7.74301 12.834 7.04865C12.8399 6.3543 13.3657 5.88485 14.1466 5.88386C15.8594 5.8819 17.5732 5.88288 19.286 5.88288Z" fill="#424242" />
            </g>
            <defs>
                <clipPath id="clip0_31_225">
                    <rect width="35" height="32.9412" fill="white" />
                </clipPath>
            </defs>
        </svg>


    )
}
export function WebHook() {
    return (



        <svg width="35" height="26" viewBox="0 0 35 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_31_235)">
                <path d="M23.854 2.90801C23.854 2.46013 23.8474 2.07203 23.855 1.68301C23.8738 0.738508 24.6139 0.00369174 25.5444 1.31026e-05C26.4871 -0.00366554 27.2356 0.744026 27.2394 1.706C27.2441 2.84179 27.2403 3.97759 27.2403 5.11338C27.2403 5.23846 27.2403 5.36353 27.2403 5.53367C27.4025 5.53367 27.5401 5.53367 27.6787 5.53367C29.5255 5.53367 31.3714 5.52723 33.2182 5.53643C34.2882 5.54287 35.0349 6.27676 34.9981 7.25529C34.9679 8.05357 34.3448 8.71849 33.5303 8.81322C33.3559 8.83345 33.1786 8.83713 33.0023 8.83713C31.2385 8.83897 29.4746 8.83805 27.7098 8.83805C27.5694 8.83805 27.4298 8.83805 27.2649 8.83805C27.2649 11.6109 27.2648 14.3588 27.2648 17.1436C27.3987 17.15 27.5345 17.162 27.6702 17.162C29.517 17.1629 31.3629 17.1565 33.2097 17.1657C34.2543 17.1712 34.9972 17.8674 35 18.81C35.0019 19.6681 34.3476 20.3772 33.468 20.4498C33.1758 20.4737 32.8807 20.4664 32.5866 20.4664C30.9519 20.4673 29.3172 20.4673 27.6825 20.4673C27.5439 20.4673 27.4063 20.4673 27.2403 20.4673C27.2403 21.2085 27.2403 21.9066 27.2403 22.6046C27.2403 23.1895 27.2469 23.7753 27.2385 24.3602C27.2243 25.2726 26.4673 26.0019 25.5481 26.0009C24.644 26 23.8927 25.2909 23.8587 24.3906C23.8427 23.9694 23.8559 23.5482 23.8559 23.0929C23.6749 23.0929 23.5373 23.0939 23.3987 23.0929C21.764 23.0837 20.1265 23.1334 18.4955 23.0525C14.1391 22.8373 10.4021 19.9054 9.19261 15.8202C9.10776 15.5342 9.01538 15.2454 8.98144 14.952C8.95127 14.6908 8.83532 14.6467 8.59397 14.6476C6.31255 14.6559 4.03113 14.655 1.74971 14.6513C0.938018 14.6504 0.28659 14.1859 0.0716471 13.4769C-0.253598 12.4036 0.543956 11.3561 1.71106 11.3515C3.98117 11.3423 6.25033 11.3441 8.52044 11.3543C8.80986 11.3552 8.92959 11.3 8.99746 10.9864C9.89306 6.83316 13.153 3.7679 17.4406 3.04872C18.0515 2.94664 18.6803 2.92365 19.3016 2.91445C20.677 2.89514 22.0534 2.90801 23.4298 2.90801C23.558 2.90801 23.6843 2.90801 23.854 2.90801Z" fill="#424242" />
            </g>
            <defs>
                <clipPath id="clip0_31_235">
                    <rect width="26" height="35" fill="white" transform="translate(35) rotate(90)" />
                </clipPath>
            </defs>
        </svg>



    )
}