import React from 'react'
export function MGoogleSheetIcon() {
    return (
        <svg width="41" height="48" viewBox="0 0 41 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_310_1906)">
                <path d="M2.58594 0.046875H21.1953L21.3125 0.117188L34.3203 13.125L34.3438 13.1719V44.8125L34.3203 45.2578L34.2266 45.7266L34.0625 46.1484L33.8516 46.5469L33.6172 46.8516L33.3594 47.1328L33.0312 47.3906L32.6797 47.6016L32.3516 47.7422L31.9766 47.8594L31.5547 47.9297L30.8516 47.9531L2.46875 47.9297L1.90625 47.8359L1.46094 47.6719L1.10938 47.4844L0.78125 47.25L0.453125 46.9453L0.195312 46.6172L-0.015625 46.2656L-0.179688 45.8906L-0.296875 45.4453L-0.34375 45.0938V2.85938L-0.273438 2.4375L-0.179688 2.10938L-0.015625 1.73438L0.171875 1.40625L0.40625 1.10156L0.757812 0.75L1.13281 0.492188L1.53125 0.28125L1.95312 0.140625L2.32812 0.0703125L2.58594 0.046875Z" fill="#24A56A" />
                <path d="M9.35938 23.8828H24.5469L24.8516 23.9297L25.1797 24.0938L25.3203 24.2109L25.4844 24.4688L25.5547 24.6562L25.5781 24.8203L25.6016 29.3203V34.2188L25.5781 38.1328L25.5312 38.3906L25.4141 38.625L25.2031 38.8594L24.9688 39L24.6875 39.0703H9.28906L8.98438 38.9766L8.77344 38.8359L8.58594 38.625V38.5781H8.53906L8.44531 38.2969L8.42188 36.7031V24.8203L8.46875 24.5859L8.60938 24.3047L8.79688 24.0938L9.05469 23.9531L9.17188 23.9062L9.35938 23.8828Z" fill="#FDFDFD" />
                <path d="M21.2188 0.0703125L21.3125 0.117188L34.3203 13.125V13.1719L27.8516 13.1953L24.2188 13.1719L23.8438 13.1484L23.4219 13.0547L22.9531 12.8672L22.5781 12.6562L22.3203 12.4453L22.0859 12.2344L21.875 12L21.6406 11.6484L21.4531 11.2734L21.3359 10.9453L21.2422 10.4531L21.2188 10.0781V0.0703125Z" fill="#8FD2B2" />
                <path d="M23.5391 13.0781L23.8438 13.125L24.2188 13.1484L28.0859 13.1719H34.3438V44.6016L34.2969 44.625L34.2734 42.0234V21.8203L34.25 20.8594V21.0703L34.2031 21.1875H34.25V21.2812L34.2031 21.3047V21.7266L34.0391 21.6328L33.8047 21.4219L33.5 21.1875L33.2891 21.0234L32.9141 20.7188L32.6562 20.5078L32 19.9922L31.6953 19.7344L31.3672 19.4766L30.9922 19.1719L30.7344 18.9609L30.5 18.7734L30.1953 18.5156L29.7969 18.2109L29.6094 18.0469L29.2812 17.7891L29.0234 17.5781L28.625 17.25L28.25 16.9453L27.8984 16.6641L27.5703 16.4062L27.2656 16.1484L26.6797 15.6797L26.4219 15.4688L26.2578 15.3516L26.1406 15.2344L25.8125 14.9766L25.5078 14.7422V14.6953L25.4141 14.6484L25.1562 14.4375L24.7812 14.1328L24.5234 13.9219L24.4297 13.8516V13.8047L24.3125 13.7578L23.5625 13.1484L23.5391 13.0781Z" fill="#1C8758" />
                <path d="M18.1016 30.2344H23.2344L23.5859 30.2578V32.7422H17.9844V30.2578L18.1016 30.2344Z" fill="#26A66B" />
                <path d="M10.5312 30.2344H15.5469L16.0156 30.2578V32.1328L15.9922 32.7422H10.4141V30.2578L10.5312 30.2344Z" fill="#26A56B" />
                <path d="M10.4141 25.875H15.9922L16.0156 25.8984V28.2656H10.4141L10.3906 26.8125V26.0625L10.4141 25.875Z" fill="#26A66B" />
                <path d="M18.1953 34.7109H23.5625L23.5859 34.7344V37.1016L23.5156 37.125L17.9844 37.1016V34.7344L18.1953 34.7109Z" fill="#26A66B" />
                <path d="M18.0078 25.875H23.5859L23.6094 25.8984L23.5859 28.2656H17.9844V25.8984L18.0078 25.875Z" fill="#26A66B" />
                <path d="M10.5781 34.7109H15.6875L16.0156 34.7344V37.1016L15.9453 37.125L10.4141 37.1016L10.3906 36.9375L10.4141 34.7344L10.5781 34.7109Z" fill="#26A66B" />
                <path d="M25.625 25.2422H25.6484L25.6953 25.5469L25.7188 26.0156L25.7422 27.375V27.4453V27.8203L25.7656 27.8906L25.7422 28.125V29.0391L25.7656 29.1562L25.7422 29.25L25.7656 29.4375V29.6953L25.7422 29.7891L25.7656 29.8359L25.7422 30L25.7656 30.1875L25.7422 30.2812V31.2656L25.7656 31.6875V32.1094L25.7422 32.25L25.7656 32.4141L25.7422 32.5312L25.7656 32.7188L25.7422 32.8125V33.2578L25.7656 33.375L25.7422 33.5156L25.7656 33.6562L25.7422 33.75L25.7656 33.7969V34.1953H25.7188L25.7656 34.3828L25.7422 34.5L25.7656 34.6875L25.7422 34.7812L25.7188 35.0156L25.7656 35.0391L25.7422 35.2734V36.7734L25.7188 37.1016L25.6953 37.875L25.6484 38.0859L25.625 38.2266H25.6016V36.9844L25.625 25.2422Z" fill="#1C8758" />
                <path d="M18.0078 30.2812H18.0781L18.1016 30.3984L18.0547 30.4688V30.6328L18.1016 30.7031L18.1484 30.9609V31.1719L18.1719 31.5V32.2031L18.1484 32.25L18.125 32.5547L18.1484 32.7188H18.0078V30.2812Z" fill="#1C8758" />
                <path d="M10.4609 30.5156H10.5078V30.7031L10.5547 30.9609V31.125L10.5781 31.1953L10.5547 31.3125L10.5781 31.3828L10.5547 31.4531L10.5781 31.5703V31.7812L10.6016 31.875H10.5547L10.5781 32.0156L10.5547 32.25L10.5312 32.3672L10.5547 32.5547L10.5781 32.6953H10.7422V32.7188H10.4375V30.5859L10.4609 30.5156Z" fill="#1C8758" />
                <path d="M18.0078 26.1328L18.0312 26.1797L18.0781 26.2031V26.3438L18.125 26.4609L18.1484 26.8359V27.7031L18.125 27.9375V28.2422H18.0078V26.1328Z" fill="#1C8758" />
                <path d="M18.0078 35.0625L18.0547 35.1562L18.125 35.3203L18.1484 35.6484V36.2344L18.125 36.3281L18.1719 36.3516L18.125 36.6328L18.1016 36.7969L18.0547 36.9375L18.0781 37.0547L18.0078 37.0781V35.0625Z" fill="#1C8758" />
                <path d="M10.4609 26.1797L10.5078 26.2031L10.5547 26.9297V27.6797L10.5312 27.9141V28.2188L10.4375 28.2422V26.2266L10.4609 26.1797Z" fill="#1C8758" />
                <path d="M10.4375 35.2266H10.4844L10.5312 35.3438L10.5547 36.0938V36.5156L10.5078 36.8438L10.4375 36.8672V35.2266Z" fill="#1C8758" />
                <path d="M23.3516 30.2578H23.5859L23.5625 30.4688L23.5156 30.5156L23.375 30.4219L23.1406 30.3047V30.2812L23.3516 30.2578Z" fill="#1D8859" />
                <path d="M15.6172 30.2812H15.9922L15.9688 30.4922H15.8984L15.8281 30.4453V30.3984H15.7109L15.7344 30.3516L15.6172 30.3047V30.2812Z" fill="#1C8758" />
                <path d="M10.4141 26.2266L10.4609 26.3203L10.4375 27.5391H10.4141L10.3906 26.8125V26.25L10.4141 26.2266Z" fill="#43B27F" />
                <path d="M15.1719 25.8984H15.9922L15.9688 26.0391L15.8047 26.0156L15.7812 25.9688L15.8047 25.9219H15.1719V25.8984Z" fill="#1C8758" />
                <path d="M22.7188 25.8984H23.5859L23.5391 26.0391L23.4219 26.0156L23.375 25.9688L23.3984 25.9219H22.7188V25.8984Z" fill="#1C8758" />
                <path d="M23.375 34.7344H23.5859V34.8281H23.5391L23.5156 34.8984L23.3984 34.875L23.2344 34.7812V34.7578L23.375 34.7344Z" fill="#1E8A5A" />
                <path d="M15.5469 34.7578H15.9922L15.9688 34.875L15.875 34.8984L15.7344 34.8516V34.7812H15.5469V34.7578Z" fill="#1C8758" />
                <path d="M23.4922 32.5547H23.5391L23.5625 32.7188H23.4219V32.6719H23.4688L23.4922 32.5547Z" fill="#1C8758" />
                <path d="M10.4141 25.8984L10.5547 25.9219V25.9922L10.4609 26.0391L10.4141 25.8984Z" fill="#1C8858" />
            </g>
            <defs>
                <clipPath id="clip0_310_1906">
                    <rect width="41" height="48" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}