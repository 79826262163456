import React from 'react'

export function MInvoiceSent() {
    return (


        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 13.8393V11.1607H13.5354V9L17 12.5L13.5354 16V13.8393H10Z" fill="#E69B0E" />
            <path d="M14.625 1.5H3.375C2.325 1.5 1.5 2.325 1.5 3.375V14.625C1.5 15.675 2.325 16.5 3.375 16.5H8V15H3.375C3.15 15 3 14.85 3 14.625V3.375C3 3.15 3.15 3 3.375 3H14.625C14.85 3 15 3.15 15 3.375V8H16.5V3.375C16.5 2.325 15.675 1.5 14.625 1.5Z" fill="#E69B0E" />
            <path d="M12 4.875H4.5V6.375H12V4.875Z" fill="#E69B0E" />
            <path d="M9 7.875H4.5V9.375H9V7.875Z" fill="#E69B0E" />
        </svg>


    )
}

