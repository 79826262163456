import React from 'react';

export function MSendClock(props: any) {
    return <React.Fragment>

        <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1.5V6.75L7.85714 8.25L1 9.75V15L6.53905 12.6675C6.85283 13.769 7.52353 14.7395 8.44911 15.4313C9.3747 16.1231 10.5045 16.4984 11.6667 16.5C13.0812 16.5 14.4377 15.9469 15.4379 14.9623C16.4381 13.9777 17 12.6424 17 11.25C17 9.85761 16.4381 8.52226 15.4379 7.53769C14.4377 6.55312 13.0812 6 11.6667 6L1 1.5ZM11.6667 7.5C12.677 7.5 13.646 7.89509 14.3604 8.59835C15.0748 9.30161 15.4762 10.2554 15.4762 11.25C15.4762 12.2446 15.0748 13.1984 14.3604 13.9017C13.646 14.6049 12.677 15 11.6667 15C10.6563 15 9.68735 14.6049 8.97293 13.9017C8.2585 13.1984 7.85714 12.2446 7.85714 11.25C7.85714 10.2554 8.2585 9.30161 8.97293 8.59835C9.68735 7.89509 10.6563 7.5 11.6667 7.5ZM10.9048 8.625V11.625L13.1905 13.125L13.7619 12.1875L12.0476 11.0625V8.625H10.9048Z" fill="#FF5100" />
        </svg>


    </React.Fragment>;
}


