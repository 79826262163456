import { Theme } from '@mui/material/styles';

import { makeStyles } from '@mui/styles';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles((theme: Theme) => ({

    taskList: {
        '& .MuiAutocomplete-input': {
            padding: '0px !important'
        },
    },
    mblDialogTitle: {
        '& h2': {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            fontFamily: "Poppins",
            fontSize: '36px',
            lineHeight: '40px',
        },
        '& svg': {
            fill: '#6B778C',
        },
    },
    radioBtn: {
        fontSize: 13,
        color: '#A0A5B9',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',

        '& .MuiFormControlLabel-root': {
            width: '100%'
        },
        '& .MuiTypography-root': {
            display: 'inline-block',
            overflow: ' hidden',
            whiteSpace: 'nowrap',
            color: '#3A3A3A',
            fontFamily: 'Poppins',
            fontWeight: 400,
            textOverflow: 'ellipsis',
            maxWidth: '7vw',

        },
        '& .MuiBox-root': {
            '&:nth-child(2)': {
                textOverflow: 'ellipsis',
                maxWidth: '3.604vw',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
            }

        }
    },
    radioOptions: {
        '& .MuiSvgIcon-root': {
            height: 18,
            width: 18
        }
    },
    servicesParsableListingBox: {

        borderRadius: '0px 0px 5px 5px',
        // minHeight: 400,

        overflowX: 'auto',
    },
    seachInput: {
        backgroundColor: '#ebeced',
        borderRadius: 5,
        '& .MuiFormLabel-root': {
            transform: 'translate(9px, 8px) scale(1)',
            fontSize: 14,
            marginTop: 5,
            // [theme.breakpoints.down('xl')]: {
            //     fontSize: 16,
            //     transform: 'translate(8px, 20px) scale(1)'
            // },
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(7px, -7px) scale(0.75) !important',
        },
        '& .MuiInputBase-input': {
            padding: '10px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #dfdfdf !important'
        },
        '& .MuiInputBase-colorSecondary.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #dfdfdf !important'
        },
        '& input::placeholder': {
            color: '#6b778c',
            fontSize: 13,
            fontFamily: 'Poppins',
            opacity: 1,
            [theme.breakpoints.down('xl')]: {
                fontSize: 16
            },
        },
        '& .MuiSvgIcon-root': {
            color: '#585858',
            width: 18,
            height: 18,
            marginRight: -3
        },
        // '& fieldset': {
        //     border: 'none'
        // }
    },
    autoCompleteSizeSm: {
        backgroundColor: '#ebeced',
        borderRadius: 3,
        '& .MuiFormLabel-root': {
            fontSize: 13,
            marginTop: '0px',
            transform: 'translate(8px, 12px) scale(1)',
            // [theme.breakpoints.down('xl')]: {
            //     fontSize: 16,
            //     transform: 'translate(8px, 20px) scale(1)'
            // },
            '&.MuiFormLabel-filled,&.Mui-focused': {
                display: 'none'
            }
        },

        '& .MuiAutocomplete-inputRoot': {
            minHeight: 32,
            paddingTop: '0px !important',

        },

        '& .MuiInputBase-root': {
            padding: '0px !important',
            paddingTop: '6px !important',
            paddingBottom: '6px !important',
        },

        '& .MuiAutocomplete-tagSizeSmall': {
            maxWidth: 'calc(100% - 42px)',
        },
        '& .MuiAutocomplete-input': {
            fontSize: 14,

        },
        editButton: {
            fontSize: 12,
            fontFamily: 'Poppins',
            height: 27,
            width: 105,
            marginLeft: 12,
        },
        uploadBtn: {
            height: 38,
            marginLeft: 15,
            width: 105,
            padding: 0,
            backgroundColor: '#EBECED',
            color: '#42526E',
            fontWeight: 600,
            '& .MuiButton-startIcon': {
                marginLeft: 0,
                marginRight: 4,
                '& svg': {
                    fill: '#42526E',
                    width: 18,
                    // marginRight: 5
                }
            },
            '&:hover': {
                background: '#dfdfdf'
            },

        },
        '& .MuiInputBase-colorSecondary.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #6b778c'

        },
        '& legend': {
            width: 0
        },
        '& fieldset': {
            // border: 'none'
        }
    },
    selectedAutoComplete: {
        backgroundColor: '#6b778c',
        '& svg': {
            color: '#fff'
        },
        '& input': {
            color: '#fff'
        }
    },
    bulkActionWrap: {

        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        // borderRadius:4,
        // background: 'linear-gradient(to right, #dddedf 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(#dddedf 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(to right, #dddedf 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(#dddedf 50%, rgba(255, 255, 255, 0) 0%)',
        // backgroundPosition: 'top, right, bottom, left',
        // backgroundRepeat: 'repeat-x, repeat-y',
        // backgroundSize: '10px 1px, 1px 10px'
    },
    searchBtn: {
        height: 32,
        padding: '20px'
        // [theme.breakpoints.down('xl')]: {
        //     height: 46,
        //     flex: 1
        // },

    },
    linkGrey: {
        [theme.breakpoints.down('xl')]: {
            height: 46,
            padding: 20
        },

    },
    bulkBtn: {
        height: 32,
        borderRadius: 4,
        backgroundColor: '#F5F6F7 !important',
        marginRight: 8,
        color: '#42526E',
        boxShadow: 'none !important',
        padding: '0px 4px',
        '& svg path': {
            fill: '#42526E'
        },
        '&:hover': {
            background: '#dfdfdf !important'
        },
    },
    mainBoxWrap: {
        display: 'flex',

        '& .Mui-checked': {

            color: '#6CDBA6 !important',
        },

        '& > .MuiBox-root': {
            '&:first-child': {
                // flex: '1 1 0',
                '@media (max-width:1219px)': {
                    flex: '2 1 0'
                },
                '@media (min-width:1220px) and (max-width:1740px)': {
                    flex: '1 1 0'
                }
            },
        }

    },

    mainBoxWrapOffline: {
        display: 'flex',

        // background: '#FEF9F9',
        '& .Mui-checked': {

            color: '#FF5100 !important',
        },
        '& > .MuiBox-root': {
            '&:first-child': {
                [theme.breakpoints.down('md')]: {
                    width: '100%'
                }
            }
        }

    },
    fileBoxWrap: {
        height: 75,
        background: '#fff',
        border: '1px solid #F4F5F7',

        // boxShadow: '0px 0px 30px rgba(214, 215, 226, 0.3)',
        borderRadius: 4,
        transition: 'all .3s',
        display: 'flex',
        // justifyContent: 'space-evenly',
        alignItems: "center",
        gap: 8,
        minWidth: 330,
        // maxWidth: 330,
        '&.hoverEffect': {
            background: '#F7F8FC',
            // border: '1px solid #CDD3EA'
        }
    },
    checkBox: {
        '& .MuiSvgIcon-root': {
            width: 18,
            height: 18
        },

    },
    servicesBox: {
        gap: '6px',
        marginTop: 1
    },
    serviceWrap: {
        border: 'none',
        borderLeft: 'none',
        borderRadius: '0 4px 4px 0'
        // boxShadow:'none'
    },
    fileSection: {
        width: '100%',
        height: 75,
        display: 'flex',
        alignItems: 'center',
        paddingRight: '20px',
        justifyContent: 'space-between',
        gap: '8px',
        boxShadow: '5px 0px 5px rgba(214, 215, 226, 0.2)',
        '& .MuiFormControlLabel-root': {
            marginRight: 6
        },
        '& .Mui-disabled': {

            pointerEvents: "auto",
            cursor: 'no-drop !important',
            color: 'rgba(0, 0, 0, 0.26) !important',
            '&:hover': {
                backgroundColor: '#ECEDF0',
                color: '#42526E',
                '& svg': {
                    fill: 'currentcolor'
                }
            }

        }


    },
    disabledCheckbox: {

        '& .MuiFormControlLabel-root.Mui-disabled': {
            opacity: "0.38",
        }

    },
    userIcon: {
        width: 30,
        height: 30,
        marginRight: 8,
        '& svg': {
            fontSize: 18
        }
    },
    dateIcon: {
        fontSize: 12,
        color: '#A2A4B9',
        marginRight: 2
    },
    serviceBoxWrap: {
        minWidth: '330px',
        height: 75,
        padding: '17.5px 20px',
        borderTop: '1px solid #F4F5F7',
        borderBottom: '1px solid #F4F5F7',
        transition: 'all .3s',
        cursor: 'pointer',
        backgroundColor: '#ced4ea21',
        '@media (max-width:1900px)': {
            minWidth: '295px !important',
        },
        // '@media (min-width:1702px) and (max-width:1880px)': {
        //     minWidth: '243px !important',
        // },
        '&:last-child': {
            // borderRight: '1px solid #F4F5F7',
            borderRadius: '0 4px 4px 0',
        },
        '&:hover': {
            background: '#eff1f8 !important',
            '& .editPencil': {
                display: 'none'
            },

        },
        '&.hoverEffect': {
            // borderTop: '1px solid #CDD3EA',
            // borderBottom: '1px solid #CDD3EA',
            background: '#f6f7fb',
            '&:last-child': {
                // borderRight: '1px solid #CDD3EA',
            }
        },
        '&.toAssignBox': {
            background: 'rgba(251, 235, 237, 0.6)',
            justifyContent: 'center',
            // width: '100%',
        },


    },
    serviceTitle: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        display: 'inline-block',
        maxWidth: 153,
        '& .editPencil': {
            color: '#A0A5B9',
            width: 14,
            marginBottom: -9,
            marginLeft: 2,
            display: 'none'
        }
    },
    collapseBtn: {
        backgroundColor: '#E4EBFB !important',
        color: '#3a3a3a',
        width: '98%',
        // paddingTop: 10,
        // paddingBottom: 10,
        fontSize: 13,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        '& svg': {
            color: '#A0A5B9',
            // position: 'absolute',
            // top: ' 11px',
            // right: '8px'
        },
        '& .MuiButton-label': {

            justifyContent: 'left',
        },

    },
    collapseWrap: {
        // marginBottom: 8,
        marginRight: 8,
        // overflow:'hidden',
        transition: 'all .4s'
    },
    editButton: {
        fontSize: 12,
        fontFamily: 'Poppins',
        height: 27,
        width: 105,
        marginLeft: 12,
    },
    uploadBtn: {
        height: 38,
        marginLeft: 15,
        width: 105,
        padding: 0,
        backgroundColor: '#EBECED',
        color: '#42526E',
        fontWeight: 600,
        '& .MuiButton-startIcon': {
            marginLeft: 0,
            marginRight: 4,
            '& svg': {
                fill: '#42526E',
                width: 18,
                // marginRight: 5
            }
        },
        '&:hover': {
            background: '#dfdfdf'
        },

    },
    btnAdd: {
        // position: 'absolute',
        // top: 395,
        margin: 0,
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        width: 123,
        height: 42,
        fontWeight: 600,
        // left: 725
        '& svg': {

            fill: '#fff'

        }
    },

    filesWrapper: {
        // minHeight: 'calc(100vh - 144px)',
    },
    fileUploadCover: {
        padding: "40px 64px",
        [theme.breakpoints.down('xl')]: {
            padding: "40px 12px",
        }
    },
    fileUploadWrap: {
        textAlign: 'center',
        // width:540,
        '& img': {
            width: '100%',
            paddingTop: 0
        },
        '& .dzu-dropzone': {
            minHeight: 266,
            borderStyle: "dashed",
            borderWidth: 1,

            borderRadius: '6px',
            overflow: 'visible !important',
            '& .dzu-inputLabelWithFiles': {
                margin: '0px !important',
                padding: 0,
                minHeight: 160,
                width: '100%'
            }
        },
        '& .dzu-inputLabel': {
            color: '#393939',
            display: 'flex'
        },
    },
    fileUploadInner: {
        display: 'flex',
        justifyContent: "space-between",
        [theme.breakpoints.down('xl')]: {
            flexDirection: 'column'
        }
    },

    panelSummary: {
        flexDirection: "row-reverse",
        padding: '0 15px',
        height: 48,
        minHeight: 'auto !important',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        "& .MuiExpansionPanelSummary-expandIcon": {
            padding: "0",
            marginRight: theme.spacing(1),
            color: "#494949",
            position: 'absolute',
            right: 0
        },
        "& .MuiExpansionPanelSummary-content": {
            justifyContent: "space-between",
            alignItems: "center"
        },
    },
    panelBodyWrapper: {
        position: 'relative',
        display: "block !important",
        padding: '16px 0 20px 0px !important',
        "& .actionBtnBox": {
            position: 'absolute',
            right: 10,
            top: 14,
            "& svg": {
                color: "#a0a5b9",
            },
        },
    },

    folderWraper: {},


    createTagButton: {
        display: 'block',
        textAlign: 'left'
    },

    tagsWrap: {
        border: '1px solid #ececec',
        borderRadius: 7,
        padding: '15px 20px',
        textAlign: 'left',
        height: '100%',
        maxHeight: '80vh',
        width: 256,
        overflow: 'auto',
        [theme.breakpoints.down('xl')]: {
            width: '100%',
        },
        '& .MuiExpansionPanel-root:before': {
            display: 'none'
        },
        '& .MuiSvgIcon-root': {
            width: 18,
            height: 18
        },
        '& .Mui-checked': {
            color: '#6CDBA6 !important',
        },

        '& .Mui-disabled': {
            cursor: 'not-allowed',
            display:'flex',
            alignItems:'center',
            marginBottom:10,
        },
        // '& .MuiFormControlLabel-root.Mui-disabled': {
        //
        //         backgroundColor: '#fff !important'
        //
        // },
        // '& .MuiFormControlLabel-root': {
        //     '& .Mui-disabled':{
        //         backgroundColor: '#fff !important'
        //
        //     }
        //
        //
        // },

    },
    uploadBtnWrap: {
        width: '100%',
        paddingLeft: 16,
        [theme.breakpoints.down('xl')]: {
            paddingLeft: 0,
            paddingTop: 16,
        },
    },
    checkboxDisable: {
        cursor: 'not-allowed',
        pointerEvents: 'none',
        opacity: 0.3,

    },
    clearBtn: {
        position: 'absolute',
        top: 6,
        right: 0,
    },
    fileUploadDrawer: {
        "& .MuiPaper-root.MuiDrawer-paper": {
            minWidth: "85%",
            maxWidth: "85%",

        },
        '&.fullWidth': {
            "& .MuiPaper-root.MuiDrawer-paper": {
                transform: 'translateX(-18%) !important'
            }
        },
        '& .MuiToolbar-gutters': {
            [theme.breakpoints.up('sm')]: {
                paddingRight: 36,
            }
        },
        '& .MuiPaper-root': {
            paddingRight: '0px !important'
        }

    },
    sendDrawer: {
        "& .MuiPaper-root.MuiDrawer-paper": {
            minWidth: "100%",
            maxWidth: "100%",
        },
        '&.fullWidth': {
            "& .MuiPaper-root.MuiDrawer-paper": {
                transform: 'translateX(-18%) !important'
            }
        },
    },
    Card: {
        padding: '20px 29px',
        position: "relative",
        border: "1px solid #e3e3e3",
        boxShadow: '0px 5px 5px 0px rgba(193, 199, 208, 0.26)',
        '&:hover': {
            boxShadow: 'none',
            cursor: 'pointer',
        },
    },
    cardContent: {
        padding: '13px 28px !important',
    },
    CardIcon: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        textTransform: 'uppercase',
    },
    sideBorder: {
        width: 18,
        height: "100%",
        backgroundColor: "#d9e3fa",
        position: "absolute",
        left: 0,
        top: 0,
        bottom: 0,
    },
    projectChip: {
        fontSize: 12,
        backgroundColor: "#f1f5fd",
        minWidth: 32,
        maxHeight: 16,
        color: "#6b778c"
    },
    projectName: {
        width: 170,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    assignBoxWrap: {
        gap: 40,
        borderRadius: 5,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
        // padding: 48,
        // background: 'linear-gradient(to right, #dddedf 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(#dddedf 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(to right, #dddedf 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(#dddedf 50%, rgba(255, 255, 255, 0) 0%)',
        // backgroundPosition: 'top, right, bottom, left',
        // backgroundRepeat: 'repeat-x, repeat-y',
        // backgroundSize: '10px 1px, 1px 10px'
    },
    timeIcon: {
        width: 12,
        marginRight: 2,
        marginTop: 1
    },
    assignSmBtn: {
        width: 61,
        height: 26
    },
    cancelBtn: {
        border: 'none',
        '&:hover': {
            backgroundColor: 'rgba(58, 58, 58, 0.04)'
        }
    },
    updateBtn: {
        minWidth: 106
    },
    switchBtn: {
        // position: 'absolute',
        // right: 5,
        // top: 7,
        '& .MuiSwitch-root': {
            height: 43
        },
        '& .MuiSwitch-track': {
            backgroundColor: '#A0A5B9',
            height: 16,
            opacity: 1,
            borderRadius: 20
        },
        '& .MuiSwitch-thumb': {
            boxShadow: 'none',
            width: 10,
            height: 10
        },
        '& .MuiSwitch-switchBase': {
            top: 6,
            left: 6
        },
        '& .MuiSwitch-colorPrimary.Mui-checked': {
            left: 3
        }
    },
    modalForm: {
        background: '#FDFDFD',
        border: '1px solid #E8E8E8',
        borderRadius: 5,
        margin: 'auto',
        padding: '44px 48px 48px',
        '& .MuiBox-root-723': {
            [theme.breakpoints.down('xl')]: {
                width: '100%',
            },
        }
    },
    formLabel: {
        fontSize: 15,
        color: theme.palette.text.primary,
        marginBottom: 10
    },
    inputWidth: {
        width: '100%',
        '& fieldset': {
            border: '1px solid #dfdfdf !important',
        },
        '& textarea,& input': {
            '&::placeholder': {
                fontSize: 14,
                color: '#9c9c9c',
                opacity: 1
            }
        },
        '& input': {
            paddingTop: '8.5px',
            paddingBottom: '12.5px',
        },
    },
    fieldCover: {
        display: 'flex',
        marginBottom: 24,
        justifyContent: 'space-between',
        alignItem: 'center',
        [theme.breakpoints.down('xl')]: {
            flexDirection: 'column',
            marginBottom: 0,
        },
    },
    autoCompleteWrap: {
        [theme.breakpoints.down('xl')]: {
            width: '100%',
            marginBottom: 24,
        },
    },
    autoComplete: {
        '& fieldset': {
            border: '1px solid #a0a5b9',
        },
        '& .MuiAutocomplete-input': {
            padding: '2.5px 9px 6.5px !important'
        },
        '& .MuiOutlinedInput-input': {
            padding: '2.5px 9px 6.5px !important'
        },
        '& .MuiOutlinedInput-root': {
            minHeight: 40
        }
    },
    vendorBoxWrap: {
        background: '#FFF',
        height: 70,
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #F4F5F7',
        boxShadow: '0px 0px 30px rgba(214, 215, 226, 0.3)',
        borderRadius: 4,
        padding: '0 12px',
        transition: 'all .3s',
        cursor: 'pointer',
        '&:hover': {
            background: '#F7F8FC',
            // border: '1px solid #CDD3EA'
        },
        '&.active': {
            background: '#F7F8FC',
            // border: '1px solid #CDD3EA'
        },
        '& .MuiSvgIcon-root': {
            width: 18,
            height: 18
        },
        '& .MuiFormControlLabel-root': {
            margin: '0px !important'
        }
    },
    servicesListing: {
        backgroundColor: '#fff',
        boxShadow: '0px 5px 5px 0px rgba(193, 199, 208, 0.26)',
        borderRadius: 5,
    },
    workFlowDrawer: {
        "& .MuiPaper-root.MuiDrawer-paper": {
            minWidth: "85%",
            maxWidth: "85%",
        },
    },
    downloadMenu: {
        marginTop: 34,
        marginLeft: -21,
        '& .MuiListItem-root': {
            minWidth: 180,
        },
        '& .MuiPaper-root': {
            boxShadow: '0px 0px 30px #d6d7e2 !important',
            border: 'none'
        }
    },
    progressMenu: {
        marginTop: 34,
        marginLeft: -60,
        '& .MuiListItem-root': {
            minWidth: 191,
        },
        '& .MuiPaper-root': {
            boxShadow: '0px 0px 30px #d6d7e2 !important',
            border: 'none'
        }
    },
    status: {
        display: 'flex',
        fontSize: 11,
        height: 28,
        borderRadius: 14,
        alignItems: 'center',
        textTransform: "capitalize",
        '&.pending': {
            // backgroundColor: '#f9f5e7',
            color: '#A0A5B9',
        },
        '&.rejected': {
            // backgroundColor: '#ffe8e8',
            color: '#ff0102',
        },
        '&.approved': {
            // backgroundColor: '#dff5eb',
            color: '#16c68e',
        },
        '&.completed': {
            // backgroundColor: '#dff5eb',
            color: '#16c68e',
        },
        '&.created': {
            // backgroundColor: '#e5f1f7',
            color: theme.palette.secondary.main
        },
        '&.dispatched': {
            // backgroundColor: '#F3EEED',
            color: '#7F3E3B',
        },
        '&.bidding': {
            // backgroundColor: '#EBF3F2',
            color: '#E69B0E',
        },
        '&.complete': {
            // backgroundColor: '#DFF5EB',
            color: '#16C68E',
        },
        '&.assigned': {
            // backgroundColor: '#E5F1F7',
            color: '#3F74E8',
        },
        '&.sendPO': {
            // backgroundColor: '#e69b0e75',
            color: '#fff',
        },
        '&.invoiceReview': {
            // backgroundColor: '#e69b0e75',
            color: 'rgb(102, 101, 221)',
        },
        '&.invoiceApproved': {
            // backgroundColor: '#e69b0e75',
            color: '#16C68E',
        },
        '&.invoicePending': {
            // backgroundColor: '#e69b0e75',
            color: '#A0A5B9',
        },
        '&.invoicePaid': {
            // backgroundColor: '#e69b0e75',
            color: 'rgb(63, 116, 232)',
        },
        '&.general': {
            '& svg': {
                fontSize: 17,
                marginRight: 4
            },
        },

    },
    jobList: {
        maxWidth: 1000,
        flex: '2 1 0',
        '@media (max-width:1219px)': {
            flex: '1 1 0'
        },
        '@media (min-width:1220px) and (max-width:1740px)': {
            flex: '1 1 0'
        },
        // [theme.breakpoints.down('xl')]: {
        //     flex: '1 1 0'
        // },


    },
    vendorAssignAvatar: {
        width: 30,
        height: 30,
        margin: '4px 8px 5px 9px',
        '& img': {
            width: '100%'
        }
    },
    downloadBtn: {
        '& .MuiPaper-root': {
            width: 180,
            '& .MuiList-root': {
                '& .MuiButtonBase-root': {
                    fontSize: 14,
                    fontWeight: 400,
                    // lineHeight: 18.62,
                    color: '#3A3A3A',

                }
            }
        }

    },
    dDropdown: {
        // '& .MuiPaper-root':{
        //     top: '396px !important'
        // }
    },
    assignAvatar: {
        // border:'1px solid',
        // borderRadius:20,
        '&:hover': {
            cursor: 'pointer',
        },

        '& .MuiAvatar-root': {
            // width: 30,
            // height: 30,
            marginRight: 5
        },
        // '& .MuiSvgIcon-root':{
        //     width:19,
        //     height:19
        // }
    },
    avatarBox: {
        border: '1px solid #BCBFC1',
        borderRadius: 20,
        padding: '5px 10px 5px 5px',


        marginBottom: 5,
        marginRight: 5,
        height: 'auto',
        position: 'relative',
        '&:hover': {
            cursor: 'pointer',
        },

        '& .MuiAvatar-root': {
            width: 28,
            height: 28,
            marginRight: 5
        },
        // '& .MuiSvgIcon-root':{
        //     width:19,
        //     height:19
        // }
    },
    deleteIcon: {
        width: 15,
        height: 15,
        display: 'flex',
        fontWeight: 700,
        marginLeft: 5,
    },
    listDownload: {
        '& .MuiButtonBase-root': {
            fontSize: 14
        }
    },
    jobListExtend: {
        '& .servicesTop': {
            minWidth: '300px !important',
            flex: '1 1 0',
            [theme.breakpoints.down('xl')]: {
                minWidth: '295px !important',
            }

        },
        '& $collapseWrap': {
            '& $serviceWrap': {
                '& $serviceBoxWrap': {
                    minWidth: '300px',
                    flex: '1 1 0',
                }
            }
        }


    },
    servicesContainer: {
        [theme.breakpoints.down('xl')]: {
            minWidth: '295px',
        },

    },
    assignBox: {
        padding: 25,
        border: '1px dashed #E8E8E8',
        backgroundColor: '#ffff',
        borderRadius: 4,
        '& .MuiSvgIcon-root': {
            width: '18px !important',
            height: '18px !important'
        },
        '& .Mui-disabled': {
            cursor: 'not-allowed'
        },
        '& .MuiFormGroup-root': {
            '& .Mui-disabled': {
                backgroundColor: '#fff !important'
            }
        },




    },
    input: {
        '& .MuiInputBase-input': {
            width: 305,
            // height:3
        }
    },
    labelBatch: {
        fontSize: 15,
        fontWeight: 500,
        color: '#3A3A3A',
        fontFamily: 'Poppins',
        margin: 0

    },
    dotIcon: {
        fontSize: 10,
        color: '#bfc1cd',
        marginLeft: 14,
        marginRight: 8,
    },
    AssignLang: {
        backgroundColor: '#F7F8FC',
        border: '1px solid #E2E6F8',
        padding: '25px 25px 35px 35px',
        borderRadius: 4
        // margin: '2px 45px',
    },
    targetLanguage: {
        '& .MuiTypography-root': {
            fontSize: 16,
            fontFamily: 'Poppins',
            color: '#6B778C'

        },

    },
    selectDate: {
        // marginBottom: 20,
        '& .MuiFormControl-root': {
            width: '100%',
            margin: 0,
            marginBottom: 10,
            '& .MuiInputBase-input': {
                padding: '10.5px 14px'
            },
            '& .MuiInput-underline:before': {
                border: '1px solid #A0A5B9',
                height: 40,
                borderRadius: 4,
                // width:305
            },
        },
        '& .MuiInput-underline:after': {
            borderBottom: '1px solid #A0A5B9 !important'
        },
        '& span': {
            fontSize: 12,
            fontWeight: 600,
            color: '#01579b'
        }

    },

    footerAssign: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        backgroundColor: '#fff'
    },
    hr: {
        height: 23,
        width: 1,


    },
    valCounter: {
        backgroundColor: "#9FA5B9",
        height: 24,
        width: 24,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        borderRadius: 1000,
        marginRight: 8,
    },
    AssignListHeader: {
        '& .MuiBox-root': {
            flex: '1 1 20%'
        }
    },
    langName: {
        maxWidth: 270,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    // assignList:{
    //     maxHeight:500,
    //     overflowY: 'auto',
    //     marginBottom: 45,
    // },
    footerPO: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        width: '85%',
        borderTop: '1px solid #DCDDDE',
        backgroundColor: '#fff',
        paddingRight: 47,
        paddingTop: 29,
        paddingBottom: 29,


    },

    footerBottom: {
        position: 'fixed',
        bottom: 0,
        left: '15%',
        width: '85%',
        borderTop: '1px solid #DCDDDE',
        backgroundColor: '#fff',
        paddingRight: 46,
        paddingTop: 15,
        paddingBottom: 15,


    },
    footerInvoiceDetail: {
        position: 'fixed',
        bottom: "0%",
        right: 0,
        width: '85%',
        borderTop: '1px solid #DCDDDE',
        backgroundColor: '#fff',
        paddingRight: 46,
        paddingTop: 60,
        paddingBottom: 15,


    },
    btnPdf: {
        backgroundColor: '#676767',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#8b8f95',
        }
    },
    btnReject: {
        backgroundColor: '#f44336',
        color: '#fff',
    },
    fileName: {
        fontSize: 14,
        color: '#3A3A3A !important',
        fontFamily: "Poppins",
        // maxWidth: 335,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'


    },
    serviceNotAvailable: {
        backgroundColor: '#ECEFF6 !important',
        '& svg': {
            fill: '#A3A8BC',
            height: 18,
            width: 18,
            marginRight: 5,
        }
    },
    btnFooter: {
        position: 'fixed',
        bottom: 20,
        right: 36,

    },
    btnMark: {
        minWidth: 142,
        marginLeft: '10px',
        backgroundColor: '#00695C',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#6e8985',
            color: '#fff',
            pointer: 'hand',
        }
    },
    selectBox: {
        width: '31vw',
        margin: 'auto',
        padding: '22px',
        border: '1px solid #E8E8E8',
        borderRadius: '5px',
        marginTop: 100,
        '& .MuiFormLabel-root': {
            marginBottom: 5,
            color: '#3a3a3a',
            fontSize: 13,
        }
    },
    invoiceDetails: {
        '& .MuiOutlinedInput-multiline': {
            padding: '7px 5px'
        }
    },

    paymentContainer: {
        width: '100%',
        maxWidth: '730px',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    paymentDetsils: {
        background: '#FDFDFD',
        padding: '20px 24px',
        border: '1px solid #E8E8E8',
        boxShadow: '0px 0px 30px rgba(214, 215, 226, 0.3)',
        borderRadius: 4
    },
    paymentOptions: {
        gap: 15,

    },
    cardBalance: {
        justifyContent: 'center',
        position: 'relative',
        '&:not(:last-child):before': {
            // '&:not(:last-child)': {

            content: "''",
            width: 2,
            height: 45,
            position: 'absolute',
            borderRightWidth: 0,
            backgroundColor: '#E8E8E8',
            display: 'inline-block',
            right: '-36px'

            // }
        },
        '& svg': {
            width: 40,
            height: 40,
        },
        '& .MuiSvgIcon-root': {
            marginLeft: 'auto',
            fill: '#A0A5B9',
        }
    },
    active: {
        border: '2px solid #16C68E !important',
        position: 'relative'
    },
    hide: {

        flex: ' 1 1 30%',
        display: 'flex',
        justifyContent: 'start',
        border: '2px solid #eeeeee',
        padding: '5px 0px',
        alignItems: 'center',
        backgroundColor: '#eeeeee',
        boxShadow: '0px 0px 30px rgb(214 215 226 / 3%)',
        borderRadius: 4,
        marginBottom: 5,
        position: 'relative',
        height: '100%',
        minHeight: 55,
        '&:hover': {
            // border: '2px solid #16C68E',
            cursor: 'pointer',
            backgroundColor: '#e2e2e2'
        },
        '& svg': {
            height: 30,
            width: 30
        }

    },
    activeIcon: {
        position: 'absolute',

        top: ' -13px',
        right: '-10px',
        backgroundColor: '#fff',
        height: 30,
        width: 30,
        borderRadius: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& .MuiSvgIcon-root': {
            fill: '#fff'
        },
        '& >.MuiBox-root': {
            backgroundColor: '#16C68E',
            height: 25,
            width: 25,
            borderRadius: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }
    },
    addPayment: {},
    test: {
        '& .paypal-button .paypal-button-shape-rect': {
            border: '1px solid !important'
        }
    },

    assignSelect: {
        margin: '0px 0px 6px 0px !important',
        '& .MuiTypography-root': {
            fontSize: '14px !important',
            // lineHeight: 1
            color: '#758094',

        },
        '& .MuiButtonBase-root': {
            padding: '0px 9px 0px 0px !important',
        },

    },
    assignSelectBold: {
        margin: '0px 0px 6px 0px !important',
        '& .MuiTypography-root': {
            fontSize: '14px !important',
            // lineHeight: 1
            color: '#758094',
            fontWeight: 600
        },
        '& .MuiButtonBase-root': {
            padding: '0px 9px 0px 0px !important',
        },

    },
    enable: {},
    disabled: {
        backgroundColor: '#fff !important',

    },
    timePicker: {
        '& .MuiInput-underline:before': {
            background: "url('/assets/images/clock.png') no-repeat",
            backgroundPosition: '96%',
            cursor: 'pointer',
            pointerEvents: 'auto'
        }
    },
    assignBadge: {
        position: 'absolute',
        right: 25,
        top: 25
    },
    deadlineTime: {
        '& .MuiDialog-container': {
            backgroundColor: 'green'
        },

    },
    btnPreview: {

        backgroundColor: '#F5F6F7',
        fontWeight: 600,
        color: '#42526E',
        fontSize: 12,
        padding: '5px 12px',
        borderRadius: '5px',
        '& svg': {
            fill: 'currentcolor'
        },
        '&:hover': {
            backgroundColor: '#FF5100',
            color: '#fff',
            '& svg': {
                fill: '#fff'
            }
        }
    },
    btnSplit: {

        backgroundColor: '#F5F6F7',
        fontWeight: 600,
        color: '#42526E',
        fontSize: 12,
        padding: '5px 12px',
        borderRadius: '5px',
        '& svg': {
            fill: 'currentcolor'
        },
        '&:hover': {
            backgroundColor: '#FF5100',
            color: '#fff',
            '& svg': {

                fill: '#fff'


            }
        }
    },
    contentBox: {
        borderBottom: "2px solid #ECECEC",
        backgroundColor: '#ee4848'
    },
    segmentList: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 0px 32px 0px rgba(214, 215, 226, 0.3)',
        borderRadius: 4,
        marginBottom: 10,
        padding: '10px 20px',


    },
    wrapperSegment: {
        maxHeight: '450px',
        overflow: 'auto',
    },
    segmentCount: {

        padding: '5px 5px 5px 16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        borderRadius: '4px',
        gap: 5,
        '& .MuiInputBase-input': {
            backgroundColor: '#F7F8FC',
            maxWidth: '100px',
            overflow: 'hidden',
            textOverflow: 'ellipsis'

        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none !important'
        }
    },
    backdrop: {
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        inset: '0px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 10000,
        transition: 'opacity 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',

    },
    dialog: {
        position: 'fixed',
        backgroundColor: '#f7f8fc',
        borderRadius: 5,
        top: '50%',
        left: '50%',
        zIndex: 1000,
        padding: 15,
        width: '100%',
        maxWidth: '960px',
        transform: 'translate(-50%, -50%)',
        transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms'
    },
    heading: {
        backgroundColor: "#E4EBFB",
        padding: '8px 16px',
        fontSize: 14,
        fontFamily: 'Poppins Medium',
        maxWidth: '28%',
        borderRadius: '4px'
    },
    accordionMargin: {
        marginTop: 16
    },

    accordionWrap: {

        filter: 'drop-shadow(0 0 16px rgba(214,215,226,0.3))',
        border: '1px solid #f4f5f7',
        backgroundColor: '#e4ebfb',
        borderRadius: '4px',
        boxShadow: '0px 5px 5px 0px rgba(193, 199, 208, 0.26)',
        marginBottom: 10,
        transition: "all .3s",
        '&:not(.Mui-expanded):hover': {
            boxShadow: 'none',
            filter: 'initial',
            '& .MuiAccordionSummary-root': {
                backgroundColor: '#f7f8fc',
            }
        },
        '& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded': {

            minHeight: "57px !important",
            maxHeight: "57px !important",
            borderRadius: 5,
            // backgroundColor: '#e4ebfb',
            margin: 0
        },
        '& .MuiButtonBase-root.MuiAccordionSummary-root': {
            '&:hover:not(.Mui-disabled)': {
                boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.05), 0px 4px 5px 0px rgba(0,0,0,0.05), 0px 1px 10px 0px rgba(0,0,0,0.05) !important",
                backgroundColor: "#E4EBFB !important"
            }


        },

        '& .MuiAccordionDetails-root': {
            backgroundColor: '#fff',
            paddingTop: 0
        },
        '&::before': {
            display: 'none'
        },
        '& .MuiAccordionSummary-root>.MuiButtonBase-root': {
            marginRight: -5,
            transform: 'rotate(-90deg)',
            '&.Mui-expanded': {
                transform: 'rotate(0deg)'
            }
        }
    }




}),
);

export default useStyles;