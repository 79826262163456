import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';
// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formBox: {
      backgroundColor: 'white',
      boxShadow: '0px 5px 5px 0px rgba(193, 199, 208, 0.26)',
      borderRadius: 5,
      '& .MuiInputBase-input:not(.MuiAutocomplete-input)': {
        paddingTop: '9.5px !important',
        paddingBottom: '11.5px !important'
      },
      '& .MuiAutocomplete-input': {
        padding: '3.5px 6px 5.5px !important'
      }
    },
    formLabel: {
      fontSize: 14,
      color: theme.palette.text.primary,
    },
    inputWidth: {
      width: '100%',
      '& fieldset': {
        border: '1px solid #a0a5b9',
      }
    },
    addSelected: {
      '& .MuiInputBase-input': {
        backgroundColor: '#ebebeb',
        borderRadius: '5px',
        maxWidth: ' 98px',
      },
      '& .MuiInputBase-input[value=""]': {
        backgroundColor: '#fff',
        borderRadius: '5px',
        maxWidth: ' 100%',
      }
    },
    wrapperSelectedField: {
      '& .css-1xnbq41-MuiAutocomplete-root': {
        '&:hover': {
          '& .MuiAutocomplete-clearIndicator': {
            visibility: 'visible '
          }
        }
      }
    },
    addServiceButton: {
      backgroundColor: theme.palette.primary.main,
      padding: '8px 15px',
      borderRadius: 4,
      color: '#fff',
      '&:hover':{
        backgroundColor: '#00695c',
              }
    },
    overviewChipStyle: {
      backgroundColor: "#eceff1",
      height: '32px',
      borderRadius: '16px',
      fontSize: '12px',
      lineHeight: '28px',
      color: '#464555',
      fontFamily: "Poppins Emoji",
    },
    overviewChipIcon: {
      marginLeft: "18px",
      height: '16px',
    },
    footerBottom: {
      display: 'flex',
      justifyContent: 'flex-end',
      position: 'fixed',
      bottom: 0,
      left: '15%',
      width: '85%',
      padding: '10px 27px 10px 0px',
      backgroundColor: '#fff',
      borderTop: '1px solid #dbdbdb',

    },
    modalForm: {
      // width: 636,
      background: '#FDFDFD',
      // border: '1px solid #E8E8E8',
      // borderRadius: 5,
      marginTop: '5%',
      padding: '44px 48px 48px',
      border: '1px solid #E8E8E8',
      backgroundColor: '#FDFDFD',
      borderRadius: 5,
      '& .MuiFormHelperText-contained.Mui-error': {
        bottom: "-21px !important",
      },
    },
    fileUploadDrawer: {
      "& .MuiPaper-root.MuiDrawer-paper": {
        minWidth: "85%",
        maxWidth: "85%",
      },
      '&.fullWidth': {
        "& .MuiPaper-root.MuiDrawer-paper": {
          transform: 'translateX(-18%) !important'
        }
      },
    },

  }),
);

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;