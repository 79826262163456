import React, { Fragment } from 'react'

export function MMarsCredit() {
    return (
        <Fragment>

            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.66668 8.7854H31.2001C32.6721 8.7854 33.8668 10.1106 33.8668 11.7435V15.2932C33.8668 16.926 32.6721 18.2513 31.2001 18.2513H6.66668C5.19467 18.2513 4 16.926 4 15.2932V11.7435C4 10.1106 5.19467 8.7854 6.66668 8.7854Z" fill="#A86A3F" />
                <path d="M31.2001 8.7854H6.66668C5.19467 8.7854 4 10.1106 4 11.7435V12.9267C4 11.2939 5.19467 9.96863 6.66668 9.96863H31.2001C32.6721 9.96863 33.8668 11.2939 33.8668 12.9267V11.7435C33.8668 10.1106 32.6721 8.7854 31.2001 8.7854Z" fill="#975F39" />
                <path d="M11.9788 11.3708L28.6722 6.46037C29.2429 6.29472 29.8349 6.66152 29.9842 7.29455L32.7575 18.6595C32.9122 19.2866 32.5709 19.9315 32.0002 20.103L10.2615 26.4984C9.69079 26.6641 9.10412 26.2913 8.94945 25.6642L6.91211 17.3165L11.9788 11.3708Z" fill="#3DB39E" />
                <path d="M29.845 18.2927L26.2396 19.3635C25.957 19.4463 25.6636 19.2629 25.589 18.9435C25.5143 18.6299 25.6796 18.3045 25.9676 18.2217L29.061 17.3047L27.4023 10.4479L24.3089 11.3649C24.0263 11.4477 23.7329 11.2643 23.6583 10.9448C23.5836 10.6254 23.7489 10.3059 24.0369 10.2171L27.6423 9.14631C27.925 9.06348 28.2183 9.24689 28.293 9.56636L30.2236 17.565C30.2983 17.8786 30.133 18.204 29.845 18.2927V18.2927ZM14.9062 22.7357L11.3009 23.8066C11.0182 23.8894 10.7249 23.706 10.6502 23.3865L8.71955 15.3879C8.64488 15.0743 8.81022 14.7489 9.09822 14.6661L12.7036 13.5953C12.9862 13.5124 13.2796 13.6958 13.3542 14.0153C13.4289 14.3289 13.2636 14.6543 12.9756 14.7371L9.88222 15.6541L11.5356 22.5109L14.6289 21.5939C14.9116 21.5111 15.2049 21.6945 15.2796 22.014C15.3542 22.3275 15.1889 22.6529 14.9009 22.7357H14.9062Z" fill="#78CABB" />
                <path d="M4.15454 15.3702C8.90656 12.3471 21.7546 4.15909 21.7546 4.15909C22.2666 3.8337 22.9226 4.02301 23.216 4.58505L28.5493 14.7668C28.8426 15.3288 28.6666 16.0506 28.1546 16.376L8.64523 28.7999C8.13322 29.1253 7.47722 28.936 7.18389 28.3739C7.18389 28.3739 5.65855 24.8834 4.15987 22.0259V15.3702H4.15454Z" fill="#5ABEAC" />
                <path d="M11.7172 22.8067L8.94387 24.5815L5.74919 18.4346L8.52253 16.6598C8.77853 16.4941 8.86387 16.1332 8.71986 15.8493C8.57586 15.5653 8.2452 15.4706 7.9892 15.6304L4.75718 17.701C4.50118 17.8667 4.41585 18.2276 4.56518 18.5115L8.29853 25.6819C8.44253 25.9659 8.7732 26.0606 9.02387 25.9008L12.2559 23.8302C12.5119 23.6645 12.5972 23.3036 12.4532 23.0196C12.3092 22.7357 11.9785 22.641 11.7225 22.8007L11.7172 22.8067ZM25.8399 14.4471L22.1066 7.27674C21.9573 6.99276 21.6319 6.8981 21.3759 7.05784L18.1439 9.1285C17.8879 9.29415 17.8026 9.65503 17.9466 9.93901C18.0906 10.223 18.4212 10.3176 18.6772 10.1579L21.4506 8.38306L24.6506 14.53L21.8773 16.3048C21.6213 16.4705 21.5359 16.8313 21.6799 17.1153C21.8239 17.3993 22.1546 17.494 22.4106 17.3342L25.6426 15.2636C25.8986 15.0979 25.9839 14.737 25.8399 14.453V14.4471ZM13.0665 12.3824C11.0239 13.6899 10.3252 16.5829 11.5039 18.8488C12.6825 21.1146 15.2906 21.8897 17.3332 20.5822C19.3759 19.2747 20.0746 16.3817 18.8959 14.1158C17.7172 11.8499 15.1092 11.0749 13.0665 12.3824V12.3824Z" fill="#8CD2C5" />
                <path d="M32.2668 14.1099H6.13334C4.95467 14.1099 4 13.0509 4 11.7434V33.0416C4 34.6745 5.19467 35.9997 6.66668 35.9997H32.2668C33.7388 35.9997 34.9335 34.6745 34.9335 33.0416V17.068C34.9335 15.4351 33.7388 14.1099 32.2668 14.1099Z" fill="#C47E4D" />
                <path d="M34.9331 29.4921H30.6665C28.6024 29.4921 26.9331 27.6403 26.9331 25.3508C26.9331 23.0612 28.6024 21.2095 30.6665 21.2095H34.9331C35.5198 21.2095 35.9998 21.7419 35.9998 22.3927V28.3089C35.9998 28.9596 35.5198 29.4921 34.9331 29.4921Z" fill="#A86A3F" />
                <path d="M30.6665 22.9844C31.8452 22.9844 32.7999 24.0434 32.7999 25.3508C32.7999 26.6583 31.8452 27.7173 30.6665 27.7173C29.4879 27.7173 28.5332 26.6583 28.5332 25.3508C28.5332 24.0434 29.4879 22.9844 30.6665 22.9844Z" fill="#ECC35D" />
                <path d="M32.2668 14.1099H6.13334C4.95467 14.1099 4 13.0509 4 11.7434V12.9266C4 14.2341 4.95467 15.2931 6.13334 15.2931H32.2668C33.7388 15.2931 34.9335 16.6183 34.9335 18.2512V17.068C34.9335 15.4351 33.7388 14.1099 32.2668 14.1099Z" fill="#CA8B5F" />
                <path d="M32.2668 34.8167H6.66668C5.19467 34.8167 4 33.4915 4 31.8586V33.0419C4 34.6747 5.19467 36 6.66668 36H32.2668C33.7388 36 34.9335 34.6747 34.9335 33.0419V31.8586C34.9335 33.4915 33.7388 34.8167 32.2668 34.8167Z" fill="#B27245" />
            </svg>

        </Fragment>
    )
}
