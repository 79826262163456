/* eslint-disable */
import { gql } from 'apollo-boost';
import React, { useCallback, useEffect, useState } from 'react';
// import {FormattedMessage} from 'react-intl';

import {
    MBox, MButton, MForm, MFormik, MLink, MTypography
} from 'src/app/material-ui';

import { useLazyQuery } from "@apollo/react-hooks";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";

import BankTransferDetails from "../../../client-screens/transactions/BankTransferDetails";
import useStyles from './PaymentStyles';
import { FormattedMessage, useIntl } from "react-intl";


const GET_ORG_PAYMENT_METHODS = gql`
  query getOrgPaymentMethods($orgId: Int,$invoiceId: Int,$type:String) {
    getOrgPaymentMethods(orgId: $orgId, invoiceId: $invoiceId, type:$type) {
          id
          name 
          description 
          isDefault 
          createdAt 
    }
  }
`;

export default function BankTransfer({ invoiceId, status, setPaymentMethod, type, fundsAmt }: any) {
    const classes = useStyles({});
    const [paths, setPaths] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [transactionDetails, setTransactionDetails] = useState('');
    const [errorText, setErrorText] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [nextPage, setNextPage]: any = useState(true);
    const selectedOrgId = useSelector((store: any) => store.orgs.selectedOrgId);
    const [transactionDescriptionError, setTransactionDescriptionError]: any = useState('');

    const [paymentDetail, setPaymentDetail]: any = useState(null);


    const selectedLocalOrgId: any = (localStorage.getItem('selectedOrgId'));
    const [getOrgPaymentMethods] = useLazyQuery(GET_ORG_PAYMENT_METHODS, {
        variables: { orgId: parseInt(selectedLocalOrgId), invoiceId: parseInt(invoiceId), type: type },

        fetchPolicy: "no-cache",
        onCompleted: (data: any) => {
            if (data) {

                // data.getOrgPaymentMethods.filter((paymentMethod: any) =>  paymentMethod.name === 'bank')
                setPaymentDetail(data.getOrgPaymentMethods.filter((paymentMethod: any) => paymentMethod.name === 'bank'));
            }
        }
    });

    const handleImport = () => {
        setLoading(true);
        const body = new FormData();
        // body.append("tbId", tbId);
        body.append("invoiceId", invoiceId);
        body.append("status", status);
        body.append("type", type);
        if (type === 'mhWallet' && typeof type != "undefined") {
            body.append("marsHubBankDetails", paymentDetail && paymentDetail[0].description);
            body.append("amount", fundsAmt);
        }
        body.append("transactionDetails", transactionDetails);
        // body.append("file", file, file.name);
        // body.append("sourceLangId", sourceLanguageObj.id);

        const headers = {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        };

        selectedFiles.forEach((file: any) => {
            if (file) {
                body.append("file", file, file.name);
            }
        });

    };

    useEffect(() => {
        if (selectedOrgId) {
            getOrgPaymentMethods();
        }
    }, [selectedOrgId]);

    function CustomDropZone() {


        const onDrop = useCallback((acceptedFiles: any) => {

            setSelectedFiles(acceptedFiles);

            if (acceptedFiles.length > 0) {
                setPaths(acceptedFiles.map((file: any) => URL.createObjectURL(file)));
            }
        }, []);

        const { getRootProps, getInputProps } = useDropzone({ onDrop })

        return (
            <div {...getRootProps()}>
                <input {...getInputProps()} />

                <MBox display='flex' sx={{ gap: 5 }}>

                    <MBox display='flex' justifyContent='center' alignItems='center' className={classes.imgUploader}>
                        {/*{selectedFiles && JSON.stringify(selectedFiles)}*/}

                        <MBox mr={1}>
                            <img src="/assets/images/file-uploader-icon.png" alt="logo" style={{ width: '90px' }} />
                        </MBox>

                        <MBox>
                            <MTypography className={classes.DropZoneInput}>
                                <FormattedMessage id="upload.transaction.slip" />
                            </MTypography>

                            <MTypography className={classes.DropZoneDetailInput}>
                                <FormattedMessage id="drop.files.click" />
                                <MLink color="secondary" sx={{ cursor: "pointer" }}>
                                    <FormattedMessage id="browse" />
                                </MLink>

                            </MTypography>
                        </MBox>
                    </MBox>

                    {paths && paths.length > 0 && <MBox className={classes.preview}>
                        <img src={paths[0]} alt="Transaction Slip" />
                    </MBox>}

                </MBox>
            </div>
        )
    }
    const intl = useIntl();

    return (
        <div className={classes.BankCard}>
            {nextPage === false &&
                <MBox className={classes.bankTransferCard}>
                    <MBox mb={3}>
                        <MBox fontFamily='Poppins' fontSize={22} color='#3a3a3a'>
                            <FormattedMessage id="upload.transaction.slip" />
                        </MBox>

                    </MBox>

                    <CustomDropZone />
                    {errorText && errorText}

                    <MFormik
                        enableReinitialize="true"
                        initialValues={{
                            transaction: paymentDetail && paymentDetail.length > 0 && paymentDetail[0].description,
                        }}
                        onSubmit={() => {
                            if (transactionDetails === '' || transactionDetails === null) {
                                setTransactionDescriptionError(intl.formatMessage({ id: "transaction.details.required.field" }));
                            } else {
                                handleImport();

                            }
                        }}
                    >
                        {() => (
                            <MForm>


                                <MBox mt={3} mb={1}>
                                    <MBox fontFamily='Poppins' fontSize={15} color='#3a3a3a'>
                                        <FormattedMessage id="transaction.details" />
                                    </MBox>
                                </MBox>
                                <MBox>
                                    <TextareaAutosize name="transaction" onInput={(e: any) => {
                                        setTransactionDetails(e?.target?.value)
                                    }} aria-label="minimum height" minRows={6} style={{ width: '100%' }}
                                        placeholder="Enter your transaction details" />

                                    {!transactionDetails && <MBox sx={{ color: "#f44336", fontSize: '0.65rem' }}>
                                        {transactionDescriptionError && transactionDescriptionError !== '' && transactionDescriptionError}
                                    </MBox>}
                                </MBox>

                                <MBox display='flex' color justifyContent='flex-end' mt={1}>
                                    <MLink href="#" color='#3a3a3a' onClick={() => { setPaymentMethod('') }} component='button' sx={{ fontSize: 14 }}>
                                        <FormattedMessage id='cancel' />
                                    </MLink>

                                    <MButton variant='contained' sx={{ marginLeft: 2 }} type='submit' color='primary' size="medium" disabled={loading || !paths || paths.length == 0 || !transactionDetails}>
                                        <FormattedMessage id="send" />
                                    </MButton>

                                </MBox>

                            </MForm>
                        )}
                    </MFormik>

                </MBox>
            }

            {nextPage === true &&
                <MBox className={classes.detailsCard} mt={4}>

                    <BankTransferDetails hideSendTo={'true'} clientTransactionData={paymentDetail && paymentDetail[0]} />

                </MBox>
            }
            <MBox ml={1} className={classes.footerBottom}>
                <MLink onClick={() => { nextPage === true ? setNextPage(false) : setNextPage(true) }}>
                    <MButton color="primary" sx={{ width: 85 }} variant="contained">
                        <FormattedMessage id={nextPage === true ? 'next' : 'previous'} />
                    </MButton>
                </MLink>
            </MBox>

        </div >
    )
}
