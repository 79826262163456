import React, { Fragment } from 'react'

export function MClipboardList() {
    return (
        <Fragment>

            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <path d="M28.5 6.1715H22.23C21.6 4.45636 19.95 3.21436 18 3.21436C16.05 3.21436 14.4 4.45636 13.77 6.1715H7.5C5.85 6.1715 4.5 7.50221 4.5 9.12864V29.8286C4.5 31.4551 5.85 32.7858 7.5 32.7858H28.5C30.15 32.7858 31.5 31.4551 31.5 29.8286V9.12864C31.5 7.50221 30.15 6.1715 28.5 6.1715ZM18 6.1715C18.825 6.1715 19.5 6.83686 19.5 7.65007C19.5 8.46328 18.825 9.12864 18 9.12864C17.175 9.12864 16.5 8.46328 16.5 7.65007C16.5 6.83686 17.175 6.1715 18 6.1715ZM10.5 12.0858H25.5V9.12864H28.5V29.8286H7.5V9.12864H10.5V12.0858ZM18 26.8715V23.9144H25.5V26.8715H18ZM18 18.0001V15.0429H25.5V18.0001H18ZM12 19.4786V15.0429H10.5V13.5644H13.5V19.4786H12ZM13.875 22.4358C14.49 22.4358 15 22.9385 15 23.5447C15 23.8404 14.88 24.1214 14.685 24.3136L12.18 26.8715H15V28.3501H10.5V26.9898L13.5 23.9144H10.5V22.4358H13.875Z" fill="#A2A4B9"/>
            </svg>

        </Fragment>
    )
}
