
import { RESET_STORE } from '../resetStateAction';
import { GET_RELEASE_NOTES_FETCH, GET_RELEASE_NOTES_SUCCESS } from "./ReleaseNotesActionType";

interface IAction {
    type: string,
    payload: any,
    response: any,
    action: any,
    releaseNotesList: any

}

interface IState {
    releaseNotesLoading: boolean,
    releaseNotesList: any

}

const initialState: IState = {
    releaseNotesLoading: false,
    releaseNotesList: []

};


const releaseNotesReducer = (state = initialState, action: IAction) => {

    switch (action.type) {

        case GET_RELEASE_NOTES_FETCH:

            return {
                ...state,
                releaseNotesLoading: true
            }
        case GET_RELEASE_NOTES_SUCCESS:
            return {

                ...state,
                releaseNotesList: action.response,
                releaseNotesLoading: false
            }

        case RESET_STORE:
            return initialState;
        default:
            return state;
    }
};

export default releaseNotesReducer;