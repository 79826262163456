import React, { Fragment } from 'react'

export function MNoAccount() {
    return (
        <Fragment>


            <svg width="68" height="65" viewBox="0 0 68 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.25 0V9.25H0V15.4167H9.25V24.6667H15.4167V15.4167H24.6667V9.25H15.4167V0H9.25ZM27.75 9.25V18.5H18.5V27.75H9.25V58.5833C9.25 61.975 11.9942 64.75 15.4167 64.75H58.5833C62.0058 64.75 64.75 62.0058 64.75 58.5833V55.5H37C33.6083 55.5 30.8333 52.7558 30.8333 49.3333V24.6667C30.8333 21.275 33.5775 18.5 37 18.5H64.75V15.4167C64.75 12.025 62.0058 9.25 58.5833 9.25H27.75ZM37 24.6667V49.3333H67.8333V24.6667H37ZM49.3333 32.375C51.8925 32.375 53.9583 34.4408 53.9583 37C53.9583 39.5592 51.8925 41.625 49.3333 41.625C46.7742 41.625 44.7083 39.5592 44.7083 37C44.7083 34.4408 46.7742 32.375 49.3333 32.375Z" fill="#DEDEDE"/>
            </svg>


        </Fragment>
    )
}
