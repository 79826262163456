import React, { useState, MouseEvent } from 'react';

import {
    MAvatar,
    MBox, MGrid, MIconButton,
    MList,
    MListItem,
    MListItemIcon,
    MListItemText,
    MListSubheader, MMenu, MTooltip
} from 'src/app/material-ui';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import useStyles from './LinguistSidebarStyles';
import { NavLink, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FormattedMessage } from "react-intl";
import { showAlert } from "../../../store";
import NextWeekIcon from '@mui/icons-material/NextWeek';
import SidebarSkeleton from './SidebarSkeleton';
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { avatarPlaceholder } from '../../../hooks/index';

function FinanceManagerSideBar() {

    const classes = useStyles({});
    const currentUser = useSelector((store: any) => store.auth.currentUser);
    const dispatch = useDispatch();


    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };


    const handleClose = () => {
        setAnchorEl(null);
    };

    const isOwner = useSelector((store: any) => store.orgs.isOwner);
    const isAdmin = useSelector((store: any) => store.orgs.isAdmin);
    const appUserRole = useSelector((store: any) => store.userRole.userRole);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const logout = () => {

        const token = localStorage.getItem('access_token');
        localStorage.clear();

        window.location.href = "" + process.env.REACT_APP_SSO_LOGOUT_URL + token;

        dispatch(showAlert({ message: 'You are signing out.', messageType: 'error', showAlertMessage: true }))

    };
    const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className || "" }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: "#6b778c",
            fontSize: 12,
            filter: "drop-shadow(0px 8px 16px rgba(214,215,226,0.57))",
            border: "1px solid #f4f5f7",
            borderRadius: 0,
            padding: "20px 15px",
            maxWidth: 230,
        },
        arrow: {
            color: theme.palette.common.white,
        },
    }));
    const selectedOrgLoading = useSelector((store: any) => store.orgs.selectedOrgLoading);

    return (
        <MBox className={classes.sidebarWrap}>
            {selectedOrgLoading ? <SidebarSkeleton />
                :
                <>
                    <MBox>
                        <MListSubheader className={classes.subheader}>
                            <MGrid item className={classes.logoBox}>
                                <NavLink to="/linguist/open-jobs">
                                    <img className="logoFull" src="/assets/images/marshub-logo.svg" alt="logo" width="140px" />
                                    <img className="logoSmall" src="/assets/images/logo-sm.svg" alt="logo" width="140px" />
                                    {/* <img src="/assets/images/marshub-logo.svg" alt="logo" width="140px" /> */}
                                </NavLink>
                            </MGrid>

                        </MListSubheader>
                    </MBox>
                    <MBox>
                        <MBox className="generalLinks">
                            <MList className={classes.sideBarLinks}>

                                <MListItem button component={NavLink} to={'/manage-payment'}>
                                    <MListItemIcon>
                                        <LightTooltip arrow placement="right" title={<FormattedMessage id="manage.payment" />}>
                                            <NextWeekIcon />
                                        </LightTooltip>
                                    </MListItemIcon>
                                    <MListItemText primary={<FormattedMessage id="manage.payment" />} />
                                </MListItem>
                                <>
                                </>
                            </MList>
                        </MBox>
                        <MBox className="generalLinks">
                            <MList className={classes.sideBarLinks}>
                            </MList>
                        </MBox>
                    </MBox>
                    <MGrid item>
                        <MBox className="footerFull">
                            <MBox className={classes.projectSidebarFooter + ' userDropdown'}>
                                <MBox display="flex">
                                    <MAvatar className={'footerAvatar'} src={currentUser && currentUser.profilePicture ? process.env.REACT_APP_PUBLIC_URL + '/' + currentUser.profilePicture : ""} />
                                    <MBox ml={1.5}>
                                        {
                                            currentUser &&
                                                (parseInt(currentUser?.firstName?.length + currentUser?.lastName?.length) > 20) ?
                                                <MTooltip placement="top" title={currentUser.firstName + ' ' + currentUser.lastName}>
                                                    <MBox sx={{ fontSize: '16', fontFamily: "sans-serif", color: "#3a3a3a", fontWeight: 700, textTransform: 'capitalize' }}>
                                                        {currentUser.firstName.concat(" " + currentUser.lastName).slice(0, 20) + "..."}
                                                    </MBox>
                                                </MTooltip>
                                                :
                                                <MBox fontSize={16} fontFamily="sans-serif" color="#3a3a3a" sx={{ textTransform: 'capitalize' }}>
                                                    {currentUser.firstName + ' ' + currentUser.lastName}
                                                </MBox>


                                        }

                                        <MBox fontSize={14} color="#A2A4B9">{appUserRole === "ROLE_FINANCE" && 'Finance manager'}{appUserRole === "ROLE_CLIENT" && 'Client'}{appUserRole === "ROLE_TRANSLATOR" && <FormattedMessage id="linguist.side" />}</MBox>
                                    </MBox>
                                </MBox>
                                <MBox mr={-2}>
                                    <MIconButton aria-controls="notificationMenu" aria-haspopup="true" color="inherit"
                                        onClick={handleClick}>
                                        <MoreVertIcon sx={{ fontSize: 22, color: '#8d8d8d' }} />
                                    </MIconButton>
                                </MBox>

                                <MMenu id="notificationMenu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}
                                    TransitionComponent={Fade}
                                    className={classes.userDropdown}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                >
                                    {(isAdmin || isOwner) && (appUserRole === "ROLE_FINANCE") &&
                                        <NavLink className={classes.menuLink} to="/manage-payment">
                                            <MenuItem onClick={handleClose} className={classes.menuLink}><FormattedMessage id="manage.payment" /></MenuItem>
                                        </NavLink>
                                    }

                                    <MenuItem onClick={logout} className={`${classes.logoutLink}`}><span
                                        className={`${classes.menuLink} ${classes.logoutLink}`}><FormattedMessage id="logout" /></span></MenuItem>


                                </MMenu>

                            </MBox>
                        </MBox>
                        <MBox className="footerSm">
                            {currentUser && currentUser.profilePicture
                                ?
                                <MAvatar className={'footerAvatar'} src={currentUser && currentUser.profilePicture ? process.env.REACT_APP_PUBLIC_URL + '/' + currentUser.profilePicture : ""} />
                                :
                                <MAvatar className={'footerAvatar'}>
                                    {currentUser && avatarPlaceholder(`${currentUser.firstName} ${currentUser.lastName}`)}
                                </MAvatar>
                            }
                        </MBox>
                        <MBox>
                        </MBox>
                    </MGrid>

                </>
            }


        </MBox>

    )
}

export default withRouter(FinanceManagerSideBar);