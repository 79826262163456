import React, { Fragment } from 'react'

export function MPoPending() {
    return (
        <Fragment>


            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 14.5H3.5V2.5H8.75V6.25H12.5V8C13 8 13.5 8 14 8V5.5L9.5 1H3.5C3.10218 1 2.72064 1.15804 2.43934 1.43934C2.15804 1.72064 2 2.10218 2 2.5V14.5C2 14.8978 2.15804 15.2794 2.43934 15.5607C2.72064 15.842 3.10218 16 3.5 16H9C9 15.5607 9 15 9 14.5Z" fill="#A0A5B9" />
                <path fillRule="evenodd" clipRule="evenodd" d="M16 10H11V12.369L12.2879 13.5L11 14.631V17H16V14.631L14.7121 13.5L16 12.369V10ZM11.3262 12.2273V10.3184H15.6739V10.3182H11.3261V12.2273L11.3262 12.2273ZM12.7753 13.5001L11.3261 14.7727V16.6818H11.3262V14.7729L12.7754 13.5002L12.7753 13.5001ZM15.6739 14.7728V14.7727L14.2248 13.5001L14.2247 13.5002L15.6739 14.7728ZM12.0508 16.0455H14.9493V14.9319L14.9494 14.932V16.0456H12.0508V16.0455ZM12.3768 15.0735L13.5 14.0872L14.6232 15.0735V15.7273H12.3768V15.0735ZM14.9493 10.9547V12.0682L13.5 13.3409L12.0508 12.0683V12.0684L13.5001 13.3411L14.9494 12.0684V10.9547H14.9493ZM14.6232 11.9265L13.5 12.9128L12.3768 11.9265V11.2727H14.6232V11.9265Z" fill="#A0A5B9" />
            </svg>


        </Fragment>
    )
}
