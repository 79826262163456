import React, { useState } from 'react'
import useStyles from './WordCountInlineEditStyles';
import { MBox, MForm, MFormik, MIconButton, MTextField } from 'src/app/material-ui';
import { MCircularProgress } from 'src/app/material-ui/circular-progress/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { MCreateIcon } from "src/app/material-ui/icons";
import { gql } from "apollo-boost";
import { showAlert } from "src/app/store";
import { useMutation } from "@apollo/react-hooks";
import { useDispatch } from "react-redux";

const UPDATE_WORDCOUNT_GQL = gql`
    mutation updateLangFileWordCount($fileId: ID!,$langId: ID!,$words: Int!) {
        updateLangFileWordCount(fileId: $fileId,langId: $langId,words: $words)
    }`;

export function WordCountInlineEdit(props: any) {
    const [loading] = useState(false);
    const dispatch = useDispatch();
    // const closeEditField = (resetForm: any) => {
    //     resetForm();
    //     // setSelectedField(null);
    // };

    const [isWordcountEdit, setIsWordcountEdit] = useState(false);

    const [UPDATE_WORDCOUNT] = useMutation(UPDATE_WORDCOUNT_GQL, {
        fetchPolicy: 'no-cache',
        onCompleted: (data: any) => {
            // getProjectLangTargetFiles();
            setIsWordcountEdit(false);

            props.setWordcount(data.updateLangFileWordCount);
            dispatch(showAlert({
                message: 'Total Words Updated successfully',
                messageType: 'success',
                showAlertMessage: true
            }));
        },

    });

    const classes = useStyles();
    return (
        <MBox>

            {!isWordcountEdit &&
                <MBox component="span" variant="h6" display="inline-block">
                    {props.wordcount && props.wordcount}
                </MBox>
            }

            {!isWordcountEdit &&
                <MIconButton sx={{ marginRight: 3 }} disabled={loading}
                    onClick={() => {
                        setIsWordcountEdit(true)
                    }}
                >
                    <MCreateIcon fontSize="small" />
                </MIconButton>
            }

            {isWordcountEdit &&
                <MBox className={classes.fieldActionButtons}>
                    <MFormik
                        enableReinitialize="true"
                        initialValues={{
                            wordCount: props.wordCount,
                        }}
                        onSubmit={() => {
                            // if(values[selectedField] !== currentUser[selectedField]){
                            //     let data:any = {};
                            //     data[selectedField] = values[selectedField];
                            //     updateAccountData({variables: data})
                            // }else{
                            //     setSelectedField(null);
                            // }
                            alert('sd');
                        }}
                    >
                        {({ values }: any) => (
                            <MForm>
                                <MBox mb={1} display="inline-block" className={classes.inputWrappper}>
                                    <MTextField
                                        className={classes.input}
                                        name="wordCount"
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        placeholder="Total words"
                                        defaultValue={props.wordcount}
                                    />
                                </MBox>

                                <MBox display="inline-block" maxWidth="90px" verticalAlign="middle" className={classes.editButtons}>
                                    <MIconButton variant="contained" sx={{ marginRight: 3 }} disabled={loading}>
                                        {loading && <MCircularProgress color="secondary" size={24} />} <DoneIcon onClick={() => {
                                            UPDATE_WORDCOUNT({
                                                variables: {
                                                    fileId: props.fileId,
                                                    langId: props.langId,
                                                    words: values.wordCount
                                                }
                                            });
                                        }} fontSize="small" />
                                    </MIconButton>
                                    <MIconButton variant="contained" color="default">
                                        <CloseIcon fontSize="small" onClick={() => {
                                            setIsWordcountEdit(false)
                                        }} />
                                    </MIconButton>
                                </MBox>

                            </MForm>
                        )}
                    </MFormik>

                </MBox>
            }

        </MBox>

    )
}
