import React, { Fragment } from 'react'

export function MUnionpayCard() {
    return (
        <Fragment>


            <svg width="60" height="36" viewBox="0 0 60 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.3559 0H26.8341C28.8561 0 30.1123 1.63509 29.6394 3.64482L22.8996 32.3587C22.4233 34.3649 20.4013 36 18.3794 36H3.90472C1.88631 36 0.626569 34.3649 1.09941 32.3587L7.84273 3.64482C8.30852 1.63509 10.3305 0 12.3559 0Z" fill="#E31937" />
                <path d="M25.6271 0H42.2755C44.2974 0 43.3835 1.63509 42.9106 3.64482L36.1708 32.3587C35.698 34.3649 35.8462 36 33.8207 36H17.1724C15.1469 36 13.8942 34.3649 14.3706 32.3587L21.1104 3.64482C21.5868 1.63509 23.6052 0 25.6271 0Z" fill="#00447C" />
                <path d="M41.6162 0H56.0944C58.1163 0 59.3761 1.63509 58.8997 3.64482L52.1599 32.3587C51.6835 34.3649 49.6581 36 47.6361 36H33.165C31.1395 36 29.8833 34.3649 30.3597 32.3587L37.0995 3.64482C37.5688 1.63509 39.5907 0 41.6162 0Z" fill="#007B85" />
                <path fillRule="evenodd" clipRule="evenodd" d="M39.1988 27.0088H40.5644L40.9561 25.7098H39.5941L39.1988 27.0088ZM40.2892 23.4164L39.8199 24.957C39.8199 24.957 40.3386 24.6944 40.6174 24.6104C40.8997 24.5474 41.3266 24.4948 41.3266 24.4948L41.6583 23.4164H40.2892ZM40.9702 21.2002L40.515 22.6812C40.515 22.6812 41.0267 22.4536 41.2984 22.3766C41.5807 22.3031 42.0006 22.2785 42.0006 22.2785L42.3288 21.2002H40.9702ZM43.9873 21.2002L42.2229 26.9947H42.6993L42.3288 28.1922H41.8559L41.743 28.5598H40.0634L40.1763 28.1922H36.7676L37.1063 27.0858H37.4521L39.2412 21.2037L39.5976 20.0167H41.309L41.129 20.6154C41.129 20.6154 41.5842 20.2898 42.0183 20.1778C42.4488 20.0657 44.933 20.0237 44.933 20.0237L44.566 21.2037H43.9873" fill="white" />
                <path d="M44.7422 20.0203H46.5736L46.5912 20.696C46.5771 20.8115 46.6794 20.8641 46.8912 20.8641H47.2617L46.9229 21.981H45.9349C45.0809 22.0405 44.7563 21.6764 44.7775 21.2667L44.7422 20.0203Z" fill="white" />
                <path d="M44.9858 25.3176H43.2426L43.5425 24.3233H45.5362L45.8185 23.4164H43.8531L44.1883 22.296H49.6613L49.3225 23.4164H47.4876L47.2018 24.3233H49.0438L48.7368 25.3176H46.7466L46.3937 25.7378H47.2018L47.3994 26.9877C47.4206 27.1138 47.4206 27.1943 47.4629 27.2468C47.5052 27.2888 47.7452 27.3133 47.8864 27.3133H48.1334L47.7593 28.5318H47.1383C47.043 28.5318 46.8983 28.5248 46.7007 28.5178C46.5172 28.5003 46.3867 28.3917 46.2596 28.3287C46.1467 28.2762 45.9773 28.1396 45.935 27.905L45.7409 26.6551L44.834 27.884C44.5482 28.2727 44.1601 28.5703 43.5002 28.5703H42.2334L42.5651 27.4814H43.0485C43.1897 27.4814 43.3096 27.4254 43.4014 27.3799C43.4931 27.3413 43.5778 27.2923 43.6696 27.1593L44.9858 25.3176Z" fill="white" />
                <path d="M25.8991 22.5972H30.5181L30.1759 23.6896H28.3304L28.041 24.6209H29.9289L29.5831 25.7483H27.6952L27.2365 27.2644C27.18 27.4324 27.6846 27.4534 27.8681 27.4534L28.8103 27.3239L28.4327 28.5738H26.3049C26.132 28.5738 26.005 28.5493 25.8215 28.5073C25.6415 28.4618 25.5639 28.3813 25.4827 28.2622C25.4051 28.1397 25.2851 28.0381 25.3663 27.772L25.9767 25.7658H24.9287L25.278 24.6209H26.3296L26.6084 23.6896H25.5603L25.8991 22.5972Z" fill="white" />
                <path d="M29.0039 20.633H30.8988L30.5565 21.7744H27.97L27.6912 22.016C27.5712 22.1315 27.5289 22.086 27.3701 22.1665C27.2254 22.2401 26.9184 22.3836 26.5197 22.3836H25.6904L26.0256 21.2877H26.2762C26.4844 21.2877 26.6291 21.2702 26.7032 21.2247C26.7843 21.1722 26.8796 21.0566 26.9819 20.8676L27.4618 20.0098H29.3426L29.0039 20.633Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M32.6352 22.5096C32.6352 22.5096 33.1504 22.0404 34.0361 21.8899C34.2372 21.8513 35.4935 21.8653 35.4935 21.8653L35.684 21.2351H33.0198L32.6352 22.5096ZM35.1335 22.9893H32.4905L32.3282 23.5179H34.6148C34.883 23.4864 34.9395 23.5249 34.9642 23.5109L35.1335 22.9893ZM31.7107 20.0202H33.3233L33.0869 20.8255C33.0869 20.8255 33.5985 20.4193 33.9549 20.2758C34.3078 20.1462 35.1124 20.0272 35.1124 20.0272L37.7236 20.0132L36.8344 22.9507C36.6826 23.4514 36.5097 23.777 36.4003 23.9276C36.3015 24.0781 36.1851 24.2077 35.9592 24.3302C35.7405 24.4493 35.5393 24.5123 35.3558 24.5333C35.1829 24.5473 34.9183 24.5508 34.5513 24.5543H32.0353L31.3296 26.8757C31.2626 27.1067 31.2308 27.2188 31.2732 27.2818C31.3084 27.3343 31.3931 27.3974 31.5131 27.3974L32.6211 27.2923L32.24 28.5668H31.0014C30.6062 28.5668 30.3204 28.5563 30.1193 28.5423C29.9287 28.5248 29.7276 28.5423 29.5935 28.4407C29.4806 28.3392 29.3041 28.2061 29.3077 28.0696C29.3218 27.9435 29.3747 27.7335 29.4523 27.4464L31.7107 20.0202Z" fill="white" />
                <path d="M36.4148 24.761L36.2631 25.4682C36.1996 25.6888 36.1431 25.8534 35.9772 25.9969C35.8008 26.1475 35.5926 26.3015 35.1092 26.3015L34.2129 26.34L34.2058 27.1383C34.1953 27.3624 34.2552 27.3414 34.2941 27.3764C34.3364 27.4184 34.3717 27.4324 34.4105 27.45L34.6928 27.4324L35.5467 27.3869L35.1939 28.5493H34.2129C33.5248 28.5493 33.0167 28.5318 32.8508 28.4023C32.685 28.2973 32.6638 28.1677 32.6673 27.9436L32.7308 24.8345H34.2976L34.2764 25.4717H34.654C34.7845 25.4717 34.8692 25.4577 34.9257 25.4262C34.9751 25.3912 35.0069 25.3422 35.0316 25.2652L35.1903 24.768H36.4148V24.761Z" fill="white" />
                <path d="M14.067 9.16976C14.0141 9.42536 13.0084 14.0225 13.0049 14.026C12.7897 14.9574 12.6309 15.6191 12.098 16.0462C11.7946 16.2948 11.4417 16.4139 11.0288 16.4139C10.369 16.4139 9.98435 16.0883 9.92084 15.472L9.90672 15.2585C9.90672 15.2585 10.1079 14.012 10.1079 14.005C10.1079 14.005 11.1629 9.81399 11.3499 9.26079C11.3605 9.22928 11.3641 9.21177 11.3641 9.19777C9.31037 9.21528 8.94692 9.19777 8.92222 9.16626C8.9081 9.21178 8.8587 9.47087 8.8587 9.47087L7.78245 14.1941L7.6907 14.5932L7.51074 15.9027C7.51074 16.2913 7.58837 16.6099 7.7401 16.876C8.23059 17.7268 9.63148 17.8529 10.4219 17.8529C11.4417 17.8529 12.398 17.6393 13.0437 17.2472C14.1658 16.5889 14.4587 15.5596 14.7198 14.6457L14.8398 14.1766C14.8398 14.1766 15.9267 9.8245 16.1102 9.2573C16.1172 9.22578 16.1207 9.20827 16.1278 9.19427C14.6457 9.21877 14.2082 9.20478 14.067 9.16976Z" fill="white" />
                <path d="M20.0766 17.8145C19.3497 17.8005 19.0921 17.8005 18.2417 17.846L18.2099 17.7829C18.284 17.4608 18.3616 17.1457 18.4357 16.8201L18.5416 16.3789C18.7004 15.6962 18.8521 14.9014 18.8698 14.6598C18.8874 14.5163 18.9368 14.1521 18.5169 14.1521C18.3405 14.1521 18.157 14.2362 17.97 14.3237C17.8676 14.6878 17.6594 15.7102 17.5606 16.1759C17.3489 17.1562 17.3383 17.2683 17.243 17.7549L17.1831 17.8215C16.435 17.8075 16.1703 17.8075 15.3093 17.853L15.2705 17.7795C15.4152 17.1982 15.5563 16.61 15.6939 16.0288C16.0574 14.4462 16.1421 13.844 16.2409 13.0387L16.3115 12.9932C17.1548 12.8742 17.356 12.8497 18.2699 12.6676L18.3475 12.7516L18.2064 13.2558C18.3616 13.1648 18.5098 13.0737 18.6651 12.9932C19.0956 12.7831 19.572 12.7201 19.8366 12.7201C20.2389 12.7201 20.6765 12.8322 20.8564 13.2873C21.0293 13.697 20.9129 14.1976 20.687 15.1885L20.5706 15.6962C20.3377 16.7956 20.2989 16.9987 20.1683 17.7549L20.0766 17.8145Z" fill="white" />
                <path d="M23.0337 17.8144C22.5927 17.8109 22.3104 17.8004 22.0351 17.8109C21.7599 17.8144 21.4917 17.8285 21.0859 17.846L21.0647 17.8109L21.04 17.7724C21.153 17.3558 21.2129 17.2087 21.2659 17.0617C21.3223 16.9111 21.3717 16.7641 21.4741 16.3404C21.6011 15.7872 21.6823 15.4021 21.7317 15.0625C21.7916 14.7333 21.8234 14.4567 21.8693 14.1311L21.901 14.1066L21.9363 14.0751C22.3739 14.0156 22.6491 13.9736 22.9349 13.928C23.2208 13.8895 23.5101 13.8335 23.9618 13.746L23.9794 13.7845L23.9935 13.8265C23.9124 14.1696 23.8242 14.5093 23.743 14.8594C23.6618 15.2095 23.5772 15.5491 23.4995 15.8922C23.3372 16.6205 23.2702 16.8936 23.2314 17.0897C23.1925 17.2718 23.1819 17.3733 23.1184 17.7514L23.0761 17.7864L23.0337 17.8144Z" fill="white" />
                <path d="M27.6666 15.248C27.6314 15.4371 27.4655 16.1303 27.2432 16.428C27.0879 16.645 26.9044 16.7781 26.6998 16.7781C26.6398 16.7781 26.2763 16.7781 26.2728 16.2459C26.2693 15.9833 26.3222 15.7137 26.3892 15.4231C26.5798 14.5828 26.8056 13.8755 27.3843 13.8755C27.836 13.879 27.8678 14.4042 27.6666 15.248ZM29.5757 15.3251C29.8297 14.2152 29.6321 13.697 29.3816 13.3819C29.004 12.8987 28.3336 12.7446 27.6384 12.7446C27.222 12.7446 26.2269 12.7866 25.4471 13.4974C24.8896 14.0086 24.632 14.7053 24.4767 15.3741C24.3179 16.0533 24.138 17.2788 25.2742 17.7339C25.6235 17.8845 26.1281 17.923 26.4528 17.923C27.2855 17.923 28.136 17.6954 28.7782 17.0232C29.2687 16.477 29.498 15.6612 29.5757 15.3251Z" fill="white" />
                <path d="M47.3746 17.8879C46.4959 17.8809 46.2419 17.8809 45.4303 17.9159L45.3809 17.8494C45.5996 17.0196 45.822 16.1933 46.016 15.3565C46.2701 14.2676 46.3301 13.8055 46.4112 13.1717L46.4783 13.1192C47.3534 12.9967 47.5933 12.9616 48.5037 12.7936L48.5285 12.8671C48.3626 13.5499 48.2003 14.2291 48.038 14.9188C47.7027 16.3509 47.5828 17.0791 47.4557 17.8284L47.3746 17.8879Z" fill="white" />
                <path d="M46.0582 15.3426C46.0159 15.5246 45.8465 16.2249 45.6242 16.519C45.476 16.7291 45.1196 16.8586 44.9184 16.8586C44.862 16.8586 44.502 16.8586 44.4915 16.3369C44.4879 16.0778 44.5409 15.8082 44.6114 15.5141C44.8055 14.6878 45.0278 13.9876 45.6065 13.9876C46.0582 13.9876 46.2523 14.4988 46.0582 15.3426ZM47.7943 15.4266C48.0484 14.3237 47.018 15.3286 46.8557 14.9644C46.6051 14.3972 46.7604 13.2453 45.7512 12.8567C45.3631 12.7061 44.4491 12.8987 43.6658 13.6094C43.1153 14.1171 42.8506 14.8104 42.6989 15.4721C42.5401 16.1409 42.3601 17.3663 43.4858 17.804C43.8457 17.9545 44.1668 18 44.495 17.986C45.6277 17.9265 46.4922 16.2249 47.1344 15.5526C47.632 15.0169 47.7167 15.7487 47.7943 15.4266Z" fill="white" />
                <path d="M34.5477 17.8145C33.8172 17.8005 33.5667 17.8005 32.7127 17.846L32.681 17.7829C32.7551 17.4608 32.8363 17.1457 32.9139 16.8201L33.0127 16.3789C33.1715 15.6962 33.3267 14.9014 33.3408 14.6598C33.3585 14.5163 33.4079 14.1521 32.9915 14.1521C32.808 14.1521 32.6281 14.2362 32.441 14.3237C32.3422 14.6878 32.1305 15.7102 32.0282 16.1759C31.8235 17.1562 31.8094 17.2683 31.7177 17.7549L31.6541 17.8215C30.9061 17.8075 30.6485 17.8075 29.7839 17.853L29.7451 17.7795C29.8898 17.1982 30.0309 16.61 30.1686 16.0288C30.532 14.4462 30.6132 13.844 30.7155 13.0387L30.779 12.9932C31.6188 12.8742 31.827 12.8497 32.7374 12.6676L32.808 12.7516L32.6775 13.2558C32.8292 13.1648 32.9809 13.0737 33.1327 12.9932C33.5596 12.7831 34.0395 12.7201 34.3042 12.7201C34.6994 12.7201 35.1405 12.8322 35.3275 13.2873C35.4969 13.697 35.3804 14.1976 35.1511 15.1885L35.0381 15.6962C34.7982 16.7956 34.7629 16.9987 34.6359 17.7549L34.5477 17.8145Z" fill="white" />
                <path d="M40.8576 9.16627C40.854 9.16627 40.2506 9.17326 40.2506 9.17326C38.6768 9.19077 38.0452 9.18727 37.7876 9.15576C37.7665 9.2678 37.7241 9.47087 37.7241 9.47087C37.7241 9.47087 37.1595 12.0653 37.1595 12.0688C37.1595 12.0688 35.8116 17.5833 35.748 17.8424C37.1207 17.8249 37.6853 17.8249 37.9217 17.8529C37.9746 17.5938 38.2922 16.0532 38.2958 16.0532C38.2958 16.0532 38.5675 14.9223 38.5851 14.8838C38.5851 14.8838 38.6698 14.7648 38.7545 14.7193H38.8815C40.0672 14.7193 41.4045 14.7193 42.4526 13.9525C43.1653 13.4273 43.6558 12.6535 43.8711 11.7082C43.9275 11.4771 43.9699 11.2005 43.9699 10.9274C43.9699 10.5668 43.8958 10.2096 43.6876 9.92954C43.1512 9.18377 42.0891 9.16977 40.8576 9.16627ZM41.6445 11.8622C41.5174 12.4364 41.1399 12.9231 40.6565 13.1577C40.2577 13.3573 39.7708 13.3713 39.2661 13.3713H38.9415L38.9662 13.2417C38.9662 13.2417 39.5661 10.6543 39.5626 10.6648L39.5802 10.5318L39.5873 10.4302L39.8272 10.4547C39.8272 10.4547 41.0622 10.5598 41.094 10.5633C41.5845 10.7488 41.7856 11.232 41.6445 11.8622Z" fill="white" />
                <path d="M54.5693 12.7867L54.4987 12.7061C53.5989 12.8882 53.4365 12.9162 52.6108 13.0247L52.5509 13.0843C52.5473 13.0948 52.5438 13.1088 52.5403 13.1228L52.5367 13.1088C51.9227 14.5163 51.9404 14.2117 51.4393 15.3216C51.4358 15.2726 51.4358 15.2411 51.4323 15.185L51.3052 12.7832L51.2276 12.7026C50.2854 12.8847 50.2643 12.9127 49.3927 13.0213L49.3256 13.0808C49.315 13.1088 49.315 13.1403 49.3115 13.1753L49.3186 13.1893C49.428 13.739 49.3997 13.6165 49.5091 14.4883C49.5585 14.9154 49.6291 15.3461 49.6785 15.7662C49.7632 16.4735 49.8126 16.8201 49.9185 17.895C49.3327 18.8579 49.1915 19.222 48.627 20.0658L48.6622 20.1463C49.5091 20.1148 49.7067 20.1148 50.3348 20.1148L50.4724 19.9608C50.9488 18.9454 54.5693 12.7867 54.5693 12.7867Z" fill="white" />
                <path d="M23.8315 13.3818C24.3149 13.0492 24.3749 12.5905 23.9691 12.3525C23.5598 12.1109 22.8364 12.1879 22.353 12.5205C21.8695 12.8461 21.8131 13.3083 22.2224 13.5534C22.6282 13.788 23.3516 13.718 23.8315 13.3818Z" fill="white" />
                <path d="M50.4092 20.0448L49.7035 21.2457C49.4812 21.6589 49.0648 21.9635 48.4049 21.967L47.2793 21.9495L47.6075 20.8676H47.8262C47.9392 20.8676 48.0239 20.8606 48.0874 20.8291C48.1438 20.8116 48.1862 20.7661 48.232 20.6995L48.6484 20.0448H50.4092Z" fill="white" />
            </svg>

        </Fragment>
    )
}
