
import { put, takeEvery } from "redux-saga/effects";
import { GET_MANAGE_ADMIN_FETCH } from "./PeoplesActionType";
import { gql } from 'apollo-boost';
import { client } from "src/app/hooks";

const query = gql`
query getOrgAdmin($orgId: ID!,$page: ID, $getPM: Boolean){
  getOrgAdmin(orgId: $orgId, page:$page, getPM:$getPM){
   totalOrgUser
      user {
      id
      ssoUserName
      firstName
      lastName
      username
      role{
          id
          name
      }
      groups {
        id
        name
      }
      profilePicture
      createdAt
      isOwner
      }
     
  } 
}
`;


function* getPeopleFetch(variables: any): any {

    try {

        const manageAdmins = yield client.query({ query, variables, fetchPolicy: "no-cache" });

        // if (variables.type == 'GET_RECENT_PROJECT_FETCH') {
        yield put({ type: 'GET_MANAGE_ADMIN_SUCCESS', response: manageAdmins.data.getOrgAdmin });

        // } else {
        //   yield put({ type: 'GET_PROJECT_SUCCESS', response: projectList.data.organizations });
        // }
    } catch (error) {
        console.log('error', error);
        // yield put({ type: 'API_CALL_FAILURE', error });
    }
}

export default function* peopleSaga() {
    yield takeEvery(GET_MANAGE_ADMIN_FETCH, getPeopleFetch);
    //   yield takeEvery(GET_RECENT_PROJECT_FETCH, getProjectFetch);
}