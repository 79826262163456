import React, { Fragment } from 'react'

export function MDiscoverCard() {
    return (
        <Fragment>


            <svg width="60" height="36" viewBox="0 0 60 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_7057_28054)">
                    <path d="M59.6021 20.007C59.6021 20.007 44.4089 30.7727 16.585 35.601H59.6021V20.007Z" fill="#F48120" />
                    <path d="M0.250918 2.0008C0.250475 1.03399 1.03411 0.25 2.00092 0.25H58C58.9665 0.25 59.75 1.0335 59.75 2V34C59.75 34.9665 58.9665 35.75 58 35.75H2.0156C1.04942 35.75 0.26605 34.967 0.265606 34.0008L0.250918 2.0008Z" stroke="black" strokeWidth="0.5" />
                    <path d="M6.89238 11.0175H4.5791V19.1629H6.89238C8.1152 19.1629 9.00004 18.8672 9.78098 18.2298C10.7096 17.4593 11.2543 16.3041 11.2543 15.105C11.24 12.6914 9.45723 11.0175 6.89238 11.0175ZM8.74957 17.1482C8.24863 17.5923 7.61535 17.8 6.58285 17.8H6.15629V12.41H6.58395C7.60113 12.41 8.2202 12.5881 8.75066 13.0618C9.29645 13.5509 9.6202 14.306 9.6202 15.0907C9.6191 15.8755 9.29426 16.6602 8.74957 17.1482Z" fill="#231F20" />
                    <path d="M13.5547 11.0175H11.9775V19.1629H13.5547V11.0175Z" fill="#231F20" />
                    <path d="M17.4144 14.1421C16.4716 13.7871 16.1916 13.5497 16.1916 13.12C16.1916 12.6012 16.6925 12.2165 17.3707 12.2165C17.8421 12.2165 18.2402 12.4088 18.6383 12.8826L19.4641 11.801C18.786 11.2086 17.9755 10.8976 17.0764 10.8976C15.6469 10.8976 14.5422 11.9044 14.5422 13.2376C14.5422 14.3631 15.0585 14.9401 16.5318 15.4742C17.1508 15.6963 17.4604 15.8446 17.6222 15.9337C17.9318 16.1414 18.0936 16.4371 18.0936 16.7778C18.0936 17.4438 17.5774 17.9329 16.8708 17.9329C16.1194 17.9329 15.5146 17.5483 15.1471 16.8514L14.1299 17.8439C14.8518 18.9254 15.7366 19.3991 16.9299 19.3991C18.5661 19.3991 19.7299 18.3033 19.7299 16.7184C19.7583 15.4006 19.2125 14.8082 17.4144 14.1421Z" fill="#231F20" />
                    <path d="M20.2441 15.105C20.2441 17.5043 22.1155 19.3552 24.5174 19.3552C25.1955 19.3552 25.7851 19.2222 26.4916 18.8815V17.0152C25.8584 17.6516 25.2984 17.9033 24.5907 17.9033C22.9993 17.9033 21.864 16.7481 21.864 15.0896C21.864 13.5201 23.0277 12.2902 24.5163 12.2902C25.2677 12.2902 25.843 12.5573 26.4905 13.2079V11.3416C25.7982 10.9866 25.2229 10.8383 24.5448 10.8383C22.1746 10.8394 20.2441 12.7353 20.2441 15.105Z" fill="#231F20" />
                    <path d="M39.0487 16.4965L36.882 11.0175H35.1582L38.5915 19.3695H39.4468L42.9391 11.0175H41.2296L39.0487 16.4965Z" fill="#231F20" />
                    <path d="M43.6611 19.1629H48.1411V17.7857H45.2383V15.5941H48.0383V14.2158H45.2383V12.41H48.1411V11.0175H43.6611V19.1629Z" fill="#231F20" />
                    <path d="M54.4036 13.4311C54.4036 11.9055 53.3569 11.0317 51.5303 11.0317H49.1875V19.1772H50.7647V15.9041H50.9714L53.1523 19.1772H55.0981L52.5486 15.7414C53.7408 15.4897 54.4036 14.6753 54.4036 13.4311ZM51.2208 14.7786H50.7636V12.3056H51.2503C52.238 12.3056 52.7684 12.72 52.7684 13.5201C52.7684 14.3345 52.238 14.7786 51.2208 14.7786Z" fill="#231F20" />
                    <path d="M31.3406 19.4585C33.7254 19.4585 35.6587 17.5158 35.6587 15.1193C35.6587 12.7228 33.7254 10.78 31.3406 10.78C28.9558 10.78 27.0225 12.7228 27.0225 15.1193C27.0225 17.5158 28.9558 19.4585 31.3406 19.4585Z" fill="url(#paint0_linear_7057_28054)" />
                    <path opacity="0.65" d="M31.3406 19.4585C33.7254 19.4585 35.6587 17.5158 35.6587 15.1193C35.6587 12.7228 33.7254 10.78 31.3406 10.78C28.9558 10.78 27.0225 12.7228 27.0225 15.1193C27.0225 17.5158 28.9558 19.4585 31.3406 19.4585Z" fill="url(#paint1_linear_7057_28054)" />
                    <path d="M55.4073 11.3285C55.4073 11.1801 55.3045 11.1064 55.1415 11.1064H54.9053V11.8176H55.0825V11.5362L55.2892 11.8176H55.4959L55.2596 11.5219C55.3482 11.5065 55.4073 11.4318 55.4073 11.3285ZM55.112 11.4318H55.0825V11.2394H55.112C55.2006 11.2394 55.2443 11.2691 55.2443 11.3285C55.2443 11.4021 55.2006 11.4318 55.112 11.4318Z" fill="#231F20" />
                    <path d="M55.1708 10.8394C54.8318 10.8394 54.5518 11.1207 54.5518 11.4614C54.5518 11.8022 54.8318 12.0835 55.1708 12.0835C55.5099 12.0835 55.7899 11.8022 55.7899 11.4614C55.7899 11.1207 55.5099 10.8394 55.1708 10.8394ZM55.1708 11.9802C54.905 11.9802 54.6699 11.7582 54.6699 11.4614C54.6699 11.1801 54.8908 10.9427 55.1708 10.9427C55.4366 10.9427 55.6575 11.1801 55.6575 11.4614C55.6575 11.7428 55.4366 11.9802 55.1708 11.9802Z" fill="#231F20" />
                </g>
                <defs>
                    <linearGradient id="paint0_linear_7057_28054" x1="33.5476" y1="18.5919" x2="30.5421" y2="13.9091" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F89F20" />
                        <stop offset="0.2502" stopColor="#F79A20" />
                        <stop offset="0.5331" stopColor="#F68D20" />
                        <stop offset="0.6196" stopColor="#F58720" />
                        <stop offset="0.7232" stopColor="#F48120" />
                        <stop offset="1" stopColor="#F37521" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_7057_28054" x1="33.0764" y1="18.5287" x2="28.6801" y2="9.97792" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F58720" />
                        <stop offset="0.3587" stopColor="#E16F27" />
                        <stop offset="0.703" stopColor="#D4602C" />
                        <stop offset="0.9816" stopColor="#D05B2E" />
                    </linearGradient>
                    <clipPath id="clip0_7057_28054">
                        <rect width="60" height="36" fill="white" />
                    </clipPath>
                </defs>
            </svg>

        </Fragment>
    )
}
