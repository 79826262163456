import React, { Fragment } from 'react'

export function MBundle() {
    return (
        <Fragment>
            <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.7867 15.4167H58.1825L55.2842 12.3333H18.2842L15.7867 15.4167ZM63.3317 16.1258C64.2258 17.1742 64.75 18.5 64.75 20.0417V58.5833C64.75 60.2188 64.1003 61.7873 62.9438 62.9438C61.7873 64.1003 60.2188 64.75 58.5833 64.75H15.4167C13.7812 64.75 12.2126 64.1003 11.0562 62.9438C9.8997 61.7873 9.25 60.2188 9.25 58.5833V20.0417C9.25 18.5 9.77417 17.1742 10.6683 16.1258L14.9233 10.9458C15.7867 9.8975 17.0508 9.25 18.5 9.25H55.5C56.9492 9.25 58.2133 9.8975 59.0458 10.9458L63.3317 16.1258ZM18.5 55.5H37V46.25H18.5V55.5Z" fill="#E8E8E8" />
            </svg>
        </Fragment>
    )
}
