/* eslint-disable*/
import React, { useEffect, useState } from 'react';
import {
    MAccordion, MAccordionActions, MAccordionDetails, MAccordionSummary,
    MAppBar, MBox,
    MButton, MCircularProgress, MDrawer, MForm, MFormik,
    // MFormLabel, MGrid,  MLink,MSkeleton,
    MIconButton,
    MPagination,
    MTextField, MToolbar
} from 'src/app/material-ui';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import EmptyContent from "src/app/components/empty-content/EmptyContent";
import * as Yup from "yup";
import MatchRatesSkeleton from './MatchRatesSkeleton';
import useStyles from './MatchRatesStyles';
import CheckPermissions from 'src/app/hooks/permissions/CheckPermissions';


/* In createMatchRate mutation $orgId only pass in case of create matchRate or updateMatchRate */


export default function EditMatchRates({ openEditMatchRateDrawer, setOpenEditMatchRateDrawer, createMatchRate, createLoading, MatchRateLoading, vendorMatchRate, setVendorMatchRate }: any) {

    const classes = useStyles({});
    const [page, setPage]: any = useState(1);
    const [totalPages, setTotalPages]: any = useState(0);
    const [matchRateName, setMatchRateName]: any = useState('');

    const [matchRateId, setMatchRateId]: any = React.useState(0);
    const [matchRateUpdateSuccessfully, setMatchRateUpdateSuccessfully]: any = React.useState();
    const [matchRateAddedSuccessfully, setMatchRateAddedSuccessfully]: any = React.useState();
    const [isPanelExpanded, SetIsPanelExpanded] = React.useState('panel0');
    const selectedOrgId = useSelector((store: any) => store.orgs.selectedOrgId);
    const organizations = useSelector((store: any) => store.orgs.organizations);
    const [mathchRateCreate, setMatchRateCreate] = useState(false);
    const [mathchRateList, setMatchRateList] = useState(false);
    const [mathchRateView, setMatchRateView] = useState(false);
    const [mathchRateEdit, setMatchRateEdit] = useState(false);
    const [mathchRateDelete, setMatchRateDelete] = useState(false);
    const intl = useIntl();
    useEffect(() => {
        setMatchRateCreate(CheckPermissions('ROLE_MATCH_RATE_CREATE', selectedOrgId, organizations));
        setMatchRateList(CheckPermissions('ROLE_MATCH_RATE_LIST', selectedOrgId, organizations));
        setMatchRateView(CheckPermissions('ROLE_MATCH_RATE_VIEW', selectedOrgId, organizations));
        setMatchRateEdit(CheckPermissions('ROLE_MATCH_RATE_EDIT', selectedOrgId, organizations));
        setMatchRateDelete(CheckPermissions('ROLE_MATCH_RATE_DELETE', selectedOrgId, organizations));
    }, [selectedOrgId, organizations]);


    const handlePageChange = (event: any, value: any) => {
        setPage(value);
    };

    const handleAccordionChange = (panel: any) => (event: any, isExpanded: any) => {
        SetIsPanelExpanded(isExpanded ? panel : false);
    };

    return (
        <div className="matchRateScreen">
            <MBox>
                <MDrawer open={openEditMatchRateDrawer} variant="temporary" anchor='right' className={classes.fileUploadDrawer}>
                    <MAppBar className='drawerAppBar'>
                        <MToolbar>
                            <MBox display="inline-block">
                                <MIconButton onClick={() => setOpenEditMatchRateDrawer(false)}>
                                    <ArrowBackIcon />
                                </MIconButton>
                            </MBox>
                            <MBox width="100%" fontSize={18} fontFamily="Poppins">
                                <FormattedMessage id="update.match.rates" />
                            </MBox>
                        </MToolbar>
                    </MAppBar>
                    <MBox py={4} mb={2} pt={2}>
                        <MBox maxWidth={700} className={classes.formBoxNew}>

                            <MBox className={classes.contentBox} px={3} py={4} minHeight={250} pt={2}>
                                <MBox fontFamily="Poppins" fontSize={18} color='#393939' mb={3}>
                                    <FormattedMessage id="match.rates" />
                                </MBox>
                                <MBox maxWidth="500px">
                                    {mathchRateView && ((vendorMatchRate && vendorMatchRate.length > 0) && !MatchRateLoading)
                                        ?
                                        vendorMatchRate.map((item: any, index: number) => (
                                            <MAccordion className={classes.accordion} expanded={mathchRateList && (isPanelExpanded === 'panel' + index)} onChange={handleAccordionChange('panel' + index)}>
                                                <MAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                                                    <MBox fontFamily="Poppins">
                                                        {item.name}
                                                    </MBox>
                                                </MAccordionSummary>
                                                <FormattedMessage id="enter.valid.percentage">
                                                    {enterValidPercentage =>
                                                        <FormattedMessage id="required">
                                                            {required =>
                                                                <MFormik
                                                                    validateOnChange
                                                                    enableReinitialize="true"
                                                                    initialValues={{
                                                                        new: item && item.new,
                                                                        Fifty_seventyFour: item && item.fifty_seventyFour,
                                                                        SeventyFive_ninetyEight: item && item.seventyFive_ninetyEight,
                                                                        EightyFive_NinetyFour: item && item.eightyFive_NinetyFour,
                                                                        NinetyFive_NinetyNine: item && item.ninetyFive_NinetyNine,
                                                                        Hundred: item && item.hundred,
                                                                        repetition: item && item.repetition,
                                                                        crossFileRepetitions: item.crossFileRepetitions,
                                                                    }}
                                                                    validationSchema={Yup.object({
                                                                        new: Yup.string().required(String(required)).matches(/^[0-9][0-9]?$|^100$/, String(enterValidPercentage)),
                                                                        Fifty_seventyFour: Yup.string().required(String(required)).matches(/^[0-9][0-9]?$|^100$/, String(enterValidPercentage)),
                                                                        SeventyFive_ninetyEight: Yup.string().required(String(required)).matches(/^[0-9][0-9]?$|^100$/, String(enterValidPercentage)),
                                                                        EightyFive_NinetyFour: Yup.string().required(String(required)).matches(/^[0-9][0-9]?$|^100$/, String(enterValidPercentage)),
                                                                        NinetyFive_NinetyNine: Yup.string().required(String(required)).matches(/^[0-9][0-9]?$|^100$/, String(enterValidPercentage)),
                                                                        Hundred: Yup.string().required(String(required)).matches(/^[0-9][0-9]?$|^100$/, String(enterValidPercentage)),
                                                                        repetition: Yup.string().required(String(required)).matches(/^[0-9][0-9]?$|^100$/, String(enterValidPercentage)),
                                                                        crossFileRepetitions: Yup.string().required(String(required)).matches(/^[0-9][0-9]?$|^100$/, String(enterValidPercentage)),
                                                                    })}
                                                                    onSubmit={(values: any) => {
                                                                        createMatchRate({
                                                                            variables: {
                                                                                orgId: selectedOrgId,
                                                                                id: matchRateId,
                                                                                name: values.name,
                                                                                new: values.new,
                                                                                fifty_seventyFour: values.Fifty_seventyFour,
                                                                                seventyFive_ninetyEight: values.SeventyFive_ninetyEight,
                                                                                eightyFive_NinetyFour: values.EightyFive_NinetyFour,
                                                                                ninetyFive_NinetyNine: values.NinetyFive_NinetyNine,
                                                                                hundred: values.Hundred,
                                                                                repetition: values.repetition,
                                                                                crossFileRepetitions: values.crossFileRepetitions,
                                                                            }
                                                                        });
                                                                        setOpenEditMatchRateDrawer(false)
                                                                        setVendorMatchRate([])

                                                                    }}
                                                                >
                                                                    {() => (

                                                                        <MForm sx={{ width: '100%' }}>
                                                                            <MAccordionDetails>

                                                                                <MBox width="100%">
                                                                                    <MBox borderBottom="1px solid #eaeaea" pb={2} px={3} className="inputsRow" display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                                                                        <MBox maxWidth="230px" display="flex" alignItems="center">
                                                                                            <MBox width="60px" mr={1.2} textAlign="right">
                                                                                                <FormattedMessage id="new" />
                                                                                            </MBox>
                                                                                            <MBox width="118px">
                                                                                                <MTextField
                                                                                                    fullWidth
                                                                                                    name="new"
                                                                                                    variant="outlined"
                                                                                                    color="secondary"
                                                                                                    type="number"
                                                                                                    size="small"
                                                                                                    disabled={false}
                                                                                                    placeholder="100%"
                                                                                                    inputProps={{
                                                                                                        max: "100",
                                                                                                        min: "0",
                                                                                                        step: "any"
                                                                                                    }}
                                                                                                />
                                                                                            </MBox>
                                                                                        </MBox>

                                                                                        <MBox maxWidth="230px" display="flex" alignItems="center">
                                                                                            <MBox width="60px" mr={1.2} textAlign="right">
                                                                                                <FormattedMessage id="fifty.to.seventyfourpercent" />
                                                                                            </MBox>
                                                                                            <MBox width="118px">
                                                                                                <MTextField
                                                                                                    fullWidth
                                                                                                    name="Fifty_seventyFour"
                                                                                                    variant="outlined"
                                                                                                    type="number"
                                                                                                    color="secondary"
                                                                                                    size="small"
                                                                                                    disabled={false}
                                                                                                    placeholder="60%"
                                                                                                    inputProps={{
                                                                                                        max: "100",
                                                                                                        min: "0",
                                                                                                        step: "any"
                                                                                                    }}
                                                                                                />
                                                                                            </MBox>
                                                                                        </MBox>
                                                                                    </MBox>
                                                                                    <MBox borderBottom="1px solid #eaeaea" pb={2} px={3} className="inputsRow" display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                                                                        <MBox maxWidth="230px" display="flex" alignItems="center">
                                                                                            <MBox width="60px" mr={1.2} textAlign="right">
                                                                                                <FormattedMessage id="seventyfive.to.eightyfour" />
                                                                                            </MBox>
                                                                                            <MBox width="118px">
                                                                                                <MTextField
                                                                                                    fullWidth
                                                                                                    name="SeventyFive_ninetyEight"
                                                                                                    variant="outlined"
                                                                                                    color="secondary"
                                                                                                    size="small"
                                                                                                    disabled={false}
                                                                                                    placeholder="80%"
                                                                                                    inputProps={{
                                                                                                        max: "100",
                                                                                                        min: "0",
                                                                                                        step: "any"
                                                                                                    }}
                                                                                                />
                                                                                            </MBox>
                                                                                        </MBox>
                                                                                        <MBox maxWidth="230px" display="flex" alignItems="center">
                                                                                            <MBox width="60px" mr={1.2} textAlign="right">
                                                                                                <FormattedMessage id="eightyfive.to.ninetyfour" />
                                                                                            </MBox>
                                                                                            <MBox width="118px">
                                                                                                <MTextField
                                                                                                    fullWidth
                                                                                                    name="EightyFive_NinetyFour"
                                                                                                    variant="outlined"
                                                                                                    type="number"
                                                                                                    color="secondary"
                                                                                                    size="small"
                                                                                                    disabled={false}
                                                                                                    placeholder="90%"
                                                                                                    inputProps={{
                                                                                                        max: "100",
                                                                                                        min: "0",
                                                                                                        step: "any"
                                                                                                    }}
                                                                                                />
                                                                                            </MBox>
                                                                                        </MBox>
                                                                                    </MBox>
                                                                                    <MBox borderBottom="1px solid #eaeaea" pb={2} px={3} className="inputsRow" display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                                                                        <MBox maxWidth="230px" display="flex" alignItems="center">
                                                                                            <MBox width="60px" mr={1.2} textAlign="right">
                                                                                                <FormattedMessage id="ninetyfive.to.ninetynine" />
                                                                                            </MBox>
                                                                                            <MBox width="118px">
                                                                                                <MTextField
                                                                                                    fullWidth
                                                                                                    name="NinetyFive_NinetyNine"
                                                                                                    variant="outlined"
                                                                                                    type="number"
                                                                                                    color="secondary"
                                                                                                    size="small"
                                                                                                    disabled={false}
                                                                                                    placeholder="97%"
                                                                                                    inputProps={{
                                                                                                        max: "100",
                                                                                                        min: "0",
                                                                                                        step: "any"
                                                                                                    }}
                                                                                                />
                                                                                            </MBox>
                                                                                        </MBox>
                                                                                        <MBox maxWidth="230px" display="flex" alignItems="center">
                                                                                            <MBox width="60px" mr={1.2} textAlign="right">
                                                                                                <FormattedMessage id="hundered.percent" />
                                                                                            </MBox>
                                                                                            <MBox width="118px">
                                                                                                <MTextField
                                                                                                    fullWidth
                                                                                                    type="number"
                                                                                                    name="Hundred"
                                                                                                    variant="outlined"
                                                                                                    color="secondary"
                                                                                                    size="small"
                                                                                                    disabled={false}
                                                                                                    placeholder="100%"
                                                                                                    inputProps={{
                                                                                                        max: "100",
                                                                                                        min: "0",
                                                                                                        step: "any"
                                                                                                    }}
                                                                                                />
                                                                                            </MBox>
                                                                                        </MBox>
                                                                                    </MBox>
                                                                                    <MBox px={3} className="inputsRow" display="flex" justifyContent="space-between" alignItems="center" pb={2}>
                                                                                        <MBox maxWidth="230px" display="flex" alignItems="center">
                                                                                            <MBox width="60px" mr={1.2} sx={{ whiteSpace: 'nowrap' }} textAlign="right">
                                                                                                <FormattedMessage id="repetition" />
                                                                                            </MBox>
                                                                                            <MBox width="118px">
                                                                                                <MTextField
                                                                                                    fullWidth
                                                                                                    type="number"
                                                                                                    name="repetition"
                                                                                                    variant="outlined"
                                                                                                    color="secondary"
                                                                                                    size="small"
                                                                                                    disabled={false}
                                                                                                    placeholder="0%"
                                                                                                    inputProps={{
                                                                                                        max: "100",
                                                                                                        min: "0",
                                                                                                        step: "any"
                                                                                                    }}
                                                                                                />
                                                                                            </MBox>
                                                                                        </MBox>
                                                                                        <MBox display="flex" alignItems="center">
                                                                                            <MBox mr={1.2}>
                                                                                                <FormattedMessage id="cross.file.repetitions" />
                                                                                            </MBox>
                                                                                            <MBox width="118px">
                                                                                                <MTextField
                                                                                                    fullWidth
                                                                                                    type="number"
                                                                                                    name="crossFileRepetitions"
                                                                                                    variant="outlined"
                                                                                                    color="secondary"
                                                                                                    size="small"
                                                                                                    disabled={false}
                                                                                                    placeholder="0%"
                                                                                                    inputProps={{
                                                                                                        max: "100",
                                                                                                        min: "0",
                                                                                                        step: "any"
                                                                                                    }}
                                                                                                />
                                                                                            </MBox>
                                                                                        </MBox>
                                                                                    </MBox>
                                                                                </MBox>

                                                                            </MAccordionDetails>

                                                                            <MAccordionActions>

                                                                                {mathchRateEdit &&
                                                                                    <MBox display="flex" mt={3} mb={12}>
                                                                                        <MBox className={classes.footerBottom}>
                                                                                            <MButton disabled={createLoading} className="btnSmall" color="primary" variant="contained" type="submit"
                                                                                                onClick={() => {
                                                                                                    setMatchRateId(item.id)
                                                                                                }}
                                                                                            >
                                                                                                {createLoading && <MCircularProgress color="primary" size={24} />}
                                                                                                <FormattedMessage id="update" />
                                                                                            </MButton>

                                                                                        </MBox>
                                                                                    </MBox>
                                                                                }
                                                                            </MAccordionActions>
                                                                        </MForm>
                                                                    )}
                                                                </MFormik>
                                                            }
                                                        </FormattedMessage>
                                                    }
                                                </FormattedMessage>
                                            </MAccordion>
                                        ))
                                        :
                                        (MatchRateLoading)
                                        &&
                                        <MatchRatesSkeleton />
                                    }
                                    {totalPages > 1 ?
                                        <MBox mt={3} display="flex" justifyContent="flex-end">
                                            <MPagination count={totalPages} page={page && page}
                                                onChange={handlePageChange} />
                                        </MBox> : " "
                                    }
                                </MBox>

                                {/* conditions to check empty content */}

                                {/* it will show no content if no record found */}
                                {(matchRateName === '') && (vendorMatchRate && vendorMatchRate.length < 1) && !MatchRateLoading &&
                                    <EmptyContent />
                                }

                                {/* it will show no content found in search  */}

                                {(!MatchRateLoading && (vendorMatchRate && vendorMatchRate.length < 1) && (matchRateName && matchRateName !== "")) &&
                                    <EmptyContent
                                        isContent={true}
                                        text={intl.formatMessage({ id: "create.rates.land.projects.related.as.many" })}

                                    />}
                            </MBox>

                        </MBox>
                    </MBox>

                    {/* Match rate Added/Update Message Start */}
                    <FormattedMessage id="match.rate.update.successfully">
                        {matchRateUpdate => setMatchRateUpdateSuccessfully(matchRateUpdate)}
                    </FormattedMessage>
                    <FormattedMessage id="match.rate.added.successfully">
                        {matchRateAdded => setMatchRateAddedSuccessfully(matchRateAdded)}
                    </FormattedMessage>
                    {/* Match rate Update Message End */}
                </MDrawer>
            </MBox>
        </div>
    );
}
