import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
const useStyles = makeStyles((theme: Theme) => ({
    formBox: {
        backgroundColor: 'white',
        boxShadow: '0px 5px 5px 0px rgba(193, 199, 208, 0.26)',
        borderRadius: 5,
        position: 'relative',
        overflow: 'auto'
    },
    formLabel: {
        fontSize: 15,
        color: theme.palette.text.primary,
        marginBottom: 10
    },
    profileBanner: {
        background: 'url("/assets/images/vendor-profile-banner-img.jpg") no-repeat',
        height: 267,
        backgroundPosition: 'center !important',
        backgroundSize: 'cover !important'
    },
    overviewChipStyle: {
        backgroundColor: "#eceff1",
        height: '32px',
        borderRadius: '16px',
        fontSize: '12px',
        lineHeight: '28px',
        color: '#464555',
        fontFamily: "Poppins Emoji",
        '& .MuiChip-label': {
            paddingRight: 0,
            // height:'100%'
        }
    },
    overviewChipIcon: {
        marginLeft: 8,
        height: '16px',
    },
    inputWidth: {
        width: '100%',
        '& fieldset': {
            border: '1px solid #a0a5b9',
        },
        '& textarea,& input': {
            '&::placeholder': {
                fontSize: 14,
                color: '#9c9c9c',
                opacity: 1
            }
        },
        '& input': {
            paddingTop: '8.5px',
            paddingBottom: '12.5px',
        },
    },
    logoWrap: {
        backgroundColor: 'rgb(255, 255, 255)',
        border: '1px solid rgb(160, 165, 185)',
        width: 192,
        height: 192,
        borderRadius: 3,
        position: 'absolute',
        top: -66,
        left: 0,
        overflow: 'hidden',
        padding: 8
    },
    tabLink: {
        cursor: 'pointer',
        position: 'relative',
        minWidth: 148,
        textAlign: 'center',
        '&.active': {
            color: '#3a3a3a',
            '& .active_bar': {
                backgroundColor: theme.palette.primary.main,
                height: 3,
                width: 148,
                position: 'absolute',
                bottom: -13,
                left: 0,
            }
        },
    },
    ceritificateWrap: {
        border: '1px solid #f1f1f1',
        borderRadius: 4,
        width: 90,
        height: 85,
        // overflow: 'hidden',
        display: 'inline-block',
        '& img': {
            // width: '100%',
            height: '100%'
        }
    },
    ceritificateWrapImg: {

        width: '100%',
        cursor: 'pointer'

    },
    reviewBox: {
        "& a:last-child": {
            borderBottom: "1px solid #f4f5f7",
        },
    },
    reviewList: {
        textDecoration: "none",
        display: 'block',
        borderTop: "1px solid #f4f5f7",
        '&:first-child': {
            border: 'none'
        }
    },
    selectRateBtn: {
        minWidth: 156,
        height: 45,
        fontSize: 16,
        fontFamily: 'Poppins'
    },
    ratesList: {
        // boxShadow: '0px 0px 32px 0px rgba(214, 215, 226, 0.3)',
        backgroundColor: '#fff',
        borderTop: '1px solid #f4f5f7',
        "&:last-child": {
            borderBottom: "1px solid #f4f5f7",
        },
        '& a': {
            color: theme.palette.secondary.main,
            textDecoration: 'none'
        },
    },
    dotIcon: {
        fontSize: 10,
        color: '#bfc1cd',
        margin: '0 12px -1px'
    },
    status: {
        fontSize: 12,
        width: 76,
        height: 28,
        borderRadius: 14,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textTransform: "capitalize",
        '&.created': {
            backgroundColor: '#f9f5e7',
            color: '#e69900',
        },
        // '&.bidding':{
        //     backgroundColor:'#ffe8e8',
        //     color: '#ff0102',
        // },
        '&.approved': {
            backgroundColor: '#dff5eb',
            color: '#16c68e',
        },
        '&.pending': {
            backgroundColor: '#e5f1f7',
            color: theme.palette.secondary.main
        },
    },
    certificateScroll: {
        minHeight: 163,
        maxHeight: 163,
    },
    contractsScroll: {
        minHeight: 150,
        maxHeight: 150,
    },
    reviewScroll: {
        minHeight: 215,
        maxHeight: 215,
    },
    pricingScroll: {
        minHeight: 219,
        maxHeight: 219,
    },
    aboutInfoText: {
        maxWidth: 200,
        overflow: 'hidden',
        display: 'inline-block',
        textOverflow: 'ellipsis'
    },
    sideBarEmpty: {
        minHeight: 177,
        '& img': {
            width: 150,
            paddingTop: 0
        }
    },
    bestKnownScroll: {
        minHeight: 150,
        maxHeight: 150,
    },
    profileAvatar: {
        width: '100%',
        height: '100%',
        borderRadius: 0,
        fontSize: '45px !important',
    },
    languageModal: {
        '& #dialog-title': {
            borderBottom: '1px solid #dbdbdb',
            '& h2': {
                color: '#3a3a3a',
                padding: '7px 0'
            }
        },
        '& .MuiDialog-paperWidthSm': {
            maxWidth: 600,
            width: 600
        },
        '& .MuiDialogContent-root': {
            minHeight: 250
        }
    },
    input: {
        display: 'none',
    },
    actionMenuItem: {
        minWidth: 120,
        fontSize: 14
    },
    contractModal: {
        "& .MuiPaper-root.MuiDrawer-paper": {
            minWidth: 650,
        },
    },
    textOverFlow: {
        maxWidth: '82%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block',
        whiteSpace: 'nowrap',
    },
    removeVendorBtn: {
        height: 36,
        borderRadius: 18
    },
    reviewsEmptyCase: {
        '& img': {
            width: 200,
            paddingTop: 0
        }
    },
    switchBtn: {
        '& .MuiSwitch-track': {
            backgroundColor: '#A0A5B9',
            height: 16,
            opacity: 1,
            borderRadius: 20
        },
        '& .MuiSwitch-thumb': {
            boxShadow: 'none',
            width: 10,
            height: 10
        },
        '& .MuiSwitch-switchBase': {
            top: 6,
            left: 6
        },
        '& .MuiSwitch-colorPrimary.Mui-checked': {
            left: 3
        }
    },
    editIcon: {
        position: 'absolute',
        right: 8,
        top: 5,
        '& svg': {
            color: '#A0A5B9'
        }
    },
    actionIcon: {
        '& svg': {
            color: '#A0A5B9'
        }
    },
    uploadBtn: {
        fontSize: 14,
        textTransform: 'none',
        border: '1px solid #a0a5b9'
    },
    certificateBox: {
        backgroundColor: '#fff',
        width: 95,
        height: 119,
        borderRadius: 4,
        border: '1px solid #F1F1F2',
        filter: 'drop-shadow(0px 5px 7px #D6D7E2)',
        padding: '10px 7px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 13,
        '& img': {
            width: '100%'
        }
    },
    accordionWrap: {
        boxShadow: 'none',
        filter: 'drop-shadow(0 0 16px rgba(214,215,226,0.3))',
        border: '1px solid #f4f5f7',
        marginBottom: 10,
        transition: "all .3s",
        '&:not(.Mui-expanded):hover': {
            boxShadow: 'none',
            filter: 'initial',
            '& .MuiAccordionSummary-root': {
                backgroundColor: '#f7f8fc',
            }
        },
        '& .MuiButtonBase-root.MuiAccordionSummary-root': {
            height: 75,
            borderRadius: 5,
            backgroundColor: '#fff',
            margin: 0
        },
        '& .MuiAccordionDetails-root': {
            backgroundColor: '#fff',
            padding: '0px 24px 24px'
        },
        '&::before': {
            display: 'none'
        },
        '& .MuiAccordionSummary-root>.MuiButtonBase-root': {
            marginRight: -5,
            transform: 'rotate(-90deg)',
            '&.Mui-expanded': {
                transform: 'rotate(0deg)'
            }
        }
    },

    panelSummary: {
        flexDirection: "row-reverse",

        "& .MuiExpansionPanelSummary-expandIcon": {
            padding: "0",
            marginRight: theme.spacing(1),
            color: "#494949"
        },
        "& .MuiExpansionPanelSummary-content": {
            justifyContent: "space-between",
            alignItems: "center"
        },
    },

    panelBodyWrapper: {
        display: "block",
        "& .actionBtnBox": {
            position: 'absolute',
            right: 10,
            top: 14,
            "& svg": {
                color: "#a0a5b9",
            },
        },
    },

    panelInner: {
        borderTop: "1px solid #dcddde",
        paddingBottom: 0
        // borderBottom: "1px solid #dcddde"
    },
    workFlowList: {
        "& .counter": {
            backgroundColor: "#6b778c",
            color: "#fff",
            borderRadius: 20,
            position: "relative",
        },
        "& .text": {
            color: "#6b778c",
        }
    },
    workFlowBox: {
        position: "relative",
        width: '20%',

        "& .hr": {
            position: "absolute",
            width: '100%',
            height: 1,
            right: '-50%',
            top: 14,
            backgroundColor: '#f1f1f2',
            // display: "none"
        },

        "& .arrow": {
            position: "absolute",
            width: 14,
            height: 14,
            top: 8,
            right: 0,
            color: "#6b778c",
            background: "url('../../assets/images/work-flow-arrow.png') no-repeat",
        },
        "& .curved-arrow-right": {
            position: "absolute",
            width: 57,
            height: 102,
            top: 8,
            right: 64,
            background: "url('../../assets/images/work-flow-right-curved-image.png') no-repeat",
        },
        "& .curved-arrow-left": {
            position: "absolute",
            width: 57,
            height: 102,
            top: 8,
            left: 0,
            background: "url('../../assets/images/work-flow-left-curved-image.png') no-repeat",
        },
        // "&:first-child .arrow, &:nth-child(5n+5) .arrow":{
        "&:nth-child(5n+5) .arrow, &:nth-child(5n+5) .hr": {
            display: "none",
        }

        // "&:before":{

        // },
        // "& :after":{
        //   width: 200,
        //   height: 1,
        //   right: 0,
        //   top: 10,
        //   backgroundColor: "#f1f1f2"
        // },


    },
    updateModal: {
        "& .MuiPaper-root.MuiDrawer-paper": {
            minWidth: "85%",
            maxWidth: "85%",

        },
    },
    avatar: {
        fontSize: 14,
        marginRight: 16
    },
    modalForm: {
        width: 636,
        background: '#FDFDFD',
        // border: '1px solid #E8E8E8',
        // borderRadius: 5,
        margin: '67px auto auto',
        padding: '44px 48px 48px',
        border: '1px solid #E8E8E8',
        backgroundColor: '#FDFDFD',
        borderRadius: 5,
    },
    autoComplete: {
        '& fieldset': {
            border: '1px solid #a0a5b9',
        },
        '& .MuiAutocomplete-input': {
            padding: '2.5px 9px 6.5px !important'
        }
    },
    cancelBtn: {
        border: 'none'
    },
    updateBtn: {
        minWidth: 106
    },
    addBtn: {
        backgroundColor: '#6B778C !important',
        borderColor: '#6B778C !important',
        width: 75,
        height: 40
    },
    chipItem: {
        '& .MuiChip-label': {
            color: '#464555DE'
        },
        '& .MuiChip-deleteIcon': {
            width: 18,
            height: 18,
            marginRight: 8
        }
    },
    footerBottom: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'fixed',
        bottom: 0,
        left: '20%',
        width: '80%',
        padding: '20px 27px 20px 0px',
        backgroundColor: '#fff',
        borderTop: '1px solid #dbdbdb',

    }
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;