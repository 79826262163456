import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles((theme?: Theme) =>
  createStyles({
    inputWidth:{
      width: '100%',
      backgroundColor: "#fff",
    },
    instructions:{
      color: '#a0a5b9',
    },
    sourceLangSelect:{
      "&.MuiSelect-outlined":{
        borderColor: theme?.palette.secondary.main,
      },
    },
    sizeMd:{
      '& .MuiAutocomplete-inputRoot':{
        height: 40,
      }
    },
    sizeSm:{
      '& .MuiFormLabel-root':{
        fontSize: 14,
        marginTop: '-2px'
      },
      '& .MuiAutocomplete-inputRoot':{
        minHeight: 35,
        paddingTop: '3px !important'
      },
      '& .MuiAutocomplete-input':{
        fontSize: 14
      }
    }
  }));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;