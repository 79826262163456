import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles((theme?: Theme) =>
    createStyles({
        inputWidth: {
            width: '100%',
            '& label': {
                transform: 'translate(14px, 12px) scale(1) !important'
            }
        },
        // instructions:{
        //     color: '#a0a5b9',
        // },
        langSelect: {
            "&.MuiSelect-outlined": {
                borderColor: theme?.palette.secondary.main,
            },
            "& .redBorder": {
                // "& fieldset":{
                //     borderColor: '#f44336'
                // }
            }
        },
        overviewChipStyle: {
            backgroundColor: "#eceff1",
            height: '32px',
            borderRadius: '16px',
            fontSize: '12px',
            lineHeight: '28px',
            color: '#464555',
            fontFamily: "Poppins Emoji",
            marginBottom: 15,

        },
        overviewChipIcon: {
            // marginLeft: "18px",
            height: '16px',
        },
        chipsWraper: {
            position: 'absolute',
            left: 0
        }
    }));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;