import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useStyles from "./ServicesListViewStyles"
import {
    MBox, MButton, MIconButton, MPagination,
    MAccordion, MAccordionDetails, MAccordionSummary, MCircularProgress, MSkeleton, MLink
} from 'src/app/material-ui';
import { gql } from "apollo-boost";
import { FormattedMessage } from 'react-intl';
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { EmptyContent } from 'src/app/components';
import { withRouter } from 'react-router-dom';
import { MDialogActions } from 'src/app/material-ui/dialog-actions/DialogActions';
import { MDialogContent } from 'src/app/material-ui/dialog-content/DialogContent';
import { MDialogTitle } from 'src/app/material-ui/dialog-title/DialogTitle';
import { MDialog } from 'src/app/material-ui/dialog/Dialog';
import { showAlert } from "src/app/store";
import { useDispatch } from "react-redux";

import { MAddIcon, MDeleteIcon, MEditIcon } from 'src/app/material-ui/icons';
import { useIntl } from "react-intl";
import { Tooltip } from '@mui/material';


const ALL_VENDORS_SERVICES_GQL = gql`
    query getAllPackages($role:String, $page:Int) {
        getAllPackages(role:$role, page:$page){
        packages{
            id
            name
            description
            vendorPackagesServices {
                id
                name
                priority
            }
           }
           total
        }
    }
`;
const DELETED_VENDORS_SERVICE = gql`
    mutation deleteVendorService($moduleId: Int!){
        deleteVendorService(moduleId: $moduleId) 
    }
`;

function ServicesListView(props: any) {

    const dispatch = useDispatch();
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const [serviceDelete, setServiceDeleted] = useState('');
    const [expanded, setExpanded] = useState<string | false>('panel0');
    const [vendorsServices, setVendorsServices]: any = useState();
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages]: any = useState(0);


    const [getAllVendorServices, { loading: serviceLoading }] = useLazyQuery(ALL_VENDORS_SERVICES_GQL, {
        variables: { page: page, ...(props.linguist && { role: 'linguist' }) },
        fetchPolicy: 'no-cache',
        onCompleted: (data: any) => {
            setVendorsServices(data.getAllPackages.packages);
            setTotalPages(Math.ceil(data.getAllPackages.total / 10));
        },

    });

    const [deleteVendorService, { loading: deleteLoading }] = useMutation(DELETED_VENDORS_SERVICE, {
        fetchPolicy: 'no-cache',
        onCompleted: () => {

            getAllVendorServices();
            handleClose();

            dispatch(showAlert({
                message: 'Service Delete successfully',
                messageType: 'success',
                showAlertMessage: true
            }));

        },
    });

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const dialogCloseYes = () => {

        deleteVendorService({
            variables: {
                moduleId: serviceDelete,
            }

        });
    };

    const handleChangeAccordion = (panel: string) => (event: React.ChangeEvent<any>, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    useEffect(() => {
        getAllVendorServices();
    }, [props.getRefresh]);
    useEffect(() => {
        getAllVendorServices();
    }, [page]);

    useEffect(() => {
        if (props.setServices) {
            props.setServices(vendorsServices)
        }
    }, [vendorsServices])

    const handlePageChange = (event: any, value: any) => {
        setPage(value);
        event?.preventDefault();
    };

    const intl = useIntl();

    return (
        <div>
            <MDialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <MDialogTitle id="alert-dialog-title">
                    <FormattedMessage id="are.sure" />
                </MDialogTitle>
                <MDialogContent>
                    <MBox mr={9}>
                        <FormattedMessage id="clicking.yes.service.deleted" />
                    </MBox>
                </MDialogContent>
                <MDialogActions>
                    <MBox mr={2}>
                        <MLink href="javascript:void(0)" onClick={handleClose} color="inherit">
                            <FormattedMessage id="no" />
                        </MLink>
                    </MBox>
                    <MButton disabled={deleteLoading} className="btnSmall" onClick={dialogCloseYes} variant="contained" color="primary"
                        autoFocus>
                        {deleteLoading && <MCircularProgress color="secondary" size={24} />}
                        <FormattedMessage id="yes" />
                    </MButton>
                </MDialogActions>
            </MDialog>

            {/*{JSON.stringify(vendorsServices)}*/}
            {vendorsServices && (vendorsServices.length > 0) &&
                <>
                    {vendorsServices && vendorsServices.map((serviceItem: any, index: number) => (
                        <MAccordion key={"vendorServices" + index} className={classes.accordionWrap} expanded={expanded === 'panel' + index} onChange={handleChangeAccordion('panel' + index)}>
                            <MAccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                id="acoordion1"
                                className={classes.panelSummary}
                            >
                                <MBox fontFamily="Poppins" fontSize={18}>{serviceItem.name}</MBox>
                            </MAccordionSummary>

                            <MAccordionDetails className={classes.panelBodyWrapper}>

                                <MBox className="actionBtnBox">
                                    <Tooltip arrow placement="top" title="Update Service" >
                                        <MIconButton
                                            onClick={() => {
                                                props.setAddServiceOpen(true);
                                                props.setServiceType("Edit");
                                                props.setSelectServiceId(serviceItem.id);
                                            }
                                                //props.history.push((props.linguist ? '/linguist/services/':'/vendor/services/')+serviceItem.id)
                                            }
                                        >
                                            {/* <NavLink to={'/vendor/services/'+serviceItem.id} sx={{height:26}}> */}
                                            <MEditIcon />
                                            {/* </NavLink> */}
                                        </MIconButton>
                                    </Tooltip>
                                    <Tooltip arrow placement="top" title="Delete" >
                                        <MIconButton aria-label="delete" onClick={() => {
                                            handleClickOpen()
                                            setServiceDeleted(serviceItem.id)
                                        }}>
                                            <MDeleteIcon sx={{ color: '#a0a5b9' }} />
                                        </MIconButton>
                                    </Tooltip>
                                </MBox>

                                <MBox className={classes.panelInner} width="100%" display="flex" flexDirection="column" pt={4}
                                    pb={5}>
                                    <MBox className={classes.workFlowList} display="flex" textAlign="center" flexWrap="wrap">
                                        {serviceItem && serviceItem.vendorPackagesServices && serviceItem.vendorPackagesServices.map((service: any, index: number) => (


                                            <MBox className={classes.workFlowBox} key={service.id} mb={3}>


                                                {/* {
                            // show curved arrow > odd row case
                            ((index+1) % 5 === 0 && (index+1) % 2 !== 0)  &&
                                <div className="curved-arrow-right">&nbsp;</div>
                        } */}

                                                {/* {
                            // show curved arrow > even row case
                            ( ( (index-1) % 5 === 0 && (index+1) % 2 === 0) )  && 
                                <div className="curved-arrow-left">&nbsp;</div>
                        } */}

                                                {((serviceItem.vendorPackagesServices.length % 5 !== 0 && index + 1 !== serviceItem.vendorPackagesServices.length || serviceItem.vendorPackagesServices.length % 5 === 0) &&
                                                    <><MBox className="hr">&nbsp;</MBox>
                                                        <MBox className="arrow">&nbsp;</MBox></>
                                                )}

                                                <MBox width={27} height={27} className="counter" mx="auto" display="flex"
                                                    alignItems="center" textAlign="center"
                                                    justifyContent="center">{service.priority}</MBox>
                                                <MBox mt={2} className="text" width="100%">{service.name}</MBox>

                                            </MBox>
                                        ))}
                                    </MBox>
                                </MBox>
                            </MAccordionDetails>
                        </MAccordion>
                    ))}
                </>
            }
            {(serviceLoading || !vendorsServices && vendorsServices != null) &&
                <>
                    {[0, 1, 2].map((_, index) => (
                        <MAccordion key={"box" + index} className={classes.accordionWrap} expanded={expanded === 'panel' + index} onChange={handleChangeAccordion('panel' + index)}>
                            <MAccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                id="acoordion1"
                                className={classes.panelSummary}
                            >
                                <MSkeleton height={23} width={150} />
                            </MAccordionSummary>
                            <MAccordionDetails className={classes.panelBodyWrapper}>
                                <MBox className="actionBtnBox">
                                    <MSkeleton height={24} width={80} />
                                </MBox>
                                <MBox className={classes.panelInner} width="100%" display="flex" flexDirection="column" pt={4}
                                    pb={5}>
                                    <MBox className={classes.workFlowList} display="flex" textAlign="center" flexWrap="wrap">
                                        {[0, 1, 2, 3, 4].map((val: any, index: number) => (
                                            <MBox className={classes.workFlowBox} key={index} mb={3}>
                                                {(([0, 1, 2, 3, 4].length % 5 !== 0 && index + 1 !== [0, 1, 2, 3, 4].length || [0, 1, 2, 3, 4].length % 5 === 0) &&
                                                    <><MBox className="hr">&nbsp;</MBox>
                                                        <MBox className="arrow">&nbsp;</MBox></>
                                                )}
                                                <MBox width={27} height={27} className="counter" mx="auto" display="flex"
                                                    alignItems="center" textAlign="center"
                                                    justifyContent="center">{index + 1}</MBox>
                                                <MBox mt={2} className="text" width="100%">
                                                    <MSkeleton height={18} width={100} sx={{ margin: 'auto' }} />
                                                </MBox>
                                            </MBox>
                                        ))}
                                    </MBox>
                                </MBox>
                            </MAccordionDetails>
                        </MAccordion>
                    ))}
                </>
            }
            {(totalPages > 1) &&
                <MBox my={4} display="flex" justifyContent="flex-end">
                    <MPagination color="primary"
                        count={totalPages}
                        page={page && page}
                        onChange={handlePageChange}
                    />
                </MBox>
            }
            {!serviceLoading && vendorsServices == null &&
                <EmptyContent
                    isContent={true}
                    title={intl.formatMessage({ id: "no.record.found" })}
                    text={intl.formatMessage({ id: "not.services.listed.create.add.services.profile.clients.get.touch" })}
                    buttonProps={{

                        startIcon: <MAddIcon sx={{ marginRight: -7 }} />,
                        // onClick:() => props.history.push((props.linguist ? '/linguist/services/add-services':'/vendor/services/add-services')),
                        onClick: () => {
                            {
                                // props.linguist &&
                                props.setAddServiceOpen(true);
                                props.setServiceType("Add");
                            }
                        },

                        text: <FormattedMessage id="add.services" />
                    }}
                />
            }
        </div>
    );
}


export default withRouter(ServicesListView);