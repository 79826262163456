/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable */
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { gql } from "apollo-boost";
import { FieldArray } from 'formik';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { FormErrorMessage } from 'src/app/components';
import * as Yup from 'yup';
import {
    MAutoComplete,
    MAvatar,
    MBox,
    MButton,
    MForm,
    MFormik,
    MFormLabel,
    MGrid,
    MSkeleton,
    MTextField,
    MTypography,
    MMenu,
    MMenuItem, MCircularProgress, MLink
} from 'src/app/material-ui';
import { selectedVendorJobName, showAlert } from "src/app/store";
import useStyles from './SendInvoiceStyles';
import { UnitSelect } from './unit-select/UnitSelect';
import { avatarPlaceholder, timeZone } from "../../../../hooks/index";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    MInvoiceReview,
    MInvoicePaidBlue,
    MBriefcase,
    MInvoiceSent,
    MInvoiceReceived,
    MInvoiceSentRevised,
    MInvoiceReceivedRevised,
    MInvoiceReject,
    MInvoiceApproved
} from 'src/app/material-ui/icons/index';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogActions, DialogContent } from "@mui/material";

import CheckPermissions from "src/app/hooks/permissions/CheckPermissions";
const GET_INVOICE_DETAIL = gql`
    query getInvoiceDetail($invoiceId: Int!) {
        getInvoiceDetail(invoiceId: $invoiceId) {
            id
            client {
                id
                profilePicture
                firstName
                lastName
            }
            module {
                title
                description
                instruction
                id
                words
                unit{
                    id
                    name
                }
                industry {
                    id
                    name
                }
                sourceLanguage {
                    id
                    name
                    code
                }
                targetLanguages {
                    id
                    name
                    code
                }
                createdAt
                deadline
            }
            total_amount
            updated_by
            updatedByOrganizationId
            discounted_amount
            tax_amount
            balance
            resource_name
            resource_id
            module_name
            isBankTransfer
            offlineTransactionMediaId
            module_id
            status
            to_user
            toOrganization
            discount_percentage
            tax_percentage
            tasks{
                name
            }
            services{
                id
                serviceName
                serviceId
                target_languages {
                    id
                    name
                    code
                }
                unit_price
                unit
                quantity
                sub_total
            }
            reversion {
                id
                name
                totalAmount
                updatedAt
                logo
                status
                verified
            }
        }
    }`;

const JOB_QUOTE_DETAILS = gql`
    query quoteDetails($moduleName: String!,$quoteId:ID!,$projectId: ID,$jobId: ID) {
        quoteDetails(moduleName: $moduleName,quoteId: $quoteId,projectId: $projectId,jobId: $jobId) {
            id
            isPO
            client {
                id
                profilePicture
                firstName
                lastName
            }
            unitPrice
            module {
                title
                description
                instruction
                id
                words
                unit{
                    id
                    name
                }
                industry {
                    id
                    name
                }
                sourceLanguage {
                    id
                    name
                    code
                }
                targetLanguages {
                    id
                    name
                    code
                }
                createdAt
                deadline
            }
            moduleTl {
                id
                name
                code
            }
            moduleServices {
                id
                name
                swId
                isMatchRate
            }
            analysis{
                id
                new
                fifty_seventyFour
                seventyFive_ninetyEight
                eightyFive_NinetyFour
                ninetyFive_NinetyNine
                hundred
                repetition
                crossFileRepetitions
                langCode
            }
            proposalSent
            createdAt
            quoteResponseId
        }
    }`;

const createVendorInvoice = gql`
    mutation createVendorInvoice($invoiceId: Int,$projectId: Int,$jobId: Int!,$orgId: Int!,$totalAmount: Float!,$discountedAmount: Float!,$taxAmount: Float!,$balance: Float!,$taxPercentage: Float!,$discountPercentage: Float!,$description: String,$services: String,$isUpdate: String,$resourceName: String){
        createVendorInvoice(invoiceId: $invoiceId,projectId: $projectId, jobId:$jobId,orgId:$orgId,totalAmount:$totalAmount,discountedAmount:$discountedAmount,taxAmount:$taxAmount,balance:$balance,taxPercentage:$taxPercentage,discountPercentage:$discountPercentage,description:$description,services:$services,isUpdate:$isUpdate,resourceName: $resourceName)
    }
`;
const unitSelectArr = [
    { id: 1, name: 'Words' },
    { id: 2, name: 'Minutes' },
    { id: 3, name: 'Pages' },
    // {id: 4, name: 'Match Rates'}
];

export default function ViewInvoice(props: any) {
    const classes = useStyles({});
    const dispatch = useDispatch();
    const [jobQuoteDetails, setJobQuoteDetails]: any = useState();
    const [languages, setLanguages] = useState([])
    const [servicesMatchRate, setServicesMatchRate]: any = useState([]);
    const [invoiceDetail, setInvoiceDetail]: any = useState();
    const [invoiceStatus, setInvoiceStatus]: any = useState(0);
    const [updatedBY, setUpdatedBy]: any = useState(0);
    const [serviceFormData, setServiceFormData]: any = useState([]);
    const [quoteRequestId, setQuoteRequestId]: any = useState(0);
    const orgId = useSelector((store: any) => store.orgs.selectedOrgId);
    const appUserRole = useSelector((store: any) => store.userRole.userRole);
    const currentUser = useSelector((store: any) => store.auth.currentUser);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [anchorTransaction, setAnchorTransaction] = React.useState<null | HTMLElement>(null);
    const [invoiceReversion, setInvoiceReversion]: any = useState([]);
    const [selectedReversionInvoiceId, setSelectedReversionInvoiceId]: any = useState(props.invoiceId);
    const [viewDialog, setViewDialog]: any = useState(false)
    const [projectInvoiceEdit, setProjectInvoiceEdit] = useState(false);
    const SelectedOrgId = useSelector((store: any) => store.orgs.selectedOrgId);
    const organizations = useSelector((store: any) => store.orgs.organizations);

    // let quoteRequestId:any = 0;
    // var jobId = window.location.href.split('/vendor/view-invoice/').pop();
    useEffect(() => {
        setProjectInvoiceEdit(CheckPermissions('ROLE_PROJ_INVOICE_EDIT', SelectedOrgId, organizations));

    }, [SelectedOrgId, organizations]);

    useEffect(() => {
        // if(typeof jobId != "undefined" && typeof jobId === "number") {
        //     setQuoteRequestId(jobId);
        //     getJobQuoteDetails();
        // } else {
        //     setQuoteRequestId(props.quoteRequestedId);
        //     getJobQuoteDetails();
        // }
        setQuoteRequestId(props.quoteRequestedId);
        getJobQuoteDetails();
    }, [props.invoiceId, props.quoteRequestedId]);

    const handlePoMenuClose = () => {

        setAnchorEl(null);
    };
    const handleActionTransactionMenu = (event: React.MouseEvent<HTMLElement>) => {

        setAnchorTransaction(event.currentTarget);
    };
    const handleTransactionMenuClose = () => {

        setAnchorTransaction(null);
    };
    const handleActionPoMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const calculateTotalPrice = (values: any) => {
        let total = 0;

        values.serviceForms.forEach((row: any, index: any) => {
            const matchRateValue = getServiceTotalAmount(index);
            if (matchRateValue != 0) {
                total += Number(matchRateValue);
            } else {
                total += (Number(row.number) * Number(row.price));
            }
        });

        return (Number(total)).toFixed(2);
    };

    const calculateTotalPriceAfterDiscount = (values: any) => {

        let total: any;

        total = calculateTotalPrice(values);

        if (values && total && values.discount != '' && values.discount != 0) {
            total = Number(total) - (total * (Number(values.discount) / 100));
        }

        return (Number(total)).toFixed(2);
    };

    const calculateTotalPriceAfterTax = (values: any) => {

        let total: any;

        total = calculateTotalPriceAfterDiscount(values);


        if (values && total && typeof values.taxPercent != "undefined" && values.taxPercent != '' && values.taxPercent != 0) {
            total = Number(total) + (total * (Number(values.taxPercent) / 100));
        }

        return (Number(total)).toFixed(2);
    };



    const getServiceTotalAmount = (index: any) => {
        let amt = 0;
        servicesMatchRate.forEach((row: any) => {
            if (row.index == index && row.totalAmount) {
                amt = (row.totalAmount);
            }
        });

        return amt;
    };

    const removeMatchRateService = (index: any) => {

        const servicesMatchRateArr: any = [];
        servicesMatchRate.forEach((row: any) => {
            if (row.index != index) {
                servicesMatchRateArr.push(row);
            }
        });

        setServicesMatchRate(servicesMatchRateArr);
    };

    const [getJobQuoteDetails] = useLazyQuery(JOB_QUOTE_DETAILS, {
        variables: {
            moduleName: quoteRequestId ? (props.taskId ? "task" : "job") : (props.projectId ? "project" : 'task'),
            quoteId: quoteRequestId ? quoteRequestId : 0,
            projectId: props.projectId,
            jobId: props.taskId
        },
        fetchPolicy: "no-cache",
        onCompleted: (data: any) => {

            // setLanguages(data && data.quoteDetails.module.targetLanguages)
            setLanguages(data && data.quoteDetails.moduleTl);

            // setQuoteAnalysis(data && data.quoteDetails.module.targetLanguages.length == 1 ? data.quoteDetails.analysis : '');
            // setJobQuoteAnalysis(data && data.quoteDetails.analysis);
            setJobQuoteDetails(data && data.quoteDetails);
            dispatch(selectedVendorJobName(data.quoteDetails.module && data.quoteDetails.module.title));

            const servicesArr: any = [];
            if (data.quoteDetails.moduleServices) {
                for (let i = 0; i < data.quoteDetails.moduleServices.length; i++) {
                    servicesArr.push({
                        service: data.quoteDetails.moduleServices[i],
                        targetLanguages: data.quoteDetails.module.targetLanguages,
                        number: data.quoteDetails.module.words,
                        unit: data.quoteDetails.module.unit,
                        price: data.quoteDetails.unitPrice,
                        subTotal: (parseFloat(data.quoteDetails.unitPrice) * parseFloat(data.quoteDetails.module.words))
                    });
                }
            }
            // setStaticFormData(servicesArr);
            // setQuoteResponseId(Number(data.quoteDetails.quoteResponseId));
            // if (props.invoiceId) {
            getInvoiceData();
            // }
            //Setting quote details data over here to get the id and use it when opening invoice drawer from header notification button
            props.setQuoteDetails && props.setQuoteDetails(data && data.quoteDetails);
        }
    } as any);

    const [createInvoice, { loading: createLoading }] = useMutation(createVendorInvoice, {
        fetchPolicy: 'no-cache',
        onCompleted: () => {
            dispatch(showAlert({
                message: 'Invoice updated successfully',
                messageType: 'success',
                showAlertMessage: true
            }));
            props.setViewDetailOpenModal(false);
            // props.setViewDetailOpenModal(false);
            props.setRefreshProject && props.setRefreshProject(!props.refreshProjects);
            if (typeof (props.setSendInvoiceFullWidth) != "undefined") {
                props.setSendInvoiceFullWidth(false);
            }
            props.setUpdate(0)
            // props.setIsFullWidth(false);
        },
    });
    const [getInvoiceData, { loading: isLoadingDraftData }] = useLazyQuery(GET_INVOICE_DETAIL, {
        variables: { invoiceId: parseInt(selectedReversionInvoiceId) },
        fetchPolicy: 'no-cache',
        onCompleted: (data: any) => {
            setInvoiceDetail(data && data.getInvoiceDetail);
            setInvoiceStatus(data && data.getInvoiceDetail && data.getInvoiceDetail.status);
            setUpdatedBy(data && data.getInvoiceDetail && data.getInvoiceDetail.updated_by);
            if (props.setToUser) {
                props.setToUser(data && data.getInvoiceDetail && data.getInvoiceDetail.toOrganization);

            }
            setInvoiceReversion(data && data.getInvoiceDetail);
            setLanguages(data && data?.getInvoiceDetail?.module?.targetLanguages);

            if (!data?.getInvoiceDetail?.module || data?.getInvoiceDetail?.module == null) {
                const langs: any = [];
                for (let k = 0; k < data?.getInvoiceDetail?.services.length; k++) {
                    for (let z = 0; z < data?.getInvoiceDetail?.services[k].target_languages.length; z++) {
                        langs.push(data?.getInvoiceDetail?.services[k].target_languages[z]);
                    }
                }

                setLanguages(langs);
            }

            if (props.setIsAcceptAllow) {
                props.setIsAcceptAllow(data && data.getInvoiceDetail && data.getInvoiceDetail.updatedByOrganizationId != currentUser.id ? 1 : 0);

            }
            if (props.setInvoiceStatus) {
                props.setInvoiceStatus(data && data.getInvoiceDetail && data.getInvoiceDetail.status)
            }
            if (props.setUpdatedInvoice) {
                props.setUpdatedInvoice(data && data.getInvoiceDetail.id);
            }
        },
    });


    useEffect(() => {
        const updatedServices: any = [];
        if (languages && languages.length > 0) {
            if (invoiceDetail && (invoiceDetail.services && invoiceDetail.services.length > 0)) {
                invoiceDetail.services.forEach((element: any) => {

                    updatedServices.push({
                        // service: jobQuoteDetails && jobQuoteDetails.moduleServices && jobQuoteDetails.moduleServices.find((ser: any) => ser.name === element.serviceName),
                        service: { name: element.serviceName },
                        serviceId: element.serviceId,
                        targetLanguages: element.target_languages,
                        number: element.quantity,
                        unit: unitSelectArr.find((un: any) => (un.name).toLowerCase() === (element.unit).toLowerCase()),
                        price: Number(element.unit_price),
                        subTotal: Number(element.sub_total),
                        matchRateId: element.matchRate ? Number(element.matchRate.id) : null,
                        matchRateObj: element.matchRate ? Number(element.matchRate) : null,
                    })
                });
            } else {
                updatedServices.push({
                    service: '',
                    targetLanguages: ((languages && languages.length === 1) ? [languages[0]] : []),
                    number: '',
                    unit: '',
                    price: '',
                    subTotal: '',
                    matchRateId: null,
                    matchRateObj: null
                })
            }
            setServiceFormData(updatedServices)
        }

        if (typeof invoiceReversion != "undefined" && invoiceReversion.reversion) {
            const filterStatus = invoiceReversion && invoiceReversion.reversion.find((el: any) => el.status === 2);
            if (props.setIsPaid) {
                props.setIsPaid(filterStatus);
            }
        }
        if (typeof invoiceReversion != "undefined" && invoiceDetail && invoiceReversion.reversion && invoiceReversion.reversion.length > 0) {
            const latestInvoice = invoiceReversion.reversion[invoiceReversion.reversion.length - 1];
            if (typeof latestInvoice !== "undefined" && latestInvoice) {

                if (latestInvoice.id == invoiceDetail.id) {
                    if (props.setUpdatedInvoiceObject) {
                        props.setUpdatedInvoiceObject(true)
                    }
                } else {
                    if (props.setUpdatedInvoiceObject) {
                        props.setUpdatedInvoiceObject(false)
                    }
                }
            }
        }


    }, [invoiceDetail, languages]);


    // useEffect(() => {
    //
    //     if (jobQuoteDetails && jobQuoteDetails.module && jobQuoteDetails.module.instruction != "") {
    //         // const decodedInstruction = convert(jobQuoteDetails.module.instruction, {});
    //
    //         // setDecodedInstructions(decodedInstruction);
    //     }
    //
    // }, [jobQuoteDetails]);

    const statusArray = [['', <></>],
    [<MBox color='#E69B0E'>Invoice Sent</MBox>, <MInvoiceSent />, <MBox color='#16C68E'>Invoice Received</MBox>, <MInvoiceReceived />],
    [<MBox color='#16C68E'>Invoice Paid</MBox>, <MInvoiceApproved />],
    [<MBox color='#5785eb'>Invoice Approved</MBox>, <MInvoicePaidBlue />],
    [<MBox color='#16C68E'>Invoice Received</MBox>, <MInvoiceSent />],
    [<MBox color='#6665DD'>Invoice Review</MBox>, <MInvoiceReview />],
    [<MBox color='#E69B0E'>Invoice Sent(Revised)</MBox>, <MInvoiceSentRevised />, <MBox color='#E69B0E'>Invoice Received(Revised)</MBox>],
    [<MBox color='#16C68E'>Invoice Received(Revised)</MBox>, <MInvoiceReceivedRevised />],
    [<MBox color='#FF0102'>Invoice Rejected</MBox>, <MInvoiceReject />],
    ];

    const transaction = [
        {
            name: 'Download', onClick: () => (window.location.href = process.env.REACT_APP_API_END_POINT + '/download/file?downloadType=single&fileType=transcript&fileId=' + invoiceDetail.offlineTransactionMediaId)

        },
        // {
        //     name: 'View', onClick: () => (handleViewSlip()),
        // },
    ]

    // const handleViewSlip = () => {
    //     handleTransactionMenuClose(),
    //         setViewDialog(true)
    // };

    // const theme = useTheme();

    // Perform any necessary calculations or formatting

    function formatTime(updatedAt: any) {
        // Perform any necessary calculations or formatting
        const createdAt = updatedAt;
        const date = new Date(createdAt);
        const options: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' }
        const newTime = date.toLocaleTimeString('en-US', options);
        return newTime;
    }

    return (
        <div className="projectRatesComponent">
            <MBox>
                <MGrid container>
                    <MBox width="100%" display="flex" justifyContent="space-between">
                        <MBox width="100%">
                            <MBox width="100%" display="flex" justifyContent="space-between" mb={2}>
                            </MBox>
                        </MBox>
                    </MBox>

                    <MGrid item xs={12}>
                        {((((jobQuoteDetails && jobQuoteDetails.moduleServices) && !isLoadingDraftData) || invoiceDetail) ?
                            <MFormik
                                enableReinitialize="true"
                                initialValues={{
                                    desc: ((invoiceDetail && invoiceDetail.description) ? invoiceDetail.description : ''),
                                    name: '',
                                    serviceForms: serviceFormData, // staticFormData && staticFormData,
                                    discount: ((invoiceDetail && invoiceDetail.discount_percentage) ? invoiceDetail.discount_percentage : 0),
                                    taxPercent: ((invoiceDetail && invoiceDetail.tax_percentage) ? invoiceDetail.tax_percentage : 0),
                                }}
                                validationSchema={Yup.object({
                                    serviceForms: Yup.array().of(
                                        Yup.object().shape({
                                            service: Yup.string().required('Required field'),
                                            targetLanguages: Yup.array().min(1, 'Required field'),
                                            price: Yup.string().required('Required field').matches(/^([0-9]*[1-9][0-9]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$/, "Enter greater than 0"),
                                            unit: Yup.string().required('Required field'),
                                            number: Yup.string().required('Required field').matches(/^([0-9]*[1-9][0-9]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$/, "Enter greater than 0"),
                                        }),
                                    ),
                                    taxPercent: Yup.number().min(0, 'Enter minimum 0').max(100, 'Enter maximum 100'),
                                    discount: Yup.number().min(0, 'Enter minimum 0').max(99, 'Enter maximum 99'),
                                })}
                                onSubmit={(values: any) => {
                                    const servicesArr = [];
                                    for (let index = 0; index < values.serviceForms.length; index++) {
                                        const tlIds = [];
                                        for (let i = 0; i < values.serviceForms[index].targetLanguages.length; i++) {
                                            tlIds.push(values.serviceForms[index].targetLanguages[i].code);
                                        }
                                        servicesArr.push({
                                            quantity: values.serviceForms[index].number,
                                            subTotal: (Number(values.serviceForms[index].number) * Number(values.serviceForms[index].price)),
                                            unit: values.serviceForms[index].unit.id,
                                            unitPrice: Number(values.serviceForms[index].price),
                                            // tlIds: values.serviceForms[index].targetLanguages.join(','),
                                            tlIds: tlIds.join(','),
                                            matchRateId: values.serviceForms[index].matchRateId,
                                            service_id: values.serviceForms[index].serviceId
                                        });
                                    }

                                    const totalAmt = calculateTotalPrice(values);
                                    const discountedAmt = calculateTotalPriceAfterDiscount(values);
                                    const taxAmt = calculateTotalPriceAfterTax(values);
                                    createInvoice({
                                        variables: {
                                            invoiceId: invoiceDetail ? Number(invoiceDetail.id) : 0,
                                            jobId: quoteRequestId,
                                            orgId: orgId,
                                            totalAmount: parseFloat(totalAmt),
                                            isUpdate: "1",
                                            projectId: props.projectId ? props.projectId : 0,
                                            resourceName: props.resourceName ? props.resourceName : '',
                                            discountedAmount: parseFloat(discountedAmt),
                                            taxAmount: parseFloat(taxAmt),
                                            balance: parseFloat(taxAmt),
                                            discountPercentage: values.discount ? parseFloat(values.discount) : 0,
                                            taxPercentage: values.taxPercent ? parseFloat(values.taxPercent) : 0,
                                            description: values.desc ? values.desc : "",
                                            services: JSON.stringify(servicesArr)
                                        }
                                    });

                                }}>
                                {({ errors, touched, setFieldValue, values }: any) => {

                                    return (
                                        <div style={{ margin: '30px 50px 30px 50px', paddingBottom: '76px', borderRadius: '8px' }}>
                                            <MForm>
                                                <MBox color="palette.secondary" className={classes.contentBox} border='1.5px solid #FFC34B' pt={2}
                                                    px={4.5}>


                                                    {!props.isPaid && invoiceStatus != 1 && invoiceStatus != 2 && props.update == 0 && updatedBY != currentUser.id ?
                                                        <>
                                                            <MBox mt={1} mb={1} fontFamily="Poppins" className={"reviewMessage"} ><>
                                                                <InfoOutlinedIcon sx={{ fill: "#36A0F4" }} />
                                                                <>{invoiceDetail && invoiceDetail.client.firstName + ' has requested for updated invoice'}</>
                                                            </>
                                                            </MBox>
                                                            <MBox fontFamily="Poppins" className={classes.invoiceLabel} sx={{ top: '242px' }} >
                                                                <FormattedMessage id="INVOICE" />

                                                            </MBox>
                                                        </>
                                                        :
                                                        <MBox fontFamily="Poppins" className={classes.invoiceLabel} sx={{ top: '195px' }} >
                                                            <FormattedMessage id="INVOICE" />

                                                        </MBox>
                                                    }

                                                    <MBox display="flex" justifyContent="space-between"
                                                        alignItems="center">
                                                        <MBox fontFamily="Poppins" className={classes.invoiceID}  >
                                                            <FormattedMessage
                                                                id="INVOICE" /> #{invoiceDetail && invoiceDetail.id}

                                                            {/*({jobQuoteDetails && jobQuoteDetails.module && jobQuoteDetails.module.title})*/}
                                                        </MBox>
                                                        <MBox display="flex" alignItems="center" mt={2}>
                                                            <MBox mr={1.75}>

                                                                {(invoiceDetail && invoiceDetail.client && invoiceDetail.client.profilePicture ?

                                                                    <MAvatar
                                                                        src={process.env.REACT_APP_PUBLIC_URL + '/' + invoiceDetail.client.profilePicture}
                                                                        alt="logo"
                                                                        sx={{ borderRadius: '50%', width: 28, height: 28, fontSize: 12 }} />
                                                                    :

                                                                    <MAvatar
                                                                        sx={{ borderRadius: '50%', width: 28, height: 28, fontSize: 12 }}>
                                                                        {invoiceDetail && invoiceDetail.client && avatarPlaceholder(`${invoiceDetail && invoiceDetail.client.firstName} ${invoiceDetail && invoiceDetail.client.lastName} `)}

                                                                    </MAvatar>


                                                                )}
                                                            </MBox>
                                                            <MBox color="palette.secondary" maxWidth="737px">
                                                                {invoiceDetail && invoiceDetail.client && invoiceDetail.client.firstName} {invoiceDetail && invoiceDetail.client && invoiceDetail.client.lastName}
                                                            </MBox>
                                                        </MBox>
                                                        <MBox display="flex">
                                                            {props.paymentType &&
                                                                <MBox display="flex" alignItems="center" mr={3}>
                                                                    <span style={{ color: '#a0a5b9', marginRight: 15 }}>
                                                                        <FormattedMessage id="payment.methods" /> :
                                                                    </span>
                                                                    <MBox display="flex" alignItems="center" className={"textCapitalize"}>
                                                                        {props.paymentType}
                                                                    </MBox>
                                                                </MBox>
                                                            }
                                                            <MBox display="flex" alignItems="center" mr={3}>
                                                                <span style={{ color: '#a0a5b9', marginRight: 15 }}>
                                                                    <FormattedMessage id="status" /> :
                                                                </span>
                                                                <MBox display="flex" alignItems="center">
                                                                    {/*<MInvoiceReview />*/}

                                                                    {updatedBY && updatedBY != currentUser.id && typeof statusArray[parseInt(invoiceStatus)][3] != "undefined" ? statusArray[parseInt(invoiceStatus)][3] : statusArray[parseInt(invoiceStatus)][1]}
                                                                    {/*{statusArray[parseInt(invoiceStatus)][1]}*/}
                                                                    &nbsp;
                                                                    {updatedBY && updatedBY != currentUser.id
                                                                        && typeof statusArray[parseInt(invoiceStatus)][2] != "undefined" ?
                                                                        statusArray[parseInt(invoiceStatus)][2] : statusArray[parseInt(invoiceStatus)][0]}


                                                                </MBox>
                                                            </MBox>


                                                            {invoiceReversion.reversion && invoiceReversion.reversion.length > 1 &&
                                                                <MBox display="flex" sx={{ gap: 15 }} >
                                                                    {invoiceDetail && invoiceDetail.isBankTransfer === true &&
                                                                        <>

                                                                            <MBox className={classes.poDropdown}>
                                                                                <MTypography>
                                                                                    <FormattedMessage id="transaction.slip" />
                                                                                </MTypography>
                                                                                <IconButton
                                                                                    aria-label="more"
                                                                                    aria-controls="long-menu"
                                                                                    aria-haspopup="true"
                                                                                    onClick={handleActionTransactionMenu}
                                                                                    size="large">

                                                                                    {anchorTransaction ?
                                                                                        <KeyboardArrowUpIcon /> :
                                                                                        <KeyboardArrowDownIcon />}
                                                                                </IconButton>
                                                                            </MBox>
                                                                        </>
                                                                    }
                                                                    <MBox className={classes.poDropdown}>
                                                                        <MTypography>
                                                                            <FormattedMessage id="invoice.versions" />
                                                                        </MTypography>
                                                                        <IconButton
                                                                            aria-label="more"
                                                                            aria-controls="long-menu"
                                                                            aria-haspopup="true"
                                                                            onClick={handleActionPoMenuClick}
                                                                            size="large">

                                                                            {anchorEl ? <KeyboardArrowUpIcon /> :
                                                                                <KeyboardArrowDownIcon />}
                                                                        </IconButton>
                                                                    </MBox>


                                                                    <MMenu
                                                                        id="long-menuu"
                                                                        anchorEl={anchorTransaction}
                                                                        // getContentAnchorEl={null}
                                                                        keepMounted
                                                                        open={Boolean(anchorTransaction)}
                                                                        onClose={handleTransactionMenuClose}
                                                                        className={`${classes.menuList} ${classes.menuTransaction}`}
                                                                        PaperProps={{}}
                                                                        MenuListProps={{
                                                                            style: {
                                                                                padding: 0,
                                                                            },
                                                                        }}


                                                                    >

                                                                        {transaction.map((item: any, idx: number) => (
                                                                            <MMenuItem
                                                                                key={idx}
                                                                                onClick={item.onClick}
                                                                            >
                                                                                <MBox className={classes.dropdownMenu}>
                                                                                    {item.name}
                                                                                </MBox>

                                                                            </MMenuItem>
                                                                        ))}
                                                                    </MMenu>
                                                                    <MMenu
                                                                        id="long-menu"
                                                                        anchorEl={anchorEl}
                                                                        // getContentAnchorEl={null}
                                                                        keepMounted
                                                                        open={Boolean(anchorEl)}
                                                                        onClose={handlePoMenuClose}
                                                                        className={classes.menuList}

                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'right',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'right',
                                                                        }}
                                                                        PaperProps={{}}
                                                                        MenuListProps={{
                                                                            style: {
                                                                                padding: 0,
                                                                            },
                                                                        }}


                                                                    >



                                                                        {invoiceReversion?.reversion && invoiceReversion?.reversion?.map((item: any, index: any) => (
                                                                            <MMenuItem
                                                                                key={index}
                                                                                onClick={() => {
                                                                                    // setReversionId(item.id);
                                                                                    setSelectedReversionInvoiceId(item.id);
                                                                                    handlePoMenuClose()
                                                                                }
                                                                                }>
                                                                                <MBox className={classes.dropdownMenu}>
                                                                                    <MBox display="flex"
                                                                                        justifyContent="space-between"
                                                                                        fontSize={12}
                                                                                        fontFamily="Poppins">
                                                                                        <MBox>{item.name}</MBox>
                                                                                        <MBox>${item.totalAmount}</MBox>
                                                                                    </MBox>
                                                                                    <MBox display="flex" alignItems="center"
                                                                                        mt={0.75}>
                                                                                        <MAvatar
                                                                                            src="/assets/images/avatar.jpg">

                                                                                        </MAvatar>
                                                                                        <MBox color="#A0A5B9" ml={0.75}
                                                                                            fontSize={12}
                                                                                            fontFamily="Poppins">
                                                                                            {item.updatedAt && timeZone(item.updatedAt, currentUser, 0)}
                                                                                            &nbsp; &nbsp;
                                                                                            {item.updatedAt && formatTime(item.updatedAt)}
                                                                                        </MBox>
                                                                                    </MBox>
                                                                                </MBox>
                                                                            </MMenuItem>
                                                                        )
                                                                        )}
                                                                    </MMenu>
                                                                </MBox>
                                                            }
                                                        </MBox>
                                                    </MBox>


                                                    {/*  <MBox display="flex" alignItems="center" mt={2}>
                                                    <MBox mr={1.75}>
                                                        {(jobQuoteDetails && jobQuoteDetails.client && jobQuoteDetails.client.profilePicture ?
                                                            <MAvatar
                                                                src={process.env.REACT_APP_PUBLIC_URL + '/' + jobQuoteDetails.client.profilePicture}
                                                                alt="logo" width="28" height="28"
                                                                sx={{ borderRadius: '50%' }} />
                                                            :
                                                            <MAvatar
                                                                sx={{ width: 28, height: 28, fontSize: 12 }}>
                                                                {jobQuoteDetails.client && avatarPlaceholder(`${jobQuoteDetails.client.firstName} ${jobQuoteDetails.client.lastName} `)}
                                                            </MAvatar>
                                                        )}
                                                    </MBox>
                                                    <MBox color="palette.secondary" maxWidth="737px">
                                                        {jobQuoteDetails && jobQuoteDetails.client && jobQuoteDetails.client.firstName} {jobQuoteDetails && jobQuoteDetails.client && jobQuoteDetails.client.lastName}
                                                    </MBox>
                                                </MBox> */}
                                                    {invoiceDetail && invoiceDetail.module && invoiceDetail.module.instruction &&
                                                        <MBox className={classes.instructionWrap} mt={2}
                                                            color="palette.secondary">
                                                            <MBox fontSize={14} color="#3a3a3a"
                                                                fontFamily="Poppins" mb={1.5}><FormattedMessage
                                                                    id="instruction" />:</MBox>
                                                            <div
                                                                dangerouslySetInnerHTML={{ __html: invoiceDetail.module.instruction }} />
                                                        </MBox>
                                                    }
                                                    <MBox className={classes.hr} my={5}></MBox>
                                                    <MBox mb={4} pl={1.1} display="flex">

                                                        {(invoiceDetail && invoiceDetail.tasks.length > 0) ?
                                                            <>
                                                                <MBox flex='1 1 0'>
                                                                    <MBox mb={1.8}
                                                                        className={classes.greyUppercaseText}><FormattedMessage
                                                                            id="vendor.job" />
                                                                    </MBox>
                                                                    <MBox display="flex" flexWrap='wrap'>

                                                                        {invoiceDetail.tasks.map((item: any, idx: number) => (
                                                                            <MBox key={idx} className={classes.jobTasks}>
                                                                                <MBox display="flex">
                                                                                    <MBox mr={0.75}>
                                                                                        <MBriefcase />
                                                                                    </MBox>
                                                                                    <MBox
                                                                                        className={classes.taskName}>
                                                                                        <>{item.name}</>
                                                                                    </MBox>
                                                                                </MBox>
                                                                            </MBox>
                                                                        ))}
                                                                    </MBox>
                                                                </MBox>
                                                            </>
                                                            :
                                                            ''
                                                        }
                                                    </MBox>
                                                    <MBox mb={4} display="flex">
                                                        <MBox display='flex' width='40%' justifyContent='space-between'>
                                                            <MBox flex='1 1 0'>
                                                                <MBox mb={1.8}
                                                                    className={classes.greyUppercaseText}><FormattedMessage
                                                                        id="source.language" /></MBox>
                                                                <MBox>{invoiceDetail && invoiceDetail.module && invoiceDetail.module.sourceLanguage.name}</MBox>
                                                            </MBox>
                                                            <MBox flex='1 1 0'>
                                                                <MBox mb={1.8}
                                                                    className={classes.greyUppercaseText}><FormattedMessage
                                                                        id="industry" /></MBox>
                                                                <MBox>{invoiceDetail && invoiceDetail.module && invoiceDetail.module.industry && invoiceDetail.module.industry.name}</MBox>
                                                            </MBox>
                                                        </MBox>
                                                    </MBox>
                                                    {
                                                        (updatedBY == currentUser.id || props.update == 0) ?
                                                            <>
                                                                <MBox mb={1} py={1.3} px={2.5} width="100%" display="flex" justifyContent="space-between" alignItems="left" className={classes.leftCol} >
                                                                    {/* <MBox width="100%" display="flex" justifyContent="space-between" textAlign="left" 
                                                                        className={classes.leftCol}>*/}
                                                                    <MBox flex='1 1 0' color="#6b778c" >
                                                                        <FormattedMessage id="services" />
                                                                    </MBox>
                                                                    <MBox flex='1 1 0' color="#6b778c" >
                                                                        <FormattedMessage id="target.languages" />
                                                                    </MBox>
                                                                    <MBox flex='1 1 0' color="#6b778c" >
                                                                        <FormattedMessage id="unit.price" />
                                                                    </MBox>
                                                                    <MBox flex='1 1 0' color="#6b778c" >
                                                                        <FormattedMessage id="qty" />
                                                                    </MBox>
                                                                    <MBox flex='1 1 0' color="#6b778c" className={classes.subCol} textAlign="right">
                                                                        <FormattedMessage id="subtotal" />
                                                                    </MBox>
                                                                    {/* </MBox> */}
                                                                </MBox>
                                                            </>
                                                            :
                                                            <>
                                                                <MBox display="flex" py={1.8} px={1.3} justifyContent="space-between"
                                                                    alignItems="center" className={"gap-5"}>
                                                                    <MBox flex='1 1 0'>
                                                                        <MBox className={classes.greyUppercaseText}><MFormLabel
                                                                            className={classes.formLabel} component="legend"
                                                                            required>
                                                                            <FormattedMessage id="services" />
                                                                        </MFormLabel></MBox>
                                                                    </MBox>
                                                                    <MBox flex='1 1 0'>
                                                                        <MBox className={classes.greyUppercaseText}><MFormLabel
                                                                            className={classes.formLabel} component="legend"
                                                                            required><FormattedMessage
                                                                                id="target.languages" /></MFormLabel></MBox>
                                                                    </MBox>
                                                                    <MBox flex='1 1 0'>
                                                                        <MBox className={classes.greyUppercaseText}><MFormLabel
                                                                            className={classes.formLabel} component="legend"
                                                                            required><FormattedMessage
                                                                                id="unit.price" /> ($)</MFormLabel></MBox>
                                                                    </MBox>
                                                                    <MBox flex='1 1 0'>
                                                                        <MBox className={classes.greyUppercaseText}><MFormLabel
                                                                            className={classes.formLabel} component="legend"
                                                                            required><FormattedMessage id="qty" />
                                                                        </MFormLabel></MBox>
                                                                    </MBox>
                                                                    <MBox flex='1 1 0'>
                                                                        <MBox textAlign="left"
                                                                            className={classes.greyUppercaseText}><MFormLabel
                                                                                className={classes.formLabel} component="legend"
                                                                                required><FormattedMessage
                                                                                    id="select.unit" /></MFormLabel></MBox>
                                                                    </MBox>
                                                                    <MBox flex='2 1 0' display='flex'  >
                                                                        <MBox className={classes.greyUppercaseText}
                                                                            textAlign="right">
                                                                            <FormattedMessage id="subtotal" />
                                                                        </MBox>
                                                                    </MBox>

                                                                    {/* <MBox width="42.4%" display="flex"
                                                                        justifyContent="space-between" p={2}
                                                                        className={classes.headerLeftCol}> */}


                                                                    {/* </MBox> */}

                                                                    {/* <MBox width="57.5%"> */}
                                                                    {/* <MBox display="flex" justifyContent="space-between" p={2}
                                                                            pl={0}
                                                                            className={classes.headerRightCol}
                                                                            alignItems="center"> */}
                                                                    {/*                                                                 
                                                                    <MBox display="flex" minWidth={320}
                                                                        justifyContent="space-between">
                                                                      
                                                                      
                                                                    </MBox> */}

                                                                    {/* </MBox> */}
                                                                    {/* </MBox> */}
                                                                </MBox>
                                                            </>
                                                    }
                                                    <FieldArray name="serviceForms" render={arrayHelper => (
                                                        <>
                                                            {values?.serviceForms && values.serviceForms.map((formValue: any, index: number) => (

                                                                (updatedBY == currentUser.id || props.update == 0) ?
                                                                    <>

                                                                        <MBox width="100%" display="flex" textAlign="left"
                                                                            justifyContent="space-between" borderRadius={4} p={1.3} className={classes.ratesList} mb={1.25}>
                                                                            <MBox flex='1 1 0' className={"textOverflow"}>
                                                                                {values.serviceForms[index].service.name}
                                                                            </MBox>

                                                                            <MBox flex='1 1 0' className={"textOverflow"}>
                                                                                {typeof values.serviceForms[index].targetLanguages[index] != "undefined" && values.serviceForms[index].targetLanguages[index].name}
                                                                                &nbsp;
                                                                                ({formValue?.targetLanguages[index]?.code})

                                                                            </MBox>

                                                                            <MBox flex='1 1 0'>
                                                                                {values.serviceForms[index].price}
                                                                            </MBox>
                                                                            <MBox flex='1 1 0'>
                                                                                {values.serviceForms[index].number + '  ' + values.serviceForms[index].unit.name}
                                                                            </MBox>
                                                                            <MBox flex='1 1 0' className={classes.subCol}
                                                                                textAlign="right">
                                                                                ${values.serviceForms[index].subTotal + ".00"}
                                                                            </MBox>
                                                                        </MBox>

                                                                    </>
                                                                    :
                                                                    <>
                                                                        <MBox key={index} py={1.8} px={1.3} display="flex" position="relative" borderRadius={5} className={classes.bgLightGrey}>
                                                                            <MBox width="100%" className={"gapDefault"}
                                                                                display="flex" justifyContent="space-between"
                                                                                alignItems="center"
                                                                                borderRadius={4}>
                                                                                {/* <MBox width="42.5%" display="flex"
                                                                                    justifyContent="space-between" p={2}
                                                                                    className={classes.leftCol}> */}
                                                                                <MBox position="relative" flex='1 1 0'>
                                                                                    <FormattedMessage id="select.service">
                                                                                        {selectService =>
                                                                                            <MAutoComplete
                                                                                                id="services"
                                                                                                value={values.serviceForms[index].service}
                                                                                                options={jobQuoteDetails.moduleServices ? jobQuoteDetails.moduleServices : []}
                                                                                                getOptionLabel={(option: any) => option.name}
                                                                                                className={classes.autoCompleteSizeSm}
                                                                                                disableClearable={true}
                                                                                                disabled={updatedBY == currentUser.id || props.update == 0}
                                                                                                label={String(selectService)}
                                                                                                onChange={(event: any, value: any) => {
                                                                                                    let alreadyPaired: any = false;
                                                                                                    let alreadyFoundLang: any;
                                                                                                    if (values.serviceForms[index].targetLanguages.length > 0) {
                                                                                                        values.serviceForms[index].targetLanguages.find((lang: any) => {
                                                                                                            values.serviceForms.find((obj: any, i: number) => {
                                                                                                                if (
                                                                                                                    (i !== index) &&
                                                                                                                    (((obj.service !== "") && obj.service.name) === (value.name && value.name)) &&
                                                                                                                    (obj.targetLanguages.find((sLang: any) => sLang.id === lang.id))
                                                                                                                ) {
                                                                                                                    alreadyPaired = true;
                                                                                                                    alreadyFoundLang = languages.find((l: any) => l.id === Number(lang.id));
                                                                                                                }
                                                                                                            })
                                                                                                        });
                                                                                                    }

                                                                                                    if (alreadyPaired === true) {
                                                                                                        alert(alreadyFoundLang.name + ' already paired with ' + value.name)
                                                                                                    } else {
                                                                                                        setFieldValue(`serviceForms.${index}.service`, value);
                                                                                                        setFieldValue(`serviceForms.${index}.serviceId`, value.id);
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        }
                                                                                    </FormattedMessage>
                                                                                    <FormErrorMessage
                                                                                        message={
                                                                                            touched.serviceForms && touched.serviceForms[index] && touched.serviceForms[index].service && touched.serviceForms[index].service ?
                                                                                                errors.serviceForms && errors.serviceForms[index] && errors.serviceForms[index].service && errors.serviceForms[index].service : ''
                                                                                        }
                                                                                    />
                                                                                </MBox>
                                                                                <MBox position="relative" flex='1 1 0'>
                                                                                    <FormattedMessage id="project.create.select.languages">
                                                                                        {selectLang =>
                                                                                            <>
                                                                                                {/* <MBox>
                                                                                        {values.serviceForms[index].targetLanguages[0]}
                                                                                        </MBox> */}


                                                                                                <MAutoComplete
                                                                                                    id="target_lang"
                                                                                                    // multiple
                                                                                                    disabled={updatedBY == currentUser.id || props.update == 0}
                                                                                                    filterSelectedOptions
                                                                                                    options={languages && languages}
                                                                                                    defaultValue={values.serviceForms[index].targetLanguages[0]}
                                                                                                    getOptionLabel={(option: any) => option.name}
                                                                                                    className={classes.autoCompleteSizeSm}
                                                                                                    // disableClearable={true}
                                                                                                    label={String(selectLang)}
                                                                                                    limitTags={2}
                                                                                                    onChange={(event: any, value: any) => {
                                                                                                        const arrVal = [value];
                                                                                                        let alreadyPaired: any = false;
                                                                                                        let alreadyFoundLang: any;
                                                                                                        if (values.serviceForms[index].service !== "") {
                                                                                                            arrVal.find((lang: any) => {
                                                                                                                values.serviceForms.find((obj: any, i: number) => {
                                                                                                                    if (
                                                                                                                        (i !== index) &&
                                                                                                                        (obj.service.id && obj.service.name === (values.serviceForms[index].service.name && values.serviceForms[index].service.name)) &&
                                                                                                                        (obj.targetLanguages.find((sLang: any) => sLang.id === lang.id))
                                                                                                                    ) {
                                                                                                                        alreadyPaired = true;
                                                                                                                        alreadyFoundLang = languages.find((l: any) => l.id === Number(lang.id));
                                                                                                                    }

                                                                                                                })
                                                                                                            });
                                                                                                        }
                                                                                                        if (alreadyPaired === true) {
                                                                                                            alert(alreadyFoundLang.name + ' already paired with ' + values.serviceForms[index].service.name)
                                                                                                            setFieldValue(`serviceForms.${index}.targetLanguages`, []);
                                                                                                        } else {
                                                                                                            setFieldValue(`serviceForms.${index}.targetLanguages`, arrVal);
                                                                                                        }

                                                                                                        // setQuoteAnalysis(result);

                                                                                                    }}
                                                                                                />
                                                                                            </>
                                                                                        }
                                                                                    </FormattedMessage>
                                                                                    <FormErrorMessage
                                                                                        message={
                                                                                            touched.serviceForms && touched.serviceForms[index] && touched.serviceForms[index].targetLanguages && touched.serviceForms[index].targetLanguages ?
                                                                                                errors.serviceForms && errors.serviceForms[index] && errors.serviceForms[index].targetLanguages && errors.serviceForms[index].targetLanguages : ''
                                                                                        }
                                                                                    />
                                                                                </MBox>
                                                                                {/* </MBox>
                                                                                <MBox width="57.5%"> */}
                                                                                {/* <MBox display="flex"
                                                                                        justifyContent="space-between"
                                                                                        p={2} pl={0}
                                                                                        className={classes.rightCol}> */}
                                                                                <MBox
                                                                                    className={classes.greyUppercaseText}
                                                                                    flex='1 1 0'>
                                                                                    <MTextField
                                                                                        disabled={updatedBY == currentUser.id || props.update == 0}
                                                                                        name={`serviceForms.${index}.price`}
                                                                                        variant="outlined"
                                                                                        color="secondary"
                                                                                        size="small"
                                                                                        defaultValue={values.serviceForms[index].price}
                                                                                        className={`${classes.smallInput} ${classes.inputWhite}`}
                                                                                        sx={{ width: 0 }}
                                                                                        type="number"
                                                                                        inputProps={{
                                                                                            'placeholder': 'Price',
                                                                                            readOnly: (getServiceTotalAmount(index)) > 0 ? true : false,
                                                                                            step: 'any'
                                                                                        }}
                                                                                        fullWidth
                                                                                    />
                                                                                </MBox>
                                                                                {/* <MBox display="flex"
                                                                                    justifyContent="space-between"
                                                                                    minWidth={320}> */}
                                                                                <MBox position="relative" flex='1 1 0'>

                                                                                    {getServiceTotalAmount(index) == 0 &&
                                                                                        <MTextField
                                                                                            name={`serviceForms.${index}.number`}
                                                                                            variant="outlined"
                                                                                            type="number"
                                                                                            disabled={updatedBY == currentUser.id || props.update == 0}
                                                                                            value={values.serviceForms[index].number}
                                                                                            color="secondary"
                                                                                            size="small"
                                                                                            className={`${classes.smallInput} ${classes.inputWhite}`}
                                                                                            sx={{ height: 35, width: 0 }}
                                                                                            inputProps={{
                                                                                                'placeholder': 'QTY',
                                                                                            }}
                                                                                            fullWidth
                                                                                        />}


                                                                                </MBox>
                                                                                <MBox position="relative" flex='1 1 0'>
                                                                                    <UnitSelect size="sizeSm"
                                                                                        value={values.serviceForms[index].unit}
                                                                                        defaultValue={values.serviceForms[index].unit}
                                                                                        disabled={updatedBY == currentUser.id || props.update == 0}
                                                                                        name={`serviceForms.${index}.unit`}
                                                                                        setFieldValue={setFieldValue}
                                                                                        // setServiceIndex={setServiceIndex}
                                                                                        index={index}
                                                                                        readOnly={(getServiceTotalAmount(index)) > 0 ? true : false}
                                                                                        selectedService={values.serviceForms[index]}
                                                                                    // setSelectedService={setSelectedService}
                                                                                    // setShowAddMatchRateModal={setShowAddMatchRateModal}
                                                                                    // setIsEdit={setIsEdit}
                                                                                    />
                                                                                    <FormErrorMessage
                                                                                        message={
                                                                                            touched.serviceForms && touched.serviceForms[index] && touched.serviceForms[index].unit && touched.serviceForms[index].unit ?
                                                                                                errors.serviceForms && errors.serviceForms[index] && errors.serviceForms[index].unit && errors.serviceForms[index].unit : ''
                                                                                        }
                                                                                    />
                                                                                </MBox>
                                                                                {/* </MBox> */}

                                                                                {getServiceTotalAmount(index) > 0 ?
                                                                                    <MBox flex='1 1 0'
                                                                                    >${(getServiceTotalAmount(index))}</MBox> :
                                                                                    <MBox flex='1 1 0'>${
                                                                                        (Number(values.serviceForms[index].price) * Number(values.serviceForms[index].number)).toFixed(2)}
                                                                                    </MBox>}

                                                                                {props.isAcceptAllow && invoiceStatus != 4 && props.update == 1 && updatedBY != currentUser.id ?
                                                                                    <MBox display="flex" alignItems="center" justifyContent="end"
                                                                                        flex='1 1 0' pr={1.2}
                                                                                    >
                                                                                        <DeleteOutlineIcon
                                                                                            // className={classes.deleteIcon}
                                                                                            onClick={() => {
                                                                                                arrayHelper.remove(index);
                                                                                                removeMatchRateService(index);
                                                                                            }} />
                                                                                    </MBox>


                                                                                    :

                                                                                    null}
                                                                            </MBox>

                                                                        </MBox>
                                                                    </>

                                                            ))}
                                                            <MBox pb={2}>
                                                                {invoiceStatus == 4 && appUserRole != "ROLE_CLIENT" ?
                                                                    <MBox
                                                                        className={classes.addRowButton}
                                                                        onClick={() => arrayHelper.push({
                                                                            service: '',
                                                                            targetLanguages: ((languages && languages.length === 1) ? [languages[0]] : []),
                                                                            number: '',
                                                                            unit: '',
                                                                            price: '',
                                                                            subTotal: ''
                                                                        })}
                                                                    >
                                                                        <MBox px={1}><span
                                                                            style={{ fontSize: 16 }}>+</span><FormattedMessage
                                                                                id="add.line" /></MBox>
                                                                    </MBox> : null
                                                                }
                                                            </MBox>
                                                        </>
                                                    )} />
                                                    <MBox pt={2} pb={4}>
                                                        <MBox display="flex" justifyContent="flex-end">
                                                            <MBox width="50%" pr={2.7}>
                                                                <MBox display="flex" justifyContent="space-between"
                                                                    alignItems="center" pb={1} pl={2}>
                                                                    <MBox
                                                                        className={classes.colorLightGrey}><FormattedMessage
                                                                            id="total.price" /></MBox>
                                                                    <MBox>${calculateTotalPrice(values)}</MBox>
                                                                </MBox>

                                                                <MBox display="flex" justifyContent="space-between"
                                                                    alignItems="center">
                                                                    <MBox width="59%"
                                                                        className={`${classes.bgLightGrey}`}
                                                                        display="flex"
                                                                        justifyContent="space-between"
                                                                        alignItems="center" p={2}>
                                                                        <MBox width="50%"
                                                                            className={classes.colorLightGrey}><FormattedMessage
                                                                                id="discount" /></MBox>
                                                                        <MBox width="50%" textAlign="right">
                                                                            <MTextField
                                                                                name="discount"
                                                                                disabled={updatedBY == currentUser.id || props.update == 0}
                                                                                variant="outlined"
                                                                                color="secondary"
                                                                                size="small"
                                                                                InputProps={{
                                                                                    inputProps: {
                                                                                        min: 0,
                                                                                        max: 99
                                                                                    }
                                                                                }}
                                                                                type="number"
                                                                                defaultValue={values.discount}
                                                                                value={values.discount}
                                                                                fullWidth
                                                                                className={`${classes.smallInput} ${classes.inputWhite} ${classes.discountInput}`}
                                                                                sx={{ width: 0 }}
                                                                                inputProps={{
                                                                                    'placeholder': 'Discount',
                                                                                }}
                                                                            /> %
                                                                        </MBox>
                                                                    </MBox>
                                                                    <MBox>${calculateTotalPriceAfterDiscount(values)}</MBox>
                                                                </MBox>

                                                                <MBox display="flex" justifyContent="space-between"
                                                                    alignItems="center">
                                                                    <MBox width="59%"
                                                                        className={`${classes.bgLightGrey}`}
                                                                        display="flex"
                                                                        justifyContent="space-between"
                                                                        alignItems="center" p={2}>
                                                                        <MBox width="50%"
                                                                            className={classes.colorLightGrey}
                                                                            borderRadius={4}>
                                                                            <FormattedMessage id="tax" />
                                                                        </MBox>
                                                                        <MBox width="50%" textAlign="right">
                                                                            <MTextField
                                                                                name="taxPercent"
                                                                                variant="outlined"
                                                                                disabled={updatedBY == currentUser.id || props.update == 0}
                                                                                color="secondary"
                                                                                size="small"
                                                                                // defaultValue={invoiceDetail && invoiceDetail.tax_percentage}
                                                                                // value={invoiceDetail && invoiceDetail.tax_percentage}
                                                                                type="number"
                                                                                fullWidth
                                                                                className={`${classes.smallInput} ${classes.inputWhite} ${classes.discountInput}`}
                                                                                sx={{ width: 0 }}
                                                                                inputProps={{
                                                                                    'placeholder': 'Tax',
                                                                                }}
                                                                            /> %
                                                                        </MBox>
                                                                    </MBox>

                                                                    <MBox>
                                                                        ${calculateTotalPriceAfterTax(values)}
                                                                    </MBox>
                                                                </MBox>

                                                                {/* <MBox display="flex" justifyContent="space-between"
                                                                    alignItems="center">
                                                                    <MBox
                                                                        className={classes.colorLightGrey}
                                                                        p={2}><FormattedMessage
                                                                            id="paid.to.date" /></MBox>
                                                                    <MBox width="50%" textAlign="right" p={2}>0</MBox>
                                                                </MBox> */}

                                                                <MBox display="flex" justifyContent="space-between"
                                                                    alignItems="center" pl={2}>
                                                                    <MBox
                                                                        className={classes.colorLightGrey}><FormattedMessage
                                                                            id="balance.(USD)" /></MBox>
                                                                    <MBox fontSize={22} color="secondary.main"
                                                                        fontFamily="Poppins Bold">${calculateTotalPriceAfterTax(values)}</MBox>
                                                                </MBox>
                                                            </MBox>
                                                        </MBox>
                                                    </MBox>

                                                    {props.isAcceptAllow && invoiceStatus != 4 && props.update == 1 && updatedBY != currentUser.id ?
                                                        <MBox display="flex" className={classes.footerBottom}
                                                            justifyContent="flex-end" width="100%">
                                                            <MBox>
                                                                {projectInvoiceEdit &&
                                                                    <MButton
                                                                        disabled={createLoading}
                                                                        // disabled={invoiceStatus != 4 || appUserRole == "ROLE_CLIENT"}
                                                                        type="submit" variant="contained" color="primary"
                                                                        className="btnMedium"
                                                                        sx={{ minWidth: '117px !important' }}
                                                                    >
                                                                        {createLoading &&
                                                                            <MCircularProgress color="primary" size={24} />}

                                                                        <FormattedMessage id="update.invoice" />
                                                                    </MButton>}
                                                                <MLink href='#' sx={{ minWidth: 110, marginLeft: 1 }}
                                                                    className="btnMedium"

                                                                    onClick={() => {
                                                                        props.setUpdate(0);
                                                                    }}>
                                                                    <FormattedMessage id="cancel" />
                                                                </MLink>


                                                            </MBox>
                                                        </MBox>
                                                        :

                                                        <>
                                                        </>
                                                    }


                                                </MBox>

                                            </MForm>
                                        </div>
                                    );
                                }}
                            </MFormik>
                            :
                            <MFormik
                                enableReinitialize="true"
                                initialValues={{
                                    desc: ((invoiceDetail && invoiceDetail.description) ? invoiceDetail.description : ''),
                                    name: '',
                                    serviceForms: serviceFormData, // staticFormData && staticFormData,
                                    discount: ((invoiceDetail && invoiceDetail.discountPercentage) ? invoiceDetail.discountPercentage : 0),
                                    taxPercent: ((invoiceDetail && invoiceDetail.taxPercentage) ? invoiceDetail.taxPercentage : 0),
                                }}
                                validationSchema={Yup.object({
                                    serviceForms: Yup.array().of(
                                        Yup.object().shape({
                                            service: Yup.string().required('Required field'),
                                            targetLanguages: Yup.array().min(1, 'Required field'),
                                            price: Yup.string().required('Required field').matches(/^([0-9]*[1-9][0-9]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$/, "Enter greater than 0"),
                                            unit: Yup.string().required('Required field'),
                                            number: Yup.string().required('Required field').matches(/^([0-9]*[1-9][0-9]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$/, "Enter greater than 0"),
                                        }),
                                    ),
                                    taxPercent: Yup.number().min(0, 'Enter minimum 0').max(100, 'Enter maximum 100'),
                                    discount: Yup.number().min(0, 'Enter minimum 0').max(99, 'Enter maximum 99'),
                                })}
                                onSubmit={(values: any) => {
                                    const servicesArr = [];
                                    for (let index = 0; index < values.serviceForms.length; index++) {
                                        const tlIds = [];

                                        for (let i = 0; i < values.serviceForms[index].targetLanguages.length; i++) {
                                            tlIds.push(values.serviceForms[index].targetLanguages[i].code);
                                        }

                                        servicesArr.push({
                                            quantity: values.serviceForms[index].number,
                                            subTotal: (Number(values.serviceForms[index].number) * Number(values.serviceForms[index].price)),
                                            unit: values.serviceForms[index].unit.id,
                                            unitPrice: Number(values.serviceForms[index].price),
                                            // tlIds: values.serviceForms[index].targetLanguages.join(','),
                                            tlIds: tlIds.join(','),
                                            matchRateId: values.serviceForms[index].matchRateId,
                                            service_id: values.serviceForms[index].serviceId
                                        });
                                    }

                                    const totalAmt = calculateTotalPrice(values);
                                    const discountedAmt = calculateTotalPriceAfterDiscount(values);
                                    const taxAmt = calculateTotalPriceAfterTax(values);


                                    createInvoice({
                                        variables: {
                                            jobId: quoteRequestId,
                                            orgId: orgId,
                                            totalAmount: parseFloat(totalAmt),
                                            isUpdate: "1",
                                            discountedAmount: parseFloat(discountedAmt),
                                            taxAmount: parseFloat(taxAmt),
                                            balance: parseFloat(taxAmt),
                                            discountPercentage: values.discount ? parseFloat(values.discount) : 0,
                                            taxPercentage: values.taxPercent ? parseFloat(values.taxPercent) : 0,
                                            description: values.desc ? values.desc : "",
                                            services: JSON.stringify(servicesArr)
                                        }
                                    });

                                }}>
                                {({ values }: any) => {

                                    return (
                                        <MForm>
                                            <MBox color="palette.secondary" className={classes.contentBox} pt={2}
                                                px={4.5}>
                                                <MBox fontFamily="Poppins" fontSize={24} color='#393939'
                                                    mb={1}>
                                                    <MSkeleton width="15%" height="100%" />
                                                    {/*({jobQuoteDetails && jobQuoteDetails.module && jobQuoteDetails.module.title})*/}
                                                </MBox>


                                                {invoiceStatus != 1 && invoiceStatus != 2 && props.update == 0 && updatedBY != currentUser.id &&
                                                    <MBox display="flex"><>
                                                        <MSkeleton width="100%" height="100%" />
                                                    </>
                                                    </MBox>
                                                }


                                                <MBox display="flex" alignItems="center" mt={2}>
                                                    <MBox mr={1.75}>
                                                        <MSkeleton variant='circle' width={40} height={40} />
                                                    </MBox>
                                                    <MBox color="palette.secondary" maxWidth="737px">
                                                        <MSkeleton width={150} height='100%' />
                                                    </MBox>
                                                </MBox>
                                                {jobQuoteDetails && jobQuoteDetails.module && jobQuoteDetails.module.instruction &&
                                                    <MBox className={classes.instructionWrap} mt={2}
                                                        color="palette.secondary">
                                                        <MBox fontSize={14} color="#3a3a3a"
                                                            fontFamily="Poppins" mb={1.5}>
                                                            <MSkeleton width='15%' height='100%' />
                                                        </MBox>
                                                        <MSkeleton width='15%' height='100%' />
                                                    </MBox>
                                                }
                                                <MBox className={classes.hr} my={5}></MBox>
                                                <MBox mb={4} pl={2} display="flex" width="42%">
                                                    <MBox width="calc(50% - -7px)">
                                                        <MBox mb={1.8} className={classes.greyUppercaseText}>
                                                            <MSkeleton width='70%' height='100%' />
                                                        </MBox>
                                                        <MBox>
                                                            <MSkeleton width='50%' height='100%' />
                                                        </MBox>
                                                    </MBox>
                                                    <MBox width="calc(50% - -7px)">
                                                        <MBox mb={1.8} className={classes.greyUppercaseText}>
                                                            <MSkeleton width='70%' height='100%' />
                                                        </MBox>
                                                        <MBox>
                                                            <MSkeleton width='50%' height='100%' />
                                                        </MBox>
                                                    </MBox>
                                                </MBox>
                                                <MBox display="flex" p={2} pl={3.1}
                                                    alignItems="center">
                                                    <MBox width="42%" display="flex"
                                                        className={classes.headerLeftCol}>
                                                        <MBox className={classes.greyUppercaseText}>
                                                            <MFormLabel className={classes.formLabel}
                                                                component="legend">
                                                                <MSkeleton width='50%' height='100%' />
                                                            </MFormLabel>
                                                        </MBox>
                                                        <MBox className={classes.greyUppercaseText}>
                                                            <MFormLabel className={classes.formLabel}
                                                                component="legend">
                                                                <MSkeleton width='50%' height='100%' />
                                                            </MFormLabel>
                                                        </MBox>
                                                    </MBox>

                                                    <MBox width="57.5%">
                                                        <MBox display="flex" justifyContent="space-between" p={2}
                                                            pl={0} className={classes.headerRightCol}
                                                            alignItems="center">
                                                            <MBox className={classes.greyUppercaseText}
                                                                maxWidth={110} minWidth={110} pr="5px">
                                                                <MFormLabel className={classes.formLabel}
                                                                    component="legend">
                                                                    <MSkeleton width='50%' height='100%' />
                                                                </MFormLabel>
                                                            </MBox>
                                                            <MBox display="flex" minWidth={320} pl={5.5}
                                                                justifyContent="space-between">
                                                                <MBox className={classes.greyUppercaseText}
                                                                    minWidth={155} maxWidth={155}>
                                                                    <MFormLabel className={classes.formLabel}
                                                                        component="legend">
                                                                        <MSkeleton width='50%' height='100%' />
                                                                    </MFormLabel>
                                                                </MBox>
                                                                <MBox textAlign="left"
                                                                    className={classes.greyUppercaseText} pl={1.2}
                                                                    minWidth={155} maxWidth={155}><MFormLabel
                                                                        className={classes.formLabel} component="legend"
                                                                    >
                                                                        <MSkeleton width='50%' height='100%' />
                                                                    </MFormLabel></MBox>
                                                            </MBox>
                                                            <MBox className={classes.greyUppercaseText}
                                                                maxWidth={113} minWidth={113} pl={7}>
                                                                <MSkeleton width='50%' height='100%' />
                                                            </MBox>
                                                        </MBox>
                                                    </MBox>
                                                </MBox>

                                                <FieldArray name="serviceForms" render={arrayHelper => (
                                                    <>
                                                        {values.serviceForms && values.serviceForms.map((formValue: any, index: number) => (
                                                            <MBox key={index} display="flex" position="relative">
                                                                <MBox width="100%" className={classes.bgLightGrey}
                                                                    display="flex" justifyContent="space-between"
                                                                    mb={1}
                                                                    borderRadius={4}>
                                                                    <MBox width="42.5%" display="flex"
                                                                        justifyContent="space-between" p={2}
                                                                        className={classes.leftCol}>
                                                                        <MBox position="relative">
                                                                            <MSkeleton width='50%' height='100%' />
                                                                        </MBox>
                                                                        <MBox position="relative">
                                                                            <MSkeleton width='50%' height='100%' />
                                                                        </MBox>
                                                                    </MBox>
                                                                    <MBox width="57.5%">
                                                                        <MBox display="flex"
                                                                            justifyContent="space-between"
                                                                            p={2} pl={0}
                                                                            className={classes.rightCol}>
                                                                            <MBox
                                                                                className={classes.greyUppercaseText}
                                                                                minWidth={110} maxWidth={110}
                                                                                pr="5px">
                                                                                <MSkeleton width='50%'
                                                                                    height='100%' />
                                                                            </MBox>
                                                                            <MBox display="flex"
                                                                                justifyContent="space-between"
                                                                                minWidth={320}>
                                                                                <MBox position="relative"
                                                                                    minWidth={155} maxWidth={155}>
                                                                                    {getServiceTotalAmount(index) == 0 &&
                                                                                        <MSkeleton width='50%'
                                                                                            height='100%' />
                                                                                    }
                                                                                </MBox>
                                                                                <MBox position="relative"
                                                                                    maxWidth={155} minWidth={155}>
                                                                                    <MSkeleton width='50%'
                                                                                        height='100%' />
                                                                                </MBox>
                                                                            </MBox>

                                                                            {getServiceTotalAmount(index) > 0 ?
                                                                                <MBox pt={1} minWidth={70}
                                                                                    maxWidth={70}>
                                                                                    <MSkeleton width='50%'
                                                                                        height='100%' />
                                                                                </MBox>
                                                                                :
                                                                                <MBox pt={1} minWidth={70}
                                                                                    maxWidth={70}>
                                                                                    <MSkeleton width='50%'
                                                                                        height='100%' />
                                                                                </MBox>}
                                                                        </MBox>
                                                                    </MBox>
                                                                    {props.isAcceptAllow && invoiceStatus != 4 && props.update == 1 && updatedBY != currentUser.id
                                                                        ?
                                                                        <MBox display="flex" alignItems="center"
                                                                            className={classes.bgLightGrey}
                                                                            mr={2}>
                                                                            <DeleteOutlineIcon
                                                                                // className={classes.deleteIcon}
                                                                                onClick={() => {
                                                                                    arrayHelper.remove(index);
                                                                                    removeMatchRateService(index);
                                                                                }} />
                                                                        </MBox>
                                                                        :
                                                                        null
                                                                    }
                                                                </MBox>

                                                            </MBox>
                                                        ))}
                                                        <MBox pb={2}>
                                                            {invoiceStatus == 4 && appUserRole != "ROLE_CLIENT" ?
                                                                <MBox
                                                                    className={classes.addRowButton}
                                                                    onClick={() => arrayHelper.push({
                                                                        service: '',
                                                                        targetLanguages: ((languages && languages.length === 1) ? [languages[0]] : []),
                                                                        number: '',
                                                                        unit: '',
                                                                        price: '',
                                                                        subTotal: ''
                                                                    })}
                                                                >
                                                                    <MBox px={1}><span
                                                                        style={{ fontSize: 16 }}>+</span><FormattedMessage
                                                                            id="add.line" /></MBox>
                                                                </MBox> : null
                                                            }
                                                        </MBox>
                                                    </>
                                                )} />
                                                <MBox pt={2} pb={4}>
                                                    <MBox display="flex" justifyContent="flex-end">


                                                        <MBox width="50%" pr={7.6}>
                                                            <MBox display="flex" justifyContent="space-between"
                                                                alignItems="center" pb={1} pl={2}>
                                                                <MBox className={classes.colorLightGrey}>
                                                                    <MSkeleton width={70} height='100%' />
                                                                </MBox>
                                                                <MBox>
                                                                    <MSkeleton width={50} height='100%' />
                                                                </MBox>
                                                            </MBox>

                                                            <MBox display="flex" justifyContent="space-between"
                                                                alignItems="center">
                                                                <MBox width="59%"
                                                                    className={`${classes.bgLightGrey}`}
                                                                    display="flex"
                                                                    justifyContent="space-between"
                                                                    alignItems="center" p={2}>
                                                                    <MBox width="50%"
                                                                        className={classes.colorLightGrey}>
                                                                        <MSkeleton width={70} height='100%' />
                                                                    </MBox>
                                                                    <MBox width="50%" textAlign="right">
                                                                        <MSkeleton width={50} height='100%' />
                                                                    </MBox>
                                                                </MBox>
                                                                <MBox>
                                                                    <MSkeleton width={50} height='100%' />
                                                                </MBox>
                                                            </MBox>

                                                            <MBox display="flex" justifyContent="space-between"
                                                                alignItems="center">
                                                                <MBox width="59%"
                                                                    className={`${classes.bgLightGrey}`}
                                                                    display="flex"
                                                                    justifyContent="space-between"
                                                                    alignItems="center" p={2}>
                                                                    <MBox width="50%"
                                                                        className={classes.colorLightGrey}
                                                                        borderRadius={4}>
                                                                        <MSkeleton width={70} height='100%' />
                                                                    </MBox>
                                                                    <MBox width="50%" textAlign="right">
                                                                        <MSkeleton width={50} height='100%' />
                                                                    </MBox>
                                                                </MBox>
                                                                <MBox>
                                                                    <MSkeleton width={50} height='100%' />
                                                                </MBox>
                                                            </MBox>

                                                            <MBox display="flex" justifyContent="space-between"
                                                                alignItems="center">
                                                                <MBox className={classes.colorLightGrey} p={2}>
                                                                    <MSkeleton width={70} height='100%' />
                                                                </MBox>
                                                                <MBox textAlign="right">
                                                                    <MSkeleton width={50} height='100%' />
                                                                </MBox>
                                                            </MBox>

                                                            <MBox display="flex" justifyContent="space-between"
                                                                alignItems="center" pl={2}>
                                                                <MBox className={classes.colorLightGrey}>
                                                                    <MSkeleton width={70} height='100%' />
                                                                </MBox>
                                                                <MBox fontSize={22} color="secondary.main"
                                                                    fontFamily="Poppins Bold">
                                                                    <MSkeleton width={50} height='100%' />
                                                                </MBox>
                                                            </MBox>
                                                        </MBox>
                                                    </MBox>
                                                </MBox>
                                                {props.isAcceptAllow && invoiceStatus != 4 && props.update == 1 && updatedBY != currentUser.id ?
                                                    <MBox display="flex" className={classes.footerBottom}
                                                        justifyContent="flex-end" width="100%">
                                                        <MBox>
                                                            <MButton
                                                                disabled={createLoading}
                                                                // disabled={invoiceStatus != 4 || appUserRole == "ROLE_CLIENT"}
                                                                type="submit" variant="contained" color="primary"
                                                                className="btnMedium"
                                                                sx={{ minWidth: '117px !important' }}
                                                            >
                                                                {createLoading &&
                                                                    <MCircularProgress color="primary" size={24} />}

                                                                <FormattedMessage id="update.invoice" />
                                                            </MButton>
                                                            <MLink href='#' sx={{ minWidth: 110, marginLeft: 1 }}
                                                                className="btnMedium"

                                                                onClick={() => {
                                                                    props.setUpdate(0);
                                                                }}>
                                                                <FormattedMessage id="cancel" />
                                                            </MLink>
                                                        </MBox>
                                                    </MBox>
                                                    :
                                                    <>
                                                    </>
                                                }
                                            </MBox>
                                        </MForm>
                                    )
                                }}
                            </MFormik>
                        )
                        }
                    </MGrid>
                </MGrid>
                <Dialog
                    // fullScreen={fullScreen}
                    open={viewDialog}
                    onClose={() => setViewDialog(false)}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogContent>
                        <MBox display='flex'>

                            <Toolbar>
                                <MButton autoFocus color="inherit" >
                                    save
                                </MButton>
                                <IconButton
                                    onClick={() => setViewDialog(false)}
                                    edge="start"
                                    color="inherit"
                                    aria-label="close"
                                    size="large">
                                    <CloseIcon />
                                </IconButton>
                            </Toolbar>
                        </MBox>
                    </DialogContent>
                    <DialogActions>
                        {/* <MButton autoFocus onClick={handleClose} color="primary">
                            Disagree
                        </MButton>
                        <MButton onClick={handleClose} color="primary" autoFocus>
                            Agree
                        </MButton> */}
                    </DialogActions>
                </Dialog>
                {/* <MDialog
                    open={viewDialog}
                    // fullScreen={fullScreen}
                    onClose={() => setViewDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">

                    <MDialogTitle id="alert-dialog-title">
                        {/* <AppBar>
                            <Toolbar>
                                <IconButton edge="start" color="inherit" aria-label="close">
                                    <CloseIcon />
                                </IconButton>

                                <MButton autoFocus color="inherit" >
                                    save
                                </MButton>
                            </Toolbar>
                        </AppBar> */}
                {/* <FormattedMessage id="are.sure" />
                        <IconButton edge="start" color="inherit" aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </MDialogTitle>
                    <MDialogContent dividers>
                        <MBox mr={9}><FormattedMessage id="Are you sure you want to" /> </MBox>
                    </MDialogContent>
                    <MDialogActions>
                        <MBox mr={1}>
                            <MLink href="javascript:void(0)" onClick={() => setViewDialog(false)} color="inherit">
                                <FormattedMessage id="no" />
                            </MLink >
                        </MBox>
                        <MButton variant="contained" color="primary" autoFocus>
                            <FormattedMessage id="yes" />
                        </MButton>
                    </MDialogActions>
                </MDialog> */}
            </MBox>

        </div>
    );
}