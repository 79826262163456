import React, { Fragment } from 'react'

export function MBuyCredits() {
    return (
        <Fragment>

            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.75 4.19985H3.75012C3.1057 4.19985 2.49609 3.9021 2.07387 3.40073C2.48625 2.90948 3.08566 2.60011 3.75012 2.60011H17.2501C17.665 2.60011 18 2.24162 18 1.80024C18 1.35887 17.6646 1 17.2501 1H3.75012C1.67941 1 0 2.79136 0 5.00009V12.9999C0 15.2086 1.67941 17 3.75012 17H15.75C16.9928 17 18 15.9256 18 14.6V6.59983C18 5.27422 16.9928 4.19985 15.75 4.19985ZM16.4999 14.5996C16.4999 15.0403 16.1638 15.3995 15.75 15.3995H3.75012C2.50945 15.3995 1.50012 14.3229 1.50012 12.9995V4.99822C2.14066 5.50934 2.92816 5.79996 3.75012 5.79996H15.75C16.1641 5.79996 16.4999 6.15921 16.4999 6.59983V14.5996ZM14.9998 10.5996C14.9998 11.0413 14.6637 11.3994 14.2499 11.3994C13.8361 11.3994 13.5 11.0409 13.5 10.5996C13.5 10.1582 13.8361 9.79968 14.2499 9.79968C14.6637 9.79968 14.9998 10.1582 14.9998 10.5996Z" fill="#6B778C" />
            </svg>

        </Fragment>
    )
}
