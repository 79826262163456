/* eslint-disable */
import React from 'react'
import Box from '@mui/material/Box';

// export function MBox(props: any) {
//     const { alignItem, isSelected, ...restProps } = props;
//     return (
//         <Box {...restProps} />
//     )
// }
export const MBox: any = React.forwardRef((props, ref) => {
    const { removeDelete, removeItem, alignItem, isSelected, totalJobs, ...restProps }: any = props;
    return <Box {...restProps} ref={ref} />;
});

