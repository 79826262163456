import React, { Suspense, useEffect } from 'react';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import LinearProgress from '@mui/material/LinearProgress';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAuth, setSidebarType, setUserRole } from 'src/app/store';
import * as RouteImports from './RouteImports';
// import {StyleGuideListing} from '../screens/client-screens/setting/tabs-data/style-guide-tab/StyleGuideListing';
const progressStyle: any = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
}

function Routing() {
    const dispatch = useDispatch();
    const token = localStorage.getItem('access_token');
    const sidebarType = localStorage.getItem('side_bar_type');
    const userRole = localStorage.getItem('user_role');

    useEffect(() => {
        // check authentication and set token in redux
        if (token) {
            dispatch(setAuth(token));
        }

        // set sidebar type from localstorage if exist
        if (sidebarType) {
            dispatch(setSidebarType(sidebarType))
        }

        // set sidebar type from localstorage if exist
        if (userRole) {
            dispatch(setUserRole(userRole))
        }

    }, []);

    return (
        <Suspense fallback={<LinearProgress color="secondary" style={progressStyle} />}>
            <Switch>
                <PublicRoute exact path="/" component={RouteImports.Login} />
                <PublicRoute exact path="/login/:invited" component={RouteImports.Login} />
                <PublicRoute exact path="/signup" component={RouteImports.Signup} />
                <PublicRoute exact path="/customer/support" component={RouteImports.CustomerSupport} />

                <PublicRoute exact path="/confirm/password/:token" component={RouteImports.ConfirmPassword} />
                <PublicRoute exact path="/reset-password" component={RouteImports.ForgotPassword} />
                {/* <PrivateRoute exact path="/dashboard" component={userRole==="ROLE_VENDOR"?RouteImports.OpenProjects:RouteImports.Projects} /> */}
                <PrivateRoute exact path="/role" component={RouteImports.UserRole} />
                <PrivateRoute exact path="/my-account" component={RouteImports.MyAccount} />
                <PrivateRoute exact path="/create-project" component={RouteImports.CreateProject} />
                <PrivateRoute exact path="/create-organization" component={RouteImports.CreateOrganization} />
                <PrivateRoute exact path="/search/:query" component={RouteImports.ProjectSearch} />
                <PrivateRoute exact path="/subscription" component={RouteImports.Subscription} />
                <PrivateRoute exact path="/settings" component={RouteImports.Setting} />
                <PrivateRoute exact path="/client/settings" component={RouteImports.SettingTab} />
                <PrivateRoute exact path="/style-guides" component={RouteImports.StyleGuidesNew} />
                <PrivateRoute exact path="/projects" component={RouteImports.Projects} />

                <PrivateRoute exact path="/activity-log" component={RouteImports.ActivityLog} />
                <PrivateRoute exact path="/tmx-group" component={RouteImports.TMXGroup} />
                <PrivateRoute exact path="/teams" component={RouteImports.Team} />
                <PrivateRoute exact path="/all-organization" component={RouteImports.OrganizationList} />
                <PrivateRoute exact path="/manage-admins" component={RouteImports.ManageAdmins} />
                <PrivateRoute exact path="/manage-admins-invite" component={RouteImports.ManageAdminsInvite} />
                <PrivateRoute exact path="/manage-group" component={RouteImports.ManageGroup} />
                <PrivateRoute exact path="/payment" component={RouteImports.payment} />
                <PrivateRoute exact path="/buy-credits" component={RouteImports.credits} />
                <PrivateRoute exact path="/import-empty" component={RouteImports.ImportEmpty} />

                {/* client routes */}
                <PrivateRoute exact path="/projects/overview/:projectId" component={RouteImports.ProjectDashboard} />
                <PrivateRoute exact path="/customers/purchase-order/:customerId" component={RouteImports.CustomerPurchaseOrder} />
                <PrivateRoute exact path="/customers/invoice/:customerId" component={RouteImports.CustomerInvoice} />
                <PrivateRoute exact path="/customers/drawer" component={RouteImports.CustomerDrawer} />
                <PrivateRoute exact path="/client/dashboard" component={RouteImports.ClientDashboard} />

                {/*customer routes*/}
                <PrivateRoute exact path="/customers/invoice" component={RouteImports.CustomerInvoice} />
                <PrivateRoute exact path="/customers/purchase-order" component={RouteImports.CustomerPurchaseOrder} />

                <PrivateRoute exact path="/projects/invoice/:projectId" component={RouteImports.CustomerInvoice} />
                <PrivateRoute exact path="/projects/purchase-order/:projectId" component={RouteImports.CustomerPurchaseOrder} />

                {/* project new dashboard */}

                {/*<PrivateRoute exact path="/projectsDashboard/overview/:projectId" component={RouteImports.ProjectDashboardNew} />*/}
                <PrivateRoute exact path="/projects/work-flow/:projectId" component={RouteImports.WorkFlowListing} />
                <PrivateRoute exact path="/projects/edit-work-flow/:projectId" component={RouteImports.EditWorkFlow} />
                <PrivateRoute exact path="/projects/job/create/:projectId" component={RouteImports.CreateJob} />
                <PrivateRoute exact path="/job/create" component={RouteImports.CreateJob} />
                <PrivateRoute exact path="/projects/files/:projectId" component={RouteImports.Files} />
                <PrivateRoute exact path="/files" component={RouteImports.OrganizationFiles} />
                <PrivateRoute exact path="/reference-files" component={RouteImports.OrganizationReferenceFiles} />
                <PrivateRoute exact path="/projects/reference-files/:projectId" component={RouteImports.ReferenceFiles} />
                {/*<PrivateRoute exact path="/projects/jobs/:projectId" component={RouteImports.Jobs} />*/}
                <PrivateRoute exact path="/projects/languages/:projectId" component={RouteImports.Languages} />
                <PrivateRoute exact path="/projects/style-guides/:projectId" component={RouteImports.StyleGuide} />
                <PrivateRoute exact path="/projects/glossary/:projectId" component={RouteImports.Glossary} />
                <PrivateRoute exact path="/glossary" component={RouteImports.OrganizationGlossary} />
                <PrivateRoute exact path="/projects/bids-for-job/:jobId" component={RouteImports.BidsForJob} />
                <PrivateRoute exact path="/projects/tm/:projectId" component={RouteImports.TranslationMemory} />
                <PrivateRoute exact path="/tm" component={RouteImports.OrganizationTranslationMemory} />
                <PrivateRoute exact path="/projects/tags/:projectId" component={RouteImports.ProjectTags} />
                <PrivateRoute exact path="/projects/job/bids/:jobId" component={RouteImports.BidsForJob} />
                <PrivateRoute exact path="/rate-request" component={RouteImports.RateRequest} />
                {/*<PrivateRoute exact path="/transactions" component={RouteImports.Transations} />*/}
                <PrivateRoute exact path="/transactions" component={RouteImports.Payments} />
                <PrivateRoute exact path="/payments" component={RouteImports.PaymentsTabs} />
                <PrivateRoute exact path="/payments/tab" component={RouteImports.PaymentsTabNew} />
                <PrivateRoute exact path="/transactions/tab" component={RouteImports.PaymentsTabs} />
                <PrivateRoute exact path="/account/settings" component={RouteImports.AccountsTabs} />
                <PrivateRoute exact path="/subscriptions" component={RouteImports.Subscriptions} />
                <PrivateRoute exact path="/subscriptions/BillingHistory" component={RouteImports.BillingHistory} />
                <PrivateRoute exact path="/subscriptions/SubscriptionPlan" component={RouteImports.SubscriptionPlan} />
                <PrivateRoute exact path="/manage-payment" component={RouteImports.TransactionRequest} />
                <PrivateRoute exact path="/withDraw-history" component={RouteImports.ManageWithDrawlHistory} />
                <PrivateRoute exact path="/invoices" component={RouteImports.Invoices} />
                <PrivateRoute exact path="/invoices/payments" component={RouteImports.InvoicesPayments} />
                <PrivateRoute exact path="/my-jobs/files/:projectId" component={RouteImports.JobDetail} />
                <PrivateRoute exact path="/projects/file/:projectId" component={RouteImports.JobDetail} />
                <PrivateRoute exact path="/projects/settings/:projectId" component={RouteImports.ProjectSetting} />
                <PrivateRoute exact path="/projects/tasks/:projectId" component={RouteImports.ProjectTasks} />
                <PrivateRoute exact path="/my-jobs/files/tasks/:projectId" component={RouteImports.ProjectTasks} />
                <PrivateRoute exact path="/notifications" component={RouteImports.Notifications} />
                <PrivateRoute exact path="/releaseNotes" component={RouteImports.ReleaseNotes} />
                <PrivateRoute exact path="/client/account/settings" component={RouteImports.AccountSettings} />
                <PrivateRoute exact path="/projects/mars-qa/:projectId" component={RouteImports.MarsQa} />
                <PrivateRoute exact path="/projects/integrations/:projectId" component={RouteImports.Github} />

                <PrivateRoute exact path="/projects/github-integration" component={RouteImports.Github} />

                {/*<PrivateRoute exact path="/client/bids-for-job/:jobId" component={RouteImports.BidsForJob} />*/}

                {/* vendor routes */}
                <PrivateRoute exact path="/vendor/dashboard" component={RouteImports.VendorDashboard} />

                <PrivateRoute exact path="/vendor/account/settings" component={RouteImports.AccountsSettings} />
                <PrivateRoute exact path="/vendor/rates/add-rates" component={RouteImports.AddRates} />
                <PrivateRoute exact path="/customers" component={RouteImports.AllCustomers} />
                <PrivateRoute exact path="/customers/details/:customersId" component={RouteImports.CustomersDetail} />
                <PrivateRoute exact path="/customers/drive/:customersId" component={RouteImports.CustomersDrive} />
                <PrivateRoute exact path="/vendor/language/:id" component={RouteImports.JobLanguages} />
                <PrivateRoute exact path="/vendor/rates/:id" component={RouteImports.AddRates} />
                <PrivateRoute exact path="/vendor/rates" component={RouteImports.Rates} />
                <PrivateRoute exact path="/customers/price/contracts/:customersId" component={RouteImports.CustomerRates} />
                <PrivateRoute exact path="/vendor/rate/request" component={RouteImports.VendorRateRequest} />
                {/* <PrivateRoute exact path="/vendor/general/rates" component={RouteImports.RatesGeneralTab} /> */}
                <PrivateRoute exact path="/vendor/client/rates" component={RouteImports.RatesClientTab} />
                <PrivateRoute exact path="/vendor/dnsrecordurl" component={RouteImports.DnsRecordUrl} />
                <PrivateRoute exact path="/vendor/send-quotes/:jobId" component={RouteImports.SendQuote} />
                <PrivateRoute exact path="/vendor/open-jobs" component={RouteImports.OpenProjects} />
                <PrivateRoute exact path="/vendor/my-jobs" component={RouteImports.AssignedProjects} />
                <PrivateRoute exact path="/customers/select-manager/:customersId" component={RouteImports.SelectCustomersManager} />

                <PrivateRoute exact path="/vendor/projects/my-jobs" component={RouteImports.AssignedProjects} />
                <PrivateRoute exact path="/vendor/projects/my-jobs/:projectId" component={RouteImports.SingleProjectDetail} />

                <PrivateRoute exact path="/projects/jobs/:projectId" component={RouteImports.CustomerProjects} />
                <PrivateRoute exact path="/vendor/assign-to-linguist" component={RouteImports.AssignToLinguists} />
                <PrivateRoute exact path="/vendor/linguistsOld" component={RouteImports.Linguists} />
                {/* <PrivateRoute exact path="/vendor/linguists-profile-detail/:id" component={RouteImports.LinguistsProfileDetail} /> */}
                <PrivateRoute exact path="/vendor/settings" component={RouteImports.VendorSetting} />
                <PrivateRoute exact path="/vendor/invoice" component={RouteImports.Invoice} />
                <PrivateRoute exact path="/vendor/projects" component={RouteImports.Projects} />
                <PrivateRoute exact path="/vendor/send-invoice/:jobId" component={RouteImports.SendInvoice} />
                <PrivateRoute exact path="/vendor/view-invoice/:jobId" component={RouteImports.ViewInvoice} />
                <PrivateRoute exact path="/vendor/send-quote/:projecId" component={RouteImports.SendQuoteToVendor} />
                <PrivateRoute exact path="/vendor/find" component={RouteImports.FindNewVendors} />
                <PrivateRoute exact path="/vendors" component={RouteImports.MyVendors} />
                <PrivateRoute exact path="/projects/jobs/:id" component={RouteImports.CustomerOpenProjects} />
                <PrivateRoute exact path="/find-vendor" component={RouteImports.FindVendor} />
                <PrivateRoute exact path="/find-linguist" component={RouteImports.FindLinguist} />
                <PrivateRoute exact path="/vendor/profile/:vendorId" component={RouteImports.VendorProfile} />
                {/*<PrivateRoute exact path="/vendor/my-profile" component={RouteImports.MyProfile} />*/}
                <PrivateRoute exact path="/vendor/services" component={RouteImports.VendorServices} />
                <PrivateRoute exact path="/vendor/services/add-services" component={RouteImports.EditVendorServices} />
                <PrivateRoute exact path="/vendor/services/:id" component={RouteImports.EditVendorServices} />
                <PrivateRoute exact path="/vendor/proposal-detail/:id" component={RouteImports.ProposalDetail} />
                <PrivateRoute exact path="/utilities" component={RouteImports.Utilities} />
                <PrivateRoute exact path="/vendor/match-rates" component={RouteImports.MatchRates} />
                <PrivateRoute exact path="/vendor/files/:langId" component={RouteImports.JobFiles} />
                <PrivateRoute exact path="/vendor/linguist" component={RouteImports.GeneralLinguist} />
                <PrivateRoute exact path="/vendor/linguists" component={RouteImports.MyLinguist} />

                <PrivateRoute exact path="/vendor/job-detail" component={RouteImports.JobDetail} />
                <PrivateRoute exact path="/linguist/open-jobs" component={RouteImports.OpenProjects} />

                <PrivateRoute exact path="/linguist/withdrawAccounts" component={RouteImports.WithdrawAccounts} />
                <PrivateRoute exact path="/vendor/withdrawAccounts" component={RouteImports.WithdrawAccounts} />
                <PrivateRoute exact path="/client/manageCreditCards" component={RouteImports.ManageCreditCards} />
                <PrivateRoute exact path="/linguist/manageCreditCards" component={RouteImports.ManageCreditCards} />
                <PrivateRoute exact path="/vendor/manageCreditCards" component={RouteImports.ManageCreditCards} />
                <PrivateRoute exact path="/user/advanceSetting" component={RouteImports.AdvanceSetting} />

                {/* linguist routes */}
                <PrivateRoute exact path="/linguist/profile" component={RouteImports.LinguistsProfile} />
                <PrivateRoute exact path="/linguist/rates/add-rates" component={RouteImports.LinguistsAddRates} />
                <PrivateRoute exact path="/linguist/rates/:id" component={RouteImports.LinguistsAddRates} />
                <PrivateRoute exact path="/linguist/rates" component={RouteImports.LinguistsRatesClientTab} />
                <PrivateRoute exact path="/linguist/general/rates" component={RouteImports.LinguistsRatesGeneralTab} />
                {/*<PrivateRoute exact path="/linguist/client/rates" component={RouteImports.LinguistsRatesClientTab} />*/}
                <PrivateRoute exact path="/linguist/vendor/rates" component={RouteImports.LinguistsRatesVendorTab} />
                <PrivateRoute exact path="/linguist/services" component={RouteImports.LinguistServices} />
                <PrivateRoute exact path="/linguist/services/add-services" component={RouteImports.EditLinguistServices} />
                <PrivateRoute exact path="/linguist/services/:id" component={RouteImports.EditLinguistServices} />
                <PrivateRoute exact path="/linguist/linguist-translation" component={RouteImports.LinguistTranslation} />
                <PrivateRoute exact path="/linguist/profile/:id" component={RouteImports.Profile} />
                <PrivateRoute exact path="/linguist/my-jobs" component={RouteImports.LinguistProjects} />
                {/* admin routes */}
                <PrivateRoute exact path="/admin/pre-processing-files" component={RouteImports.PreProcessingFiles} />

                <PrivateRoute exact path="/package/payments/:id" component={RouteImports.PaymentsPreLoader} />

                {/* Redirecting from SubscriptionPlan to PaymentsPreLoader */}
                {/*<Route exact path="/subscriptions/SubscriptionPlan">*/}
                {/*<Redirect to="/package/payments/2" />*/}
                {/*</Route>*/}

                <Route exact path="/page-not-found" component={RouteImports.Page404} />
                <Route exact path="/page-not-found1" component={RouteImports.Page404} />
                <Route exact path="*">
                    <Redirect to='/page-not-found' />
                </Route>
            </Switch>
        </Suspense>
    )

}

export default withRouter(Routing);