import React from 'react'

export function MAccount() {
    return (
        <React.Fragment>

            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M26.375 15.625L28.125 17.3875L19.9625 25.625L15.625 21.25L17.375 19.4875L19.9625 22.0875L26.375 15.625ZM13.75 5C15.0761 5 16.3479 5.52678 17.2855 6.46447C18.2232 7.40215 18.75 8.67392 18.75 10C18.75 11.3261 18.2232 12.5979 17.2855 13.5355C16.3479 14.4732 15.0761 15 13.75 15C12.4239 15 11.1521 14.4732 10.2145 13.5355C9.27678 12.5979 8.75 11.3261 8.75 10C8.75 8.67392 9.27678 7.40215 10.2145 6.46447C11.1521 5.52678 12.4239 5 13.75 5ZM13.75 7.5C13.087 7.5 12.4511 7.76339 11.9822 8.23223C11.5134 8.70107 11.25 9.33696 11.25 10C11.25 10.663 11.5134 11.2989 11.9822 11.7678C12.4511 12.2366 13.087 12.5 13.75 12.5C14.413 12.5 15.0489 12.2366 15.5178 11.7678C15.9866 11.2989 16.25 10.663 16.25 10C16.25 9.33696 15.9866 8.70107 15.5178 8.23223C15.0489 7.76339 14.413 7.5 13.75 7.5ZM13.75 16.25C14.6 16.25 15.625 16.3625 16.7625 16.575L14.675 18.6625L13.75 18.625C10.0375 18.625 6.125 20.45 6.125 21.25V22.625H13.875L16.25 25H3.75V21.25C3.75 17.925 10.4125 16.25 13.75 16.25Z" fill="#6B778C" />
            </svg>

        </React.Fragment>

    )
}

