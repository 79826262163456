/* eslint-disable */
import React, { useState } from 'react';
import clsx from 'clsx';
import useStyles from './SourceLanguageSelectStyles';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Autocomplete } from "@mui/material";
import TextField from '@mui/material/TextField';
import { MKeyboardArrowDownRoundedIcon } from "src/app/material-ui/icons";
import { FormattedMessage } from 'react-intl';

const SOURCE_LANGUAGE_GQL = gql`
    {
        sourceLanguages {
            code
            id
            name
        }
    }
`;

export function SourceLanguageSelect(props: any) {
    // const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    // const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const classes = useStyles({});
    // const dispatch = useDispatch();
    const [sourceLanguage, setSourceLanguage]: any = useState([]);
    // const [isFiltered, setIsFiltered]: any = useState(0);

    const { data } = useQuery(SOURCE_LANGUAGE_GQL, {
        onCompleted: (data: any) => {
            setSourceLanguage(data && data.sourceLanguages)
        }
    });

    const filterSourceLanguage = () => {
        if (props.targetLanguages && props.targetLanguages.length > 0) {
            let newSourceLanguages: any = data && data.sourceLanguages;
            props.targetLanguages.forEach((targetLang: any) => {
                if (targetLang && targetLang.id) {
                    newSourceLanguages = newSourceLanguages.filter((element: any) => element.id !== targetLang.id);
                } else {
                    newSourceLanguages = newSourceLanguages.filter((element: any) => element.code !== targetLang);
                }
            });
            setSourceLanguage(newSourceLanguages);
        } else {

            const options: any = [];
            // options.push({id: null, code: "", name: 'Select Language'});
            if (data && data.sourceLanguages) {
                for (let i = 0; i < data.sourceLanguages.length; i++) {
                    options.push(data.sourceLanguages[i]);
                }
            }

            // setSourceLanguage(data && data.sourceLanguages)
            setSourceLanguage(options)
            // setSourceLanguage(data && data.sourceLanguages);
        }
    };



    const { redBorder, sourceLangObj, targetLanguages, setSourceLanguageObj, setSourceLang, maxWidth, setSourceLangO, setFieldValue, minWidth, ...autoCompleteProps } = props
    return (
        <div className="sourceLangSelect">
            <FormattedMessage id="no.options">
                {noOptions =>
                    <FormattedMessage id="select.source.language">
                        {() =>
                            <Autocomplete
                                {...autoCompleteProps}
                                multiple={props.multipleSelect ? props.multipleSelect : false}
                                id="sourceLanguage"
                                size="small"
                                label={props.label}
                                limitTags={1}
                                defaultValue={props.defaultValue ? props.defaultValue : props.multipleSelect ? [] : props.defaultLanguage && props.defaultLanguage}
                                value={props.sourceLangObj && props.sourceLangObj != undefined && Object.keys(props.sourceLangObj).length === 0 ? (props.multipleSelect ? [] : null) : props.sourceLangObj && props.sourceLangObj}
                                className={clsx(props.className ? props.className : '', classes.sourceLangSelect, classes.inputWidth, props.redBorder && classes.redBorder)}
                                options={sourceLanguage && sourceLanguage}
                                popupIcon={<MKeyboardArrowDownRoundedIcon />}
                                getOptionLabel={(option: any) => option.code && option.code != undefined ? option.name + ' (' + option.code + ') ' : option.name || ''}
                                onOpen={filterSourceLanguage}
                                noOptionsText={String(noOptions)}
                                onChange={(_event: any, value: any) => {
                                    if (props.customHandleChange) {
                                        props.customHandleChange();
                                    }
                                    if (value !== null) {
                                        if (props?.returnId && props?.returnId === 'id' && props?.setFieldValue) {
                                            const sourceLanguageId: any = [];
                                            if (props.multipleSelect) {
                                                value.forEach((ind: any) => {
                                                    sourceLanguageId.push(ind.id)
                                                })
                                                props.setFieldValue('sourceLanguage', sourceLanguageId);
                                            } else {
                                                props.setFieldValue('sourceLanguage', value.id);
                                                if (props.setSourceLang) {
                                                    props.setSourceLang(value.id);
                                                }

                                            }

                                        } else if (props.returnId && props.returnId === 'object' && props.setFieldValue) {
                                            props.setFieldValue('sourceLanguage', { id: value.id, name: value.name, code: value.code });
                                            if (props.setSourceLang) {
                                                props.setSourceLang({ id: value.id, name: value.name });
                                            }
                                        } else {
                                            if (props.setFieldValue) {
                                                props.setFieldValue('sourceLanguage', value.code);
                                                if (props.setSourceLang) {
                                                    props.setSourceLang(value.code);
                                                    props.setSourceLangO(value);
                                                }
                                            }
                                        }
                                        if (props.setSlId) {
                                            props.setSlId(value.id)
                                        }

                                        if (props.setSourceLanguage) {
                                            props.setSourceLanguage(value.code)
                                            props.setSourceLanguageObj(value)
                                        }

                                        if (props.setSourceLanguageObj) {
                                            props.setSourceLanguageObj(value)
                                        }
                                    } else {
                                        if (props.setFieldValue) {
                                            props.setFieldValue('sourceLanguage', '');
                                            if (props.setSourceLang) {
                                                props.setSourceLang('');
                                            }
                                        }
                                        if (props.setSlId) {
                                            props.setSlId('')
                                        }
                                        if (props.setSourceLanguage) {
                                            props.setSourceLanguage('');
                                        }

                                        if (props.setSourceLanguageObj) {
                                            props.setSourceLanguageObj({})
                                        }
                                    }
                                    if (props.sourceLanguageHandler) {
                                        props.sourceLanguageHandler();
                                    }
                                }}
                                // renderOption={(props: any, targetLanguage: any, { selected }: any) => (
                                //     <li {...props}>
                                //         <CheckBox
                                //             // icon={icon}
                                //             checkedIcon={checkedIcon}
                                //             style={{ marginRight: 8 }}
                                //             checked={selected}
                                //         />
                                //         {targetLanguage}
                                //     </li>

                                // )}
                                renderInput={params => <TextField {...params}
                                    variant="outlined" color="secondary"
                                    label={props.label && props.label ? props.label : null}
                                    placeholder={props.placeholder ? props.placeholder : null}
                                    sx={{ fontSize: '12px !important' }}

                                />}
                            />
                        }
                    </FormattedMessage>
                }
            </FormattedMessage>
        </div>

    )
}