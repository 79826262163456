// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./app/shared/fonts/Poppins/Poppins-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./app/shared/fonts/Poppins/Poppins-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./app/shared/fonts/Poppins/Poppins-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./app/shared/fonts/Poppins/Poppins-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./app/shared/fonts/Poppins/Poppins-Black.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Roboto fonts css */
/* @font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('./app/shared/fonts/roboto/Roboto-Regular.ttf') format('truetype');
} */


@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
}

@font-face {
  font-family: 'Poppins Light';
  src: local('Poppins Light'), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
}

@font-face {
  font-family: 'Poppins Medium';
  src: local('Poppins Medium'), url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
}
@font-face {
  font-family: 'Poppins Medium';
  font-weight: 500;
  src: local('Poppins Medium'), url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
}

@font-face {
  font-family: 'Poppins Bold';
  src: local('Poppins Bold'), url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
}

@font-face {
  font-family: 'Poppins Black';
  src: local('Poppins Black'), url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('truetype');
}


body {
  margin: 0;
  /* font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.875rem;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;;;GAGG;;;AAGH;EACE,sBAAsB;EACtB,iFAA+F;AACjG;;AAEA;EACE,4BAA4B;EAC5B,uFAAmG;AACrG;;AAEA;EACE,6BAA6B;EAC7B,wFAAqG;AACvG;AACA;EACE,6BAA6B;EAC7B,gBAAgB;EAChB,wFAAqG;AACvG;;AAEA;EACE,2BAA2B;EAC3B,sFAAiG;AACnG;;AAEA;EACE,4BAA4B;EAC5B,uFAAmG;AACrG;;;AAGA;EACE,SAAS;EACT;;iBAEe;EACf,mCAAmC;EACnC,kCAAkC;EAClC,mBAAmB;AACrB","sourcesContent":["/* Roboto fonts css */\n/* @font-face {\n  font-family: 'Roboto';\n  src: local('Roboto'), url('./app/shared/fonts/roboto/Roboto-Regular.ttf') format('truetype');\n} */\n\n\n@font-face {\n  font-family: 'Poppins';\n  src: local('Poppins'), url('./app/shared/fonts/Poppins/Poppins-Regular.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'Poppins Light';\n  src: local('Poppins Light'), url('./app/shared/fonts/Poppins/Poppins-Light.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'Poppins Medium';\n  src: local('Poppins Medium'), url('./app/shared/fonts/Poppins/Poppins-Medium.ttf') format('truetype');\n}\n@font-face {\n  font-family: 'Poppins Medium';\n  font-weight: 500;\n  src: local('Poppins Medium'), url('./app/shared/fonts/Poppins/Poppins-Medium.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'Poppins Bold';\n  src: local('Poppins Bold'), url('./app/shared/fonts/Poppins/Poppins-Bold.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'Poppins Black';\n  src: local('Poppins Black'), url('./app/shared/fonts/Poppins/Poppins-Black.ttf') format('truetype');\n}\n\n\nbody {\n  margin: 0;\n  /* font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif; */\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  font-size: 0.875rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
