import React, { Fragment } from 'react'

export function MPackage() {
    return (
        <Fragment>

            <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.44403 6.75005H26.0065L24.7375 5.40005H8.53753L7.44403 6.75005ZM28.261 7.06055C28.6525 7.51955 28.882 8.10005 28.882 8.77505V25.65C28.882 26.3661 28.5976 27.0529 28.0912 27.5592C27.5849 28.0656 26.8981 28.35 26.182 28.35H7.28203C6.56595 28.35 5.87919 28.0656 5.37284 27.5592C4.86649 27.0529 4.58203 26.3661 4.58203 25.65V8.77505C4.58203 8.10005 4.81153 7.51955 5.20303 7.06055L7.06603 4.79255C7.44403 4.33355 7.99753 4.05005 8.63203 4.05005H24.832C25.4665 4.05005 26.02 4.33355 26.3845 4.79255L28.261 7.06055ZM8.63203 24.3H16.732V20.25H8.63203V24.3Z" fill="#9FA5B9" />
            </svg>

        </Fragment>
    )
}
