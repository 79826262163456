import React from 'react'

export function MRemoveSupplier(props: any) {
    return (

        <React.Fragment>
            <svg {...props} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.2504 15.1673C19.1429 15.1673 24.917 16.6082 24.917 19.5007V21.6673H7.5837V19.5007C7.5837 16.6082 13.3579 15.1673 16.2504 15.1673ZM16.2504 13.0007C15.1011 13.0007 13.9989 12.5441 13.1862 11.7314C12.3736 10.9188 11.917 9.81659 11.917 8.66732C11.917 7.51805 12.3736 6.41585 13.1862 5.60319C13.9989 4.79053 15.1011 4.33398 16.2504 4.33398C17.3996 4.33398 18.5018 4.79053 19.3145 5.60319C20.1271 6.41585 20.5837 7.51805 20.5837 8.66732C20.5837 9.81659 20.1271 10.9188 19.3145 11.7314C18.5018 12.5441 17.3996 13.0007 16.2504 13.0007ZM5.41703 10.3898L7.7137 8.08232L9.25203 9.62065L6.94453 11.9173L9.25203 14.214L7.7137 15.7523L5.41703 13.4448L3.12036 15.7523L1.58203 14.214L3.88953 11.9173L1.58203 9.62065L3.12036 8.08232L5.41703 10.3898Z" fill="#6B778C" />
            </svg>
        </React.Fragment>


    )
}

