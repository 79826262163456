import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import CloseIcon from '@mui/icons-material/Close';
import DescriptionIcon from '@mui/icons-material/Description';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import GetAppIcon from '@mui/icons-material/GetApp';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { gql } from "apollo-boost";
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone-uploader';
import { FormattedDate, FormattedTimeParts } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import {
    MAvatar,
    MBox,
    MButton,
    MCard,
    MCardContent,
    MCircularProgress,
    MForm,
    MFormik,
    MGrid,
    MIconButton,
    MLink,
    MTextField,
    MTypography
} from 'src/app/material-ui';
import { MDialogActions } from 'src/app/material-ui/dialog-actions/DialogActions';
import { MDialogContent } from 'src/app/material-ui/dialog-content/DialogContent';
import { MDialogTitle } from 'src/app/material-ui/dialog-title/DialogTitle';
import { MDialog } from 'src/app/material-ui/dialog/Dialog';
import { MDeleteIcon, MScheduleIcon } from "src/app/material-ui/icons";
import { showAlert } from "src/app/store";
import EmptyContent from "../empty-content/EmptyContent";
import useStyles from './LanguageTabPopupStyles';
import { FormattedMessage } from "react-intl";

interface IProps {
    open: boolean,
    setOpen: (val: any) => void,
    languageDetailPopup: any,
    catFileId: any,
    fileId: any,
    fileDetails: any,
    seq: any,
    // getLanguages?: any,
    getFiles?: any,
    getProjectFiles?: any,
    getProjectLanguages?: any,
    setLanguageDetailPopup?: any,
    setFsetIntervalCall?: any,
}

const PROJECT_LANG_Target_Files_GQL = gql`
    query getProjectTargetFiles($projectFile:ID!,$languageId: ID!) {
        getProjectTargetFiles(projectFile: $projectFile,languageId: $languageId) {
            createdAt
            id
            name
            path
        }
    }
`;

const REMOVE_Target_Files_GQL = gql`
    mutation removeTargetFile($id: ID!) {
        removeTargetFile(id: $id)
    }
`;

const REMOVE_Source_Files_GQL = gql`
    mutation removeSourceFile($fileId: String!){
        removeSourceFile(fileId: $fileId)
    }
`;

const EDIT_FILE_NAME = gql`
    mutation updateFileName($fileId: Int!,$fileName: String!){
        updateFileName(fileId:$fileId,fileName:$fileName)
    }
`;

const after_Last_File_Process = gql`
    mutation afterLastFileProcess($projectId: String!,$type: String!,$fileId: String,$langId: ID){
        afterLastFileProcess(projectId: $projectId,type: $type,fileId:$fileId,langId:$langId)
    }
`;

export default function LanguageTabPopup(props: IProps) {
    const classes = useStyles({});
    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();
    const dispatch = useDispatch();
    const organizations = useSelector((store: any) => store.orgs.organizations);
    const SelectedOrgId = useSelector((store: any) => store.orgs.selectedOrgId);
    const [selectedOrg, setSelectedOrg]: any = useState();
    const [showFileUploadSection, setShowFileUploadSection] = useState(true);
    const [openDownloadTranslationPopup, setOpenDownloadTranslationPopup] = useState(false);
    const [uploadedFilesData, setUploadedFilesData]: any = React.useState([]);
    const [uploadedFileLength, setUploadedFilesLength]: any = React.useState(0);
    const [projectLangFiles, setProjectLangFiles] = useState([]);
    const [tabName, setTabName]: any = useState();
    const [allFilesId, setAllFilesId]: any = useState([]);
    const projectId = useSelector((store: any) => store.projects.selectedProjectId);
    const [openNew, setOpenNew] = useState(false);
    const [officeSelectedFileId, setOfficeSelectedFileId]: any = useState(false);
    const [editFileName, setEditFileName]: any = useState();
    const [afterLastFileProcess] = useMutation(after_Last_File_Process, {
        fetchPolicy: 'no-cache'
    });

    const displayReviewed = (language: any) => {
        if (language.stats) {
            const lang = JSON.parse(language.stats);
            if (lang['file_id_' + props.fileId] && lang['file_id_' + props.fileId].reviewed_percentage) {
                return lang['file_id_' + props.fileId].reviewed_percentage;
            }
        }
        return 0;
    }

    const display100 = (language: any) => {
        if (language.stats) {
            const lang = JSON.parse(language.stats);
            if (lang['file_id_' + props.fileId] && typeof lang['file_id_' + props.fileId].details != "undefined" && typeof lang['file_id_' + props.fileId].details._100 != "undefined") {
                return lang['file_id_' + props.fileId].details._100;
            }
        }
        return 0;
    }

    const display_95_99 = (language: any) => {
        if (language.stats) {
            const lang = JSON.parse(language.stats);
            if (lang['file_id_' + props.fileId] && typeof lang['file_id_' + props.fileId].details != "undefined" && typeof lang['file_id_' + props.fileId].details._95_99 != "undefined") {
                return lang['file_id_' + props.fileId].details._95_99;
            }
        }
        return 0;
    }

    const display_85_94 = (language: any) => {
        if (language.stats) {
            const lang = JSON.parse(language.stats);
            if (lang['file_id_' + props.fileId] && typeof lang['file_id_' + props.fileId].details != "undefined" && typeof lang['file_id_' + props.fileId].details._85_94 != "undefined") {
                return lang['file_id_' + props.fileId].details._85_94;
            }
        }
        return 0;
    }

    const display_75_84 = (language: any) => {
        if (language.stats) {
            const lang = JSON.parse(language.stats);
            if (lang['file_id_' + props.fileId] && typeof lang['file_id_' + props.fileId].details != "undefined" && typeof lang['file_id_' + props.fileId].details._75_84 != "undefined") {
                return lang['file_id_' + props.fileId].details._75_84;
            }
        }
        return 0;
    }

    const display_50_74 = (language: any) => {
        if (language.stats) {
            const lang = JSON.parse(language.stats);
            if (lang['file_id_' + props.fileId] && typeof lang['file_id_' + props.fileId].details != "undefined" && typeof lang['file_id_' + props.fileId].details._50_74 != "undefined") {
                return lang['file_id_' + props.fileId].details._50_74;
            }
        }
        return 0;
    }

    const displayTranslated = (language: any) => {
        if (language.stats) {
            const lang = JSON.parse(language.stats);
            if (lang['file_id_' + props.fileId] && lang['file_id_' + props.fileId].translated_percentage) {
                return lang['file_id_' + props.fileId].translated_percentage;
            }
        }
        return 0;
    }


    const displayWords = (language: any) => {
        // alert(JSON.stringify(language.stats));
        if (language.stats) {
            const lang = JSON.parse(language.stats);
            if (lang['file_id_' + props.fileId] && lang['file_id_' + props.fileId].words) {
                return lang['file_id_' + props.fileId].words;
            }
        }
        return 0;
    }

    const displayUntranslated = (language: any) => {
        if (language.stats) {
            const lang = JSON.parse(language.stats);
            if (lang['file_id_' + props.fileId] && lang['file_id_' + props.fileId].untranslated_percentage) {
                return lang['file_id_' + props.fileId].untranslated_percentage;
            }
        }

        return 0;
    }

    const handleDelTargetBtn = () => {
        removeTargetFile({
            variables: {
                id: officeSelectedFileId
            }
        });
    };

    const handleSetFileId = (id: any) => {
        setOfficeSelectedFileId(id);
        setOpenNew(true);
    };

    const openNewSet = (val: any) => {
        setOpenNew(val);
    }

    const [removeTargetFile] = useMutation(REMOVE_Target_Files_GQL, {
        fetchPolicy: 'no-cache',
        onCompleted: () => {
            getProjectLangTargetFiles({
                variables: { projectFile: props.fileId, languageId: props.languageDetailPopup.id }
            });
            setOpenNew(false);
            // props.setFsetIntervalCall(1);
            dispatch(showAlert({
                message: 'File deleted successfully',
                messageType: 'success',
                showAlertMessage: true
            }));
        }
    });

    const [removeSourceFile] = useMutation(REMOVE_Source_Files_GQL, {
        variables: { fileId: props.fileId },
        fetchPolicy: 'no-cache',
        onCompleted: () => {
            props.setOpen(false);
            dispatch(showAlert({
                message: 'File deleted successfully',
                messageType: 'success',
                showAlertMessage: true
            }));
        },

    });

    const [editFileNameFunc, { loading }] = useMutation(EDIT_FILE_NAME, {
        fetchPolicy: 'no-cache',
        onCompleted: () => {
            // props.setOpen(false);
            props.getProjectFiles();
            dispatch(showAlert({
                message: 'File Name updated successfully',
                messageType: 'success',
                showAlertMessage: true
            }));
            setEditFileName(false)
        },

    });

    const [getProjectLangTargetFiles] = useLazyQuery(PROJECT_LANG_Target_Files_GQL, {
        fetchPolicy: 'no-cache',
        onCompleted: (data: any) => {
            setProjectLangFiles(data && data.getProjectTargetFiles);
            // setLoadingContent(false);
        }
    });

    useEffect(() => {
        if (props.fileId) {
            getProjectLangTargetFiles({
                variables: { projectFile: props.fileId, languageId: props.languageDetailPopup.id }
            });
        }
    }, [props.fileId, props.languageDetailPopup.id]);


    useEffect(() => {
        setWordcount(displayWords(props.languageDetailPopup));
    }, [displayWords(props.languageDetailPopup)]);

    useEffect(() => {
        if (SelectedOrgId) {
            setSelectedOrg(organizations && organizations.filter((org: any) => org.id === SelectedOrgId));
        }
    }, [SelectedOrgId])

    const Input = () => {

        const text = 'Add files'

        return (
            <MBox>
                <MGrid container direction="row" justifyContent="center" alignItems="center">
                    <MBox mr={1}>
                        <img src="/assets/images/file-uploader-icon.png" alt="logo" className={classes.fileUploader} />
                    </MBox>
                    <MBox>
                        <MTypography className={classes.DropZoneInput}>
                            {text}
                        </MTypography>

                        <MTypography className={classes.DropZoneDetailInput}>
                            <FormattedMessage id="drop.files.click" />
                            <MLink color="secondary" class="cursor-pointer" >
                                <FormattedMessage id="browse" />
                            </MLink>
                            <FormattedMessage id="through.computer" />
                        </MTypography>
                    </MBox>
                </MGrid>
            </MBox>
        )
    }

    const formatBytes = (bytes: number, decimals: number) => {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['bytes', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const afterFileUpload = async (file: any) => {
        const body = new FormData();
        body.append("fileName", file.name);
        body.append("filePath", file.filePath);
        body.append("size", file.size);
        body.append("type", 'target');
        body.append("lang_id", props.languageDetailPopup.id);
        body.append("source_file_id", props.fileId);
        body.append("translation_type", '1');
        const headers = {
            'accept': 'application/json',
            'authorization': 'Bearer ' + localStorage.getItem('access_token')
        }
        await axios.post(process.env.REACT_APP_API_END_POINT + '/project-api/upload-project-files/' + projectId, body, { headers: headers }).then(
            (res: any) => {

                if (res.data[0][0].fileId) (
                    setAllFilesId([...allFilesId, res.data[0][0].fileId])
                )
            }
        )
        await getProjectLangTargetFiles({
            variables: { projectFile: props.fileId, languageId: props.languageDetailPopup.id }
        });
    }

    const Layout = ({ input, previews, dropzoneProps, files, extra: { maxFiles } }: any) => {
        files.forEach(async (file: any) => {
            if (file.meta.status === 'done' && file.xhr.statusText === "OK") {
                const fileResponse: any = file.meta;
                const found = uploadedFilesData.find((f: any) => f.id === fileResponse.id);
                if (!found) {
                    setUploadedFilesData([...uploadedFilesData, fileResponse])

                    await afterFileUpload(file.meta)
                    files.filter((f: any) => {
                        if (f.meta.name === fileResponse.name) {
                            f.remove();
                        }
                        return f;
                    });
                }
            }
        });


        return (
            <div>
                <MBox {...dropzoneProps} sx={{
                    padding: files.length > 0 ? 0 : 16,
                    border: files.length > 0 ? 'none' : '1px dashed lightgrey'
                }}>
                    {
                        previews.map((element: any, index: number) => (
                            <MBox key="previews" className={classes.fileProgressStyle}>
                                <MGrid container key={index} justifyContent="space-between" alignItems="center">
                                    <MGrid item xs={6} lg={6} xl={6} class="txt-left">
                                        <MTypography variant="span" className="fileName">
                                            {element.props.fileWithMeta.meta.name}
                                        </MTypography>
                                        <MBox className="fileData">
                                            <span>
                                                <FormattedTimeParts
                                                    value={new Date()}
                                                    year="numeric"
                                                    month="long"
                                                    day="2-digit"
                                                >
                                                    {(parts: any) => (
                                                        <>
                                                            {parts[2].value}
                                                            {parts[1].value}
                                                            {parts[0].value}
                                                            {parts[3].value}
                                                            {parts[4].value}
                                                            {' at '}
                                                            {parts[6].value}
                                                            {parts[7].value}
                                                            {parts[8].value}
                                                            {parts[9].value}
                                                            {parts[10].value}
                                                        </>
                                                    )}
                                                </FormattedTimeParts>
                                            </span>
                                            <span><b><span style={{
                                                fontSize: 22,
                                                margin: '0 7px',
                                                lineHeight: 0
                                            }}>.</span>{formatBytes(element.props.fileWithMeta.meta.size, 1)}</b></span>
                                        </MBox>
                                    </MGrid>
                                    <MGrid item xs={6} lg={6} xl={6} sx={{ marginTop: 3 }}>
                                        <MBox display={'flex'} justifyContent={"flex-end"}>
                                            <span style={{
                                                color: '#6b778c',
                                                fontSize: 12,
                                                marginRight: 10
                                            }}>{Math.round(element.props.fileWithMeta.meta.percent)}%</span>
                                            <LinearProgress variant="determinate"
                                                className={'progressStyle'}
                                                value={Math.round(element.props.fileWithMeta.meta.percent)}
                                                color="secondary"
                                            />
                                        </MBox>
                                    </MGrid>
                                </MGrid>
                            </MBox>
                        ))
                    }
                    {files.length < maxFiles && input}
                </MBox>
            </div>
        )
    }

    const afterAllUploads = async () => {
        await afterLastFileProcess({
            variables: {
                projectId: projectId,
                type: "target",
                fileId: allFilesId.join(','),
            }
        });
        setAllFilesId([])
    }

    useEffect(() => {
        if ((uploadedFilesData.length > 0) && (uploadedFilesData.length === uploadedFileLength) && (allFilesId.length === uploadedFileLength)) {
            afterAllUploads()
            setShowFileUploadSection(false);
            setTimeout(function () {
                setShowFileUploadSection(true);
            }, 50);
            // setLoadingContent(true);
            props.setFsetIntervalCall(1);
            dispatch(showAlert({
                message: 'Files added successfully.',
                messageType: 'success',
                showAlertMessage: true
            }));
            setUploadedFilesData([]);
            setUploadedFilesLength(0);
        }
    }, [uploadedFilesData, allFilesId])

    useEffect(() => {
        const detailUrl = window.location.href.split('/').pop();
        if (detailUrl) {
            setTabName(detailUrl.split('#')[1]);
        }

    }, [showFileUploadSection])

    // let projectId = 1;
    const getUploadParams = async ({ file }: any) => {


        const method: any = "PUT";
        const body = new FormData();
        body.append("filename", file.name);

        const { filePath, url } = await axios.post(process.env.REACT_APP_API_END_POINT + '/get-s3-presigned-url', body).then(
            (res: any) => {
                return res.data[0];
            });
        return { url: url, method: method, body: file, meta: { filePath } }
    }

    const uploadModalOnChange = (fileWithMeta: any, status: any, allFiles: any) => {
        if (uploadedFileLength < allFiles.length) {
            setUploadedFilesLength(allFiles.length)
        }
        if (fileWithMeta.meta && fileWithMeta.meta.status === "rejected_file_type") {
            dispatch(showAlert({
                message: fileWithMeta.meta.name + ' file not supported. Supported files are .csv .xlf .xliff .json .yaml .yml .doc .docx .tmx .sdltm',
                messageType: 'error',
                showAlertMessage: true
            }));
        }
    };

    const [setWordcount] = useState(displayWords(props.languageDetailPopup));

    const handleModalClose = (setClose: any) => {
        setClose(false);

    }

    const handleDelSourceFile = () => {
        removeSourceFile();
    }


    return (
        <div>
            <MDialog fullWidth maxWidth='lg' onClose={() => handleModalClose(props.setOpen)}
                aria-labelledby="customized-dialog-title" open={props.open}>

                <MBox>
                    <MDialogTitle className={classes.dialogHeader} id="customized-dialog-title"
                        onClose={() => props.setOpen(false)}>
                        <MGrid container direction="row" justifyContent="space-between" alignItems="center">

                            {editFileName ?
                                <MBox className={classes.fieldActionButtons}>
                                    <MFormik
                                        enableReinitialize="true"
                                        initialValues={{
                                            fileName: props.fileDetails && props.fileDetails.name,
                                        }}
                                        onSubmit={(values: any) => {
                                            editFileNameFunc({
                                                variables: { fileId: props.fileId, fileName: values.fileName },
                                            });
                                            // resetForm();
                                        }}
                                    >
                                        {() => (
                                            <MForm>
                                                <MBox mb={1} display="inline-block"
                                                    className={classes.inputWrappper}>
                                                    <MTextField
                                                        className={classes.input}
                                                        name="fileName"
                                                        variant="outlined"
                                                        color="secondary"
                                                        size="small"
                                                        placeholder="File Name"
                                                    />
                                                </MBox>

                                                <MBox display="inline-block"
                                                    maxWidth="90px"
                                                    verticalAlign="middle"
                                                    className={classes.editButtons}>
                                                    <MIconButton variant="contained"
                                                        type="submit"
                                                        sx={{ marginRight: 3 }}>
                                                        {loading ? <MCircularProgress color="secondary" size={24} /> :
                                                            <DoneIcon fontSize="small" />}
                                                    </MIconButton>
                                                    <MIconButton variant="contained" color="default">
                                                        <CloseIcon onClick={() => setEditFileName(false)}
                                                            fontSize="small" />
                                                    </MIconButton>
                                                </MBox>

                                            </MForm>
                                        )}
                                    </MFormik>
                                </MBox>
                                :
                                <MBox
                                    fontSize={18}
                                    fontWeight="bold"
                                    color={'#3a3a3a'}
                                    sx={{
                                        width: 'calc(100% - 160px)',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis'
                                    }}>
                                    {(props.seq !== 'file' ?
                                        `${props.fileDetails && props.fileDetails.name} > (${props.languageDetailPopup && props.languageDetailPopup.name} )`
                                        :
                                        `( ${props.languageDetailPopup && props.languageDetailPopup.name} ) > ${props.fileDetails && props.fileDetails.name}`
                                    )}
                                    <MBox sx={{ display: 'inline-block' }}>
                                        <MIconButton>
                                            <EditIcon onClick={() => setEditFileName(true)} fontSize="small" />
                                        </MIconButton>
                                    </MBox>

                                </MBox>
                            }

                            {((selectedOrg && selectedOrg[0] && selectedOrg[0].onlineTranslation == '0')
                                ?
                                <span style={{ color: 'rgba(0,0,0,0.38)', position: 'absolute', right: 24 }}>
                                    <FormattedMessage id="translation.disabled" />
                                </span>
                                :
                                (props.catFileId && props.fileDetails.isOnline === 1
                                    ?
                                    <MLink target="_blank"
                                        href={process.env.CAT_TOOL_ENDPOINT + '/doc/'.concat(props.catFileId, '.', props.languageDetailPopup.code)}>
                                        <MButton className="btnSmall" variant="contained" color="secondary">
                                            <FormattedMessage id="translate" />
                                        </MButton>
                                    </MLink>
                                    :
                                    (props.fileDetails && props.fileDetails.isOnline == 0
                                        ?
                                        <MLink target="_blank"
                                            href={process.env.CAT_TOOL_ENDPOINT + '/doc/'.concat(props.catFileId, '.', props.languageDetailPopup.code)}>
                                        </MLink>
                                        :
                                        <span style={{ color: 'rgba(0,0,0,0.38)', position: 'absolute', right: 24 }}>
                                            <FormattedMessage id="content.analyzing" />
                                        </span>
                                    )))}

                        </MGrid>
                    </MDialogTitle>

                    <MDialogContent className={classes.contentWrapper}>
                        {((props.catFileId && (selectedOrg && selectedOrg[0] && selectedOrg[0].onlineTranslation == '1'))
                            ?
                            <MBox>
                                {/*stat boxes  */}
                                <MBox pb={4} px={3} className={classes.section}>
                                    <MGrid container spacing={2} alignItems="center">
                                        <MGrid item xs={12} sm={6} md={3} lg={3}>
                                            <MCard className={classes.statsCard}>
                                                <MCardContent>
                                                    <MGrid container wrap="nowrap" spacing={2}>
                                                        <MGrid item>
                                                            <MBox ml={0.8} mt={1.6}>
                                                                <MAvatar className={classes.statsCardIcon}>
                                                                    <DescriptionIcon color="action" />
                                                                </MAvatar>
                                                            </MBox>
                                                        </MGrid>
                                                        <MGrid item>
                                                            <MBox mt={2.3} fontSize="16px" textAlign='left' lineHeight='14px'>
                                                                {displayWords(props.languageDetailPopup)}
                                                            </MBox>
                                                            <MBox fontSize="14px" textAlign='left' color="#6b778c">
                                                                <FormattedMessage id="total.words" />
                                                            </MBox>
                                                        </MGrid>
                                                    </MGrid>
                                                </MCardContent>
                                            </MCard>
                                        </MGrid>
                                        <MGrid item xs={12} sm={6} md={3} lg={3}>
                                            <MCard className={classes.statsCard}>
                                                <MCardContent>
                                                    <MGrid container wrap="nowrap" spacing={2}>
                                                        <MGrid item>
                                                            <MBox ml={0.8} mt={1.6}>
                                                                <MAvatar className={classes.statsCardIcon}>
                                                                    <RateReviewIcon color="action" />
                                                                </MAvatar>
                                                            </MBox>
                                                        </MGrid>
                                                        <MGrid item>
                                                            <MBox mt={2.3} fontSize="16px" textAlign='left' lineHeight='14px'
                                                                color='#3a3a3a'>
                                                                {displayReviewed(props.languageDetailPopup)}%
                                                            </MBox>
                                                            <MBox fontSize="14px" textAlign='left' color="#6b778c">
                                                                <FormattedMessage id="words.reviewed" />
                                                            </MBox>
                                                        </MGrid>
                                                    </MGrid>
                                                </MCardContent>
                                            </MCard>
                                        </MGrid>
                                        <MGrid item xs={12} sm={6} md={3} lg={3}>
                                            <MCard className={classes.statsCard}>
                                                <MCardContent>
                                                    <MGrid container wrap="nowrap" spacing={2}>
                                                        <MGrid item>
                                                            <MBox ml={0.8} mt={1.6}>
                                                                <MAvatar className={classes.statsCardIcon}>
                                                                    <GTranslateIcon color="action" />
                                                                </MAvatar>
                                                            </MBox>
                                                        </MGrid>
                                                        <MGrid item>
                                                            <MBox mt={2.3} fontSize="16px" textAlign='left' lineHeight='14px'
                                                                color='#3a3a3a'>
                                                                {displayTranslated(props.languageDetailPopup)}%
                                                            </MBox>
                                                            <MBox fontSize="14px" textAlign='left' color="#6b778c">
                                                                <FormattedMessage id="words.translated" />
                                                            </MBox>
                                                        </MGrid>
                                                    </MGrid>
                                                </MCardContent>
                                            </MCard>
                                        </MGrid>
                                        <MGrid item xs={12} sm={6} md={3} lg={3}>
                                            <MCard className={classes.statsCard}>
                                                <MCardContent>
                                                    <MGrid container wrap="nowrap" spacing={2}>
                                                        <MGrid item>
                                                            <MBox ml={0.8} mt={1.6}>
                                                                <MAvatar className={classes.statsCardIcon}>
                                                                    <GTranslateIcon color="action" />
                                                                </MAvatar>
                                                            </MBox>
                                                        </MGrid>
                                                        <MGrid item>
                                                            <MBox mt={2.3} fontSize="16px" textAlign='left' lineHeight='14px'
                                                                color='#3a3a3a'>
                                                                {displayUntranslated(props.languageDetailPopup)}%
                                                            </MBox>
                                                            <MBox fontSize="14px" textAlign='left' color="#6b778c">
                                                                <FormattedMessage id="words.untranslated" />
                                                            </MBox>
                                                        </MGrid>
                                                    </MGrid>
                                                </MCardContent>
                                            </MCard>
                                        </MGrid>
                                    </MGrid>
                                </MBox>

                                <MBox mt={3} pb={3} px={3} className={classes.section}>
                                    <MBox mb={3} fontSize={18} fontWeight="bold" color='#3a3a3a'>
                                        <FormattedMessage id="vendor.language.translation.memory.leverage" />
                                    </MBox>
                                    <MGrid container spacing={10} direction="row" justifyContent="flex-start" alignItems="center">
                                        <MGrid item>
                                            <MBox fontSize={14} mb={1} fontWeight="bold" color='#3a3a3a'>
                                                <FormattedMessage id="hundered.percent" />
                                            </MBox>
                                            <MBox className={classes.lightText}>
                                                {display100(props.languageDetailPopup)}
                                                <FormattedMessage id="words" />
                                            </MBox>
                                        </MGrid>
                                        <MGrid item>
                                            <MBox fontSize={14} mb={1} fontWeight="bold" color='#3a3a3a'>
                                                <FormattedMessage id="ninetyfive.to.ninetynine" />
                                            </MBox>
                                            <MBox className={classes.lightText}>
                                                {display_95_99(props.languageDetailPopup)}
                                                <FormattedMessage id="words" />
                                            </MBox>
                                        </MGrid>
                                        <MGrid item>
                                            <MBox fontSize={14} mb={1} fontWeight="bold" color='#3a3a3a'>
                                                <FormattedMessage id="eightyfive.to.ninetyfour" />
                                            </MBox>
                                            <MBox className={classes.lightText}>
                                                {display_85_94(props.languageDetailPopup)}
                                                <FormattedMessage id="words" />
                                            </MBox>
                                        </MGrid>
                                        <MGrid item>
                                            <MBox fontSize={14} mb={1} fontWeight="bold" color='#3a3a3a'>
                                                <FormattedMessage id="seventyfive.to.eightyfour" />
                                            </MBox>
                                            <MBox className={classes.lightText}>
                                                {display_75_84(props.languageDetailPopup)}
                                                <FormattedMessage id="words" />
                                            </MBox>
                                        </MGrid>
                                        <MGrid item>
                                            <MBox fontSize={14} mb={1} fontWeight="bold" color='#3a3a3a'>
                                                <FormattedMessage id="fiftynine.to.zero" />
                                            </MBox>
                                            <MBox className={classes.lightText}>
                                                {display_50_74(props.languageDetailPopup)}
                                                <FormattedMessage id="words" />
                                            </MBox>
                                        </MGrid>
                                    </MGrid>
                                </MBox>
                                <MBox mt={3} pb={3} px={3} className={classes.closeSection}>
                                    <MBox mb={2} fontSize={18} fontWeight="bold" color='#3a3a3a'>
                                        <FormattedMessage id="download" />
                                    </MBox>

                                    <MGrid container spacing={10} direction="row" justifyContent="flex-start"
                                        alignItems="center">
                                        <MGrid item>
                                            <MLink onClick={preventDefault} href="#" className={classes.linkStyle}>
                                                <FormattedMessage id="download.source" />
                                            </MLink>
                                        </MGrid>
                                        <MGrid item>
                                            <MLink target="_blank" href={process.env.REACT_APP_API_END_POINT + '/download/target/file?lang=' + props.languageDetailPopup.projectLangId + '&file_id=' + props.fileId}
                                                className={classes.linkStyle}>
                                                <FormattedMessage id="download.translation" />
                                            </MLink>
                                        </MGrid>
                                        <MGrid item>
                                            <MLink onClick={preventDefault} href="#" className={classes.linkStyle}>
                                                <FormattedMessage id="download" />
                                                <FormattedMessage id="bi.lingual" />
                                                <FormattedMessage id="csv" />
                                            </MLink>
                                        </MGrid>
                                    </MGrid>
                                </MBox>
                                {tabName == 'content' &&
                                    <MBox mt={3} pb={3} px={3} className={classes.closeSection}>
                                        <MBox mb={2} fontSize={18} fontWeight="bold" color='#3a3a3a'>
                                            <FormattedMessage id="delete" />
                                        </MBox>

                                        <MGrid container spacing={10} direction="row" justifyContent="flex-start" alignItems="center">
                                            <MGrid item>
                                                <MLink onClick={() => handleDelSourceFile()} href='javascript:void(0);' className={classes.linkStyle}>
                                                    <FormattedMessage id="delete.source.file" />
                                                </MLink>
                                            </MGrid>
                                        </MGrid>
                                    </MBox>
                                }

                            </MBox>
                            :
                            ((props.fileDetails && props.fileDetails.isOnline === 0) || (selectedOrg && selectedOrg[0] && selectedOrg[0].onlineTranslation == '0')
                                ?
                                <MBox>
                                    <MBox pb={3} px={3}>
                                        <MGrid container spacing={2} alignItems="center">
                                            <MGrid item xs={12} sm={6} md={3} lg={3}>
                                                <MCard className={classes.statsCard}>
                                                    <MCardContent>
                                                        <MGrid container wrap="nowrap" spacing={2}>
                                                            <MGrid item>
                                                                <MBox ml={0.8} mt={1.6}>
                                                                    <MAvatar className={classes.statsCardIcon}>
                                                                        <DescriptionIcon color="action" />
                                                                    </MAvatar>
                                                                </MBox>
                                                            </MGrid>
                                                            <MGrid item>
                                                                <MBox mt={2.3} fontSize="16px" textAlign='left' lineHeight='14px'
                                                                    color='#3a3a3a'>
                                                                    {displayWords(props.languageDetailPopup)}
                                                                </MBox>
                                                                <MBox fontSize="14px" textAlign='left' color="#6b778c">
                                                                    <FormattedMessage id="total.words" />
                                                                </MBox>
                                                                {/*</MBox>*/}
                                                            </MGrid>
                                                        </MGrid>
                                                    </MCardContent>
                                                </MCard>
                                            </MGrid>
                                            <MGrid item xs={12} sm={6} md={3} lg={3}>
                                                <MCard className={classes.statsCard}>
                                                    <MCardContent>
                                                        <MGrid container wrap="nowrap" spacing={2}>
                                                            <MGrid item>
                                                                <MBox ml={0.8} mt={1.6}>
                                                                    <MAvatar className={classes.statsCardIcon}>
                                                                        <RateReviewIcon color="action" />
                                                                    </MAvatar>
                                                                </MBox>
                                                            </MGrid>
                                                            <MGrid item>
                                                                <MBox mt={2.3} fontSize="16px" textAlign='left' lineHeight='14px' color='#3a3a3a'>
                                                                    {displayReviewed(props.languageDetailPopup)}%
                                                                </MBox>
                                                                <MBox fontSize="14px" textAlign='left' color="#6b778c">
                                                                    <FormattedMessage id="words.reviewed" />
                                                                </MBox>
                                                            </MGrid>
                                                        </MGrid>
                                                    </MCardContent>
                                                </MCard>
                                            </MGrid>
                                            <MGrid item xs={12} sm={6} md={3} lg={3}>
                                                <MCard className={classes.statsCard}>
                                                    <MCardContent>
                                                        <MGrid className={classes.cardHeight} container wrap="nowrap"
                                                            spacing={2}>
                                                            <MGrid item>
                                                                <MBox ml={0.8} mt={1.6}>
                                                                    <MAvatar className={classes.statsCardIcon}>
                                                                        <GTranslateIcon color="action" />
                                                                    </MAvatar>
                                                                </MBox>
                                                            </MGrid>
                                                            <MGrid item>
                                                                <MBox mt={2.3} fontSize="16px" textAlign='left' lineHeight='14px'
                                                                    color='#3a3a3a'>
                                                                    {displayTranslated(props.languageDetailPopup)}%
                                                                </MBox>
                                                                <MBox fontSize="14px" textAlign='left' color="#6b778c">
                                                                    <FormattedMessage id="words.translated" />
                                                                </MBox>
                                                            </MGrid>
                                                        </MGrid>
                                                    </MCardContent>
                                                </MCard>
                                            </MGrid>
                                            <MGrid item xs={12} sm={6} md={3} lg={3}>
                                                <MCard className={classes.statsCard}>
                                                    <MCardContent>
                                                        <MGrid className={classes.cardHeight} container wrap="nowrap"
                                                            spacing={2}>
                                                            <MGrid item>
                                                                <MBox ml={0.8} mt={1.6}>
                                                                    <MAvatar className={classes.statsCardIcon}>
                                                                        <GTranslateIcon color="action" />
                                                                    </MAvatar>
                                                                </MBox>
                                                            </MGrid>
                                                            <MGrid item>
                                                                <MBox mt={2.3} fontSize="16px" textAlign='left' lineHeight='14px'
                                                                    color='#3a3a3a'>
                                                                    {displayUntranslated(props.languageDetailPopup)}%
                                                                </MBox>
                                                                <MBox fontSize="14px" textAlign='left' color="#6b778c">
                                                                    <FormattedMessage id="words.untranslated" />
                                                                </MBox>
                                                            </MGrid>
                                                        </MGrid>
                                                    </MCardContent>
                                                </MCard>
                                            </MGrid>
                                        </MGrid>
                                    </MBox>
                                    <MBox pb={3} px={3} className={classes.section}>

                                        {showFileUploadSection &&
                                            <MBox>
                                                <MBox className={classes.fileUploadWrap}>
                                                    <Dropzone
                                                        onChangeStatus={uploadModalOnChange}
                                                        getUploadParams={getUploadParams}
                                                        // accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,.xlf,.xliff,.json,.yaml,.yml,.doc,.docx,.tmx,.sdltm"
                                                        LayoutComponent={Layout}
                                                        inputContent={Input}
                                                        multiple
                                                    />
                                                </MBox>
                                            </MBox>
                                        }
                                        <MBox color='#3a3a3a' fontWeight='bold' fontSize={18} mt={3} display="flex"
                                            justifyContent="space-between" alignContent="center">
                                            <span>
                                                {projectLangFiles && projectLangFiles.length}
                                                <FormattedMessage id="target.files" />
                                            </span>
                                            {!showFileUploadSection &&
                                                <MButton color="secondary" onClick={() => {
                                                    setShowFileUploadSection(true);
                                                }} variant="contained">
                                                    <FormattedMessage id="upload.file" />
                                                </MButton>
                                            }
                                        </MBox>
                                        {
                                            projectLangFiles.map((element: any) => (
                                                <MBox key="projectLangFiles" borderRadius={4} mt={1} className={classes.offlineFileList}
                                                    sx={{ padding: '12px 12px' }}>
                                                    <MGrid container justifyContent="space-between" alignItems="center">
                                                        <MGrid item container xs={12} sm={8} alignItems="center" justifyContent="flex-start">
                                                            <MBox display="flex" alignItems="center">
                                                                <MGrid container direction="row" alignItems="flex-start">
                                                                    <MBox ml={1} className={classes.fileTitle}>
                                                                        {element.name}
                                                                    </MBox>
                                                                    <MBox ml={0.5} mt={0.2} className={classes.fileSize}>
                                                                        (14Kb)
                                                                    </MBox>
                                                                </MGrid>
                                                            </MBox>
                                                        </MGrid>
                                                        <MGrid item xs={12} sm={4}>
                                                            <MBox display="flex" justifyContent="space-between"
                                                                alignItems="center">
                                                                <MBox>
                                                                    <MBox justifyContent="flex-end">
                                                                        <MTypography className={classes.sourceText} component="div"
                                                                            variant="caption">
                                                                            <MScheduleIcon className="fa fa-plus-circle" fontSize="inherit" />
                                                                            <span>
                                                                                <FormattedMessage id="last.activity" />
                                                                            </span>
                                                                        </MTypography>
                                                                        <MBox
                                                                            fontSize={14} fontWeight="bold"
                                                                            color={'#3a3a3a'}>
                                                                            <FormattedDate value={element.createdAt}
                                                                                day="2-digit" month="long"
                                                                                year="numeric" />
                                                                        </MBox>
                                                                    </MBox>
                                                                </MBox>
                                                                <MBox>
                                                                    <MIconButton

                                                                        onClick={() => handleSetFileId(element.id)}
                                                                        aria-label="upload picture"
                                                                        component="span">
                                                                        <MDeleteIcon />
                                                                    </MIconButton>
                                                                    <MLink target="_blank"
                                                                        href={process.env.REACT_APP_API_END_POINT + '/download/file?downloadType=single&fileType=target&targetFileId=' + element.id}
                                                                        color="secondary">
                                                                        <MIconButton aria-label="upload picture"
                                                                            component="span">
                                                                            <GetAppIcon />
                                                                        </MIconButton>
                                                                    </MLink>
                                                                </MBox>
                                                            </MBox>
                                                        </MGrid>
                                                    </MGrid>
                                                </MBox>
                                            ))
                                        }
                                    </MBox>

                                    <MBox mt={3} pb={3} px={3} className={classes.section}>
                                        <MBox mb={2} fontSize={18} fontWeight="bold" color='#3a3a3a'>
                                            <FormattedMessage id="download" />
                                        </MBox>

                                        <MGrid container spacing={10} direction="row" justifyContent="flex-start"
                                            alignItems="center">
                                            <MGrid item>
                                                <MLink target="_blank"
                                                    href={process.env.REACT_APP_API_END_POINT + '/download/file?downloadType=single&fileType=source&fileId=' + props.fileId}
                                                    className={classes.linkStyle}>
                                                    <FormattedMessage id="download.source" />
                                                </MLink>
                                            </MGrid>
                                            <MGrid item>

                                                {
                                                    openDownloadTranslationPopup &&
                                                    <DownloadTranslationPopup fileId={props.fileId}
                                                        langId={props.languageDetailPopup.id}
                                                        setOpen={setOpenDownloadTranslationPopup}
                                                        open={openDownloadTranslationPopup}
                                                        showFileUploadSection={showFileUploadSection}
                                                        handleModalClose={handleModalClose} />
                                                }
                                            </MGrid>

                                        </MGrid>
                                    </MBox>
                                    {tabName == 'content' &&
                                        <MBox mt={3} pb={3} px={3} className={classes.closeSection}>
                                            <MBox mb={2} fontSize={18} fontWeight="bold" color='#3a3a3a'>
                                                <FormattedMessage id="delete" />
                                            </MBox>
                                            <MGrid container spacing={10} direction="row" justifyContent="flex-start"
                                                alignItems="center">
                                                <MGrid item>
                                                    <MLink onClick={() => handleDelSourceFile()} href='javascript:void(0);'
                                                        className={classes.linkStyle}>
                                                        <FormattedMessage id="delete.source.file" />
                                                    </MLink>
                                                </MGrid>
                                            </MGrid>
                                        </MBox>
                                    }
                                </MBox>
                                :
                                <EmptyContent />
                            ))}
                    </MDialogContent>

                </MBox>

                <MDialogActions>
                    <MGrid container direction="row" justifyContent="flex-end" alignItems="center">

                        <MBox my={1} mr={2}>
                            <MButton className="btnSmall" variant="outlined" onClick={() => handleModalClose(props.setOpen)} >
                                <FormattedMessage id="close" />
                            </MButton>
                        </MBox>
                    </MGrid>
                </MDialogActions>
            </MDialog>

            <Dialog
                open={openNew}
                onClose={() => openNewSet(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title"><FormattedMessage id="are.sure" /></DialogTitle>
                <DialogContent>
                    <MBox mr={9}>
                        <FormattedMessage id="clicking.target.file.deleted" />
                    </MBox>
                </DialogContent>
                <DialogActions>
                    <MButton className="btnSmall" onClick={() => openNewSet(false)} variant="contained">
                        <FormattedMessage id="no" />
                    </MButton>
                    <MButton className="btnSmall" onClick={() => handleDelTargetBtn()} variant="contained" color="secondary" autoFocus>
                        <FormattedMessage id="yes" />
                    </MButton>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const generate_target_files_zip_GQL = gql`
    mutation generateZipTargetFiles($langId: ID!,$fileId: ID!) {
        generateZipTargetFiles(langId: $langId,fileId: $fileId)
    }
`;

const PROJECT_Download_Target_Files_GQL = gql`
    query getDownloadTargetFile($fileId: ID!,$langId: ID!) {
        getDownloadTargetFile(fileId: $fileId,langId: $langId) {
            createdAt
            id
            name
            path
        }
    }
`;

const DownloadTranslationPopup = (props: any) => {
    const classes = useStyles({});
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(props.open);
    const [downloadedFiles, setDownloadedFiles] = useState([]);




    useEffect(() => {
        setLoading(true);
        generateZipTargetFiles();
    }, [props.fileId, props.langId]);

    useEffect(() => {
        props.setOpen(open);
    }, [open]);

    const [getProjectDownloadTargetFiles] = useLazyQuery(PROJECT_Download_Target_Files_GQL, {
        variables: { fileId: props.fileId, langId: props.langId },
        fetchPolicy: 'no-cache',
        onCompleted: (data: any) => {
            setDownloadedFiles(data && data.getDownloadTargetFile);
        }
    });

    useEffect(() => {
        getProjectDownloadTargetFiles();
    }, [props.fileId, props.langId]);

    const [generateZipTargetFiles] = useMutation(generate_target_files_zip_GQL, {
        variables: { fileId: props.fileId, langId: props.langId },
        fetchPolicy: 'no-cache',
        onCompleted: () => {


            setLoading(false);

            getProjectDownloadTargetFiles();

        }
    });

    return (
        <div>
            <MDialog sx={{ width: 500, margin: 'auto' }} fullWidth maxWidth='lg' onClose={() => setOpen(false)}
                aria-labelledby="customized-dialog-title" open={open}>

                <MBox>
                    <MDialogTitle className={classes.dialogHeader} id="customized-dialog-title" onClose={() => setOpen(false)}>
                        <FormattedMessage id="download.translation" />
                    </MDialogTitle>

                    <MDialogContent className={classes.contentWrapper}>
                        <MBox p={3}>
                            {loading &&
                                <MBox>
                                    <MCircularProgress color="secondary" size={34}
                                        sx={{
                                            position: 'absolute',
                                            left: 0,
                                            right: 0,
                                            margin: 'auto'
                                        }} />
                                </MBox>
                            }
                            {!loading &&
                                <MButton sx={{ margin: 'auto', display: 'block' }} className="btnSmall"
                                    variant="contained"
                                    onClick={() => {
                                        // setOpen(false);
                                        setLoading(true);
                                        generateZipTargetFiles();
                                    }}
                                    color="secondary">
                                    <GetAppIcon sx={{ fontSize: 18, marginRight: 5, marginBottom: '-5px' }} />
                                    <FormattedMessage id="request.download" />
                                </MButton>
                            }

                            {
                                downloadedFiles.map((element: any, index: number) => (


                                    <MBox p={1} mt={4} key={"box" + index}
                                        sx={{ width: '100%', boxShadow: '0 0 5px lightgray', borderRadius: 4 }}>
                                        <MGrid container key={index} justifyContent="space-between" alignItems="center">
                                            <MGrid item xs={4} lg={3} xl={3} sx={{ textAlign: 'left' }}>
                                                <MTypography variant="span">
                                                    {element.name}
                                                </MTypography>
                                            </MGrid>
                                            <MGrid item xs={5} lg={4} xl={3} sx={{ textAlign: 'right' }}>
                                                <MLink
                                                    href={process.env.REACT_APP_API_END_POINT + '/download/file?downloadType=zip&fileType=target&targetFileId=' + element.id}>
                                                    <GetAppIcon sx={{
                                                        color: 'black',
                                                        fontSize: 18,
                                                        marginRight: 5,
                                                        marginBottom: '-5px'
                                                    }} />
                                                </MLink>
                                                {/*<MButton color="secondary" variant="contained">Download</MButton>*/}
                                            </MGrid>
                                        </MGrid>
                                    </MBox>
                                ))
                            }

                        </MBox>
                    </MDialogContent>

                </MBox>

                <MDialogActions>
                    <MBox my={1}>
                        <MButton sx={{ border: 'none' }} className="btnSmall" variant="outlined" onClick={() => props.handleModalClose(setOpen)}
                        >
                            <FormattedMessage id="close" />
                        </MButton>
                    </MBox>
                </MDialogActions>
            </MDialog>


        </div>
    )
}