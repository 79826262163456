import { FETCH_JOB_LANGUAGES_REQUEST, FETCH_JOB_LANGUAGES_SUCCESS, FETCH_JOB_LANGUAGES_FAILURE } from './JobActionTypes';


interface IAction {
    type: string,
    payload: any,
    action: any,
}
interface IState {
    languages: any,
    loading: boolean,
    error: any
}
const initialState: IState = {
    languages: null,
    loading: false,
    error: null
};

export default function JobLanguagesReducer(state = initialState, action: IAction) {
    switch (action.type) {
        case FETCH_JOB_LANGUAGES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_JOB_LANGUAGES_SUCCESS:
            return {
                ...state,
                languages: action.payload,
                loading: false
            };
        case FETCH_JOB_LANGUAGES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}