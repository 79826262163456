/* eslint-disable*/
/*@typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ApolloProvider } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import clsx from 'clsx';

import {
    getAllOrganizations,
    selectedProjectId,
    selectOrganization, setAuth,
    setCurrentUserData,
    setSidebarType,
    setUserRole,
    sideBarShow,
} from 'src/app/store';
import { MBox, MAppBar, MIconButton, MToolbar, MDrawer } from 'src/app/material-ui';
import { MKeyboardArrowLeftSharpIcon, MKeyboardArrowRightSharpIcon } from 'src/app/material-ui/icons'
import { client, useRoleRedirect } from './hooks';
import useStyles from './appStyles';
import './App.css';
import ErrorBoundary from './components/error-boundry/ErrorBoundry';
import ComingSoonModal from './components/coming-soon-modal/ComingSoonModal';
import ShowModal from './components/Modal/Modal'
import MobileHeader from './components/partials/header/MobileHeader';
import { selectOrganizationLoading } from "./store/organizations/OrganizationsActions";
import ClientCn from '../lang/client-cn.json';
import GlobalCn from '../lang/global-cn.json';
import LinguistCn from '../lang/linguist-cn.json';
import VendorCn from '../lang/vendor-cn.json';
import Default from '../lang/en.json';
import Global from '../lang/global.json';
import Client from '../lang/client.json';
import Vendor from '../lang/vendor.json';
import Linguist from '../lang/linguist.json';
import Redirect from './components/redirect/Redirect';
import { IntlProvider } from "react-intl";
import InvoiceDetailModal from "./screens/vendor/job-detail/InvoiceDetailModel";
import Routing from './routes/routing';
import {
    Footer,
    VendorSidebar,
    CustomerSidebar,
    LinguistSidebar,
    MessageAlert,
    SidebarNew,
    HeaderNew,
    FinanceManagerSideBar
} from './components';

import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/de';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/de';

const GET_ORGANIZATIONS_AND_CURRENT_USER_GQL = gql`
    {
        currentUser {
            id
            firstName
            lastName
            username
            selectedOrgId
            address
            profilePicture
            defaultRole
            lang
            isPrivate
            isProfile
            postalCode
            timeZone
            isEmailEnable
            rates
            isBuyPackageFlag
            slug
            ssoUserName
            vendor_is_mt
            isChatNotification
            country {
                id
                countryName
            }  
            city {
                id
                cityName
            }
            state {
                id
                stateName
            }
       
        }
        
        organizations {
            id
            name
            logo
            isOwner
            address
            isAdmin
            isProjectAdmin
            isVendorAdmin
            url
            createdAt
            description
            onlineTranslation
            isAutoProcessingFromMarsHub
            autoPreprocessing
            autoPostprocessing
            walletBalance
            groups {
                id
                name
            }
            permissions{
                name
                granted
                url
            }
            vendorProfile {
                id
                vendor_profile_id
                company_name
                vendor_is_mt
                logo
            }
            subscriptionPackage {
                 name
                 accountUserLimit
                 teamUserGroup
            }
            services {
                id
                name
            }
            orgIndustries {
                id
                name
            }
        }
    }`;


function App(props: any) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    const [open, setOpen] = useState(true);
    const [invoiceData, setInvoiceData]: any = useState('');
    const [allOrgs, setAllOrgs]: any = useState();
    const [isOrgSet, setIsOrgSet]: any = useState(false);
    const [isRedirecting, setIsRedirecting]: any = useState(false);
    const [locale, setLocale]: any = useState(() => window.localStorage.getItem('locale'));
    const [viewDetailOpenModal, setViewDetailOpenModal] = useState(false);
    const [quoteDetails, setQuoteDetails]: any = useState(null);
    const [state, setState] = useState({
        mobileView: false,
        drawerOpen: false,
    });

    const storeSidebarType = useSelector((store: any) => store.sideBar.sidebarType);
    const isLogin = useSelector((store: any) => store.auth.isLogin);
    const isVendorAdmin = useSelector((store: any) => store.orgs.isVendorAdmin);
    const isProjectAdmin = useSelector((store: any) => store.orgs.isProjectAdmin);
    const isSidebarShow = useSelector((store: any) => store.sideBar.showSideBar);
    const notificationData = useSelector((store: any) => store.notification.notificationData);
    const showHeader = useSelector((store: any) => store.sideBar.showHeader);
    const localSidebarType = localStorage.getItem('side_bar_type');
    const appUserRole = useSelector((store: any) => store.userRole.userRole);
    const selectedOrg = window.localStorage.getItem('selectedOrgId');
    const selectedOrgId = useSelector((store: any) => store.orgs.selectedOrgId);
    const userRole = useSelector((store: any) => store.auth.currentUser.defaultRole);
    const projId = useSelector((store: any) => store.projects.selectedProjectId);
    const storeOrgs = useSelector((store: any) => store.orgs.organizations);
    const ComingSoonModalOpen = useSelector((store: any) => store.alert.comingSoonModal);
    const showModalOpen = useSelector((store: any) => store.alert.showModal);
    const currentUser = useSelector((store: any) => store.auth.currentUser);
    const allOrganizations = useSelector((store: any) => store.orgs.organizations);

    function getUrlParameter(name: any) {
        // eslint-disable-next-line no-empty-character-class
        name = name.replace(/[]/, '\\[').replace(/[\]]/, '\\]');
        const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        const results = regex.exec(window.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    // alert('hi');
    // useEffect(() => {
    //     // if (notificationData && notificationData.description) {
    //     //     setInvoiceData(JSON.parse(notificationData && notificationData.description))
    //     //     setViewDetailOpenModal(true)
    //     //     // Continue processing the parsed data...
    //     // }
    //     notifications && notifications.forEach((notification: any) => {
    //         const parsedData = JSON.parse(notification.description);
    //
    //         if (notification.type === 'invoice_paid') {
    //             setInvoiceData(parsedData)
    //             setViewDetailOpenModal(true)
    //         } else if (notification.type === 'job_accepted') {
    //             // notification.type = 'projectStatus';
    //         } else if (notification.type === 'job_rejected') {
    //             // notification.type = 'jobRejected';
    //         } else if (notification.type === 'job_dispatched') {
    //             // notification.type = 'jobAccepted';
    //         } else if (notification.type === 'linguist_added_supplier') {
    //             // notification.type = 'jobAccepted';
    //         } else if (notification.type === 'job_approved') {
    //             // notification.type = 'jobAccepted';
    //         }else if (notification.type === 'rate_request') {
    //             // notification.type = 'jobAccepted';
    //         }
    //     });
    //
    //
    // }, [notificationData])

    // if PM is invited not the existing one then it will redirect the route according to the given permission 


    if (localStorage.getItem("locale") === null) {
        localStorage.setItem('locale', "en-US")
    }

    const userCheck = localStorage.getItem('user_role');
    // English
    const clientLocalGlobalFile = { ...Client, ...Global };
    const vendorLocalGlobalFile = { ...Vendor, ...Global };
    const linguistLocalGlobalFile = { ...Linguist, ...Global };
    const financeLocalGlobalFile = { ...Global };
    const DefaultFile = { ...Default, ...Global };

    // Chinese
    const clientCnLangFile = { ...ClientCn, ...GlobalCn };
    const vendorCnLangFile = { ...VendorCn, ...GlobalCn };
    const linguistCnLangFile = { ...LinguistCn, ...GlobalCn };
    const financeCnLangFile = { ...GlobalCn };
    const DefaultFileCN = { ...Default, ...GlobalCn };

    let roleLang;

    if (userCheck === 'ROLE_CLIENT' || userCheck === 'ROLE_CUSTOMER') {
        roleLang = clientLocalGlobalFile
    } else if (userCheck === 'ROLE_VENDOR') {
        roleLang = vendorLocalGlobalFile
    } else if (userCheck === 'ROLE_TRANSLATOR') {
        roleLang = linguistLocalGlobalFile
    } else if (userCheck === 'ROLE_FINANCE') {
        roleLang = financeLocalGlobalFile
    } else if (userCheck === null || userCheck === "") {
        roleLang = DefaultFile
    }

    let roleCnLang;
    if (userCheck === 'ROLE_CLIENT' || userCheck === 'ROLE_CUSTOMER') {
        roleCnLang = clientCnLangFile
    } else if (userCheck === 'ROLE_VENDOR') {
        roleCnLang = vendorCnLangFile
    } else if (userCheck === 'ROLE_TRANSLATOR') {
        roleCnLang = linguistCnLangFile
    } else if (userCheck === 'ROLE_FINANCE') {
        roleCnLang = financeCnLangFile
    } else if (userCheck === null || userCheck === "") {
        roleCnLang = DefaultFileCN
    }

    // language files config
    // const locale = navigator.language;
    let lang;
    if (locale === "en-US" || locale === "en") {
        lang = roleLang;
    } else {
        if (locale === "zh-CN") {
            lang = roleCnLang;
        }
    }

    const { mobileView } = state;

    useEffect(() => {
        if (allOrganizations && selectedOrg) {
            // with selected Organization Id => selectedOrg we are finding the currently selected organization object from allOrganization array
            const selectedOrganization = allOrganizations.find((org: any) => org.id === +selectedOrg);

            if (selectedOrganization && !selectedOrganization.isOwner) {
                const permission = selectedOrganization.permissions.find((permission: any) => permission.granted && permission.url);
                const pathname = window.location.pathname;
                // if its profile url no need to redirect
                const isProfileUrl = pathname.includes('profile');

                const vendorMyJobs = pathname.includes('vendor/projects/my-jobs');

                const alreadyExistUrl = selectedOrganization.permissions.find((permission: any) => permission.granted && permission.url && (('/' + pathname.split('/')[1]) == permission.url || (pathname) == permission.url));

                if (permission?.url && !alreadyExistUrl && !isProfileUrl && !vendorMyJobs) {
                    props.history.push(permission.url);
                }
            }
        }
    }, [allOrganizations, selectedOrg, props.history]);


    useEffect(() => {
        if (notificationData && notificationData.type !== undefined && notificationData.type) {
            // notificationData.forEach((notification:any) => {

            const parsedData = notificationData.description ? JSON.parse(notificationData && notificationData.description) : [];
            if (parsedData !== null && parsedData.length !== 0) {
                if (notificationData.type && notificationData.type === 'invoice_paid' || notificationData.type === 'invoice_by_supplier') {
                    const newParsedData = { ...parsedData, type: notificationData.type };
                    setInvoiceData(newParsedData)
                    setViewDetailOpenModal(true)
                    // Your logic for 'invoice_paid'
                } else if (notificationData.type && notificationData.type === 'job_accepted') {

                    props.history.push(`/projects/tasks/${parsedData.projectId}?po=1&id=${parsedData && parsedData.jobId}`);

                } else if (notificationData.type && notificationData.type === 'job_rejected') {

                    props.history.push(`/projects/file/${parsedData.projectId}`);

                } else if (notificationData.type && notificationData.type === 'job_dispatched') {
                    props.history.push(`/projects/tasks/${parsedData.projectId}?po=1&id=${parsedData && parsedData.jobId}`);

                } else if (notificationData.type && notificationData.type === 'linguist_added_supplier' || notificationData.type === 'vendor_added_supplier') {
                    // window.open(
                    //     `${parsedData && parsedData.role == 'linguist' ? '/linguist/profile/' : '/vendor/profile/'}${parsedData.slug}`, "_blank")


                } else if (notificationData.type && notificationData.type === 'job_approved') {

                    if (appUserRole === 'ROLE_VENDOR') {
                        props.history.push(`/vendor/projects/my-jobs?id=${parsedData && parsedData.jobId}`);
                    } else {
                        props.history.push(`/linguist/my-jobs?id=${parsedData && parsedData.jobId}`);
                    }

                } else if (notificationData.type && notificationData.type === 'rate_request') {
                    props.history.push('/rate-request');
                }
            }
        }
    }, [notificationData]);

    useEffect(() => {

        // window.localStorage.setItem('selectedPlanId','');
        const accessToken = getUrlParameter('accessToken');
        if (!accessToken) {
            const token = localStorage.getItem('access_token');
            if (!token) {
                if (window.location.pathname !== '/customer/support') {
                    setIsRedirecting(true);
                    window.location.href = (process.env.REACT_APP_SSO_ENDPOINT + '/login?redirect_url=' + window.location.origin);
                }

            } else {
                dispatch(setAuth(token));
            }
        } else if (accessToken) {
            const name = getUrlParameter('first_name');
            const email = getUrlParameter('email');
            const username = getUrlParameter('username');
            let data: any = '';
            if (window.location.href.split('/')[3] == 'login') {
                data = window.location.href.split('/').pop();
            }

            const password = '123123';
            let firstName = name;
            let lastName = getUrlParameter('last_name');

            if (!firstName && !lastName) {
                lastName = firstName = email.split('@')[0];
            }
            if (firstName && !lastName) {
                lastName = firstName;
            }
            if (!firstName && lastName) {
                firstName = lastName;
            }

            const body: any = {};
            body.firstName = lastName;
            body.lastName = firstName;
            body.email = email;
            body.username = username;
            body.password = password;
            body.accessToken = accessToken;
            body.data = data.split('?')[0];
            axios.post(process.env.REACT_APP_API_END_POINT + '/register', body).then(
                (res: any) => {
                    // if(res.data.code == 200) {

                    if (res.data.errors.code == 401) {
                        dispatch(sideBarShow(false))
                        props.history.push('/customer/support');
                    } else {
                        localStorage.setItem('access_token', accessToken);
                        dispatch(setAuth(accessToken));
                    }
                    // }
                }).catch(() => {

                    localStorage.setItem('access_token', accessToken);

                    dispatch(setAuth(accessToken));
                });

        }
    }, [currentUser, isLogin]);

    // The  function is the function which is dispatching and calling the organization api

    useEffect(() => {
        // The  function is calling and also setting the loading true
        fetchAndHandleData();
        dispatch(selectOrganizationLoading(true));
    }, [isLogin, dispatch, currentUser.profilePicture]);


    useEffect(() => {
        if (storeOrgs) {
            setAllOrgs(storeOrgs)
        }
    }, [storeOrgs])

    useEffect(() => {
        if (allOrgs && isOrgSet) {
            if (allOrgs.length < 1) {
                if (currentUser && currentUser.isBuyPackageFlag === false && appUserRole !== 'ROLE_TRANSLATOR') {

                    if (!appUserRole) {
                        props.history.push('/role');
                    } else {
                        let selectedPl: any = window.localStorage.getItem('selectedPlanId');
                        // alert(appUserRole);
                        if (selectedPl == 1) {
                            if (appUserRole == 'ROLE_CLIENT') {
                                props.history.push('/create-organization');
                            } else {
                                props.history.push('/vendor/account/settings#organization/settings');
                            }
                        } else if (window.localStorage.getItem('selectedPlanId')) {
                            props.history.push('/package/payments/' + window.localStorage.getItem('selectedPlanId'));
                        } else {
                            // props.history.push('/subscriptions/SubscriptionPlan');
                            // props.history.push('/subscriptions/SubscriptionPlan');

                            let role = 'client';
                            if (currentUser.defaultRole !== 'ROLE_CLIENT') {
                                role = 'lsp';
                            }

                            // alert(role);
                            let redirectPricingPage = process.env.REACT_APP_PRICING_URL + '?role=' + role;
                            window.location.replace(redirectPricingPage);
                        }
                    }
                } else if (appUserRole === 'ROLE_CLIENT') {

                    // if(window.localStorage.getItem('selectedPlanId')) {
                    //     props.history.push('/package/payments/'+window.localStorage.getItem('selectedPlanId'));
                    // } else {
                    props.history.push('/create-organization')
                    // }
                } else if (appUserRole === 'ROLE_VENDOR') {
                    props.history.push('/vendor/account/settings')
                }
                // else if (appUserRole === 'ROLE_TRANSLATOR' && currentUser.isProfile) {
                //    return  props.history.push('/linguist/assigned-jobs')
                // } else if (appUserRole === 'ROLE_TRANSLATOR'){
                //   return  props.history.push('/linguist/profile')
                // }
            } else {
                if (userRole !== undefined && userRole) {
                    userRedirectByRole(userRole);
                }
            }
        }
    }, [appUserRole, allOrgs, isOrgSet]);


    useEffect(() => {
        if ((appUserRole == 'ROLE_CLIENT' && !selectedOrgId) || appUserRole == '') {
            // if(appUserRole == 'ROLE_CLIENT' && !selectedOrgId && !window.localStorage.getItem('selectedPlanId')) {
            //     props.history.push('/subscriptions/SubscriptionPlan');
            // }

            if (window.location.pathname == '/customer/support' || window.location.pathname == '/subscriptions/SubscriptionPlan' || window.location.pathname == '/create-organization' || window.location.pathname == '/import-empty') {
                dispatch(sideBarShow(false));
            }

        } else {
            dispatch(sideBarShow(true))
        }
    }, [selectedOrgId, appUserRole]);

    useEffect(() => {
        const routeLinkSplit = location.pathname.split('/');
        const routeLinkSplitPop = location.pathname.split('/').pop();
        // alert(routeLinkSplitPop);
        // alert(Number(routeLinkSplit[routeLinkSplit.length - 1]));
        if ((routeLinkSplit[1] === 'tasks' || routeLinkSplit[1] === 'my-jobs' || routeLinkSplit[1] === 'projects') && routeLinkSplitPop !== 'projects' && location.pathname != '/projects/job/bids/' + Number(routeLinkSplit[routeLinkSplit.length - 1])) {
            // set project if from page url on reload
            const projectId = (routeLinkSplit[routeLinkSplit.length - 1]);
            if (projectId && projectId !== undefined) {
                dispatch(selectedProjectId(projectId));
                // dispatch(setSidebarType('projectBar'));
                dispatch(sideBarShow(true))
            }
        } else if (projId !== 0 && (routeLinkSplit[1] !== 'projects' || routeLinkSplitPop === 'projects')) {
            // if user click unrelated link to peoject detail pages
            dispatch(selectedProjectId('0'));
            if (localSidebarType === "projectBar" && appUserRole != 'ROLE_VENDOR') {
                dispatch(setSidebarType('ROLE_CLIENT'));
            } else if (localSidebarType === "projectBar") {
                dispatch(setSidebarType('ROLE_CLIENT'));
            }
        }

        // alert(routeLinkSplit[1]);
        if (isVendorAdmin && (routeLinkSplit[1] == 'projects' || routeLinkSplit[1] == 'dashboard')) {
            // props.history.push('/vendor/my-vendors');
            props.history.push('/vendors');
        }

        // window.localStorage.setItem('selectedPlanId','');

        // console.log('routeLinkSplit[1]', routeLinkSplit[1]);

        if (isProjectAdmin && (routeLinkSplit[1] != 'job' && routeLinkSplit[1] != 'projects' && routeLinkSplit[1] != 'create-project' && routeLinkSplit[1] != 'setting' && routeLinkSplit[1] != 'my-account' && routeLinkSplit[1] != 'package')) {
            props.history.push('/projects');

            if (appUserRole == 'ROLE_VENDOR') {
                dispatch(setSidebarType('ROLE_CLIENT'));
                dispatch(setUserRole('ROLE_CLIENT'));
            }
        } else if (appUserRole == 'ROLE_CUSTOMER' && routeLinkSplit.length == 2 && routeLinkSplit[0] == '' && routeLinkSplit[1] == '') {
            props.history.push('/projects');
        } else if (routeLinkSplit[1] == 'package') {
            // console.log('routeLinkSplit[1]', routeLinkSplit[3]);
            window.localStorage.setItem('selectedPlanId', routeLinkSplit[3]);
            // props.history.push('/projects');
        }


        // console.log('routeLinkSplit[1]', routeLinkSplit[1]);

        // if project id 0 store in redux
        if (localSidebarType === 'projectBar' && routeLinkSplit[1] !== 'projects' && appUserRole == 'ROLE_VENDOR') {
            dispatch(setSidebarType('ROLE_VENDOR'))
        } else if (localSidebarType === 'projectBar' && projId === 0) {
            dispatch(setSidebarType('ROLE_CLIENT'))
        }
    }, [location, projId]);

    useEffect(() => {
        if (localSidebarType == 'projectBar') {
            localStorage.setItem('user_role', 'ROLE_CLIENT');
            dispatch(setUserRole('ROLE_CLIENT'));
        }
    }, [localSidebarType])
    //for opening hamburger menu in mobile view

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 960
                ? setState((prevState) => ({ ...prevState, mobileView: true }))
                : setState((prevState) => ({ ...prevState, mobileView: false }));
        };

        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());
        return () => {
            window.removeEventListener("resize", () => setResponsiveness());
        };
    }, []);

    useEffect(() => {
        setLocale(window.localStorage.getItem('locale'));
    }, [isLogin, userCheck])

    function subscribeOrganizationUserAddedEvent() {
        const url: any = new URL(process.env.REACT_APP_MERCURE_URL + '/.well-known/mercure');
        url.searchParams.append('topic', 'organization/user/added/' + currentUser.id);
        const eventSource = new EventSource(url);
        // The callback will be called every time an update is published
        eventSource.onmessage = () => {
            // The will call the function  every time when  an update is published and it will refetch the organization data into dropdown
            fetchAndHandleData()
        };
    }

    const fetchAndHandleData = () => {
        if (isLogin) {
            client
                .query({
                    query: GET_ORGANIZATIONS_AND_CURRENT_USER_GQL,
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all'
                })
                .then((response: any) => {
                    let orgFound = false;
                    for (let i = 0; i < response.data.organizations.length; i++) {
                        if (response.data.organizations[i] && response.data.organizations[i].id == selectedOrg) {
                            orgFound = true;
                        }
                    }
                    if (!orgFound && response.data.organizations && response.data.organizations.length > 0) {
                        window.localStorage.setItem('selectedOrgId', response.data.organizations[0].id);
                        dispatch(selectOrganization(response.data.organizations[0].id));
                    }
                    setAllOrgs(response.data.organizations && response.data.organizations);
                    dispatch(getAllOrganizations(response.data.organizations && response.data.organizations));
                    subscribeOrganizationUserAddedEvent();
                    dispatch(setCurrentUserData(response.data.currentUser));
                    setIsOrgSet(true);
                    if (location.pathname === "/" || location.pathname === "/signup") {
                        dispatch(setSidebarType(response.data.currentUser.defaultRole));
                        dispatch(setUserRole(response.data.currentUser.defaultRole));
                    }
                    dispatch(setSidebarType(response.data.currentUser.defaultRole));
                    dispatch(setUserRole(response.data.currentUser.defaultRole));
                    setUserDefaultOrg(response);
                    checkUserRole(response);
                    dispatch(selectOrganizationLoading(false));

                    if (response.data.currentUser.defaultRole == 'ROLE_FINANCE_USER') {
                        dispatch(setSidebarType('ROLE_FINANCE'));
                        dispatch(setUserRole('ROLE_FINANCE'));
                    }

                    if (response.data.organizations.length == 0) {
                        if (response.data.currentUser.defaultRole == 'ROLE_CLIENT' && !window.localStorage.getItem('selectedPlanId')) {
                            // props.history.push('/subscriptions/SubscriptionPlan');
                            if (process.env.REACT_APP_PRICING_URL) {

                                let role = 'client';
                                if (response.data.currentUser.defaultRole !== 'ROLE_CLIENT') {
                                    role = 'lsp';
                                }

                                let redirectPricingPage = process.env.REACT_APP_PRICING_URL + '?role=' + role;
                                window.location.replace(redirectPricingPage);
                            }
                        }
                    }
                })
                .catch((error: any) => {
                    // Handle error
                    console.error('Error fetching data:', error);
                    dispatch(selectOrganizationLoading(false));
                });
        }
    };

    const { handelRedirect } = useRoleRedirect(props);
    const userRedirectByRole = (role: any) => {
        // user redirect on page load or after login,signup according to role
        if (location.pathname === "/" || location.pathname === "/signup") {
            handelRedirect(role)
        }
    }

    const setUserDefaultOrg = (response: any) => {
        // set organizations in redux and set user owner org id
        const organizations = response.data.organizations && response.data.organizations;
        if (localStorage.getItem('selectedOrgId')) {
            dispatch(selectOrganization(Number(localStorage.getItem('selectedOrgId'))));
        } else if (organizations && organizations.length > 0) {
            dispatch(selectOrganization(organizations && organizations[0] && organizations[0].id));
        } else {
            dispatch(selectOrganization(0));
        }
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const openCloseDrawer = () => {
        if (open === false) {
            handleDrawerOpen();
        } else {
            handleDrawerClose();
        }
    }

    const checkUserRole = (response: any) => {
        // check role first on login or page load , redirect run if role not set
        if (isLogin && response.data.currentUser.defaultRole === "") {
            props.history.push('/role')
        }
    }

    const localeChinese = () => {
        localStorage.setItem('locale', 'zh-CN');
        setLocale('zh-CN')
    }
    const localeEnglish = () => {
        localStorage.setItem('locale', 'en-US');
        setLocale('en-US')
    }

    const secureContent = (
        <div className={classes.root}>
            <ApolloProvider client={client}>
                {showHeader && !isRedirecting &&
                    <MAppBar
                        position="fixed"
                        className={`${clsx(classes.appBar, {
                            [classes.appBarShift]: open,
                        })} ${!isSidebarShow && classes.fullWidth} ${open ? 'topHeader' : ''} ${mobileView && classes.fullWidth}`}>
                        <MToolbar>
                            {mobileView ? <MobileHeader /> : <HeaderNew localeChinese={localeChinese} localeEnglish={localeEnglish} locale={locale} />}
                            {/* <HeaderNew /> */}

                            {isSidebarShow && !isRedirecting &&
                                <MIconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={openCloseDrawer}
                                    edge="start"
                                    className={clsx(classes.navigationResizer, {
                                        [classes.navigationResizerClosed]: !open,
                                    })}
                                    onMouseEnter={() => {
                                        const test = document.getElementById('drawerResizer')!;
                                        if (test) {
                                            test.style.opacity = "1";
                                        }
                                    }}
                                    onMouseLeave={() => {
                                        const test = document.getElementById('drawerResizer')!;
                                        if (test) {
                                            test.style.opacity = "0";
                                        }
                                    }}
                                    id="drawerResizer"
                                >
                                    {(
                                        open ? <MKeyboardArrowLeftSharpIcon className={classes.arrowLeft} /> :
                                            <MKeyboardArrowRightSharpIcon className={classes.arrowRight} />
                                    )}
                                </MIconButton>
                            }
                        </MToolbar>
                    </MAppBar>
                }

                {/* {selectedOrgId != null && showSideBar && (storeSidebarType === "projectBar" && appUserRole === 'ROLE_CLIENT') && */}

                {typeof invoiceData != "undefined" && invoiceData && invoiceData.type == 'invoice_paid' || invoiceData.type == 'invoice_by_supplier' &&

                    <InvoiceDetailModal setInvoiceData={setInvoiceData} setViewDetailOpenModal={setViewDetailOpenModal} invoiceId={invoiceData.invoiceId} taskId={invoiceData.jobId} viewDetailOpenModal={viewDetailOpenModal} setQuoteDetails={setQuoteDetails} quoteRequestedId={quoteDetails && +quoteDetails?.id} />
                }
                {isSidebarShow && !isRedirecting &&
                    (
                        !mobileView &&
                        <MDrawer
                            variant="permanent"
                            className={clsx(['mainDrawer'], classes.drawer, {
                                [classes.drawerOpen]: open,
                                [`${classes.drawerClose} ${classes.drawerSm}`]: !open,
                            })}
                            onMouseEnter={() => {
                                const test = document.getElementById('drawerResizer')!;

                                if (test) {
                                    test.style.opacity = "1";
                                }
                            }}
                            onMouseLeave={() => {
                                const test = document.getElementById('drawerResizer')!;
                                if (test) {
                                    test.style.opacity = "0";
                                }
                            }}
                            classes={{
                                paper: clsx({
                                    [classes.drawerOpen]: open,
                                    [`${classes.drawerClose} ${classes.drawerSm}`]: !open,
                                }),
                            }}
                            id="mainDrawer"
                        >
                            {
                                storeSidebarType === 'ROLE_CLIENT' && isSidebarShow ?
                                    <SidebarNew drawerState={open} className={classes.drawerSm} />
                                    : ((storeSidebarType === 'projectBar') && (appUserRole === 'ROLE_CLIENT')) ?
                                        <SidebarNew drawerState={open} className={props.drawerClose} />
                                        : ((storeSidebarType === 'ROLE_VENDOR') && (appUserRole === 'ROLE_VENDOR')) ?
                                            <VendorSidebar />
                                            : storeSidebarType === 'ROLE_TRANSLATOR' ?
                                                <LinguistSidebar /> : ''

                            }
                            {storeSidebarType === 'ROLE_FINANCE' ? <FinanceManagerSideBar /> : ''}
                            {storeSidebarType === 'ROLE_CUSTOMER' ? <CustomerSidebar /> : ''}
                        </MDrawer>
                    )
                }
                <MBox component="div"
                    className={classes.content}
                    mt={8} p={5} position="relative">
                    <ErrorBoundary {...props}>
                        {/* {selectedOrgId!=null && */}
                        <MBox>
                            <Routing />
                        </MBox>
                        {/* } */}
                    </ErrorBoundary>
                    <Footer />
                </MBox>
            </ApolloProvider>
        </div>
    );

    return (
        <IntlProvider locale={locale} messages={lang}>
            <div className="App">
                {/* {isLogin ? secureContent :
                <MBox>
                    <Routing/>
                </MBox>
            } */}
                {/* {selectedOrgId && */}

                {/* it will show loading when logout or login */}
                {isRedirecting && <Redirect />}
                {secureContent}
                <MessageAlert />
                <ComingSoonModal modalOpen={ComingSoonModalOpen} />
                <ShowModal modalOpen={showModalOpen} />
                {/*{invoiceDrawer}*/}

            </div>
        </IntlProvider>
    );
}

export default withRouter(App);
