/* eslint-disable */
import React from 'react';
import { Autocomplete, Chip } from "@mui/material";
// import useStyles from './AutoCompletetStyles';
import TextField from '@mui/material/TextField';
import { MKeyboardArrowDownRoundedIcon } from "../icons";

interface iProps {
  label?: string,
  name?: string,
  className?: string,
  id: string,
  options: Array<any>,
  getOptionLabel?: any,
  onOpen?: any,
  onChange?: any,
  onInputChange?: any,
  multiple?: any,
  disabled?: any,
  filterSelectedOptions?: any,
  defaultValue?: any,
  disableClearable?: boolean,
  autoComplete?: any,
  popupIcon?: any,
  clearOnEscape?: any,
  limitTags?: number,
  style?: any,
  value?: any,
  renderOption?: any,
  disableCloseOnSelect?: any,
  loading?: boolean,
  loadingText?: string,
  placeholder?: string,
  freeSolo?: boolean,
  renderTags?: any,
  open?: boolean,
  renderInput?: any,
  clearIcon?: any,
  sx?: any,
  size?: any,
  isOptionEqualToValue?: any
}

const renderTags = (tagProps: any) => {
  return (
    tagProps.chipData.map((option: any, index: number) => {
      const { key, ...restProps } = tagProps.getTagProps({ index }); // Remove key

      return (
        <Chip
          key={index}  // Pass key explicitly
          label={option.name || option.label || 'Unnamed Option'}
          {...restProps}  // Spread the rest of the props
        />
      );
    })
  );
};


export function MAutoComplete(props: iProps) {

  const { key, industryId, defaultIndustry, inputProps, setFieldValue, ...restProps }: any = props

  const autocomplete = (defaultValue?: any) => {
    return (
      <Autocomplete
        size="small"
        defaultValue={defaultValue}
        limitTags={props.limitTags !== undefined ? props.limitTags : 1}
        popupIcon={<MKeyboardArrowDownRoundedIcon />}
        {...restProps as iProps}
        renderTags={(value, getTagProps) => renderTags({ chipData: value, getTagProps })}
        renderInput={(params) => <TextField  {...params} color='primary' placeholder={props.placeholder} label={!props.placeholder ? props.label : ''} variant="outlined" />}
        renderOption={(renderOptionProps, option, state) =>
          // [renderOptionProps, option, state.index] as React.ReactNode
          <li {...renderOptionProps} key={option.id || state.index}>
            {option.name || option.label || option.title || state.index}
          </li>
        }
      />
    )
  }

  return (
    <>
      {props?.defaultValue ?
        autocomplete(props.defaultValue) :
        autocomplete()
      }
    </>
  )
}