/* eslint-disable*/
import React from 'react';
import {
    MBox,
    MAccordion,
    MAccordionDetails,
    MAccordionSummary,
    MAccordionActions, MSkeleton
} from 'src/app/material-ui';
import useStyles from './MatchRatesStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function MatchRatesSkeleton() {
    const classes = useStyles({});
    return (
        <MAccordion className={classes.accordion} expanded={true}>
            <MAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <MSkeleton height={18} width={100} />
            </MAccordionSummary>
            <MAccordionDetails>
                <MBox width="100%">
                    <MBox borderBottom="1px solid #eaeaea" pb={2} px={3} className="inputsRow" display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                        <MBox maxWidth="230px" display="flex" alignItems="center">
                            <MBox width="60px" mr={1.2} textAlign="right">
                                <MSkeleton height='100%' width='100%' />
                            </MBox>
                            <MSkeleton height={33} width={118} />
                        </MBox>
                        <MBox maxWidth="230px" display="flex" alignItems="center">
                            <MBox width="60px" mr={1.2} textAlign="right">
                                <MSkeleton height='100%' width='100%' />
                            </MBox>
                            <MSkeleton height={33} width={118} />
                        </MBox>
                    </MBox>
                    <MBox borderBottom="1px solid #eaeaea" pb={2} px={3} className="inputsRow" display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                        <MBox maxWidth="230px" display="flex" alignItems="center">
                            <MBox width="60px" mr={1.2} textAlign="right">
                                <MSkeleton height='100%' width='100%' />
                            </MBox>
                            <MSkeleton height={33} width={118} />
                        </MBox>
                        <MBox maxWidth="230px" display="flex" alignItems="center">
                            <MBox width="60px" mr={1.2} textAlign="right">
                                <MSkeleton height='100%' width='100%' />
                            </MBox>
                            <MSkeleton height={33} width={118} />
                        </MBox>
                    </MBox>
                    <MBox borderBottom="1px solid #eaeaea" pb={2} px={3} className="inputsRow" display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                        <MBox maxWidth="230px" display="flex" alignItems="center">
                            <MBox width="60px" mr={1.2} textAlign="right">
                                <MSkeleton height='100%' width='100%' />
                            </MBox>
                            <MSkeleton height={33} width={118} />
                        </MBox>
                        <MBox maxWidth="230px" display="flex" alignItems="center">
                            <MBox width="60px" mr={1.2} textAlign="right">
                                <MSkeleton height='100%' width='100%' />
                            </MBox>
                            <MSkeleton height={33} width={118} />
                        </MBox>
                    </MBox>
                    <MBox px={3} className="inputsRow" display="flex" justifyContent="space-between" alignItems="center" pb={2}>
                        <MBox maxWidth="230px" display="flex" alignItems="center">
                            <MBox width="60px" mr={1.2} textAlign="right">
                                <MSkeleton height='100%' width='100%' />
                            </MBox>
                            <MSkeleton height={33} width={118} />
                        </MBox>
                        <MBox maxWidth="230px" display="flex" alignItems="center">
                            <MBox width="65px" mr={1.2}>
                                <MSkeleton height='100%' width='100%' />
                            </MBox>
                            <MSkeleton height={33} width={118} />
                        </MBox>
                    </MBox>
                </MBox>
            </MAccordionDetails>
            <MAccordionActions>
                <MSkeleton height={36} width={106} />
                <MSkeleton height={36} width={30} />
            </MAccordionActions>
        </MAccordion>
    )
}
