/* eslint-disable */
import React, { MouseEvent, useEffect, useState } from 'react';
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import GridViewIcon from '@mui/icons-material/GridView';
import MemoryOutlinedIcon from '@mui/icons-material/MemoryOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Fade from '@mui/material/Fade';
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { gql } from 'apollo-boost';

import {
    MAvatar,
    MBadge,
    MBox,
    MGrid,
    MIconButton,
    MList,
    MListItem,
    MListItemIcon,
    MListItemText,
    MMenu,
    MMenuItem,
    MTooltip,
} from 'src/app/material-ui';
import { showAlert } from 'src/app/store';
import { avatarPlaceholder } from '../../../hooks/index';
import SidebarSkeleton from './SidebarSkeleton';
import useStyles from './SidebarStylesNew';
import OrganizationDropdown from './organization-dropdown/OrganizationDropdown';
// import CheckPermission from 'src/app/hooks/permissions/CheckPermission';
import CheckPermissions from "src/app/hooks/permissions/CheckPermissions";
const SELECTED_ORGANIZATION_PROJECTS_GQL = gql`
    query Organizations($id: ID){
        organizations(id: $id) {
            projects {
                id
                name
                createdAt
                projectId
            }
        }
    }`;
const GET_RATE_REQUEST_BIT = gql`
    query getRateRequestsBit($orgId: Int!){
        getRateRequestsBit(orgId: $orgId)
        
    }`;
function SidebarNew({ props }: any) {

    const classes = useStyles({});
    // const allowList = CheckPermission('ROLE_PROJECT_LIST');
    // const peopleList = CheckPermission('ROLE_PEOPLE_LIST');
    // const suppliersList = CheckPermission('ROLE_MY_SUPPLIERS_LIST');
    // const findNewsuppliersList = CheckPermission('ROLE_FIND_NEW_SUPPLIERS_LIST');
    // const rateRequestList = CheckPermission('ROLE_RATE_REQUEST_LIST');
    // const [setProjectsData]: any = useState();
    const [organizations, setOrganization]: any = useState();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const urlType = window.location.href.split('/');
    const [allowList, setAllowList] = useState(false);
    const [peopleList, setPeopleList] = useState(false);
    const [suppliersList, setSuppliersList] = useState(false);
    const [findNewsuppliersList, setFindNewsuppliersList] = useState(false);
    const [rateRequestList, setRateRequestList] = useState(false);
    const [allowSidebarTM, setAllowSidebarTM] = useState(false);
    const [glossaryList, setGlossaryList] = useState(false);
    const [allowSidebarFD, setAllowSidebarFD] = useState(false);
    const [styleGuideList, setStyleGuideList] = useState(false);
    const [isChatNotification, setIsChatNotification]: any = useState('false');

    const selectedOrgId = useSelector((store: any) => store.orgs.selectedOrgId);
    const allOrganizations = useSelector((store: any) => store.orgs.organizations);
    const selectedOrg = useSelector((store: any) => store.orgs);
    const isOwner = useSelector((store: any) => store.orgs.isOwner);
    // const isAdmin = useSelector((store: any) => store.orgs.isAdmin);
    const refreshSideBarProjects = useSelector((store: any) => store.projects.refreshSideBarProjects);
    const orgs = useSelector((store: any) => store.orgs.organizations);
    const appUserRole = useSelector((store: any) => store.userRole.userRole);
    // const organizations = useSelector((store: any) => store.orgs.organizations);
    const currentUser = useSelector((store: any) => store.auth.currentUser);
    const selectedOrgLoading = useSelector((store: any) => store.orgs.selectedOrgLoading);
    // const { handelRedirect } = useRoleRedirect(props);
    const dispatch = useDispatch();

    const [getRateRequestBit] = useLazyQuery(
        GET_RATE_REQUEST_BIT, {
            variables: { orgId: selectedOrgId },
            fetchPolicy: "no-cache",
            onCompleted: (data: any) => {
                setIsChatNotification(data && data.getRateRequestsBit);
            }
        } as any);

    const [getProjects] = useLazyQuery(
        SELECTED_ORGANIZATION_PROJECTS_GQL, {
            variables: { id: selectedOrgId },
            fetchPolicy: "no-cache",
            // onCompleted: (data: any) => {
            //     setProjectsData(data && data.organizations[0]);
            // }
        } as any);

    useEffect(() => {
        if (selectedOrgId) {
            getRateRequestBit();
            getProjects();
        }
    }, [refreshSideBarProjects, selectedOrgId]);

    useEffect(() => {
        if (orgs && (orgs.length > 0)) {
            setOrganization(orgs)

        }
    }, [orgs])

    useEffect(() => {
        if (currentUser?.id != 0) {

            subscribeRateRequestEvent();

        }
    }, [currentUser]);

    useEffect(() => {
        if (selectedOrgId && allOrganizations) {
            setAllowList(CheckPermissions('ROLE_PROJECT_LIST', selectedOrgId, allOrganizations));
            setPeopleList(CheckPermissions('ROLE_PEOPLE_LIST', selectedOrgId, allOrganizations));
            setSuppliersList(CheckPermissions('ROLE_MY_SUPPLIERS_LIST', selectedOrgId, allOrganizations));
            setFindNewsuppliersList(CheckPermissions('ROLE_FIND_NEW_SUPPLIERS_LIST', selectedOrgId, allOrganizations));
            setRateRequestList(CheckPermissions('ROLE_RATE_REQUEST_LIST', selectedOrgId, allOrganizations));
            setAllowSidebarTM(CheckPermissions("ROLE_TM_LIST", selectedOrgId, allOrganizations));
            setGlossaryList(CheckPermissions('ROLE_GLOSSARY_LIST', selectedOrgId, allOrganizations));
            setAllowSidebarFD(CheckPermissions("ROLE_FILE_DRIVE_LIST", selectedOrgId, allOrganizations));
            setStyleGuideList(CheckPermissions('ROLE_STYLE_GUIDE_LIST', selectedOrgId, allOrganizations));
        }

    }, [selectedOrgId, allOrganizations]);

    function subscribeRateRequestEvent() {
        const url: any = new URL(process.env.REACT_APP_MERCURE_URL + '/.well-known/mercure');
        url.searchParams.append('topic', 'request/rate-request/' + currentUser.id);
        const eventSource = new EventSource(url);
        // The callback will be called every time an update is published
        eventSource.onmessage = ((e: any) => {
            e.preventDefault();
            getRateRequestBit();
            // setIsChatNotification(currentUser.rates)
        });
    }

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    const logout = () => {
        const token = localStorage.getItem('access_token');
        localStorage.clear();
        const url = process.env.REACT_APP_SSO_ENDPOINT + '/logout?redirect_url=' + window.location.origin + '&access_token=' + token;
        // window.location.href = "" + process.env.REACT_APP_SSO_LOGOUT_URL + token;
        window.location.href = url;
        dispatch(showAlert({ message: 'You are signing out.', messageType: 'error', showAlertMessage: true }))
        // localStorage.clear()
        // dispatch(resetState());
        // props.history.push('/')
    };

    const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className || "" }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#4f4f4f',
            color: "#fff",
            fontSize: 12,
            filter: "drop-shadow(0px 8px 16px rgba(214,215,226,0.57))",
            border: "1px solid #f4f5f7",
            borderRadius: 0,
            padding: "10px 15px",
            maxWidth: 230,
        },
        arrow: {
            color: theme.palette.common.white,
        },
    }));

    // const noPageModalOpen = (e: any) => {
    //     e.preventDefault();
    //     dispatch(showComingSoonModal(true));
    // };
    // const switchRole = (role: string) => {
    //     setAnchorEl(null);
    //     if (role !== '') {
    //         dispatch(setUserRole(role));
    //         dispatch(setSidebarType(role));
    //         // localStorage.setItem('user_role',role);
    //         handelRedirect(role)
    //     }
    // };

    return (
        // <Zoom in={props.drawerState} unmountOnExit>
        <MBox className={classes.sidebarWrap}>

            {selectedOrgLoading
                ?
                <SidebarSkeleton />
                :
                <>
                    <MBox style={{ marginBottom: 9 }}>
                        {!(appUserRole === "ROLE_CLIENT" && currentUser.isPrivate) ? <>{(appUserRole === "ROLE_CLIENT")
                            ?
                            <MGrid item className={classes.logoBox}>
                                <NavLink to={(organizations && organizations.length > 0) ? "/projects" : "/create-organization"}>
                                    <img className="logoFull" src="/assets/images/marshub-logo.svg" width="140px" alt="logo" />
                                    <img className="logoSmall" src="/assets/images/logo-sm.svg" alt="logo" />
                                    <MBox>
                                    </MBox>
                                </NavLink>
                            </MGrid>
                            :
                            <MGrid item className={classes.logoBox}>
                                <NavLink to={((appUserRole === "ROLE_TRANSLATOR") ? "/linguist/profile" : "/vendor/open-jobs")}>
                                    <img className="logoFull" src="/assets/images/marshub-logo.svg" width="140px" alt="logo" />
                                    <img className="logoSmall" src="/assets/images/logo-sm.svg" alt="logo" />
                                </NavLink>
                            </MGrid>
                        }
                        </> : <br />}
                        {organizations && organizations.length > 0 &&
                            <MGrid item >
                                <OrganizationDropdown className="orgTest" headerText="Organizations" footerText="See All" />
                            </MGrid>
                        }
                    </MBox>

                    <MBox>
                        {selectedOrgId !== 0 &&
                            <>
                                <MBox className={classes.generalLinks}>
                                    <MList className={classes.sideBarLinks}>
                                        {((appUserRole === "ROLE_CLIENT" && currentUser.isPrivate == 1) || allOrganizations?.find((item: any) => item.id == selectedOrgId)?.isOwner != true) ? null :
                                            <MListItem button component={NavLink} to={'/vendor/dashboard'} className={urlType[3] === 'vendor/dashboard' ? 'active' : ''}>
                                                <MListItemIcon>
                                                    <LightTooltip arrow placement="right" title={<FormattedMessage id="dashboard" />}>
                                                        <GridViewIcon />
                                                    </LightTooltip>
                                                </MListItemIcon>
                                                <MListItemText primary={<FormattedMessage id="dashboard" />} />
                                            </MListItem>
                                        }
                                        {allowList && <MListItem button component={NavLink} to={'/projects'} className={urlType[3] === 'create-project' || urlType[3] === 'github-integration' ? 'active' : ''}>
                                            <MListItemIcon>
                                                <LightTooltip arrow placement="right" title={<FormattedMessage id="projects" />}>
                                                    <DashboardOutlinedIcon />
                                                </LightTooltip>
                                            </MListItemIcon>
                                            <MListItemText primary={<FormattedMessage id="projects" />} />
                                        </MListItem>}

                                        {selectedOrg.isProjectAdmin !== true &&
                                            <>
                                                {(appUserRole === "ROLE_CLIENT" && currentUser.isPrivate == 1) ?
                                                    allowSidebarFD &&
                                                    <MListItem button component={NavLink} to={`/files`}>
                                                        <MListItemIcon style={{ color: '#6b778c' }}>
                                                            <LightTooltip arrow placement="right" title="Global Files">
                                                                <DescriptionOutlinedIcon />
                                                            </LightTooltip>
                                                        </MListItemIcon>
                                                        <MListItemText primary={<FormattedMessage id="global.files" />} />
                                                    </MListItem>
                                                    :
                                                    <>
                                                        {peopleList && <MListItem button component={NavLink} to={'/manage-admins'} className={(urlType[3] === 'manage-admins-invite' || urlType[3] === 'manage-group') ? 'active' : ''} >
                                                            <MListItemIcon>
                                                                <LightTooltip arrow placement="right" title={<FormattedMessage id="menu.people" />}>
                                                                    <PeopleAltOutlinedIcon />
                                                                </LightTooltip>
                                                            </MListItemIcon>
                                                            <MListItemText primary={<FormattedMessage id="menu.people" />} />
                                                        </MListItem>}
                                                    </>
                                                }
                                                {(suppliersList || findNewsuppliersList) && <MListItem button component={NavLink} to={'/vendors'} className={urlType[3] === 'find-vendor' ? 'active' : ''}>
                                                    <MListItemIcon>
                                                        <LightTooltip arrow placement="right" title={<FormattedMessage id="suppliers" />} >
                                                            <PersonOutlineIcon />
                                                        </LightTooltip>
                                                    </MListItemIcon>
                                                    <MListItemText primary={<FormattedMessage id="suppliers" />} />
                                                </MListItem>}
                                                {rateRequestList && <MListItem button component={NavLink} to={`/rate-request`}>
                                                    <MListItemIcon style={{ color: '#6b778c' }}>
                                                        <LightTooltip arrow placement="right" title={<FormattedMessage id="rate.request" />} >
                                                            <MonetizationOnIcon />
                                                        </LightTooltip>
                                                    </MListItemIcon>
                                                    <MListItemText primary={<FormattedMessage id="rate.request" />} />
                                                    {isChatNotification && isChatNotification !== 'false' &&
                                                        <MBadge variant="dot" color="secondary" className={classes.dotBagde}>
                                                        </MBadge>
                                                    }
                                                </MListItem>}
                                            </>
                                        }
                                        {/* <MListItem button component={NavLink} to={'/vendor/linguist'}>
                                    <MListItemIcon>
                                        <LightTooltip arrow placement="right" title="Linguist">
                                        <RecentActorsOutlinedIcon/>
                                        </LightTooltip>
                                    </MListItemIcon>
                                    <MListItemText primary="Linguist" />
                                </MListItem> */}

                                        {/* <MListItem button component={NavLink} to={`/projects/tm/`+projectId}>
                                    <MListItemIcon>
                                        <LightTooltip arrow placement="right" title="Translation Memory">
                                            <MemoryOutlinedIcon/>
                                        </LightTooltip>
                                    </MListItemIcon>
                                    <MListItemText primary="TM"/>
                            </MListItem> */}
                                        {/* <MListItem button component={NavLink} to={`/projects/glossary/`+projectId}>
                                    <MListItemIcon>
                                        <LightTooltip arrow placement="right" title="glossary">
                                            <MenuBookOutlinedIcon/> 
                                        </LightTooltip>
                                    </MListItemIcon>
                                    <MListItemText primary="Glossary"/>
                            </MListItem> */}
                                        {/* <MListItem button  onClick={(e: any) => noPageModalOpen(e)}>
                                <MListItemIcon>
                                    <LightTooltip arrow placement="right" title="Reports">
                                        <AssessmentOutlinedIcon />
                                    </LightTooltip>
                                </MListItemIcon>
                                <MListItemText primary="Reports"/>
                            </MListItem> */}
                                    </MList>
                                </MBox>
                            </>
                        }
                        {selectedOrg.isProjectAdmin !== true &&
                            <MBox className={classes.generalLinks} px={1.2} pb={12} mb={9.5}>

                                <>
                                    {(glossaryList || allowSidebarTM || styleGuideList || allowSidebarFD) &&
                                        <MBox className="orgFull">
                                            <FormattedMessage id="org.resources" />
                                        </MBox>}
                                    {/* <MBox className="orgSm">Organization Resources</MBox> */}
                                </>

                                {/* <MBox className={classes.orgName}>Organization Resources</MBox> */}

                                <MList className={classes.sideBarLinks}>
                                    {(appUserRole === "ROLE_CLIENT" && currentUser.isPrivate == 1) ? "" : allowSidebarFD &&
                                        <MListItem button component={NavLink} to={`/files`}>
                                            <MListItemIcon style={{ color: '#6b778c' }}>
                                                <LightTooltip arrow placement="right" title="Global Files">
                                                    <DescriptionOutlinedIcon />
                                                </LightTooltip>
                                            </MListItemIcon>
                                            <MListItemText primary={<FormattedMessage id="global.files" />} />
                                        </MListItem>
                                    }
                                    {allowSidebarTM &&
                                        <MListItem button component={NavLink} to={`/tm`}>
                                            <MListItemIcon sx={{ color: '#6b778c' }}>
                                                <LightTooltip arrow placement="right" title="Translation Memory">
                                                    <MemoryOutlinedIcon />
                                                </LightTooltip>
                                            </MListItemIcon>
                                            <MListItemText primary={<FormattedMessage id="translation.memory" />} />
                                        </MListItem>
                                    }
                                    {glossaryList &&
                                        <MListItem button component={NavLink} to={`/glossary`}>
                                            <MListItemIcon sx={{ color: '#6b778c' }}>
                                                <LightTooltip arrow placement="right" title="Glossary">
                                                    <MenuBookOutlinedIcon />
                                                </LightTooltip>
                                            </MListItemIcon>
                                            <MListItemText primary={<FormattedMessage id="glossary" />} />
                                        </MListItem>
                                    }
                                    {/*<MListItem button component={NavLink} to={`/projects/style-guides/`+projectId}>*/}
                                    {styleGuideList &&
                                        <MListItem button component={NavLink} to={`/style-guides`}>
                                            <MListItemIcon sx={{ color: '#6b778c' }}>
                                                <LightTooltip arrow placement="right" title="Styled Guide">
                                                    <FeedbackOutlinedIcon />
                                                </LightTooltip>
                                            </MListItemIcon>
                                            <MListItemText primary={<FormattedMessage id="style.guides" />} />
                                        </MListItem>
                                    }
                                    {/* <MListItem button component={NavLink} to={`/projects/languages/`+projectId}>
                                <MListItemIcon sx={{color:'#6b778c'}}><GTranslateOutlinedIcon/></MListItemIcon>
                                <MListItemText primary={<FormattedMessage id="languages"/>}/>
                            </MListItem> */}
                                    {/* <MListItem button component={NavLink} to="/client/project-dashboard/'+projectId">
                                <MListItemIcon sx={{color:'#6b778c'}}><MBox fontSize={16}>TM</MBox></MListItemIcon>
                                <MListItemText primary="Translation Memory"/>
                            </MListItem> */}
                                    {/* <MListItem button component={NavLink} to={`/projects/glossary/`+projectId}>
                                <MListItemIcon sx={{color:'#6b778c'}}><MenuBookOutlinedIcon/></MListItemIcon>
                                <MListItemText  primary={<FormattedMessage id="glossary"/>}/>
                            </MListItem>
                            <MListItem button component={NavLink} to={`/projects/style-guides/`+projectId}>
                                <MListItemIcon sx={{color:'#6b778c'}}><FeedbackOutlinedIcon/></MListItemIcon>
                                <MListItemText primary={<FormattedMessage id="style.guides"/>}/>
                            </MListItem> */}
                                    {/* <MListItem button component={NavLink} to={`/projects/work-flow/`+projectId}>
                                <MListItemIcon sx={{color:'#6b778c'}}><SettingsInputCompositeIcon/></MListItemIcon>
                                <MListItemText primary="Workflow"/>
                            </MListItem> */}
                                    {/* <MListItem button component={NavLink} to="/settings">
                                <MListItemIcon sx={{color:'#6b778c'}}><MSettingsOutlinedIcon/></MListItemIcon>
                                <MListItemText primary="Settings"/>
                            </MListItem> */}
                                </MList>
                            </MBox>
                        }
                        {/* //sidebar footer */}
                        {(appUserRole === "ROLE_CLIENT")
                            ?
                            <MGrid className={classes.footerSm} item>
                                {/*<NavLink to={(organizations && organizations.length > 0) ? "/projects" : "/create-organization"}>*/}
                                <MBox className="footerFull">
                                    <MBox className={classes.projectSidebarFooter + ' userDropdown'}>
                                        <MBox display="flex" width="calc(100% - 76px)">
                                            {currentUser && currentUser.profilePicture
                                                ?
                                                <MAvatar className={'footerAvatar'} src={currentUser && currentUser.profilePicture ? process.env.REACT_APP_PUBLIC_URL + '/' + currentUser.profilePicture : ""} />
                                                :
                                                <MAvatar className={'footerAvatar'}>
                                                    {currentUser && avatarPlaceholder(`${currentUser.firstName} ${currentUser.lastName}`)}
                                                </MAvatar>
                                            }
                                            <MBox ml={1.5} width="100%" className={classes.footerDetails}>
                                                {
                                                    currentUser &&
                                                        (parseInt(currentUser.firstName.length + currentUser.lastName.length) > 20)
                                                        ?
                                                        <MTooltip placement="top" title={currentUser.firstName + ' ' + currentUser.lastName}>
                                                            <MBox className={classes.sidebarAvatar}>
                                                                {currentUser.firstName.concat(" " + currentUser.lastName).slice(0, 20) + "..."}
                                                            </MBox>
                                                        </MTooltip>
                                                        :
                                                        <MBox className={classes.sidebarAvatar}>
                                                            {currentUser.firstName + ' ' + currentUser.lastName}
                                                        </MBox>
                                                }
                                                <MBox fontSize={14} color="#A2A4B9">
                                                    {appUserRole === "ROLE_VENDOR" && 'Vendor'}
                                                    {appUserRole === "ROLE_CLIENT" && currentUser.isPrivate && <FormattedMessage id="Private Client" />}
                                                    {/*{appUserRole === "ROLE_CLIENT" && !currentUser.isPrivate && <FormattedMessage id="client" />}*/}
                                                    {organizations && organizations.length > 0 && organizations.find((item: any) => item.id == selectedOrgId)?.isOwner == true && !currentUser.isPrivate && appUserRole === "ROLE_CLIENT" && <FormattedMessage id="client" />}
                                                    {organizations && organizations.length > 0 && organizations.find((item: any) => item.id == selectedOrgId)?.isOwner != true && !currentUser.isPrivate && organizations.find((item: any) => item.id == selectedOrgId)?.groups && organizations.find((item: any) => item.id == selectedOrgId)?.groups.length > 0 && organizations.find((item: any) => item.id == selectedOrgId)?.groups[0].name}
                                                    {appUserRole === "ROLE_TRANSLATOR" && 'Linguist'}
                                                </MBox>
                                            </MBox>
                                        </MBox>
                                        <MBox mr={-2}>
                                            <MIconButton aria-controls="notificationMenu" aria-haspopup="true" color="inherit"
                                                onClick={handleClick}>
                                                <MoreVertIcon sx={{ fontSize: 22, color: '#8d8d8d' }} />
                                            </MIconButton>
                                        </MBox>
                                        <MMenu
                                            id="notificationMenu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                            TransitionComponent={Fade}
                                            className={classes.userDropdown}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                        >
                                            {/* <NavLink className={classes.menuLink} to={((appUserRole === "ROLE_VENDOR" ? '/vendor/open-jobs' : (appUserRole === "ROLE_TRANSLATOR") ? '#' : '/projects'))}>
                                                <MMenuItem onClick={handleClose}>
                                                    <FormattedMessage id="dashboard" />
                                                </MMenuItem>
                                            </NavLink> */}
                                            {isOwner && appUserRole === "ROLE_CLIENT" &&
                                                <NavLink className={classes.menuLink} to="/payments">
                                                    <MMenuItem onClick={handleClose} className={classes.menuLink}>
                                                        <FormattedMessage id="payments" />
                                                    </MMenuItem>
                                                </NavLink>
                                            }
                                            {isOwner && appUserRole === "ROLE_CLIENT" &&
                                                <NavLink className={classes.menuLink} to="/client/account/settings">
                                                    <MMenuItem onClick={handleClose}>
                                                        <FormattedMessage id="account.settings" />
                                                    </MMenuItem>
                                                </NavLink>
                                            }
                                            {/*<NavLink className={classes.menuLink} to={"/activity-log"}>*/}
                                            {/*<MMenuItem onClick={handleClose} className={classes.menuLink}>*/}
                                            {/*<FormattedMessage id="activity.log" />*/}
                                            {/*</MMenuItem>*/}
                                            {/*</NavLink>*/}
                                            {/*<NavLink className={classes.menuLink} to={((appUserRole === "ROLE_VENDOR" ? '/vendor/settings' : (appUserRole === "ROLE_TRANSLATOR") ? '/linguist/profile' : '/settings'))}>*/}
                                            {/*<MMenuItem onClick={handleClose} className={classes.menuLink}>*/}
                                            {/*<FormattedMessage id="settings" />*/}
                                            {/*</MMenuItem>*/}
                                            {/*</NavLink>*/}
                                            {/* {(isOwner || isOwner == "") ?
                                        <MBox mt={1} mb={.75} pb={1} className={classes.roleWrap}>
                                            <MBox mt={1.5} ml={1.25} mb={.5} fontSize={12} color="#6b778c"><FormattedMessage id="switch.role" /></MBox>
                                            <MMenuItem className={(appUserRole === "ROLE_CLIENT") ? classes.selectedRole : ''} onClick={() => switchRole('ROLE_CLIENT')}>
                                                <FormattedMessage id="client" />
                                            </MMenuItem>
                                            <MMenuItem className={appUserRole === "ROLE_VENDOR" ? classes.selectedRole : ''} onClick={() => switchRole('ROLE_VENDOR')}>
                                                <FormattedMessage id="vendor" />
                                            </MMenuItem>
                                            <MMenuItem className={appUserRole === "ROLE_TRANSLATOR" ? classes.selectedRole : ''} onClick={() => switchRole('ROLE_TRANSLATOR')}>
                                                Linguist
                                            </MMenuItem>

                                        </MBox> :
                                        <MBox sx={{ marginTop: 10, marginBottom: 5, borderTop: "1px solid #d8d9db" }}></MBox>
                                    } */}
                                            <MMenuItem onClick={logout} className={`${classes.logoutLink}`}><span
                                                className={`${classes.menuLink} ${classes.logoutLink}`}><FormattedMessage id="logout" /></span></MMenuItem>
                                        </MMenu>

                                    </MBox>
                                </MBox>
                                <MBox className="footerSm">
                                    <MAvatar className={'footerAvatar'} sx={{ width: 36, height: 36 }} src={currentUser && currentUser.profilePicture ? currentUser.profilePicture : "/assets/images/avatar.jpg"}
                                        onClick={handleClick}>
                                        {/* <MoreVertIcon sx={{ fontSize: 22, color: '#8d8d8d' }} /> */}
                                    </MAvatar>
                                </MBox>
                                {/* <MBox className="footerSm">
                            hello test
                                </MBox> */}

                                {/* <img className="logoFull" src="/assets/images/marshub-logo.svg" alt="logo" width="140px" />
                            <img className="logoSmall" src="/assets/images/logo-sm.svg" alt="logo" width="140px" /> */}
                                <MBox>
                                </MBox>
                                {/*</NavLink>*/}
                            </MGrid>
                            :
                            <>
                                {currentUser.id ? <MGrid item className={classes.logoBox}>
                                    <NavLink to={((appUserRole === "ROLE_TRANSLATOR") ? "/linguist/profile" : "/vendor/open-jobs")}>
                                        <MBox className="footerFull">
                                            <MBox className={classes.projectSidebarFooter + ' userDropdown'}>
                                                <MBox display="flex">
                                                    <MAvatar className={'footerAvatar'} src={currentUser && currentUser.profilePicture
                                                        ?
                                                        currentUser.profilePicture
                                                        :
                                                        "/assets/images/avatar.jpg"
                                                    } />
                                                    <MBox ml={1.5}>
                                                        {
                                                            currentUser &&
                                                                (parseInt(currentUser.firstName.length + currentUser.lastName.length) > 20)
                                                                ?
                                                                <MTooltip placement="top" title={currentUser.firstName + ' ' + currentUser.lastName}>
                                                                    <MBox sx={{ fontSize: '16', fontFamily: "sans-serif", color: "#3a3a3a", fontWeight: 700, textTransform: 'uppercase' }}>
                                                                        {currentUser.firstName.concat(" " + currentUser.lastName).slice(0, 20) + "..."}
                                                                    </MBox>
                                                                </MTooltip>
                                                                :
                                                                <MBox fontSize={16} fontFamily="sans-serif" color="#3a3a3a" sx={{ textTransform: 'uppercase' }}>
                                                                    {currentUser.firstName + ' ' + currentUser.lastName}
                                                                </MBox>

                                                        }
                                                        <MBox fontSize={14} color="#A2A4B9">
                                                            {organizations && organizations.length > 0 && organizations.find((item: any) => item.id == selectedOrgId)?.isOwner == true && appUserRole === "ROLE_VENDOR" && 'Vendor'}
                                                            {organizations && organizations.length > 0 && organizations.find((item: any) => item.id == selectedOrgId)?.isOwner == true && appUserRole === "ROLE_CLIENT" && currentUser.isPrivate && 'Private Client'}
                                                            {organizations && organizations.length > 0 && organizations.find((item: any) => item.id == selectedOrgId)?.isOwner == true && appUserRole === "ROLE_CLIENT" && !currentUser.isPrivate && 'Client'}
                                                            {organizations && organizations.length > 0 && organizations.find((item: any) => item.id == selectedOrgId)?.isOwner == true && appUserRole === "ROLE_TRANSLATOR" && 'Linguist'}
                                                            {/* {organizations && organizations.length>0 && organizations.find((item:any)=>item.id==selectedOrgId)?.isOwner == true && appUserRole === "ROLE_VENDOR" && <FormattedMessage id="vendor" />} */}
                                                            {organizations && organizations.length > 0 && organizations.find((item: any) => item.id == selectedOrgId)?.isOwner != true && organizations.find((item: any) => item.id == selectedOrgId)?.groups && organizations.find((item: any) => item.id == selectedOrgId)?.groups.length > 0 && organizations.find((item: any) => item.id == selectedOrgId)?.groups[0].name}
                                                        </MBox>
                                                    </MBox>
                                                </MBox>
                                                <MBox mr={-2}>
                                                    <MIconButton aria-controls="notificationMenu" aria-haspopup="true" color="inherit"
                                                        onClick={handleClick}>
                                                        <MoreVertIcon sx={{ fontSize: 22, color: '#8d8d8d' }} />
                                                    </MIconButton>
                                                </MBox>
                                                <MMenu
                                                    id="notificationMenu"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleClose}
                                                    TransitionComponent={Fade}
                                                    className={classes.userDropdown}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'center',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'center',
                                                    }}
                                                >
                                                    {/* <NavLink className={classes.menuLink} to={((appUserRole === "ROLE_VENDOR" ? '/vendor/open-jobs' : (appUserRole === "ROLE_TRANSLATOR") ? '#' : '/projects'))}>
                                                    <MMenuItem onClick={handleClose}>
                                                        <FormattedMessage id="dashboard" />
                                                    </MMenuItem>
                                                </NavLink> */}
                                                    {(isOwner) && (appUserRole === "ROLE_CLIENT") &&
                                                        <NavLink className={classes.menuLink} to="/transactions">
                                                            <MMenuItem onClick={handleClose} className={classes.menuLink}>
                                                                <FormattedMessage id="transactions" />
                                                            </MMenuItem>
                                                        </NavLink>
                                                    }
                                                    {isOwner && <NavLink className={classes.menuLink} to="/settings">
                                                        <FormattedMessage id="my" />
                                                        <MMenuItem onClick={handleClose}>
                                                            <FormattedMessage id="account.settings" />
                                                        </MMenuItem>
                                                    </NavLink>}
                                                    {/*<NavLink className={classes.menuLink} to={((appUserRole === "ROLE_VENDOR" ? '/vendor/settings' : (appUserRole === "ROLE_TRANSLATOR") ? '/linguist/profile' : '/settings'))}>*/}
                                                    {/*<MMenuItem onClick={handleClose} className={classes.menuLink}>*/}
                                                    {/*<FormattedMessage id="settings" />*/}
                                                    {/*</MMenuItem>*/}
                                                    {/*</NavLink>*/}
                                                    {/*{(isOwner || isOwner === "")*/}
                                                    {/*?*/}
                                                    {/*<MBox mt={1} mb={.75} pb={1} className={classes.roleWrap}>*/}
                                                    {/*<MBox mt={1.5} ml={1.25} mb={.5} fontSize={12} color="#6b778c">*/}
                                                    {/*<FormattedMessage id="switch.role" />*/}
                                                    {/*</MBox>*/}
                                                    {/*<MMenuItem className={(appUserRole === "ROLE_CLIENT") ? classes.selectedRole : ''} onClick={() => switchRole('ROLE_CLIENT')}>*/}
                                                    {/*<FormattedMessage id="client" />*/}
                                                    {/*</MMenuItem>*/}
                                                    {/*<MMenuItem className={appUserRole === "ROLE_VENDOR" ? classes.selectedRole : ''} onClick={() => switchRole('ROLE_VENDOR')}>*/}
                                                    {/*<FormattedMessage id="vendor" />*/}
                                                    {/*</MMenuItem>*/}
                                                    {/*<MMenuItem className={appUserRole === "ROLE_TRANSLATOR" ? classes.selectedRole : ''} onClick={() => switchRole('ROLE_TRANSLATOR')}>*/}
                                                    {/*<FormattedMessage id="linguist.side" />*/}
                                                    {/*</MMenuItem>*/}
                                                    {/*</MBox>*/}
                                                    {/*:*/}
                                                    {/*<MBox sx={{ marginTop: 10, marginBottom: 5, borderTop: "1px solid #d8d9db" }}>*/}
                                                    {/*</MBox>*/}
                                                    {/*}*/}
                                                    <MMenuItem onClick={logout} className={`${classes.logoutLink}`}>
                                                        <span className={`${classes.menuLink} ${classes.logoutLink}`}>
                                                            <FormattedMessage id="logout" />
                                                        </span>
                                                    </MMenuItem>
                                                </MMenu>
                                            </MBox>
                                        </MBox>
                                        <MBox className="footerSm">
                                            <MIconButton aria-controls="notificationMenu" aria-haspopup="true" color="inherit"
                                                onClick={handleClick}>
                                                <MoreVertIcon sx={{ fontSize: 22, color: '#8d8d8d' }} />
                                            </MIconButton>
                                        </MBox>
                                    </NavLink>
                                </MGrid> : ''}
                            </>
                        }
                    </MBox>
                </>
            }
        </MBox >
        // {/* </Zoom> */}
    )
}

export default withRouter(SidebarNew);