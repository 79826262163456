interface IAction {
    type: string,
    payload: any
}

interface IState {
    showAlertMessage: boolean,
    comingSoonModal?: boolean,
    message: string,
    messageType: string,
    graphqlError?: boolean,
    showModal?:boolean
}


const SHOW_ALERT_MESSAGE = "SHOW_ALERT_MESSAGE";
const SHOW_COMING_SOON_MODAL = "SHOW_COMING_SOON_MODAL";
const SHOW_MODAL = "SHOW_MODAL";
const initialState:IState = {
    showAlertMessage: false,
    message: '',
    messageType: 'error',
    comingSoonModal:false,
    graphqlError:false,
    showModal: false
}

export const showAlert = (alertData:IState) => {
    return {
        type: SHOW_ALERT_MESSAGE,
        payload: alertData
    }
}

export const showComingSoonModal = (isShow:boolean) => {
    return {
        type: SHOW_COMING_SOON_MODAL,
        payload: isShow
    }
}

export const showModal = (isShow:boolean) => {
    
    return {
        type: SHOW_MODAL,
        payload: isShow
    }
}

const showAlertMessage = (state = initialState, action:IAction) => {   
    switch (action.type) {
        case SHOW_ALERT_MESSAGE:
            return {
                ...state,
                showAlertMessage: action.payload.showAlertMessage,
                message: action.payload.message,
                messageType: action.payload.messageType,
                graphqlError: action.payload.graphqlError?action.payload.graphqlError:false
            }
        case SHOW_COMING_SOON_MODAL:
            return {
                ...state,
                comingSoonModal: action.payload,
                showAlertMessage: false,
            }
        case SHOW_MODAL: 
            return {
                ...state,
                showModal: action.payload,
                showAlertMessage: false,
            }
        default:
            return state
    }
}

export default showAlertMessage;