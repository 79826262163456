import React, { useEffect, useState } from 'react'
import clsx from 'clsx';
import useStyles from './TeamSelectStyles';
import MenuItem from '@mui/material/MenuItem';
import { MFormControl, MSelect, MInputLabel } from 'src/app/material-ui';
import { MKeyboardArrowDownRoundedIcon } from 'src/app/material-ui/icons';
import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
interface Team {
  id: number,
  name: string
}

const TEAMS_GQL = gql`
query Teams($orgId:ID!) {
  teams(orgId: $orgId) {
    id
    name
  }
}
`;

export function TeamSelect(props: any) {
  const classes = useStyles({});
  const selectedOrgId = useSelector((store: any) => store.orgs.selectedOrgId);
  const [teams, setTeams] = useState([])
  const [getTeams] = useLazyQuery(TEAMS_GQL, {
    variables: { orgId: selectedOrgId && selectedOrgId },
    onCompleted: (data: any) => {
      setTeams(data && data.teams)
    }
  });

  useEffect(() => {
    if (selectedOrgId) {
      getTeams();
    }
  }, [getTeams, selectedOrgId])

  return (
    <div className="teamSelect">

      <MFormControl variant="outlined" className={clsx(classes.inputWidth, classes.teamSelect)}>
        <MInputLabel id="chooseTeam">Choose team</MInputLabel>
        <MSelect
          name="chooseTeam"
          IconComponent={MKeyboardArrowDownRoundedIcon}
          id="chooseTeam"
          color="secondary"
          className={props.redBorder && classes.redBorder}
          disabled={false}
          displayEmpty
          label="Choose team"
        >
          {teams && teams.map((team: Team) => (
            <MenuItem value={team.id} key={team.id}>{team.name}</MenuItem>
          ))}
        </MSelect>
      </MFormControl>
    </div>
  )
}
