import React from 'react'
import { MBox, MSkeleton } from 'src/app/material-ui'
import useStyles from './BilingualStyles'

export default function BilingualSkeleton() {
    const classes = useStyles();
    return (
        <div>
            <MBox mb={9}>

                <MBox fontSize={22} mb={1} mt={3} fontFamily='Poppins Medium'>
                    <MSkeleton variant='text' width={'60%'} height={25} />
                </MBox>


                <MBox fontSize={14} mb={1} fontFamily='Poppins' display='flex' alignItems='center'>
                    <MSkeleton variant='text' width={233} height={25} />
                </MBox>
                <MBox mb={1} mt={3}>
                    <MSkeleton variant='circular' width={25} height={25} />
                </MBox>
              
                <MBox display='flex' maxWidth='45%' className={'gap-10'}>
                    <MBox flex='2 1 0'>
                        <MSkeleton variant='text' width={133} height={25} />
                    </MBox>
                   
                </MBox>
                <MBox fontSize={18} mb={1} fontFamily='Poppins' my={2}>
                    <MSkeleton variant='text' width={133} height={25} />
                </MBox>

                {/*{selectedOptions && JSON.stringify(selectedOptions)}*/}

                {[...Array(10)].map((item: any, index: any) => (
                    <MBox key={index} display='flex' maxWidth='45%' className={'gap-10'} mb={1}>
                        {/*{selectedOptions && JSON.stringify(selectedOptions.find((it:any) => it.code == item.code))}*/}

                        <MBox flex='2 1 0'>
                            <MSkeleton variant='text' width={'100%'} height={25} />
                        </MBox>
                        <MBox display='flex' flex='1 1 0'>
                            <MSkeleton variant='text' width={133} height={25} />
                        </MBox>
                    </MBox>
                )

                )}
                <MBox fontSize={18} my={2} fontFamily='Poppins'>
                    <MSkeleton variant='text' width={133} height={25} />
                </MBox>
                <MBox display='flex' maxWidth='45%' className={'gap-10'} mb={1}>
                    <MBox flex='2 1 0'>
                        Reference
                    </MBox>
                    <MBox display='flex' flex='1 1 0'>
                        <MSkeleton variant='text' width={133} height={25} />
                    </MBox>
                </MBox>
                <MBox display="flex">
                    <MBox className={classes.footerBottom}>

                        <MSkeleton variant='text' width={133} height={25} />
                    </MBox>
                </MBox>

            </MBox>
        </div>
    )
}
