import { styled } from '@mui/material/styles';
import React from 'react';
import Tooltip from "@mui/material/Tooltip";
import { MBox } from '../box/Box';

interface IProps {
    fileType?: any,
}

const LightTooltip = styled(Tooltip)(() => ({
    tooltip: {
        backgroundColor: '#4f4f4f',
        color: '#fff',
        fontSize: 12,
        filter: 'drop-shadow(0px 8px 16px rgba(214,215,226,0.57))',
        border: '1px solid #f4f5f7',
        borderRadius: 5,
        padding: '10px 15px',
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        wordBreak: 'break-word',
    },
    arrow: {
        color: '#4f4f4f',
    },
}));

const Wrapper = styled(MBox)({
    backgroundColor: '#F9F9F9',
    color: '#6B778C',
    fontFamily: 'Poppins',
    fontSize: 13,
    padding: '5px 7px',
    borderRadius: 5,
    fontWeight: 'normal',
    '& span': {
        color: '#3F74E8',
        padding: '0px 3px',
    },
});

export function FileTypes({ fileType }: IProps) {
    const more = 'ott, ots, pptx, odp, odt, ods, idml, mif, strings, json, xlsx, yam, resx, docx, txt, srt, ttx, properties, po';
    return (
        <Wrapper>

            {fileType !== "styleGuide" ?
                <>
                    File types accepted csv, icml, txml, xml, html, dita, yaml, otp,
                    <LightTooltip title={more} placement="bottom" arrow>
                        <span> More</span>
                    </LightTooltip>
                    With a maximum size of <span>100 mb</span>
                    <br />
                    {typeof fileType === "undefined" &&
                        "xlxs format are accepted for bilingual"
                    }
                </>
                :
                <>
                    Please upload a valid file type (.txt, .pdf, .docx, .xlsx).
                </>

            }
        </Wrapper>
    );
}
