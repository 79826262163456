import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

export default function RoleRedirect(props:RouteComponentProps) {
    
    let userRole = useSelector((store: any) => store.auth.currentUser.defaultRole);

    const handelRedirect = (roleProp?:string) => {
        if(roleProp) {
            userRole = roleProp;
        }

        if (userRole === 'ROLE_CLIENT') {
            props.history.push('/projects')
        } else if (userRole === 'ROLE_VENDOR') {
            props.history.push('/vendor/open-jobs')
        } else if (userRole === 'ROLE_TRANSLATOR') {
            props.history.push('/linguist/open-jobs')
        }else if (userRole === 'ROLE_FINANCE_USER') {
            props.history.push('/manage-payment')
        }else{
            props.history.push('/')
        }
    }

    return {
        handelRedirect:handelRedirect
    }
}