import React, { useEffect, useState } from "react";
import { gql } from "apollo-boost";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/react-hooks";
import { Divider } from "@mui/material";
import Fade from "@mui/material/Fade";
import MenuItem from "@mui/material/MenuItem";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import HelpIcon from "@mui/icons-material/Help";
import NotificationsIcon from "@mui/icons-material/Notifications";
import clsx from "clsx";
import { NavLink, useLocation, withRouter } from "react-router-dom";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

import { timeAgo, timeZone, useRoleRedirect } from "src/app/hooks";
import { GET_NOTIFICATION } from "src/app/store/notification/NotificationActionType";
import {
  MAvatar,
  MBadge,
  MBox,
  MButton,
  MGrid,
  MIconButton,
  MLink,
  MMenu,
  MMenuItem,
  MTypography,
} from "src/app/material-ui";
import { MChinessSimplified, MEnglish } from "src/app/material-ui/icons/index";
import {
  setSidebarType,
  setUserRole,
  showAlert,
  showModal,
  showComingSoonModal,
} from "src/app/store";
import useStyles from "./HeaderStyles";
import { GET_RELEASE_NOTES_FETCH } from "src/app/store/releaseNotes/ReleaseNotesActionType";
import { setNotificationData } from "../../../store";
import CheckPermissions from "src/app/hooks/permissions/CheckPermissions";
import { setChatNotification } from "src/app/store/auth/AuthActions";


const READ_NOTIFICATION = gql`
  mutation readNotification($ids: [Int], $type: String) {
    readNotification(ids: $ids, type: $type)
  }
`;
const READ_CHAT_NOTIFICATION = gql`
  mutation readChatNotification($id: Int) {
    readChatNotification(id: $id,)
  }
`;

function Header(props: any) {
  const classes = useStyles({});

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const [setJobMenu] = useState<null | HTMLElement>(null);
  const [organizations, setOrganization]: any = useState();
  const [isVendorMenu, setIsVendorMenu]: any = useState(false);
  const [isChatNotification, setIsChatNotification]: any = useState(false);
  const [isIconNotification, setIsIconNotification]: any = useState(false);
  const [isOrgFilesMenu, setIsOrgFilesMenu]: any = useState(false);
  const location = useLocation();
  const { handelRedirect } = useRoleRedirect(props);
  const [notification, setNotification] = React.useState<null | HTMLElement>(null);
  const [releaseNotes, setReleaseNotes] = React.useState<null | HTMLElement>(null);
  const [releaseNotesData, setReleaseNotesData]: any = useState([])
  const [menuItems, setMenuItems]: any = useState([]);
  const [anchorTopMenu, setAnchorTopMenu]: any = React.useState(false);
  // const [notificationCounter] = useState(true);
  const [notificationsData, setNotificationsData]: any = useState([]);
  const [notificationIds, setNotificationIds]: any = useState([]);
  const urlType = window.location.href.split('/');
  // const suppliersList = CheckPermission('ROLE_MY_SUPPLIERS_LIST');
  // const findNewsuppliersList = CheckPermission('ROLE_FIND_NEW_SUPPLIERS_LIST');
  // const rateRequestList = CheckPermission('ROLE_RATE_REQUEST_LIST');
  // const ratesList = CheckPermission('ROLE_RATES_LIST');
  // const projectSettingView = CheckPermission('ROLE_PROJ_SETTINGS_VIEW');
  const [suppliersList, setSuppliersList] = useState(false);
  const [customerInvoiceList, setCustomerInvoiceList] = useState(false);
  const [customerPoList, setCustomerPoList] = useState(false);
  const [customerFileDriveList, setCustomerFileDriveList] = useState(false);
  const [customerManagerList, setCustomerManagerList] = useState(false);
  const [findNewsuppliersList, setFindNewsuppliersList] = useState(false);
  const [rateRequestList, setRateRequestList] = useState(false);
  const [ratesList, setRatesList] = useState(false);
  const [projectSettingView, setProjectSettingView] = useState(false);
  const [projectTasksList, setProjectTasksList] = useState(false);
  const [peopleList, setPeopleList] = useState(false);
  const [jobOpenList, setOpenList]: any = useState(false);
  const [jobMyOpenList, setJobMyOpenList]: any = useState(false);
  const [projectSettingsAllowed, setProjectSettingsAllowed]: any = useState(false);
  const [lastParamId, setLastParamId]: any = useState(null);
  const [marsQaList, setMarsQaList]: any = useState(false);
  const [marsQaCreate, setMarsQaCreate]: any = useState(false);
  const [marsQaView, setMarsQaView]: any = useState(false);
  const [integrationsList, setIntegrationsList]: any = useState(false);
  //  const [integrationsCreate, setIntegrationsCreate]: any = useState(false);
  //  const [integrationsDelete, setIntegrationsDelete]: any = useState(false);


  const orgs = useSelector((store: any) => store.orgs.organizations);
  const currentUser = useSelector((store: any) => store.auth.currentUser);
  const isAdmin = useSelector((store: any) => store.orgs.isAdmin);
  const isProjectAdmin = useSelector((store: any) => store.orgs.isProjectAdmin);
  const isOwner = useSelector((store: any) => store.orgs.isOwner);
  const appUserRole = useSelector((store: any) => store.userRole.userRole);
  const projectId = useSelector((store: any) => store.projects.selectedProjectId);
  const notifications = useSelector((store: any) => store.notification.notificationList);
  const selectedOrgId = useSelector((store: any) => store.orgs.selectedOrgId);
  const allOrganizations = useSelector((store: any) => store.orgs.organizations);
  const clientType = localStorage.getItem('clientType')

  useEffect(() => {
    setCustomerInvoiceList(CheckPermissions('ROLE_CUSTOMER_INVOICE_LIST', selectedOrgId, allOrganizations));
    setCustomerPoList(CheckPermissions('ROLE_CUSTOMER_PO_LIST', selectedOrgId, allOrganizations));
    setCustomerFileDriveList(CheckPermissions('ROLE_CUSTOMER_FILE_DRIVE_LIST', selectedOrgId, allOrganizations));
    setCustomerManagerList(CheckPermissions('ROLE_CUSTOMER_MANAGER_LIST', selectedOrgId, allOrganizations));
    // console.log('ssd', CheckPermissions('ROLE_CUSTOMER_FILE_DRIVE_LIST', selectedOrgId, allOrganizations));
    setSuppliersList(CheckPermissions('ROLE_MY_SUPPLIERS_LIST', selectedOrgId, allOrganizations));
    setFindNewsuppliersList(CheckPermissions('ROLE_FIND_NEW_SUPPLIERS_LIST', selectedOrgId, allOrganizations));
    setRateRequestList(CheckPermissions('ROLE_RATE_REQUEST_LIST', selectedOrgId, allOrganizations));
    setRatesList(CheckPermissions('ROLE_RATES_LIST', selectedOrgId, allOrganizations));
    setProjectSettingView(CheckPermissions('ROLE_PROJ_SETTINGS_VIEW', selectedOrgId, allOrganizations));
    setProjectTasksList(CheckPermissions('ROLE_TASKS_LIST', selectedOrgId, allOrganizations));
    setPeopleList(CheckPermissions('ROLE_PEOPLE_LIST', selectedOrgId, allOrganizations));
    setOpenList(CheckPermissions('ROLE_OPEN_JOBS_LIST', selectedOrgId, allOrganizations));
    setJobMyOpenList(CheckPermissions('ROLE_MY_JOBS_LIST', selectedOrgId, allOrganizations));
    setProjectSettingsAllowed(CheckPermissions('ROLE_PROJ_SETTINGS_VIEW', selectedOrgId, allOrganizations));
    setMarsQaList(CheckPermissions('ROLE_MARS_QA_REPORT_LIST', selectedOrgId, organizations));
    setMarsQaCreate(CheckPermissions('ROLE_MARS_QA_REPORT_CREATE', selectedOrgId, organizations));
    setMarsQaView(CheckPermissions('ROLE_MARS_QA_REPORT_VIEW', selectedOrgId, organizations));
    setIntegrationsList(CheckPermissions('ROLE_INTEGRATIONS_LIST', selectedOrgId, organizations));
    // setIntegrationsCreate(CheckPermissions('ROLE_INTEGRATIONS_CREATE', selectedOrgId, organizations));
    // setIntegrationsDelete(CheckPermissions('ROLE_INTEGRATIONS_DELETE', selectedOrgId, organizations));

  }, [selectedOrgId, allOrganizations]);

  const loading = useSelector((store: any) => store.notification.notificationLoading);
  const releaseNotesList = useSelector((store: any) => store.releaseNotesReducer.releaseNotesList);

  const dispatch = useDispatch();

  const menu = [
    {
      'role': 'ROLE_CLIENT', 'menu': [
        { title: <FormattedMessage id="subscriptions" />, url: '/payments' },
        { title: <FormattedMessage id="account.settings" />, url: '/client/account/settings', tag: 'Organizatization settings' },
        { title: <FormattedMessage id="invoices" />, url: '/invoices/payments' },

      ]
    },
    {
      'role': 'ROLE_VENDOR', 'menu': [
        { title: <FormattedMessage id="company.profile" />, url: '/vendor/profile/' + currentUser.slug },
        { title: <FormattedMessage id="subscriptions" />, url: '/payments/tab' },
        { title: <FormattedMessage id="account.settings" />, url: '/vendor/account/settings' },
        { title: <FormattedMessage id="invoices" />, url: '/invoices/payments' },

      ]
    },
    {
      'role': 'ROLE_TRANSLATOR', 'menu': [
        { title: <FormattedMessage id="my.profile" />, url: '/linguist/profile/' + currentUser.slug },
        { title: <FormattedMessage id="account.settings" />, 'url': '/account/settings' },
        { title: <FormattedMessage id="payments" />, url: '/payments' },

      ]
    }
  ];

  useEffect(() => {
    setCustomerInvoiceList(CheckPermissions('ROLE_CUSTOMER_INVOICE_LIST', selectedOrgId, allOrganizations));
    setCustomerPoList(CheckPermissions('ROLE_CUSTOMER_PO_LIST', selectedOrgId, allOrganizations));
    setCustomerFileDriveList(CheckPermissions('ROLE_CUSTOMER_FILEDRIVE_LIST', selectedOrgId, allOrganizations));
    setCustomerManagerList(CheckPermissions('ROLE_CUSTOMER_MANAGER_LIST', selectedOrgId, allOrganizations));
    setSuppliersList(CheckPermissions('ROLE_MY_SUPPLIERS_LIST', selectedOrgId, allOrganizations));
    setFindNewsuppliersList(CheckPermissions('ROLE_FIND_NEW_SUPPLIERS_LIST', selectedOrgId, allOrganizations));
    setRateRequestList(CheckPermissions('ROLE_RATE_REQUEST_LIST', selectedOrgId, allOrganizations));
    setRatesList(CheckPermissions('ROLE_RATES_LIST', selectedOrgId, allOrganizations));
    setProjectSettingView(CheckPermissions('ROLE_PROJ_SETTINGS_VIEW', selectedOrgId, allOrganizations));
    setProjectTasksList(CheckPermissions('ROLE_TASKS_LIST', selectedOrgId, allOrganizations));
    setPeopleList(CheckPermissions('ROLE_PEOPLE_LIST', selectedOrgId, allOrganizations));
    setOpenList(CheckPermissions('ROLE_OPEN_JOBS_LIST', selectedOrgId, allOrganizations));
    setJobMyOpenList(CheckPermissions('ROLE_MY_JOBS_LIST', selectedOrgId, allOrganizations));
    setProjectSettingsAllowed(CheckPermissions('ROLE_PROJ_SETTINGS_VIEW', selectedOrgId, allOrganizations));
    setMarsQaList(CheckPermissions('ROLE_MARS_QA_REPORT_LIST', selectedOrgId, organizations));
    setMarsQaCreate(CheckPermissions('ROLE_MARS_QA_REPORT_CREATE', selectedOrgId, organizations));
    setMarsQaView(CheckPermissions('ROLE_MARS_QA_REPORT_VIEW', selectedOrgId, organizations));
    setIntegrationsList(CheckPermissions('ROLE_INTEGRATIONS_LIST', selectedOrgId, organizations));
    // setIntegrationsCreate(CheckPermissions('ROLE_INTEGRATIONS_CREATE', selectedOrgId, organizations));
    // setIntegrationsDelete(CheckPermissions('ROLE_INTEGRATIONS_DELETE', selectedOrgId, organizations));

  }, [selectedOrgId, allOrganizations]);

  useEffect(() => {
    const path = urlType.filter((entry: any) => entry !== "");
    setLastParamId(path[path.length - 1]);
  }, [urlType]);
  useEffect(() => {
    if (currentUser?.id != 0) {

      subscribeSendChatEmailEvent();

      dispatch({ type: GET_RELEASE_NOTES_FETCH })
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser.id !== 0 && appUserRole) {
      const userMenu: any = menu.find(element => element.role === appUserRole)?.menu;
      // Check if the user role is 'ROLE_CLIENT' and the page is currentUser.isPrivate
      if (appUserRole === 'ROLE_CLIENT' && currentUser.isPrivate) {
        // Filter out the 'Payments' menu item
        const filteredMenu = userMenu && userMenu.filter((item: any) => item.tag !== 'Payments');
        setMenuItems(filteredMenu);
      } else {
        if (!isOwner) {
          // const filteredMenu = userMenu && userMenu.filter((item: any) => item.tag === 'My Profile');
          setMenuItems(userMenu);
        } else {
          setMenuItems(userMenu);
        }
      }
    }
  }, [currentUser, appUserRole]);

  useEffect(() => {
    if (orgs && orgs.length > 0) {
      setOrganization(orgs);
    }
  }, [orgs]);

  useEffect(() => {
    if (
      window.location.pathname == "/vendors" ||
      window.location.pathname == "/find-vendor"
    ) {
      setIsVendorMenu(true);
    } else {
      setIsVendorMenu(false);
    }
    if (window.location.pathname == "/files") {
      setIsOrgFilesMenu(true);
    } else {
      setIsOrgFilesMenu(false);
    }
    if (window.location.pathname.split('/')[1] != 'projects') {
      localStorage.setItem('projectOwner', 'true');
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (currentUser && currentUser.id != 0) {
      subscribeUserNotificationsEvent();
      dispatch({ type: GET_NOTIFICATION, limit: 1, page: 1, })

    }
  }, [currentUser]);

  useEffect(() => {
    setNotificationsData(notifications)
    const ids: any = [];
    if (notifications) {
      notifications.map((item: any) => {
        if (item.id != "") {
          ids.push(item.id);
        }
      });
    }
    setNotificationIds(ids)
  }, [notifications]);

  useEffect(() => {
    setReleaseNotesData(releaseNotesList && releaseNotesList.items)
  }, [releaseNotesList]);

  // useEffect(() => {
  //     if (currentUser.id != 0 && appUserRole) {
  //         setMenuItems(menu.find(element => element.role == appUserRole)?.menu);
  //     }
  // }, [currentUser]);
  // const searchCustomerValue = localStorage.getItem('searchCustomer');

  const logout = () => {
    const token = localStorage.getItem("access_token");
    localStorage.clear();
    window.location.href = "" + process.env.REACT_APP_SSO_LOGOUT_URL + token;
    dispatch(
      showAlert({
        message: "You are signing out.",
        messageType: "info",
        showAlertMessage: true,
      })
    );
  };
  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorTopMenu(event.currentTarget);
  // };

  // const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  //   <Tooltip {...props} classes={{ popper: className }} />
  // ))(({ theme }) => ({
  //   [`& .${tooltipClasses.tooltip}`]: {
  //     backgroundColor: '#4f4f4f',
  //     color: "#fff",
  //     fontSize: 12,
  //     filter: "drop-shadow(0px 8px 16px rgba(214,215,226,0.57))",
  //     border: "1px solid transparent",
  //     borderRadius: 0,
  //     padding: "10px 15px",
  //     maxWidth: 230,
  //   },
  //   arrow: {
  //     color: theme.palette.common.white,
  //   },
  // }));

  const LightTooltip = styled(
    React.forwardRef(function LightTooltip({ className, ...props }: TooltipProps, ref) {
      return (
        <Tooltip
          {...props}
          classes={{ ...props?.classes, popper: className || '' }}  // Ensure className is passed safely
          ref={ref}
        />
      );
    })
  )(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "#6b778c",
      fontSize: 12,
      filter: "drop-shadow(0px 8px 16px rgba(214,215,226,0.57))",
      border: "1px solid #f4f5f7",
      borderRadius: 0,
      padding: "20px 15px",
      maxWidth: 230,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
    },
  }));

  const redirectToPage = (url: string) => {
    props.history.push(url);
    handleClose();
  };

  function subscribeSendChatEmailEvent() {
    // close old connection
    // if (oldFileEvent) {
    //     oldFileEvent.close();
    // }
    const url: any = new URL(process.env.REACT_APP_MERCURE_URL + '/.well-known/mercure');
    url.searchParams.append('topic', 'request/chat-email/' + currentUser.id);
    const eventSource = new EventSource(url);
    // The callback will be called every time an update is published
    eventSource.onmessage = ((e: any) => {
      e?.preventDefault();
      setIsChatNotification(true)
    });
  }

  const getAllNotification = () => {
    if (notifications && typeof (notifications) == 'function') {
      notifications()
    }
  }

  const [readNotification] = useMutation(READ_NOTIFICATION, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      if (notifications && typeof (notifications) == 'function') {
        notifications()
      }
    }
  });

  const [readChatNotification] = useMutation(READ_CHAT_NOTIFICATION, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      dispatch(setChatNotification());
      setIsChatNotification(false)
    }
  });

  const handleAllNotifications = () => {
    setNotification(null);
    props.history.push('/notifications')
    dispatch({ type: GET_NOTIFICATION, limit: 0 })
  };

  const handleAllReleaseNotes = () => {
    setReleaseNotes(null);
    props.history.push('/releaseNotes')
  };

  const showProfileHandler = (e: any) => {
    if (currentUser.slug === null) {
      e.preventDefault();
      dispatch(showModal(true));
    }
  }

  // const [getAllNotification, { loading }] = useLazyQuery(
  //     Notification_List, {
  //     fetchPolicy: "no-cache",
  //     variables: {
  //         limit: 1,
  //         page: 1,
  //     },
  //     onCompleted: (data: any) => {
  //         setNotificationsData(data && data.getAllNotification.notifications)
  //         const ids: any = [];
  //         data && data.getAllNotification.notifications.map((item: any) => {
  //             if (item.id != "") {
  //                 ids.push(item.id);
  //             }
  //             setNotificationIds(ids)
  //         });
  //     }
  // }
  // );

  const switchRole = (role: string) => {
    setAnchorEl(null);
    if (role !== "") {
      dispatch(setUserRole(role));
      dispatch(setSidebarType(role));
      // localStorage.setItem('user_role',role);
      handelRedirect(role);
    }
  };

  // const handleClick = (event: MouseEvent<HTMLElement>) => {
  //     setAnchorEl(event.currentTarget);
  // };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotification = (event: React.MouseEvent<HTMLButtonElement>) => {
    getAllNotification()
    setNotification(event.currentTarget)
    setIsChatNotification(false)
  }

  const handleReleaseNotes = (event: React.MouseEvent<HTMLButtonElement>) => {
    setReleaseNotes(event.currentTarget)
  }

  const handleCloseNotification = () => {
    setNotification(null);

  };

  const handleCloseReleaseNotes = () => {
    setReleaseNotes(null);
  };

  // const noPageModalOpen = (e: any) => {
  //     e.preventDefault();
  //     dispatch(showComingSoonModal(true));
  // };
  // const openJobMenu = (event: MouseEvent<HTMLElement>) => {
  //     setJobMenu(event.currentTarget);
  // };
  // const closeJobMenu = () => {
  //     setJobMenu(null);
  // };
  // const jobMenuLink = (link: string) => {
  //     props.history.push(link);
  //     closeJobMenu();
  // };

  function subscribeUserNotificationsEvent() {
    // close old connection
    // if (oldFileEvent) {
    //     oldFileEvent.close();
    // }
    const url: any = new URL(process.env.REACT_APP_MERCURE_URL + '/.well-known/mercure');
    url.searchParams.append('topic', 'user/notifications/' + currentUser.id);
    const eventSource = new EventSource(url);
    // The callback will be called every time an update is published
    eventSource.onmessage = ((e: any) => {
      // alert('message received');
      // console.log('emer',e);
      e.preventDefault();
      setIsIconNotification(true);

      if (currentUser && currentUser.id != 0) {
        dispatch({ type: GET_NOTIFICATION, limit: 1, page: 1 });
      }
    });
  }

  return (
    // Menu and right icons
    <MGrid container alignItems="center">
      <MGrid item sm={10}>
        <MGrid container alignItems="center">
          <MMenu id="role-switch"
            anchorEl={anchorTopMenu}
            keepMounted
            open={Boolean(anchorTopMenu)}
            onClose={() => setAnchorTopMenu(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          // className={classes.menuList}
          >
            <MMenuItem onClick={() => setAnchorTopMenu(false)} style={{ "paddingLeft": "7px" }}>
              <MLink href="/settings" underline="none" width={150} height={18} style={{ padding: 0, display: 'flex', margin: 0 }}
                className="actionButton">
                <MTypography style={{ "marginLeft": "3px" }}>
                  <FormattedMessage id="org.settings" />
                </MTypography>
              </MLink>
            </MMenuItem>
            <MMenuItem onClick={() => setAnchorTopMenu(false)} style={{ "paddingLeft": "7px" }}>
              <MLink href="/my-account" underline="none" width={150} height={18} style={{ padding: 0, display: 'flex', margin: 0 }}
                className="actionButton">
                <MTypography style={{ "marginLeft": "3px" }}>
                  <FormattedMessage id="personal.settings" />
                </MTypography>
              </MLink>
            </MMenuItem>
          </MMenu>

          {
            (appUserRole === "ROLE_CUSTOMER" || appUserRole === "ROLE_CLIENT" || appUserRole === "ROLE_VENDOR" || appUserRole === "ROLE_LINGUIST") &&
            ((location.pathname === "/my-account") || (location.pathname === "/settings")) && (
              <>
                {menuItems && menuItems?.map((item: any, idx: number) => (

                  <li key={idx}>
                    <NavLink
                      exact
                      className={classes.menuLink}
                      to={item.url}
                    >
                      {item.title}
                      {/*<FormattedMessage id="transactions" />*/}
                    </NavLink>
                  </li>
                ))}
              </>
            )
          }

          {organizations && organizations.length > 0 && (
            <MGrid item alignItems="center">
              {appUserRole === "ROLE_CLIENT" && localStorage.getItem('projectOwner') == 'false'
                ?
                <MBox
                  className={classes.mainNav}
                  component="ul"
                  display="flex"
                  p={0}
                >

                  <>
                    {projectId && projectId != '0' && (
                      <>
                        <li>
                          <NavLink
                            exact
                            className={classes.menuLink}
                            to={"/projects/jobs/" + projectId}
                          >
                            <FormattedMessage id="jobs" />
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            exact
                            className={classes.menuLink}
                            to={"/projects/purchase-order/" + projectId}
                          >
                            <FormattedMessage id="purchase.orders" />
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            exact
                            className={classes.menuLink}
                            to={"/projects/invoice/" + projectId}
                          >
                            <FormattedMessage id="invoices" />
                          </NavLink>
                        </li>
                      </>
                    )}
                  </>
                  {isOrgFilesMenu == true && (
                    <>
                      <li>
                        <NavLink
                          exact
                          className={classes.menuLink}
                          to={"/files"}
                        >
                          <FormattedMessage id="source.files" />
                        </NavLink>
                      </li>
                    </>
                  )}
                </MBox>
                :
                ''
              }

              {/*{(isAdmin || isProjectAdmin || isOwner)?'t':'f'}*/}
              {/*{projectId} = {appUserRole} = {localStorage.getItem('projectOwner')}*/}
              {(appUserRole === "ROLE_CLIENT" && currentUser.isPrivate && localStorage.getItem('projectOwner') == 'true') || (appUserRole === "ROLE_CLIENT" && !currentUser.isPrivate) || appUserRole === "ROLE_VENDOR"
                ?
                (
                  <MBox
                    className={classes.mainNav}
                    component="ul"
                    display="flex"
                    p={0}
                  >
                    {(isAdmin || isProjectAdmin || isOwner || (appUserRole === "ROLE_CLIENT" && currentUser.isPrivate)) && (
                      <>
                        {projectId && projectId != '0' && (
                          <>
                            {/*<li><NavLink exact className={classes.menuLink}*/}
                            {/*to={"/projects/overview/" + projectId}><FormattedMessage id="Overview" /></NavLink></li>*/}
                            {/*<li>*/}
                            {/*<NavLink*/}
                            {/*exact*/}
                            {/*className={classes.menuLink}*/}
                            {/*to={"/projects/file/" + projectId}*/}
                            {/*>*/}
                            {/*<FormattedMessage id="files" />*/}
                            {/*</NavLink>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*<NavLink*/}
                            {/*exact*/}
                            {/*className={classes.menuLink}*/}
                            {/*to={"/projects/tasks/" + projectId}*/}
                            {/*>*/}
                            {/*<FormattedMessage id="tasks" />*/}
                            {/*</NavLink>*/}
                            {/*</li>*/}

                            {/*{localStorage.getItem('projectOwner') == 'true' &&*/}
                            <>
                              {localStorage.getItem("selectedType") == "task"
                                ?
                                (
                                  <>
                                    <li>
                                      <NavLink
                                        exact
                                        className={classes.menuLink}
                                        to={"/my-jobs/files/" + projectId}
                                      >
                                        <FormattedMessage id="files" />
                                      </NavLink>
                                    </li>
                                    {projectTasksList &&
                                      <li>
                                        <NavLink
                                          exact
                                          className={classes.menuLink}
                                          to={"/my-jobs/files/tasks/" + projectId}
                                        >
                                          <FormattedMessage id="vendor.job" />
                                        </NavLink>
                                      </li>
                                    }

                                  </>
                                )
                                :
                                (
                                  <>
                                    <li>
                                      <NavLink
                                        exact
                                        className={classes.menuLink}
                                        to={"/projects/file/" + projectId}
                                      >
                                        <FormattedMessage id="files" />
                                      </NavLink>
                                    </li>
                                    {projectTasksList &&
                                      <li>
                                        <NavLink
                                          exact
                                          className={classes.menuLink}
                                          to={"/projects/tasks/" + projectId}
                                        >
                                          <FormattedMessage id="vendor.job" />
                                        </NavLink>
                                      </li>
                                    }
                                  </>
                                )}
                            </>
                            {/*}*/}
                            {/*process.env.REACT_APP_SHOW_SPRINT_16_LINKS === 'true' &&*/}
                            {(marsQaList || marsQaCreate || marsQaView) && urlType[3] !== 'my-jobs' &&
                              <li>
                                <NavLink
                                  exact
                                  className={classes.menuLink}
                                  to={"/projects/mars-qa/" + projectId}
                                >
                                  <FormattedMessage id="mars.qa" />
                                </NavLink>
                              </li>
                            }
                            {projectSettingsAllowed &&
                              <li>
                                <NavLink
                                  exact
                                  className={classes.menuLink}
                                  to={"/projects/settings/" + projectId}
                                >
                                  <FormattedMessage id="settings" />
                                </NavLink>
                              </li>
                            }
                            {integrationsList &&
                              <li>
                                <NavLink
                                  exact
                                  className={classes.menuLink}
                                  to={"/projects/integrations/" + projectId}
                                >
                                  <FormattedMessage id="integration" />
                                </NavLink>
                              </li>}
                            {/* } */}
                          </>

                        )}

                        {/* {location.pathname === "/projects/file/" + projectId && */}

                        {/* } */}

                        {!projectId || projectId == '0' && (menuItems && menuItems.find((element: any) => (element.url == location.pathname))) &&
                          <>
                            {menuItems && menuItems.map((item: any, idx: number) => (

                              <li key={idx}>
                                <NavLink
                                  exact
                                  className={classes.menuLink}
                                  to={item.url}
                                >
                                  {item.title}
                                  {/*<FormattedMessage id="transactions" />*/}
                                </NavLink>
                              </li>
                            ))}
                          </>
                        }

                        {/*{(location.pathname === "/client/manageCreditCards" || location.pathname === "/transactions" || location.pathname === "/subscriptions" || location.pathname === "/withdraw-history" || location.pathname === "/invoices")*/}

                        {/*&&*/}
                        {/*(*/}
                        {/*<>*/}
                        {/*<li>*/}
                        {/*<NavLink*/}
                        {/*exact*/}
                        {/*className={classes.menuLink}*/}
                        {/*to={"/transactions"}*/}
                        {/*>*/}
                        {/*<FormattedMessage id="transactions" />*/}
                        {/*</NavLink>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*<NavLink*/}
                        {/*exact*/}
                        {/*className={classes.menuLink}*/}
                        {/*to={appUserRole === "ROLE_CLIENT" ? "/client/manageCreditCards" : appUserRole === "ROLE_VENDOR" ? "/vendor/manageCreditCards" : "/linguist/manageCreditCards"}*/}
                        {/*>*/}
                        {/*{" "}*/}
                        {/*<FormattedMessage id="manage.credit.cards" />*/}
                        {/*</NavLink>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*<NavLink*/}
                        {/*exact*/}
                        {/*className={classes.menuLink}*/}
                        {/*to={"/subscriptions"}*/}
                        {/*>*/}
                        {/*<FormattedMessage id="Subscriptions" />*/}
                        {/*</NavLink>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*<NavLink*/}
                        {/*exact*/}
                        {/*className={classes.menuLink}*/}
                        {/*to={"/invoices"}*/}
                        {/*>*/}
                        {/*<FormattedMessage id="invoices" />*/}
                        {/*</NavLink>*/}
                        {/*</li>*/}
                        {/*</>*/}
                        {/*)*/}
                        {/*}*/}

                        {peopleList &&
                          <>
                            {(location.pathname === "/manage-admins" || location.pathname === "/manage-admins-invite" || location.pathname === "/manage-group")
                              &&
                              (
                                <>
                                  <li>
                                    <NavLink
                                      exact
                                      className={classes.menuLink}
                                      to="/manage-admins"
                                    >
                                      <FormattedMessage id="manage.admin's" />
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink
                                      exact
                                      className={classes.menuLink}
                                      to="/manage-admins-invite"
                                    >
                                      <FormattedMessage id="invited.admin's" />
                                    </NavLink>
                                  </li>
                                  <li>
                                    <NavLink
                                      exact
                                      className={classes.menuLink}
                                      to="/manage-group"
                                    >
                                      <FormattedMessage id="manage.group" />
                                    </NavLink>
                                  </li>
                                </>
                              )
                            }
                          </>
                        }

                        {isVendorMenu == true && (
                          <>
                            {suppliersList && <li>
                              <NavLink
                                exact
                                className={classes.menuLink}
                                to={"/vendors"}
                              >
                                <FormattedMessage id="my.lsp.linguist" />
                              </NavLink>
                            </li>}
                            {appUserRole !== 'ROLE_CLIENT' && appUserRole !== "ROLE_VENDOR" && !currentUser.isPrivate && findNewsuppliersList &&
                              <li>
                                <NavLink
                                  exact
                                  className={classes.menuLink}
                                  to={"/find-vendor"}
                                >
                                  <FormattedMessage id="find.new.suppliers" />
                                </NavLink>
                              </li>
                            }

                          </>
                        )}

                        {(location.pathname === "/vendor/linguist" || location.pathname === "/find-linguist")
                          &&
                          (
                            <>
                              <li>
                                <NavLink
                                  exact
                                  className={classes.menuLink}
                                  to={"/vendor/linguist"}
                                >
                                  <FormattedMessage id="my.linguist" />
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  exact
                                  className={classes.menuLink}
                                  to={"/find-linguist"}
                                >
                                  <FormattedMessage id="find.new.linguist" />
                                </NavLink>
                              </li>
                            </>
                          )
                        }

                        {isOrgFilesMenu == true && (
                          <>
                            <li>
                              <NavLink
                                exact
                                className={classes.menuLink}
                                to={"/files"}
                              >
                                <FormattedMessage id="source.files" />
                              </NavLink>
                            </li>
                          </>
                        )}

                        {(!projectId || projectId == '0') &&
                          (location.pathname === "/vendor/rate/request" ||
                            location.pathname === "/vendor/client/rates" ||
                            location.pathname === "/vendor/rates/client" ||
                            location.pathname === "/vendor/rates/add-rates" ||
                            location.pathname === "/vendor/rates") && (
                            <>
                              {/*----------old nav link---------------- */}
                              {/* <li><NavLink exact className={classes.menuLink}
                                                        to="/vendor/general/rates"> <FormattedMessage id="general" /></NavLink></li> */}
                              {/* <li><NavLink exact className={classes.menuLink}
                                                        to="/vendor/client/rates"> <FormattedMessage id="client" /></NavLink></li></>} */}

                              {/*----------------new nav link--------------- */}
                              {rateRequestList && <li>
                                <NavLink
                                  exact
                                  className={classes.menuLink}
                                  to="/vendor/rate/request"
                                >
                                  <FormattedMessage id="rate.request" />
                                </NavLink>
                              </li>}
                              {ratesList && <li>
                                <NavLink
                                  exact
                                  className={classes.menuLink}
                                  to="/vendor/rates"
                                >
                                  <FormattedMessage id="add.rate" />
                                </NavLink>
                              </li>}
                            </>
                          )
                        }

                        {(appUserRole === "ROLE_VENDOR") &&
                          (window.location.pathname === "/vendor/open-jobs" ||
                            window.location.pathname === "/vendor/my-jobs") && (
                            <>
                              {jobOpenList &&
                                <li>
                                  <NavLink
                                    exact
                                    className={classes.menuLink}
                                    to="/vendor/open-jobs"
                                  >
                                    <FormattedMessage id="open.jobs" />
                                  </NavLink>
                                </li>
                              }
                              {(appUserRole !== "ROLE_VENDOR") && jobMyOpenList &&
                                <li>
                                  <NavLink
                                    exact
                                    className={classes.menuLink}
                                    to="/vendor/my-jobs"
                                  >
                                    {" "}
                                    <FormattedMessage id="assigned.jobs" />
                                  </NavLink>
                                </li>
                              }
                            </>
                          )}

                        {(appUserRole === "ROLE_VENDOR") &&
                          (window.location.pathname === "/projects" ||
                            window.location.pathname === "/vendor/projects/my-jobs") && (
                            <>
                              {jobOpenList &&
                                <li>
                                  <NavLink
                                    exact
                                    className={classes.menuLink}
                                    to="/projects"
                                  >

                                    <FormattedMessage id="all.projects" />
                                  </NavLink>
                                </li>
                              }
                              {jobMyOpenList &&
                                <li>
                                  <NavLink
                                    exact
                                    className={classes.menuLink}
                                    to="/vendor/projects/my-jobs"
                                  >
                                    {" "}
                                    <FormattedMessage id="assigned.jobs" />
                                  </NavLink>
                                </li>
                              }
                            </>
                          )}
                        {(appUserRole === "ROLE_VENDOR") &&
                          (window.location.pathname === "/customers/details/" + lastParamId || window.location.pathname === "/customers/invoice/" + lastParamId || window.location.pathname === "/customers/drive/" + lastParamId || window.location.pathname === "/customers/purchase-order/" + lastParamId || window.location.pathname === "/customers/select-manager/" + lastParamId || window.location.pathname === "/customers/price/contracts/" + lastParamId) && (
                            <>
                              {(clientType !== "LSP Client") && 
                              <li>
                                <NavLink
                                  exact
                                  className={classes.menuLink}
                                  to={"/customers/details/" + lastParamId}
                                >

                                  <FormattedMessage id="overview" />
                                </NavLink>
                              </li>
                              }
                              {customerInvoiceList && (clientType == "Customers") && 
                              <li>
                                <NavLink
                                  exact
                                  className={classes.menuLink}
                                  to={"/customers/invoice/" + lastParamId}
                                >
                                  <FormattedMessage id="invoices" />
                                </NavLink>
                              </li>
                              }

                              {customerPoList && (clientType == "Customers") && 
                              <li>
                                <NavLink
                                  exact
                                  className={classes.menuLink}
                                  to={"/customers/purchase-order/" + lastParamId}
                                >
                                  <FormattedMessage id="p.o" />
                                </NavLink>
                              </li>
                              }

                              {customerFileDriveList && (clientType == "Customers") &&
                                <li>
                                  <NavLink
                                    exact
                                    className={classes.menuLink}
                                    to={"/customers/drive/" + lastParamId}
                                  >
                                    <FormattedMessage id="file.drive" />
                                  </NavLink>
                                </li>
                              }
                              {(customerManagerList || isOwner) &&
                                <li>
                                  <NavLink
                                    exact
                                    className={classes.menuLink}
                                    to={"/customers/select-manager/" + lastParamId}
                                  >
                                    <FormattedMessage id="select.manager" />
                                  </NavLink>
                                </li>
                              }
                              {ratesList &&
                                <li>
                                  <NavLink
                                    exact
                                    className={classes.menuLink}
                                    to={"/customers/price/contracts/" + lastParamId}
                                  >
                                    <FormattedMessage id="price.contracts" />
                                  </NavLink>
                                </li>
                                }
                            </>
                          )}
                      </>
                    )}
                  </MBox>
                )
                :
                (
                  appUserRole === "ROLE_VENDOR" && (
                    <MBox
                      className={classes.mainNav}
                      component="ul"
                      display="flex"
                      p={0}
                    >
                      {isVendorMenu == true && (
                        <>
                          {suppliersList && <li>
                            <NavLink
                              exact
                              className={classes.menuLink}
                              to={"/vendors"}
                            >
                              <FormattedMessage id="my.lsp.linguist" />
                            </NavLink>
                          </li>}
                          {findNewsuppliersList && <li>
                            <NavLink
                              exact
                              className={classes.menuLink}
                              to={"/find-vendor"}
                            >
                              <FormattedMessage id="find.new.suppliers" />
                            </NavLink>
                          </li>}
                        </>
                      )}
                      {projectId && projectId != '0' && (
                        <>
                          {localStorage.getItem("selectedType") == "task"
                            ?
                            (
                              <>
                                <li>
                                  <NavLink
                                    exact
                                    className={classes.menuLink}
                                    to={"/my-jobs/files/" + projectId}
                                  >
                                    <FormattedMessage id="files" />
                                  </NavLink>
                                </li>
                                {projectTasksList &&
                                  <li>
                                    <NavLink
                                      exact
                                      className={classes.menuLink}
                                      to={"/my-jobs/files/tasks/" + projectId}
                                    >
                                      <FormattedMessage id="tasks" />
                                    </NavLink>
                                  </li>
                                }

                              </>
                            )
                            :
                            (
                              <>
                                <li>
                                  <NavLink
                                    exact
                                    className={classes.menuLink}
                                    to={"/projects/file/" + projectId}
                                  >
                                    <FormattedMessage id="files" />
                                  </NavLink>
                                </li>
                                {projectTasksList &&
                                  <li>
                                    <NavLink
                                      exact
                                      className={classes.menuLink}
                                      to={"/projects/tasks/" + projectId}
                                    >
                                      <FormattedMessage id="vendor.job" />
                                    </NavLink>
                                  </li>
                                }
                              </>
                            )}
                          {localStorage.getItem("selectedType") != "task" && projectSettingView
                            ?
                            (
                              <li>
                                <NavLink
                                  exact
                                  className={classes.menuLink}
                                  to={"/projects/settings/" + projectId}
                                >
                                  <FormattedMessage id="settings" />
                                </NavLink>
                              </li>
                            )
                            :
                            (
                              ""
                            )
                          }
                        </>
                      )}

                      {(appUserRole === "ROLE_VENDOR") &&
                        (window.location.pathname === "/vendor/open-jobs" ||
                          window.location.pathname === "/vendor/my-jobs") && (
                          <>
                            <li>
                              <NavLink
                                exact
                                className={classes.menuLink}
                                to="/vendor/open-jobs"
                              >

                                <FormattedMessage id="open.jobs" />
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                exact
                                className={classes.menuLink}
                                to="/vendor/my-jobs"
                              >
                                {" "}
                                <FormattedMessage id="assigned.jobs" />
                              </NavLink>
                            </li>
                          </>
                        )}
                      {(location.pathname === "/payments/tab" || location.pathname === "/vendor/profile/" + currentUser.slug || location.pathname === "/vendor/account/settings" || location.pathname === "/invoices")
                        &&
                        (
                          <>
                            <li>
                              <NavLink
                                exact
                                className={classes.menuLink}
                                to={"/vendor/profile/" + currentUser.slug}
                              >
                                <FormattedMessage id="my.profile" />
                              </NavLink>
                            </li>
                            {isOwner && <li>
                              <NavLink
                                exact
                                className={classes.menuLink}
                                to={"/payments/tab"}
                              >
                                <FormattedMessage id="payments" />
                              </NavLink>
                            </li>}

                            {isOwner && <NavLink
                              exact
                              className={classes.menuLink}
                              to={"/vendor/account/settings"}
                            >
                              <FormattedMessage id="account.settings" />
                            </NavLink>}
                          </>
                        )}
                      {/* {(location.pathname === "/transactions" || location.pathname === "/subscriptions" || location.pathname === "/withdraw-history" || location.pathname === "/invoices")

                                                &&
                                                (
                                                    <>
                                                        <li>
                                                            <NavLink
                                                                exact
                                                                className={classes.menuLink}
                                                                to={"/transactions"}
                                                            >
                                                                <FormattedMessage id="transactions" />
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink
                                                                exact
                                                                className={classes.menuLink}
                                                                to={"/subscriptions"}
                                                            >
                                                                <FormattedMessage id="Subscriptions" />
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink
                                                                exact
                                                                className={classes.menuLink}
                                                                to={"/withdraw-history"}
                                                            >
                                                                <FormattedMessage id="withdrawals.history" />
                                                            </NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink
                                                                exact
                                                                className={classes.menuLink}
                                                                to={"/invoices"}
                                                            >
                                                                <FormattedMessage id="invoices" />
                                                            </NavLink>
                                                        </li>
                                                    </>
                                                )
                                            } */}

                      {(!projectId || projectId == '0') &&
                        (location.pathname === "/vendor/rate/request" ||
                          location.pathname === "/vendor/client/rates" ||
                          location.pathname === "/vendor/rates/client" ||
                          location.pathname === "/vendor/rates/add-rates" ||
                          location.pathname === "/vendor/rates") && (
                          <>
                            {/*----------old nav link---------------- */}

                            {/* <li><NavLink exact className={classes.menuLink}
                                                        to="/vendor/general/rates"> <FormattedMessage id="general" /></NavLink></li> */}
                            {/* <li><NavLink exact className={classes.menuLink}
                                                        to="/vendor/client/rates"> <FormattedMessage id="client" /></NavLink></li></>} */}

                            {/*----------------new nav link--------------- */}
                            {rateRequestList && <li>
                              <NavLink
                                exact
                                className={classes.menuLink}
                                to="/vendor/rate/request"
                              >
                                <FormattedMessage id="rate.request" />
                              </NavLink>
                            </li>}
                            {ratesList && <li>
                              <NavLink
                                exact
                                className={classes.menuLink}
                                to="/vendor/rates"
                              >
                                <FormattedMessage id="add.rate" />
                              </NavLink>
                            </li>}
                          </>
                        )
                      }

                      {/* <li><NavLink exact className={classes.menuLink}
                                                 to="/vendor/projects"> <FormattedMessage id="projects"/></NavLink></li> */}
                      {/*<li><NavLink exact className={classes.menuLink}*/}
                      {/*to="/vendor/services"> <FormattedMessage id="services"/></NavLink></li>*/}
                      {/*<li><NavLink exact className={classes.menuLink} to="/vendor/rates"><FormattedMessage id="rates"/></NavLink>*/}
                      {/*</li>*/}
                      {/*<>*/}
                      {/*<li><NavLink exact className={classes.menuLink}  onClick={() => localStorage.setItem('searchVendor', '')}*/}
                      {/*to="/vendor/linguists"><FormattedMessage id="linguist"/></NavLink></li>*/}
                      {/*</>*/}
                      {/*<li><NavLink exact className={classes.menuLink} to="/vendor/match-rates"><FormattedMessage id="match.rate"/></NavLink></li>*/}
                      {/*/!*<li><NavLink exact className={classes.menuLink} to="javascript:void(0);" onClick={(e: any) => noPageModalOpen(e)}>Teams</NavLink></li>*!/*/}
                      {/*<li><NavLink exact className={classes.menuLink} to="javascript:void(0);"*/}
                      {/*onClick={(e: any) => noPageModalOpen(e)}><FormattedMessage id="reports"/></NavLink></li>*/}
                    </MBox>
                  )
                )
              }
            </MGrid>
          )}

          {appUserRole === "ROLE_TRANSLATOR" && (
            <MBox
              className={classes.mainNav}
              component="ul"
              display="flex"
              p={0}
            >
              {location.pathname === "/linguist/general/rates" ||
                location.pathname === "/linguist/client/rates" ||
                location.pathname === "/linguist/vendor/rates" ? (
                <>
                  <li>
                    <NavLink
                      exact
                      className={classes.menuLink}
                      to="/linguist/general/rates"
                    >
                      <FormattedMessage id="general" />
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      className={classes.menuLink}
                      to="/linguist/client/rates"
                    >
                      <FormattedMessage id="client" /> /{" "}
                      <FormattedMessage id="vendor" />
                    </NavLink>
                  </li>
                  {/*<li><NavLink exact className={classes.menuLink}*/}
                  {/*to="/linguist/vendor/rates"> <FormattedMessage id="vendor"/></NavLink></li>*/}
                </>
              ) : (
                ""
              )}

              {(location.pathname === "/transactions" || location.pathname === "/subscriptions" || location.pathname === "/withdraw-history" || location.pathname === "/invoices")
                &&
                (
                  <>
                    <li>
                      <NavLink
                        exact
                        className={classes.menuLink}
                        to={"/transactions"}
                      >
                        <FormattedMessage id="transactions" />
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        exact
                        className={classes.menuLink}
                        to={"/withdraw-history"}
                      >
                        <FormattedMessage id="withdrawals.history" />
                      </NavLink>
                    </li>
                  </>
                )
              }
              {location.pathname === "/linguist/my-jobs" ||
                location.pathname === "/linguist/open-jobs" ? (
                <>
                  <li>
                    <NavLink
                      exact
                      className={classes.menuLink}
                      to="/linguist/open-jobs"
                    >
                      <FormattedMessage id="open.jobs" />
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      className={classes.menuLink}
                      to="/linguist/my-jobs"
                    >
                      <FormattedMessage id="assigned.jobs" />
                    </NavLink>
                  </li>
                </>
              ) : (
                ""
              )}
              {location.pathname === ("/linguist/profile/" + currentUser.slug) || location.pathname === "/transactions/tab" || location.pathname === "/account/settings" ? (
                <>
                  <li>
                    <NavLink
                      exact
                      className={classes.menuLink}
                      to={currentUser.slug !== null ? "/linguist/profile/" + currentUser.slug : ''}
                      onClick={showProfileHandler}
                    >
                      <FormattedMessage id="my.profile" />
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      exact
                      className={classes.menuLink}
                      to="/transactions/tab"
                    >
                      <FormattedMessage id="payments" />
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      exact
                      className={classes.menuLink}
                      to="/account/settings"
                    >
                      <FormattedMessage id="account.settings" />
                    </NavLink>
                  </li>
                </>
              ) : (
                ""
              )}
            </MBox>
          )}
          {/* {!orgs &&
                        !isGraphqlError &&
                        Array(appUserRole === "ROLE_CLIENT" ? 1 : 1)
                            .fill(null)
                            .map(
                                () => (
                                    <MBox ml={3}>
                                        <MSkeleton width={78} height={20} />
                                    </MBox>
                                )
                            )
                    } */}
        </MGrid>
      </MGrid>
      <MGrid item sm={2} container justifyContent="flex-end">
        <MBox display="flex" flexDirection="row" p={1} alignItems="center">
          {/* {(organizations && organizations.length > 0) &&
                        <MBox mr={1}>
                            <RoleDropdown />
                        </MBox>
                    } */}
          <MBox>
            {organizations &&
              organizations.length < 1 &&
              window.location.href.split("/").pop() === "dashboard" && (
                <MButton
                  variant="contained"
                  color="secondary"
                  onClick={() => props.history.push("/create-organization")}
                >
                  <FormattedMessage id="create.organization" />
                </MButton>
              )}
            {/* {localStorage.getItem('subscription') === "false" &&
                        <MButton variant="contained" color="secondary" onClick={() => localStorage.setItem('subscription', "true")}>
                        <NavLink to="/subscription" style={{color:'#fff',textDecoration:'none'}}>Subscribe</NavLink>
                        </MButton>
                        } */}

            {/* {organizations && organizations.length > 0 &&
                        <MBox mr={2}>
                            <OrganizationDropdown headerText="Organizations" footerText="See All"/>
                        </MBox>
                        } */}
          </MBox>
          <MBox>
            {/* <ToggleButton
                        value="check"
                        selected={themeType}
                        onChange={() => {
                            setThemeType(!themeType);
                        }}
                        >
                        <CheckIcon />
                    </ToggleButton> */}
          </MBox>
          {/* <MBox className={classes.langSwith}>
            <MIconButton
              aria-label="show 17 new notifications"
              aria-controls="simple-menu"
              aria-haspopup="true"
              disabled={loading}
              onClick={
                handleNotification
              }
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.86771 5.45915C11.4467 5.45915 13.035 5.35197 14.6027 5.48492C16.8691 5.67662 18.3511 7.21845 18.5131 9.48586C18.6844 11.88 18.8052 14.2907 18.4192 16.6725C18.0869 18.7214 16.6771 19.9613 14.6099 20.2004C14.065 20.2632 13.5077 20.2292 12.968 20.3189C12.6749 20.3673 12.3405 20.5178 12.1465 20.7322C11.6996 21.2269 11.3311 21.7927 10.9235 22.3225C10.2372 23.214 9.39607 23.2222 8.71182 22.3431C8.31139 21.8288 7.96256 21.2671 7.51569 20.7971C7.28348 20.5528 6.90266 20.3694 6.56518 20.3199C5.68485 20.1911 4.77356 20.2364 3.90665 20.0571C2.20378 19.7036 1.21715 18.5153 1.11291 16.5169C0.998356 14.3092 0.94469 12.0862 1.07989 9.88265C1.26153 6.91338 2.9582 5.4272 5.94905 5.37155C7.25458 5.34681 8.56115 5.36743 9.86771 5.36743C9.86771 5.39732 9.86771 5.42823 9.86771 5.45915ZM5.95112 14.1433C6.5755 14.1495 7.07191 13.662 7.06572 13.0488C7.05953 12.4644 6.56931 11.9718 5.98414 11.9625C5.37111 11.9532 4.8778 12.4479 4.88192 13.0694C4.88605 13.6836 5.33705 14.1371 5.95112 14.1433ZM9.7707 14.1423C10.392 14.1588 10.8678 13.7269 10.8956 13.123C10.9235 12.5015 10.4549 11.9841 9.84604 11.9635C9.23816 11.9429 8.72215 12.4386 8.71595 13.0477C8.70976 13.6548 9.16489 14.1268 9.7707 14.1423ZM13.6574 14.1433C14.2601 14.1443 14.7431 13.6816 14.7585 13.0869C14.7751 12.4768 14.2745 11.9635 13.6636 11.9625C13.0577 11.9614 12.5417 12.483 12.5562 13.0807C12.5696 13.6672 13.0619 14.1423 13.6574 14.1433Z" fill="#6B778C" />
                <path d="M14.2103 1.02859C15.7057 1.02859 17.2052 0.954382 18.6945 1.04508C21.2446 1.19967 22.8619 2.85076 22.9506 5.39643C23.0218 7.4371 23.0125 9.48394 22.9454 11.5256C22.9 12.8912 22.4005 14.1115 21.2395 14.9525C21.0537 15.0875 20.7039 15.2225 20.5645 15.137C20.3891 15.0298 20.2745 14.6979 20.2673 14.4599C20.2188 12.7789 20.2776 11.0917 20.162 9.41592C19.9484 6.33122 17.7791 4.08134 14.6819 3.84223C12.527 3.67527 10.3535 3.7536 8.18831 3.72577C7.68983 3.71958 7.19032 3.73814 6.69185 3.72165C6.15415 3.70412 5.96323 3.33722 6.26252 2.88786C7.06029 1.69335 8.24713 1.12856 9.62181 1.05435C11.1472 0.971903 12.6797 1.0358 14.2092 1.0358C14.2103 1.03374 14.2103 1.03065 14.2103 1.02859Z" fill="#6B778C" />
              </svg>
              <MTypography sx={{ fontWeight: 600 }}>&nbsp;Chat</MTypography>

            </MIconButton>
          </MBox> */}
          {/* to switch between languages */}
          <MBox className={classes.langSwith}>
            <MIconButton
              aria-label="Help icon"
              onClick={props.locale === "en-US" ? props.localeChinese : props.localeEnglish}
            >
              <MTypography>{props.locale === "en-US" ? '简体中文' : "English"}</MTypography>
              <Divider orientation="vertical" flexItem></Divider>
              {props.locale === "en-US" ? <MChinessSimplified /> : <MEnglish />}
            </MIconButton>
          </MBox>
          {/*{appUserRole} = {window.localStorage.getItem('selectedOrgId')}*/}
          {/*{(!(appUserRole === 'ROLE_CLIENT' && currentUser.isPrivate) && window.location.pathname !== "/subscriptions/SubscriptionPlan") &&*/}
          {window.location.pathname !== "/subscriptions/SubscriptionPlan" &&
            <MIconButton
              aria-label="chat"
              aria-controls="simple-menu"
              aria-haspopup="true"
              disabled={loading}
              onClick={() => {
                if (isChatNotification) {
                  readChatNotification({ variables: { id: currentUser.id } })
                }
                window.open(
                  process.env.REACT_APP_MARS_CHAT_BASE_URL + `?id=${currentUser.ssoUserName}&platform=${process.env.REACT_APP_MARS_CHAT_PLATFORM}`, "_blank")

              }}>
              {isChatNotification ?
                <MBadge
                  variant="dot"
                  color="secondary"
                  className={classes.dotBagde}
                >

                </MBadge>
                :
                null
              }
              <LightTooltip arrow placement="bottom" title={<FormattedMessage id="chat" />}>

                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.86771 5.45915C11.4467 5.45915 13.035 5.35197 14.6027 5.48492C16.8691 5.67662 18.3511 7.21845 18.5131 9.48586C18.6844 11.88 18.8052 14.2907 18.4192 16.6725C18.0869 18.7214 16.6771 19.9613 14.6099 20.2004C14.065 20.2632 13.5077 20.2292 12.968 20.3189C12.6749 20.3673 12.3405 20.5178 12.1465 20.7322C11.6996 21.2269 11.3311 21.7927 10.9235 22.3225C10.2372 23.214 9.39607 23.2222 8.71182 22.3431C8.31139 21.8288 7.96256 21.2671 7.51569 20.7971C7.28348 20.5528 6.90266 20.3694 6.56518 20.3199C5.68485 20.1911 4.77356 20.2364 3.90665 20.0571C2.20378 19.7036 1.21715 18.5153 1.11291 16.5169C0.998356 14.3092 0.94469 12.0862 1.07989 9.88265C1.26153 6.91338 2.9582 5.4272 5.94905 5.37155C7.25458 5.34681 8.56115 5.36743 9.86771 5.36743C9.86771 5.39732 9.86771 5.42823 9.86771 5.45915ZM5.95112 14.1433C6.5755 14.1495 7.07191 13.662 7.06572 13.0488C7.05953 12.4644 6.56931 11.9718 5.98414 11.9625C5.37111 11.9532 4.8778 12.4479 4.88192 13.0694C4.88605 13.6836 5.33705 14.1371 5.95112 14.1433ZM9.7707 14.1423C10.392 14.1588 10.8678 13.7269 10.8956 13.123C10.9235 12.5015 10.4549 11.9841 9.84604 11.9635C9.23816 11.9429 8.72215 12.4386 8.71595 13.0477C8.70976 13.6548 9.16489 14.1268 9.7707 14.1423ZM13.6574 14.1433C14.2601 14.1443 14.7431 13.6816 14.7585 13.0869C14.7751 12.4768 14.2745 11.9635 13.6636 11.9625C13.0577 11.9614 12.5417 12.483 12.5562 13.0807C12.5696 13.6672 13.0619 14.1423 13.6574 14.1433Z" fill="#6B778C" />
                  <path d="M14.2103 1.02859C15.7057 1.02859 17.2052 0.954382 18.6945 1.04508C21.2446 1.19967 22.8619 2.85076 22.9506 5.39643C23.0218 7.4371 23.0125 9.48394 22.9454 11.5256C22.9 12.8912 22.4005 14.1115 21.2395 14.9525C21.0537 15.0875 20.7039 15.2225 20.5645 15.137C20.3891 15.0298 20.2745 14.6979 20.2673 14.4599C20.2188 12.7789 20.2776 11.0917 20.162 9.41592C19.9484 6.33122 17.7791 4.08134 14.6819 3.84223C12.527 3.67527 10.3535 3.7536 8.18831 3.72577C7.68983 3.71958 7.19032 3.73814 6.69185 3.72165C6.15415 3.70412 5.96323 3.33722 6.26252 2.88786C7.06029 1.69335 8.24713 1.12856 9.62181 1.05435C11.1472 0.971903 12.6797 1.0358 14.2092 1.0358C14.2103 1.03374 14.2103 1.03065 14.2103 1.02859Z" fill="#6B778C" />
                </svg>
              </LightTooltip>
            </MIconButton>
          }
          {(window.localStorage.getItem('selectedOrgId') || window.location.pathname != "/subscriptions/SubscriptionPlan") &&
            <>
              <MBox>
                <MIconButton
                  aria-label="Help icon"
                  onClick={() => dispatch(showComingSoonModal(true))}
                >
                  <LightTooltip arrow placement="bottom" title={<FormattedMessage id="faqs" />}>
                    <HelpIcon className={classes.headerIcon}></HelpIcon>
                  </LightTooltip>
                </MIconButton>
              </MBox>
              {/* {(organizations && organizations.length > 0) &&
                        <MBox>
                            <MIconButton aria-label="settings"
                                         onClick={() => props.history.push((storeSidebarType === "ROLE_VENDOR" ? '/vendor/settings' : '/settings'))}>
                                <SettingsIcon className={classes.headerIcon}/>
                            </MIconButton>
                        </MBox>
                        } */}
              <MBox>
                {window.location.pathname != "/create-organization" ? <MIconButton
                  aria-label="show 17 new notifications"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  disabled={loading}
                  onClick={
                    handleReleaseNotes
                  }
                >
                  {notificationIds && notificationIds.length > 0 ?
                    <MBadge
                      variant="dot"
                      color="secondary"
                      className={classes.dotBagde}
                    >

                    </MBadge>
                    :
                    null
                  }
                  <LightTooltip arrow placement="bottom" title={<FormattedMessage id="release.notes" />}>
                    <NewReleasesIcon className={classes.headerIcon} />
                  </LightTooltip>
                </MIconButton> : null}
                <MMenu
                  id="simple-menu"
                  anchorEl={releaseNotes}
                  keepMounted
                  open={Boolean(releaseNotes)}
                  onClose={handleCloseReleaseNotes}
                  className={classes.notificationLable}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  PaperProps={{
                    style: {
                      marginTop: "40px",
                      width: '100%',
                      maxWidth: 470,
                    }
                  }}
                >
                  <MMenuItem >
                    <MBox className={classes.headerNotification}>
                      <MBox fontFamily='Poppins' fontSize={18}><FormattedMessage id='release.notes' /></MBox>
                      <MBox>
                        <MLink onClick={handleAllReleaseNotes} color='secondary'><FormattedMessage id='view.all' /></MLink></MBox>
                    </MBox>
                  </MMenuItem>
                  <MBox mb={2}>
                    {/* {JSON.stringify(releaseNotesList)} */}
                    {releaseNotesData?.slice(0, 1).map((item: any, idx: number) => (
                      <MBox key={idx} p={2} mb={2} className={classes.infoBox}>
                        <MBox display='flex' alignItem="center" mb={0.75}>
                          <MBox className={classes.badgeBug}>
                            {item.featureType}
                          </MBox>
                          <MBox color='#c5c5c5' fontSize={12} ml={0.75}>
                            {timeZone(item.createdAt, currentUser, 0)}
                          </MBox>
                        </MBox>
                        <MBox fontFamily='Poppins' fontSize={16} color='#4d65b0'>
                          {item.featureName}
                        </MBox>
                        <MBox fontFamily='Poppins' fontSize={14} color='#707070' mt={1}>
                          {Object.keys(JSON.parse(item.featureDescription)).map((key, idx1) => (
                            <div key={idx1}>
                              <h4>{key}</h4>
                              <ul>
                                {JSON.parse(item.featureDescription)[key].map((item: any, idx: number) => (
                                  <li key={idx}>{item}</li>
                                ))}
                              </ul>
                            </div>
                          ))}
                        </MBox>
                      </MBox>
                    )
                    )}
                  </MBox>
                </MMenu>
              </MBox>
              <MBox>
                {window.location.pathname != "/create-organization" ? <MIconButton
                  aria-label="show 17 new notifications"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  disabled={loading}
                  onClick={
                    handleNotification
                  }
                >
                  {isIconNotification && notificationIds && notificationIds.length > 0 ?
                    <MBadge
                      variant="dot"
                      color="secondary"
                      className={classes.dotBagde}
                    >
                    </MBadge>
                    :
                    null
                  }
                  <LightTooltip arrow placement="bottom" title={<FormattedMessage id="notification" />}>
                    <NotificationsIcon className={classes.headerIcon} />
                  </LightTooltip>
                </MIconButton> : null}
                <MMenu
                  id="simple-menu"
                  keepMounted
                  open={Boolean(notification)}
                  onClose={handleCloseNotification}
                  className={classes.notificationLable}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  PaperProps={{
                    style: {
                      marginTop: "40px",
                      width: '100%',
                      maxWidth: 470,
                    }
                  }}
                >
                  <MMenuItem >


                    <MBox className={classes.headerNotification}>
                      <MBox fontFamily='Poppins' fontSize={18}><FormattedMessage id='notifications' /></MBox>
                      <MBox>
                        <MLink onClick={handleAllNotifications} color='secondary'><FormattedMessage id='view.all' /></MLink></MBox>
                    </MBox>
                  </MMenuItem>
                  <MMenuItem>
                    <MBox px={2.5} display='flex' justifyContent='space-between' alignItems='center' color='#6B778C' width='100%' mt={2} mb={0.75}>
                      <MBox fontSize={14} className="textUppercase">
                        <FormattedMessage id="today" />
                      </MBox>
                      <MBox>
                        <MLink className={classes.notificationBar} variant="button" underline='none' onClick={() => {
                          readNotification({
                            variables: {
                              type: 'mark_five_read',
                              ids: notificationIds,

                            }
                          })

                        }}>
                          <FormattedMessage id="mark.all.as.read" />
                        </MLink>
                      </MBox>
                    </MBox>
                  </MMenuItem>
                  {notificationsData && notificationsData.slice(0, 4).map((notification: any, index: number) => (
                    <MBox key={index}
                      sx={{
                        '&:hover': {
                          cursor: 'pointer'
                        }
                      }}
                      onClick={() => {
                        dispatch(setNotificationData(notification));
                        setNotification(null);

                      }}>

                      {/* <MBox className={clsx(classes.notifications, classes[notification.status == 0 ? 'unRead' : 'read'])}
                          display='flex' justifyContent='space-between' alignItems='center' width='100%'> */}
                      {notification && JSON.parse(notification.description) &&
                        <MBox className={clsx(classes.notifications, classes[notification.status != 0 ? 'unRead' : 'read'])}>
                          <MBox display='flex' justifyContent='space-between'>
                            <MBox fontSize={14} color='#42526E' fontFamily='Poppins'>
                              {JSON.parse(notification && notification.description).title}
                            </MBox>
                            {/* <MBox fontSize={14} color='#42526E' fontFamily='Poppins'> */}
                            {notification.status == 0 ?
                              <MBox>
                                <MLink className={classes.markButton} >
                                  <FiberManualRecordIcon />

                                </MLink>
                              </MBox>
                              :
                              ''
                            }
                            {/* </MBox> */}
                          </MBox>

                          <MBox fontSize={12} color='#6B778C' fontFamily='Poppins'>
                            {/*{JSON.parse(notification && notification.description).jobTitle ?*/}
                            <MBox display='flex' justifyContent='space-between'>
                              <MBox>

                                {JSON.parse(notification && notification.description).jobTitle}
                              </MBox>
                              <MBox>
                                <span>&nbsp; {timeAgo(notification.minutes)} </span>
                              </MBox>

                            </MBox>
                            {/*: ''*/}
                            {/*}*/}
                          </MBox>
                        </MBox>
                      }
                    </MBox>
                    // </MBox>
                  )

                  )}
                </MMenu>
              </MBox>
            </>}
          {/* <MBox>
              <MIconButton edge="end" aria-label="account of current user" aria-haspopup="true" color="inherit">
                <MAvatar className={classes.small} alt="Remy Sharp" src="/assets/images/avatar.jpg" />
              </MIconButton>
            </MBox> */}
          <MBox pl={1}>
            {/*<MIconButton aria-controls="UserMenu" aria-haspopup="true" color="inherit"*/}
            {/*onClick={handleClick} className={classes.userAvatarWrapper}>*/}
            {/*/!* <AccountCircle /> *!/*/}
            {/*<MAvatar variant="circle" alt="Remy Sharp"*/}
            {/*src={currentUser && currentUser.profilePicture ? process.env.REACT_APP_PUBLIC_URL + '/' + currentUser.profilePicture : "/assets/images/avatar.jpg"}/>*/}
            {/*</MIconButton>*/}
            <MMenu
              id="UserMenu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              TransitionComponent={Fade}
              className={classes.userDropdown}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {/* <MMenuItem onClick={handleClose}>Home</MMenuItem> */}
              {/* <MMenuItem onClick={handleClose}>Profile</MMenuItem> */}
              {/*<MMenuItem onClick={handleClose}>My account</MMenuItem> */}
              <MBox
                p={1}
                pb={2}
                mb={1}
                textAlign="center"
                className={classes.avatarBox}
              >
                <MAvatar
                  variant="circular"
                  alt="Remy Sharp"
                  src={
                    currentUser && currentUser.profilePicture
                      ? process.env.REACT_APP_PUBLIC_URL +
                      "/" +
                      currentUser.profilePicture
                      : "/assets/images/avatar.jpg"
                  }
                />
                <MBox>
                  {currentUser &&
                    currentUser.firstName + " " + currentUser.lastName}
                </MBox>
              </MBox>
              <MenuItem
                className={classes.menuLink}
                onClick={() =>
                  redirectToPage(
                    appUserRole === "ROLE_CLIENT"
                      ? "/projects"
                      : appUserRole === "ROLE_TRANSLATOR"
                        ? "/linguist/profile"
                        : "/vendor/open-jobs"
                  )
                }
              >
                <FormattedMessage id="dashboard" />
              </MenuItem>
              <MenuItem
                className={classes.menuLink}
                onClick={() => redirectToPage("/my-account")}
              >
                <FormattedMessage id="my.account" />
              </MenuItem>
              {(isAdmin || isOwner) && appUserRole === "ROLE_CLIENT" && (
                <MenuItem
                  className={classes.menuLink}
                  onClick={() => redirectToPage("/transactions")}
                >
                  <FormattedMessage id="transactions" />
                </MenuItem>
              )}
              <MenuItem
                className={classes.menuLink}
                onClick={() =>
                  redirectToPage(
                    appUserRole === "ROLE_VENDOR"
                      ?
                      "/vendor/settings"
                      :
                      appUserRole === "ROLE_TRANSLATOR"
                        ?
                        "/linguist/profile"
                        :
                        "/settings"
                  )
                }
              >
                <FormattedMessage id="settings" />
              </MenuItem>
              {/*{(organizations && organizations.length > 0) &&*/}
              {isOwner || isOwner == ""
                ?
                (
                  <MBox mt={1} mb={0.75} pb={1} className={classes.roleWrap}>
                    <MBox
                      mt={1.5}
                      ml={1.25}
                      mb={0.5}
                      fontSize={12}
                      color="#6b778c"
                    >
                      <FormattedMessage id="switch.role" />
                    </MBox>
                    {/*<MenuItem */}
                    {/*className={(currentRole==="ROLE_CLIENT" || currentRole==="projectBar")? classes.selectedRole:''} */}
                    {/*onClick={() => switchRole('ROLE_CLIENT')}*/}
                    {/*>*/}
                    {/*Client*/}
                    {/*</MenuItem>*/}
                    {/*<MenuItem className={currentRole==="ROLE_VENDOR"? classes.selectedRole:''} onClick={() => switchRole('ROLE_VENDOR')}>*/}
                    {/*Vendor*/}
                    {/*</MenuItem>*/}
                    {/*<MenuItem  onClick={() => {*/}
                    {/*dispatch(showComingSoonModal(true));*/}
                    {/*handleClose();*/}
                    {/*}}>*/}
                    {/*Lignuist*/}
                    {/*</MenuItem>*/}
                    <MenuItem
                      className={
                        appUserRole === "ROLE_CLIENT" ? classes.selectedRole : ""
                      }
                      onClick={() => switchRole("ROLE_CLIENT")}
                    >
                      <FormattedMessage id="client" />
                    </MenuItem>
                    <MenuItem
                      className={
                        appUserRole === "ROLE_VENDOR" ? classes.selectedRole : ""
                      }
                      onClick={() => switchRole("ROLE_VENDOR")}
                    >
                      <FormattedMessage id="vendor" />
                    </MenuItem>
                    <MenuItem className={
                      appUserRole === "ROLE_TRANSLATOR"
                        ?
                        classes.selectedRole
                        :
                        ""
                    }
                      onClick={() => switchRole("ROLE_TRANSLATOR")}
                    >
                      <FormattedMessage id="linguist.side" />
                    </MenuItem>
                  </MBox>
                )
                :
                (
                  <MBox
                    style={{
                      marginTop: 10,
                      marginBottom: 5,
                      borderTop: "1px solid #d8d9db",
                    }}
                  ></MBox>
                )}

              {/*}*/}
              <MenuItem onClick={logout} className={`${classes.logoutLink}`}>
                <span className={`${classes.menuLink} ${classes.logoutLink}`}>
                  <FormattedMessage id="logout" />
                </span>
              </MenuItem>
            </MMenu>
          </MBox>
        </MBox>
      </MGrid>
    </MGrid >
  );
}
export default withRouter(Header);
