/* eslint-disable */
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DoneIcon from '@mui/icons-material/Done';
import { gql } from "apollo-boost";
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import {
    MAvatar,
    MBox,
    MButton, MCircularProgress,
    MDialog, MDialogActions,
    MDialogContent,
    MDialogTitle,
    MDrawer,
    MIconButton,
    MLink
} from 'src/app/material-ui';
import {
    MBankTransfer,
    MClipboardList,
    MCreditCard,
    MMarsCredit,
    MPaypalIcon,
    MTextboxCheckOutline,
    MTotalIcon
} from 'src/app/material-ui/icons';
import { showAlert } from "../../../store";
import ViewInvoice from "../invoice/SendInvoice/ViewInvoice";
import useStyles from './JobDetailStyles';
import BankTransfer from "./paymentComponent/BankTransfer";
import CreditCard from './paymentComponent/CreditCard'
import Credit from './paymentComponent/Credits'
import { avatarPlaceholder } from "../../../hooks";
import { PayPalButton } from "@repeatgg/react-paypal-button-v2";

import Disclaimer from "src/app/components/disclaimer/Disclaimer";
import CheckPermissions from "src/app/hooks/permissions/CheckPermissions";

// import BankTransfer from './paymentComponent/BankTransfer'
const UPDATE_INVOICE_STATUS = gql`
    mutation updateInvoiceStatus($isPayThroughCredits: Int,$isNewCard: Int, $invoiceId: Int!,$status: String!,$paymentInformation: String,$cardId : Int,$transactionDetails: String) {
        updateInvoiceStatus(isPayThroughCredits: $isPayThroughCredits, isNewCard: $isNewCard, invoiceId: $invoiceId, status: $status, paymentInformation: $paymentInformation, cardId: $cardId, transactionDetails: $transactionDetails)
    }
`;

interface Iprops {
    viewDetailOpenModal?: boolean,
    setViewDetailOpenModal?: any,
    invoiceId?: any,
    quoteRequestedId?: any,
    detailQuery?: any,
    selectedJob?: any,
    setRefreshProject?: any,
    refreshProjects?: any,
    sendInvoiceFullWidth?: any,
    selectedProjectId?: any,
    setInvoiceId?: any,
    setSendInvoiceFullWidth?: any
    resourceName?: any
    paymentType?: any
    taskId?: any
    invoiceData?: any
    setInvoiceData?: any,
    setQuoteDetails?: any
}

const GET_ALL_GATEWAYS = gql`
    query getActivePaymentGateways($invoiceId:Int,$orgId:Int)  {
        getActivePaymentGateways(invoiceId:$invoiceId,orgId:$orgId) {
            code
            name
            clientId
            fee
        }
    }
`;


const GET_ALL_ORG_PAYMENT_CARDS = gql`
    query getOrgPaymentCards($orgId:Int) {
        getOrgPaymentCards(orgId:$orgId) {
            id
            number
            type
            expiryMonth
            isDefault
            expiryYear
            createdAt
            updatedAt
        }
    }
`;

const InvoiceDetailModal = (props: Iprops) => {
    const { setSendInvoiceFullWidth, selectedJob, viewDetailOpenModal, setViewDetailOpenModal, invoiceId, quoteRequestedId, setRefreshProject, refreshProjects, selectedProjectId, resourceName, taskId, paymentType, setInvoiceData, invoiceData, setQuoteDetails } = props;
    const classes = useStyles({});
    const [acceptRejectOpen, setAcceptRejectOpen] = useState(false);
    //disclaimer drawer
    const [disclaimerDrawer, setDisclaimerDrawer] = useState(false);

    const [update, setUpdate] = useState(0);
    const [paymentInformation, setPaymentInformation] = useState('');
    const [invoiceStatus, setInvoiceStatus] = useState(0);
    const [isAcceptAllow, setIsAcceptAllow] = useState(0);
    const [toUser, setToUser] = useState(0);
    const [status, setStatus] = useState('');
    // const currentUser = useSelector((store: any) => store.auth.currentUser);
    const [paymentMethod, setPaymentMethod]: any = useState('');
    const [isFullWidth, setIsFullWidth] = React.useState(false);
    const [allGateways, setAllGateways]: any = useState([]);
    const [selectedGateway, setSelectedGateway]: any = useState({});
    const selectedOrg: any = window.localStorage.getItem('selectedOrgId');
    // const selectedOrgId = useSelector((store: any) => store.orgs.selectedOrgId);
    const [allPaymentCards, setAllPaymentCards]: any = useState([]);
    const [defaultCard, setDefaultCard]: any = useState({});
    const [selectedCard, setSelectedCard]: any = useState(null);
    const [paypalFee, setPaypalFee]: any = useState(null);
    const [paypalClientId, setPaypalClientId]: any = useState(null);
    const [isNewCard, setIsNewCard]: any = useState(0);
    const [isPayThroughCredits, setIsPayThroughCredits]: any = useState(0);
    const [walletBalance, setWalletBalance]: any = useState(0);
    const [isPaid, setIsPaid]: any = useState(null);
    const [updatedInvoice, setUpdatedInvoice]: any = useState(0);
    const [onlineTransactionDetails, setOnlineTransactionDetails]: any = useState({});
    const [updatedInvoiceObject, setUpdatedInvoiceObject]: any = useState(false);
    const [addFundsDrawer, setAddFundsDrawer]: any = useState(false);
    const SelectedOrgId = useSelector((store: any) => store.orgs.selectedOrgId);
    const organizations = useSelector((store: any) => store.orgs.organizations);
    // const projectInvoiceCreate = CheckPermission('ROLE_PROJ_INVOICE_CREATE');
    // const projectInvoiceEdit = CheckPermission('ROLE_PROJ_INVOICE_EDIT');
    const [projectInvoiceEdit, setProjectInvoiceEdit] = useState(false);
    const [projectInvoicePaymenCreate, setProjectInvoicePaymenCreate] = useState(false);
    const [projectInvoiceDownload, setProjectInvoiceDownload] = useState(false);

    const [maxWidth, setMaxWidth] = React.useState('sm');

    useEffect(() => {

        setProjectInvoiceEdit(CheckPermissions('ROLE_PROJ_INVOICE_PAYMENT_EDIT', SelectedOrgId, organizations));
        setProjectInvoicePaymenCreate(CheckPermissions('ROLE_PROJ_INVOICE_PAYMENT_CREATE', SelectedOrgId, organizations));
        setProjectInvoiceDownload(CheckPermissions('ROLE_PROJ_INVOICE_PAYMENT_EXPORT', SelectedOrgId, organizations));

    }, [SelectedOrgId, organizations]);

    const handlePaymentMethod = (method: string) => {
        setPaymentMethod(method);

        if (method == 'marshub_credits') {
            setIsPayThroughCredits(1);
        } else {
            setIsPayThroughCredits(0);
        }
    };

    const [getAllGateways] = useLazyQuery(GET_ALL_GATEWAYS, {
        variables: { invoiceId: parseInt(invoiceId), orgId: parseInt(selectedOrg) },
        fetchPolicy: 'no-cache',
        onCompleted: (data: any) => {
            setAllGateways(data.getActivePaymentGateways);
            setPaypalFee(data.getActivePaymentGateways.find((item: any) => item.code == 'paypal')?.fee);
            setPaypalClientId(data.getActivePaymentGateways.find((item: any) => item.code == 'paypal')?.clientId);

            setWalletBalance(data.getActivePaymentGateways.find((item: any) => item.code == 'marshub_credits' ? item.fee : 0));

            if (typeof selectedGateway.code != "undefined" && selectedGateway.code) {
                setSelectedGateway(data.getActivePaymentGateways.find((itm: any) => itm.code == selectedGateway.code))
            }
            // setStripePromise(loadStripe(data.getActivePaymentGateways.find((item: any) => item.code == 'stripe').clientId));
        }
    });


    const [getAllOrgPaymentCards] = useLazyQuery(GET_ALL_ORG_PAYMENT_CARDS, {
        variables: { orgId: parseInt(selectedOrg) },
        fetchPolicy: 'no-cache',
        onCompleted: (data: any) => {
            setAllPaymentCards(data.getOrgPaymentCards);
            setDefaultCard(data.getOrgPaymentCards.find((item: any) => item.isDefault == 1));
        }
    });

    useEffect(() => {
        if (selectedOrg && invoiceId) {
            getAllGateways();
            getAllOrgPaymentCards();
        }
        // setStripeForm(<StripeForm/>);
    }, [selectedOrg, invoiceId]);

    const dispatch = useDispatch();
    const [updateInvoice, { loading: updateLoading }] = useMutation(UPDATE_INVOICE_STATUS, {
        fetchPolicy: 'no-cache',
        onCompleted: () => {
            dispatch(showAlert({
                message: status === 'paid' ? 'Invoice paid successfully' : 'Invoice approved successfully.',
                messageType: 'success',
                showAlertMessage: true
            }));
            setRefreshProject && setRefreshProject(!refreshProjects);
            setAcceptRejectOpen(false);
            setViewDetailOpenModal(false);
            setAddFundsDrawer(false);
            setIsFullWidth(false);
            if (typeof (props.setSendInvoiceFullWidth) != "undefined") {
                props.setSendInvoiceFullWidth(false);
            }
            getAllGateways();
        }
    });
    const handleClose = () => {
        setPaymentMethod('');
    };
    const handleAcceptRejectClose = () => {
        setAcceptRejectOpen(false);
        setIsFullWidth(false);
    };

    const dialogCloseYes = () => {

        setTimeout(() => {
            // alert(onlineTransactionDetails);
            updateInvoice({
                variables: {
                    transactionDetails: onlineTransactionDetails && JSON.stringify(onlineTransactionDetails),
                    cardId: JSON.stringify(onlineTransactionDetails) == "{}" ? (selectedCard && selectedCard.id) : 0,
                    status: status,
                    invoiceId: +invoiceId,
                    isPayThroughCredits: isPayThroughCredits,
                    isNewCard: isNewCard,
                    paymentInformation: paymentInformation
                }
            });
        }, 1500);

    };

    useEffect(() => {
        if (invoiceId && status == 'paid' && JSON.stringify(onlineTransactionDetails) != "{}") {
            dialogCloseYes();
            // alert(JSON.stringify(onlineTransactionDetails));
        }
    }, [onlineTransactionDetails]);

    return (
        <div className={classes.filesWrapper}>

            <MBox className={'gggg'}>


                <Disclaimer
                    open={disclaimerDrawer}
                    maxWidth={maxWidth}
                    onClose={() => setDisclaimerDrawer(false)}
                    fullWidth={true}
                    message={'Disclaimer'}
                    description={'MarsCloud is not responsible for your transactions. Please verify your documents before making any payments, Users are advised to exercise caution and perform due diligence before engaging in financial transactions through MarsCloud platform'}
                    onApprove={() => { setAcceptRejectOpen(true); setDisclaimerDrawer(false); }}
                    onCancel={() => setDisclaimerDrawer(false)}
                />
            </MBox>
            <MDrawer
                className={`${classes.fileUploadDrawer} ${addFundsDrawer ? 'fullWidth' : ""}`}
                open={(acceptRejectOpen && status == 'paid')}
                variant="temporary"
                anchor={'right'}
            >

                <AppBar className='drawerAppBar'>
                    <Toolbar>
                        <MBox width="100%" display="flex" justifyContent="space-between" alignItems="center">
                            <MBox display="flex">
                                <MBox display="inline-block" mr={1}>
                                    <MIconButton onClick={handleAcceptRejectClose}>
                                        <ArrowBackIcon />
                                    </MIconButton>
                                </MBox>
                                <MBox fontSize={18} fontFamily="Poppins" mt={1.25}>
                                    <FormattedMessage id="pay.now" />
                                    {/*{onlineTransactionDetails && JSON.stringify(onlineTransactionDetails)}*/}
                                </MBox>

                            </MBox>

                        </MBox>

                    </Toolbar>
                </AppBar>
                <MBox className={classes.paymentContainer} mt={6} mb={5}>
                    <MBox className={classes.paymentDetsils}>
                        <MBox display='flex' justifyContent='space-between' gap='15px'>
                            <MBox className={classes.cardBalance} color='#6B778C' display='flex' alignItems='center'>
                                <MClipboardList />
                                <MBox pl={1}>
                                    <MBox fontSize={14}>
                                        <FormattedMessage id='Task Name' />
                                    </MBox>
                                    <MBox fontSize={16} color='#3a3a3a' fontFamily="Poppins">
                                        {/*<FormattedMessage id='Task Name' />*/}
                                        {selectedJob && selectedJob.name}
                                    </MBox>
                                </MBox>
                            </MBox>
                            <MBox className={classes.cardBalance} color='#6B778C' display='flex' alignItems='center'>
                                <MTextboxCheckOutline />
                                <MBox pl={1}>
                                    <MBox fontSize={14}>
                                        <FormattedMessage id='Invoice No.' />
                                    </MBox>
                                    <MBox fontSize={16} color='#3a3a3a' fontFamily="Poppins">
                                        {/*<FormattedMessage id='#8456548' />*/}
                                        #{invoiceId}
                                    </MBox>
                                </MBox>
                            </MBox>
                            <MBox className={classes.cardBalance} color='#6B778C' display='flex' alignItems='center'>
                                <MBox>
                                    {/*<MAvatar*/}
                                    {/*src={process.env.REACT_APP_PUBLIC_URL + '/' }*/}
                                    {/*alt="logo" width="36" height="36"*/}
                                    {/*sx={{ borderRadius: '50%' }} />*/}
                                    {selectedJob && selectedJob.assignee && selectedJob.assignee.profilePicture ?
                                        <MAvatar
                                            src={selectedJob.assignee.profilePicture && selectedJob.assignee.profilePicture ? process.env.REACT_APP_PUBLIC_URL + '/' + selectedJob.assignee.profilePicture : ""}
                                            alt="logo"
                                            sx={{ "width": "33px", "height": "33px", "fontSize": "11px" }}>
                                        </MAvatar>
                                        :
                                        <>
                                            {selectedJob && selectedJob.assignee &&
                                                <MAvatar alt="logo"
                                                    sx={{ "width": "33px", "height": "33px", "fontSize": "11px" }}>
                                                    {selectedJob.assignee && avatarPlaceholder(selectedJob.assignee.firstName)}
                                                </MAvatar>
                                            }
                                        </>
                                    }
                                </MBox>
                                <MBox pl={1}>
                                    <MBox fontSize={14}>
                                        <FormattedMessage id='Send To' />
                                    </MBox>
                                    <MBox fontSize={16} color='#3a3a3a' fontFamily="Poppins">
                                        {/*<FormattedMessage id='Alfonso' />*/}
                                        {selectedJob && selectedJob.assignee && selectedJob.assignee.firstName}

                                    </MBox>
                                </MBox>
                            </MBox>
                            <MBox className={classes.cardBalance} color='#6B778C' display='flex' alignItems='center'
                            >
                                <MTotalIcon />
                                <MBox pl={1}>
                                    <MBox fontSize={14}>
                                        <FormattedMessage id='Total' />
                                    </MBox>
                                    <MBox fontSize={16} color='#3a3a3a' fontFamily="Poppins">
                                        {/*<FormattedMessage id='$207.57' />*/}
                                        ${selectedJob && selectedJob.invoiceBalance}
                                    </MBox>
                                </MBox>
                            </MBox>

                        </MBox>
                    </MBox>
                </MBox>
                <MBox className={classes.paymentContainer}>

                    {(paymentMethod == '' || paymentMethod == 'paypal') &&
                        <>
                            <MBox fontSize={14} color='#6B778C' fontFamily="Poppins" mb={3}>
                                <FormattedMessage id='choose.your.payment.method.below' />
                            </MBox>

                            <MBox className={classes.paymentOptions}>

                                {allGateways && allGateways.map((item: any, idx: number) => (

                                    <React.Fragment key={idx}>
                                        {item.code == 'paypal' && selectedJob && paypalFee > 0 && paypalClientId &&
                                            <>
                                                <MBox position='relative' >
                                                    <PayPalButton style={{
                                                        layout: 'vertical',
                                                        color: 'silver',
                                                        shape: 'rect',
                                                        label: 'paypal', width: "100%", height: 55, border: 'none',
                                                        'input': {
                                                            'font-size': '200pt',
                                                            'color': '#3A3A3A',
                                                            padding: 55,
                                                        },
                                                    }}
                                                        amount={(selectedJob.invoiceBalance + paypalFee).toFixed(2)}
                                                        // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                                                        onSuccess={(details: any, data: any) => {
                                                            // alert("Transaction completed by " + JSON.stringify(details));

                                                            updateInvoice({
                                                                variables: {
                                                                    transactionDetails: details && JSON.stringify(details),
                                                                    cardId: 0,
                                                                    status: status,
                                                                    invoiceId: +invoiceId,
                                                                    paymentInformation: paymentInformation
                                                                }
                                                            });
                                                            // OPTIONAL: Call your server to save the transaction
                                                            // return fetch("/paypal-transaction-complete", {
                                                            //     method: "post",
                                                            //     body: JSON.stringify({
                                                            //         orderId: data.orderID
                                                            //     })
                                                            // });
                                                        }}
                                                        options={{
                                                            // component: 'buttons',
                                                            disableFunding: "credit,card",
                                                            clientId: paypalClientId
                                                        }}
                                                    />
                                                    <MBox color='#6c788c' fontSize={12} sx={{ position: 'absolute', right: 11, bottom: 11, zIndex: 10000000000000 }}>
                                                        {item.fee > 0 && <><FormattedMessage
                                                            id={item.code !== 'marshub_credits' ? 'Fee' : 'Balance'} /> :
                                                            ${item.code !== 'marshub_credits' ? item.fee : walletBalance}</>}
                                                    </MBox>
                                                </MBox>

                                            </>

                                        }

                                        {item.code != 'paypal' &&
                                            <MBox onClick={() => {
                                                handlePaymentMethod(item.code);
                                                setSelectedGateway(item)
                                            }} className={clsx(classes[paymentMethod == 'paypal' ? "active" : "hide"])}>


                                                <MBox color='#6B778C' display='flex'
                                                    width='100%' className={"marginLeft"} alignItems='center'>
                                                    {item.code === 'paypal' && <MPaypalIcon />}
                                                    {item.code === 'stripe' && <MCreditCard />}
                                                    {item.code === 'marshub_credits' && <MMarsCredit />}
                                                    {item.code === 'bank_transfer' && <MBankTransfer />}

                                                    <MBox pl={1} display='flex'>
                                                        <MBox fontSize={14} color='#3a3a3a' fontFamily="Poppins">
                                                            {item?.name && (
                                                                <FormattedMessage id={item.name} />
                                                            )}
                                                        </MBox>
                                                        <MBox fontSize={12} sx={{ position: 'absolute', right: 11, bottom: 5 }}>
                                                            {item.fee > 0 && <><FormattedMessage
                                                                id={item.code !== 'marshub_credits' ? 'Fee' : 'Balance'} /> :
                                                                ${item.fee}</>}
                                                        </MBox>
                                                    </MBox>


                                                </MBox>

                                                {paymentMethod == 'paypal' ?

                                                    <MBox className={classes.activeIcon}>
                                                        <MBox>
                                                            <DoneIcon />
                                                        </MBox>
                                                    </MBox>


                                                    : ''}
                                            </MBox>
                                        }

                                    </React.Fragment>
                                ))}

                            </MBox>
                        </>
                    }

                    {/*{paymentMethod == '' ?*/}
                    {/*<MBox textAlign='center' mt={18}>*/}
                    {/*<MPayment />*/}
                    {/*<MBox fontSize={17} color='#C9C9C9' fontFamily="Poppins"  >*/}
                    {/*<FormattedMessage id='please.select.your.payment.method' />*/}
                    {/*</MBox>*/}
                    {/*</MBox>*/}

                    {/*: ''}*/}

                    {/*if the use clicks credit card option then it will show credit card component*/}
                    {paymentMethod == 'stripe' &&

                        <CreditCard setIsNewCard={setIsNewCard} setOnlineTransactionDetails={setOnlineTransactionDetails} selectedJob={selectedJob}
                            selectedCard={selectedCard} setSelectedCard={setSelectedCard}
                            selectedGateway={selectedGateway} setSelectedGateway={setSelectedGateway} setPaymentMethod={setPaymentMethod} defaultCard={defaultCard} allCards={allPaymentCards}
                            updateInvoice={dialogCloseYes} setAddFundsDrawer={handleClose} />
                    }
                    {/*if the use clicks marshub credits card option then it will show marshub credits component*/}
                    {paymentMethod == 'marshub_credits' &&
                        <Credit setAddFundsDrawer={setAddFundsDrawer} getAllGateways={getAllGateways} addFundsDrawer={addFundsDrawer} isFullWidth={isFullWidth} updateInvoice={dialogCloseYes} invoiceLoading={updateLoading} selectedGateway={selectedGateway} selectedJob={selectedJob} setPaymentMethod={setPaymentMethod} handleAcceptRejectClose={handleAcceptRejectClose} setWalletBalance={setWalletBalance} walletBalance={walletBalance} />
                    }
                    {paymentMethod == 'bank_transfer' &&
                        // <BankTransfer />
                        <BankTransfer setRefreshProject={setRefreshProject && setRefreshProject} setViewDetailOpenModal={setViewDetailOpenModal}
                            invoiceId={invoiceId} status={status} setPaymentMethod={setPaymentMethod}
                            handleAcceptRejectClose={handleAcceptRejectClose} refreshProjects={refreshProjects} />
                    }
                </MBox>
            </MDrawer>
            <MDialog
                open={(acceptRejectOpen && status == 'accept')}
                onClose={handleAcceptRejectClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <MDialogTitle id="alert-dialog-title">
                    <FormattedMessage id="are.sure" />
                </MDialogTitle>
                <MDialogContent dividers>
                    <MBox mr={9}>
                        <FormattedMessage id="sure.want.to.approve.invoice" />
                    </MBox>
                </MDialogContent>
                <MDialogActions>
                    <MBox mr={1}>
                        <MLink href="javascript:void(0)" onClick={() => handleAcceptRejectClose()} color="inherit">
                            <FormattedMessage id="no" />
                        </MLink >
                    </MBox>
                    <MButton disabled={updateLoading} onClick={() => {
                        updateInvoice({
                            variables: {
                                transactionDetails: null,
                                cardId: 0,
                                status: status,
                                invoiceId: +invoiceId,
                                paymentInformation: paymentInformation
                            }
                        });
                    }} variant="contained" color="primary"
                        autoFocus>
                        {updateLoading && <MCircularProgress color="primary" size={24} />}
                        <FormattedMessage id="yes" />
                    </MButton>
                </MDialogActions>
            </MDialog>

            <MDrawer
                className={`${classes.fileUploadDrawer} ${isFullWidth ? 'fullWidth' : ""}`}
                open={viewDetailOpenModal}
                variant="temporary"
                anchor={'right'}
            >

                <AppBar className='drawerAppBar'>
                    <Toolbar>
                        <MBox width="100%" display="flex" justifyContent="space-between" alignItems="center">
                            <MBox display="flex">
                                <MBox display="inline-block" mr={1}>
                                    <MIconButton onClick={() => {
                                        setViewDetailOpenModal(false);
                                        if (typeof invoiceData != "undefined") {
                                            if (setInvoiceData && typeof setInvoiceData !== undefined) {
                                                setInvoiceData('');
                                            }


                                        }
                                        if (typeof (setSendInvoiceFullWidth) != "undefined") {
                                            setSendInvoiceFullWidth(false);
                                        }

                                    }}>
                                        <ArrowBackIcon />
                                    </MIconButton>
                                </MBox>
                                <MBox fontSize={18} fontFamily="Poppins" mt={1.25}>
                                    <FormattedMessage id="invoice.detail" />
                                </MBox>

                            </MBox>
                            <MBox display="flex" mr={2}>



                                {updatedInvoiceObject && updatedInvoiceObject == true && (invoiceStatus == 1 || invoiceStatus == 5 || invoiceStatus == 3 || invoiceStatus == 2) ?
                                    <>

                                        {isAcceptAllow || 1 == 1 ?
                                            <>
                                                {update === 0 ?

                                                    <>


                                                        {/* {SelectedOrgId} = {organizations && JSON.stringify(organizations)} */}
                                                        {/* {projectInvoicePaymenCreate ? 'T' : "F"} = {isPaid} = {SelectedOrgId} = {toUser} = {isAcceptAllow} = {invoiceStatus} */}
                                                        {/* {(!isPaid && projectInvoicePaymenCreate && (toUser == SelectedOrgId && isAcceptAllow && (invoiceStatus == 1 || invoiceStatus == 5 || invoiceStatus == 3))) ? 'TT' : 'GG'} */}
                                                        {/* {!isPaid && (toUser === currentUser.id && isAcceptAllow && (invoiceStatus === 1 || invoiceStatus === 5 || invoiceStatus === 3)) */}
                                                        {projectInvoicePaymenCreate && ((toUser == SelectedOrgId) && isAcceptAllow && (invoiceStatus == 1 || invoiceStatus == 5 || invoiceStatus == 3))
                                                            ?
                                                            <>
                                                                {process.env.REACT_APP_ENVIRONMENT != 'production' &&
                                                                    <MButton className={classes.btnMark}
                                                                        color="primary" variant="contained"
                                                                        onClick={() => {
                                                                            setDisclaimerDrawer(true)
                                                                            // setAcceptRejectOpen(true);
                                                                            setStatus('paid');
                                                                            // setIsFullWidth(true);
                                                                        }}
                                                                    >
                                                                        <FormattedMessage id="mark.paid" />
                                                                    </MButton>
                                                                }
                                                            </>
                                                            :

                                                            <>
                                                                {!isPaid && (toUser == SelectedOrgId) && (isAcceptAllow === 1 && (invoiceStatus === 1 || invoiceStatus === 5 || invoiceStatus === 3)) ?

                                                                    <>

                                                                        {projectInvoicePaymenCreate &&
                                                                            <MButton sx={{ minWidth: 110, marginLeft: '10px' }} className="btnMedium"
                                                                                color="primary" variant="contained"
                                                                                onClick={() => {
                                                                                    setAcceptRejectOpen(true)
                                                                                    // setAcceptRejectOpen(true);
                                                                                    setStatus('accept');
                                                                                    // setIsFullWidth(true);
                                                                                }}
                                                                            >
                                                                                <FormattedMessage id="approved" />
                                                                            </MButton>}
                                                                    </>

                                                                    : null}

                                                            </>
                                                        }
                                                        {projectInvoiceDownload &&
                                                            <MLink underline="none"
                                                                href={process.env.REACT_APP_API_END_POINT + '/download/invoice/pdf/' + updatedInvoice}>
                                                                <MButton
                                                                    variant="contained"
                                                                    className={classes.btnPdf}
                                                                    sx={{
                                                                        minWidth: 110,
                                                                        marginLeft: '10px',
                                                                    }}>
                                                                    <FormattedMessage id="download.invoice" />
                                                                </MButton>
                                                            </MLink>
                                                        }
                                                        {!isPaid && (toUser == SelectedOrgId) && isAcceptAllow && (invoiceStatus === 1 || invoiceStatus === 5 || invoiceStatus === 3) ?
                                                            <MBox className={classes.footerInvoiceDetail} pt={8}>


                                                                {projectInvoiceEdit &&
                                                                    <MButton className={classes.btnFooter}
                                                                        sx={{ minWidth: 110, marginLeft: '10px' }}
                                                                        color="primary" variant="contained"
                                                                        onClick={() => {
                                                                            setUpdate(1);
                                                                        }}
                                                                    >
                                                                        <FormattedMessage id="update" />
                                                                    </MButton>}
                                                            </MBox> : null}

                                                    </>
                                                    :
                                                    null
                                                }
                                            </> : null}

                                    </> : null
                                }

                            </MBox>

                        </MBox>

                    </Toolbar>
                </AppBar>

                <ViewInvoice setUpdatedInvoiceObject={setUpdatedInvoiceObject} isPaid={isPaid} setUpdatedInvoice={setUpdatedInvoice} setIsPaid={setIsPaid} paymentType={paymentType} resourceName={resourceName} invoiceId={invoiceId} quoteRequestedId={quoteRequestedId}
                    setInvoiceStatus={setInvoiceStatus} refreshProjects={refreshProjects}
                    setRefreshProject={setRefreshProject && setRefreshProject} setSendInvoiceFullWidth={setSendInvoiceFullWidth}
                    invoiceStatus={invoiceStatus} setViewDetailOpenModal={setViewDetailOpenModal}
                    update={update} setUpdate={setUpdate} setIsAcceptAllow={setIsAcceptAllow}
                    isAcceptAllow={isAcceptAllow}
                    setToUser={setToUser} toUser={toUser} projectId={selectedProjectId} taskId={taskId} setQuoteDetails={setQuoteDetails} />


            </MDrawer>
        </div>
    )
}

export default InvoiceDetailModal;