import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles((theme: Theme) => ({
    projectList: {
        filter: 'drop-shadow(0px 5px 2.5px rgba(193,199,208,0.26))',
        backgroundColor: '#fff',
        border: '1px solid rgb(244, 245, 247)',
        borderRadius: 5,
        '& a': {
            color: '#01579b',
            textDecoration: 'none',
            display: 'inline-block'
        }
    },
    fileProgressStyle: {
        width: '100%',
        border: '1px solid rgb(244, 245, 247)',
        borderRadius: 5,
        backgroundColor: '#fff',
        boxShadow: '0px 0px 32px 0px rgba(214, 215, 226, 0.3)',
        padding: '14px 16px 0',
        marginBottom: 10,
        height: 75,
        '& .fileName': {
            maxWidth: '100%',
            display: 'inline-block',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            fontSize: 14,
            color: '#3a3a3a',
            fontFamily: 'Poppins'
        },
        '& .fileData': {
            fontSize: 12,
            color: '#6b778c'
        },
        '& .progressStyle': {
            width: 180,
            height: 8,
            borderRadius: 5,
            backgroundColor: '#f0f0f0',
            marginTop: 4,
            '& .MuiLinearProgress-barColorSecondary.MuiLinearProgress-bar1Determinate': {
                backgroundColor: '#64dba1'
            }
        },
    }, offlineFileList: {
        background: '#fff',
        border: '1px solid #f1f2f5',
        boxShadow: '0px 0px 32px 0px rgba(214, 215, 226, 0.3)',
        '& .MuiButtonBase-root': {
            color: '#a0a5b9',
        }
    }, fileTitle: {
        color: '#3a3a3a',
        textTransform: 'capitalize',
        fontWeight: 'bold',
        fontFamily: "Poppins",
        fontSize: '14px',
    }, fileSize: {
        color: '#a0a5b9',
        fontFamily: "Poppins",
        fontSize: '12px',
    },
    sourceText: {
        color: '#a0a5b9',
        textAlign: 'left',
        '& div, & svg': {
            display: 'inline-block',
            verticalAlign: 'middle'
        },
        '& svg': {
            marginRight: 4,
        },
    },
    DropZoneInput: {
        fontFamily: "Poppins",
        textAlign: 'left',
        color: '#393939',
        cursor: 'pointer',
        fontSize: '24px',
        fontWeight: 'bold',
    },
    DropZoneDetailInput: {
        fontFamily: "Poppins",
        textAlign: 'left',
        color: '#6b778c',
        cursor: 'pointer',
        fontSize: '14px'
    },
    fileUploader: {
        width: '100px'
    },
    mblDialogTitle: {
        '& h2': {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            fontFamily: "Poppins",
            fontSize: '36px',
            lineHeight: '40px',
        },
        '& svg': {
            fill: '#6B778C',
        },
    },
    formBox: {
        backgroundColor: 'white',
        // boxShadow: '0px 5px 5px 0px rgba(193, 199, 208, 0.26)',
        borderRadius: 5,
        height: '100%',
        position: 'relative',
        padding: '15px 41px',
    },

    // workFlowList:{
    //   "& .counter":{
    //       backgroundColor: "#a0a5b9",
    //       color: "#fff",
    //       borderRadius: 20,
    //       position: "relative",
    //   },
    //   "& .text":{
    //       color: "#a0a5b9",
    //   }
    // },
    listHeading: {
        maxWidth: 360,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    captionHeading: {
        fontSize: '12px',
        lineHeight: '21px',
        color: '#a0a5b9',
        // fontFamily: "Poppins",
    },
    sendQuoteBtn: {
        minWidth: '128px !important',
    },
    timeAgo: {
        maxWidth: 180,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },



    seachInput: {
        backgroundColor: '#f5f6f7',
        borderRadius: 5,
        '& .MuiFormLabel-root': {
            transform: 'translate(15px, 8px) scale(1)',
            fontSize: 14,
            // [theme.breakpoints.down('xl')]: {
            //     fontSize: 16,
            // },
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(15px, -7px) scale(0.75)'
        },
        '& .MuiInputBase-input': {

            padding: '5px',
            // [theme.breakpoints.down('xl')]: {
            //     minHeight: 43,
            // },
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #6b778c'
        },
        '& .MuiInputBase-colorSecondary.Mui-focused .MuiOutlinedInput-notchedOutline': {
            // border: '2px solid #6b778c'
        },
        '& input::placeholder': {
            color: '#6b778c',
            fontSize: 13,
            fontFamily: 'Poppins',
            opacity: 1,
            // [theme.breakpoints.down('xl')]: {
            //     fontSize: 16,
            // },
        },
        '& .MuiSvgIcon-root': {
            color: '#585858',
            width: 18,
            height: 18,
            marginRight: -3
        },
        '& fieldset': {
            border: 'none'
        }
    },
    autoCompleteSizeSm: {

        backgroundColor: '#f5f6f7',
        borderRadius: 3,
        // '& .Mui-focused': {
        //      backgroundColor: '#f5f6f7',

        //     maxHeight:50,
        //     overflow:'auto'
        // },
        '& .MuiInputBase-input': {
            padding: '0px !important'
        },

        '& .MuiFormLabel-root': {
            fontSize: 13,
            marginTop: '-3px',
            transform: 'translate(8px, 12px) scale(1)',
            '&.MuiFormLabel-filled,&.Mui-focused': {
                display: 'none'
            },
            // [theme.breakpoints.down('xl')]: {
            //     fontSize: 16,
            //     transform: 'translate(8px, 20px) scale(1)'
            // },
        },
        // '& .MuiAutocomplete-tagSizeSmall': {
        //     width: '65%',
        // },
        '& .MuiAutocomplete-inputRoot': {
            minHeight: 32,
            paddingTop: '0px !important',
            // [theme.breakpoints.down('xl')]: {
            //     minHeight: 56,
            // },
        },
        '& .MuiAutocomplete-input': {
            fontSize: 14,
            // flexGrow: 0,
            minWidth: 'auto',
            [theme.breakpoints.down('xl')]: {
                minHeight: 6,
            },
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #6b778c'
        },
        '& .css-17pjadj-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input': {
            padding: '2px 27px 4px 2px !important',
            backgroundColor: 'red'
        },
        '& legend': {
            width: 0
        },
        '& fieldset': {
            border: 'none'
        }
    },
    //   selectedAutoComplete:{
    //       backgroundColor: '#f5f6f7',
    //       '& svg':{
    //           color:'#6b778c'
    //       },
    //       '& input':{
    //           color:'#6b778c'
    //       }
    //   },
    clearAll: {
        height: 32,
        color: 'rgb(58, 58, 58)',
        // [theme.breakpoints.down('xl')]: {
        //     minHeight: 46,
        //     marginRight: 10
        // },
    },
    //   instructionWrap:{
    //     '& img':{
    //       maxWidth:'80%'
    //     }
    //   },
    status: {
        fontSize: 12,
        minWidth: 90,
        height: 28,
        borderRadius: 14,
        padding: 15,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textTransform: "capitalize",
        [theme.breakpoints.down('xl')]: {
            gap: '0 !important'
        },
        '&.created': {
            // backgroundColor:'#f9f5e7',
            color: '#e69900',
        },
        // '&.bidding':{
        //     backgroundColor:'#ffe8e8',
        //     color: '#ff0102',
        // },
        '&.completed': {
            // backgroundColor: '#F3EEED',
            color: '#7F3E3B',
        },
        '&.pending': {
            // backgroundColor:'#e5f1f7',
            // color: theme.palette.secondary.main
            // backgroundColor:'#f9f5e7',
            color: '#e69900',
            // color: theme.palette.secondary.main
        },
        '&.inprogress': {
            // backgroundColor:'#EBF3F2', 
            color: '#E69B0E',
            '& svg': {
                fill: '#E69B0E'
            }
            // backgroundColor:'#e5f1f7',
            // color: theme.palette.secondary.main
            // color: theme.palette.secondary.main
        },
        '&.approved': {
            // backgroundColor:' #DFF5EB',
            color: '#16C68E',
            '& svg': {
                fill: '#16C68E'
            },
            '&.invoice_5': {
                color: 'rgb(102, 101, 221) !important',
                '& svg': {
                    fill: 'rgb(102, 101, 221) !important'
                }, '& svg path': {
                    fill: 'rgb(102, 101, 221) !important'
                }
            },
            '&.invoice_2': {
                color: 'rgb(63, 116, 232) !important',
                '& svg': {
                    fill: 'rgb(63, 116, 232) !important'
                }, '& svg path': {
                    fill: 'rgb(63, 116, 232) !important'
                }
            }

            // backgroundColor:'#e5f1f7',
            // color: theme.palette.secondary.main
            // color: theme.palette.secondary.main
        },
        '& svg': {
            marginRight: 3
        }
    },
    btnSmall: {
        height: 32,
        minWidth: '106px !important',
        backgroundColor: 'rgb(255, 107, 0)', // 
        // '&:hover': {
        //     backgroundColor: '#a6b3cb'
        // }
        // [theme.breakpoints.down('xl')]: {
        //     minHeight: 46,
        //     marginRight: 10
        // },
    },

    jobDetailDrawer: {
        '& .MuiDrawer-paper': {
            minWidth: "70%"
        }
    },
    fileUploadDrawer: {
        "& .MuiPaper-root.MuiDrawer-paper": {
            minWidth: "85%",
            maxWidth: "85%",

            //   "& .MuiToolbar-root":{
            //       display: 'flex',
            //       justifyContent: 'space-between'
            //   }

        },
        //   '& .MuiToolbar-root':{
        //         display:'flex',
        //         justifyContent: 'space-between'
        //   },
        '&.fullWidth': {
            "& .MuiPaper-root.MuiDrawer-paper": {
                transform: 'translateX(-18%) !important'
            }
        },
    },
    updateModal: {
        "& .MuiPaper-root.MuiDrawer-paper": {
            minWidth: "700px",
            maxWidth: "700px",
        },
    }, skeleton: {
        marginBottom: '32px',
        display: 'block'
    }, contentBox: {
        // backgroundColor: 'white',
        // boxShadow: '0px 5px 5px 0px rgba(193, 199, 208, 0.26)',
        // borderRadius: 5,
        '& .MuiFormHelperText-root.Mui-error': {
            minWidth: 120,
            textTransform: 'initial'
        }
    }, instructionWrap: {
        '& img': {
            maxWidth: '80%'
        }
    }, greyUppercaseText: {
        color: theme.palette.text.secondary,
        textTransform: "uppercase",
    }, hr: {
        borderBottom: "1px solid #dbdbdb",
    }, headerLeftCol: {
        "& > div": {
            width: "calc(50% - 6px)",
        },
    }, headerRightCol: {
        "& > div": {
            width: "calc(50% - 15px)",
        },
    },
    leftCol: {
        "& > div": {
            width: "calc(50% - 6px)",
        }
    },
    rightCol: {
        "& > div": {
            width: "calc(50% - 33px)",
        },
    }, colorLightGrey: {
        color: "#a0a5b9",
    },
    bgLightGrey: {
        backgroundColor: "#f7f9fa",
    }, coverLetterBox: {
        border: '1px solid #ddd',
        padding: 16,
        wordBreak: 'break-all',
    },
    col: {
        //   '@media (max-width:1700px)': {
        //       minWidth: '295px !important',
        //   },
    },
    colStatus: {
        color: '#A0A5B9',
        '& svg': {
            fill: '#A0A5B9',
        },
        //   '@media (max-width:1700px)': {
        //       minWidth: '200px !important',
        //   },
    },
    menuList: {
        '& .MuiButtonBase-root': {
            '& .MuiSvgIcon-root': {
                fill: 'rgb(160, 165, 185)',
                height: 18,
                width: 18,
            },
            '& .MuiTypography-root': {
                fontSize: '12px !important',
                marginLeft: 8,
                color: '#3a3a3a'
            },
            '& .MuiIconButton-root': {
                paddingLeft: 0,
                '&:hover': {
                    backgroundColor: 'transparent'
                }
            }
        }

    },
    mblTableResponsive: {
        [theme.breakpoints.down('xl')]: {
            overflowX: 'scroll'
        },
        '& .mblRowResponsive': {
            [theme.breakpoints.down('xl')]: {
                minWidth: 'max-content'
            },
            '& .MuiBox-root': {
                [theme.breakpoints.down('xl')]: {
                    minWidth: 'max-content',
                    gap: '0 20px'
                }
            }
        }
    }

}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;