/* eslint-disable */
import React, { useEffect } from 'react';
import clsx from 'clsx';
import useStyles from './IndustrySelectStyles';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Autocomplete, Chip } from "@mui/material";
import TextField from '@mui/material/TextField';
import { MKeyboardArrowDownRoundedIcon } from "src/app/material-ui/icons";

const GET_INDUSTRY_GQL = gql`
    {
        getIndustries {
              id
              name
           }
    }
`;


export function IndustrySelect(props: any) {


    const classes: any = useStyles({});


    const { data: sourceLanguage } = useQuery(GET_INDUSTRY_GQL);

    useEffect(() => (function () {

        if (sourceLanguage && props.defaultIndustry) {
            if (props.setFieldValue !== undefined) {
                props.setFieldValue('industryId', props.defaultIndustry.id);

            }
        }
    }), [sourceLanguage]);

    // filtering already selected values from the original array 
    const filterOptions = (options: any, selectedOptions: any) => {
        if (!Array.isArray(selectedOptions) || !Array.isArray(options)) return [];

        const selectedIds = selectedOptions.map((option: any) => option.id);

        return options.filter(option => !selectedIds.includes(option.id));
    };

    const options = (() => {

        if (props.filterSelectedOptions && Array.isArray(sourceLanguage?.getIndustries)) {
            if (Array.isArray(props.filterSelectedOptions)) // sending array from lsp update profile to filter and only show unselected values
            {
                return filterOptions(sourceLanguage.getIndustries, props.filterSelectedOptions);
            }
            else {
                return filterOptions(sourceLanguage.getIndustries, props.defaultIndustry); // sending true from client Account Settings to filter and only show unselected values
            }
        }

        return sourceLanguage ? sourceLanguage.getIndustries : []; // for the rest => old condition will work
    })();

    const renderTags = (tagProps: any) => {
        return (
            tagProps.chipData.map((option: any, index: number) => {
                const { key, ...restProps } = tagProps.getTagProps({ index }); // Remove key

                return (
                    <Chip
                        key={index}  // Pass key explicitly
                        label={option.name || option.label || 'Unnamed Option'}
                        {...restProps}  // Spread the rest of the props
                    />
                );
            })
        );
    };

    const { key, returnInstance, multipleObjs, industryId, defaultIndustry, inputProps, inputHeight, setIndustry, setFieldValue, ...restProps } = props;

    return (

        <div className="sourceLangSelect" style={{ width: '100%' }}>
            <Autocomplete
                {...restProps}
                multiple={props.multiple ? props.multiple : false}
                id="industry"
                size="small"
                loading={!sourceLanguage}
                limitTags={1}
                sx={{ width: props.width ? props.width : 493, backgroundColor: props.backgroundColor ? '' : '#fff' }}
                className={clsx(props.className ? props.className : '', classes.sourceLangSelect, classes[props.size ? props.size : 'sizeMd'], classes.inputWidth, props.redBorder && classes.redBorder)}
                options={options}
                defaultValue={props.industryId ? props.industryId : (props.multiple ? [] : (sourceLanguage && (props.defaultIndustry ? props.defaultIndustry.name : {})))}
                // value={(props.industryId) ? props.industryId : (props.defaultIndustry?props.defaultIndustry.name:{})}
                value={props.industryId ? props.industryId : (props.multiple ? (props.defaultIndustry ? props.defaultIndustry : []) : (sourceLanguage ? (props.defaultIndustry ? props.defaultIndustry.name : {}) : {}))}
                popupIcon={!props.disabled && <MKeyboardArrowDownRoundedIcon />}
                getOptionLabel={(option: any) => typeof option.name != undefined && option.name ? option.name : ''}
                disableClearable={props.clearable ? props.clearable : false}
                onChange={(event: any, value: any) => {
                    let industryId: any = [];
                    const industryObjs: any = [];
                    if (props.multiple) {
                        value?.forEach((ind: any) => {
                            industryId.push(ind.id)
                            industryObjs.push(ind);
                        })
                    } else {
                        industryId = value
                    }
                    if (props.setIndustry) {

                        if (typeof props.multipleObjs != "undefined" && props.multipleObjs) {
                            props.setIndustry(industryObjs);

                        } else {
                            props.setIndustry(industryId)
                        }
                    }


                    if (props.setFieldValue) {

                        if (typeof props.multipleObjs != "undefined" && props.multipleObjs) {
                            // props.setIndustry(industryObjs);
                            props.setFieldValue('industryId', industryObjs)
                        } else {
                            props.setFieldValue('industryId', industryId)
                        }
                    }
                    event?.preventDefault();
                }}
                renderTags={(value, getTagProps) => renderTags({ chipData: value, getTagProps })}
                renderInput={params => <TextField {...params} placeholder={props?.label + ""} variant="outlined" color="secondary"
                    sx={{
                        '& .MuiInputBase-root': {
                            ...(props?.inputHeight && { height: 'auto !important' }),
                        }
                    }}
                />}
                renderOption={(renderOptionProps, option, state) =>
                    // [renderOptionProps, option, state.index] as React.ReactNode
                    <li {...renderOptionProps} key={option.id || state.index}>
                        {option.name || option.label || option.title || state.index}
                    </li>
                }
            />




        </div>

    )
}