import React from 'react';

import {
    MBox,
    MSkeleton, MLink,
} from 'src/app/material-ui';

function ListingSkeleton(props: any) {
    return (
        <>
            {[1, 2, 3].map((_, index: number) => (
                <MBox key={"box" + index} className={props.classes.projectList} pt={1.75} pl={3} pb={3} mb={2}>
                    <MBox display="flex" justifyContent="space-between" alignItems="center">
                        <MBox className={props.classes.listHeading} fontFamily="Poppins" fontSize={17}
                            color='#3a3a3a'>
                            <MLink
                                // href="javascript:void(0);" 
                                onClick={(e: any) => e?.preventDefault()}
                                color="inherit">
                                <MSkeleton width={100} height='100%' />
                            </MLink>
                        </MBox>
                    </MBox>
                    <MBox display="flex" justifyContent="space-between" alignItems="center">
                        <MBox flex="2">
                            <MBox fontFamily="Poppins" className={`${props.classes.captionHeading} textUppercase`}>
                                <MSkeleton width='35%' height='100%' />
                            </MBox>
                            <MBox mt={.5} fontSize={14} fontFamily="Poppins" color="#3a3a3a">
                                <MSkeleton width='35%' height='100%' />
                            </MBox>
                        </MBox>
                        <MBox flex="2" className={props.classes.col}>
                            <MBox fontFamily="Poppins" className={`${props.classes.captionHeading} textUppercase`}>
                                <MSkeleton width={80} height='100%' />
                            </MBox>
                            <MBox fontSize={14} fontFamily="Poppins" color="#3a3a3a">
                                <MBox display="inline-block" mt={.5} mr={1}>
                                    <MSkeleton width={100} height='100%' />
                                    &nbsp;
                                </MBox>
                            </MBox>
                        </MBox>
                        <MBox flex="1">
                            <MBox fontFamily="Poppins" className={`${props.classes.captionHeading} textUppercase`}>
                                <MSkeleton width={80} height='100%' />
                            </MBox>
                            <MBox mt={.5}>
                                <MSkeleton width={100} height='100%' />
                            </MBox>
                        </MBox>
                        <MBox flex="1" className={props.classes.colStatus} display="flex" alignItems="center"
                            justifyContent="space-around">
                            <MSkeleton width={80} height='100%' />
                        </MBox>
                        <MBox flex="1" display="flex" flexDirection="column" alignItems="center">
                            <MBox fontFamily="Poppins" className={`${props.classes.captionHeading} textUppercase`}>
                                <MSkeleton width={80} height='100%' />
                            </MBox>
                        </MBox>
                        <MBox flex="1" className={props.classes.colStatus} display="flex" alignItems="center"
                            justifyContent="space-around">
                            <MSkeleton variant='circle' width={20} height={20} />
                        </MBox>
                    </MBox>
                </MBox>
            ))}
        </>
    )

}
export default ListingSkeleton;