import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import Zoom from '@mui/material/Zoom';
import { gql } from 'apollo-boost';
import { FormattedDateParts, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import {
    MAvatar,
    MBox,
    MButton,
    MInputBase,
    MList,
    MListItem,
    MListItemAvatar,
    MListItemText,
    MListSubheader,
    MSkeleton
} from 'src/app/material-ui';
import { MAddIcon, MSearchIcon } from 'src/app/material-ui/icons';
import { selectedProjectId } from 'src/app/store';
import useStyles from './SidebarStyles';

const SELECTED_ORGANIZATION_PROJECTS_GQL = gql`
    query Organizations($id: ID){
        organizations(id: $id) {
            projects {
                id
                name
                createdAt
                projectId
            }
        }
    }`;

function Sidebar(props: any) {
    const classes = useStyles({});
    const dispatch = useDispatch();
    const selectedOrgId = useSelector((store: any) => store.orgs.selectedOrgId);
    const isOwner = useSelector((store: any) => store.orgs.isOwner);
    const isAdmin = useSelector((store: any) => store.orgs.isAdmin);
    const isProjectAdmin = useSelector((store: any) => store.orgs.isProjectAdmin);
    const refreshSideBarProjects = useSelector((store: any) => store.projects.refreshSideBarProjects);
    const [projectsData, setProjectsData]: any = useState();
    // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    // const organizations = useSelector((store: any) => store.orgs.organizations);



    const [getProjects, { loading: isLoadingQuoteData }] = useLazyQuery(
        SELECTED_ORGANIZATION_PROJECTS_GQL, {
            variables: { id: selectedOrgId },
            fetchPolicy: "no-cache",
            onCompleted: (data: any) => {
                setProjectsData(data && data.organizations[0]);
            }
        } as any);

    useEffect(() => {
        if (selectedOrgId) {
            getProjects();
        }
    }, [refreshSideBarProjects, getProjects, selectedOrgId]);


    const goToSearch = (query: string) => {
        if (query === "") {
            props.history.push('/projects');
        } else {
            props.history.push('/search/' + query);
        }
    }


    const goToDetail = (projectId: string) => {
        dispatch(selectedProjectId(projectId));
        props.history.push('/projects/file/' + projectId);
    }


    const projectsList = (
        <>
            {projectsData && projectsData.projects.slice(0, 5).map((project: any) => (
                <React.Fragment key={project.id}>
                    <MListItem button className={classes.listItem}
                        onClick={() => goToDetail(project.id)}>
                        <MListItemAvatar className={classes.avatarWrap}>
                            <MAvatar variant="circular">
                                {project.name && (project.name.split(' ').length > 1) ?
                                    `${project.name.split(' ')[0].charAt(0)}${project.name.split(' ')[1].charAt(0)}` :
                                    `${project.name.charAt(0)}${project.name.charAt(1)}`

                                }
                            </MAvatar>
                        </MListItemAvatar>
                        <MListItemText className={classes.listItemText}
                            primary={project.name}
                            secondary={
                                <FormattedDateParts
                                    value={project.createdAt}
                                    year="numeric"
                                    month="2-digit"
                                    day="2-digit"
                                >
                                    {parts => (
                                        <>
                                            {/*{JSON.stringify(parts)}*/}
                                            {parts[2].value}
                                            {parts[1].value}
                                            {parts[0].value}
                                            {parts[3].value}
                                            {parts[4].value}
                                        </>
                                    )}
                                </FormattedDateParts>
                            } />
                        {/*<FormattedDate value={project.Create project}  day="2-digit"  year="numeric" month="long"/>*/}


                    </MListItem>
                </React.Fragment>
            ))
            }
        </>
    );


    return (
        <Zoom in={props.drawerState} unmountOnExit>
            <MBox className={classes.sidebarWrap}>
                <MBox px={3} pb={3}>
                    <form>
                        {/* <TextField color="secondary"  size="small" id="outlined-search" label="Search field" type="search" variant="outlined" /> */}
                        <MBox className={classes.search}>
                            <div className={classes.searchIcon}>
                                <MSearchIcon />
                            </div>
                            <MInputBase
                                placeholder={"Search for projects…"}
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                onChange={(e: any) => goToSearch(e?.target?.value)}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </MBox>
                    </form>
                    <MBox>
                        <MListSubheader className={classes.subheader}>
                            <MBox display="flex" justifyContent="space-between" pt={2}>
                                <MBox fontSize={14} color="#6b778c" lineHeight={2}>
                                    <FormattedMessage id="recent.projects" />
                                </MBox>
                                {(projectsData && projectsData.projects.length > 1) &&
                                    <NavLink className={classes.viewAllLink} to="/projects">
                                        <FormattedMessage id="view.job" />
                                    </NavLink>
                                }
                            </MBox>
                        </MListSubheader>

                        {isLoadingQuoteData &&
                            <MBox key="isLoadingQuoteData" display="flex" flexWrap='wrap' mt={1.25}>
                                {[0, 1, 2, 3, 4].map((_, index: number) => (
                                    <MBox key={"box" + index} display="flex" mb={2}>
                                        <MSkeleton variant="circular" width={40} height={40} />
                                        <MBox ml={2}>
                                            <MBox mb={.75}>
                                                <MSkeleton width={139} height={19} />
                                            </MBox>
                                            <MSkeleton width={71} height={16} />
                                        </MBox>
                                    </MBox>
                                ))}
                            </MBox>
                        }

                        {!isLoadingQuoteData
                            ?
                            (projectsData && projectsData.projects.length > 0
                                ?
                                projectsList
                                :
                                <p style={{ marginLeft: '10px' }}>
                                    <FormattedMessage id="no.projects.yet" />
                                </p>
                            )
                            :
                            ''
                        }

                        {(isOwner || isAdmin || isProjectAdmin) && selectedOrgId !== 0 &&
                            <MBox display="flex" justifyContent="center" pt={3}>
                                {window.location.pathname.split("/").pop() !== 'create-project'
                                    ?
                                    isLoadingQuoteData
                                        ?
                                        <MSkeleton width={'100%'} height={36} />
                                        :
                                        <MButton variant="contained" color="primary" className={classes.createProjectBtn}
                                            startIcon={<MAddIcon />} onClick={() => props.history.push('/create-project')}>
                                            <FormattedMessage id="project.create" />
                                        </MButton>
                                    :
                                    ''
                                }
                            </MBox>
                        }
                    </MBox>
                </MBox>
                <MBox>
                    {selectedOrgId !== 0 &&
                        <>
                            <MBox className={classes.generalLinks} px={2}>
                                <MList className={classes.sideBarLinks}>
                                </MList>
                            </MBox>
                        </>
                    }
                </MBox>
            </MBox>
        </Zoom>
    )
}

export default withRouter(Sidebar);