import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputWidth: {
      // width: 493,
      '& .MuiInputBase-colorSecondary.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '2px solid' + theme.palette.secondary.main + ' !important',
      },
    },


    sizeMd: {
      '& .MuiAutocomplete-inputRoot': {
        minHeight: 40,
      }
    },

  }));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;