import React from 'react';
import Tooltip from '@mui/material/Tooltip';

export function MTooltip(props: any) {
  // const {children, ...restProps } = props;
  return (
    <Tooltip {...props} />
  )
}



