import { makeStyles } from '@mui/styles';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles(() => ({
    fileUploadDrawer: {
        "& .MuiPaper-root.MuiDrawer-paper": {
            minWidth: "85%",
            maxWidth: "85%",
        },
        "&.FullWidth .MuiPaper-root.MuiDrawer-paper": {
            transform: 'translateX(-18%) !important',
        },
        '&.fileUploadDrawer': {
            "& .MuiPaper-root.MuiDrawer-paper": {
                transform: 'translateX(-18%) !important'
            }
        },
    },

    wrapper: {
        width: '100%',
        maxWidth: 730,
        margin: '50px auto 0px auto',
    },
    tranSlip: {
        boxShadow: '0px 1px 0px #F0ECED',
        border: '1px solid #E8E8E8',
        borderRadius: 5,
        padding: '16px 24px'
    },
    slipThumbnail: {
        display: 'flex',
        justifyContent: 'center',
        border: ' 1px solid #DCDDDE',
        maxWidth: 290,
        minHeight: 220,
        padding: 17,
        backgroundColor: '#fff',
        borderRadius: 5,
        margin: '20px 0px',
        '& img': {
            width: '100%'
        },
        '& .MuiIconButton-root': {
            color: '#A7ADBB',
        },
        '&:hover': {
            '& .viewImg': {
                display: 'flex'
            },
            '& .btnDownloads': {
                display: 'flex'
            },
            '& .imgHoverBox': {
                display: 'block',
            }
        }
    },

    transferDetails: {

        boxShadow: '0px 1px 0px #F0ECED',
        border: '1px solid #E8E8E8',
        borderRadius: 5,

        marginTop: '40px',
        '& .MuiGrid-container': {
            flexDirection: 'column',

            '& .MuiGrid-grid-xs-5': {
                fontFamily: "Poppins",
                color: '#3A3A3A'
            },
            '& .MuiGrid-grid-xs-8': {
                fontFamily: "Poppins",
                color: '#758094 !important',
                textTransform: 'UpperCase'
            },
            '& .MuiBox-root': {
                padding: '16px 24px',
                borderStyle: 'solid',
                borderColor: '#E8E8E8',
                borderWidth: '0px 0px 1px 0px',
                display: 'flex',
                alignItems: 'center',
                gap: 15,
            }


        }
    },
    header: {
        background: '#F7F8FC',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '16px 24px'
    },
    status: {
        background: '#FDFDFD',
        border: '1px solid #E8E8E8',
        borderRadius: 5,
        padding: 30
    },
    btnDownload: {
        position: 'absolute',
        left: 70,
        top: 0,
        zIndex: 2,
        height: '100%',
        display: 'none',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '& .icnButton': {
            backgroundColor: 'white',
            borderRadius: 1000,
        },
    },
    viewImg: {
        position: 'absolute',
        left: 130,
        top: 0,
        zIndex: 2,
        height: '100%',
        display: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        '& .icnCover': {
            backgroundColor: 'white',
            borderRadius: 1000,
        },
    },
    imgHoverBox: {
        width: '100%',
        height: "100%",
        position: 'absolute',
        background: 'rgba(208, 208, 208, 0.75)',
        top: 0,
        zIndex: 1,
        display: 'none',
    },
    cardText: {
        wordBreak: 'break-all',
    }
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;