// import { GET_NOTIFICATION, GET_NOTIFICATION_SUCCESS } from "./NotificationActionType";


import { RESET_STORE } from '../resetStateAction';
import { GET_NOTIFICATION, GET_NOTIFICATION_SUCCESS, SET_NOTIFICATION_DATA } from './NotificationActionType';


interface IAction {
    type: string,
    payload: any,
    response: any,
    action: any,
    notificationList: any
    notificationData: any

}

interface IState {
    notificationLoading: boolean,
    notificationList: any
    notificationData: any

}

const initialState: IState = {
    notificationLoading: false,
    notificationList: [],
    notificationData: []

};


const notificationReducer = (state = initialState, action: IAction) => {

    switch (action.type) {

        case GET_NOTIFICATION:
            return {
                ...state,
                notificationLoading: true
            }
        case GET_NOTIFICATION_SUCCESS:
            return {

                ...state,
                notificationList: action.response,
                notificationLoading: false
            }

        case SET_NOTIFICATION_DATA:
            return {
                ...state,
                notificationData: action.payload,
                notificationLoading: false
            }
        case RESET_STORE:
            return initialState;
        default:
            return state;
    }
};

export default notificationReducer;