import React, { Fragment } from 'react'

export function MArrowRightIcon() {
    return (
        <Fragment>

            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.4996 12.7051V9.70508H3.80961L3.78711 8.19758H10.4996V5.20508L14.2496 8.95508L10.4996 12.7051Z" fill="#A7ADBB" />
            </svg>

        </Fragment>
    )
}
