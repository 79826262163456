import React, { Fragment } from 'react'

export function MSelectManagerIcon() {
    return (
        <Fragment>
            <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0 15.75C0 12.7463 6.00654 11.25 9.01544 11.25C9.8669 11.25 10.9584 11.3698 12.0859 11.6097V15.4587C12.0859 16.2058 12.6916 16.8115 13.4387 16.8115H18.0309V18H0V15.75ZM12.2029 7.68198C11.3575 8.52589 10.211 9 9.01544 9C7.81992 9 6.67336 8.52589 5.828 7.68198C4.98264 6.83807 4.50772 5.69347 4.50772 4.5C4.50772 3.30653 4.98264 2.16193 5.828 1.31802C6.67336 0.474106 7.81992 0 9.01544 0C10.211 0 11.3575 0.474106 12.2029 1.31802C13.0482 2.16193 13.5232 3.30653 13.5232 4.5C13.5232 5.69347 13.0482 6.83807 12.2029 7.68198Z" fill="#A0A5B9" />
                <path d="M20.2547 12.8649C20.2547 13.7274 20.2547 14.591 20.2547 15.4535C20.2219 15.5382 20.1993 15.6299 20.154 15.7066C20.0104 15.946 19.7931 16.0445 19.5193 16.0445C17.2668 16.0422 15.8978 16.0433 13.6452 16.0433C13.6181 16.0433 13.592 16.0433 13.5649 16.0422C13.2051 16.0273 12.9109 15.7341 12.9087 15.371C12.9042 14.5578 12.9076 13.7434 12.9064 12.9302C12.9064 12.8558 12.9324 12.7974 12.9992 12.763C13.0648 12.7275 13.1282 12.7378 13.1881 12.7813C13.325 12.8833 13.4789 12.9199 13.6486 12.9199C14.5107 12.9176 14.9316 12.9188 15.7937 12.9188C15.8254 12.9188 15.8559 12.9188 15.8933 12.9188C15.8933 13.0871 15.8921 13.244 15.8933 13.401C15.8944 13.5499 15.9578 13.614 16.1037 13.614C16.4114 13.614 16.7203 13.614 17.028 13.614C17.2136 13.614 17.2679 13.5602 17.2679 13.3723C17.2679 13.2234 17.2679 13.0757 17.2679 12.9176C17.3086 12.9176 17.3403 12.9176 17.372 12.9176C18.2375 12.9176 18.6606 12.9165 19.5261 12.9188C19.6777 12.9188 19.8169 12.8867 19.9447 12.8019C20.0873 12.7046 20.1642 12.7218 20.2547 12.8649Z" fill="#A0A5B9" />
                <path d="M20.2574 10.7323C20.2574 11.1446 20.2574 11.5569 20.2574 11.9693C20.254 11.9784 20.2483 11.9887 20.2461 11.9979C20.142 12.3885 19.9055 12.5717 19.5073 12.5717C18.6474 12.5717 18.2299 12.5717 17.3701 12.5717C17.3384 12.5717 17.3079 12.5717 17.2705 12.5717C17.2705 12.5133 17.2717 12.4641 17.2705 12.416C17.266 12.2911 17.2027 12.227 17.0782 12.2258C16.749 12.2247 16.4186 12.2247 16.0894 12.2258C15.9615 12.2258 15.8993 12.2911 15.8959 12.4228C15.8948 12.471 15.8959 12.5179 15.8959 12.5717C15.8665 12.5717 15.8484 12.5717 15.8303 12.5717C14.941 12.5717 14.4941 12.5729 13.6037 12.5706C13.2066 12.5695 12.9113 12.2659 12.9091 11.8616C12.9079 11.5386 12.9181 11.2156 12.9068 10.8938C12.891 10.4643 13.2179 10.1275 13.649 10.139C14.1683 10.1527 14.2464 10.1424 14.7657 10.1424C14.7973 10.1424 14.8279 10.1424 14.8664 10.1424C14.8664 10.0313 14.8664 9.93166 14.8664 9.83087C14.8686 9.39448 15.1582 9.10126 15.5882 9.10012C16.2523 9.09897 16.9175 9.10012 17.5817 9.10012C17.6348 9.10012 17.6891 9.10126 17.7412 9.11157C18.0738 9.17915 18.2922 9.45404 18.299 9.81025C18.3012 9.91791 18.299 10.0256 18.299 10.1413C18.3408 10.1413 18.3725 10.1413 18.4042 10.1413C18.9506 10.1413 19.0547 10.1378 19.6012 10.1447C19.8919 10.1481 20.1408 10.3497 20.2257 10.6326C20.2359 10.667 20.2461 10.7002 20.2574 10.7323ZM15.5554 10.1355C16.2444 10.1355 16.9266 10.1355 17.6066 10.1355C17.6066 10.0187 17.6066 9.90875 17.6066 9.7988C16.9198 9.7988 16.2387 9.7988 15.5554 9.7988C15.5554 9.91333 15.5554 10.0221 15.5554 10.1355Z" fill="#A0A5B9" />
            </svg>
        </Fragment>
    )
}
