import { combineReducers } from "redux";
import authReducer from "./auth/AuthReducer";
import breadcrumsReducer from "./breadcrums-data/BreadcrumsReducer";
import CustomerReducer from "./customers/CustomerReducer";
import inviteAdminReducer from "./invitedAdmins/AdminReducer";
import AssignedJobFilesReducer from "./jobs/AssignedJobFilesReducer";
import JobFilesReducer from "./jobs/JobFilesReducer";
import JobLanguagesReducer from "./jobs/JobLanguagesReducer";
import JobReducer from "./jobs/JobReducer";
import linguistProfileDataReducer from "./jobs/LinguistProfileDataReducer";
import profileDataReducer from "./jobs/ProfileDataReducer";
import findNewSuppliersReducer from "./newSupplier/FindNewSuppliersReducer";
import notificationReducer from "./notification/NotificationReducer";
import organizationsReducer from "./organizations/OrganizationsReducer";
import peopleReducer from "./peoples/PeopleReducer";
import projectsReducer from "./projects/ProjectsReducer";
import projectTaskReducer from "./projectTask/ProjectTaskReducer";
import acceptRejectRateReducer from "./rates/AcceptRejectRateReducer";
import ratesRequestReducer from "./rates/RateRequestReducer";
import releaseNotesReducer from "./releaseNotes/ReleaseNotesReducer";
import showAlertMessage from "./show-alert-message/ShowAlertMessage";
import showSideBar from "./show-sidebar/ShowSideBarReducer";
import sourceLanguageReducer from "./sourceLanguage/SourceLanguageReducer";
import suppliersReducer from "./suppliers/SuppliersReducer";
import showUserRole from "./user-roles/UserRolesHandler";
const rootReducer = combineReducers({
    auth: authReducer,
    orgs: organizationsReducer,
    sideBar: showSideBar,
    userRole: showUserRole,
    alert: showAlertMessage,
    projects: projectsReducer,
    jobs: JobReducer,
    jobFiles: JobFilesReducer,
    profile: profileDataReducer,
    assignedjobFiles: AssignedJobFilesReducer,
    jobLanguages: JobLanguagesReducer,
    linguistProfile: linguistProfileDataReducer,
    breadcrumsData: breadcrumsReducer,
    people: peopleReducer,
    notification: notificationReducer,
    suppliers: suppliersReducer,
    findSupplier: findNewSuppliersReducer,
    adminReducer: inviteAdminReducer,
    projectTaskReducer: projectTaskReducer,
    releaseNotesReducer: releaseNotesReducer,
    customers: CustomerReducer,
    ratesRequest:ratesRequestReducer,
    sourceLanguages:sourceLanguageReducer,
    acceptRejectRates:acceptRejectRateReducer

})

export default rootReducer;