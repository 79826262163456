import React, { Fragment } from 'react'

export function MPioneer() {
    return (
        <Fragment>

            <svg width="85" height="30" viewBox="0 0 85 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_6490_27188)">
                    <path d="M53.2469 1.12706C53.2469 1.12706 55.8176 2.87054 53.2469 3.19548C53.2469 3.19548 43.6419 4.2846 36.4927 10.8238C36.4927 10.8238 25.7712 19.9737 21.7487 29.9976C21.7487 29.9976 20.4093 29.9976 20.8573 28.0367C21.303 26.0759 23.9839 17.4683 35.9345 6.89982C35.9345 6.89982 47.4393 -3.34146 53.2469 1.12482V1.12706ZM22.7572 2.43579C22.8674 4.83364 24.9901 13.9836 24.9901 13.9836C25.7712 15.8368 26.442 13.9836 26.442 13.9836C29.0104 6.46507 23.7634 2.10861 23.7634 2.10861C22.4218 1.34443 22.7572 2.43355 22.7572 2.43355" fill="#F64D25" />
                    <path d="M5.64676 15.7587C5.00811 15.7587 4.62446 15.7071 4.21554 15.631L5.185 10.5753C5.49284 10.5238 6.00513 10.4745 6.69662 10.4745C8.13013 10.4745 9.30405 11.0078 9.30405 12.6348C9.30405 14.3626 7.84527 15.7609 5.64446 15.7609L5.64676 15.7587ZM6.41635 8.65703C5.03108 8.65703 3.60216 8.78476 2.68554 9.01782L0 23.0172H3.00716L3.98811 17.8921C4.30743 17.9123 4.73473 17.975 5.24703 17.975C6.99527 17.975 8.67919 17.5515 9.85311 16.6394C10.9627 15.7923 11.7943 14.4163 11.7943 12.637C11.7943 11.261 11.2177 10.2436 10.2368 9.60944C9.19378 8.86768 7.72122 8.65479 6.41865 8.65479" fill="#363636" />
                    <path d="M15.0197 21.294C14.0318 21.294 13.5632 20.5007 13.5632 19.3466C13.5632 17.1571 15.2402 14.4164 17.5329 14.4164C17.9028 14.4164 18.1991 14.4635 18.4702 14.5128L17.9786 17.2311C17.4341 20.1175 16.081 21.294 15.0197 21.294ZM18.4426 12.3682C13.0853 12.3682 10.6318 16.198 10.6318 19.7903C10.6318 22.0111 11.9712 23.4924 13.9331 23.4924C15.2058 23.4924 16.6117 22.9053 17.6386 21.1416H17.6822C17.6386 21.9035 17.572 22.6431 17.549 23.2526H20.4941C20.4505 21.9237 20.6733 19.8351 20.9398 18.4636L22.0356 12.8903C21.142 12.6057 19.759 12.3682 18.4426 12.3682Z" fill="#363636" />
                    <path d="M38.5764 21.3791C37.2991 21.3791 36.5433 20.4783 36.5433 19.127C36.5433 17.3252 37.6391 14.4814 39.8008 14.4814C41.2872 14.4814 41.8064 15.5974 41.8064 16.6865C41.8064 18.6541 40.6347 21.3791 38.6016 21.3791H38.5764ZM40.1385 12.3682C36.2561 12.3682 33.6211 15.4159 33.6211 19.0956C33.6211 21.7287 35.475 23.4946 38.2639 23.4946H38.2869C42.0361 23.4946 44.7814 20.6419 44.7814 16.7672C44.7814 14.329 43.086 12.3704 40.1408 12.3704" fill="#363636" />
                    <path d="M53.3291 12.3682C51.9002 12.3682 50.5402 13.0001 49.511 14.3716H49.4674L49.6672 12.6079H46.8324C46.6991 13.5671 46.5199 14.7212 46.2511 16.0703L44.8682 23.2548H48.0821L48.9757 18.5309C49.4444 16.1801 50.6482 14.8937 51.698 14.8937C52.5251 14.8937 52.7915 15.4159 52.7915 16.1801C52.7915 16.5722 52.7456 16.9868 52.679 17.3543L51.5418 23.2548H54.7557L55.9618 17.0697C56.0514 16.5498 56.1387 15.7655 56.1387 15.2859C56.1387 13.4349 55.1118 12.3682 53.3268 12.3682" fill="#363636" />
                    <path d="M59.9871 17.0877C59.8998 17.0877 59.8125 17.0877 59.7229 17.0877C59.994 15.8977 61.0208 14.4209 62.8334 14.4209C63.7248 14.4209 64.2669 14.8713 64.2669 15.5033V15.5302C64.2669 16.6843 62.8403 17.0877 59.9871 17.0877V17.0877ZM63.0907 12.3682C59.1394 12.3682 56.7295 15.7408 56.7295 18.9858C56.7295 21.7713 58.3353 23.4924 61.4619 23.4924C62.6657 23.4924 64.2508 23.2728 65.5235 22.6431L65.3007 20.3797C64.43 20.792 63.4261 21.0542 62.243 21.0542C61.3057 21.0542 60.5683 20.7718 60.1456 20.2273C59.9204 19.9225 59.8102 19.4452 59.8102 19.1382C60.0261 19.1426 60.2352 19.1471 60.4442 19.1471C64.4438 19.1471 66.8858 18.1342 66.8858 15.5683C66.8858 13.5895 65.303 12.3682 63.093 12.3682" fill="#363636" />
                    <path d="M70.2097 17.0876C70.1224 17.0876 70.0351 17.0876 69.9455 17.0876C70.2166 15.8977 71.2435 14.4231 73.0561 14.4231C73.9474 14.4231 74.4896 14.8735 74.4896 15.5055V15.5324C74.4896 16.6865 73.063 17.0899 70.2097 17.0899V17.0876ZM73.3134 12.3704C69.362 12.3704 66.9521 15.743 66.9521 18.988C66.9521 21.7735 68.558 23.4946 71.6823 23.4946C72.8884 23.4946 74.4735 23.275 75.7439 22.6453L75.5211 20.3819C74.6504 20.7942 73.6465 21.0564 72.4634 21.0564C71.5261 21.0564 70.7886 20.774 70.3659 20.2295C70.1408 19.9247 70.0305 19.4474 70.0305 19.1404C70.2465 19.1448 70.4578 19.1493 70.6646 19.1493C74.6642 19.1493 77.1062 18.1364 77.1062 15.5705C77.1062 13.5917 75.5211 12.3704 73.3134 12.3704" fill="#363636" />
                    <path d="M84.3976 12.3457C82.9021 12.3457 82.0245 13.3676 81.2664 14.8063H81.1768C81.2664 14.0444 81.3307 13.3251 81.3767 12.6057H78.6084C78.6682 13.706 78.5005 15.9784 78.211 17.5919L76.959 23.2526H79.6399L80.336 19.2905C80.738 17.1997 81.8132 15.0708 83.844 15.0708C84.0232 15.0708 84.2024 15.0708 84.3356 15.0954L85.0018 12.3659C84.8226 12.3659 84.6228 12.3435 84.3999 12.3435" fill="#363636" />
                </g>
                <defs>
                    <clipPath id="clip0_6490_27188">
                        <rect width="85" height="30" fill="white" />
                    </clipPath>
                </defs>
            </svg>

        </Fragment>
    )
}
