import React, { Fragment } from 'react'

export function MHwallet() {
    return (
        <Fragment>

            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.6667 7C9.07667 7 7 9.1 7 11.6667V44.3333C7 45.571 7.49167 46.758 8.36684 47.6332C9.24201 48.5083 10.429 49 11.6667 49H44.3333C45.571 49 46.758 48.5083 47.6332 47.6332C48.5083 46.758 49 45.571 49 44.3333V39.0133C50.3767 38.1967 51.3333 36.7267 51.3333 35V21C51.3333 19.2733 50.3767 17.8033 49 16.9867V11.6667C49 10.429 48.5083 9.242 47.6332 8.36683C46.758 7.49167 45.571 7 44.3333 7H11.6667ZM11.6667 11.6667H44.3333V16.3333H30.3333C29.0957 16.3333 27.9087 16.825 27.0335 17.7002C26.1583 18.5753 25.6667 19.7623 25.6667 21V35C25.6667 36.2377 26.1583 37.4247 27.0335 38.2998C27.9087 39.175 29.0957 39.6667 30.3333 39.6667H44.3333V44.3333H11.6667V11.6667ZM30.3333 21H46.6667V35H30.3333V21ZM37.3333 24.5C36.4051 24.5 35.5148 24.8687 34.8585 25.5251C34.2021 26.1815 33.8333 27.0717 33.8333 28C33.8333 28.9283 34.2021 29.8185 34.8585 30.4749C35.5148 31.1313 36.4051 31.5 37.3333 31.5C38.2616 31.5 39.1518 31.1313 39.8082 30.4749C40.4646 29.8185 40.8333 28.9283 40.8333 28C40.8333 27.0717 40.4646 26.1815 39.8082 25.5251C39.1518 24.8687 38.2616 24.5 37.3333 24.5Z" fill="#A2A4B9" />
            </svg>

        </Fragment>
    )
}
