// import axios from 'axios';

// axios.interceptors.request.use(config => {
//   // config.headers = {
//   //   'content-type' : 'application/json'
//   // };
//   return config;
// }, error => {
//   return Promise.reject(error);
// });

// axios.interceptors.response.use(res => {
//   return res
// });

// added to remove warning start
import React from 'react'

function AxiosInterceptors() {
    return (
        <></>
    )
}

export default AxiosInterceptors
// added to remove warning end
