import {
    REFRESH_SIDEBAR_PROJECTS,
    SELECTED_PROJECT_ID,
    SET_USER_ROLES,
    SET_PROJECT_EVENT_SOURCE,
    SET_PROJECT_FILE_EVENT_SOURCE,
    SELECTED_PROJECT_NAME, GET_PROJECT_SUCCESS, GET_PROJECT_FETCH, GET_RECENT_PROJECT_SUCCESS, GET_RECENT_PROJECT_FETCH
} from "./ProjectsActionTypes";
import { RESET_STORE } from '../resetStateAction';

interface IAction {
    type: string,
    payload: any,
    response: any,
    action: any,
    projectList: any,
    projectLoading: boolean,
    recentProjectList: any,
    recentProjectLoading: boolean
}

interface IState {
    refreshSideBarProjects: boolean,
    selectedProjectId: string,
    selectedProjectName: string,
    userRoles: [],
    projectEventSource: any,
    projectFileEventSource: any,
    projectList: any,
    recentProjectList: any,
    projectLoading: boolean
    recentProjectLoading: boolean
}

const initialState: IState = {
    refreshSideBarProjects: false,
    selectedProjectId: '0',
    selectedProjectName: '',
    userRoles: [],
    projectEventSource: false,
    projectFileEventSource: false,
    projectList: [],
    recentProjectList: [],
    projectLoading: false,
    recentProjectLoading: false
};


const projectsReducer = (state = initialState, action: IAction) => {

    switch (action.type) {
        case REFRESH_SIDEBAR_PROJECTS:
            return {
                ...state,
                refreshSideBarProjects: !state.refreshSideBarProjects
            };
        case SELECTED_PROJECT_ID:
            return {
                ...state,
                selectedProjectId: action.payload
            }
        case SELECTED_PROJECT_NAME:
            return {
                ...state,
                selectedProjectName: action.payload
            }
        case SET_USER_ROLES:
            return {
                ...state,
                userRoles: action.payload
            }
        case SET_PROJECT_EVENT_SOURCE:
            return {
                ...state,
                projectEventSource: action.payload
            }
        case SET_PROJECT_FILE_EVENT_SOURCE:
            return {
                ...state,
                projectFileEventSource: action.payload
            }
        case GET_PROJECT_SUCCESS:

            return {
                ...state,
                projectList: action.response,
                projectLoading: false
            }
        case GET_PROJECT_FETCH:
            return {
                ...state,
                projectLoading: true
            }
        case GET_RECENT_PROJECT_FETCH:
            return {
                ...state,
                recentProjectLoading: true
            }
        case GET_RECENT_PROJECT_SUCCESS:
            return {
                ...state,
                recentProjectList: action.response,
                recentProjectLoading: false
            }
        case RESET_STORE:
            return initialState;
        default:
            return state;
    }
};

export default projectsReducer;