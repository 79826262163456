import { put, takeEvery } from 'redux-saga/effects';
import { gql } from 'apollo-boost';
import { client } from "src/app/hooks";
import { acceptRejectRatesFailure, acceptRejectRatesSuccess } from './RateRequestActions';
import { ACCEPT_REJECT_RATES_REQUEST } from './RateRequestActionTypes';



const ACCEPT_OR_REJECT_RATE = gql`
mutation acceptOrRejectRate($rateId: String!,$status:String!,$orgId: Int!){
    acceptOrRejectRate(rateId: $rateId,status: $status,orgId: $orgId) 
}
`;



function* acceptRejectRates(action: any): any {
    try {
        const variables = action.payload || {};
        const response = yield client.mutate({ mutation: ACCEPT_OR_REJECT_RATE, variables: variables, fetchPolicy: "no-cache" });

        yield put(acceptRejectRatesSuccess(response.data));

    } catch (error) {
        yield put(acceptRejectRatesFailure(error));
    }
}

export default function* acceptRejectRateSaga() {
    yield takeEvery(ACCEPT_REJECT_RATES_REQUEST, acceptRejectRates);

}