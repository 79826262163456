import React, { useEffect, useState } from 'react';
import useStyles from './ServicesSelectStyles'
import { useSelector } from 'react-redux';
import { Autocomplete } from "@mui/material";
import { MKeyboardArrowDownRoundedIcon } from "src/app/material-ui/icons";
import TextField from '@mui/material/TextField';
import clsx from 'clsx';

export function ServicesSelect(props: any) {
  const classes: any = useStyles({});
  const organizations = useSelector((store: any) => store.orgs.organizations)
  const [services, setServices] = useState(organizations && organizations[0] && organizations[0].services);
  const [selectedVal, setSelectedVal]: any = useState([]);

  useEffect(() => {
    if (props.services) {
      setServices(props.services);
    }
  }, [props.services]);

  useEffect(() => {
    if (props.value) {
      let selectVal: any = [];
      props.value.forEach((id: any) => {
        selectVal = [...selectVal, services.find((el: any) => el.id === Number(id))];
      });
      setSelectedVal(selectVal)
    }
  }, [props.value, services])


  return (
    <div>
      {/*{organizations && organizations[0].services && (organizations[0].services.length > 0) &&*/}
      {/* {services && (services.length > 0) && */}
      <Autocomplete
        {...props}
        className={clsx(classes.inputWidth, classes[props.size ? props.size : 'sizeMd'])}
        sx={{ width: props.width ? props.width : 493, backgroundColor: '#fff' }}
        limitTags={1}
        defaultValue={props.defaultValue && props.defaultValue}
        value={selectedVal}
        id="services"
        options={services}
        getOptionLabel={(option: any) => option.name}
        size="small"
        popupIcon={<MKeyboardArrowDownRoundedIcon />}
        renderInput={(params: any) => <TextField  {...params} color="secondary" label={props.label} variant="outlined" />}
        onChange={(event: any, value: any) => {
          let selectedValue: any = [];
          setSelectedVal(value)
          if (value) {
            if (props.multiple) {
              value.forEach((service: any) => {
                selectedValue.push(service.id)
              });
            } else {
              selectedValue = value
            }
            if (props.setFieldValue) {
              props.setFieldValue(props.name, selectedValue)
            } else {
              props.setSelectedServices(selectedValue);
            }
          }
        }}
      />
      {/* } */}
    </div>
  );
}


