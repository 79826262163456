import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
const useStyles = makeStyles((theme: Theme) => ({
    fileUploadDrawer: {
        "& .MuiPaper-root.MuiDrawer-paper": {
            minWidth: "85%",
            maxWidth: "85%",

        },
        '&.fullWidth': {
            "& .MuiPaper-root.MuiDrawer-paper": {
                transform: 'translateX(-18%) !important'
            }
        },
        '& .MuiToolbar-gutters': {
            [theme.breakpoints.up('sm')]: {
                paddingRight: 36,
            }
        },
        '& .MuiPaper-root': {
            paddingRight: '0px !important'
        }

    },
    paymentContainer: {
        width: '100%',
        maxWidth: 730,
        margin: '50px auto 50px auto',

    },
    paymentOptions: {
        gap: 5,

    },
    paymentMode: {
        flex: ' 1 1 30%',
        display: 'flex',
        border: '2px solid #eeeeee',
        alignItems: 'center',
        backgroundColor: '#eeeeee',
        boxShadow: '0px 0px 30px rgb(214 215 226 / 3%)',
        borderRadius: 4,
        position: 'relative',
        height: '100%',
        minHeight: 55,
        '&:hover': {
            backgroundColor: '#e2e2e2',
            cursor: 'pointer'
        },
        '& svg': {
            width: 30,
            height: 30
        }

    },
    disabled: {
        flex: ' 1 1 30%',
        display: 'flex',
        border: '2px solid #eeeeee',
        alignItems: 'center',
        backgroundColor: '#eeeeee',
        boxShadow: '0px 0px 30px rgb(214 215 226 / 3%)',
        borderRadius: 4,
        position: 'relative',
        height: '100%',
        minHeight: 51,

    }
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;