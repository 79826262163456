
import { RESET_STORE } from '../resetStateAction';
import { GET_INVITE_ADMIN_FETCH, GET_INVITE_ADMIN_SUCCESS } from './AdminActionType';


interface IAction {
    type: string,
    payload: any,
    response: any,
    action: any,
    adminList: any

}

interface IState {
    adminDataLoading: boolean,
    invitedAdmin: any

}

const initialState: IState = {
    adminDataLoading: false,
    invitedAdmin: []

};


const inviteAdminReducer = (state = initialState, action: IAction) => {

    switch (action.type) {

        case GET_INVITE_ADMIN_FETCH:
            return {
                ...state,
                adminDataLoading: true
            }
        case GET_INVITE_ADMIN_SUCCESS:
            return {

                ...state,
                invitedAdmin: action.response,
                adminDataLoading: false
            }

        case RESET_STORE:
            return initialState;
        default:
            return state;
    }
};

export default inviteAdminReducer;