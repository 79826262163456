import { GET_ALL_ORGANIZATIONS, SELECT_ORGANIZATION, SELECT_ORGANIZATION_LOADING } from "./OrganizationsActionTypes";
import { RESET_STORE } from '../resetStateAction';

interface IAction {
    type: string,
    payload: any
}

interface IState {
    organizations: any,
    selectedOrgId: any,
    selectedOrgLoading: any,
    isOwner: boolean,
    isAdmin: boolean,
    isProjectAdmin: boolean,
    isVendorAdmin: boolean,
    walletBalance: number
}

const initialState: IState = {
    organizations: null,
    selectedOrgId: null,
    selectedOrgLoading: false,
    isOwner: false,
    isAdmin: false,
    isProjectAdmin: false,
    isVendorAdmin: false,
    walletBalance: 0
};

const checkSelectedOrgOwner = (selectedOrgId: number, organizations: any) => {
    // const selectedOrg = organizations.filter((org: any) => org !== selectedOrgId);
    const selectedOrg = organizations && organizations.find((org: any) => {
        return org.id === selectedOrgId
    });
    return (selectedOrg) ? selectedOrg.isOwner : false;
};

const checkSelectedOrgAdmin = (selectedOrgId: number, organizations: any) => {
    // const selectedOrg = organizations.filter((org: any) => org !== selectedOrgId);
    const selectedOrg = organizations && organizations.find((org: any) => {
        return org.id === selectedOrgId
    });
    return (selectedOrg) ? selectedOrg.isAdmin : false;
};

const checkSelectedOrgProjectAdmin = (selectedOrgId: number, organizations: any) => {
    // const selectedOrg = organizations.filter((org: any) => org !== selectedOrgId);
    const selectedOrg = organizations && organizations.find((org: any) => {
        return org.id === selectedOrgId
    });
    return (selectedOrg) ? selectedOrg.isProjectAdmin : false;
};

const checkSelectedOrgVendorAdmin = (selectedOrgId: number, organizations: any) => {
    // const selectedOrg = organizations.filter((org: any) => org !== selectedOrgId);
    const selectedOrg = organizations && organizations.find((org: any) => {
        return org.id === selectedOrgId
    });
    return (selectedOrg) ? selectedOrg.isVendorAdmin : false;
};

const organizationsReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case GET_ALL_ORGANIZATIONS:

            return {
                ...state,

                organizations: action.payload,



            };
        case SELECT_ORGANIZATION:
            return {
                ...state,
                isOwner: checkSelectedOrgOwner(action.payload, state.organizations),
                isAdmin: checkSelectedOrgAdmin(action.payload, state.organizations),
                isProjectAdmin: checkSelectedOrgProjectAdmin(action.payload, state.organizations),
                isVendorAdmin: checkSelectedOrgVendorAdmin(action.payload, state.organizations),
                selectedOrgId: action.payload
            };
        case SELECT_ORGANIZATION_LOADING:
            return {
                ...state,
                selectedOrgLoading: action.payload,
            };
        case RESET_STORE:
            return initialState;
        default:
            return state;
    }
};

export default organizationsReducer;