import React from 'react';
import useStyles from './ModalStyle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { MBox, MButton } from 'src/app/material-ui';
import { useDispatch } from 'react-redux';
import { showModal } from 'src/app/store';
import InfoIcon from '@mui/icons-material/Info';
import { FormattedMessage } from 'react-intl';


export default function Modal(props: any) {
    const classes = useStyles({});
    const dispatch = useDispatch();

    return (
        <div className={classes.filesWrapper}>
            <Dialog className={classes.dialogWrapper} open={props.modalOpen} onClose={() => dispatch(showModal(false))}>
                <DialogContent className={classes.dialogContent}>
                    <MBox p={3} textAlign="center" height="100%">
                        <MBox fontSize={30} fontFamily="Poppins" textAlign="center">
                            <InfoIcon sx={{ fontSize: "3.5rem", color: "#ff9800" }} />
                        </MBox>
                        <MBox mt={1.5} mb={3} fontSize={16} color="#637381">
                            <FormattedMessage id="complete.profile.access.menu" />
                        </MBox>
                    </MBox>
                </DialogContent>
                <DialogActions className={classes.dialogBtn}>
                    <MBox pt={1} pb={1} pr={2}>
                        <MButton variant="contained" color="primary" onClick={() => dispatch(showModal(false))}>
                            <FormattedMessage id="close" />
                        </MButton>
                    </MBox>
                </DialogActions>
            </Dialog>
        </div>
    )
}
