
import { put, takeEvery } from "redux-saga/effects";
import { GET_INVITE_ADMIN_FETCH } from "./AdminActionType";
import { gql } from 'apollo-boost';
import { client } from "src/app/hooks";

const query = gql`
query getAllInvitedOrgAdmin($orgId: ID!,$page: ID,$email: String,$status: String){
    getAllInvitedOrgAdmin(orgId: $orgId, page:$page,email:$email,status:$status){
        total
        organizationClient {
            id
            email
            status
            createdAt
            role
            isDisabled
          }
       
    } 
  }
`;


function* getInvitedAdminFetch(variables: any): any {

    try {

        const manageAdmins = yield client.query({ query, variables, fetchPolicy: "no-cache" });

        // if (variables.type == 'GET_RECENT_PROJECT_FETCH') {
        yield put({ type: 'GET_INVITE_ADMIN_SUCCESS', response: manageAdmins.data.getAllInvitedOrgAdmin });

        // } else {
        //   yield put({ type: 'GET_PROJECT_SUCCESS', response: projectList.data.organizations });
        // }
    } catch (error) {
        console.log('error', error);
        // yield put({ type: 'API_CALL_FAILURE', error });
    }
}

export default function* inviteAdminSaga() {
    yield takeEvery(GET_INVITE_ADMIN_FETCH, getInvitedAdminFetch);
    //   yield takeEvery(GET_RECENT_PROJECT_FETCH, getProjectFetch);
}