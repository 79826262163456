import React from "react";

export const responsiveText = (value: any) => {
  const name = value.name.split(".").slice(0, -1).join(".");
  const taskName = value.taskName;

  const generateBreakpoints = (start: number, end: number, step: number) => {
    const breakpoints = [];
    for (let i = start; i <= end; i += step) {
      breakpoints.push(i);
    }
    return breakpoints;
  };

  const generateLengths = (start: number, step: number, count: number) => {
    const lengths = [];
    for (let i = 0; i < count; i++) {
      lengths.push(start + i * step);
    }
    return lengths;
  };

  const breakpoints = generateBreakpoints(868, 9924, 100);
  const lengths = generateLengths(6, 3, breakpoints.length);

  let maxLength = lengths[lengths.length - 1]; // Default to the last length if no breakpoint matches

  for (let i = 0; i < breakpoints.length; i++) {
    if (window.innerWidth <= breakpoints[i]) {
      maxLength = lengths[i];
      break;
    }
  }

  const truncatedName =
    name.length > maxLength ? name.substring(0, maxLength) + "..." : name;

  return (
    <>
      {truncatedName}
      <span>&nbsp;{taskName}</span>
    </>
  );
};

export default responsiveText;
