import React, { MouseEvent, useEffect, useState } from 'react';

import Fade from '@mui/material/Fade';

import { NavLink, useHistory, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
// import Link from '@mui/material/Link';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { FormattedMessage } from "react-intl";
// import { useRoleRedirect } from "../../../hooks";
import {
    showAlert,
} from "../../../store";
import SidebarSkeleton from './SidebarSkeleton';
import { avatarPlaceholder } from '../../../hooks/index';
// import {consoleLog} from '../../../hooks/index';
import {
    MAvatar,
    MBox,
    MButton,
    MGrid,
    MIconButton,
    MList,
    MListItem,
    MListItemIcon,
    MListItemText,
    MMenu,
    MMenuItem,
    MTooltip,
} from 'src/app/material-ui';

import { MAccount, MActivityLog, } from 'src/app/material-ui/icons';
import useStyles from './customerSidebarStyles';
import OrganizationDropdown from './organization-dropdown/OrganizationDropdown';

function CustomerSidebar() {

    const classes = useStyles({});
    const history = useHistory();

    // const allOrganizations = useSelector((store: any) => store.orgs.organizations);
    // const { handelRedirect } = useRoleRedirect(props);
    const urlType = window.location.href.split('/');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    // const [selectedOrg,setSelectedOrg]: any = useState();
    const [showDialog, setShowDialog]: any = useState(false);
    const [organizations, setOrganization]: any = useState();
    // const isOwner = useSelector((store: any) => store.orgs.isOwner);
    // const isAdmin = useSelector((store: any) => store.orgs.isAdmin);
    const appUserRole = useSelector((store: any) => store.userRole.userRole);
    const selectedOrgLoading = useSelector((store: any) => store.orgs.selectedOrgLoading);
    const currentUser = useSelector((store: any) => store.auth.currentUser);

    const orgs = useSelector((store: any) => store.orgs.organizations);

    const dispatch = useDispatch();

    useEffect(() => {
        if (orgs && (orgs.length > 0)) {
            setOrganization(orgs)
        }
    }, [orgs])

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // useEffect(() => {

    //     if (allOrganizations && allOrganizations.length > 0) {
    //         const newOrganizations = allOrganizations.filter((org: Organizations) => org.id !== SelectedOrgId);
    //         setSelectedOrg(newOrganizations);

    //         if (newOrganizations.length === 0) {
    //             setSelectedOrg(allOrganizations);
    //         }
    //     }
    // }, [allOrganizations]);

    // const switchRole = (role: string) => {
    //     setAnchorEl(null);
    //     if (role !== '') {
    //         dispatch(setUserRole(role));
    //         dispatch(setSidebarType(role));
    //         // localStorage.setItem('user_role',role);
    //         handelRedirect(role)
    //     }
    // };

    // const goToDetail = (projectId: string) => {
    //     dispatch(selectedProjectId(projectId));
    //     props.history.push('/projects/file/' + projectId);
    // };
    // const noPageModalOpen = (e: any) => {
    //     e.preventDefault();
    //     dispatch(showComingSoonModal(true));
    // };
    const logout = () => {
        const token = localStorage.getItem('access_token');
        localStorage.clear();
        const url = process.env.REACT_APP_SSO_ENDPOINT + '/logout?redirect_url=' + window.location.origin + '&access_token=' + token;
        // window.location.href = "" + process.env.REACT_APP_SSO_LOGOUT_URL + token;
        window.location.href = url;
        dispatch(showAlert({ message: 'You are signing out.', messageType: 'error', showAlertMessage: true }))
        // localStorage.clear()
        // dispatch(resetState());
        // props.history.push('/')
    };

    const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className || "" }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: "#6b778c",
            fontSize: 12,
            filter: "drop-shadow(0px 8px 16px rgba(214,215,226,0.57))",
            border: "1px solid #f4f5f7",
            borderRadius: 0,
            padding: "20px 15px",
            maxWidth: 230,
        },
        arrow: {
            color: theme.palette.common.white,
        },
    }));

    return (
        <MBox className={`${classes.sidebarWrap} ${"customerSidebar"}`}>
            <Dialog open={showDialog}>
                <DialogContent>
                    <MBox p={3} textAlign="center" height="100%">
                        <MBox fontSize={30} fontFamily="Poppins" textAlign="center">
                            <FormattedMessage id="coming.soon" />
                        </MBox>
                        <MBox mt={1.5} mb={3} fontSize={16} color="#637381">
                            <FormattedMessage id="working.hard.this.page" />
                        </MBox>
                    </MBox>
                </DialogContent>
                <DialogActions>
                    <MBox pt={1} pb={1} pr={2}>
                        <MButton variant="contained" color="primary" onClick={() => setShowDialog(false)}>
                            <FormattedMessage id="close" />
                        </MButton>
                    </MBox>
                </DialogActions>
            </Dialog>
            {selectedOrgLoading ? <SidebarSkeleton />
                :
                <>
                    <MBox display="flex" alignItems="center" justifyContent="center" minHeight={64} px={1.2}>

                        {organizations && organizations.length > 0 &&
                            <MGrid item >
                                <OrganizationDropdown className="orgTest" headerText="Organizations" footerText="See All" />
                            </MGrid>
                        }

                        {/* <MListSubheader className={classes.subheader}> */}
                        {/* <MBox display="flex" className={`${classes.customerLogo} ${"customerSmallLogo"}`}>
                            {JSON.stringify(allOrganizations && allOrganizations)}
                            {allOrganizations && allOrganizations[0] ?
                                <MAvatar variant="circle" src={allOrganizations && allOrganizations ? process.env.REACT_APP_PUBLIC_URL + '/' + allOrganizations[0].logo : ''}> {allOrganizations && avatarPlaceholder(allOrganizations[0].name)}</MAvatar>
                                :
                                <MAvatar>
                                    {allOrganizations && avatarPlaceholder(allOrganizations[0].name)}
                                </MAvatar>
                            }
                            <MBox ml={1.8} className='customerLogoText'>
                                <MTypography className={classes.orgShow}>
                                    {allOrganizations && allOrganizations.length > 0 && allOrganizations[0].name}
                                    {selectedOrg && typeof selectedOrg != "undefined" && typeof selectedOrg[0] != "undefined" && selectedOrg[0].name}

                                </MTypography>
                            </MBox>

                            <NavLink to='/projects'>
                                {allOrganizations && allOrganizations[0] &&
                                    <img className="logoFull" src={process.env.REACT_APP_PUBLIC_URL + '/' + allOrganizations[0].logo} alt="logo" width="140px" />
                                }
                                <MBox>
                                </MBox>
                            </NavLink>
                        </MBox> */}
                    </MBox>
                    <MBox>
                        <MBox className="generalLinks">
                            <MList className={classes.sideBarLinks}>

                                <MListItem button className={(urlType[3] === 'projects' || urlType[3] === 'my-jobs' || urlType[4] === 'my-jobs' || urlType[4] === 'open-jobs') ? 'active activehover' : ''} onClick={() => history.push("/projects")} >
                                    <MListItemIcon>
                                        <LightTooltip arrow placement="right" title="Projects">
                                            <DashboardOutlinedIcon />
                                        </LightTooltip>
                                    </MListItemIcon>
                                    <MListItemText primary={<FormattedMessage id="projects" />} />
                                </MListItem>
                            </MList>
                            {/*<MListItem button component={NavLink} to={'/customers/purchase-order'}>*/}
                            {/*<MListItemIcon>*/}
                            {/*<LightTooltip arrow placement="right" title="Purchase Order">*/}
                            {/*<MPOsend />*/}
                            {/*</LightTooltip>*/}
                            {/*</MListItemIcon>*/}
                            {/*<MListItemText primary="Purchase Order" />*/}
                            {/*</MListItem>*/}
                            {/*<MListItem button component={NavLink} to={'/customers/invoice'}>*/}
                            {/*<MListItemIcon>*/}
                            {/*<LightTooltip arrow placement="right" title="Invoice">*/}
                            {/*<DescriptionIcon />*/}
                            {/*</LightTooltip>*/}
                            {/*</MListItemIcon>*/}
                            {/*<MListItemText primary="Invoice" />*/}
                            {/*</MListItem>*/}
                        </MBox>
                        <MBox className={classes.generalLinks} px={1.2} pb={12}>
                            <MBox className="orgFull">
                                <FormattedMessage id="org.resources" />
                            </MBox>
                            <MBox className="orgSm">
                                <FormattedMessage id="org.resources" />
                            </MBox>
                        </MBox>
                        <MList className={classes.sideBarLinks}>
                            <MListItem button component={NavLink} to={`/files`}>
                                <MListItemIcon sx={{ color: '#6b778c' }}>
                                    <LightTooltip arrow placement="right" title="Global Files">
                                        <DescriptionOutlinedIcon />
                                    </LightTooltip>
                                </MListItemIcon>
                                <MListItemText primary={<FormattedMessage id="global.files" />} />
                            </MListItem>
                            <MListItem button component={NavLink} to={'/my-account'} >
                                <MListItemIcon>
                                    <LightTooltip arrow placement="right" title="My Account" >
                                        <MAccount />
                                    </LightTooltip>
                                </MListItemIcon>
                                <MListItemText primary={<FormattedMessage id="my.account" />} />
                            </MListItem>

                            <MListItem button onClick={() => setShowDialog(true)}>
                                <MListItemIcon>
                                    <LightTooltip arrow placement="right" title="Activity Log">
                                        <MActivityLog />
                                    </LightTooltip>
                                </MListItemIcon>
                                <MListItemText primary={<FormattedMessage id="activity.log" />} />
                            </MListItem>
                        </MList>

                        <MBox className="generalLinks">
                            <MList className={classes.sideBarLinks}>
                            </MList>
                        </MBox>
                    </MBox>

                    {/* sidebar footer */}

                    <MGrid item>
                        {/* <NavLink to={(organizations && organizations.length > 0) ? "/projects" : "/create-organization"}> */}
                        <MBox className="footerFull">
                            <MBox className={classes.projectSidebarFooter + ' userDropdown'}>
                                <MBox display="flex" className={'footerContainer'}>
                                    {currentUser && currentUser.profilePicture
                                        ?
                                        <MAvatar className={'footerAvatar'} sx={{ width: 36, height: 36 }} src={currentUser && currentUser.profilePicture ? process.env.REACT_APP_PUBLIC_URL + '/' + currentUser.profilePicture : ""}
                                            onClick={handleClick}>
                                            {/* <MoreVertIcon sx={{ fontSize: 22, color: '#8d8d8d' }} /> */}
                                        </MAvatar>
                                        :
                                        <MAvatar className={'footerAvatar'}>
                                            {currentUser && avatarPlaceholder(`${currentUser.firstName} ${currentUser.lastName}`)}
                                        </MAvatar>
                                    }
                                    {/* <MAvatar className={'footerAvatar'} src={currentUser && currentUser.profilePicture ? process.env.REACT_APP_PUBLIC_URL + '/' + currentUser.profilePicture : ""} /> */}
                                    <MBox ml={1.5} width="100%">
                                        {
                                            currentUser &&
                                                (parseInt(currentUser.firstName.length + currentUser.lastName.length) > 20)
                                                ?
                                                <MTooltip placement="top" title={currentUser.firstName + ' ' + currentUser.lastName}>
                                                    <MBox className={"footerUserDetails"} sx={{ fontSize: '16', fontFamily: "sans-serif", color: "#3a3a3a", fontWeight: 700, textTransform: 'capitalize' }}>
                                                        {`${currentUser.firstName} ${currentUser.lastName}`}
                                                        {/* {currentUser.firstName.concat(" " + currentUser.lastName).slice(0, 20) + "..."} */}
                                                    </MBox>
                                                </MTooltip>
                                                :
                                                <MBox className="footerUserDetails" fontSize={16} fontFamily="sans-serif" color="#3a3a3a" sx={{ textTransform: 'capitalize' }}>
                                                    {currentUser.firstName + ' ' + currentUser.lastName}
                                                </MBox>
                                        }

                                        <MBox fontSize={14} color="#A2A4B9" className={'footerUserDetails'}>
                                            {appUserRole === "ROLE_VENDOR" && 'Vendor'}
                                            {appUserRole === "ROLE_CLIENT" && 'Client'}
                                            {appUserRole === "ROLE_TRANSLATOR" && 'Linguist'}
                                            {appUserRole === "ROLE_CUSTOMER" && 'Customer'}
                                        </MBox>
                                    </MBox>
                                </MBox>
                                <MBox mr={-2}>
                                    <MIconButton aria-controls="notificationMenu" aria-haspopup="true" color="inherit"
                                        onClick={handleClick}>
                                        <MoreVertIcon sx={{ fontSize: 22, color: '#8d8d8d' }} />
                                    </MIconButton>
                                </MBox>

                                <MMenu
                                    id="notificationMenu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    TransitionComponent={Fade}
                                    className={classes.userDropdown}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                >
                                    <NavLink className={classes.menuLink}
                                        to={((appUserRole === "ROLE_VENDOR"
                                            ?
                                            '/vendor/open-jobs'
                                            :
                                            (appUserRole === "ROLE_TRANSLATOR")
                                                ?
                                                '/linguist/open-jobs'
                                                :
                                                '/projects'
                                        ))}
                                    >
                                        <MMenuItem onClick={handleClose}>
                                            <FormattedMessage id="dashboard" />
                                        </MMenuItem>
                                    </NavLink>
                                    <NavLink className={classes.menuLink} to="/my-account">
                                        <MMenuItem onClick={handleClose}>
                                            <FormattedMessage id="my.account" />
                                        </MMenuItem>
                                    </NavLink>
                                    <NavLink onClick={() => setShowDialog(true)} className={classes.menuLink} to="/my-account">
                                        <MMenuItem onClick={handleClose}>
                                            <FormattedMessage id="activity.log" />
                                        </MMenuItem>
                                    </NavLink>
                                    {appUserRole !== "ROLE_CUSTOMER" ?
                                        <NavLink className={classes.menuLink}
                                            to={((appUserRole === "ROLE_VENDOR" ? '/vendor/settings' : (appUserRole === "ROLE_TRANSLATOR") ? '/linguist/profile' : '/settings'))}>
                                            <MMenuItem onClick={handleClose} className={classes.menuLink}>Settings</MMenuItem>
                                        </NavLink>
                                        : ''
                                    }

                                    <MMenuItem onClick={logout} className={`${classes.logoutLink}`}>
                                        <span className={`${classes.menuLink} ${classes.logoutLink}`}>
                                            <FormattedMessage id="logout" />
                                        </span>
                                    </MMenuItem>
                                </MMenu>

                            </MBox>
                        </MBox>
                        <MBox className="footerSm">
                            <MBox className="footerSm">
                                {currentUser && currentUser.profilePicture
                                    ?
                                    <MAvatar className={'footerAvatar'} sx={{ width: 36, height: 36 }}
                                        src={currentUser && currentUser.profilePicture} onClick={handleClick}>
                                        {/* <MoreVertIcon sx={{ fontSize: 22, color: '#8d8d8d' }} /> */}
                                    </MAvatar>
                                    :
                                    <MAvatar className={'footerAvatar'}>
                                        {currentUser && avatarPlaceholder(`${currentUser.firstName} ${currentUser.lastName}`)}
                                    </MAvatar>
                                }
                            </MBox>
                        </MBox>
                        {/* <MBox className="footerSm">
                        hello test
                            </MBox> */}
                        {/* <img className="logoFull" src="/assets/images/marshub-logo.svg" alt="logo" width="140px" />
                        <img className="logoSmall" src="/assets/images/logo-sm.svg" alt="logo" width="140px" /> */}
                        <MBox>
                        </MBox>
                    </MGrid>
                </>
            }
        </MBox>
    )
}

export default withRouter(CustomerSidebar);