import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { withStyles } from '@mui/styles';
import { LinearProgress } from '@mui/material';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
    },
    barColorPrimary: {
      backgroundColor: 'green',
    },
  });

const BorderLinearProgress = withStyles(styles)(LinearProgress);

export default function CustomizedProgressBars(props: any) {
  return (
    <div>
      <BorderLinearProgress variant="determinate" {...props} />
    </div>
  );
}
