import React, { Fragment } from 'react'

export function MInsufficientBalance() {
    return (
        <Fragment>

            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.3333 7V8.66667H26.3333V12.3333H18.3333C16.8667 12.3333 16.6667 13.5333 16.6667 15V22C16.6667 23.4667 16.8667 23.6667 18.3333 23.6667H26.3333V27.3333H7.66667V15.3333H6V26.3333C6 27.8 7.18667 29 8.66667 29H25.3333C26.8 29 28 27.8 28 26.3333V23.2933C29 23 29.3333 23.1162 29.3333 22V14C29.3333 13.0133 29.3333 12.5851 28 12.3333V9.66667C28 8 28 7 26.3333 7H14.3333ZM18.3333 14H27.6667V22H18.3333V14ZM23.3333 16C22.8029 16 22.2942 16.2107 21.9191 16.5858C21.544 16.9609 21.3333 17.4696 21.3333 18C21.3333 18.5304 21.544 19.0391 21.9191 19.4142C22.2942 19.7893 22.8029 20 23.3333 20C23.8638 20 24.3725 19.7893 24.7475 19.4142C25.1226 19.0391 25.3333 18.5304 25.3333 18C25.3333 17.4696 25.1226 16.9609 24.7475 16.5858C24.3725 16.2107 23.8638 16 23.3333 16Z" fill="#DCA3A3" />
                <path d="M5.25926 3H9.29966V8.38721H12.5589L7.27946 13.6667L2 8.38721H5.25926V3Z" fill="#DCA3A3" />
            </svg>

        </Fragment>
    )
}
