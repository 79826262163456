import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            width: 250,
        },
        fullList: {
            // width: 50,
            [theme.breakpoints.down('lg')]: {
                width: "50%",
            },
        },
        drawer: {
            top: 47,
            width: "57% important",
            //transform: translate(350px, 0px) !important;
        },
        paper: {
            width: "75%",
        },
        test: {
            //   backgroundColor : "gray",
            width: "100%",
        },
        paperAnchorRight: {
            //   width: "100%",
            height: '100%',
            "@media (max-width: 400px) and (min-width:320px )": {

                width: 250,
            },
            "@media (max-width: 440px) and (min-width:401px )": {

                width: 280,
            },
            "@media (max-width: 480px) and (min-width:441px )": {

                width: 284,
            },
            "@media  (min-width:480px )": {

                width: 300,
                //   backgroundColor: "gray"
            },
        },
        //   .MuiDrawer-paper {
        //     width: 60% !important;
        //     }


        logo: {
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            height: 55,
        },
        drawerOrg: {
            marginLeft: "0px !important",
            paddingLeft: "0px !important",
            border: "none !important",
        },
        userAvatarWrapper: {
            '& .MuiAvatar-root': {
                width: '28px',
                height: '28px',
                display: "inline-block",
            },
        },
        menuLink: {
            color: '#3a3a3a',
            textDecoration: 'none !important',
            height: 32,
            padding: '5px 10px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            transition: 'all .3s',
            borderRadius: 3,
            fontSize: 13,
            fontFamily: "Poppins",
            '& svg': {
                color: '#98a0af',
                marginRight: -6,
            },
            '&:hover,&.activeSubMenu': {
                color: '#ffaa00 !important',
                // backgroundColor: 'rgba(222,235,255,0.9)',
                '& svg': {
                    color: '#ffaa00',
                },
            },
            '&.active': {
                position: 'relative',
                // color: '#ffaa00 !important',
                '&::after': {
                    content: "''",
                    width: '100%',
                    position: 'absolute',
                    bottom: -2,
                    left: 0,
                    height: 3,
                    backgroundColor: '#ffbe00',
                    borderTopLeftRadius: 1,
                    borderTopRightRadius: 1,
                },
                '& svg': {
                    color: '#ffaa00',
                },
            }
        },
        avatarBox: {
            borderBottom: '1px solid #d8d9db',
            outline: 'none',
            '& .MuiAvatar-root': {
                width: '28px',
                height: '28px',
                display: "inline-block",
            },
        },
        userDropdown: {
            minWidth: 195,
            "& ul": {
                minWidth: 195,
                '& .MuiListItem-button': {
                    borderRadius: 4,
                    margin: theme.spacing(0, 1),
                }
            },
            "& .MuiMenu-paper": {
                top: "188px !important",
                left: "104px !important",
            }
        },
        selectedRole: {
            textDecoration: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
        roleWrap: {
            borderTop: '1px solid #d8d9db',
            borderBottom: '1px solid #d8d9db',
        },
        logoutLink: {
            color: "#f44336",
            padding: '5px 8px',
            "&:hover": {
                color: theme.palette.error.main,
                backgroundColor: "#fddedf",
            }
        },
        topNavRight: {
            display: "flex",
            justifyContent: "flex-end",
        },
        headerIcon: {
            color: theme.palette.text.secondary,
        },
        dotBagde: {
            "& .MuiBadge-badge": {
                width: 10,
                height: 10,
                top: 6,
                right: 7,
                borderRadius: 25,
                color: "#ff0102",
                backgroundColor: theme.palette.primary.main,
                border: "2px solid #fff",
            },
        },
        navIcons: {
            width: "100%",
            display: "flex",
            justifyContent: "space-between",

        },
        topLogo: {
            height: 60,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        logoContainer: {
            backgroundColor: '#fff',
            height: '100%',
            // boxShadow: '0 0 10px #dee0de !important',

        },
        drawerSm: {
            '& .btnWrapper': {
                backgroundColor: 'green'
            }
        },
        mainNav: {
            listStyle: 'none',
            '& li': {
                marginRight: '19.5px',
            },
            '& li:hover a': {
                color: theme.palette.secondary.main,
            },
            '& li a.active': {
                color: theme.palette.secondary.main,
            },
        },

        // heading: {

        //         "@media (max-width: 767px)": {

        //           backgroundColor: "green"
        //         },
        //     },
    }));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;