import { createTheme, responsiveFontSizes } from '@mui/material/styles';
// Augment the palette to include an ochre color
declare module '@mui/material/styles' {
  interface Palette {
    ochre: Palette['primary'];
  }

  interface PaletteOptions {
    ochre?: PaletteOptions['primary'];
  }
}

// Update the Button's color options to include an ochre option
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    ochre: true;
  }
}
let theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      light: "#ffbb33",
      main: "#FF6b00",
      dark: "#FF9500",
      contrastText: "#fff",
    },
    secondary: {
      light: "#e5f1f7",
      main: "#ffbe00",
      //we will use this as accept job button
      dark: "#00695c",
      contrastText: "#fff",
    },
    ochre: {
      main: '#E3D026',
      light: '#E9DB5D',
      dark: '#A29415',
      contrastText: '#242105',
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
    },
    warning: {
      light: "#ffb74d",
      main: "#ff9800",
      dark: "#ff9800",
    },
    info: {
      light: "#64b5f6",
      main: "#2196f3",
      dark: "#1976d2",
    },
    success: {
      light: "#81c784",
      main: "#4caf50",
      dark: "#388e3c",
    },
    text: {
      primary: "#6b778c",
      // primary: "#42526E",
      secondary: "#6b778c",
      // secondary: "#42526E",
      disabled: "rgba(0,0,0,0.38)",
    },
  },
  typography: {
    fontSize: 14,
    h1: {
      // Add your h1 styles here
    },
    button: {
      fontSize: '0.875rem',
      textTransform: 'none',
    },
    fontFamily: [
      // '"Poppins Light"',
      '"Poppins"',
      '"Poppins Semibold"',
      '"Al Bayan Plain"',
      '"Arial"',
      '"Poppins Bold"',
      '"Poppins Symbol"',
      '"Poppins Emoji"',
      '"-apple-system"',
      '"BlinkMacSystemFont"',
      '"Roboto"',
      '"Helvetica Neue"',
      '"Apple Color Emoji"',
      'sans-serif'
    ].join(','),
  },

  components: {
    MuiButton: {
      styleOverrides: {
        text: {
          fontSize: '0.875rem',
          color: '#3a3a3a',
          textTransform: 'none',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#01579b'
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {



        listbox: {
          '& .MuiAutocomplete-option[aria-selected="true"]': {  // works
            backgroundColor: '#f5f6f7',

          },

          '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused': { // works
            backgroundColor: '#f5f6f7',

          },
          '& .MuiAutocomplete-option': { // works
            fontSize: 14,
          },
        },

      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {

          "&.Mui-selected": { backgroundColor: "green" }
        }
      }
    },


    MuiAlert: {
      styleOverrides: {
        icon: {
          // Override styles for the icon inside the Alert component
          color: 'blue', // Change this to the desired color
        },
        standardSuccess: {
          backgroundColor: '#4caf50',
          color: 'white',
          '& svg': {
            color: '#fff',
            fill: '#fff',
          }
        },
        standardError: {
          backgroundColor: 'red',
          color: 'white',
          '& svg': {
            color: '#fff',
            fill: '#fff',
          }
        },
        standardWarning: {
          backgroundColor: 'orange',
          color: 'white',
          '& svg': {
            color: '#fff',
            fill: '#fff',
          }
        },
        standardInfo: {
          backgroundColor: '#01579b',
          color: 'white',
          '& svg': {
            color: '#fff',
            fill: '#fff',
          }
        }
      }
    },
    // MuiTreeView: {
    //   styleOverrides: {
    //     ".MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label": {
    //       backgroundColor: "red"
    //     },
    //     ".MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label": {
    //       backgroundColor: "red"
    //     }

    //   },
    // },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "#ff0000",
        },
      },
    },

    MuiTypography: {
      styleOverrides: {

        h1: {
          // Add your h1 typography styles here
        },
      },
    },
  },
});
theme = responsiveFontSizes(theme);

export default theme;
