import React from 'react'

export function MWarningIcon(props: any) {
    return (

        <React.Fragment>


            <svg {...props} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="40" height="40" rx="9" fill="#FF5100" />
                <g clipPath="url(#clip0_101_32)">
                    <path d="M19.9466 30.4987C17.5102 30.4987 15.0728 30.502 12.6365 30.4987C9.70454 30.4943 8.10362 27.7041 9.52822 25.0714C11.9742 20.5493 14.4353 16.037 16.8748 11.5115C17.5575 10.2463 18.5617 9.50972 19.9734 9.49981C21.4088 9.4899 22.4323 10.2265 23.1258 11.5115C25.5654 16.0359 28.0264 20.5493 30.4735 25.0692C31.897 27.6997 30.295 30.4921 27.3641 30.4965C24.8912 30.5009 22.4184 30.4965 19.9466 30.4965V30.4987ZM21.155 19.3535C21.155 18.8404 21.155 18.3273 21.155 17.8141C21.155 17.7778 21.155 17.7404 21.155 17.704C21.1271 16.9135 20.6648 16.3992 19.9906 16.4058C19.3359 16.4125 18.8649 16.9157 18.8531 17.6732C18.837 18.7908 18.8348 19.9084 18.8531 21.026C18.866 21.7814 19.3445 22.2659 20.0132 22.2603C20.6809 22.2548 21.1303 21.7704 21.1518 21.0029C21.1679 20.4535 21.155 19.904 21.155 19.3546V19.3535ZM20.0154 25.7827C20.64 25.7761 21.1421 25.2663 21.1497 24.6299C21.1583 23.9747 20.6325 23.4396 19.9874 23.4462C19.366 23.4528 18.8606 23.9681 18.852 24.6024C18.8434 25.2564 19.3681 25.7882 20.0154 25.7816V25.7827Z" fill="white" />
                    <path d="M21.1535 19.3537C21.1535 19.9031 21.1664 20.4537 21.1503 21.002C21.1277 21.7695 20.6783 22.2539 20.0117 22.2594C19.344 22.2649 18.8656 21.7805 18.8516 21.0251C18.8323 19.9075 18.8344 18.7899 18.8516 17.6723C18.8624 16.9147 19.3344 16.4126 19.9891 16.4049C20.6633 16.3983 21.1256 16.9125 21.1535 17.7031C21.1546 17.7395 21.1535 17.7769 21.1535 17.8132C21.1535 18.3263 21.1535 18.8395 21.1535 19.3526V19.3537Z" fill="#FF5100" />
                    <path d="M20.014 25.784C19.3668 25.7906 18.8421 25.2588 18.8507 24.6048C18.8593 23.9694 19.3646 23.4552 19.9861 23.4486C20.6312 23.442 21.1569 23.976 21.1483 24.6323C21.1397 25.2687 20.6387 25.7785 20.014 25.7851V25.784Z" fill="#FF5100" />
                </g>
                <defs>
                    <clipPath id="clip0_101_32">
                        <rect width="22" height="21" fill="white" transform="translate(9 9.5)" />
                    </clipPath>
                </defs>
            </svg>



        </React.Fragment>


    )
}

