import React, { useState } from 'react';
import { Autocomplete } from "@mui/material";
import TextField from '@mui/material/TextField';
import useStyles from './ProjectTypeSelectStyles'
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

interface Language {
  id: number,
  name: string,
  code: string
}

const SOURCE_LANGUAGE_GQL = gql`
  {
    sourceLanguages {
      code
      id
      name
    }
  }
`;

export function ProjectTypeSelect(props: any) {
  const classes = useStyles({});
  const [targetLanguage, setTargetLanguage] = useState([]);
  const { data } = useQuery(SOURCE_LANGUAGE_GQL, {
    onCompleted: (data: any) => {
      if (data && data?.sourceLanguages) {
        setTargetLanguage(data.sourceLanguages);
      }
    }
  });

  return (
    <div>
      {targetLanguage && targetLanguage?.length && (
        <Autocomplete
          className={classes.inputWidth}
          id="tags-outlined"
          size="small"
          options={targetLanguage}
          getOptionLabel={(option: any) => option.name}
          limitTags={1}
          onChange={(event, value) => {
            const languagesCode: any[] = [];
            value.forEach((languageObj: Language) => {
              languagesCode.push(languageObj.code);
              const newTargetLanguages = data && data.sourceLanguages.filter((element: Language) => element.code !== languageObj.code);
              setTargetLanguage(newTargetLanguages);
            });
            if (value.length < 1) {
              setTargetLanguage(data && data.sourceLanguages);
            }
            props.setTargetLanguages(languagesCode);
            event?.preventDefault();
          }}
          multiple
          filterSelectedOptions
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              color="secondary"
              label="All Types"
            />
          )}
        />
      )}
    </div>
  );
}


