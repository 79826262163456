
import { RESET_STORE } from '../resetStateAction';
import { GET_LSP_FETCH, GET_LSP_SUCCESS } from './SuppliersActionTypes';

interface IAction {
    type: string,
    payload: any,
    response: any,
    action: any,
    supplierLoading: boolean,
    myVendors: any,
}

interface IState {
    myVendors: any,
    supplierLoading: boolean

}

const initialState: IState = {

    myVendors: [],
    supplierLoading: false,

};


const suppliersReducer = (state = initialState, action: IAction) => {

    switch (action.type) {

        case GET_LSP_FETCH:
            return {
                ...state,
                supplierLoading: true
            }
        case GET_LSP_SUCCESS:
            return {
                ...state,
                myVendors: action.response,
                supplierLoading: false,


            }

        case RESET_STORE:
            return initialState;
        default:
            return state;
    }
};

export default suppliersReducer;