import { FETCH_LINGUIST_PROFILE_DATA_REQUEST, FETCH_LINGUIST_PROFILE_DATA_SUCCESS, FETCH_LINGUIST_PROFILE_DATA_FAILURE } from './JobActionTypes';


interface IAction {
    type: string,
    payload: any,
    action: any,
}
interface IState {
    profileData: any,
    loading: boolean,
    error: any
}
const initialState: IState = {
    profileData: null,
    loading: false,
    error: null
};

export default function linguistProfileDataReducer(state = initialState, action: IAction) {
    switch (action.type) {
        case FETCH_LINGUIST_PROFILE_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_LINGUIST_PROFILE_DATA_SUCCESS:
            return {
                ...state,
                profileData: action.payload,
                loading: false
            };
        case FETCH_LINGUIST_PROFILE_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
      
        default:
            return state;
    }
}