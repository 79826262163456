import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles((theme: Theme) => ({
    // formBox:{
    // backgroundColor: 'white',
    //     boxShadow: '0px 5px 5px 0px rgba(193, 199, 208, 0.26)',
    //     borderRadius: 5
    // },
    // ratesList:{
    //     // boxShadow: '0px 0px 32px 0px rgba(214, 215, 226, 0.3)',
    //     backgroundColor: '#fff',
    //     borderBottom: '1px solid #f4f5f7',
    //     '& a':{
    //         color: theme.palette.secondary.main,
    //         textDecoration: 'none'
    //     },
    // },
    // dotIcon:{
    //     fontSize: 10,
    //     color: '#bfc1cd',
    //     margin: '0 12px -1px'
    // },
    status: {
        fontSize: 12,
        minWidth: 76,
        height: 28,
        padding: 15,
        borderRadius: 14,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textTransform: "capitalize",
        '&.created': {
            backgroundColor: '#f9f5e7',
            color: '#e69900',
        },
        // '&.bidding':{
        //     backgroundColor:'#ffe8e8',
        //     color: '#ff0102',
        // },
        '&.approved': {
            backgroundColor: '#DFF5EB',
            color: '#16C68E',
        },

        '&.registered': {
            backgroundColor: '#DFF5EB',
            color: '#16C68E',
        },
        '&.pending': {
            backgroundColor: '#f5f6f7',
            color: '#9e9e9e',
        },
        '&.profilePending': {
            backgroundColor: '#F9F5E7',
            color: '#E69B0E'
        },
        '&.rejected, .invalid': {
            backgroundColor: '#FFE8E8',
            color: '#FF0102',
        },
    },
    // matchRateLink:{
    //     color: theme.palette.secondary.main,
    //     textDecoration: 'none',
    //     '&:hover':{
    //         textDecoration: 'underline',
    //     }
    // },
    uploadedFilesList: {
        width: '100%',
        backgroundColor: '#fff',
        boxShadow: '0px 0px 32px 0px rgba(214, 215, 226, 0.3)',
        '& .MuiGrid-root': {
            '& >.MuiBox-root': {
                [theme.breakpoints.down('lg')]: {
                    justifyContent: 'flex-start',

                    marginBottom: 5
                },
            }
        }


    },
    assignDate: {
        [theme.breakpoints.down('lg')]: {
            fontSize: 12
        },
    },
    textOverflow: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'

    },
    sourceText: {
        color: '#a0a5b9',
        textAlign: "left",
        '& span, & svg': {
            display: 'inline-block',
            verticalAlign: 'middle'
        },
        '& svg': {
            marginRight: 4,
        },
    },
    formLabel: {
        fontSize: 14,
        color: theme.palette.text.primary,
    },
    inputWidth: {
        width: 493,
        '& .MuiInputBase-input:not(.MuiAutocomplete-input)': {
            paddingTop: '9.5px !important',
            paddingBottom: '11.5px !important'
        },
        [theme.breakpoints.down('lg')]: {
            width: '100%'
        },
    },
    sendClock: {
        border: '1px solid #6b778c',
        color: '#ff6b00 !important',
        '&:hover': {
            color: '#fff !important',
            backgroundColor: '#6b778c',
            '& svg path': {
                fill: '#fff',
            },
        },
        '& .MuiIconButton-root': {
            padding: 0,
            paddingRight: 10,
            '&:hover': {
                backgroundColor: 'transparent'
            }
        },
    },
    addIconStyle: {
        marginRight: '2px',
        fontSize: "0.8rem"
    },
    inviteError: {
        '& a': {
            color: '#3f74e8',
            textDecoration: 'none'
        },
        '& .error': {
            color: 'red'
        }
    },
    radioGroup: {
        '& .MuiFormGroup-root': {
            flexDirection: 'row',
            '& .MuiSvgIcon-root': {
                height: 15,
                width: 15
            },
            '& .MuiRadio-colorSecondary.Mui-checked': {
                color: '#ffaa00'
            },
            '& .MuiTypography-root': {
                fontSize: 14
            }
        }

    },
    formInvite: {
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        '& .MuiBox-root': {
            '&:first-child': {
                [theme.breakpoints.down('lg')]: {
                    width: '100%'
                },
            }
        }

    }, seachInput: {
        backgroundColor: '#f5f6f7',
        borderRadius: 5,
        '& .MuiFormLabel-root': {
            transform: 'translate(15px, 8px) scale(1)',
            fontSize: 14
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(15px, -7px) scale(0.75)'
        },
        '& .MuiOutlinedInput-inputMarginDense': {
            padding: '4.5px 0 8.5px 15px'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        },
        '& .MuiInputBase-colorSecondary.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid' + theme.palette.secondary.main,
            '& legend': {
                width: '70px !important'
            }
        },
        '& .MuiSvgIcon-root': {
            color: '#585858',
            width: 18,
            height: 18,
            marginRight: -3
        }
    },

}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;