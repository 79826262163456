/* eslint-disable */
import React from 'react';
import { useSelector } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from "@mui/material/Tooltip";
import ArrowForward from '@mui/icons-material/ArrowForward';
import { MBox, MLink, MTypography } from 'src/app/material-ui';
import useStyles from './DraggableStyles';
import { MDownload, MScheduleIcon,MApproved,MClearIcon } from 'src/app/material-ui/icons';
import { timeZone } from 'src/app/hooks';
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";

export function Draggable(props: any) {

  const classes = useStyles();
  const languagesArray = ['Chinese Simplified (zh-CN)', 'French (fr-FR) ', 'German (de-DE) ', 'Japanese (ja-JP)', 'Korean (ko-KR)', 'Spanish (es-ES)', 'Italian (it-IT)', 'Russian (ru-RU)'];
  const currentUser = useSelector((store: any) => store.auth.currentUser);
  const service = (services: any) => {
    const servicesNameArr: any = [];
    services.forEach((services: any, index: number) => index > 1 && servicesNameArr.push(services));
    return servicesNameArr.join(', ');
  }

  const { removeDelete, totalJobs, isPageTm, translationMemory, isDragDisabled, langName, langCode, createdAt, ...restProps } = props;

  return (
    <>
      {props.translationMemory ?
        <MBox className={`${classes.taskList} ${props.type}`} {...restProps} display="flex" justifyContent='space-between' width='100%' alignContent="center">
          <MBox display="flex" justifyContent='space-between' fontFamily='Poppins' width='100%'>
            <MBox flex={props.text === "Mars TM" ? '1 1 0' : '3 1 0'}>
              <MBox display='flex'>
                {props?.text === "Mars TM" &&
                  <>
                    <MBox className='default-tm' sx={{
                      display: "flex",
                      backgroundColor: "#edecec",
                      borderRadius: "1000px",
                      marginRight: '10px',
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "9px",
                      position: 'relative'
                    }}>
                      <img className="logoSmall" src="/assets/images/logo-sm.svg" alt="logo" width="18px" />
                    </MBox>

                    <Tooltip arrow title="MarsCloud offers a TMX file for users who do not have a TM. This file can be added to the Read-Only section. Link it to your project to benefit from enhanced translation resources.">
                      <IconButton sx={{
                        width: "18px",
                        height: "18px",
                        position: 'absolute',
                        left: '150px'
                      }}>
                        <InfoIcon sx={{
                          height: 18,
                          width: 18
                        }} />
                      </IconButton>
                    </Tooltip>
                  </>
                }

                <MBox>
                  <MBox fontSize={14} color='#545454'>
                    {props.text}
                  </MBox>
                  <MBox color='#9D9D9D' fontSize={12} display='flex' alignItems='center'>
                    {/* it will not show language code it it is mars tm */}
                    {`${props.langName}${props?.text === "Mars TM" ? ` (${props.langCode})` : ` (${props.langCode})`}`} {props?.text === "Mars TM" &&
                      <>
                        &nbsp;
                        <ArrowForward sx={{
                          height: '15px',
                          width: '15px'
                        }} />
                        &nbsp;
                        {languagesArray.slice(0, 2).map((item: string, index: number) => (
                          <React.Fragment key={index}>
                            &nbsp;
                            {item}
                            &nbsp;
                            {(index < 1) && ","}
                          </React.Fragment>
                        ))}
                        {languagesArray.length > 2 &&
                          <Tooltip arrow title={service(languagesArray)} placement="top">
                            <div style={{ display: 'inline-block' }}>
                              <MLink href="#" onClick={(e: React.SyntheticEvent) => e?.preventDefault()} >
                                &nbsp;
                                {languagesArray && (languagesArray.length - 2)} <FormattedMessage id="more" />
                              </MLink>
                            </div>
                          </Tooltip>
                        }
                      </>
                    }
                  </MBox>
                </MBox>
              </MBox>
            </MBox>
              {props.text !== "Mars TM" && typeof props.tmStatus !== "undefined" &&
              <MBox flex={'2 1 0'} display='flex' justifyContent='space-between' alignItems='center'>

                  {props.tmStatus !== null && <>

                      <MBox> <MTypography
                          className={classes.sourceText}
                          component="span"
                          variant="caption"
                      >
                          {/*<MScheduleIcon*/}
                              {/*className="fa fa-plus-circle"*/}
                              {/*fontSize="inherit"*/}
                          {/*/>*/}
                          <span>
                          <FormattedMessage id="status" />
                        </span>
                      </MTypography>
                          <MBox
                              fontSize={15}
                              color={(props.tmStatus === 0) ? "#a0a5b9" : (props.tmStatus === 1 ? "#16C68E" : "#c62d16")}
                              display='flex'
                              alignItems='center'
                              gap='10px'
                          >
                              <MBox display="flex" alignItems="center">
                                  {/* Status Icon */}
                                  <MBox display="flex" alignItems="center" mr={1}>
                                      {props.tmStatus === 0 ? (
                                          <HourglassEmptyOutlinedIcon style={{ fontSize: "16px" }} />
                                      ) : props.tmStatus === 1 ? (
                                          <MApproved color="#16C68E" />
                                      ) : (
                                          <MClearIcon color="#c62d16" />
                                      )}
                                  </MBox>

                                  {/* Status Message */}
                                  <FormattedMessage
                                      id={props.tmStatus === 0 ? "inprogress" : props.tmStatus === 1 ? "active" : "failed"}
                                  />
                              </MBox>

                          </MBox>
                      </MBox>
                  </>
                  }

              </MBox>
              }
            <MBox flex={props.text === "Mars TM" ? '' : '2 1 0'} display='flex' justifyContent='space-between' alignItems='center'>
              <MBox>
                <MBox>
                  {props?.createdAt !== null && <>
                    <MBox>
                      <MTypography
                        className={classes.sourceText}
                        component="span"
                        variant="caption"
                      >
                        <MScheduleIcon
                          className="fa fa-plus-circle"
                          fontSize="inherit"
                        />
                        <span>
                          <FormattedMessage id="created" />
                        </span>
                      </MTypography>
                      <MBox fontFamily="Poppins" fontSize={13} color={"#3a3a3a"}>
                        {timeZone(props?.createdAt, currentUser, 0)}
                        {/* {timeZone('12',"02", "2022")} */}
                      </MBox>
                    </MBox>
                  </>
                  }
                </MBox>
              </MBox>
              <MBox display='flex' justifyContent='flex-end'>
                <IconButton>
                  {!props.isPageTm && props.page != 'createProject' && <MDownload />}
                </IconButton>
              </MBox>
            </MBox>
          </MBox>
          {/* <MBox flexGrow={9} className={classes.text} fontSize={14} alignSelf="center" ml={1}></MBox>
          {props.isSelected && (typeof props.totalJobs == "undefined" || props.totalJobs == 0) ?
            <MBox className={classes.remove} px={2} component="a" href="javascript:void(0)" classname="dragIcon" textAlign="right" alignSelf="center" onClick={() => props.removeItem()}><CloseIcon /></MBox> :
            ''} */}
        </MBox>
        :
        <MBox className={`${classes.draggableBox} ${props?.type}`} {...props} display="flex" alignContent="center">
          <MBox className={`${classes.iconBox} menuIconBox`} alignSelf="center" alignItems="center" textAlign="center" display="flex">
            {props?.isSelected ?
              <MBox className="counter" width="100%">{props.counter} </MBox>
              :
              <MBox className="dragIcon" width="100%"><MenuIcon className={classes.menuDragIcon} /></MBox>
            }
          </MBox>
          <MBox flexGrow={9} className={classes.text} fontSize={14} alignSelf="center" ml={1}>{props.text}{props?.translationMemory && 'translation memory'}</MBox>
          {props?.isSelected && props?.removeDelete !== true && (typeof props?.totalJobs == "undefined" || props?.totalJobs == 0) ?
            <MBox className={classes.remove} px={2} component="a" href="#" classname="dragIcon" textAlign="right" alignSelf="center" onClick={(e: React.SyntheticEvent) => {
              props.removeItem();
              e?.preventDefault();
            }}><CloseIcon /></MBox> :
            ''}
        </MBox>
      }
    </>

  )
}