import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({


    panelSummary: {
      flexDirection: "row-reverse",

      "& .MuiExpansionPanelSummary-expandIcon": {
        padding: "0",
        marginRight: theme.spacing(1),
        color: "#494949"
      },
      "& .MuiExpansionPanelSummary-content": {
        justifyContent: "space-between",
        alignItems: "center"
      },
    },

    panelBodyWrapper: {
      display: "block",
      "& .actionBtnBox": {
        position: 'absolute',
        right: 10,
        top: 14,
        "& svg": {
          color: "#a0a5b9",
        },
      },
    },

    panelInner: {
      borderTop: "1px solid #dcddde",
      // borderBottom: "1px solid #dcddde"
    },
    workFlowList: {
      "& .counter": {
        backgroundColor: "#6b778c",
        color: "#fff",
        borderRadius: 20,
        position: "relative",
      },
      "& .text": {
        color: "#6b778c",
      }
    },
    workFlowBox: {
      position: "relative",
      width: '20%',

      "& .hr": {
        position: "absolute",
        width: '100%',
        height: 1,
        right: '-50%',
        top: 14,
        backgroundColor: '#f1f1f2',
        // display: "none"
      },

      "& .arrow": {
        position: "absolute",
        width: 14,
        height: 14,
        top: 8,
        right: 0,
        color: "#6b778c",
        background: "url('../../assets/images/work-flow-arrow.png') no-repeat",
      },
      "& .curved-arrow-right": {
        position: "absolute",
        width: 57,
        height: 102,
        top: 8,
        right: 64,
        background: "url('../../assets/images/work-flow-right-curved-image.png') no-repeat",
      },
      "& .curved-arrow-left": {
        position: "absolute",
        width: 57,
        height: 102,
        top: 8,
        left: 0,
        background: "url('../../assets/images/work-flow-left-curved-image.png') no-repeat",
      },
      // "&:first-child .arrow, &:nth-child(5n+5) .arrow":{
      "&:nth-child(5n+5) .arrow, &:nth-child(5n+5) .hr": {
        display: "none",
      }

      // "&:before":{

      // },
      // "& :after":{
      //   width: 200,
      //   height: 1,
      //   right: 0,
      //   top: 10,
      //   backgroundColor: "#f1f1f2"
      // },


    },

    accordionWrap: {
      boxShadow: 'none',
      filter: 'drop-shadow(0 0 16px rgba(214,215,226,0.3))',
      border: '1px solid #f4f5f7',
      marginBottom: 10,
      transition: "all .3s",
      '&:not(.Mui-expanded):hover': {
        boxShadow: 'none',
        filter: 'initial',
        '& .MuiAccordionSummary-root': {
          backgroundColor: '#f7f8fc',
        }
      },
      '& .MuiButtonBase-root.MuiAccordionSummary-root': {
        height: 75,
        borderRadius: 5,
        backgroundColor: '#fff',
        margin: 0
      },
      '& .MuiAccordionDetails-root': {
        backgroundColor: '#fff',
        paddingTop: 0
      },
      '&::before': {
        display: 'none'
      },
      '& .MuiAccordionSummary-root>.MuiButtonBase-root': {
        marginRight: -5,
        transform: 'rotate(-90deg)',
        '&.Mui-expanded': {
          transform: 'rotate(0deg)'
        }
      }
    }

  }));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;