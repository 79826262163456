import React from 'react';

import Grid from '@mui/material/Grid';

export function MGrid(props: any) {

    return (
        <Grid {...props} item={true}
        />
    )
}