import React from 'react'
import { MBox } from 'src/app/material-ui';
import './css/freakflags.css';
export function FlagIcon(props:any){


    return(
        <>
          <MBox
            {...props}
            className={`fflag ${props.classes}`} />
        </>
    )
}