import { FETCH_SOURCE_LANGUAGE_FAILURE, FETCH_SOURCE_LANGUAGE_REQUEST, FETCH_SOURCE_LANGUAGE_SUCCESS } from "./SourceLanguageActionTypes";




export function fetchSourceLanguageRequest() {
    return {
      type: FETCH_SOURCE_LANGUAGE_REQUEST,
    };
  }
  
  export function fetchSourceLanguageSuccess(data: any) {
    return {
      type: FETCH_SOURCE_LANGUAGE_SUCCESS,
      payload: data
    };
  }
  
  export function fetchSourceLanguageFailure(error: any) {
    return {
      type: FETCH_SOURCE_LANGUAGE_FAILURE,
      payload: error
    };
  }