import React, { Fragment } from 'react'

export function MPoSent() {
    return (
        <Fragment>

            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 13.8393V11.1607H12.5354V9L16 12.5L12.5354 16V13.8393H9Z" fill="#E69B0E" />
                <path d="M9 15H3.5V3H8.75V6.75H12.5V8H14V6L9.5 1.5H3.5C3.10218 1.5 2.72064 1.65804 2.43934 1.93934C2.15804 2.22064 2 2.60218 2 3V15C2 15.3978 2.15804 15.7794 2.43934 16.0607C2.72064 16.342 3.10218 16.5 3.5 16.5H9V15Z" fill="#E69B0E" />
            </svg>

        </Fragment>
    )
}
