/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { gql } from "apollo-boost";
import { FormattedMessage } from 'react-intl';
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

import { avatarPlaceholder } from '../../../../hooks/index';
import { selectOrganization } from 'src/app/store';
import { showAlert } from "../../../../store";
import { MKeyboardArrowDownRoundedIcon } from 'src/app/material-ui/icons/keyboard-rounded-icon/KeyboardArrowDownRoundedIcon';
import useStyles from './OrganizationDropdownStyles';
import {
    MButton,
    MMenu,
    MListItem,
    MAvatar,
    MListItemAvatar,
    MListItemText,
    MBox,
    MChip,
    MTypography
} from 'src/app/material-ui';
interface Organizations {
    id: number,
    name: string,
    url: string,
    logo: string,
    isOwner: boolean,
    isAdmin: boolean,
    groups: any,
    isProjectAdmin: boolean,
    isVendorAdmin: boolean
}

const UPDATE_SELECTED_ORG_GQL = gql`
    mutation UpdateAccountSetting ($selectedOrgId: Int){
        updateAccountSetting(selectedOrgId: $selectedOrgId) {
            id
        }
    }`;

// const GET_PROFILE_DATA = gql`
//     query getVendorProfile {
//         getVendorProfile {
//             vendor_profile_id
//             vendor_website
//             vendor_phone
//             vendor_address
//             vendor_about_us
//             vendor_logo
//             vendor_cover_picture{
//                 id
//                 name
//                 path
//                 ext
//             }
//             vendor_email
//             vendor_founded
//             vendor_company_size
//             vendor_company_name
//             vendor_url_slug
//             vendor_company_email
//             vendor_url_slug
//             vendor_org_url
//             vendor_best_known{
//                 name
//             }
//         }
//     }
// `;
function OrganizationDropdown(props: any) {

    const dispatch = useDispatch();
    const classes = useStyles({});
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedOrg, setSelectedOrg]: any = useState();
    const allOrganizations = useSelector((store: any) => store.orgs.organizations);
    const [organizations, setOrganizations] = useState(allOrganizations && allOrganizations);
    const appUserRole = useSelector((store: any) => store.userRole.userRole);
    const currentUser = useSelector((store: any) => store.auth.currentUser);
    // const [hidden, setHidden]: any = useState(false);
    const [selectedOrgObj, setSelectedOrgObj]: any = useState(null);
    // const [showIcon, setShowIcon]: any = useState(false);
    // const [showFilterMenu, setShowFilterMenu]: any = useState('');
    //  const [vendorData, setVendorData]: any = useState();
    // const [hiddenn, setHiddenn]: any = useState(false);
    const [state, setState] = useState({
        mobileView: false,
        drawerOpen: false,
    });
    const { mobileView } = state;

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const LightTooltip = styled(
        React.forwardRef(function LightTooltip({ className, ...props }: TooltipProps, ref) {
            return (
                <Tooltip
                    {...props}
                    classes={{ ...props?.classes, popper: className || '' }}  // Ensure className is passed safely
                    ref={ref}
                />
            );
        })
    )(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: "#6b778c",
            fontSize: 12,
            filter: "drop-shadow(0px 8px 16px rgba(214,215,226,0.57))",
            border: "1px solid #f4f5f7",
            borderRadius: 0,
            padding: "20px 15px",
            maxWidth: 230,
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.white,
        },
    }));

    // const [vendorProfile] = useLazyQuery(GET_PROFILE_DATA, {
    //     fetchPolicy: 'no-cache',
    //     onCompleted: (data: any) => {
    //         setVendorData(data.getVendorProfile)
    //     }
    // });


    useEffect(() => {
        //  vendorProfile();
    }, [currentUser]);

    useEffect(() => {
        setTimeout(() => {
            if (currentUser && currentUser.selectedOrgId != 0) {
                if ((appUserRole === 'ROLE_CLIENT' && currentUser.isPrivate)) {
                    const newOrganizations = allOrganizations.filter((org: any) => org.isOwner == false);
                    setOrganizations(newOrganizations);
                    const oldSelectedOrg = organizations.find((org: Organizations) => (org.id === Number(currentUser.selectedOrgId)));
                    if (oldSelectedOrg) {
                        setSelectedOrg(oldSelectedOrg);
                        dispatch(selectOrganization(Number(oldSelectedOrg.id)));
                    }
                } else {
                    setOrganizations(allOrganizations);
                    setSelectedOrg(allOrganizations[0]);
                    if (localStorage.getItem('selectedOrgId')) {
                        const oldSelectedOrg = organizations.find((org: Organizations) => (org.id === Number(localStorage.getItem('selectedOrgId'))));
                        if (oldSelectedOrg) {
                            setSelectedOrg(oldSelectedOrg);
                            dispatch(selectOrganization(Number(oldSelectedOrg.id)));
                        }
                    }
                }
            } /*else if (localStorage.getItem('selectedOrgId')) {
                dispatch(selectOrganization(Number(localStorage.getItem('selectedOrgId'))));
                // let oldSelectedOrg;
                // if ((appUserRole === 'ROLE_CUSTOMER')) {
                //
                //     oldSelectedOrg = organizations.filter((org: Organizations) => (org.id === Number(localStorage.getItem('selectedOrgId'))));
                //
                //     let newOrganizations = allOrganizations.filter((org: any) => org.isOwner == false);
                //
                //     setOrganizations(newOrganizations);
                //
                //     const urlBasedOrg = newOrganizations.filter((org: any) => (org.url === window.location.host));
                //     const withOutUrl = urlBasedOrg.find((org:any) =>( org.url === ""  || org.isOwner  == false));
                //
                //     if (withOutUrl) {
                //         dispatch(selectOrganization(Number(oldSelectedOrg[0].id)));
                //         localStorage.setItem('selectedOrgId', String(oldSelectedOrg[0].id));
                //     } else if (urlBasedOrg.length > 0) {
                //
                //         oldSelectedOrg = urlBasedOrg;
                //
                //         dispatch(selectOrganization(Number(oldSelectedOrg[0].id)));
                //         localStorage.setItem('selectedOrgId', String(oldSelectedOrg[0].id));
                //     }
                //
                // } else {
                //     oldSelectedOrg = organizations.filter((org: Organizations) => org.id === Number(localStorage.getItem('selectedOrgId')));
                // }
                let oldSelectedOrg;
                oldSelectedOrg = organizations.filter((org: Organizations) => org.id === Number(localStorage.getItem('selectedOrgId')));
                setSelectedOrg(oldSelectedOrg[0]);
            }*/ else {
                if ((appUserRole === 'ROLE_CLIENT' && currentUser.isPrivate) && (allOrganizations.length > 0 && allOrganizations[0].isOwner == true)) {
                    const newOrganizations = allOrganizations.filter((org: any) => org.isOwner == false);
                    // if (newOrganizations[0].url === '') {
                    //     setOrganizations(newOrganizations);
                    //
                    // } else {
                    setOrganizations(newOrganizations);
                    const urlBasedOrg = newOrganizations.filter((org: any) => (org.url === window.location.host));
                    if (urlBasedOrg.length > 0) {
                        // setSelectedOrg(newOrganizations[0]);
                        setSelectedOrg(urlBasedOrg[0]);
                    } else {
                        setSelectedOrg(newOrganizations[0]);
                        // }
                    }
                } else {
                    setOrganizations(allOrganizations);
                    setSelectedOrg(allOrganizations[0])
                }
            }
        }, 1000);
    }, [allOrganizations]);

    useEffect(() => {
        if (allOrganizations) {
            // subscribeOrganizationUserAddedEvent()
            setOrganizations(allOrganizations);
            setSelectedOrg(allOrganizations[0])
        }
    }, [allOrganizations]);

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 960
                ? setState((prevState) => ({ ...prevState, mobileView: true }))
                : setState((prevState) => ({ ...prevState, mobileView: false }));
        };
        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());
        return () => {
            window.removeEventListener("resize", () => setResponsiveness());
        };
    }, []);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [updateSelectedOrgData] = useMutation(UPDATE_SELECTED_ORG_GQL, {
        onError: ({ graphQLErrors }: any) => {
            if (graphQLErrors)
                dispatch(showAlert({
                    message: graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message,
                    messageType: 'error',
                    showAlertMessage: true
                }))

        },
        onCompleted: () => {
            if (appUserRole === 'ROLE_CLIENT' && currentUser.isPrivate) {
                let url = selectedOrgObj ? selectedOrgObj.url : '';
                const isOwner = selectedOrgObj?.isOwner;
                if (isOwner == false && url && url.indexOf('http') === -1) {
                    url = 'http://' + url;
                } else if (isOwner == false && url === '') {
                    url = process.env.REACT_APP_FRONTEND_URL;
                }
                setTimeout(function () {
                    window.location.href = url;
                }, 2000);
            } else {
                const selectedOrg = selectedOrgObj && selectedOrgObj.permissions.find((permission: any) => {
                    // return org.id === selectedOrgId
                    if (permission.granted == true) {
                        return permission.url
                    }
                });
                props.history.push((appUserRole === "ROLE_CLIENT") ? selectedOrg.url : selectedOrg.url)
            }
        }
    });

    const selectOrg = (organanization: Organizations) => {
        localStorage.setItem('selectedOrgId', String(organanization.id));
        // const newOrganizations: Array<Organizations> = organizations;
        // newOrganizations.push(selectedOrg);
        // setOrganizations(newOrganizations)
        dispatch(selectOrganization(organanization.id));
        setSelectedOrgObj(organanization);
        setSelectedOrg({
            id: organanization.id,
            name: organanization.name,
            logo: organanization.logo,
            isOwner: organanization.isOwner
        });
        handleClose();
        updateSelectedOrgData({
            variables: {
                selectedOrgId: organanization.id
            }
        });
    };

    // useEffect(() => {
    // setOrganizations(allOrganizations);
    // if ((appUserRole === 'ROLE_CUSTOMER')) {
    // let newOrganizations;
    // if(selectedOrg && typeof selectedOrg!="undefined" && typeof selectedOrg.id!="undefined") {
    //     newOrganizations = allOrganizations.filter((org: any) => (org.url !== '' && org.id!==selectedOrg?.id));
    // } else {
    //     newOrganizations = allOrganizations.filter((org: any) => (org.url !== '' && org.id!==allOrganizations[0]?.id));
    // }
    //
    // setOrganizations(newOrganizations);
    //
    //
    // const urlBasedOrg = newOrganizations.filter((org: any) => (org.url.split('://').length>1 && org.url.split('://')[1] === window.location.host));
    // if(urlBasedOrg.length > 0) {
    //
    //     setSelectedOrg(urlBasedOrg[0]);
    // } else {
    //     setSelectedOrg(newOrganizations[0]);
    // }
    // } else {
    // setSelectedOrg(allOrganizations[0]);
    // }
    // }, [allOrganizations, appUserRole]);


    // useEffect(() => {
    // if (selectedOrg) {
    //     const newOrganizations = allOrganizations.filter((org: Organizations) => org.id !== selectedOrg.id);
    //     setOrganizations(newOrganizations);
    //     // setOrganizations(orgsNew);
    //     if ((appUserRole === 'ROLE_CUSTOMER') && (selectedOrg && selectedOrg.url) !== '') {
    //         const newOrganizations = allOrganizations.filter((org: any) => (org.url !== '' && org.id!==selectedOrg?.id));
    //
    //         setOrganizations(newOrganizations);
    //     } else {
    //         const newOrganizations = allOrganizations.filter((org: any) => (org.id!==selectedOrg?.id));
    //
    //         setOrganizations(newOrganizations);
    //     }
    // }
    // }, [selectedOrg]);




    // const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    //     <Tooltip {...props} classes={{ popper: className }} />
    // ))(({ theme }) => ({
    //     [`& .${tooltipClasses.tooltip}`]: {
    //         backgroundColor: theme.palette.common.white,
    //         color: "#6b778c",
    //         fontSize: 12,
    //         filter: "drop-shadow(0px 8px 16px rgba(214,215,226,0.57))",
    //         border: "1px solid #f4f5f7",
    //         borderRadius: 0,
    //         padding: "20px 15px",
    //         maxWidth: 230,
    //     },
    //     arrow: {
    //         color: theme.palette.common.white,
    //     },
    // }));

    // useEffect(() => {
    //     const filteredOrganizations = filterOrganizations(allOrganizations);
    //     const showIcon = filteredOrganizations.length > 0;
    //     setShowIcon(showIcon);
    // }, [allOrganizations]);

    // const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    //     const filteredOrganizations = filterOrganizations(allOrganizations);
    //     // setAnchorEl(event.currentTarget);
    //     // if (filteredOrganizations.length > 0) {
    //     setAnchorEl(event.currentTarget);
    //     setShowFilterMenu(true)
    //     // }
    // }

    // const handleNavigate = () => {
    //     const filteredOrganizations = filterOrganizations(allOrganizations);
    //     if (filteredOrganizations.length > 0) {
    //         const url = selectedOrg?.url;
    //         window.location.href = url;
    //     } else {
    //         return false;
    //     }
    // }

    function startsWithHttps(url: any) {
        if (url && url.startsWith("https://")) {
            const index = url.indexOf("uploads");
            if (index !== -1) {
                return url.slice(index);
            }
        } else {
            return url;
        }
    }

    return (
        <>
            {/* {selectedOrg && selectedOrg.logo !== null ?
                <MAvatar src={process.env.REACT_APP_PUBLIC_URL + '/' + startsWithHttps(selectedOrg.logo)}></MAvatar>
                :
                <>
                   
                    <MAvatar className={classes.avatar} variant="square" >
                        {selectedOrg && selectedOrg.name && avatarPlaceholder(selectedOrg.name)}
                    </MAvatar>
                </>
            } */}

            <MBox display="flex" justifyContent="center" mx={2.5} className="orgTest">
                {/* {allOrganizations && allOrganizations.length > 1 ? */}
                <MBox className={`${mobileView ? classes.btnWrapperMobile : classes.btnWrapper} ${"btnWrapperDrawer"}`}>
                    <MButton color="secondary" className="orgOpener" onClick={handleClick} disableFocusRipple disableRipple>
                        {/* updated code */}
                        {selectedOrg && selectedOrg.logo !== null ?
                            <MAvatar src={process.env.REACT_APP_PUBLIC_URL + '/' + startsWithHttps(selectedOrg.logo)}></MAvatar>
                            :
                            <>
                                <MAvatar className={classes.avatar} variant="square" >
                                    {selectedOrg && selectedOrg.name && avatarPlaceholder(selectedOrg.name)}
                                </MAvatar>
                            </>
                        }
                        {/* old code */}
                        {/* {appUserRole === 'ROLE_VENDOR' && vendorData && vendorData.vendor_logo
                            ?
                            < MAvatar className={classes.avatar} variant="square" src={vendorData && vendorData.vendor_logo
                                ?
                                process.env.REACT_APP_PUBLIC_URL + '/' + vendorData.vendor_logo
                                :
                                ''}
                            >
                                {selectedOrg && avatarPlaceholder(selectedOrg.name)}
                            </MAvatar>
                            :
                            selectedOrg
                                ?
                                < MAvatar className={classes.avatar} variant="square" src={selectedOrg
                                    ?
                                    process.env.REACT_APP_PUBLIC_URL + '/' + startsWithHttps(selectedOrg?.logo && selectedOrg.logo)
                                    :
                                    ''}
                                >
                                    {selectedOrg && avatarPlaceholder(selectedOrg.name)}
                                </MAvatar>
                                :
                                <MAvatar className={classes.avatar} variant="square" >
                                    {selectedOrg && selectedOrg.name && avatarPlaceholder(selectedOrg.name)}
                                </MAvatar>
                        } */}
                        <LightTooltip
                            title={selectedOrg && selectedOrg.name}
                            placement="bottom"
                            arrow
                        >
                            <div>
                                <MBox className={`${classes.orgName} ${classes.orgNameDisable} ${"orgHidden"}`} fontFamily="Poppins">
                                    {organizations && (organizations.length > 1 || (selectedOrg && typeof selectedOrg.id != "undefined" && typeof organizations[0] != "undefined" && typeof organizations[0].id != "undefined" && organizations[0].id !== selectedOrg.id))
                                        ?
                                        <>
                                            <MTypography className={classes.orghidden}>
                                                {selectedOrg && selectedOrg.name}
                                            </MTypography>
                                        </>
                                        :
                                        //if the arrow is not visible then it shows this//
                                        <>
                                            <MTypography className={classes.orgShow}>
                                                {selectedOrg && selectedOrg.name}
                                            </MTypography>
                                        </>
                                    }
                                </MBox>
                            </div>
                        </LightTooltip>
                        {organizations && (organizations.length > 1 || (selectedOrg && typeof selectedOrg.id != "undefined" && typeof organizations[0] != "undefined" && typeof organizations[0].id != "undefined" && organizations[0].id !== selectedOrg.id)) &&
                            <MKeyboardArrowDownRoundedIcon className={`${classes.dropdownIcon} ${"orgIconHidden"}`} />
                        }
                    </MButton>
                </MBox>

                <>
                    {/* {organizations && organizations.length > 0
                            && */}
                    <MMenu
                        style={{ marginLeft: -9 }}
                        className={classes.avatarDropdown}
                        anchorEl={anchorEl}
                        // getContentAnchorEl={null}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: "left"
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: "left"
                        }}
                    >
                        {/* <ListSubheader className={classes.subheader}>
                    <MBox display="flex" justifyContent="space-between" alignItems="center">
                        <MBox pl={1}>
                            <MBox fontSize={16} fontWeight="fontWeightBold" lineHeight={1}>{props.headerText}</MBox>
                        </MBox>
                    </MBox>
                </ListSubheader> */}
                        {organizations && organizations.map((organanization: Organizations) => (
                            <div key={organanization.id}>
                                {(!selectedOrg || selectedOrg.id !== organanization.id) ? <MListItem button className={classes.listItem} key={organanization.id}
                                    onClick={() => selectOrg(organanization)}>
                                    <MListItemAvatar>
                                        {organanization.logo
                                            ?
                                            (<MAvatar variant="circular" src={process.env.REACT_APP_PUBLIC_URL + '/' + organanization.logo} />)
                                            :
                                            (
                                                // <MAvatar variant="circle"
                                                //     sx={{ textTransform: 'uppercase' }}>{organanization.name.charAt(0) + '' + organanization.name.charAt(1)}</MAvatar>)}
                                                <MAvatar variant="circular" sx={{ textTransform: 'uppercase' }}>
                                                    {organanization && avatarPlaceholder(organanization.name)}
                                                </MAvatar>
                                            )
                                        }
                                    </MListItemAvatar>
                                    <MListItemText>
                                        <MBox display="flex" alignItems="center" justifyContent="space-between">
                                            <MBox className={classes.orgTitle}>{organanization.name}</MBox>
                                            <MBox ml={1}>
                                                {/* {(organanization.isAdmin && !organanization.isOwner
                                                                ?
                                                                <MChip label="admin" className={classes.ownerBadge}>
                                                                    <FormattedMessage id="is.admin" />
                                                                </MChip>
                                                                :
                                                                ''
                                                            )} */}
                                                {(organanization.isAdmin && organanization.groups && organanization.groups.length > 0
                                                    ?
                                                    <MChip label={organanization.groups[0].name} className={classes.ownerBadge}>
                                                        {organanization.groups[0].name}
                                                        {/* <FormattedMessage id="is.admin" /> */}
                                                    </MChip>
                                                    :
                                                    ''
                                                )}

                                                {(organanization.isProjectAdmin && !organanization.isOwner
                                                    ?
                                                    <MChip label="PM" className={classes.ownerBadge}>
                                                        <FormattedMessage id="pm" />
                                                    </MChip>
                                                    :
                                                    ''
                                                )}
                                                {(organanization.isVendorAdmin && !organanization.isOwner
                                                    ?
                                                    <MChip label="VM" className={classes.ownerBadge}>
                                                        <FormattedMessage id="vm" />
                                                    </MChip>
                                                    :
                                                    ''
                                                )}
                                                {(organanization.isOwner && appUserRole !== 'ROLE_CUSTOMER' ?
                                                    <MChip label="owner" className={classes.ownerBadge}>
                                                        <FormattedMessage id="owner" />
                                                    </MChip>
                                                    :
                                                    '')
                                                }
                                                {appUserRole === 'ROLE_CUSTOMER' ? <MChip label="LSP" className={classes.ownerBadge}>
                                                    <FormattedMessage id="vendor" />
                                                </MChip> : ''}
                                            </MBox>
                                        </MBox>
                                    </MListItemText>
                                </MListItem> : ''}
                            </div>
                        ))}
                        {/*{organizations && organizations.length > 4 ?*/}
                        {/*(<NavLink className={classes.simpleNavLink} to="/all-organization">*/}
                        {/*<MListItem button className={classes.footer}>*/}
                        {/*<MBox variant="body2" fontSize={14} display="block">*/}
                        {/*<li>*/}
                        {/*<FormattedMessage id="view.job" />*/}
                        {/*</li>*/}
                        {/*</MBox>*/}
                        {/*</MListItem></NavLink>*/}
                        {/*) : ''*/}
                        {/*}*/}
                    </MMenu>
                    {/* } */}
                </>
                {/* } */}

            </MBox >
        </>
    )
}

export default withRouter(OrganizationDropdown);