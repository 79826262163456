import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar:{
            '&.MuiAvatar-root':{
                width: 36,
                height: 36,
                border: '1px solid #d8d9db',
                borderRadius: '50%',
            }
        },
        avatarDropdown:{
            '& ul':{
                padding: 0,
            },
            '& h6':{
                lineHeight: 'normal',
            },
        },
        orgName:{
            whiteSpace: 'nowrap',
            color: '#3a3a3a',
            marginLeft: 10,
            "& > span":{
                display:" block",
                height: 23,
                lineHeight: 1,
                maxWidth: 120,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }
        },
        btnWrapper:{
            borderLeft: "1px solid #ddd",
            "& .orgOpener":{
                padding: "0 20px 0 0!important",
                borderRadius: "0!important",
                "&.MuiButton-root:hover":{
                    backgroundColor: 'transparent',
                },
                "&.singleOrg":{
                    cursor: "default",
                },
            },
        },
        dropdownIcon:{
            color: "#6b778c",
            position: "absolute",
            top: 7,
            right: "-10px",
        },
        projectType:{
            color: "#6b778c",
            lineHeight: 1,
            marginTop: 5,
        },
        viewAllLink:{
            color: theme.palette.secondary.main,
            lineHeight: 2,
            textDecoration: 'none',
        },
        subheader: {
            backgroundColor: theme.palette.background.paper,
            borderBottom: '1px solid #eee',
            padding: theme.spacing(2,3),
            outline: 'none',
            '& div div div':{
                position: 'relative',
            },
        },
        topArrow:{
            position: 'absolute',
            bottom: '0',
            right: 1,
            width: 0,
            height: 0,
            zIndex: 9999999999,
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderBottom: '11px solid #f1e7e7',
        },
        listItem:{
            maxWidth:350,
            minWidth:350,
            padding: theme.spacing(2),
            borderBottom: '1px solid #eee',
            // '& img':{
            //     objectFit:'contain'
            // }
        },
        footer:{
            padding: '20px 24px',
            backgroundColor: '#fafafa',
            '& div':{
                width: '100%',
                textAlign: 'center',
                textTransform: 'uppercase',
            },
        },
        simpleNavLink:{
            color: "#3a3a3a",
            textDecoration: "none", 
        },
        ownerBadge: {
            '&.MuiChip-root':{
                height: 24,
                fontSize: '12px',
                borderRadius: 2,
                textTransform: 'capitalize',
                cursor:'pointer'
            }
        },
        orgTitle:{
            maxWidth: 100,
            minWidth: 190,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        }
    }));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;