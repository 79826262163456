import React, { Fragment } from 'react'

export function MAttachment() {
    return (
        <Fragment>


            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.66307e-05 7.79865C0.021112 6.93479 0.326761 6.01754 1.03885 5.28853C2.42974 3.86514 3.81781 2.43567 5.26871 1.07455C6.4388 -0.0229559 7.86298 -0.277199 9.36215 0.300116C10.8196 0.861511 11.708 1.95199 11.9485 3.50789C12.1411 4.75382 11.7891 5.8621 10.9279 6.78308C10.3152 7.43812 9.67671 8.07116 9.02322 8.6864C8.21691 9.44538 7.07823 9.6013 6.15894 9.1181C4.73664 8.37129 4.4263 6.59205 5.51623 5.32973C5.88282 4.90552 6.29348 4.51784 6.69992 4.13015C7.00369 3.84032 7.38153 3.80614 7.68718 4.00326C8.1555 4.3048 8.23238 4.81001 7.85313 5.21736C7.53951 5.55448 7.20058 5.86771 6.88227 6.20015C6.75945 6.32844 6.64225 6.47031 6.55834 6.62529C6.39567 6.92636 6.41489 7.22555 6.64507 7.49525C6.87524 7.76494 7.20621 7.87544 7.53014 7.74715C7.71343 7.67457 7.89391 7.5608 8.03596 7.42455C8.59288 6.8889 9.14042 6.34296 9.67952 5.78952C10.6537 4.78894 10.6443 3.32201 9.66733 2.35092C8.68616 1.37562 7.22027 1.36485 6.22503 2.34577C4.9354 3.61745 3.6589 4.90178 2.37864 6.18329C1.57373 6.9891 1.37356 8.05664 1.84188 9.01275C2.29988 9.94825 3.31059 10.4816 4.41224 10.343C4.71835 10.3046 5.02025 10.2109 5.31653 10.1177C5.74453 9.98383 6.08205 10.0583 6.30426 10.365C6.55506 10.7105 6.52178 11.0561 6.18472 11.3379C5.73937 11.7106 5.19699 11.8694 4.63772 11.9546C2.21691 12.3235 -0.00701517 10.3982 1.66307e-05 7.79865Z" fill="#949494" />
            </svg>

        </Fragment>
    )
}
