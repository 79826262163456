// import { InMemoryCache, } from "apollo-boost";

import { ApolloLink, concat, ApolloClient, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

import { showAlert } from "../store";
import store from "../store/store";

const httpLink = createUploadLink({
    // uri: 'https://ent.marstranslation.com/graphiql/',
    uri: process.env.REACT_APP_API_END_POINT + '/graphiql/',
    // uri: 'http://local.mtenterprise.com/graphiql/',
});

// const httpLink = new HttpLink({
//     uri: process.env.REACT_APP_API_END_POINT + '/graphiql/',
// });

const authMiddleware = () => new ApolloLink((operation, forward) => {

    operation.setContext({
        headers: {
            "keep-alive": "true",
            "accept": "application/json",
            "content-type": 'application/json',
            "authorization": 'Bearer ' + localStorage.getItem('access_token'),
            "Cache-Control": "public, max-age=31536000, immutable",
        }
    });

    return forward(operation);
});

const errors = (store: any) => onError(({ graphQLErrors, networkError }: any) => {

    if (graphQLErrors && graphQLErrors.length > 0 && graphQLErrors[0].message && graphQLErrors[0].message == 'Data not found') {
        // windo
    } else if (graphQLErrors && graphQLErrors.length > 0 && graphQLErrors[0].message && graphQLErrors[0].message != 'Email does not exist') {
        store.dispatch(showAlert({
            message: graphQLErrors[0].message && graphQLErrors[0].message,
            messageType: 'error',
            showAlertMessage: true,
            graphqlError: true
        }));
    }

    if (networkError) {
        store.dispatch(showAlert({
            message: 'Network error',
            messageType: 'error',
            showAlertMessage: true,
            graphqlError: true
        }));
    }

    if (networkError && networkError.statusCode === 401) {
        localStorage.clear();

        window.location.href = (process.env.REACT_APP_SSO_ENDPOINT + '/login?redirect_url=' + window.location.origin);
    }
});

const graphqlHooks = concat(errors(store), authMiddleware());

export const client: any = new ApolloClient({
    link: concat(graphqlHooks, httpLink),
    cache: new InMemoryCache({
        addTypename: false,
    })
});