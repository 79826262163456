import { put, takeEvery } from "redux-saga/effects";
import { gql } from 'apollo-boost';
import { client } from "src/app/hooks";
import { GET_NOTIFICATION } from "./NotificationActionType";

const query = gql`
query getAllNotification($limit: Int,$page: Int){
    getAllNotification(limit: $limit,page: $page) {
         
         notifications{
              id
             type
             description
             status
             createdAt
             minutes
       }
       total
    }
}
`;
function* getNotificationFetch(variables: any): any {
    try {

        const notification = yield client.query({ query, variables, fetchPolicy: "no-cache" });


        // if (variables.type == 'GET_RECENT_PROJECT_FETCH') {
        yield put({ type: 'GET_NOTIFICATION_SUCCESS', response: notification.data.getAllNotification.notifications });

        // } else {
        //   yield put({ type: 'GET_PROJECT_SUCCESS', response: projectList.data.organizations });
        // }
    } catch (error) {
        console.log('error ===>> ', error);

        // yield put({ type: 'API_CALL_FAILURE', error });
    }
}

export default function* notificationSaga() {
    yield takeEvery(GET_NOTIFICATION, getNotificationFetch);

}