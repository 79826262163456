
import { put, takeEvery } from "redux-saga/effects";

import { gql } from 'apollo-boost';
import { client } from "src/app/hooks";
import { GET_SUPPLIERS_FETCH } from "./FindNewSuppliersActionTypes";

const query = gql`
query GetAllVendorsQuery($companyName:String,$service:[ID],$page:ID,$orgId: ID, $role: String ,$industry: Int ,$sourceLanguageId: [Int], $targetLanguageId: [Int], $countryId: [Int] ) {
    getAllVendors(companyName: $companyName,service: $service, page:$page,orgId: $orgId,role:$role,industry:$industry,sourceLanguageId:$sourceLanguageId, targetLanguageId: $targetLanguageId, countryId: $countryId ) {
        total
        vendors {
            id
            logo
            vendor_profile_id
            company_name
            about
            isAlreadyAdded
            total_projects
            totalProject
            vendor_avg_rating
            vendor_url_slug
            role
            languages{
                name
                code
            }languagesTo{
                id
                name
                code
            }
            services {
                name
            }
        }
    }
}
    `;

function* getNewSuppliersFetch(variables: any): any {
    try {

        const newSupplierList = yield client.query({ query, variables, fetchPolicy: "no-cache" });



        yield put({ type: 'GET_SUPPLIER_SUCCESS', response: newSupplierList.data.getAllVendors });

    } catch (error) {
        console.log('error', error);
        // yield put({ type: 'API_CALL_FAILURE', error });
    }
}

export default function* findNewSuppliersSaga() {
    yield takeEvery(GET_SUPPLIERS_FETCH, getNewSuppliersFetch);
    //   yield takeEvery(GET_RECENT_PROJECT_FETCH, getProjectFetch);
}