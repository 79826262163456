import * as React from "react";
import { useState, useEffect } from 'react';
import { MKeyboardArrowDownRoundedIcon } from "src/app/material-ui/icons/keyboard-rounded-icon/KeyboardArrowDownRoundedIcon";
import useStyles from "./OrganizationDropdownStyles";
import {
  MButton,
  MMenu,
  MListItem,
  MAvatar,
  MListItemAvatar,
  MListItemText,
  MBox,
  MChip,
} from "src/app/material-ui";
import { useDispatch, useSelector } from "react-redux";
import { selectOrganization } from "src/app/store";
import ListSubheader from "@mui/material/ListSubheader";

import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

import { styled } from "@mui/material/styles";

interface Organizations {
  id: number;
  name: string;
  logo: string;
  isOwner: boolean;
  isAdmin: boolean;
  groups: any;
  isProjectAdmin: boolean;
  isVendorAdmin: boolean;
}

function OrganizationDropdown(props: any) {
  const dispatch = useDispatch();
  const classes = useStyles({});
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedOrg, setSelectedOrg]: any = useState();
  const allOrganizations = useSelector(
    (store: any) => store.orgs.organizations
  );
  const [organizations, setOrganizations] = useState(
    allOrganizations && allOrganizations
  );
  const appUserRole = useSelector((store: any) => store.userRole.userRole);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectOrg = (organanization: Organizations) => {
    localStorage.setItem("selectedOrgId", String(organanization.id));
    const newOrganizations: Array<Organizations> = organizations;
    newOrganizations.push(selectedOrg);
    setOrganizations(newOrganizations);
    dispatch(selectOrganization(organanization.id));
    setSelectedOrg({
      id: organanization.id,
      name: organanization.name,
      logo: organanization.logo,
      isOwner: organanization.isOwner,
    });
    handleClose();
    props.history.push(
      appUserRole === "ROLE_CLIENT" ? "/projects" : "/vendor/open-jobs"
    );
  };

  useEffect(() => {
    if (selectedOrg) {
      const newOrganizations = allOrganizations.filter(
        (org: Organizations) => org.id !== selectedOrg.id
      );
      setOrganizations(newOrganizations);
    }
  }, [selectedOrg]);

  useEffect(() => {
    setTimeout(() => {
      if (localStorage.getItem("selectedOrgId")) {
        dispatch(
          selectOrganization(Number(localStorage.getItem("selectedOrgId")))
        );
        const oldSelectedOrg = organizations.filter(
          (org: Organizations) =>
            org.id === Number(localStorage.getItem("selectedOrgId"))
        );
        setSelectedOrg(oldSelectedOrg[0]);
      } else {
        setSelectedOrg(organizations[0]);
      }
    }, 500);
  }, []);

  useEffect(() => {
    setOrganizations(allOrganizations);
    setSelectedOrg(allOrganizations[0]);
  }, [allOrganizations]);

  // const myRef1: any = React.useRef(null); // 13030105521200
  // const myRef2: any = React.useRef(null);

  // const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  //   <Tooltip {...props} classes={{ popper: className }} />
  // ))(({ theme }) => ({
  //   [`& .${tooltipClasses.tooltip}`]: {
  //     backgroundColor: theme.palette.common.white,
  //     color: "#6b778c",
  //     fontSize: 12,
  //     filter: "drop-shadow(0px 8px 16px rgba(214,215,226,0.57))",
  //     border: "1px solid #f4f5f7",
  //     borderRadius: 0,
  //     padding: "20px 15px",
  //     maxWidth: 230,
  //   },
  //   arrow: {
  //     color: theme.palette.common.white,
  //   },
  // }));

  const LightTooltip = styled(
    React.forwardRef(function LightTooltip({ className, ...props }: TooltipProps, ref) {
      return <Tooltip {...props} classes={{ popper: className }} ref={ref} />;
    })
  )(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "#6b778c",
      fontSize: 12,
      filter: "drop-shadow(0px 8px 16px rgba(214,215,226,0.57))",
      border: "1px solid #f4f5f7",
      borderRadius: 0,
      padding: "20px 15px",
      maxWidth: 230,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
    },
  }));


  return (
    <div>
      {allOrganizations && allOrganizations?.length > 1 ? (
        <MBox pl={3} className={classes.btnWrapper} mr={4}>
          <MButton
            color="secondary"
            className="orgOpener"
            onClick={handleClick}
            disableFocusRipple
            disableRipple
          >
            <MAvatar
              className={classes.avatar}
              variant="square"
              src={
                selectedOrg &&
                process.env.REACT_APP_PUBLIC_URL + "/" + selectedOrg.logo
              }
            />
            {selectedOrg?.name && (
              <LightTooltip
                title={selectedOrg.name}
                placement="bottom"
                arrow
              >
                <MBox
                  className={classes.orgName}
                  fontSize={20}
                  fontFamily="Poppins"
                  textAlign="left"
                >
                  <span>{selectedOrg.name}</span>
                </MBox>
              </LightTooltip>
            )}
            <MKeyboardArrowDownRoundedIcon className={classes.dropdownIcon} />
          </MButton>
        </MBox>
      ) : (
        <MBox pl={3} className={classes.btnWrapper} mr={4}>
          <MButton
            className={`orgOpener singleOrg`}
            disableFocusRipple
            disableRipple
          >
            <MAvatar
              className={classes.avatar}
              variant="square"
              src={
                selectedOrg &&
                process.env.REACT_APP_PUBLIC_URL + "/" + selectedOrg.logo
              }
            />
            {selectedOrg?.name && (
              <LightTooltip
                title={selectedOrg?.name ? selectedOrg.name : ""}
                placement="bottom"
                arrow
              >
                <MBox
                  className={classes.orgName}
                  fontSize={20}
                  textAlign="left"
                >
                  <span>{selectedOrg?.name ? selectedOrg.name : ""}</span>
                </MBox>
              </LightTooltip>
            )}
          </MButton>
        </MBox>
      )}

      {organizations && organizations?.length > 0 &&
        <MMenu
          className={classes.avatarDropdown}
          anchorEl={anchorEl}
          // getContentAnchorEl={null}
          keepMounted
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: "left"
          }}
        >

          <ListSubheader className={classes.subheader}>
            <MBox display="flex" justifyContent="space-between" alignItems="center">
              <MBox pl={1}>
                <MBox fontSize={16} fontWeight="fontWeightBold" lineHeight={1}>
                  {props.headerText}
                </MBox>
              </MBox>
            </MBox>
          </ListSubheader>

          {organizations && organizations?.map((organanization: Organizations) => (
            <MListItem key={organanization?.id} button className={classes.listItem}
              onClick={() => selectOrg(organanization)}>
              <MListItemAvatar key={1}>
                {organanization.logo
                  ?
                  (<MAvatar variant="circular" src={process.env.REACT_APP_PUBLIC_URL + '/' + organanization.logo} />)
                  :
                  (
                    <MAvatar variant="circular" style={{ textTransform: 'uppercase' }}>
                      {organanization.name.charAt(0) + '' + organanization.name.charAt(1)}
                    </MAvatar>
                  )
                }
              </MListItemAvatar>
              <MListItemText key={2}>
                <MBox display="flex" alignItems="center" justifyContent="space-between" key={1}>
                  <MBox className={classes.orgTitle}>
                    {organanization.name}
                  </MBox>

                  <MBox ml={1}>
                    {(organanization.isAdmin && organanization.groups && organanization.groups.length > 0
                      ?
                      <MChip label="admin" className={classes.ownerBadge}>
                        {organanization.groups[0].name}
                        {/* <FormattedMessage id="is.admin" /> */}
                      </MChip>
                      :
                      ''
                    )}

                    {(organanization.isProjectAdmin && !organanization.isOwner
                      ?
                      <MChip label="PM" className={classes.ownerBadge}>
                        <FormattedMessage id="pm" />
                      </MChip>
                      :
                      ''
                    )}

                    {(organanization.isVendorAdmin && !organanization.isOwner
                      ?
                      <MChip label="VM" className={classes.ownerBadge}>
                        <FormattedMessage id="vm" />
                      </MChip>
                      :
                      ''
                    )}

                    {(organanization.isOwner
                      ?
                      <MChip label="owner" className={classes.ownerBadge}>
                        <FormattedMessage id="owner" />
                      </MChip>
                      :
                      ''
                    )}
                  </MBox>
                </MBox>
              </MListItemText>
            </MListItem>
          ))}
        </MMenu>
      }
    </div>
  )
}

export default withRouter(OrganizationDropdown);