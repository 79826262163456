import { FETCH_SOURCE_LANGUAGE_FAILURE, FETCH_SOURCE_LANGUAGE_REQUEST, FETCH_SOURCE_LANGUAGE_SUCCESS } from "./SourceLanguageActionTypes";


interface IAction {
    type: string,
    payload: any,
    action: any,
}
interface IState {
    list: any,
    loading: boolean,
    error: any
}
const initialState: IState = {
    list: null,
    loading: false,
    error: null
};

export default function sourceLanguageReducer(state = initialState, action: IAction) {
    switch (action.type) {
        case FETCH_SOURCE_LANGUAGE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_SOURCE_LANGUAGE_SUCCESS:
            return {
                ...state,
                list: action.payload,
                loading: false
            };
        case FETCH_SOURCE_LANGUAGE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}