import { put, takeEvery } from 'redux-saga/effects';
import { gql } from 'apollo-boost';
import { client } from "src/app/hooks";
import { fetchCustomersFailure, fetchCustomersSuccess } from './CustomerActions';
import { FETCH_CUSTOMERS_REQUEST } from './CustomerActionTypes';

const GETALLCUSTOMERS = gql`


    query getAllCustomers($name: String, $page: Int,$orgName: String, $email: String, $orgId: ID, $customerId: ID,$role:String,$createdDate:String,$sm:String,$pm:String) {
        getAllCustomers(name: $name, page:$page, orgId: $orgId, customerId: $customerId, role: $role, orgName: $orgName, email: $email,createdDate: $createdDate,pm: $pm,sm: $sm) {

            customers{
                canInvite
                id
                firstName
                lastName
                name
                email
                phone
                createdAt
                isInvited
                isInvitedClass
                address
                postalCode
                customerManagers {
                    customerId
                    managerId
                    role
                    username
                }
                country {
                    id
                    countryName
                }
                state {
                    id
                    stateName
                }
                city {
                    id
                    cityName
                }
                organization{
                    id
                    name
                    url
                    address
                    description
                    orgIndustries{
                        id
                        name
                    }
                }
                
            }
            total

        }
    }
`;

// GET ALL CUSTOMERS

function* fetchCustomers(action: any): any {
    const variables = action.payload || {};
    try {

        const customers = yield client.query({ query: GETALLCUSTOMERS, variables, fetchPolicy: "no-cache" });
        yield put(fetchCustomersSuccess(customers.data));

        // check store updated sucessfully
        // yield select(store => console.log(store.customers) );

    } catch (error) {
        yield put(fetchCustomersFailure(error));
    }
}

// for multiple generator function
export default function* customersSaga() {
    yield takeEvery(FETCH_CUSTOMERS_REQUEST, fetchCustomers);

}