
import { RESET_STORE } from '../resetStateAction';
import { GET_SUPPLIERS_FETCH, GET_SUPPLIER_SUCCESS } from './FindNewSuppliersActionTypes';

interface IAction {
    type: string,
    payload: any,
    response: any,
    action: any,
    supplierLoading: boolean,
    findNewSupplier: any,
}

interface IState {
    findNewSupplier: any,
    supplierLoading: boolean

}

const initialState: IState = {

    findNewSupplier: [],
    supplierLoading: false,

};


const findNewSuppliersReducer = (state = initialState, action: IAction) => {

    switch (action.type) {

        case GET_SUPPLIERS_FETCH:
            return {
                ...state,
                supplierLoading: true
            }
        case GET_SUPPLIER_SUCCESS:
            return {
                ...state,
                findNewSupplier: action.response,
                supplierLoading: false,


            }

        case RESET_STORE:
            return initialState;
        default:
            return state;
    }
};

export default findNewSuppliersReducer;