
import { RESET_STORE } from '../resetStateAction';
import { GET_PROJECT_TASK_FETCH, GET_PROJECT_TASK_FETCH_SUCCESS } from './ProjectTaskActionTypes';

interface IAction {
    type: string,
    payload: any,
    response: any,
    action: any,
    projectTask: any,
    projectTaskLoading: boolean,

}

interface IState {
    projectTask: any,
    projectTaskLoading: boolean
}

const initialState: IState = {
    projectTask: [],
    projectTaskLoading: false,
};


const projectTaskReducer = (state = initialState, action: IAction) => {

    switch (action.type) {
        case GET_PROJECT_TASK_FETCH:
            return {
                ...state,
                projectTaskLoading: true
            }
        case GET_PROJECT_TASK_FETCH_SUCCESS:
            return {
                ...state,
                projectTask: action.response,
                projectTaskLoading: false
            }
        case RESET_STORE:
            return initialState;
        default:
            return state;
    }
};

export default projectTaskReducer;