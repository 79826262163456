import React, { Fragment } from 'react'

export function MVisaCard() {
    return (
        <Fragment>

            <svg width="60" height="36" viewBox="0 0 60 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M16.8628 10.122C16.8628 10.5973 16.7922 10.9641 16.6109 11.2173L10.3736 22.3669L9.98063 8.76831H0L3.35039 10.4113C4.31268 11.0313 4.81146 11.8373 4.81146 12.8603L5.73849 27.301H10.6205L22.3141 8.76831H16.3237C16.6814 9.09897 16.8577 9.61047 16.8577 10.122H16.8628Z" fill="#1F347A" />
                <path fillRule="evenodd" clipRule="evenodd" d="M57.2185 9.32086L57.6115 8.77319H51.5153C51.7269 8.99019 51.8025 9.10386 51.8025 9.17619C51.7319 9.47069 51.5908 9.65152 51.5153 9.86852L42.638 26.4637C42.4264 26.7582 42.2098 27.0114 41.9629 27.3059H46.991L46.7391 26.7582C46.7391 26.2829 46.991 25.4046 47.525 24.1646L48.5931 22.1186H53.7623C53.9739 23.8391 54.12 25.2651 54.1905 26.3242L53.7623 27.311H59.9996L59.0725 25.9212L57.2185 9.32602V9.32086ZM53.5104 19.5198H49.8023L52.9764 13.2682L53.5104 19.5198Z" fill="#1F347A" />
                <path fillRule="evenodd" clipRule="evenodd" d="M38.11 7.81767C36.1854 7.81767 34.5429 8.36533 33.0819 9.46065C31.4797 10.4113 30.6938 11.6203 30.6938 13.1186C30.6938 14.8391 31.2631 16.3374 32.4067 17.4689L35.8629 19.9179C37.1124 20.8324 37.717 21.6384 37.717 22.3669C37.717 23.0954 37.3945 23.6844 36.79 24.1597C36.1854 24.635 35.5052 24.852 34.6538 24.852C33.4043 24.852 31.2681 23.9375 28.3057 22.2584V25.658C30.6232 27.084 32.8703 27.8125 35.0417 27.8125C37.2132 27.8125 38.8556 27.2287 40.3519 25.9112C41.9541 24.7022 42.74 23.2814 42.74 21.7056C42.74 20.3881 42.1707 19.1842 40.886 17.9029L37.5709 15.4539C36.4675 14.5394 35.8579 13.7335 35.8579 13.1135C35.8579 11.6875 36.6791 10.9538 38.3518 10.9538C39.4904 10.9538 41.1681 11.61 43.4152 12.9998L44.0903 9.45549C42.201 8.36016 40.2411 7.8125 38.0999 7.8125L38.11 7.81767Z" fill="#1F347A" />
                <path fillRule="evenodd" clipRule="evenodd" d="M24.8478 27.3062C24.7773 26.5363 24.6312 25.844 24.5607 25.1103L29.09 10.122L30.1581 8.76831H23.6336C23.7042 9.09897 23.7747 9.53814 23.8452 9.86363C23.8452 10.1943 23.8452 10.5973 23.7747 10.959L19.2454 25.8027L18.3184 27.301H24.8428L24.8478 27.3062Z" fill="#1F347A" />
            </svg>
        </Fragment>
    )
}
