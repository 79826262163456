import React, { Fragment } from 'react'

export function MClearIcon(props: any) {
    return (
        <Fragment>
            {props ?
                <svg {...props} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.8346 1.34166L10.6596 0.166656L6.0013 4.82499L1.34297 0.166656L0.167969 1.34166L4.8263 5.99999L0.167969 10.6583L1.34297 11.8333L6.0013 7.17499L10.6596 11.8333L11.8346 10.6583L7.1763 5.99999L11.8346 1.34166Z" fill="#FF0102" />
                </svg>
                :
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.8346 1.34166L10.6596 0.166656L6.0013 4.82499L1.34297 0.166656L0.167969 1.34166L4.8263 5.99999L0.167969 10.6583L1.34297 11.8333L6.0013 7.17499L10.6596 11.8333L11.8346 10.6583L7.1763 5.99999L11.8346 1.34166Z" fill="#FF0102" />
                </svg>
            }
        </Fragment>
    )
}
