import React from 'react';
import { FormattedMessage } from "react-intl";
import {
    MBox,
    MButton,
    MSkeleton,
    MFormik, MForm, MTextField,
    MAutoComplete, MCircularProgress,
} from 'src/app/material-ui';

import { IService, ILanguage, IStatus } from './interface';
import useStyles from './JobListingStyles';

function JobListingFilterDsk(props: any) {
    const classes = useStyles({});
    return (
        <MFormik
            enableReinitialize="true"
            initialValues={{
                clientName: '',
                jobsTitle: '',
                sourceLanguage: [],
                service: [],
                status: [],
            }}
            onSubmit={(values: any) => {
                const url = new URL(window.location.href);
                url.searchParams.delete('id');
                window.history.replaceState({}, '', url);
                if (props && values) {
                    props.setFormJobTitle(values.jobsTitle);
                    props.setClientName(values.clientName);
                    props.setServicesFieldValue(values.service.map((val: IService) => val.id).join(','));
                    props.setStatusFieldValue(values.status.map((val: IStatus) => val.id).join(','));
                    props.setLanguagesFieldValue(values.sourceLanguage.map((val: ILanguage) => val.id).join(','));
                    //  props.getAllJobs();
                    props.setFilter(!props.filter);
                }
            }}
        >
            {({ setFieldValue, values, resetForm }: any) => (
                <>
                    {(!props.jobsList)
                        ?
                        <MForm>
                            <MBox mt={3} mb={1} display="flex">
                                <MBox maxWidth={168} mr={1}>
                                    <MSkeleton width={120} height={30} />
                                </MBox>
                                <MBox maxWidth={168} mr={1}>
                                    <MSkeleton width={120} height={30} />
                                </MBox>
                                <MBox maxWidth={168} mr={1}>
                                    <MSkeleton width={120} height={30} />
                                </MBox>
                                <MBox maxWidth={168} mr={1}>
                                    <MSkeleton width={120} height={30} />
                                </MBox>
                                <MBox maxWidth={168} mr={1}>
                                    <MSkeleton width={120} height={30} />
                                </MBox>
                                <MBox maxWidth={168} >
                                    <MSkeleton width={100} height={30} />
                                </MBox>
                            </MBox>
                        </MForm>
                        :
                        <MForm>
                            {(props.openProjectsLoading || (props.jobsList && props.jobsList.length > 0) ||
                                ((props.clientName !== "") || (props.formJobTitle !== "") || (props.languagesFieldValue !== "") || (props.statusFieldValue !== "") || (props.servicesFieldValue !== ""))) &&
                                <MBox mt={3} mb={1} display="flex">
                                    <MBox maxWidth={168} mr={1}>
                                        <FormattedMessage id="job.title">
                                            {jobTitle =>
                                                <MTextField
                                                    className={classes.seachInput}
                                                    variant="outlined"
                                                    // color="secondary"
                                                    size="small"
                                                    placeholder={jobTitle + ""}
                                                    name="jobsTitle"
                                                    disabled={false}
                                                    defaultValue={values.companyName}
                                                    fullWidth
                                                />
                                            }
                                        </FormattedMessage>
                                    </MBox>
                                    {props.hideFilter != true &&
                                        <MBox maxWidth={168} mr={1}>
                                            <FormattedMessage id="client.name">
                                                {clientName =>
                                                    <MTextField
                                                        className={classes.seachInput}
                                                        variant="outlined"
                                                        // color="secondary"
                                                        size="small"
                                                        placeholder={clientName + ""}
                                                        name="clientName"
                                                        disabled={false}
                                                        defaultValue={values.companyName}
                                                        fullWidth
                                                    />
                                                }
                                            </FormattedMessage>
                                        </MBox>
                                    }
                                    <MBox width={168} mr={1}>
                                        {props?.statusArray && (
                                            <FormattedMessage id="status">
                                                {label =>
                                                    <MAutoComplete
                                                        className={classes.autoCompleteSizeSm}
                                                        id="statusAutocomplete"
                                                        label={(values.status.length > 0 ? "" : label + "")}
                                                        value={values.status}
                                                        multiple={true}
                                                        options={props.statusArray}
                                                        getOptionLabel={(option: IStatus) => option.name || ""}
                                                        disableClearable={true}
                                                        onChange={(_event: React.ChangeEvent, value: IStatus[]) => {
                                                            setFieldValue('status', value);
                                                            // if(value[0].id == 12 && props.status == 'open') {
                                                            //     setFieldValue('status', {id:'8',name:'PO Review'})
                                                            // }
                                                        }}

                                                    />
                                                }
                                            </FormattedMessage>
                                        )}
                                    </MBox>
                                    <MBox width={168} mr={1} >
                                        <FormattedMessage id="services">
                                            {label =>
                                                <MAutoComplete
                                                    className={classes.autoCompleteSizeSm}
                                                    id="serviceAutocomplete"
                                                    label={(values.service.length > 0 ? "" : String(label))}
                                                    value={values.service}
                                                    multiple={true}
                                                    options={props.serviceData && props.serviceData}
                                                    getOptionLabel={(option: IService) => option.name || ""}
                                                    disableClearable={true}
                                                    onChange={(_event: React.ChangeEvent, value: IService[]) => {
                                                        setFieldValue('service', value)
                                                    }}

                                                />
                                            }
                                        </FormattedMessage>
                                    </MBox>

                                    <MBox>
                                        <MBox width={168} mr={1}
                                        >
                                            <FormattedMessage id="languages">
                                                {label =>
                                                    <MAutoComplete
                                                        className={classes.autoCompleteSizeSm}
                                                        id="sourceLanguageAutocomplete"
                                                        label={(values.sourceLanguage.length > 0 ? "" : String(label))}
                                                        multiple={true}
                                                        value={values.sourceLanguage}
                                                        options={props.languagesData && props.languagesData}
                                                        getOptionLabel={(option: ILanguage) => (option?.name && option?.code) ? option.name + ' (' + option.code + ') ' : ""}
                                                        disableClearable={true}
                                                        onChange={(_event: React.ChangeEvent, value: ILanguage[]) => {
                                                            setFieldValue('sourceLanguage', value)
                                                        }}
                                                    />
                                                }
                                            </FormattedMessage>
                                        </MBox>
                                    </MBox>
                                    <MBox display="flex">
                                        <MButton
                                            className={classes.btnSmall}
                                            color="primary"
                                            disabled={props.openProjectsLoading}
                                            variant="contained"
                                            type="submit"
                                        >
                                            <FormattedMessage id="search" />
                                            {props.openProjectsLoading &&
                                                <MCircularProgress color="secondary" size={24} />
                                            }
                                        </MButton>
                                        {(values.clientName != '' || values.jobsTitle != '' || values.sourceLanguage.length > 0 || values.service.length > 0 || values.status.length > 0) ?
                                            <MButton className={classes.clearAll} color="secondary"
                                                type="button"
                                                onClick={(e: any) => {
                                                    resetForm();
                                                    setFieldValue('service', []);
                                                    setFieldValue('sourceLanguage', []);
                                                    if (props) {
                                                        props.setFormJobTitle('');
                                                        props.setClientName('');
                                                        props.setServicesFieldValue('');
                                                        props.setStatusFieldValue('');
                                                        props.setLanguagesFieldValue('');
                                                        props.setFilter(!props.filter)
                                                    }
                                                    e?.preventDefault();
                                                    //   props.getAllJobs()
                                                }}>
                                                <FormattedMessage id="clear.all" />
                                            </MButton> : ''
                                        }
                                    </MBox>
                                </MBox>
                            }
                        </MForm>
                    }
                </>
            )}
        </MFormik>
    )
}
export default JobListingFilterDsk;