import React, { Fragment } from 'react'

export function MArrowDown() {
    return (
        <Fragment>

            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="12" fill="#F3F4F5" />
                <path d="M15.94 9.93945L12 13.8795L8.06 9.93945L7 10.9995L12 15.9995L17 10.9995L15.94 9.93945Z" fill="#42526E" />
            </svg>

        </Fragment>

    )
}
