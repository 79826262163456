import { FETCH_JOB_FILES_REQUEST, FETCH_JOB_FILES_SUCCESS, FETCH_JOB_FILES_FAILURE } from './JobActionTypes';


interface IAction {
    type: string,
    payload: any,
    action: any,
}
interface IState {
    jobFilesList: any,
    jobFilesLoading: boolean,
    error: any
}
const initialState: IState = {
    jobFilesList: null,
    jobFilesLoading: false,
    error: null
};

export default function JobFilesReducer(state = initialState, action: IAction) {
    switch (action.type) {
        case FETCH_JOB_FILES_REQUEST:
            return {
                ...state,
                jobFilesLoading: true,
                error: null
            };
        case FETCH_JOB_FILES_SUCCESS:
            return {
                ...state,
                jobFilesList: action.payload,
                jobFilesLoading: false
            };
        case FETCH_JOB_FILES_FAILURE:
            return {
                ...state,
                jobFilesLoading: false,
                error: action.payload
            };
        default:
            return state;
    }
}