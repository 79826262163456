import React from 'react';

export function MClockEditOutline() {
    return   <React.Fragment>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.75 9.825C15.675 9.825 15.525 9.9 15.45 9.975L14.7 10.725L16.275 12.3L17.025 11.55C17.175 11.4 17.175 11.1 17.025 10.95L16.05 9.975C15.975 9.9 15.9 9.825 15.75 9.825ZM14.325 11.175L9.75 15.675V17.25H11.325L15.9 12.675L14.325 11.175ZM9.375 5.25V9.15L12.375 10.95L11.625 11.7L8.25 9.75V5.25H9.375ZM8.25 16.425C4.425 16.05 1.5 12.825 1.5 9C1.5 4.875 4.875 1.5 9 1.5C12.975 1.5 16.2 4.575 16.5 8.475C16.275 8.4 16.05 8.325 15.75 8.325C15.45 8.325 15.225 8.4 15 8.475C14.7 5.4 12.15 3 9 3C5.7 3 3 5.7 3 9C3 12.075 5.325 14.625 8.325 14.925L8.25 15.075V16.425Z" fill="#3F74E8" />
        </svg>

        </React.Fragment>;
}


