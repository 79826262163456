/* eslint-disable */
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete } from "@mui/material";
import { gql } from 'apollo-boost';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { FormErrorMessage, SourceLanguageSelect, TargetLanguageSelect } from 'src/app/components';
import {
    MBox,
    MButton,
    MChip, MForm,
    MFormik,
    MFormLabel,
    MGrid,
    MTextField,
    MLink,
    MCircularProgress,
    MDrawer
} from 'src/app/material-ui';
import { selectedRateName, showAlert } from "src/app/store";
import useStyles from './AddRatesStyles';
import { FormattedMessage, useIntl } from "react-intl";
import { withRouter } from 'react-router-dom';
import { MKeyboardArrowDownRoundedIcon } from "src/app/material-ui/icons";
import AddServices from "src/app/screens/vendor/services/edit-services/EditServices";
import AddIcon from '@mui/icons-material/Add';

interface tags {
    id: number,
    username: string
}
const GET_PROJECT_PACKAGE_DETAILS = gql`
    query getAllPackages($role:String, $orgId: Int){
        getAllPackages(role:$role, orgId: $orgId){
        packages{
            id
            name
            }
        }
    }
    `;
// const USER_EMAILS_GQL = gql`
//     query userEmails($email: String, $moduleName: String) {
//         userEmails(email: $email,moduleName: $moduleName) {
//             id
//             username
//             defaultRole
//         }
//     }
// `;

const GET_ACTIVE_INDUSTRIES = gql`
{
    getIndustries {
        id
        name
        
    }
 } 
`;
const VENDOR_RATE_ID = gql`
    query getVendorRates($rateId: String, $role:String, $orgId: Int) {
        getVendorRates(rateId:$rateId, role:$role, orgId: $orgId) {
            id
            price
            subject
            packageName
            packageId
            clients {
                id
                username
            }
            sourceLanguage {
                id
                name
                code
            }
            targetLanguages {
                id
                name
                code
            }
        }
    }
`;



const ADD_VENDORS_RATE = gql`
    mutation setVendorRates($rateId: Int, $packageId: Int!,$slId: Int!,$price: String,$subject: String,$tlIds: String,$clientIds: String,$role: String,$orgId:Int){
        setVendorRates(rateId: $rateId, packageId:$packageId,slId:$slId,price:$price,subject:$subject,tlIds:$tlIds,clientIds:$clientIds,role:$role,orgId:$orgId) 
    }
`;
const AddNewRates = (props: any) => {

    const dispatch = useDispatch();
    const classes = useStyles({});
    const intl = useIntl();

    const [targetLanguages, setTargetLanguages]: any = useState([]);
    const [packageName, setPackageName]: any = useState([]);
    const [activeIndustries, setActiveIndustries]: any = useState([]);
    // const [ setDataUserEmails]: any = useState([]);
    const [packageId, setpackageId]: any = useState([]);
    const [email, setEmail]: any = useState([]);
    const [setTab]: any = useState('');
    const [slId, setSlId] = useState();
    // const [setCheckClientId]: any = useState([]);
    const [vendorIdRateData, setVendorIdRateData]: any = useState([]);
    const appUserRole = useSelector((store: any) => store.userRole.userRole);

    const selectedOrgId: any = useSelector((store: any) => store.orgs.selectedOrgId);

    const isOwner = useSelector((store: any) => store.orgs.isOwner);
    const isAdmin = useSelector((store: any) => store.orgs.isAdmin);

    const [addServiceOpen, setAddServiceOpen]: any = useState(false);
    const [serviceType, setServiceType]: any = useState("");
    const [selectServiceId, setSelectServiceId]: any = useState();
    const [getRefresh, setGetRefresh]: any = useState();
    let customerId = 0;
    const urlType = window.location.href.split('/').slice(3, 6).join('/');
    if (urlType == 'customers/price/contracts') {
        customerId = Number(window.location.href.split('/').pop());
    }


    const [addVendorsRate, { loading: addLoading }] = useMutation(ADD_VENDORS_RATE, {
        onCompleted: () => {
            props.setAddRate(false);
            props.getVendorsRateDetails(appUserRole === "ROLE_TRANSLATOR" ? ({ variables: { moduleName: "client", page: 1 } }) : (urlType == 'customers/price/contracts' ? { variables: { orgId: +selectedOrgId, customerId: customerId } } : (!isOwner && isAdmin && { variables: { orgId: +selectedOrgId } })));
            dispatch(showAlert({
                message: "Rate created successfully",
                messageType: 'success',
                showAlertMessage: true
            }));
            // if(checkClientId && checkClientId.length > 0){
            if (urlType !== 'customers/price/contracts') {
                props.history.push((appUserRole === "ROLE_TRANSLATOR" ? `/linguist/rates` : `/vendor/rates`))
            }

            // }else {
            //     props.history.push((appUserRole==="ROLE_TRANSLATOR"?`/linguist/general/rates`:`/vendor/rates`))
            // }

        },
        onError: ({ graphQLErrors }: any) => {
            dispatch(showAlert({
                message: graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message && graphQLErrors[0].message,
                messageType: 'error',
                showAlertMessage: true
            }));
        }
    });

    const [GetpackageName] = useLazyQuery(GET_PROJECT_PACKAGE_DETAILS, {
        variables: { ...(appUserRole === "ROLE_TRANSLATOR" ? ({ role: 'linguist' }) : (!isOwner && isAdmin && ({ orgId: +selectedOrgId }))) },
        fetchPolicy: 'no-cache',
        onCompleted: (data: any) => {
            setPackageName(data.getAllPackages.packages)
        },
    });

    // const [emailsQueryData] = useLazyQuery(USER_EMAILS_GQL, {
    //     fetchPolicy: 'no-cache',
    //     onCompleted: (data: any) => {
    //         if (data && data.userEmails && data.userEmails.length > 0) {
    //             setDataUserEmails(data && data.userEmails);
    //         }
    //     }
    // });

    useQuery(GET_ACTIVE_INDUSTRIES, {
        fetchPolicy: 'no-cache',
        onCompleted: (data: any) => {
            setActiveIndustries(data.getIndustries)
        },
    });

    const [vendorRateData] = useLazyQuery(VENDOR_RATE_ID, {
        fetchPolicy: 'no-cache',
        variables: { orgId: +selectedOrgId, rateId: '0', ...(appUserRole === "ROLE_TRANSLATOR" && { role: 'linguist' }) },
        onCompleted: (data: any) => {
            if (data.getVendorRates && data.getVendorRates[0]) {
                dispatch(selectedRateName(data.getVendorRates[0].packageName))
                setVendorIdRateData(data.getVendorRates);
                setpackageId(data.getVendorRates[0].packageId);
                setSlId(data.getVendorRates[0].sourceLanguage.id);
                setTargetLanguages(data.getVendorRates[0].targetLanguages.map((lang: any) => lang.code));
            }
        }
    });

    useEffect(() => {
        if (typeof props.setAddRate == "undefined") {
            vendorRateData();
        }
    }, [vendorRateData]);
    useEffect(() => {
        GetpackageName();
    }, [GetpackageName]);

    useEffect(() => {
        if (vendorIdRateData[0] && vendorIdRateData[0].clients) {
            setEmail(vendorIdRateData[0] && vendorIdRateData[0].clients)
        }
    }, [vendorIdRateData])


    return (
        <div className="createProjectComponent">
            <MDrawer
                open={addServiceOpen}
                variant="temporary"
                anchor='right'
                className={classes.fileUploadDrawer}
                onClose={() => { setAddServiceOpen(false) }}
            >
                <AddServices getRefresh={getRefresh} setGetRefresh={setGetRefresh} Services={serviceType} GetpackageName={GetpackageName} setAddServiceOpen={setAddServiceOpen} selectServiceId={selectServiceId} />
            </MDrawer>
            <MGrid container justifyContent="center">
                {/* <MBox> */}
                {/* <BreadcrumbsNavigation/> */}

                {/* {location === "vendor" ?
                <BreadcrumbsNavigationCustom
                    isloading={false}
                    breadcrumbs={
                        [
                            {
                                type: "Rate request",
                                link: "/vendor/rate/request"
                            },
                            {
                                type: "Rate",
                                link: "/vendor/rates"
                            },
                            {
                                type: "Add rates",
                                link: "/"
                            }

                        ]
                    }
                />
                :
                <BreadcrumbsNavigationCustom
                    isloading={false}
                    breadcrumbs={
                        [
                            
                            {
                                type: "Rate",
                                link: "/linguist/rates"
                            },
                            {
                                type: "Add rates",
                                link: "/"
                            }

                        ]
                    }
                />

            
                } */}

                {/* </MBox> */}
                <MGrid item xs={12} sm={9} md={7} lg={5} xl={5}>
                    <MBox color="palette.secondary" className={classes.modalForm} p={4}>
                        <MFormik
                            validateOnChange
                            enableReinitialize="true"
                            initialValues={{
                                targetLanguages: vendorIdRateData[0] && vendorIdRateData[0].targetLanguages,
                                sourceLanguage: vendorIdRateData[0] && vendorIdRateData[0].sourceLanguage,
                                services: vendorIdRateData[0] && vendorIdRateData[0].packageName,
                                ratePerWord: vendorIdRateData[0] && vendorIdRateData[0].price,
                                subject: vendorIdRateData[0] && vendorIdRateData[0].subject,
                                client: '',
                                clientEmail: urlType == 'customers/price/contracts' && localStorage.getItem('custEmail') ? localStorage.getItem('custEmail') : ''
                            }}
                            validationSchema={Yup.object({
                                // clientEmail: Yup.string().required(intl.formatMessage({ id: "email.required.field" })).email(intl.formatMessage({ id: "valid.email" })).matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, intl.formatMessage({ id: "valid.email" })),
                                //  targetLanguages: Yup.string().required(intl.formatMessage({ id: "target.languages.required" })),
                                // services: Yup.string().required(intl.formatMessage({ id: "services.required.field" })),
                                targetLanguages: Yup.mixed()
                                    .test(
                                        'is-valid-targetLocale',
                                        'Invalid locale. Must be a string or an array of strings.',
                                        (value) => {
                                            if (typeof value === 'string') {
                                                return true; // Valid if it's a string
                                            }
                                            if (Array.isArray(value) && value.every((item) => typeof item === 'string')) {
                                                return true; // Valid if it's an array of strings
                                            }
                                            return false; // Invalid for other cases
                                        }
                                    )
                                    .required(intl.formatMessage({ id: "target.languages.required" })),
                                services: Yup.mixed()
                                    .test(
                                        'is-valid-services',
                                        intl.formatMessage({ id: 'services.required.field' }),
                                        (value: any) => {
                                            if (!value) return false; // Value is required
                                            if (typeof value === 'string') {
                                                return true;
                                            }

                                            if (typeof value === 'number') {
                                                return true;
                                            }
                                            if (typeof value === 'object' && value.id || value.name) {
                                                return true;
                                            }
                                            return false; // Invalid if neither condition matches
                                        }
                                    )
                                    .required(intl.formatMessage({ id: 'services.required.field' })),
                                ratePerWord: Yup.number().required(intl.formatMessage({ id: "rate.price.required.field" })).moreThan(0, intl.formatMessage({ id: "greater.than.zero" })).max(1, intl.formatMessage({ id: "cannot.greater.one" })),
                                subject: Yup.string().required(intl.formatMessage({ id: "industry.required.field" })),
                                // sourceLanguage: Yup.string().required(intl.formatMessage({ id: "source.language.required.field" })),
                                sourceLanguage: Yup.mixed()
                                    .test(
                                        'is-valid-sourceLocale',
                                        'Invalid locale. Must be a string or an array of strings.',
                                        (value) => {
                                            if (typeof value === 'string') {
                                                return true; // Valid if it's a string
                                            }
                                            if (Array.isArray(value) && value.every((item) => typeof item === 'string')) {
                                                return true; // Valid if it's an array of strings
                                            }
                                            return false; // Invalid for other cases
                                        }
                                    )
                                    .required(intl.formatMessage({ id: "source.language.required.field" })),
                            })}
                            onSubmit={(values: any) => {
                                // props.setAddRate(false);
                                if (email.length > 0) {
                                    setTab('client')
                                }
                                const emailIdArr: any = [];
                                email.forEach((i: any) => {
                                    emailIdArr.push(i.id)
                                });

                                const data: any = {
                                    packageId: parseInt(values.services.id),
                                    slId: slId,
                                    price: values.ratePerWord.toString(),
                                    tlIds: values.targetLanguages.join(','),
                                    subject: values.subject,
                                    clientIds: values.clientEmail,
                                    orgId: selectedOrgId,
                                    // clientIds:emailIdArr.toString(),
                                    ...(appUserRole === "ROLE_TRANSLATOR" && { role: 'linguist' })
                                };
                                //  setCheckClientId(emailIdArr);
                                // if (VendorRateId !== 'add-rates') {
                                //     data.rateId = VendorRateId
                                // }
                                addVendorsRate({
                                    variables: data
                                });
                            }}>
                            {({ touched, errors, setFieldValue, values }: any) => (

                                <MForm>
                                    <MBox p={2} mb={3.5} className="formElementWrapper" sx={{ Width: '100%', backgroundColor: '#FDFDFD', borderRadius: 5, border: '1px solid #E8E8E8' }}>
                                        <>
                                            <MBox display="block">
                                                <MBox mb={1} position="relative" className={classes.wrapperSelectedField}>
                                                    <MBox mb={1}>
                                                        <MFormLabel className={classes.formLabel} required component="legend"><FormattedMessage id="service" /></MFormLabel>
                                                    </MBox>
                                                    {vendorIdRateData[0] && vendorIdRateData[0]?.packageName &&
                                                        <Autocomplete
                                                            selectOnFocus={true}
                                                            autoComplete={false}
                                                            limitTags={1}
                                                            id="services"
                                                            size="small"
                                                            popupIcon={<MKeyboardArrowDownRoundedIcon />}
                                                            className={`${classes.inputWidth} ${classes.addSelected}`}
                                                            options={packageName}
                                                            defaultValue={{ name: vendorIdRateData[0].packageName }}
                                                            getOptionLabel={(option: any) => option.name}
                                                            onChange={(event: any, value: any) => {
                                                                //  console.log(' -=-==- ', value);
                                                                if (value && value) {
                                                                    setpackageId(value.id);
                                                                    setFieldValue('services', value.id)
                                                                } else {
                                                                    setpackageId([]);
                                                                    setFieldValue('services', '')
                                                                }

                                                            }}
                                                            renderInput={params => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    placeholder={intl.formatMessage({ id: "service.name" })}
                                                                    autoComplete='off'

                                                                />
                                                            )}

                                                        />
                                                    }
                                                    {packageName && packageName.length > 0 && !vendorIdRateData[0] &&

                                                        <Autocomplete
                                                            autoComplete={false}
                                                            limitTags={1}
                                                            id="services"
                                                            disabled={!packageName}
                                                            size="small"
                                                            popupIcon={<MKeyboardArrowDownRoundedIcon />}
                                                            className={`${classes.inputWidth} ${classes.addSelected}`}
                                                            value={values.services}
                                                            options={packageName}
                                                            getOptionLabel={(option: any) => option.name}
                                                            onChange={(event: any, value: any) => {
                                                                if (value && value) {
                                                                    // setpackageId(value.id);
                                                                    setFieldValue('services', value)
                                                                } else {
                                                                    // setpackageId([]);
                                                                    setFieldValue('services', [])
                                                                }
                                                            }}
                                                            renderInput={params => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    placeholder={intl.formatMessage({ id: "service.name" })}
                                                                    autoComplete='off'
                                                                />
                                                            )}
                                                        />
                                                    }
                                                </MBox>
                                                <MButton className={classes.addServiceButton}
                                                    startIcon={<AddIcon />}
                                                    onClick={() => {
                                                        setAddServiceOpen(true);
                                                        setServiceType("Add");
                                                    }}
                                                >
                                                    <FormattedMessage id="add.service" />
                                                </MButton>
                                                <FormErrorMessage message={touched.services ? errors.services : ''} />
                                            </MBox>
                                        </>
                                    </MBox>

                                    <MBox mb={4}>
                                        <MBox mb={1}>
                                            <MFormLabel className={classes.formLabel} component="legend" required>
                                                <FormattedMessage id="client" /> / <FormattedMessage id="vendor" />
                                                {/*{appUserRole === 'ROLE_TRANSLATOR' ?<FormattedMessage id="vendor"/>:<FormattedMessage id="client"/>}*/}
                                            </MFormLabel>
                                        </MBox>
                                        <MTextField
                                            name="clientEmail"
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            className={classes.inputWidth}
                                            disabled={urlType == 'customers/price/contracts' && localStorage.getItem('custEmail') ? true : false}
                                            placeholder={intl.formatMessage({ id: "email" })}
                                            error={touched.client && !!errors.client}
                                            helperText={touched.client && errors.client}
                                        />
                                    </MBox>
                                    <MGrid container direction="row" justifyContent="flex-start" alignItems="center">
                                        <MGrid item container direction="row" justifyContent="flex-start" alignItems="center">
                                            {email && email.map((item: tags, idx: number) => (
                                                <MBox key={"email-" + idx} mr={1} mb={2}>
                                                    <MChip className={classes.overviewChipStyle} key={item.id} label={item.username}
                                                        deleteIcon={
                                                            <CloseIcon className={classes.overviewChipIcon} />
                                                        }
                                                        onDelete={() => setEmail(email.filter((i: any) => i.id !== item.id))}
                                                    />
                                                </MBox>
                                            ))}
                                        </MGrid>
                                    </MGrid>

                                    <MBox className={classes.inputWidth} mb={4}>
                                        <MBox mb={1}>
                                            <MFormLabel className={classes.formLabel} component="legend" required>
                                                <FormattedMessage id="source.language" />
                                            </MFormLabel>
                                        </MBox>
                                        <MBox mb={4} position="relative">
                                            {vendorIdRateData[0] && vendorIdRateData[0].sourceLanguage &&
                                                <SourceLanguageSelect
                                                    defaultLanguage={vendorIdRateData[0] && vendorIdRateData[0].sourceLanguage}
                                                    label={intl.formatMessage({ id: "select.source.language" })}
                                                    setFieldValue={setFieldValue}
                                                    // className={classes.inputWidth}
                                                    targetLanguages={targetLanguages}
                                                    setSlId={setSlId}
                                                />
                                            }
                                            {!vendorIdRateData[0] &&
                                                <SourceLanguageSelect
                                                    label={intl.formatMessage({ id: "select.source.language" })}
                                                    setFieldValue={setFieldValue}
                                                    // className={classes.inputWidth}
                                                    targetLanguages={targetLanguages}
                                                    setSlId={setSlId}
                                                />
                                            }
                                            <FormErrorMessage message={touched.sourceLanguage ? errors.sourceLanguage : ''} />
                                        </MBox>
                                        <MBox mb={1}>
                                            <MFormLabel className={classes.formLabel} component="legend" required>
                                                <FormattedMessage id="target.languages" />
                                            </MFormLabel>
                                        </MBox>
                                        <MBox position="relative" mb={4}>
                                            {vendorIdRateData[0] && vendorIdRateData[0].targetLanguages && values.sourceLanguage &&
                                                <TargetLanguageSelect
                                                    label={intl.formatMessage({ id: "select.target.language" })}
                                                    name="targetLanguages"
                                                    setFieldValue={setFieldValue}
                                                    filterLanguages={[(values.sourceLanguage && values.sourceLanguage.code) ? values.sourceLanguage.code : values.sourceLanguage]}
                                                    defaultLanguage={vendorIdRateData[0].targetLanguages}
                                                />
                                            }
                                            {!vendorIdRateData[0] &&
                                                <TargetLanguageSelect
                                                    label={intl.formatMessage({ id: "select.target.language" })}
                                                    name="targetLanguages"
                                                    setFieldValue={setFieldValue}
                                                    filterLanguages={[(values.sourceLanguage && values.sourceLanguage.code) ? values.sourceLanguage.code : values.sourceLanguage]}
                                                />
                                            }
                                            <FormErrorMessage message={touched.targetLanguages ? errors.targetLanguages : ''} />
                                        </MBox>
                                    </MBox>
                                    <MBox mb={4} position="relative">
                                        <MBox mb={1}>
                                            <MFormLabel className={classes.formLabel} component="legend" required><FormattedMessage id="industry" /></MFormLabel>
                                        </MBox>
                                        {vendorIdRateData[0] && vendorIdRateData[0].subject &&
                                            <Autocomplete
                                                autoComplete={false}
                                                limitTags={1}
                                                id="subject"
                                                size="small"
                                                //  placeholder={intl.formatMessage({ id: "select.industry" })}
                                                className={classes.inputWidth}
                                                popupIcon={<MKeyboardArrowDownRoundedIcon />}
                                                options={activeIndustries}
                                                defaultValue={{ name: activeIndustries && activeIndustries[0] && activeIndustries[0].name }}
                                                getOptionLabel={(option: any) => option.name}
                                                onChange={(event: any, value: any) => {
                                                    if (value && value) {
                                                        setFieldValue('subject', value.name)
                                                    }
                                                }}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        color="secondary"
                                                        placeholder={intl.formatMessage({ id: "select.industry" })}
                                                        autoComplete='off'
                                                    />
                                                )}
                                            />
                                        }
                                        {!vendorIdRateData[0] &&
                                            <Autocomplete
                                                autoComplete={false}
                                                limitTags={1}
                                                id="subject"
                                                size="small"
                                                className={classes.inputWidth}
                                                options={activeIndustries}
                                                getOptionLabel={(option: any) => option.name}
                                                popupIcon={<MKeyboardArrowDownRoundedIcon />}
                                                onChange={(event: any, value: any) => {
                                                    if (value && value) {
                                                        setFieldValue('subject', value.name)
                                                    }
                                                }}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        color="secondary"
                                                        placeholder={intl.formatMessage({ id: "select.industry" })}
                                                        autoComplete='off'
                                                    />
                                                )}
                                            />
                                        }
                                        <FormErrorMessage message={touched.subject ? errors.subject : ''} />
                                    </MBox>
                                    <MBox mb={4}>
                                        <MBox mb={1}>
                                            <MFormLabel className={classes.formLabel} component="legend" required>
                                                <FormattedMessage id="rate.per.word" />
                                            </MFormLabel>
                                        </MBox>
                                        <MTextField
                                            name="ratePerWord"
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            type="number"
                                            className={classes.inputWidth}
                                            disabled={false}
                                            placeholder="$0.02"
                                            onKeyDown={(evt: any) => {
                                                const value = evt.target.value;
                                                const decimalPart = value.toString().split(".")[1];
                                                if (evt.key === 'Backspace') return;
                                                // Prevent typing when the decimal part length is already four
                                                if (decimalPart && decimalPart.length >= 4) {
                                                    evt.preventDefault();
                                                }
                                            }}
                                            error={touched.ratePerWord && !!errors.ratePerWord}
                                            helperText={touched.ratePerWord && errors.ratePerWord}
                                        />

                                    </MBox>
                                    <MBox display="flex">
                                        <MBox className={classes.footerBottom}>
                                            <MBox display="inline-block" mr={2} mt={1} ml={2}>
                                                <MLink href="#" onClick={(e: React.SyntheticEvent) => {
                                                    props.setAddRate(false);
                                                    e?.preventDefault();
                                                }} color="inherit">
                                                    <FormattedMessage id="cancel" />
                                                </MLink>
                                            </MBox>
                                            <MButton disabled={addLoading} className="btnSmall" color="primary" variant="contained" type="submit">
                                                {addLoading && <MCircularProgress color="primary" size={24} />}
                                                <FormattedMessage id="save" />
                                            </MButton>
                                        </MBox>
                                    </MBox>
                                </MForm>
                            )}
                        </MFormik>
                    </MBox>
                </MGrid>
            </MGrid>
        </div >
    );
}


export default withRouter(AddNewRates);