/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { MBox } from 'src/app/material-ui';
import { FormattedMessage } from 'react-intl';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { error: null, errorInfo: null };
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			error: error,
			errorInfo: errorInfo
		})
	}

	componentDidUpdate(prevProps) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			this.setState({ error: null, errorInfo: null });
		}
	}

	render() {
		if (this.state.errorInfo || (this.props.isGraphqlError && this.props.isAlert)) {
			return (
				<MBox
					bgcolor="#fff"
					boxShadow='0px 5px 5px 0px rgba(193, 199, 208, 0.26)'
					borderRadius={5} minHeight={560} mt={5} display="flex" justifyContent="center" alignItems="center"
				>
					<MBox>
						<img src="/assets/images/404-image.png" alt="404" width="460" />
						<MBox textAlign="center" mt={3.75}>
							<MBox fontSize={18} fontFamily="Poppins">
								<FormattedMessage id="something.wrong" />
							</MBox>
							<MBox mt={1.5} mb={3}>
								<FormattedMessage id="something.wrong.try.again" />
							</MBox>
						</MBox>
					</MBox>
				</MBox>
			);
		}
		return this.props.children;
	}
}

const mapStateToProps = (state) => ({
	isAlert: state.alert.showAlertMessage,
	isGraphqlError: state.alert.graphqlError

});

export default connect(mapStateToProps)(ErrorBoundary);