import React from 'react';
import {
    MBox,
    MGrid,
    MSkeleton
} from 'src/app/material-ui';
import useStyles from './SidebarStylesNew';
import { useSelector } from 'react-redux';

type SidebarSkeletonProps = {
    isVendor?: boolean;
}

export default function SidebarSkeleton({ isVendor }: SidebarSkeletonProps) {
    const classes = useStyles({});
    const appUserRole = useSelector((store: any) => store.userRole.userRole);
    const selectedOrgId = useSelector((store: any) => store.orgs.selectedOrgId);
    const client = 4;
    const vendor = 7;
    const customer = 3;

    return (
        <MBox className={classes.sidebarWrap}>
            {isVendor ? null :
                (appUserRole === "ROLE_CUSTOMER") ?
                    <>
                        {(appUserRole === "ROLE_CUSTOMER") &&
                            <MBox display="flex" justifyContent="center" alignItems="center" width='calc(100% - 57px)' m="auto" minHeight={64}>
                                <MSkeleton variant="rect" animation="wave" width={'140px'} height={34} sx={{ fontSize: '1rem', borderRadius: '5px' }} />
                            </MBox>
                        }
                    </>
                    :
                    <MBox>
                        <MBox minHeight={50} sx={{ display: { xs: 'none', md: 'flex', lg: 'flex' } }} pt={2} pb={2} display="flex" justifyContent="center" alignItems="center">
                            <MSkeleton variant="rect" animation="wave" width={'140px'} height={34} sx={{ fontSize: '1rem', borderRadius: '5px' }} />
                        </MBox>
                    </MBox>
            }

            {(appUserRole === "ROLE_CLIENT" || appUserRole === "ROLE_VENDOR") &&

                <>
                    {selectedOrgId !== 0 &&
                        <>
                            <MBox className={classes.orgSkeleton} sx={{ marginTop: '-9px' }}>
                                <MBox display="flex" alignItems='center' px={2}>

                                    <MBox mr={-2} p={1.5} width="100%" >
                                        <MSkeleton variant="rounded" animation="wave" height={25} />
                                    </MBox>
                                </MBox>
                            </MBox>
                        </>
                    }
                </>
            }

            <MBox className={classes.generalLinks} px={1.2} pb={12} mr={1.8}>
                {(appUserRole === "ROLE_CLIENT") &&
                    <>
                        {
                            [...Array(client)].map((_, idx) => (
                                <MBox key={"client" + idx} className="orgFull" mt={1} mb={1} display='flex !important' alignItems='center'>
                                    <MSkeleton variant="circular" animation="wave" width={'24px'} height={24} sx={{ borderRadius: '100%' }} />
                                    &nbsp;
                                    &nbsp;
                                    <MSkeleton variant="text" animation="wave" width={'140px'} height={15} sx={{ fontSize: '1rem', marginLeft: 10, borderRadius: '5px' }} />
                                </MBox>
                            ))
                        }
                    </>
                }
                {(appUserRole === "ROLE_VENDOR") &&
                    <>
                        {
                            [...Array(vendor)].map((_, idx) => (
                                <MBox key={"vendor" + idx} className="orgFull" mt={1} mb={1} display='flex !important' alignItems='center'>
                                    <MSkeleton variant="circular" animation="wave" width={'24px'} height={24} sx={{ borderRadius: '100%' }} />
                                    &nbsp;
                                    &nbsp;
                                    <MSkeleton variant="text" animation="wave" width={'140px'} height={15} sx={{ fontSize: '1rem', borderRadius: '5px' }} />
                                </MBox>
                            ))}
                    </>
                }
                {(appUserRole === "ROLE_TRANSLATOR") &&
                    <>
                        {
                            [0, 1, 2].map((_, index: number) => (
                                <MBox key={"box" + index} className="orgFull" mt={1} mb={1} display='flex !important' alignItems='center'>
                                    <MSkeleton variant="circular" animation="wave" width={'24px'} height={24} sx={{ borderRadius: '100%' }} />
                                    &nbsp;
                                    &nbsp;
                                    <MSkeleton variant="text" animation="wave" width={'140px'} height={15} sx={{ fontSize: '1rem', marginLeft: 10, borderRadius: '5px' }} />
                                </MBox>
                            )
                            )
                        }
                    </>

                }
                {(appUserRole === "ROLE_CUSTOMER") &&
                    <MBox className="orgFull" display='flex !important' alignItems='center' mt={1} mb={1}>
                        <MSkeleton variant="circular" animation="wave" width={'24px'} height={24} sx={{ borderRadius: '100%' }} />
                        &nbsp;
                        &nbsp;
                        <MSkeleton variant="text" animation="wave" width={'140px'} height={15} sx={{ fontSize: '1rem', marginLeft: 10, borderRadius: '5px' }} />
                    </MBox>
                }
            </MBox>

            <MBox className={classes.generalLinks} px={1.2} pb={12} mr={1.8}>
                {(appUserRole === "ROLE_TRANSLATOR") ? '' :
                    <MBox className="orgFull" mt={3} mb={3}><MSkeleton variant="rect" animation="wave" width={'100%'} height={25} sx={{ fontSize: '1rem', marginTop: '30px', marginBottom: '30px', borderRadius: '5px' }} /></MBox>
                }
                {(appUserRole === "ROLE_TRANSLATOR") ?
                    ''
                    :
                    <>
                        {
                            [...Array(customer)].map((_, idx) => (
                                <MBox key={"customer" + idx} className="orgFull" display='flex !important' alignItems='center' mt={1} mb={1}>
                                    <MSkeleton variant="circular" animation="wave" width={'24px'} height={24} sx={{ borderRadius: '100%' }} />
                                    &nbsp;
                                    &nbsp;
                                    <MSkeleton variant="text" animation="wave" width={'140px'} height={15} sx={{ fontSize: '1rem', marginLeft: 10, borderRadius: '5px' }} />
                                </MBox>
                            )
                            )
                        }
                    </>
                }
            </MBox>
            {/* it will show only show in client and vendor sidebars */}
            {(appUserRole === "ROLE_CLIENT" || "ROLE_VENDOR" || "ROLE_TRANSLATOR") &&
                ''
            }
            <MGrid className={classes.footerSm} item>

                <MBox className="footerFull">
                    <MBox className={classes.projectSidebarFooter + ' userDropdown'}>
                        <MBox display="flex" width="calc(100% - 57px)">
                            <MSkeleton variant="circular" animation="wave" height={40} width={55} />
                            <MBox ml={1.5} width="100%" className={classes.footerDetails}>
                                <MBox className={classes.sidebarAvatar} mb={0.75}>
                                    <MSkeleton variant="text" animation="wave" width='100%' height={20} sx={{ borderRadius: '5px' }} />
                                </MBox>
                                <MBox fontSize={14} color="#A2A4B9">      <MSkeleton variant="text" animation="wave" width='70%' height={20} sx={{ borderRadius: '5px' }} /></MBox>

                            </MBox>
                        </MBox>
                        <MBox mr={-2} p={1.5}>
                            <MSkeleton variant="circular" animation="wave" height={25} width={25} />
                        </MBox>
                    </MBox>
                </MBox>
                <MBox>
                </MBox>
            </MGrid>
        </MBox >
    )
}

