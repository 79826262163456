import { put, takeEvery } from 'redux-saga/effects';
import { gql } from 'apollo-boost';
import { client } from "src/app/hooks";
import { fetchSourceLanguageFailure, fetchSourceLanguageSuccess } from './SourceLanguageActions';
import { FETCH_SOURCE_LANGUAGE_REQUEST } from './SourceLanguageActionTypes';

const SOURCE_LANGUAGE_GQL = gql`
    query sourceLanguages {
        sourceLanguages {
            code
            id
            name
        }
    }
`;

function* fetchSourceLanguages(): any {

  try {

    const sourceLanguages = yield client.query({ query: SOURCE_LANGUAGE_GQL, fetchPolicy: "no-cache" });

    yield put(fetchSourceLanguageSuccess(sourceLanguages.data));

  } catch (error) {
    yield put(fetchSourceLanguageFailure(error));
  }
}

export default function* sourceLanguageSaga() {
  yield takeEvery(FETCH_SOURCE_LANGUAGE_REQUEST, fetchSourceLanguages);

}