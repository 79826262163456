
import {
  FETCH_JOBS_REQUEST, FETCH_JOBS_SUCCESS, FETCH_JOBS_FAILURE, FETCH_JOB_FILES_REQUEST, FETCH_JOB_FILES_SUCCESS, FETCH_JOB_FILES_FAILURE,
  FETCH_PROFILE_DATA_REQUEST, FETCH_PROFILE_DATA_SUCCESS, FETCH_PROFILE_DATA_FAILURE, FETCH_ASSIGNED_JOB_FILES_REQUEST, FETCH_ASSIGNED_JOB_FILES_SUCCESS,
  FETCH_ASSIGNED_JOB_FILES_FAILURE, FETCH_JOB_LANGUAGES_REQUEST, FETCH_JOB_LANGUAGES_SUCCESS, FETCH_JOB_LANGUAGES_FAILURE,
  FETCH_LINGUIST_PROFILE_DATA_REQUEST, FETCH_LINGUIST_PROFILE_DATA_SUCCESS, FETCH_LINGUIST_PROFILE_DATA_FAILURE
} from './JobActionTypes';

// GET ALL JOBS

export function fetchJobsRequest(data: any) {
  return {
    type: FETCH_JOBS_REQUEST,
    payload: data
  };
}

export function fetchJobsSuccess(data: any) {
  return {
    type: FETCH_JOBS_SUCCESS,
    payload: data
  };
}

export function fetchJobsFailure(error: any) {
  return {
    type: FETCH_JOBS_FAILURE,
    payload: error
  };
}

// GET ALL OPEN JOB FILES

export function fetchJobFilesRequest(data: any) {
  return {
    type: FETCH_JOB_FILES_REQUEST,
    payload: data
  };
}
export function fetchJobFilesSuccess(data: any) {
  return {
    type: FETCH_JOB_FILES_SUCCESS,
    payload: data
  };
}
export function fetchJobFilesFailure(error: any) {
  return {
    type: FETCH_JOB_FILES_FAILURE,
    payload: error
  };
}

// GET ALL ASSIGNED JOB FILES

export function fetchAssignedJobFilesRequest(data: any) {
  return {
    type: FETCH_ASSIGNED_JOB_FILES_REQUEST,
    payload: data
  };
}
export function fetchAssignedJobFilesSuccess(data: any) {
  return {
    type: FETCH_ASSIGNED_JOB_FILES_SUCCESS,
    payload: data
  };
}
export function fetchAssignedJobFilesFailure(error: any) {
  return {
    type: FETCH_ASSIGNED_JOB_FILES_FAILURE,
    payload: error
  };
}

// GET ALL JOB LANGUAGES

export function fetchJobLanguagesRequest(data: any) {
  return {
    type: FETCH_JOB_LANGUAGES_REQUEST,
    payload: data
  };
}

export function fetchJobLanguagesSuccess(data: any) {
  return {
    type: FETCH_JOB_LANGUAGES_SUCCESS,
    payload: data
  };
}

export function fetchJobLanguagesFailure(error: any) {
  return {
    type: FETCH_JOB_LANGUAGES_FAILURE,
    payload: error
  };
}

// GET PROFILE DATA

export function fetchProfileDataRequest() {
  return {
    type: FETCH_PROFILE_DATA_REQUEST,
  };
}
export function fetchProfileDataSuccess(data: any) {
  return {
    type: FETCH_PROFILE_DATA_SUCCESS,
    payload: data
  };
}
export function fetchProfileDataFailure(error: any) {
  return {
    type: FETCH_PROFILE_DATA_FAILURE,
    payload: error

  };
}

// GET LINGUIST PROFILE DATA

export function fetchLinguistProfileDataRequest() {
  return {
    type: FETCH_LINGUIST_PROFILE_DATA_REQUEST,
  };
}
export function fetchLinguistProfileDataSuccess(data: any) {
  return {
    type: FETCH_LINGUIST_PROFILE_DATA_SUCCESS,
    payload: data
  };
}
export function fetchLinguistProfileDataFailure(error: any) {
  return {
    type: FETCH_LINGUIST_PROFILE_DATA_FAILURE,
    payload: error

  };
}