import { makeStyles } from "@mui/styles";
// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
const useStyles = makeStyles(() => ({
  draggableBox: {
    height: 50,
    border: "1px solid #f2f2f4",
    boxShadow: "0px 5px 5px 0px rgba(193, 199, 208, 0.14)",
    borderRadius: "4px",
    backgroundColor: "#fff",
  },
  iconBox: {
    minWidth: 30,
    maxWidth: 30,
    height: "100%",
    borderRadius: "4px 0 0 4px",
    backgroundColor: "#a0a5b9",
    // color: "#fff",
    "& .counter": {},
    "& .dragIcon": {},
  },
  text: {},
  remove: {
    color: "#a0a5b9",
    "& svg": {
      width: 15,
    },
  },
  menuDragIcon: {
    color: "#393939",
    fontSize: 13,
  },
  taskList: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #F4F5F7",
    boxShadow: "0px 0px 30px rgba(214, 215, 226, 0.3)",
    padding: 15,
    borderRadius: "5px",
    width: "100%",
    cursor: 'pointer',

    "& .MuiAvatar-root": {
      width: 18,
      height: 18,
      marginRight: 6,
    },

    gap: 15,
    "& > .MuiBox-root": {
      span: {
        fontStyle: "italic",
        color: "#A0A5B9",
      },
    },
  },
  sourceText: {
    color: '#a0a5b9',
    textAlign: 'left',
    '& div, & svg': {
      display: 'inline-block',
      verticalAlign: 'middle'
    },
    '& svg': {
      marginRight: 4,
    },
  },
}));


// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;