import { makeStyles } from '@mui/styles';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles(() => ({
  infoText: {
    backgroundColor: '#E5F1F6',
    color: "#498BA7",
    padding: '15px',
    fontSize: 12,
    borderRadius: 4,
    maxWidth: '50%',
    marginBottom: '35px'
  },
  btnSelection: {
    backgroundColor: '#F5F6F7',
    border: '1px solid #DDE6F2',
    padding: '7px 20px'
  },
  active: {
    // Add active button styles here
    border: '1px solid #FF5100 !important'
  },
  lang: {
    backgroundColor: '#F4F5F7',
    borderRadius: 4,
    padding: '5px 8px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',


  },
  footerBottom: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'fixed',
    bottom: 0,
    right: 0,
    width: '85%',
    padding: '20px 35px 20px 0px',
    backgroundColor: '#fff',
    borderTop: '1px solid #dbdbdb',

  },


}),
);

export default useStyles;