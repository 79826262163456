import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
const useStyles = makeStyles(() =>
  createStyles({
    inputWidth: {
      // width: 493,
    },

    propsInputWidth: {
      maxWidth: 312,
    },
    sizeMd: {
      '& .MuiAutocomplete-inputRoot': {
        // minHeight: 40, 
      }
    },

    customChipSize: {

    }
  }));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;