
import { ACCEPT_REJECT_RATES_FAILURE, ACCEPT_REJECT_RATES_REQUEST, ACCEPT_REJECT_RATES_SUCCESS, FETCH_RATES_FAILURE, FETCH_RATES_REQUEST, FETCH_RATES_SUCCESS } from "./RateRequestActionTypes";



export function fetchRatesRequest(data: any) {
    return {
      type: FETCH_RATES_REQUEST,
      payload: data
    };
  }
  
  export function fetchRatesSuccess(data: any) {
    return {
      type: FETCH_RATES_SUCCESS,
      payload: data
    };
  }
  
  export function fetchRatesFailure(error: any) {
    return {
      type: FETCH_RATES_FAILURE,
      payload: error
    };
  }

  // ACCEPT REJECT RATE REQUEST ACTIONS

  export function acceptRejectRatesRequest(data: any) {
    return {
      type: ACCEPT_REJECT_RATES_REQUEST,
      payload: data
    };
  }
  
  export function acceptRejectRatesSuccess(data: any) {
    return {
      type: ACCEPT_REJECT_RATES_SUCCESS,
      payload: data
    };
  }
  
  export function acceptRejectRatesFailure(error: any) {
    return {
      type: ACCEPT_REJECT_RATES_FAILURE,
      payload: error
    };
  }