import React, { ComponentType, FC } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute: FC<any> = (
    { component: Component, ...rest }:
        { component: ComponentType<any> }
) => {

    const isLogin = useSelector((store: any) => store.auth.isLogin);


    return (
        <Route
            {...rest}
            render={componentProps =>
                isLogin ? (
                    <Component {...componentProps} />
                ) : (

                    <></>
                )
            }
        />
    );
}

{/*<Redirect*/ }
{/*to={{ pathname: "/" }}*/ }
{/*/>*/ }

export default withRouter(PrivateRoute);
