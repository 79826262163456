import React, { useEffect } from 'react'
import AnimatedText from 'react-animated-text-content';
import { MBox } from 'src/app/material-ui';
import { MRedirectLogo } from 'src/app/material-ui/icons';
import { gql } from 'apollo-boost';
import { useMutation } from "@apollo/react-hooks";
import {
    sideBarShow,
} from 'src/app/store';
import { useDispatch } from 'react-redux';
import { headerShow } from "src/app/store/show-sidebar/ShowSideBarReducer";


const SET_PACKAGE_AND_GET_PAYMENT_LINK = gql`
  mutation SetPackageAndGetPaymentLink($packageId: Int!) {
    setPackageAndGetPaymentLink(packageId: $packageId)
  }
`;
  
export default function Redirect() {
    const dispatch = useDispatch();

    const packageId = window.location.href.split('/').pop();

    // const isInteger = (value:any) => {
    //     console.log("value", typeof value);
        
    //     return Number.isInteger(value);
    // };

    useEffect(()=> {
        
        // if(isInteger(packageId)){
            dispatch(sideBarShow(false));
            dispatch(headerShow(false));
            createCustomerPO({
                variables: {
                    packageId: Number(packageId)
                }
            });
        // }
      },[packageId])
    
    const [createCustomerPO] = useMutation(SET_PACKAGE_AND_GET_PAYMENT_LINK, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            const paymentLink = data.setPackageAndGetPaymentLink;

          if(paymentLink){
            window.location.href = paymentLink;
            
          }
        },
      });

    return (
        <MBox
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="86vh"
        flexDirection="column"
        sx={{
            overflow: 'hidden', // Prevent scrollbar
            margin: 0,
            padding: 0,
            position: 'relative', // Make sure positioning is correct
            width: '100%', // Full width
        }}
        >
            <MRedirectLogo />

            <AnimatedText
                type="words" // animate words or chars
                animation={{
                    x: '200px',
                    y: '-20px',
                    scale: 1.1,
                    ease: 'ease-in-out',
                }}
                animationType="wave"
                interval={0.10}
                duration={0.8}
                tag="p"
                className="animated-paragraph"
                includeWhiteSpaces
                threshold={0.1}
                rootMargin="20%"
            >
                LOADING. . . .
            </AnimatedText>
        </MBox>
    )
}
