import React, { Fragment } from 'react'

export function MMarkAll() {
    return (
        <Fragment>

            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.3327 13.3334C18.3327 13.7754 18.1571 14.1994 17.8445 14.5119C17.532 14.8245 17.108 15.0001 16.666 15.0001H6.66602C5.74102 15.0001 4.99935 14.2501 4.99935 13.3334V3.33341C4.99935 2.40841 5.74102 1.66675 6.66602 1.66675H16.666C17.108 1.66675 17.532 1.84234 17.8445 2.1549C18.1571 2.46746 18.3327 2.89139 18.3327 3.33341V13.3334ZM13.3327 16.6667V18.3334H3.33268C2.89065 18.3334 2.46673 18.1578 2.15417 17.8453C1.84161 17.5327 1.66602 17.1088 1.66602 16.6667V5.83341H3.33268V16.6667H13.3327ZM10.8327 11.6667L16.666 5.83341L15.491 4.65841L10.8327 9.30841L8.25768 6.74175L7.08268 7.91675L10.8327 11.6667Z" fill="#42526E" />
            </svg>

        </Fragment>
    )
}
