import React from 'react'

export function MInvoiceReceivedRevised() {
    return (


        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.625 1.5H3.375C2.325 1.5 1.5 2.325 1.5 3.375V14.625C1.5 15.675 2.325 16.5 3.375 16.5H7V15H3.375C3.15 15 3 14.85 3 14.625V3.375C3 3.15 3.15 3 3.375 3H14.625C14.85 3 15 3.15 15 3.375V7H16.5V3.375C16.5 2.325 15.675 1.5 14.625 1.5Z" fill="#16C68E" />
            <path d="M16.8863 10.5719C17.0379 10.4202 17.0379 10.1675 16.8863 10.0236L15.9764 9.11373C15.8325 8.96209 15.5798 8.96209 15.4281 9.11373L14.7127 9.8253L16.1708 11.2834M10 14.5419V16H11.4581L15.7586 11.6956L14.3005 10.2375L10 14.5419Z" fill="#16C68E" />
            <path d="M12 4.875H4.5V6.375H12V4.875Z" fill="#16C68E" />
            <path d="M9 7.875H4.5V9.375H9V7.875Z" fill="#16C68E" />
        </svg>






    )
}

