import React from 'react'
import Button from '@mui/material/Button';
// import { MCircularProgress } from '../circular-progress/CircularProgress';
// import { createStyles, makeStyles } from '@mui/material/styles';

// const useStyles = makeStyles(() =>
//   createStyles({
//     MButton: {
//       position: 'relative'
//     }
//   }),
// );

export function MButton(props: any) {
  // const classes = useStyles([]);
  return (
    // <Button className={classes.MButton} disabled={props.showLoading} {...props} disableElevation>{props.showLoading && <MCircularProgress color="secondary" size={24} />}</Button>
    <Button {...props} disableElevation />
  )
}