/* eslint-disable */
import React, { useState, useEffect, MouseEvent } from 'react';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
// import {MDashboardOutlinedI} from 'src/app/material-ui/icons';
import { NavLink, withRouter, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FormattedMessage } from "react-intl";
import NextWeekIcon from '@mui/icons-material/NextWeek';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import SidebarSkeleton from './SidebarSkeleton';
import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import {
    MAvatar,
    MBox,
    MGrid,
    MIconButton,
    MList,
    MListItem,
    MListItemIcon,
    MListItemText,
    MMenu
} from 'src/app/material-ui';
import useStyles from './LinguistSidebarStyles';
import { showAlert, showModal } from "../../../store";
import { avatarPlaceholder } from '../../../hooks/index';
// import CheckPermission from 'src/app/hooks/permissions/CheckPermission';
import CheckPermissions from 'src/app/hooks/permissions/CheckPermissions';

const LINGUIST_FIELDS_DATA = gql`
query getLinguistProfile{
    getLinguistProfile {
    linguist_firstName
    linguist_lastName
    linguist_phone
    linguist_slug
    linguist_native_language{
        id
        name
    }
    linguist_cat_tool{
        id
        name
    }
    linguist_availability{
        start
        end
        day
    }
    linguist_industry{
        id
        name
    }
    linguist_aboutUs
    linguist_slug
    linguist_logo
    }
}`;


function LinguistSidebar() {

    const classes = useStyles({});
    const history = useHistory();
    const currentUser = useSelector((store: any) => store.auth.currentUser);
    const dispatch = useDispatch();
    // const currentUser = useSelector((store: any) => store.auth.currentUser);
    const organizations = useSelector((store: any) => store.orgs.organizations);
    const selectedOrgId = useSelector((store: any) => store.orgs.selectedOrgId);
    // const ratesList = CheckPermission('ROLE_RATES_LIST');
    const selectedOrgLoading = useSelector((store: any) => store.orgs.selectedOrgLoading);
    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const urlType = window.location.href.split('/');

    const handleClose = () => {
        setAnchorEl(null);
    };
    const isProfileComplete = (link: any) => {
        currentUser.isProfile ? history.push(link) : dispatch(showModal(true));
    };
    const isOwner = useSelector((store: any) => store.orgs.isOwner);
    const isAdmin = useSelector((store: any) => store.orgs.isAdmin);
    const appUserRole = useSelector((store: any) => store.userRole.userRole);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [avatarSrc, setAvatarSrc] = useState('');
    const [ratesList, setRatesList] = useState(false);
    const [isAllowOpenJobs, setIsAllowOpenJobs] = useState(false);
    const [isAllowMyJobs, setIsAllowMyJobs] = useState(false);



    const [linguistData] = useLazyQuery(LINGUIST_FIELDS_DATA, {
        fetchPolicy: 'no-cache',
        onCompleted: (data: any) => {
            if (data?.getLinguistProfile && data.getLinguistProfile.linguist_logo) {
                setAvatarSrc((process.env.REACT_APP_PUBLIC_URL + '/' + data.getLinguistProfile.linguist_logo) || '');
            }
        }
    });

    useEffect(() => {
        linguistData();
    }, [avatarSrc, currentUser]);

    useEffect(() => {
        if (selectedOrgId && organizations?.length) {
            setRatesList(CheckPermissions('ROLE_RATES_LIST', selectedOrgId, organizations));
            setIsAllowOpenJobs(CheckPermissions('ROLE_OPEN_JOBS_LIST', selectedOrgId, organizations));
            setIsAllowMyJobs(CheckPermissions('ROLE_MY_JOBS_LIST', selectedOrgId, organizations));
        }

    }, [selectedOrgId, organizations]);


    const logout = () => {
        const token = localStorage.getItem('access_token');
        localStorage.clear();
        window.location.href = "" + process.env.REACT_APP_SSO_LOGOUT_URL + token;
        dispatch(showAlert({ message: 'You are signing out.', messageType: 'error', showAlertMessage: true }))

        // localStorage.clear()
        // dispatch(resetState());
        // props.history.push('/')
    };
    const LightTooltip = styled(
        React.forwardRef(function LightTooltip({ className, ...props }: TooltipProps, ref) {
            return (
                <Tooltip
                    {...props}
                    classes={{ ...props?.classes, popper: className || '' }}  // Ensure className is passed safely
                    ref={ref}
                />
            );
        })
    )(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: "#6b778c",
            fontSize: 12,
            filter: "drop-shadow(0px 8px 16px rgba(214,215,226,0.57))",
            border: "1px solid #f4f5f7",
            borderRadius: 0,
            padding: "20px 15px",
            maxWidth: 230,
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.white,
        },
    }));


    return (
        <MBox className={classes.sidebarWrap}>
            {selectedOrgLoading ? <SidebarSkeleton />
                :

                <>
                    <MBox style={{ marginBottom: 9 }}>

                        <MGrid item className={classes.logoBox}>
                            <NavLink to="/linguist/open-jobs">
                                <img className="logoFull" src="/assets/marshub-logo.webp" alt="logo" width="140px" />
                                <img className="logoSmall" src="/assets/images/logo-sm.svg" alt="logo" width="140px" />
                                {/* <img src="/assets/images/marshub-logo.svg" alt="logo" width="140px" /> */}
                            </NavLink>
                        </MGrid>

                    </MBox>
                    <MBox>
                        <MBox className="generalLinks">
                            <MList className={classes.sideBarLinks}>
                                {(isAllowMyJobs || isAllowOpenJobs) && <MListItem button className={((urlType[3] && urlType[3].split('#')[0] == 'my-jobs') || (urlType[4] && urlType[4].split('#')[0] == 'my-jobs') || (urlType[4] && urlType[4].split('#')[0] == 'open-jobs')) ? 'active activehover' : ''} onClick={() => isProfileComplete("/linguist/open-jobs")}>
                                    <MListItemIcon>
                                        <LightTooltip arrow placement="right" title="Jobs">
                                            <NextWeekIcon />
                                        </LightTooltip>
                                    </MListItemIcon>
                                    <MListItemText primary={<FormattedMessage id="jobs" />} />
                                </MListItem>}
                                {ratesList && <MListItem button onClick={() => isProfileComplete("/linguist/rates")} className={(urlType[4] && urlType[4].split('#')[0] == 'rates') ? 'active' : ''}>
                                    <MListItemIcon>
                                        <LightTooltip arrow placement="right" title="Rates">
                                            <MonetizationOnOutlinedIcon />
                                        </LightTooltip>
                                    </MListItemIcon>
                                    <MListItemText primary={<FormattedMessage id="rates" />} />
                                </MListItem>}
                            </MList>
                        </MBox>
                        <MBox className="generalLinks">
                            <MList className={classes.sideBarLinks}>
                            </MList>
                        </MBox>
                    </MBox>

                    {/* sidebar footer */}
                    <MGrid item>
                        {/* <NavLink to={(organizations && organizations.length > 0) ? "/projects" : "/create-organization"}> */}
                        <MBox className="footerFull">
                            <MBox className={classes.projectSidebarFooter + ' userDropdown'}>
                                <MBox display="flex" width="calc(100% - 76px)">
                                    <MAvatar className={'footerAvatar'} src={avatarSrc} />
                                    <MBox ml={1.5} width="100%">
                                        {currentUser &&
                                            (parseInt(currentUser.firstName.length + currentUser.lastName.length) > 20) ?
                                            <LightTooltip placement="top" title={currentUser.firstName + ' ' + currentUser.lastName}>
                                                <div>
                                                    <MBox sx={{ fontSize: '16', fontFamily: "sans-serif", color: "#3a3a3a", fontWeight: 700, textTransform: 'capitalize' }}>
                                                        {currentUser.firstName.concat(" " + currentUser.lastName).slice(0, 20) + "..."}
                                                    </MBox>
                                                </div>
                                            </LightTooltip>
                                            :
                                            <MBox className='textOverflow' fontSize={16} fontFamily="sans-serif" fontWeight={700} color="#3a3a3a" sx={{ textTransform: 'capitalize' }}>
                                                {currentUser.firstName + ' ' + currentUser.lastName}
                                            </MBox>
                                        }
                                        <MBox fontSize={14} color="#A2A4B9">{appUserRole === "ROLE_VENDOR" && 'Vendor'}{appUserRole === "ROLE_CLIENT" && 'Client'}{appUserRole === "ROLE_TRANSLATOR" && <FormattedMessage id="linguist.side" />}</MBox>
                                    </MBox>
                                </MBox>
                                <MBox mr={-2}>
                                    <MIconButton aria-controls="notificationMenu" aria-haspopup="true" color="inherit"
                                        onClick={handleClick}>
                                        <MoreVertIcon sx={{ fontSize: 22, color: '#8d8d8d' }} />
                                    </MIconButton>
                                </MBox>

                                <MMenu id="notificationMenu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}
                                    TransitionComponent={Fade}
                                    className={classes.userDropdown}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                >
                                    <NavLink className={classes.menuLink} to={currentUser.slug !== null ? "/linguist/profile/" + currentUser.slug : "#"}>
                                        < MenuItem onClick={() => currentUser.slug !== null ? handleClose() : dispatch(showModal(true))}>
                                            <FormattedMessage id="my.profile" />
                                        </MenuItem>
                                    </NavLink>
                                    {(isAdmin || isOwner) && (appUserRole === "ROLE_CLIENT") &&
                                        <NavLink className={classes.menuLink} to="/transactions">
                                            <MenuItem onClick={handleClose} className={classes.menuLink}><FormattedMessage id="transactions" /></MenuItem>
                                        </NavLink>
                                    }
                                    {/* <NavLink className={classes.menuLink} to={((appUserRole === "ROLE_VENDOR" ? '/vendor/settings' : (appUserRole === "ROLE_TRANSLATOR") ? '/linguist/profile' : '/settings'))}>
                                        <MenuItem onClick={handleClose} className={classes.menuLink}>
                                            <FormattedMessage id="settings" />
                                        </MenuItem>
                                    </NavLink> */}

                                    <MenuItem onClick={() => { (history.push('/account/settings'), handleClose()) }} className={classes.menuLink}>
                                        <FormattedMessage id="account.settings" />
                                    </MenuItem>
                                    {/* <NavLink className={classes.menuLink} to="/transactions"> */}
                                    <MenuItem onClick={() => { currentUser.isProfile ? (history.push('/transactions/tab'), handleClose()) : dispatch(showModal(true)); }} className={classes.menuLink}>
                                        <FormattedMessage id="payments" />
                                    </MenuItem>
                                    <MenuItem onClick={logout} className={`${classes.logoutLink}`}><span
                                        className={`${classes.menuLink} ${classes.logoutLink}`}><FormattedMessage id="logout" /></span></MenuItem>
                                </MMenu>
                            </MBox>
                        </MBox>
                        <MBox className="footerSm">
                            {currentUser && currentUser.profilePicture
                                ?
                                <MAvatar className={'footerAvatar'} onClick={handleClick} src={currentUser && currentUser.profilePicture ? process.env.REACT_APP_PUBLIC_URL + '/' + currentUser.profilePicture : ""} />
                                :
                                <MAvatar className={'footerAvatar'}>
                                    {currentUser && avatarPlaceholder(`${currentUser.firstName} ${currentUser.lastName}`)}
                                </MAvatar>
                            }
                        </MBox>
                        <MBox>
                        </MBox>
                    </MGrid>
                </>
            }
        </MBox >
    )
}

export default withRouter(LinguistSidebar);