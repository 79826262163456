
import { FETCH_RATES_FAILURE, FETCH_RATES_REQUEST, FETCH_RATES_SUCCESS } from "./RateRequestActionTypes";


interface IAction {
    type: string,
    payload: any,
    action: any,
}
interface IState {
    ratesList: any,
    loading: boolean,
    error: any
}
const initialState: IState = {
    ratesList: null,
    loading: false,
    error: null
};

export default function ratesRequestReducer(state = initialState, action: IAction) {
    switch (action.type) {
        case FETCH_RATES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_RATES_SUCCESS:
            return {
                ...state,
                ratesList: action.payload,
                loading: false
            };
        case FETCH_RATES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}