import { GET_NOTIFICATION } from "./NotificationActionType";
import { SET_NOTIFICATION_DATA } from "./NotificationActionType";

export const getNotificationFetch = () => ({
    type: GET_NOTIFICATION
})

export function setNotificationData(data: any) {
    return {
        type: SET_NOTIFICATION_DATA,
        payload: data
    };
}