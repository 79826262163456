import React, { Fragment } from 'react'


export function MDashboardIcon() {
    return (
        <Fragment>






            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_124_6412)">
                    <path d="M8.3 4.3C8.3 4.8 8.3 5.4 8.3 6C8.1 7.3 7.1 8.3 5.8 8.3C4.7 8.3 3.6 8.3 2.5 8.3C1.1 8.3 0 7.2 0 5.8C0 4.7 0 3.6 0 2.5C0 1.1 1.1 0 2.5 0C3.6 0 4.7 0 5.8 0C7.2 0 8.2 1.1 8.3 2.5C8.3 3.1 8.3 3.6 8.3 4.3ZM6.6 4.2C6.6 3.7 6.6 3.1 6.6 2.6C6.6 2 6.3 1.7 5.7 1.7C4.6 1.7 3.6 1.7 2.5 1.7C2 1.7 1.7 2 1.7 2.6C1.7 3.7 1.7 4.7 1.7 5.8C1.7 6.3 2 6.7 2.6 6.7C3.7 6.7 4.7 6.7 5.8 6.7C6.3 6.7 6.7 6.4 6.7 5.8C6.6 5.2 6.6 4.7 6.6 4.2Z" fill="#6B778C" />
                    <path d="M9.60059 4C9.60059 3.5 9.60059 2.9 9.60059 2.3C9.80059 1 10.8006 0 12.1006 0C13.2006 0 14.3006 0 15.4006 0C16.8006 0 17.8006 1.1 17.9006 2.5C17.9006 3.6 17.9006 4.7 17.9006 5.8C17.9006 7.2 16.8006 8.2 15.4006 8.3C14.3006 8.3 13.2006 8.3 12.1006 8.3C10.7006 8.3 9.60059 7.2 9.60059 5.8C9.60059 5.2 9.60059 4.7 9.60059 4ZM16.3006 4.2C16.3006 3.7 16.3006 3.1 16.3006 2.6C16.3006 2.1 16.0006 1.7 15.4006 1.7C14.3006 1.7 13.3006 1.7 12.2006 1.7C11.7006 1.7 11.3006 2 11.3006 2.6C11.3006 3.7 11.3006 4.8 11.3006 5.8C11.3006 6.3 11.7006 6.7 12.2006 6.7C13.3006 6.7 14.3006 6.7 15.4006 6.7C15.9006 6.7 16.3006 6.4 16.3006 5.8C16.3006 5.2 16.3006 4.7 16.3006 4.2Z" fill="#6B778C" />
                    <path d="M0.000390626 13.5C0.000390626 13 0.000390626 12.4 0.000390626 11.8C0.100391 10.5 1.10039 9.6 2.40039 9.5C3.50039 9.5 4.60039 9.5 5.70039 9.5C7.10039 9.5 8.10039 10.6 8.20039 12C8.20039 13.1 8.20039 14.2 8.20039 15.3C8.20039 16.7 7.10039 17.8 5.70039 17.8C4.60039 17.8 3.50039 17.8 2.40039 17.8C1.00039 17.8 -0.0996094 16.7 -0.0996094 15.3C0.000390626 14.7 0.000390626 14.2 0.000390626 13.5ZM4.10039 16.1C4.60039 16.1 5.20039 16.1 5.70039 16.1C6.20039 16.1 6.60039 15.8 6.60039 15.2C6.60039 14.1 6.60039 13.1 6.60039 12C6.60039 11.5 6.30039 11.1 5.70039 11.1C4.60039 11.1 3.60039 11.1 2.50039 11.1C2.00039 11.1 1.60039 11.4 1.60039 12C1.60039 13.1 1.60039 14.1 1.60039 15.2C1.60039 15.7 1.90039 16.1 2.50039 16.1C3.10039 16.1 3.60039 16.1 4.10039 16.1Z" fill="#6B778C" />
                    <path d="M13.7006 17.8C13.2006 17.8 12.6006 17.8 11.9006 17.8C10.6006 17.7 9.70059 16.7 9.60059 15.4C9.60059 14.3 9.60059 13.2 9.60059 12C9.60059 10.6 10.7006 9.59998 12.1006 9.59998C13.2006 9.59998 14.3006 9.59998 15.4006 9.59998C16.8006 9.59998 17.8006 10.7 17.9006 12.1C17.9006 13.2 17.9006 14.3 17.9006 15.4C17.9006 16.8 16.8006 17.9 15.4006 17.9C14.9006 17.8 14.3006 17.8 13.7006 17.8ZM11.3006 13.6C11.3006 14.1 11.3006 14.7 11.3006 15.2C11.3006 15.7 11.6006 16.1 12.2006 16.1C13.3006 16.1 14.3006 16.1 15.4006 16.1C15.9006 16.1 16.3006 15.8 16.3006 15.2C16.3006 14.1 16.3006 13 16.3006 12C16.3006 11.5 15.9006 11.1 15.4006 11.1C14.3006 11.1 13.3006 11.1 12.2006 11.1C11.7006 11.1 11.3006 11.4 11.3006 12C11.3006 12.6 11.3006 13.1 11.3006 13.6Z" fill="#6B778C" />
                </g>
                <defs>
                    <clipPath id="clip0_124_6412">
                        <rect width="17.9" height="17.8" fill="white" />
                    </clipPath>
                </defs>
            </svg>





        </Fragment>


    )
}