import { SET_AUTH, SET_CHAT_NOTIFICATION, SET_CURRENT_USER_DATA } from "./AuthActionTypes";

interface currentUser {
    id: number,
    username: string,
    firstName: string,
    lastName: string,
    lang: any,
    isProfile: number,
    isPrivate: boolean,
    isChatNotification: boolean,
    city: any,
    state: any,
    country: any,
    postalCode: string,
    address: string,
    timeZone: string,
    isEmailEnable: any,
    rates: any,
    slug: string,
    isBuyPackageFlag: boolean,
    ssoUserName: string
}

export const setAuth = (token: string) => {
    return {
        type: SET_AUTH,
        payload: token
    }
}

export const setCurrentUserData = (data: currentUser) => {
    return {
        type: SET_CURRENT_USER_DATA,
        payload: data
    }
}
export const setChatNotification = () => {
    return {
        type: SET_CHAT_NOTIFICATION,
    }
}