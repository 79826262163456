import { FETCH_ASSIGNED_JOB_FILES_REQUEST, FETCH_ASSIGNED_JOB_FILES_SUCCESS, FETCH_ASSIGNED_JOB_FILES_FAILURE } from './JobActionTypes';


interface IAction {
    type: string,
    payload: any,
    action: any,
}
interface IState {
    jobFiles: any,
    loading: boolean,
    error: any
}
const initialState: IState = {
    jobFiles: null,
    loading: false,
    error: null
};

export default function AssignedJobFilesReducer(state = initialState, action: IAction) {
    switch (action.type) {
        case FETCH_ASSIGNED_JOB_FILES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_ASSIGNED_JOB_FILES_SUCCESS:
            return {
                ...state,
                jobFiles: action.payload,
                loading: false
            };
        case FETCH_ASSIGNED_JOB_FILES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}