/* eslint-disable */
import React from 'react';

export function MDispatch() {
    return <React.Fragment>

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.2 12C19.2 13.9096 18.4414 15.7409 17.0912 17.0912C15.7409 18.4414 13.9096 19.2 12 19.2C10.0904 19.2 8.25909 18.4414 6.90883 17.0912C5.55857 15.7409 4.8 13.9096 4.8 12C4.8 10.0904 5.55857 8.25909 6.90883 6.90883C8.25909 5.55857 10.0904 4.8 12 4.8C12.684 4.8 13.35 4.899 13.98 5.079L15.393 3.666C14.349 3.234 13.206 3 12 3C10.8181 3 9.64778 3.23279 8.55585 3.68508C7.46392 4.13738 6.47177 4.80031 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12C3 14.3869 3.94821 16.6761 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C14.3869 21 16.6761 20.0518 18.364 18.364C20.0518 16.6761 21 14.3869 21 12H19.2ZM8.319 10.272L7.05 11.55L11.1 15.6L20.1 6.6L18.831 5.322L11.1 13.053L8.319 10.272Z" fill="#A0A5B9" />
        </svg>


    </React.Fragment>;
}


