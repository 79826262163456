/* eslint-disable */
import React from 'react';
import { FormattedMessage } from "react-intl";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { MDialog } from "src/app/material-ui/dialog/Dialog";
import { MDialogTitle } from "src/app/material-ui/dialog-title/DialogTitle";
import { MDialogContent } from "src/app/material-ui/dialog-content/DialogContent";
import { IService, ILanguage, IStatus } from './interface';
import {
    MBox,
    MButton,
    MSkeleton,
    MFormik,
    MForm,
    MTextField, MAutoComplete, MCircularProgress
} from 'src/app/material-ui';

function JobListingFilterMbl(props: any) {
    return (
        <MFormik
            enableReinitialize="true"
            initialValues={{
                clientName: '',
                jobsTitle: '',
                sourceLanguage: [],
                service: [],
                status: [],
            }}
            onSubmit={(values: any) => {
                if (values) {
                    props.setFormJobTitle(values.jobsTitle);
                    props.setClientName(values.clientName);
                    props.setServicesFieldValue(values.service.map((val: IService) => val.id).join(','));
                    props.setStatusFieldValue(values.status.map((val: IStatus) => val.id).join(','));
                    props.setLanguagesFieldValue(values.sourceLanguage.map((val: ILanguage) => val.id).join(','));
                    // props.getAllJobs();
                }
                props.setFilter(!props.filter)
                props.setOpen(false);
            }}
        >
            {({ setFieldValue, values, resetForm }: any) => (
                <>
                    {(props.openProjectsLoading || !props.jobsList)
                        ?
                        <MForm>
                            <MBox mt={3} mb={1} display="flex">
                                <MBox maxWidth={168} mr={1}>
                                    <MSkeleton width={120} height={30} />
                                </MBox>
                                <MBox maxWidth={168} mr={1}>
                                    <MSkeleton width={120} height={30} />
                                </MBox>
                                <MBox maxWidth={168} mr={1}>
                                    <MSkeleton width={120} height={30} />
                                </MBox>
                                <MBox maxWidth={168} mr={1}>
                                    <MSkeleton width={120} height={30} />
                                </MBox>
                                <MBox maxWidth={168} mr={1}>
                                    <MSkeleton width={120} height={30} />
                                </MBox>
                                <MBox maxWidth={168}>
                                    <MSkeleton width={100} height={30} />
                                </MBox>
                            </MBox>
                        </MForm>
                        :
                        <MDialog
                            open={props.open}
                            keepMounted
                            onClose={props.handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        // mediumScreen
                        >
                            <MDialogTitle id="alert-dialog-title" className={props.classes.mblDialogTitle}>
                                <FormattedMessage id="filters" />
                                <CloseOutlinedIcon onClick={props.handleClose} />
                            </MDialogTitle>
                            <MDialogContent>
                                <MForm>
                                    {(props.openProjectsLoading || (props.jobsList && props.jobsList.length > 0) ||
                                        ((props.clientName !== "") || (props.formJobTitle !== "") || (props.languagesFieldValue !== "") || (props.statusFieldValue !== "") || (props.servicesFieldValue !== ""))) &&
                                        <MBox mt={3} mb={1}>
                                            <MBox mb={2}>
                                                <FormattedMessage id="job.title">
                                                    {(jobTitle) =>
                                                        <MTextField
                                                            className={props.classes.seachInput}
                                                            variant="outlined"
                                                            color="secondary"
                                                            size="small"
                                                            placeholder={jobTitle + ""}
                                                            name="jobsTitle"
                                                            disabled={false}
                                                            defaultValue={values.companyName}
                                                            fullWidth
                                                        />
                                                    }
                                                </FormattedMessage>
                                            </MBox>
                                            {props.hideFilter != true &&
                                                <MBox mb={2}>
                                                    <FormattedMessage id="client.name">
                                                        {(clientName) =>
                                                            <MTextField
                                                                className={props.classes.seachInput}
                                                                variant="outlined"
                                                                color="secondary"
                                                                size="small"
                                                                placeholder={clientName + ""}
                                                                name="clientName"
                                                                disabled={false}
                                                                defaultValue={values.companyName}
                                                                fullWidth
                                                            />
                                                        }
                                                    </FormattedMessage>
                                                </MBox>
                                            }
                                            <MBox mb={2}>
                                                {props?.statusArray && (
                                                    <FormattedMessage id="status">
                                                        {label =>
                                                            <MAutoComplete
                                                                className={props.classes.autoCompleteSizeSm}
                                                                id="statusAutocomplete"
                                                                label={(values.status.length > 0 ? "" : String(label))}
                                                                value={values.status}
                                                                multiple={true}
                                                                options={props.statusArray}
                                                                getOptionLabel={(option: IStatus) => option.name}
                                                                disableClearable={true}
                                                                onChange={(event: React.ChangeEvent, value: IStatus[]) => {
                                                                    setFieldValue('status', value);
                                                                    event?.preventDefault();
                                                                    // if(value[0].id == 12 && props.status == 'open') {
                                                                    //     setFieldValue('status', {id:'8',name:'PO Review'})
                                                                    // }
                                                                }}
                                                            />
                                                        }
                                                    </FormattedMessage>
                                                )}
                                            </MBox>
                                            <MBox mb={2}>
                                                <FormattedMessage id="services">
                                                    {label =>
                                                        <MAutoComplete
                                                            className={props.classes.autoCompleteSizeSm}
                                                            id="statusAutocomplete"
                                                            label={(values.service.length > 0 ? "" : String(label))}
                                                            value={values.service}
                                                            multiple={true}
                                                            options={props.serviceData && props.serviceData}
                                                            getOptionLabel={(option: IService) => option.name}
                                                            disableClearable={true}
                                                            onChange={(event: React.ChangeEvent, value: IService[]) => {
                                                                event?.preventDefault();
                                                                setFieldValue('service', value)
                                                            }}
                                                        />
                                                    }
                                                </FormattedMessage>
                                            </MBox>
                                            <MBox mb={2}>
                                                {props?.languagesData && props?.languagesData?.length && (
                                                    <FormattedMessage id="languages">
                                                        {label =>
                                                            <MAutoComplete
                                                                className={props.classes.autoCompleteSizeSm}
                                                                id="statusAutocomplete"
                                                                label={(values?.sourceLanguage?.length > 0 ? "" : String(label))}
                                                                multiple={true}
                                                                value={values.sourceLanguage}
                                                                options={props.languagesData}
                                                                getOptionLabel={(option: ILanguage) => option.name}
                                                                disableClearable={true}
                                                                onChange={(event: React.ChangeEvent, value: ILanguage[]) => {
                                                                    event?.preventDefault();
                                                                    setFieldValue('sourceLanguage', value)
                                                                }}
                                                            />
                                                        }
                                                    </FormattedMessage>
                                                )}
                                            </MBox>
                                            <MBox display="flex" mb={2} alignItem="center">
                                                <MButton
                                                    className={props.classes.btnSmall}
                                                    color="primary"
                                                    disabled={props.openProjectsLoading}
                                                    variant="contained"
                                                    type="submit"
                                                >
                                                    <FormattedMessage id="search" />
                                                    {props.openProjectsLoading &&
                                                        <MCircularProgress color="secondary" size={24} />
                                                    }
                                                </MButton>
                                                {values.clientName != '' || values.jobsTitle != '' || values.sourceLanguage.length > 0 || values.service.length > 0 || values.status.length > 0 ?
                                                    <MButton className={props.classes.clearAll}
                                                        variant="contained"
                                                        href="javascript:void(0)"
                                                        size="medium"
                                                        fullWidth
                                                        type="button"
                                                        onClick={() => {
                                                            resetForm();
                                                            setFieldValue('service', []);
                                                            setFieldValue('sourceLanguage', []);
                                                            props.setFormJobTitle('');
                                                            props.setClientName('');
                                                            props.setServicesFieldValue('');
                                                            props.setStatusFieldValue('');
                                                            props.setLanguagesFieldValue('');
                                                            props.setFilter(!props.filter)
                                                            //  props.getAllJobs();
                                                        }}>
                                                        <FormattedMessage id="clear.all" />
                                                    </MButton> : ''
                                                }
                                            </MBox>
                                        </MBox>
                                    }
                                </MForm>
                            </MDialogContent>
                        </MDialog>
                    }
                </>
            )}
        </MFormik>
    )
}
export default JobListingFilterMbl;