import React from 'react'

export function MPOsend() {
    return (


        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 16.5L16.3846 21L13.1538 17.85L14.5385 16.5L16.3846 18.3L19.6154 15.15L21 16.5ZM4.84615 3C3.82154 3 3 3.801 3 4.8V19.2C3 20.199 3.82154 21 4.84615 21H12.0554C11.5662 20.181 11.3077 19.245 11.3077 18.3C11.3077 15.321 13.7908 12.9 16.8462 12.9C17.16 12.9 17.4646 12.927 17.7692 12.972V8.4L12.2308 3H4.84615ZM11.3077 4.35L16.3846 9.3H11.3077V4.35Z" fill="#A7ADBB" />
        </svg>
    )
}

