import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';
// import {customColors} from '../utilities/colors/custmColors'

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles((theme: Theme) =>
  // const colors = customColors;
  createStyles({
    treeItem: {
      padding: '5px 0',
      '& .css-5qd238-MuiTreeItem-content.Mui-selected': {
        backgroundColor: '#f5f6f7 !important',
        '& .fileFolderIcon': {
          fill: 'rgb(63, 116, 232) !important',
        },
        // '& .MuiTreeItem-content':{
        //   '& .MuiTypography-root':{
        //     '& .fileFolderIcon':{
        //       fill: 'rgb(63, 116, 232)'
        //     }
        //   },
        // }
      }


    },
    // folderCollapse:{
    //   borderBottom: 'none',
    //   boxShadow: 'none',
    //   margin:'8px 0 0 !important',
    //   '&::before':{
    //     display: 'none'
    //   },
    //   '& .dirPathLine':{
    //     height: 0,
    //     width: 1,
    //     backgroundColor:'lightgrey',
    //     position: 'absolute',
    //     top: 20,
    //     left: -19,
    //     display: 'none',
    //     '&.file':{
    //       display:'inline-block',
    //       height:1,
    //       left:-38,
    //       width:42,
    //       top:12,
    //     }
    //   },
    //   "& .MuiExpansionPanelSummary-expandIcon":{
    //     left: -10,
    //     right: 'initial',
    //     backgroundColor: '#a8a7bc',
    //     borderRadius: 2,
    //     color: '#fff',
    //     height: 13,
    //     width: 13,
    //     '& svg':{
    //       fontSize:13
    //     }
    //   },
    //   '& >.Mui-expanded':{
    //     '& .dirPathLine':{
    //       display: 'inline-block'
    //     },
    //     "& .MuiExpansionPanelSummary-expandIcon":{
    //       '& svg':{
    //         display:'none'
    //       },
    //       '& .MuiIconButton-label':{
    //         width: 7,
    //         height: 1,
    //         backgroundColor: '#fff'
    //       }
    //     },
    //   }
    // },
    newFolderInput: {
      margin: '8px 0 6px 24px',
      '& input': {
        padding: '3px 10px 5px',
        fontSize: 14
      }
    },
    newFolderActions: {
      margin: '6px 0 0 6px',
      '& button': {
        padding: 3,
        marginRight: 3,
      }
    },
    checkboxWrap: {
      lineHeight: 1,
      '& .MuiIconButton-root': {
        padding: '4px 2px 0 0',
        '& svg': {
          width: 15,
          height: 15
        }
      }
    },
    root: {
      maxHeight: 300,
      flexGrow: 1,
      // maxWidth: 400,
      overflowY: 'auto'
    },
    treeActionButton: {
      color: '#949494',
    },
    folderIcon: {
      color: theme.palette.secondary.main,
      marginRight: 8,
    },
    errorMessage: {
      '& span': {
        bottom: -10,
        left: 30
      }
    },
    error: {
      display: 'flex',
      position: 'absolute',
      width: 246,
      left: -170,
      bottom: -17,
      fontSize: 11,
      color: 'red',
    }


  }));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;