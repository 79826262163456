import React, { Fragment } from 'react'

export function MJcbCard() {
    return (
        <Fragment>


            <svg width="60" height="36" viewBox="0 0 60 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M42.1562 21.8572H45.6202C45.7191 21.8572 45.9501 21.8242 46.049 21.8242C46.7088 21.6923 47.2697 21.0989 47.2697 20.2747C47.2697 19.4835 46.7088 18.8901 46.049 18.7253C45.9501 18.6923 45.7521 18.6923 45.6202 18.6923H42.1562V21.8572Z" fill="#5CB531" />
                <path d="M45.2246 0C41.9256 0 39.2205 2.67033 39.2205 6V12.2308H47.6988C47.8968 12.2308 48.1277 12.2308 48.2926 12.2637C50.206 12.3626 51.6246 13.3516 51.6246 15.0659C51.6246 16.4176 50.6679 17.5714 48.8865 17.8022V17.8681C50.8329 18 52.3174 19.0879 52.3174 20.7692C52.3174 22.5824 50.6679 23.7692 48.4906 23.7692H39.1875V35.967H47.9957C51.2947 35.967 53.9999 33.2967 53.9999 29.967V0H45.2246Z" fill="#5CB531" />
                <path d="M46.8408 15.4615C46.8408 14.6703 46.28 14.1428 45.6202 14.0439C45.5542 14.0439 45.3892 14.0109 45.2903 14.0109H42.1562V16.912H45.2903C45.3892 16.912 45.5872 16.912 45.6202 16.8791C46.28 16.7802 46.8408 16.2527 46.8408 15.4615Z" fill="#5CB531" />
                <path d="M12.0371 0C8.73814 0 6.03299 2.67033 6.03299 6V20.8022C7.71546 21.6264 9.46392 22.1538 11.2124 22.1538C13.2907 22.1538 14.4124 20.9011 14.4124 19.1868V12.1978H19.5588V19.1538C19.5588 21.8571 17.8763 24.0659 12.1691 24.0659C8.70515 24.0659 6 23.3077 6 23.3077V35.9341H14.8082C18.1072 35.9341 20.8124 33.2637 20.8124 29.9341V0H12.0371Z" fill="#006FBC" />
                <path d="M28.6311 0C25.3321 0 22.627 2.67033 22.627 6V13.8462C24.1445 12.5604 26.7837 11.7363 31.0393 11.9341C33.3156 12.033 35.7568 12.6593 35.7568 12.6593V15.1978C34.5362 14.5714 33.0847 14.011 31.2043 13.8791C27.9713 13.6484 26.0249 15.2308 26.0249 18C26.0249 20.8022 27.9713 22.3846 31.2043 22.1209C33.0847 21.989 34.5362 21.3956 35.7568 20.8022V23.3407C35.7568 23.3407 33.3486 23.967 31.0393 24.0659C26.7837 24.2637 24.1445 23.4396 22.627 22.1538V36H31.4352C34.7342 36 37.4393 33.3297 37.4393 30V0H28.6311Z" fill="#E60039" />
            </svg>


        </Fragment>
    )
}
