import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { MBox, MButton, MCircularProgress, MDivider, MFormControlLabel, MLink, MRadio } from 'src/app/material-ui';

import { cardIcon } from 'src/app/hooks/helpers';

import useStyles from './PaymentStyles';
import AddCardForm from "../../../../components/add-card-form/AddCardForm";
import theme from 'src/app/material-ui/ThemeConfig';

export default function CreditCard(props: any) {
    const classes = useStyles({});
    const [isChangeCard, setIsChangeCard] = useState(0);
    const [isAddCard, setIsAddCard] = useState(0);
    const [selectedCard, setSelectedCard] = useState(props.defaultCard);
    const [event, setEvent] = useState(null);
    const [isSubmit, setIsSubmit] = useState(false);

    const handlePaymentSubmit = (e: any) => {
        setEvent(e);
        setIsSubmit(true);
        props.updateInvoice();
    };

    useEffect(() => {
        props.setSelectedCard(selectedCard);
    }, [selectedCard]);

    const handleChangeCreditCard = () => {
        setIsAddCard(0);
        setIsChangeCard(1)
    }
    return (
        <div>
            <MBox className={classes.paymentCard}>

                <MBox display='flex' justifyContent='space-between' alignItems='center' mb={3}>
                    <MBox fontFamily='Poppins' fontSize={22} color='#3a3a3a'>
                        <FormattedMessage id='credit.card' />
                    </MBox>
                    {props.allCards && props.allCards.length > 0 &&
                        <MBox display='flex' fontSize={14}>
                            {isChangeCard === 0 ? <><MLink onClick={handleChangeCreditCard} component='button' color="secondary" sx={{ fontSize: 14 }}>
                                <FormattedMessage id='change' />
                            </MLink>

                            </> : ''}

                            {isAddCard === 0 && isChangeCard === 0 ?
                                <MDivider sx={{ margin: 'auto 8px', height: 20, width: 2 }} orientation="vertical"
                                    flexItem /> : ''}

                            {isAddCard === 0 ? <MLink onClick={() => {
                                setIsAddCard(1);
                                setIsChangeCard(0)
                            }} component='button' color="secondary" sx={{ fontSize: 14 }}>
                                <FormattedMessage id='New' />
                            </MLink> : ''}

                        </MBox>}
                </MBox>

                {props.defaultCard && isChangeCard === 0 && isAddCard === 0 ?
                    <MBox className={`${classes.cardDetails} ${classes.cardDetailsCenter}`}>
                        <MBox className={classes.cardBorder}>
                            {cardIcon(props.defaultCard.type)}
                        </MBox>
                        <MBox fontFamily='Poppins' flexDirection="column" fontSize={18} color='#3a3a3a'>
                            {props.defaultCard && (props.defaultCard.type).toUpperCase()} **** ****
                            **** {props.defaultCard && props.defaultCard.number}
                            <MBox color="#6B778C" fontSize={14}>
                                <FormattedMessage
                                    id='expires' /> {props.defaultCard && props.defaultCard.expiryMonth}/{props.defaultCard && props.defaultCard.expiryYear}
                            </MBox>
                        </MBox>
                    </MBox> : ''
                }

                {isChangeCard === 1 && <>
                    {props.allCards && props.allCards.length > 0 &&
                        <>
                            {props.allCards.map((card: any) => (
                                <MBox className={`${classes.cardDetails}`} sx={{ paddingLeft: 26, paddingRight: 26 }}>
                                    <MBox>
                                        <MFormControlLabel value={card.number}
                                            control={<MRadio
                                                onChange={() => {
                                                    setSelectedCard(card)
                                                }}
                                                checked={selectedCard && selectedCard.id == card.id ? true : false}
                                                           /*className={classes.radioOptions}*/ />}
                                        />

                                    </MBox>

                                    <MBox className={classes.cardBorder}>
                                        {cardIcon(card.type)}
                                    </MBox>
                                    <MBox display='flex' justifyContent='space-between' alignItems='center' width="100%">
                                        <MBox fontFamily='Poppins' flexDirection="column" fontSize={18}
                                            color='#3a3a3a'>
                                            {card && (card.type).toUpperCase()}
                                            **** **** **** {card && card.number}
                                            <MBox color="#6B778C" fontSize={14}>
                                                <FormattedMessage
                                                    id='expires' /> {card && card.expiryMonth}/{card && card.expiryYear}
                                            </MBox>
                                        </MBox>


                                        {card.isDefault == 1 && <MBox className={`${classes.defaultStatus}`}>
                                            <FormattedMessage id="Default" />

                                        </MBox>}
                                    </MBox>


                                </MBox>

                            ))}
                        </>
                    }

                </>}

                {(isAddCard === 1 || (props.allCards && props.allCards.length == 0)) && <AddCardForm updateInvoice={props.updateInvoice}
                    setOnlineTransactionDetails={props.setOnlineTransactionDetails} setIsNewCard={props.setIsNewCard} setPaymentMethod={props.setPaymentMethod}
                    fee={props.selectedGateway && props.selectedGateway.fee} event={event}
                    isNew={1} setAddFundsDrawer={props.setAddFundsDrawer} fundsAmt={props.fundsAmt}
                    amt={props.selectedJob && props.selectedGateway ? (props.selectedGateway.fee + props.selectedJob.invoiceBalance) : (props.amt ? props.selectedGateway.fee + parseFloat(props.amt) : 0)} />}

                {(isAddCard !== 1 && (props.allCards && props.allCards.length > 0)) && <>
                    <MBox display='flex' justifyContent='center' color='#6B778C' mt={3.2} mb={5}>
                        <MBox>
                            <FormattedMessage
                                id='Fund amount' />: <span>${props.fundsAmt}</span>
                        </MBox>
                        <MBox sx={{ paddingLeft: 1 }}>
                            <MDivider sx={{ margin: 'auto 8px', height: 20, width: 2 }} orientation="vertical" flexItem />
                        </MBox>
                        <MBox>
                            <FormattedMessage
                                id='card.fee' />: <span>${props.selectedGateway && props.selectedGateway.fee}</span>
                        </MBox>
                        <MBox sx={{ paddingLeft: 1 }}>
                            <MDivider sx={{ margin: 'auto 8px', height: 20, width: 2 }} orientation="vertical"
                                flexItem />
                        </MBox>


                        <MBox sx={{ paddingLeft: 1 }}>
                            <FormattedMessage
                                id='total.payable.amount' />: &nbsp;
                            <span>${props.selectedJob && props.selectedGateway && (props.selectedGateway.fee + props.selectedJob.invoiceBalance)}

                                {props.selectedGateway && props.amt ? (props.selectedGateway.fee + parseFloat(props.amt)) : ''}
                            </span>
                        </MBox>
                    </MBox>
                    {/*<MBox className={`${classes.paymentBox} ${classes.creditCard}`}>*/}
                    {/*<MBox fontFamily='Poppins' fontSize={24} color='#3F74E8'>*/}
                    {/*Fee: {props.selectedGateway && props.selectedGateway.fee}*/}
                    {/*Total:*/}
                    {/*${props.selectedJob && props.selectedGateway && (props.selectedGateway.fee + props.selectedJob.invoiceBalance)}*/}
                    {/*</MBox>*/}
                    {/*</MBox>*/}

                    <MBox display='flex' color justifyContent='flex-end'>
                        <MLink href="#" color='#3a3a3a' component='button' onClick={() => props.setAddFundsDrawer(false)} sx={{ fontSize: 14 }}>
                            <FormattedMessage id='cancel' />
                        </MLink>
                        {process.env.REACT_APP_ENVIRONMENT != 'production' &&
                            <MLink underline='none' href="#" color='#3a3a3a' component='button'
                                sx={{ fontSize: 14, marginLeft: 1 }}>
                                <MButton type="button" onClick={handlePaymentSubmit} disabled={isSubmit}
                                    sx={{ backgroundColor: theme.palette.secondary.dark }} variant="contained">
                                    {isSubmit && <MCircularProgress color="secondary" size={24} />} <FormattedMessage id='pay.now' />
                                </MButton>
                            </MLink>
                        }
                    </MBox>

                </>

                }


            </MBox>
        </div>
    )
}
