import React, { Fragment } from 'react'

export function MAmexCard() {
    return (
        <Fragment>
            <svg width="64" height="36" viewBox="0 0 64 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M49.8822 26.6841L52.5502 23.334L55.2373 26.6707H64L57.1273 17.9394L64 9.34254L26.6724 9.31567L25.2621 13.7565L23.8565 9.31567H15.7047V15.2448L13.3851 9.31567H6.78206L0 26.6438L49.8822 26.6841Z" fill="#1CD8B3" />
                <path fillRule="evenodd" clipRule="evenodd" d="M34.7285 24.7445V11.2231H44.9111V14.3368H38.0241V16.4511H44.7465V19.5138H38.0241V21.5905H44.9111V24.7445H34.7285Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M44.8613 24.7445L50.4955 17.9744L44.8613 11.2231H49.2212L52.6648 15.5082L56.1178 11.2231H60.294V11.3279L54.7767 17.9744L60.294 24.551V24.7445H56.0773L52.5717 20.4138L49.1043 24.7445H44.8613Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M8.03384 11.199L2.70508 24.7068H6.361L7.36566 22.0015H12.8137L13.8184 24.7068H17.565L12.2553 11.199H8.03384ZM8.49202 18.9711L10.0909 14.67L11.6874 18.9711H8.49202Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M17.5117 24.7039V11.196L22.6496 11.2149L25.2937 19.5216L27.9545 11.196H32.899V24.7039L29.718 24.7362V15.4569L26.7136 24.7039H23.8093L20.7429 15.4246V24.7039H17.5117Z" fill="white" />
            </svg>
        </Fragment>
    )
}
