import React, { Fragment } from 'react'

export function MDinersCard() {
    return (
        <Fragment>

            <svg width="64" height="36" viewBox="0 0 64 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M42.9097 36C52.8996 36 61 27.94 61 18C61 8.06001 52.8996 0 42.9097 0H21.0903C11.1004 0 3 8.06001 3 18C3 27.94 11.1004 36 21.0903 36H42.9097Z" fill="#0054A6" />
                <path d="M38.8034 18.0001C38.8034 27.7339 30.8729 35.6249 21.0902 35.6249C11.3075 35.6249 3.37695 27.7339 3.37695 18.0001C3.37695 8.26622 11.3075 0.375244 21.0902 0.375244C30.8729 0.375244 38.8034 8.26622 38.8034 18.0001Z" fill="white" />
                <path d="M10.0029 17.9999C10.0029 22.6916 12.9489 26.6866 17.0924 28.2821V7.71362C12.9448 9.31326 10.0029 13.3082 10.0029 17.9999Z" fill="#0054A6" />
                <path d="M32.1822 18C32.1822 13.3124 29.2404 9.31741 25.0928 7.71777V28.2821C29.2362 26.6825 32.1822 22.6875 32.1822 18Z" fill="#0054A6" />
            </svg>

        </Fragment>
    )
}
