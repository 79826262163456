
import { FETCH_CUSTOMERS_FAILURE, FETCH_CUSTOMERS_REQUEST, FETCH_CUSTOMERS_SUCCESS } from "./CustomerActionTypes";


// GET ALL CUSTOMERS

export function fetchCustomersRequest(data: any) {
    return {
      type: FETCH_CUSTOMERS_REQUEST,
      payload: data
    };
  }
  
  export function fetchCustomersSuccess(data: any) {
    return {
      type: FETCH_CUSTOMERS_SUCCESS,
      payload: data
    };
  }
  
  export function fetchCustomersFailure(error: any) {
    return {
      type: FETCH_CUSTOMERS_FAILURE,
      payload: error
    };
  }