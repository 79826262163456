import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formBox: {
      backgroundColor: 'white',
      boxShadow: '0px 5px 5px 0px rgba(193, 199, 208, 0.26)',
      borderRadius: 5,
      '& .MuiInputBase-input:not(.MuiAutocomplete-input)': {
        paddingTop: '9.5px !important',
        paddingBottom: '11.5px !important'
      },
      '& .MuiAutocomplete-input': {
        padding: '3.5px 6px 5.5px !important'
      }
    },
    formLabel: {
      fontSize: 14,
      color: theme.palette.text.primary,
    },
    inputWidth: {
      maxWidth: 534,
      width: '100%',
    },
    footerBottom: {
      display: 'flex',
      justifyContent: 'flex-end',
      position: 'fixed',
      bottom: 0,
      left: '15%',
      width: '85%',
      padding: '20px 27px 20px 0px',
      backgroundColor: '#fff',
      borderTop: '1px solid #dbdbdb',

    }

    // dropableBox:{
    //   border: "1px dashed #dcddde",
    //   backgroundColor: "#fefefe",
    // },

    // //services listing
    // servicesListingBox:{
    //     backgroundColor: '#fff',
    //     boxShadow: '0px 5px 5px 0px rgba(193, 199, 208, 0.26)',
    //     borderRadius: 5,
    // },
  }));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;