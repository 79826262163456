import React from 'react';

export function MInvoiceApproved() {
    return <React.Fragment>

        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.39431 13.3936L6.28748 13.4996L6.39395 13.6061L8.8945 16.1061L9.00094 16.2125L9.10699 16.1057L9.71345 15.4949L9.81877 15.3889L9.71306 15.2832L7.21681 12.7875L7.11112 12.6818L7.00506 12.7871L6.39431 13.3936ZM16.4959 9.89415L16.3898 9.78803L16.2837 9.8942L11.8393 14.3427L10.1562 12.6556L10.0509 12.55L9.9447 12.6547L9.32965 13.2611L9.22208 13.3672L9.3289 13.474L11.7332 15.8783L11.8393 15.9844L11.9453 15.8783L17.1066 10.717L17.2127 10.611L17.1066 10.5049L16.4959 9.89415ZM14.2133 10.2166L14.3185 10.1109L14.2134 10.0051L13.607 9.39431L13.5007 9.28723L13.3942 9.3942L11.1222 11.6775L11.0163 11.784L11.1228 11.8898L11.7336 12.4962L11.8399 12.6018L11.9456 12.4956L14.2133 10.2166Z" fill="#16C68E" stroke="#16C68E" strokeWidth="0.3" />
            <path d="M14.625 1.5H3.375C2.325 1.5 1.5 2.325 1.5 3.375V14.625C1.5 15.675 2.325 16.5 3.375 16.5H5V15H3.375C3.15 15 3 14.85 3 14.625V3.375C3 3.15 3.15 3 3.375 3H14.625C14.85 3 15 3.15 15 3.375V7H16.5V3.375C16.5 2.325 15.675 1.5 14.625 1.5Z" fill="#16C68E" />
            <path d="M12 4.875H4.5V6.375H12V4.875Z" fill="#16C68E" />
            <path d="M9 7.875H4.5V9.375H9V7.875Z" fill="#16C68E" />
        </svg>




    </React.Fragment>;
}


