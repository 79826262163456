import React, { Fragment } from 'react'

export function MTranslate() {
    return (
        <Fragment>

            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.57935 10.0467L6.88602 8.37337L6.90602 8.35337C8.06602 7.06004 8.89268 5.57337 9.37935 4.00004H11.3327V2.66671H6.66602V1.33337H5.33268V2.66671H0.666016V4.00004H8.11268C7.66602 5.28004 6.95935 6.50004 5.99935 7.56671C5.37935 6.88004 4.86602 6.12671 4.45935 5.33337H3.12602C3.61268 6.42004 4.27935 7.44671 5.11268 8.37337L1.71935 11.72L2.66602 12.6667L5.99935 9.33337L8.07268 11.4067L8.57935 10.0467ZM12.3327 6.66671H10.9993L7.99935 14.6667H9.33268L10.0793 12.6667H13.246L13.9993 14.6667H15.3327L12.3327 6.66671ZM10.586 11.3334L11.666 8.44671L12.746 11.3334H10.586Z" fill="#4C5B76" />
            </svg>

        </Fragment>
    )
}
