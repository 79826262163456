import { FETCH_CUSTOMERS_FAILURE, FETCH_CUSTOMERS_REQUEST, FETCH_CUSTOMERS_SUCCESS } from "./CustomerActionTypes";

interface IAction {
    type: string,
    payload: any,
    action: any,
}
interface IState {
    list: any,
    loading: boolean,
    error: any
}
const initialState: IState = {
    list: null,
    loading: false,
    error: null
};

export default function CustomerReducer(state = initialState, action: IAction) {
    switch (action.type) {
        case FETCH_CUSTOMERS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_CUSTOMERS_SUCCESS:
            return {
                ...state,
                list: action.payload,
                loading: false
            };
        case FETCH_CUSTOMERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}