import { put, takeEvery } from 'redux-saga/effects';
import { gql } from 'apollo-boost';
import { client } from "src/app/hooks";
import { fetchRatesFailure, fetchRatesSuccess } from './RateRequestActions';
import { FETCH_RATES_REQUEST } from './RateRequestActionTypes';


const GET_VENDOR_RATES_REQUEST = gql`query getVendorRatesRequest($orgId:Int,$name:String,$targetLangId:[Int],$subject:String,$sourceLangId:Int,$type:String,$page: Int,$status: Int) {
    getVendorRatesRequest(orgId: $orgId,name:$name,targetLangId:$targetLangId,subject:$subject,sourceLangId:$sourceLangId,type:$type,page: $page,status: $status) {
        vendorData {
            id
            status
            companyName
            role
            packageName
            packageId
            sourceLanguage {
                id
                name
                code
            }
            targetLanguages {
                id
                name
                code
            }
            price
            subject
            clients {
                id
                username
            }
        }
        total
  }
  
}`;

function* fetchRates(action: any): any {
  const variables = action.payload || {};
  try {

    const rates = yield client.query({ query: GET_VENDOR_RATES_REQUEST, variables, fetchPolicy: "no-cache" });

    yield put(fetchRatesSuccess(rates.data));

  } catch (error) {
    yield put(fetchRatesFailure(error));
  }
}

export default function* rateSaga() {
  yield takeEvery(FETCH_RATES_REQUEST, fetchRates);

}