import React, { Fragment } from 'react'

export function MTextboxCheckOutline() {
    return (
        <Fragment>

            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <path d="M24.66 31.5L20.7 27L22.3704 25.26L24.66 27.645L29.8296 22.26L31.5 24.375M18.612 31.5H7.38C5.7816 31.5 4.5 30.165 4.5 28.5V7.5C4.5 5.835 5.7816 4.5 7.38 4.5H27.54C29.1384 4.5 30.42 5.835 30.42 7.5V19.2C29.5416 18.675 28.5768 18.3 27.54 18.12V7.5H7.38V28.5H17.5752C17.748 29.58 18.108 30.585 18.612 31.5ZM17.46 25.5H10.26V22.5H17.46M21.3192 19.5H10.26V16.5H24.66V18.12C23.436 18.33 22.3128 18.81 21.3192 19.5ZM24.66 13.5H10.26V10.5H24.66" fill="#A2A4B9"/>
            </svg>

        </Fragment>
    )
}
