import { Theme } from '@mui/material/styles';


import { makeStyles } from "@mui/styles";
// const drawerWidth = 260;
const drawerWidth = '16.5%';

const useStyles: any = makeStyles((theme: Theme) => ({
  root: {
    // overflowX: 'hidden',
    // overflowY: 'hidden',
    display: "flex",



  },
  content: {
    flexGrow: 1,
    marginTop: theme.spacing(6.5),
    backgroundColor: "#f4f5f7",
    minHeight: "calc(100vh - 68)",
    width: "calc(100% - 16.5%)",
    marginLeft: "auto",
    overflow: "auto",

    // [theme.breakpoints.down(1400)]: {
    //   padding: '30px 15px'
    // }
  },
  fullWidth: {
    width: "100% !important",
  },
  appBar: {
    width: "calc(100% - 70px)",
    backgroundColor: "#fff",
    boxShadow:
      "0px 6px 10px 0px rgba(206, 209, 219, 0.35),inset 0px -1px 0px 0px rgba(224, 227, 232, 0.04)",
    // zIndex: theme.zIndex.drawer + 1,
    borderBottom: "1px solid #e0e3e8",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth})`,
    // zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,

      // zIndex: -1
    }),
    // [theme.breakpoints.down("xl")]: {
    //   width: "75.5%",
    // },
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    position: "relative",
  },
  drawerOpen: {
    width: drawerWidth,
    maxWidth: 350,
    zIndex: 1,
    // [theme.breakpoints.down("xl")]: {
    //   width: "24.5%",
    // },
    "& .logoFull": {
      display: "block",

      width: "calc(100% - 16.5%)",
      margin: 'auto',
    },
    "& .footerFull": {
      display: "block",
    },
    "& .orgFull": {
      display: "block",
    },
    "& .logoSmall": {
      display: "none",
    },
    "& .footerSm": {
      display: "none",
    },
    "& .orgSm": {
      display: "none",
    },
    "& .customerLogoText": {
      display: "block",
    },
    "& .customerSmallLogo": {
      justifyContent: "flex-start",
    },

    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "& .sidebarWrap": {
      // backgroundColor: 'red'
    },
    "& .orgDrawer": {
      display: "none",
    },
  },
  // test:{backgroundColor: 'red'},
  logoFull: {
    display: "block",

    width: "calc(100% - 16.5%)",
    margin: 'auto',
  },
  //css used for hiding the text of menu for sidebar client and vendors
  drawerSm: {},
  //till here
  sid: {
    backgroundColor: "green",
  },
  drawerClose: {
    overflowX: "hidden",
    width: 70,
    overflowY: "hidden",
    zIndex: 1,

    [theme.breakpoints.down('sm')]: {
      display: 'none !important'
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "& >.MuiBox-root": {
      marginTop: "calc(100% - 4px)",
    },
    "& .customerSidebar": {
      marginTop: "0px !important",
    },

    "& .MuiListItem-gutters": {
      display: "flex",
      justifyContent: "center",
      padding: "8px 0px",
      marginBottom: 4,
    },

    // '& .MuiListItem-root': {
    //   paddingLeft: 19
    // },
    "& #drawerResizer": {
      left: 61,
    },
    "& .userDropdown": {
      left: -288,
      paddingRight: 25,
    },
    // '& .orgDropdown': {
    //   visibility: 'hidden'
    // },
    "& .logoFull": {
      display: "none",
      width: "calc(100% - 16.5%) !important",
      margin: 'auto',
    },
    "& .footerFull": {
      display: "none",
    },
    "& .activeIconFull": {
      display: "none",
    },
    "& .orgFull": {
      display: "none",
    },
    "& .orgSmall": {
      display: "none",
    },
    "& .logoSmall": {
      display: "block",
      width: 46,
      height: 35,
      position: "absolute",
      left: "21%",
      top: 18,
    },
    "& .customerLogoText": {
      display: "none",
    },
    "& .customerSmallLogo": {
      justifyContent: "center",
    },
    "& .activeIconSmall": {
      display: "block",
      width: 40,
      position: "absolute",
      left: 10,
      top: 0,
    },
    "& .footerSm": {
      bottom: 0,
      display: "flex",
      padding: "15px 0px",
      position: "fixed",
      /* max-width: 349px, */
      /* box-shadow: 0px -1px 10px rgb(220 221 222 / 80%), */
      alignItems: "center",
      justifyContent: "center",
      boxShadow: "0px -1px 10px rgba(220, 221, 222, 0.8)",
      width: 70,
      backgroundColor: "#fff",
      "& .MuiButtonBase-root": {
        padding: "18px !important",
      },
    },
    "& .orgSm": {
      display: "none",
    },
    "& .MuiListItemText-root": {
      display: "none !important",
    },
    "& .orgTest": {
      // display: 'none',
      "& .orgHidden": {
        display: "none",
      },
      "& .orgIconHidden": {
        display: "none",
      },
      "& .btnWrapperDrawer": {
        width: "46px !important",
        padding: 0,
        "& .orgOpener": {
          padding: "6px !important",
        },
      },
    },

    // "& .MuiButton-contained, & form, & hr, & .scrollBar":{
    //   display: "none",
    // },
  },

  testSide: {},
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 3),
    ...theme.mixins.toolbar,
  },
  hide: {
    display: "none",
  },

  navigationResizer: {
    width: 28,
    height: 28,
    backgroundColor: "#fff",
    position: "absolute",
    // left: 258,
    left: 0,
    top: 130,
    opacity: 0,
    padding: 0,
    transition: "ease .4s",
    zIndex: theme.zIndex.drawer + 1,
    border: "1px solid #ddd",
    boxShadow:
      "rgba(9, 30, 66, 0.05) 0px 0px 0px 1px, rgba(9, 30, 66, 0.05) 0px 2px 4px 1px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      "& svg": {
        width: 24,
        height: 24,
        color: "#fff",
      },
    },
  },
  navigationResizerClosed: {
    left: -5,
    top: 126,
  },
  arrowRight: {
    color: theme.palette.primary.main,
  },
  arrowLeft: {
    color: theme.palette.primary.main,
  },
  container: {
    maxWidth: "1140px !important",
    padding: "40px 0",
    margin: "52px auto auto",
  },
}));
// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;