import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles(() =>
  createStyles({
    pageWraper:{
    },
    imageEmptyContent:{
      width: " 200px !important" ,
      display: 'block',
      marginLeft: 'auto',
     
      // paddingTop: theme.spacing(5)
    },
  
   
    content:{
      textAlign: 'left'
    },
    emptyContentBtn:{
      padding:'8px 15px',

    },
    uploadBtn: {
     
      marginLeft: 15,
      padding:'12px 25px',
      backgroundColor: '#FFAA00',
      color: '#fff',
      fontWeight:600,
      marginTop:32,
      '& svg': {
          fill: '#fff',
          width: 18,
          marginRight: 5
      }
  },
  }));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;