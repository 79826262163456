import React from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import useStyles from './SourceLanguageSelectStyles';
import { Autocomplete } from "@mui/material";
import TextField from '@mui/material/TextField';
import { MKeyboardArrowDownRoundedIcon } from "src/app/material-ui/icons";


export function SourceLanguageSelected(props: any) {

    const classes = useStyles({});



    return (

        <div className="sourceLangSelect">
            <FormattedMessage id="no.options">
                {noOptions =>
                    <Autocomplete
                        {...props}
                        multiple={props.multipleSelect ? props.multipleSelect : false}
                        id="sourceLanguage"
                        size="small"
                        limitTags={1}
                        className={clsx(props.className ? props.className : '', classes.sourceLangSelect, classes.inputWidth, props.redBorder && classes.redBorder)}
                        options={[props.sourceLanguage]}
                        popupIcon={<MKeyboardArrowDownRoundedIcon />}
                        getOptionLabel={(option: any) => option.code && option.code != undefined ? option.name + ' (' + option.code + ') ' : option.name}
                        noOptionsText={String(noOptions)}
                        onChange={(event: any, value: any) => {

                            if (value !== null) {

                                if (props.setFieldValue) {
                                    props.setFieldValue('sourceLanguage', value.code);

                                }

                                if (props.setSourceLanguageObj) {
                                    props.setSourceLanguageObj(value)
                                }
                            } else {
                                if (props.setFieldValue) {
                                    props.setFieldValue('sourceLanguage', '');
                                }

                                if (props.setSourceLanguageObj) {
                                    props.setSourceLanguageObj({})
                                }
                            }

                        }}

                        renderInput={params => <TextField {...params}
                            placeholder={props.label}
                            variant="outlined" color="secondary" />}
                    />
                }
            </FormattedMessage>
        </div>

    )
}