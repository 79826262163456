import React, { useEffect, useState } from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { showAlert } from 'src/app/store';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="standard" {...props} />;
}

function MessageAlert() {
    const [open, setOpen] = useState(false);
    const alertData = useSelector((store: any) => store.alert);
    const dispatch = useDispatch();

    const handleClose = () => {
        setOpen(false);
        dispatch(showAlert({
            message: '',
            messageType: '',
            showAlertMessage: false,
            graphqlError: false
        }))
    };

    useEffect(() => {
        setOpen(alertData.showAlertMessage);
    }, [alertData])

    return (
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
            <div>
                <Alert onClose={handleClose} severity={(alertData && alertData?.messageType) ? alertData?.messageType : "error"} sx={{ alignItems: 'center' }}>
                    {alertData && alertData.message && alertData.message.split('\n').map((line: any, index: number) => (
                        <div key={index}>{line}</div>
                    ))}
                </Alert>
            </div>
        </Snackbar>
    )
}

export default MessageAlert;