const timeAgo = (min: any)=>{ 
        const result = []
        const units: any = {
            "Year": 12 * 60 * 365,
            "Month": 12 * 60 * 30,
            "Week": 12 * 60 * 7,
            "Day": 12 * 60,
            "Hour": 60,
            "Min": 1, 
        }
        for (const name in units) { 
            if (parseInt(min) == 0) result.push(" "+ "just now");
            const p = Math.floor(min / units[name]); 
            if (p == 1) result.push(p + " " + name + " " + " ago");
            if (p >= 2) result.push(p + " " + name + "s " + " ago");
            min %= units[name]
        } 
        return result[0]; 
}
export default timeAgo;