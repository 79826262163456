import React from 'react';
import { PieChart, pieChartDefaultProps } from 'react-minimal-pie-chart';

export const DataPieChart = (props: any) => {
    return (
        <PieChart
            radius={pieChartDefaultProps.radius - props.pieRadius}
            {...props}
        />
    )
}