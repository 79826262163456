import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import useStyles from './MultiSelectStyles';
import { Autocomplete } from "@mui/material";
import TextField from '@mui/material/TextField';
import { MKeyboardArrowDownRoundedIcon } from 'src/app/material-ui/icons/keyboard-rounded-icon/KeyboardArrowDownRoundedIcon';
import { MBox, MChip } from 'src/app/material-ui';
import CloseIcon from '@mui/icons-material/Close';

interface IProps {
  label: string,
  setFieldValue: (name: any, val: any) => void,
  options: Array<any>,
  name: string,
  selectedObjectInstance: string,
  handleChange?: (event: any, value: any) => void,
  className?: any,
  value?: Array<any>,
  removeItems?: Array<any>
}

export default function MultiSelect({ setFieldValue, name, value: defaultValue, label, options: optionsProp, selectedObjectInstance, className, handleChange: handleChangeProp, removeItems }: IProps) {
  const classes = useStyles();
  const [chipWraperHeight, setChipWraperHeight]: any = useState()
  const [allOptions, setAllOptions]: any = useState()
  const [defaultOptions, setDefaultOptions]: any = useState([]);
  const [show, setShow] = useState(false)

  const checkChipsHeight = () => {
    if (defaultOptions.length < 1) {
      setChipWraperHeight(0)
    } else {
      const height = document.getElementById('chipsWraper' + name)?.clientHeight;
      setChipWraperHeight(height)
    }
  }

  const handleChange = (event: any, value: any) => {
    if (handleChangeProp) {
      handleChangeProp(event, value)
    }
    setFieldValue(name, value);
    checkChipsHeight();
  };

  useEffect(() => {
    checkChipsHeight()
  }, [checkChipsHeight, defaultOptions])

  useEffect(() => {
    setAllOptions(optionsProp)
    if ((removeItems && removeItems.length > 0) && optionsProp.length > 0) {
      let newOptions: any = optionsProp;
      removeItems.forEach((item: any) => {
        if (item) {
          const filterOption = item.id ? item.id : item;
          newOptions = newOptions.filter((element: any) => element.id !== filterOption);
        }
      });
      setAllOptions(newOptions);
    }
  }, [optionsProp, removeItems])

  useEffect(() => {
    if ((allOptions && allOptions.length > 0)) {
      const itemArr: any = [];
      if (defaultValue && (defaultValue.length > 0)) {
        defaultValue.forEach((item: any) => {
          const index = allOptions.findIndex((x: any) => x?.id === item?.id);
          itemArr.push(allOptions[index])
        });
      }
      setShow(true)
      setDefaultOptions(itemArr);
    }

  }, [defaultValue, allOptions, removeItems])

  const MAutoComplete = () => {
    return (
      <Autocomplete
        multiple
        limitTags={1}
        size="small"
        id={'autocomplete' + name}
        popupIcon={<MKeyboardArrowDownRoundedIcon />}
        className={clsx(classes.inputWidth, classes.langSelect)}
        defaultValue={defaultOptions}
        options={(allOptions && allOptions) || []}
        getOptionLabel={(option: any) => (selectedObjectInstance ? option[selectedObjectInstance] : option)}
        onChange={handleChange}
        renderInput={params => <TextField {...params} placeholder={label} variant="outlined" color="secondary" />}
        renderTags={(tagValue, getTagProps) =>
          <MBox id={"chipsWraper" + name} className={classes.chipsWraper} display="flex" alignItems="center" flexWrap="wrap" sx={{ bottom: ("-" + (10 + chipWraperHeight) + "px") }} >
            {tagValue && tagValue?.map((option, index) => (
              <MBox key="tagValue" mr={0.8}>
                <MChip
                  label={selectedObjectInstance && option ? option[selectedObjectInstance] : option ? option : selectedObjectInstance}
                  {...getTagProps({ index })}
                  deleteIcon={<CloseIcon className={classes.overviewChipIcon} />}
                  className={classes.overviewChipStyle}
                />
              </MBox>
            ))}
          </MBox>
        }
      />
    )
  }

  return (
    <div className={className} style={{ height: 40 + chipWraperHeight }}>
      {show &&
        <MAutoComplete />
      }
      {!show &&
        <MAutoComplete />
      }
    </div>
  )
}