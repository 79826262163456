/* eslint-disable*/
import { Theme } from "@mui/material/styles";

import createStyles from "@mui/styles/createStyles";
import { makeStyles } from "@mui/styles";

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    languageModal: {
      "& #dialog-title": {
        borderBottom: "1px solid #dbdbdb",
        "& h2": {
          color: "#3a3a3a",
          padding: "7px 0",
        },
      },
      "& .MuiDialog-paperWidthSm": {
        maxWidth: 540,
      },
    },
    matchRateTable: {
      "& thead": {
        backgroundColor: "#f7f9fa",
      },
      "& .MuiTableCell-root": {
        maxWidth: 125,
        minWidth: 125,
      },
    },
    matchRateDrawer: {
      "& .MuiPaper-root.MuiDrawer-paper": {
        minWidth: "85%",
        maxWidth: "85%",
      },
    },
    analysisDrawer: {
      "& .MuiPaper-root.MuiDrawer-paper": {
        minWidth: "60%",
        maxWidth: "60%",
      },
    },
    accordionWrap: {
      boxShadow: "none",
      filter: "drop-shadow(0 0 16px rgba(214,215,226,0.3))",
      border: "1px solid #f4f5f7",
      marginBottom: 10,
      transition: "all .3s",
      "&:not(.Mui-expanded):hover": {
        boxShadow: "none",
        filter: "initial",
        "& .MuiAccordionSummary-root": {
          backgroundColor: "#f7f8fc",
        },
      },
      "& .MuiButtonBase-root.MuiAccordionSummary-root": {
        height: 64,
        padding: 0,
        borderRadius: 5,
        backgroundColor: "#fff",
        margin: 0,
      },
      "& .MuiAccordionDetails-root": {
        backgroundColor: "#fff",
        paddingTop: 0,
      },
      "&::before": {
        display: "none",
      },
      "& .MuiAccordionSummary-root>.MuiButtonBase-root": {
        marginRight: -5,
        transform: "rotate(-90deg)",
        "&.Mui-expanded": {
          transform: "rotate(0deg)",
        },
      },
    },
    analysisHeaderBreakPoint: {
      [theme.breakpoints.down(1700)]: {
        width: "calc(100vw + 194px)",
      },
    },
    analysisHeaderList: {
      padding: 15,
      gap: 9,
      flexWrap: "nowrap",
      backgroundColor: "#F7F8FC",
      whiteSpace: "nowrap",
      "& > .MuiBox-root": {
        flex: "1 1 0",
        textOverflow: "ellipsis",
        textAlign: "center",

        "&:first-child": {
          [theme.breakpoints.down(1700)]: {
            flex: "2 1 0",
          },
          flex: "3 1 0",
          overflow: "hidden",
        },
        "&:nth-last-child(2)": {
          flex: "2 1 0",
        },
        color: "#A0A5B9",
        fontSize: 13,
        fontFamily: "Poppins",
        textTransform: "uppercase",
        span: {
          fontStyle: "italic",
          color: "#A0A5B9",
        },
      },
    },
    analysisWrapper: {
      backgroundColor: "#FFFFFF",
      flexWrap: "nowrap",
      padding: 15,
      width: "100%",
      borderBottom: "1px solid #F0EDED",
      [theme.breakpoints.down(1700)]: {
        width: "calc(100vw + 194px)",
      },
    },
    analysisList: {
      display: "flex",
      justifyContent: "spaceBetween",
      width: "100%",

      "& .MuiAvatar-root": {
        width: 18,
        height: 18,
        marginRight: 6,
      },

      gap: 15,
      "& > .MuiBox-root": {
        flex: "1 1 0",
        fontFamily: "Poppins",
        fontWeight: 500,
        fontSize: 13,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        textAlign: "center",
        "&:first-child": {
          [theme.breakpoints.down(1700)]: {
            flex: "2 1 0",
          },
          flex: "3 1 0",
          overflow: "hidden",
        },
        "&:nth-last-child(2)": {
          flex: "2 1 0",
        },

        span: {
          fontStyle: "italic",
          color: "#A0A5B9",
        },
      },
    },
    panelSummary: {
      flexDirection: "row-reverse",

      "& .MuiExpansionPanelSummary-expandIcon": {
        padding: "0",
        marginRight: theme.spacing(1),
        color: "#494949",
      },
      "& .MuiExpansionPanelSummary-content": {
        justifyContent: "space-between",
        alignItems: "center",
      },
    },

    panelBodyWrapper: {
      display: "block",
      "& .actionBtnBox": {
        position: "absolute",
        right: 10,
        top: 14,
        "& svg": {
          color: "#a0a5b9",
        },
      },
    },

    panelInner: {
      borderTop: "1px solid #dcddde",
      // borderBottom: "1px solid #dcddde"
    },

    fileUploadDrawer: {
      "& .MuiPaper-root.MuiDrawer-paper": {
        minWidth: "85%",
        maxWidth: "85%",

        //   "& .MuiToolbar-root":{
        //       display: 'flex',
        //       justifyContent: 'space-between'
        //   }
      },
      //   '& .MuiToolbar-root':{
      //         display:'flex',
      //         justifyContent: 'space-between'
      //   },
      "&.fullWidth": {
        "& .MuiPaper-root.MuiDrawer-paper": {
          transform: "translateX(-18%) !important",
        },
      },
    },
    vendorListView: {
      backgroundColor: "#fff",
      border: "1px solid #f4f5f7",
      height: 75,
      borderRadius: 5,
      filter: "drop-shadow(0 0 16px rgba(214,215,226,0.3))",
      transition: "all .3s",
      cursor: "pointer",
      marginBottom: 10,

      // '& :not(:last-child)':{
      // marginBottom: 10,
      // },

      "&:hover": {
        backgroundColor: "#f7f8fc",
        boxShadow: "none",
        filter: "initial",
      },
    },
    checkbox: {
      "& .MuiButtonBase-root": {
        padding: 0,
      },
      "& svg": {
        width: 16,
      },
    },

    drawerFotter: {
      display: "flex",
      justifyContent: "flex-end",
      position: "fixed",
      bottom: 0,
      left: "15%",
      width: "85%",
      padding: "10px 27px 10px 0px",
      backgroundColor: "#fff",
      borderTop: "1px solid #dbdbdb",
      [theme.breakpoints.only("md")]: {
        right: 0,
      },
    },
  })
);

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;
