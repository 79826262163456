import React from 'react'
import TextField from '@mui/material/TextField';
import { Field } from 'formik';

export function MTextField(props: any) {
    return (
        <Field
            {...props}
            placeholder={props.placeholder as string}

        >
            {({ field }: any) => (
                <TextField {...field} {...props}
                    placeholder={typeof props.placeholder === 'string' ? props.placeholder : ''}
                    value={field.value ?? ""}
                //  placeholder={props.placeholder as string}
                />
            )}
        </Field>

    )
}