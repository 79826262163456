import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';
// import {customColors} from '../utilities/colors/custmColors'

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
const useStyles = makeStyles((theme: Theme) =>
  // const colors = customColors;
  createStyles({
    fileUploadDrawer: {
      "& .MuiPaper-root.MuiDrawer-paper": {
        minWidth: "85%",
        maxWidth: "85%",
      },
      "&.FullWidth .MuiPaper-root.MuiDrawer-paper": {
        transform: 'translateX(-18%) !important',
      },
      '&.fullWidth': {
        "& .MuiPaper-root.MuiDrawer-paper": {
          transform: 'translateX(-18%) !important'
        }
      },
    },
    filesWrapper: {
      // minHeight: 'calc(100vh - 144px)',
    },
    contentBox: {
      width: '100%',
      backgroundColor: 'white',
      position: "relative",
      boxShadow: '0px 5px 5px 0px rgba(193, 199, 208, 0.26)',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      '& .contentBoxFull': {
        width: '100%',
        borderRadius: "5px",
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      '& .contentBoxLess': {
        width: 'calc(100% - 270px)',
        borderRadius: "0 5px 5px 0",
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
    folderDrawer: {
      width: 270,
      borderRadius: "5px 0 0 5px",
      backgroundColor: '#fafbfc',
      boxShadow: '0px 5px 5px 0px rgba(193, 199, 208, 0.26)',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerToggle: {
      width: 26,
      height: 26,
      backgroundColor: '#fff',
      position: 'absolute',
      left: 0,
      top: 30,
      padding: 0,
      transition: "ease .2s",
      boxShadow: '0px 3px 8px 0px rgba(88, 88, 88, 0.24)',
      '& svg': {
        width: 17,
        height: 17,
        transition: "ease .2s",
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.main,

        '& svg': {
          color: '#fff',
        }
      },
    },
    icon: {
      color: theme.palette.secondary.main,
    },
    folderDrawerOpen: {
      width: 270,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    folderDrawerClosed: {
      width: 0,
      marginRight: -49,
      overflow: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },

    fieldActionButtons: {
      '& button': {
        width: 32,
        height: 32,
        backgroundColor: 'rgb(245, 246, 247)',
        color: '#3a3a3a',
        padding: 0,
        boxShadow: '0px 3px 9px 0px rgba(160, 165, 185, 0.26)',
        border: '1px solid rgb(228, 229, 232)',
        borderRadius: 5,
      }
    },

    uploadedFile: {
      backgroundColor: "#fff",
      border: "1px solid #f4f5f7",
      boxShadow: "0px 0px 32px 0px rgba(214, 215, 226, 0.3)",
    },
    targetFiles: {
      position: "relative",
      "& .fileDirectionBg": {
        width: 17,
        height: 24,
        position: "absolute",
        top: "-13px",
        left: "-30px",
        background: "url('../assets/images/file-direction-bg.png') no-repeat",
      },
    },
    sourceText: {
      color: '#a0a5b9',
      '& span, & svg': {
        display: 'inline-block',
        verticalAlign: 'middle'
      },
      '& svg': {
        marginRight: 4,
      },
    },
    inputWrappper: {
      verticalAlign: 'middle',
    },
    input: {
      width: 'auto',
      transition: 'all .3s',
      border: '1px solid #d9d9d9',
      height: 33,
      marginTop: 9,
      marginRight: 10,
      borderRadius: 4,
      backgroundColor: '#ffff',
      '&:hover': {
        backgroundColor: '#ffff'
      },
      '& fieldset': {
        border: 'none',
        padding: 0
      },
      '& input': {
        padding: '2px 7px',
        fontSize: 20,
        fontFamily: 'Poppins',
        color: '#393939',
      }
    },
    editButtons: {
      right: 0,
      bottom: '-30px',
      zIndex: 9
    },
    nameEditIcon: {
      position: 'relative',
      top: '5px',
      left: '5px',
      width: '20px',
      color: 'gray',
      cursor: 'pointer',
    },
    fileUploadWrap: {
      textAlign: 'center',
      // width:540,
      '& img': {
        width: '100%',
        paddingTop: 0
      },
      '& .dzu-dropzone': {
        minHeight: 160,
        borderStyle: "dashed",
        borderWidth: 1,

        borderRadius: '6px',
        overflow: 'visible !important',
        '& .dzu-inputLabelWithFiles': {
          // margin: '0px !important',
          // padding: 0,
          minHeight: 160,
          width: '100%'
        }
      },
      '& .dzu-inputLabel': {
        color: '#393939',
        display: 'flex'
      },
    },
    DropZoneInput: {
      fontFamily: "Poppins",
      textAlign: 'left',
      color: '#3a3a3a',
      cursor: 'pointer',
      fontSize: '24px'
    },
    DropZoneDetailInput: {
      fontFamily: "Poppins",
      textAlign: 'left',
      color: '#6b778c',
      cursor: 'pointer',
      fontSize: '0.875rem'
    },
    status: {
      fontSize: 12,
      width: 90,
      height: 28,
      borderRadius: 14,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textTransform: "capitalize",
      '&.created': {
        backgroundColor: '#f9f5e7',
        color: '#e69900',
      },
      '&.preprocessing': {
        backgroundColor: '#f9f5e7',
        color: '#e69900',
      },
      '&.bidding': {
        backgroundColor: '#EBF3F2',
        color: '#00695C',
      },
      '&.completed': {
        backgroundColor: '#dff5eb',
        color: '#16c68e',
      },
      '&.assigned': {
        backgroundColor: '#e5f1f7',
        color: theme.palette.secondary.main
      },
      '&.pending': {
        backgroundColor: '#f9f5e7',
        color: '#e69900'
      },
      '&.cancelled': {
        backgroundColor: '#e5f1f7',
        color: theme.palette.secondary.main
      },
    },
    fileNameWrap: {
      '& .MuiChip-root': {
        backgroundColor: 'transparent !important',
        fontSize: 12,
        height: 20,
        marginLeft: 8,
        minWidth: 60,
        marginTop: -8
      }
    },
    fileProgressStyle: {
      position: 'relative',
      width: '100%',
      border: '1px solid rgb(244, 245, 247)',
      borderRadius: 5,
      backgroundColor: '#fff',
      boxShadow: '0px 0px 32px 0px rgba(214, 215, 226, 0.3)',
      padding: '14px 16px 0',
      height: 75,
      transition: 'all .3s',
      overflow: 'hidden',
      // '& .MuiSvgIcon-root': {
      //   marginRight: '5px !important'
      // },
      '& .fileName': {
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontSize: 14,
        color: '#3a3a3a',
        fontFamily: 'Poppins',
      },
      '& .fileData': {
        fontSize: 12,
        color: '#6b778c'
      },
      '& .progressStyle': {
        width: 180,
        height: 8,
        borderRadius: 5,
        backgroundColor: '#f0f0f0',
        marginTop: 4,
        '& .MuiLinearProgress-barColorSecondary.MuiLinearProgress-bar1Determinate': {
          backgroundColor: '#64dba1'
        },

      },
    },
    dialogWrapper: {
      '& .MuiDialog-paper': {
        width: '100%',
        height: 'calc(100% - 64px)',
        maxHeight: 800,
        maxWidth: 970
      },
      '& #dialog-title': {
        borderBottom: '1px solid #dbdbdb',
        '& h2': {
          fontSize: 15,
          color: '#3a3a3a',
          fontWeight: 'bold'
        }
      }
    },
    dialogContent: {
      // overflowY: 'hidden',
      padding: 0,
      "& .scrollBar": {
        minHeight: 365,
      },
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
    },
    panelSummary: {
      flexDirection: "row-reverse",
      padding: '0 15px',
      height: 48,
      minHeight: 'auto !important',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      "& .MuiExpansionPanelSummary-expandIcon": {
        padding: "0",
        marginRight: theme.spacing(1),
        color: "#494949",
        position: 'absolute',
        right: 0
      },
      "& .MuiExpansionPanelSummary-content": {
        justifyContent: "space-between",
        alignItems: "center"
      },
    },
    panelBodyWrapper: {
      position: 'relative',
      display: "block !important",
      padding: '16px 0 20px 0px !important',
      "& .actionBtnBox": {
        position: 'absolute',
        right: 10,
        top: 14,
        "& svg": {
          color: "#a0a5b9",
        },
      },
    },
    panelInner: {
      borderTop: "1px solid #dcddde",
      borderBottom: "1px solid #dcddde"
    },
    folderWraper: {

    },
    folderCollapse: {
      borderBottom: 'none',
      boxShadow: 'none',
      margin: '8px 0 0 !important',
      '&::before': {
        display: 'none'
      },
      '& .dirPathLine': {
        height: 0,
        width: 1,
        backgroundColor: 'lightgrey',
        position: 'absolute',
        top: 20,
        left: 12,
        display: 'none',
        '&.file': {
          display: 'inline-block',
          height: 1,
          left: -7,
          width: 11,
          top: 12,
        }
      },
      "& .MuiExpansionPanelSummary-expandIcon": {
        left: -10,
        right: 'initial',
        backgroundColor: '#a8a7bc',
        borderRadius: 2,
        color: '#fff',
        height: 13,
        width: 13,
        '& svg': {
          fontSize: 13
        }
      },
      '& >.Mui-expanded': {
        '& .dirPathLine': {
          display: 'inline-block'
        },
        "& .MuiExpansionPanelSummary-expandIcon": {
          '& svg': {
            display: 'none'
          },
          '& .MuiIconButton-label': {
            width: 7,
            height: 1,
            backgroundColor: '#fff'
          }
        },
      }
    },
    newFolderInput: {
      margin: '8px 0 6px 15px',
      '& input': {
        padding: '3px 10px',
        fontSize: 14
      }
    },
    newFolderActions: {
      margin: '6px 0 0 6px',
      '& button': {
        padding: 3
      }
    },
    addTagsWrap: {
      width: '100%',
      height: 'calc(100% - 34px)',
      '& .MuiFormLabel-root': {
        color: '#d2d2d2',
        fontSize: 14
      }
    },
    addButtonStyle: {
      minHeight: '40px',
      minWidth: '75px'
    },
    addIconStyle: {
      marginRight: '2px',
      fontSize: "0.8rem"
    },
    overviewChipStyle: {
      backgroundColor: "#eceff1",
      height: '32px',
      borderRadius: '16px',
      fontSize: '12px',
      lineHeight: '28px',
      color: '#464555',
      fontFamily: "Poppins Emoji",
      '& .MuiChip-label': {
        paddingRight: 0,
        // height:'100%'
      }
    },
    overviewChipIcon: {
      marginLeft: 8,
      height: '16px',
    },
    autoCompleteSm: {
      width: 'auto !important',
      '& .MuiFormLabel-root': {
        '&.Mui-focused': {
          display: 'none',
        },
        fontSize: 14,
        marginTop: '-2px',
        transform: 'translate(14px, 4px) scale(1)',
      },
      '& .MuiAutocomplete-inputRoot': {
        padding: '0 65px 0 0 !important',
        minHeight: 32,
        marginTop: -6,
      },
      '& .MuiAutocomplete-input': {
        fontSize: 14
      },
      '& fieldset': {
        top: 0,
        borderColor: 'transparent !important',
        '& legend': {
          display: 'none'
        },
      },
      '& .MuiInputBase-root': {
        background: '0px center rgba(9, 30, 66, 0.04)',
        '&.Mui-focused': {
          background: '#fff'
        }
      }
    },
    seachInput: {
      borderRadius: 5,
      '& fieldset': {
        borderColor: 'transparent !important',
      },
      '& .MuiFormLabel-root': {
        transform: 'translate(15px, 8px) scale(1)',
        fontSize: 14
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(15px, -7px) scale(0.75)'
      },
      '& .MuiOutlinedInput-inputMarginDense': {
        padding: '4.5px 0 8.5px 15px'
      },
      '& .MuiSvgIcon-root': {
        color: '#585858',
        width: 18,
        height: 18,
        marginRight: -3
      },
      '& .MuiInputBase-root': {
        background: '0px center rgba(9, 30, 66, 0.04)',
        '&.Mui-focused': {
          background: '#fff'
        }
      }
    },
    folderListWrap: {
      '& .MuiButtonBase-root': {
        width: '100%',
        padding: '2px 8px',
        borderRadius: 3,
        justifyContent: 'unset',
      },
      '&  .MuiButtonBase-root .MuiTouchRipple-root span': {
        backgroundColor: '#c7c7c7',
        // opacity: 1,

      },
    },

    '& .MuiButtonBase-root.active': {
      backgroundColor: '#f3f5f6',
    },
    // icon:{
    //   color: theme.palette.secondary.main,
    // },
    buttonBaseRipple: {

    },
    createTagButton: {
      display: 'block',
      textAlign: 'left'
    },
    fileProgressBar: {
      backgroundColor: 'transparent',
      width: '95%',
      height: 2,
      position: 'absolute',
      left: 0,
      right: 0,
      margin: 'auto',
      bottom: 8,
      zIndex: 100
    },
    fileProgressOverlay: {
      backgroundColor: 'rgba(0,0,0,0.04)',
      display: 'inline-block',
      height: '100%',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      transition: 'transform .4s linear'
    },
    playPauseButton: {
      backgroundColor: '#e9eff5',
      color: '#a9bcce',
      padding: 5,
      '& svg': {
        width: 13,
        height: 13,
      }
    },
    fileDeleteButton: {
      backgroundColor: '#f8e3ea',
      color: '#fc1e43',
      padding: 5,
      '& svg': {
        width: 16,
        height: 16,
      }
    },
    tagsWrap: {
      border: '1px solid #ececec',
      borderRadius: 7,
      padding: '15px 20px',
    },
    clearBtn: {
      position: 'absolute',
      top: 6,
      right: 0,
    },
    fileWrapScroll: {
      width: '100%',
      /* height: 274px, */
      maxHeight: 300,
      overflow: 'auto',
    },
    selectFolderLabel: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      color: "#3F74E8",
    },
    infoText: {
      backgroundColor: '#E5F1F6',
      color: "#498BA7",
      padding: '15px',
      fontSize: 13,
      borderRadius: 4,
      maxWidth: '50%',
      marginBottom: '35px'
    },
  }),
);

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;