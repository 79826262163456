import React from 'react';
import { MBox, MButton } from 'src/app/material-ui';
import useStyles from './EmptyContentStyle';
import { FormattedMessage } from "react-intl";


interface IProps {
	isContent?: boolean,
	title?: any,
	text?: any,
	buttonProps?: any,
	sx?: any,
	allowCreateProject?: any
}

function EmptyContent({ title, text, buttonProps, isContent, allowCreateProject, sx }: IProps) {
	const classes = useStyles();
	const description = text ? text : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ullamcorper scelerisque accumsan. Suspendisse non purus venenatis, accumsan leo in, porttitor ante. Praesent aliquam ante ut bibendum tincidunt. Curabitur eleifend, lorem non Curabitur eleifend, lorem non Curabitur eleifend, lorem non';

	return (
		<MBox maxWidth={1000} sx={sx} m="auto" width="100%" diplay="flex" justifyContent="center" alignItems="center" className={classes.pageWraper}>
			<MBox pb={isContent ? 5 : 0} display="flex" justifyContent="space-between" alignItems="center">
				<MBox textAlign="center">
					{isContent &&
						<MBox fontSize={15} mt={1} className={classes.content}>
							<MBox fontSize={18} color="#393939" fontFamily="Poppins">
								{title
									?
									title
									:
									<FormattedMessage id="no.record.found" />
								}
							</MBox>
							<MBox fontSize={15} color="#7c8088" lineHeight="22px" mt={1} width={490}>
								{description.split('').map((val: string, index: number) => ((index < 240) && ((index === 239) ? `${val}... ` : val)))}
							</MBox>
							{buttonProps && allowCreateProject &&
								<MBox mt={3.5}>
									<MButton
										{...buttonProps}
										variant="contained"
										color="primary"
										className={classes.emptyContentBtn}
									>
										{buttonProps.text}
									</MButton>
								</MBox>
							}
						</MBox>
					}
				</MBox>
				<MBox width="100%" textAlign="center">

					{!isContent &&
						<>

							<img
								className={classes.imageEmptyContent}
								style={{ marginRight: isContent ? 'initial' : 'auto' }}
								width={isContent ? '390px' : '240px'} src="/assets/images/empty-data-image-new.png"
								alt="Empty Content"
							/>
							<MBox color="lightgrey" fontSize={17} fontFamily="Poppins" mt={1.5}>
								{text ? text : <FormattedMessage id="no.record.found" />}
							</MBox>
							{buttonProps && allowCreateProject &&
								<MBox mt={3.5}>
									<MButton
										{...buttonProps}
										variant="contained"
										color="primary"
										className={classes.emptyContentBtn}
									>
										{buttonProps.text}
									</MButton>
								</MBox>
							}
							{/* <MButton
						 className={classes.uploadBtn}
						 variant="contained"
						 size="small"
						 startIcon={<AddIcon/>}
						 // onClick={() => setOpenFileModal(true)}
					 >
						 Add Files
					 </MButton> */}
						</>
					}

				</MBox>
			</MBox>
		</MBox>
	)
}

export default EmptyContent;