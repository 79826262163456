import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    // languageDialog:{
    //   '& .MuiDialog-paperFullWidth':{
    //     maxWidth: 1024,
    //   },
    // },

    statsCard: {
      boxShadow: 'none',
      height: '100px',
      borderRadius: '5px',
      backgroundColor: '#ffffff',
      border: '1px solid #f2f2f4',
    },
    statsCardIcon: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '20px',
      boxShadow: '0px 2px 1px #d6d7e2',
      '& svg': {
        color: 'red'
      }
    },
    dialogHeader: {

    },
    contentWrapper: {
      padding: 0,
      overflowX: 'hidden',
    },

    section: {
      borderBottom: "1px solid #f4f5f7",
    },
    lightText: {
      fontSize: '12px',
      lineHeight: '21px',
      color: '#6b778c',
      fontFamily: "Poppins",
    },
    leverageItemsBox: {
      // width: 'calc(100% + 16px)',
      // margin: '-8px',
    },
    item: {
      // padding: theme.spacing(3),
    },

    offlineFileList: {
      background: '#fff',
      border: '1px solid #f1f2f5',
      boxShadow: '0px 0px 32px 0px rgba(214, 215, 226, 0.3)',
      '& .MuiButtonBase-root': {
        color: '#a0a5b9',
      }
    },
    fileTitle: {
      color: '#3a3a3a',
      textTransform: 'capitalize',
      fontWeight: 'bold',
      fontFamily: "Poppins",
      fontSize: '14px',
    },
    sourceText: {
      color: '#a0a5b9',
      textAlign: 'left',
      '& div, & svg': {
        display: 'inline-block',
        verticalAlign: 'middle'
      },
      '& svg': {
        marginRight: 4,
      },
    },
    fileSize: {
      color: '#a0a5b9',
      fontFamily: "Poppins",
      fontSize: '12px',
    },
    DropZoneInput: {
      fontFamily: "Poppins",
      textAlign: 'left',
      color: '#393939',
      cursor: 'pointer',
      fontSize: '24px',
      fontWeight: 'bold',
    },
    DropZoneDetailInput: {
      fontFamily: "Poppins",
      textAlign: 'left',
      color: '#6b778c',
      cursor: 'pointer',
      fontSize: '14px'
    },
    fileUploadWrap: {
      textAlign: 'center',
      width: '100%',
      '& img': {
        width: '100%'
      },
      '& .dzu-dropzone': {
        minHeight: 230,
        borderStyle: "dashed",
        borderWidth: 1,
        borderColor: "#dcddde",
        borderRadius: '5px'
      },
      '& .dzu-inputLabel': {
        color: '#393939',
      },
    },

    cardHeight: {
      height: '72px',
    },
    cardTextStyle: {
      position: 'absolute',
      bottom: '31px',
    },
    fileProgressStyle: {
      width: '100%',
      border: '1px solid rgb(244, 245, 247)',
      borderRadius: 5,
      backgroundColor: '#fff',
      boxShadow: '0px 0px 32px 0px rgba(214, 215, 226, 0.3)',
      padding: '14px 16px 0',
      marginBottom: 10,
      height: 75,

      '& .fileName': {
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontSize: 14,
        color: '#3a3a3a',
        fontFamily: 'Poppins'
      },
      '& .fileData': {
        fontSize: 12,
        color: '#6b778c'
      },
      '& .progressStyle': {
        width: 180,
        height: 8,
        borderRadius: 5,
        backgroundColor: '#f0f0f0',
        marginTop: 4,
        '& .MuiLinearProgress-barColorSecondary.MuiLinearProgress-bar1Determinate': {
          backgroundColor: '#64dba1'
        }
      },
    },
    DeleteFileButton: {
      color: "rgb(220, 0, 78)",
    },
    fieldActionButtons: {
      // textAlign: 'right',
      '& button': {
        width: 32,
        height: 32,
        backgroundColor: 'rgb(245, 246, 247)',
        color: '#3a3a3a',
        padding: 0,
        boxShadow: '0px 3px 9px 0px rgba(160, 165, 185, 0.26)',
        border: '1px solid rgb(228, 229, 232)',
        borderRadius: 5,
      }
    },
    inputWrappper: {
      verticalAlign: 'middle',
    },
    input: {
      width: 'auto',
      transition: 'all .3s',
      border: '1px solid #d9d9d9',
      height: 33,
      marginTop: 9,
      marginRight: 10,
      borderRadius: 4,
      backgroundColor: '#ffff',
      '&:hover': {
        backgroundColor: '#ffff'
      },
      '& fieldset': {
        border: 'none',
        padding: 0
      },
      '& input': {
        padding: '2px 7px',
        fontSize: 20,
        fontFamily: 'Poppins',
        color: '#393939',
      }
    },
    editButtons: {
      // position: 'absolute',
      right: 0,
      bottom: '-30px',
      zIndex: 9
    },
    linkStyle: {
      fontSize: '14px',
      lineHeight: '28px',
      color: theme.palette.secondary.main,
      fontFamily: "Poppins Emoji",
    },
    closeSection: {
      borderBottom: "1px solid #dbdbdb",
    },
    fileUploader: {
      width: '100px'
    }
  }));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;