import React, { Fragment } from 'react'

export function MSendPO() {
    return (
        <Fragment>

            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.875 9.3H17.6875L12.875 4.35V9.3ZM6.75 3H13.75L19 8.4V19.2C19 19.6774 18.8156 20.1352 18.4874 20.4728C18.1592 20.8104 17.7141 21 17.25 21H6.75C5.77875 21 5 20.19 5 19.2V4.8C5 3.801 5.77875 3 6.75 3ZM14.625 17.4V15.6H6.75V17.4H14.625ZM17.25 13.8V12H6.75V13.8H17.25Z" fill="#A7ADBB" />
            </svg>

        </Fragment>
    )
}

