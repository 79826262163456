import { Theme } from '@mui/material/styles';

import { alpha } from '@mui/material/styles';


import { makeStyles } from "@mui/styles";
function pxToRem(value: any) {
  return `${value / 16}rem`;
}
const useStyles = makeStyles((theme: Theme) => ({
  sidebarWrap: {

    dotBagde: {
      position: 'absolute !important',
      top: 10,
      right: 7,
      "& .MuiBadge-badge": {
        width: 10,
        height: 10,
        top: 10,
        right: 7,
        borderRadius: 25,
        color: "#ff0102",
        backgroundColor: theme.palette.primary.main,
        border: "2px solid #fff",
      },
    },
    roleWrap: {
      borderTop: '1px solid #d8d9db',
      borderBottom: '1px solid #d8d9db',
    },
    selectedRole: {
      textDecoration: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    test: {},
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.5),
      border: '2px solid #cfd3db',
      width: '100%',
      marginTop: 2,
      minHeight: 35,
      '& input': {
        fontSize: 12
      },
      '&:hover': {
        borderColor: theme.palette.secondary.main,
      },
    },
    // "&.vendor":{
    //   paddingTop: 80,
    // }
  },
  // testTwo:{
  //   '& .generalLinks':{

  //   }
  // },
  dotBagde: {
    position: 'absolute !important' as any,
    top: 10,
    right: 7,
    "& .MuiBadge-badge": {
      width: 10,
      height: 10,
      top: 6,
      right: 7,
      borderRadius: 25,
      color: "#ff0102",
      backgroundColor: theme.palette.primary.main,
      border: "2px solid #fff",
    },
  },
  roleWrap: {
    borderTop: "1px solid #d8d9db",
    borderBottom: "1px solid #d8d9db",
  },
  selectedRole: {
    textDecoration: "none",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
  test: {},
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    border: "2px solid #cfd3db",
    width: "100%",
    marginTop: 2,
    minHeight: 35,
    "& input": {
      fontSize: 12,
    },
    "&:hover": {
      borderColor: theme.palette.secondary.main,
    },
  },
  searchIcon: {
    position: "absolute",
    right: 5,
    top: 5,
    pointerEvents: "none",
    "& svg": {
      fontSize: "20px",
      color: "#6f7b8f",
    },
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: "8px 32px 9px 8px",
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
    paddingRight: 0,
    paddingLeft: 0,
    marginBottom: 0,
    "& .logoSmall": {
      top: "-35px !important",
    },
  },
  listItem: {
    // maxWidth:350,
    // minWidth:350,
    padding: theme.spacing(1, 1),
    marginLeft: -8,
    "&:hover .MuiAvatar-root": {
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
    },
    "&:hover .MuiListItemText-root span": {
      color: theme.palette.secondary.main,
    },
  },
  listItemText: {
    margin: 0,
    width: 110,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    "& span": {
      fontSize: 14,
      textTransform: "capitalize",
    },
  },
  viewAllLink: {
    color: theme.palette.secondary.main,
    lineHeight: 2,
    textDecoration: "none",
  },
  avatarWrap: {
    "& .MuiAvatar-root": {
      transition: "ease .3s",
      fontSize: 14,
      textTransform: "uppercase",
      backgroundColor: "#e6edfd",
      color: "#2c2c2c",
    },
  },
  footer: {
    textAlign: "center",
    padding: theme.spacing(2, 3),
    backgroundColor: "#fafafa",
  },
  createProjectBtn: {
    minWidth: 196,
  },

  // client sidebar
  projectAvatar: {
    width: "100%",
    height: "100%",
    // alignItems: "start",
    // backgroundColor: "transparent",
    "& .MuiAvatar-img": {
      height: "auto",
    },
  },
  workFlowBox: {
    cursor: "pointer",
    "& .workFlowRow": {
      position: "relative",
    },

    "& .directionImgFirstCase": {
      width: 7,
      height: 8,
      position: "absolute",
      top: 4,
      left: "-20px",
      background:
        "url('/assets/images/work-flow-direction-single-case.png') no-repeat",
    },

    "& .directionImgMultipleCase": {
      width: 7,
      height: 30,
      position: "absolute",
      bottom: 4,
      left: "-20px",
      background:
        "url('/assets/images/work-flow-direction-multiple-case.png') no-repeat",
    },
  },
  projectSidebarFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#fff",
    width: "16.5%",
    maxWidth: 349,
    padding: 15,
    position: "fixed",
    boxShadow: "0px -1px 10px rgb(220 221 222 / 80%)",
    bottom: 0,
    // "@media (max-width: 400px) and (min-width:320px )": {
    //   width: 250,
    // },
    // "@media (max-width: 440px) and (min-width:401px )": {
    //   width: 280,
    // },
    // "@media (max-width: 480px) and (min-width:441px )": {
    //   width: 284,
    // },
    // "@media  (min-width:480px ) and (max-width:959px )": {
    //   width: 300,
    // },
    // "@media (max-width:280px)": {
    //   width: "73.35%",
    // },
    // "@media (min-width:281px) and (max-width:319px)": {
    //   width: "77.35%",
    // },
    // "@media (min-width:960px) and (max-width:1280px)": {
    //   width: "16.35%",
    // },
    // "@media (min-width:1281px) and (max-width:1920px)": {
    //   width: "16.45%",
    //   maxWidth: 350,
    // },
    "& .footerAvatar": {
      width: 36,
      height: 36,
      "& .MuiBox-root": {
        fontWeight: "bold !important",
      },
    },
    // Sidebar footer Three Dots hide on small screen
    "& >.MuiBox-root:nth-child(2n)": {
      [theme.breakpoints.down("xl")]: {
        // display: 'none',
      },
    },
  },
  userDropdown: {
    minWidth: 195,
    "& ul": {
      minWidth: 195,
      "& .MuiListItem-button": {
        borderRadius: 4,
        padding: "3px !important",
        paddingLeft: "20px !important",
        // margin: theme.spacing(0, 1),
      },
    },
  },
  menuLink: {
    color: theme.palette.text.primary,
    textDecoration: "none",
  },
  sidebarLink: {
    color: theme.palette.text.primary,
    textDecoration: "none",
  },
  logoutLink: {
    color: "#f44336",
    "&:hover": {
      color: theme.palette.error.main,
      backgroundColor: "#fddedf",
    },
  },

  sideBarLinks: {
    // '& .MuiListItem-gutters': {
    //   paddingLeft: "35px !important",
    // },
    "& .MuiButtonBase-root": {
      marginBottom: 4,
      [theme.breakpoints.between("md", "xl")]: {
        paddingLeft: 0,
        whiteSpace: "break-spaces",
      },
      [theme.breakpoints.down("xl")]: {
        paddingLeft: 0,
      },
      [theme.breakpoints.down("xl")]: {
        paddingTop: 4,
        paddingBottom: 4,
      },

      "& .MuiListItemIcon-root": {
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down("xl")]: {
          paddingLeft: 0,
        },

        // minWidth: 38,
        "& .MuiSvgIcon-root": {
          fill: "#6B778C",
          height: 24,
          width: 24,
          // [theme.breakpoints.down('lg')]: {
          //   height: 15,
          //   width: 15
          // },
        },
      },
      "& .MuiListItemText-root": {
        "& .MuiTypography-root": {
          color: "#6B778C",
          fontSize: 16,
          fontFamily: "Poppins",
          // [theme.breakpoints.down('lg')]: {
          //   fontSize: pxToRem(14)
          // },

          [theme.breakpoints.down("xl")]: {
            whiteSpace: "break-spaces",
            fontSize: pxToRem(14),
          },
          [theme.breakpoints.down("xl")]: {
            whiteSpace: "break-spaces",
            // fontSize: pxToRem(12)
          },
        },
      },
    },
    "& a.active": {
      textDecoration: "none",
      backgroundColor: "#F6F6F6",
      position: "relative",
      "& .MuiListItemIcon-root": {
        "& .MuiSvgIcon-root": {
          fill: " #FF5100",
        },
      },

      "& .MuiListItemText-root": {
        "& .MuiTypography-root": {
          color: "#FF5100",
          fontWeight: 600,
          fontFamily: "Poppins",
          "&:before": {
            // content: '"arrow_forward_ios"',
            color: "#C4C9D1",
            fontFamily: "Material Icons",
            width: 12,
            height: 7,
            position: "absolute",
            right: 0,
            padding: "0px 45px",
            display: "inline-block",
            fontWeight: 100,
          },
        },
      },
      "& svg": {
        "& path": {
          fill: "#FF5100 !important",
        },
      },
    },
    "& .active": {
      backgroundColor: "#F6F6F6",
      "& svg": {
        fill: "#FF5100 !important",
        height: 24,
      },
      "& .MuiListItemText-root": {
        "& .MuiTypography-root": {
          color: "#FF5100 !important",
          fontWeight: 600,
        },
      },
    },

    "& .active::before": {
      content: "''",
      width: 4,
      height: "100%",
      position: "absolute",
      left: 0,
      backgroundColor: "#FF5100",
      display: "inline-block",
    },
  },

  generalLinks: {
    padding: "0px",
    "& .MuiBox-root": {
      margin: "15px 0px 18px 27px",
      color: "#6B778C !important",
      fontSize: 16,
      fontWeight: 600,
      textTransform: "uppercase",
      [theme.breakpoints.down("xl")]: {
        whiteSpace: "break-spaces",
        margin: "1px 0px 3px 27px",
        fontSize: pxToRem(14),
      },
    },
  },
  orgName: {},
  drawerSm: {
    backgroundColor: "red",
    "& .btnWrapper": {
      backgroundColor: "green",
    },
  },
  logoBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 163,
    "& .logoFull": {
      // [theme.breakpoints.down('lg')]: {
      //   height: '75px !important',
      //   width: '95px !important'
      // },
      [theme.breakpoints.down("xl")]: {
        display: "none",
      },
      ...theme.mixins.toolbar,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),

      "& a": {
        // height: 21,
      },
    },
  },

  footerDetails: {
    "& .MuiBox-root:nth-child(2n)": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whitespace: "nowrap",
    },
  },
  hiddenMenu: {
    // [theme.breakpoints.down('sm')]: {
    //   display: 'none'
    // }
  },

  sidebarAvatar: {
    [theme.breakpoints.down("xl")]: {
      // fontSize: pxToRem(14)
    },
    [theme.breakpoints.down("xl")]: {
      // fontSize: pxToRem(12)
    },
    fontSize: "14px",

    fontFamily: "sans-serif",
    color: "#3a3a3a",
    fontWeight: 700,
    textTransform: "capitalize",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whitespace: "nowrap",
  },

  footerSm: {
    "& .footerSm": {
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
      [theme.breakpoints.down("xl")]: {
        display: "none",
      },
    },
  },
}));


// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;