import { lazy } from 'react';



// global screens
export const Dashboard = lazy(() => import('../screens/client-screens/dashboard/Dashboard'));
export const ProjectSearch = lazy(() => import('../components/partials/sidebar/project-search/ProjectSearch'));
export const Subscription = lazy(() => import('../screens/global/subscription/Subscription'));
export const Login = lazy(() => import('../screens/global/login/Login'));
export const Signup = lazy(() => import('../screens/global/signup/Signup'));
export const CustomerSupport = lazy(() => import('../components/contact-support/ContactSupport'));

export const ConfirmPassword = lazy(() => import('../screens/global/confirmPassword/ConfirmPassword'));
export const ForgotPassword = lazy(() => import('../screens/global/forgot-password/ForgotPassword'));
export const UserRole = lazy(() => import('../screens/global/user-role/UserRole'));
export const Team = lazy(() => import('../screens/global/team/Team'));
export const TMXGroup = lazy(() => import('../screens/global/tmx-group/TmxGroup'));
export const OrganizationList = lazy(() => import('../screens/global/organization-list/Organizations'));
export const MyAccount = lazy(() => import('../screens/global/my-account/MyAccount'));
export const Page404 = lazy(() => import('../screens/global/page-404/Page404'));
export const Utilities = lazy(() => import('../screens/global/utilities/Utilities'));


// admin routes
export const PreProcessingFiles = lazy(() => import('../screens/admin-role/pre-processing-files/PreProcessingFiles'));


// client screeens

export const SettingTab = lazy(() => import('../screens/client-screens/setting/SettingTab'));
export const Setting = lazy(() => import('../screens/client-screens/setting/Setting'));
export const StyleGuidesNew = lazy(() => import('../screens/client-screens/setting/StyleGuidesNew'));
export const CreateProject = lazy(() => import('../screens/client-screens/create-project/CreateProject'));
export const CreateOrganization = lazy(() => import('../screens/client-screens/create-organization/CreateOrganization'));
export const Projects = lazy(() => import('../screens/client-screens/projects/Projects'));
export const ActivityLog = lazy(() => import('../screens/client-screens/ActivityLog/ActivityLog'));
export const ManageAdmins = lazy(() => import('../screens/client-screens/manage-admins/ManageAdmins'));
export const ManageAdminsInvite = lazy(() => import('../screens/client-screens/Manage-admins-invite/ManageAdmins'));
export const ManageGroup = lazy(() => import('../screens/client-screens/manage-group/ManageGroup'));
export const EditWorkFlow = lazy(() => import('../screens/client-screens/work-flow/EditWorkFlow'));
export const WorkFlowListing = lazy(() => import('../screens/client-screens/work-flow/WorkFlowListing'));
export const CreateJob = lazy(() => import('../screens/client-screens/create-job/CreateJob'));
export const Jobs = lazy(() => import('../screens/client-screens/jobs/Jobs'));
export const BidsForJob = lazy(() => import('../screens/client-screens/bids-for-job/BidsForJob'));
export const Files = lazy(() => import('../screens/client-screens/files/Files'));
export const OrganizationFiles = lazy(() => import('../screens/client-screens/files/OrganizationFiles'));
export const ReferenceFiles = lazy(() => import('../screens/client-screens/reference-files/ReferenceFiles'));
export const OrganizationReferenceFiles = lazy(() => import('../screens/client-screens/reference-files/OrganizationReferenceFiles'));
export const Languages = lazy(() => import('../screens/client-screens/languages/Languages'));
export const TranslationMemory = lazy(() => import('../screens/client-screens/general/General'));
export const OrganizationTranslationMemory = lazy(() => import('../screens/client-screens/general/organizationTm'));
export const ProjectTags = lazy(() => import('../screens/client-screens/project-tags/ProjectTags'));
export const RateRequest = lazy(() => import('../screens/client-screens/rate-request/RateRequest'));
export const StyleGuide = lazy(() => import('../screens/client-screens/style-guide/StyleGuide'));
export const Glossary = lazy(() => import('../screens/client-screens/glossary/Glossary'));
export const OrganizationGlossary = lazy(() => import('../screens/client-screens/glossary/OrganizationGlossary'));
export const TransactionRequest = lazy(() => import('../screens/client-screens/transactions/TransactionRequest'));
export const ManageWithDrawlHistory = lazy(() => import('../screens/client-screens/transactions/WithDrawlHistory'));
export const Invoices = lazy(() => import('../screens/client-screens/transactions/invoices/Invoices'));
export const Transations = lazy(() => import('../screens/client-screens/transactions/Transaction'));
export const Payments = lazy(() => import('../screens/client-screens/payments/Payments'));
export const PaymentsTabs = lazy(() => import('../screens/client-screens/payments/PaymentsTab'));
export const PaymentsTabNew = lazy(() => import('../screens/client-screens/payments/PaymentsTabNew'));
export const AccountsTabs = lazy(() => import('../screens/client-screens/payments/AccountSettingsTab'));

export const Subscriptions = lazy(() => import('../screens/client-screens/subscriptions/Subscriptions'));
export const BillingHistory = lazy(() => import('../screens/client-screens/subscriptions/BillingHistory'));
export const SubscriptionPlan = lazy(() => import('../screens/client-screens/subscriptions/SubscriptionPlan'));
export const ProjectDashboard = lazy(() => import('../screens/client-screens/project-dashboard/ProjectDashboardNew'));
export const CustomerPurchaseOrder = lazy(() => import('../components/customer/CustomersInvoiceAndPo'));
export const CustomerInvoice = lazy(() => import('../components/customer/CustomersInvoiceAndPo'));
export const CustomerDrawer = lazy(() => import('../components/customer/InvoiceAndPurchaseDialog'));
export const payment = lazy(() => import('../components/payments/Stripe'));
export const credits = lazy(() => import('../screens/global/buy-credits/BuyCredits'));
export const ClientDashboard = lazy(() => import('../screens/global/dashboard/Dashboard'));
export const ImportEmpty = lazy(() => import('../screens/global/import-empty/ImportEmpty'));

// export const ProjectDashboardNew = lazy(() => import('../screens/client-screens/project-dashboard/ProjectDashboard'));
export const MyVendors = lazy(() => import('../screens/client-screens/my-vendors/MyVendors'));
export const CustomerOpenProjects = lazy(() => import('../screens/customer/open-projects/OpenProjects'));
export const FindVendor = lazy(() => import('../screens/client-screens/my-vendors/FindVendor'));
export const FindLinguist = lazy(() => import('../components/Linguists/find-new-linguist/FindNewLinguist'));
export const FindNewVendors = lazy(() => import('../screens/client-screens/find-new-vendor/FindNewVendors'));
export const JobLanguages = lazy(() => import('../screens/vendor/job-language/JobLanguages'));
export const JobFiles = lazy(() => import('../screens/vendor/job-files/JobFiles'));
export const ProjectSetting = lazy(() => import('../screens/client-screens/project-settings/Setting'));
export const ProjectTasks = lazy(() => import('../screens/client-screens/project-tasks/ProjectTasks'));
export const Notifications = lazy(() => import('../screens/global/notifications/Notifications'));
export const ReleaseNotes = lazy(() => import('../screens/global/releaseNotes/ReleaseNotes'));
export const AccountSettings = lazy(() => import('../screens/client-screens/account-settings/AccountSettings'));
export const MarsQa = lazy(() => import('../screens/vendor/mars-qa/Marsqa'));
export const Github = lazy(() => import('../screens/vendor/github-integration/GithubIntegration'));

// vendor screens
export const Rates = lazy(() => import('../screens/vendor/rates/Rates'));
export const VendorRateRequest = lazy(() => import('../screens/vendor/rates/rate-request/FetchRateRequest'));
// export const RatesGeneralTab = lazy(() => import('../screens/vendor/rates/GeneralRates'));
export const RatesClientTab = lazy(() => import('../screens/vendor/rates/ClientRates'));
export const SendQuote = lazy(() => import('../screens/vendor/send-quote/SendQuote'));
export const AddRates = lazy(() => import('../screens/vendor/rates/add-rates/AddRates'));
export const OpenProjects = lazy(() => import('../screens/vendor/open-projects/OpenProjects'));
export const AssignedProjects = lazy(() => import('../screens/vendor/assigned-projects/AssignedProjects'));
export const SelectCustomersManager = lazy(() => import('../screens/customer/select-customers-manager/SelectCustomersManager'));

export const SingleProjectDetail = lazy(() => import('../screens/vendor/open-projects/OpenSingleProjectDetail'));
export const CustomerProjects = lazy(() => import('../screens/customer/open-projects/OpenProjects'));
export const DnsRecordUrl = lazy(() => import('../screens/vendor/setting/tabs-data/profile/DnsRecordUrl'));
export const AllCustomers = lazy(() => import('../screens/customer/AllCustomers'));
export const CustomersDetail = lazy(() => import('../screens/customer/ViewCustomers'));
export const CustomersDrive = lazy(() => import('../screens/customer/CustomerDrive'));
export const AssignToLinguists = lazy(() => import('../screens/vendor/assign-to-linguist/AssignToLinguist'));
export const Invoice = lazy(() => import('../screens/vendor/invoice/Invoice'));
export const SendInvoice = lazy(() => import('../screens/vendor/invoice/SendInvoice/SendInvoice'));
export const ViewInvoice = lazy(() => import('../screens/vendor/invoice/SendInvoice/ViewInvoice'));
export const SendQuoteToVendor = lazy(() => import('../screens/vendor/send-quote-to-vendor/SendQuoteToVendor'));
export const VendorSetting = lazy(() => import('../screens/vendor/setting/Setting'));
export const VendorServices = lazy(() => import('../screens/vendor/services/Services'));
export const EditVendorServices = lazy(() => import('../screens/vendor/services/edit-services/EditServices'));
export const ProposalDetail = lazy(() => import('../screens/vendor/proposal-detail/ProposalDetail'));
export const MatchRates = lazy(() => import('../screens/vendor/match-rates/MatchRates'));
export const MyLinguist = lazy(() => import('../screens/vendor/my-linguists/MyLinguists'));
export const GeneralLinguist = lazy(() => import('../components/Linguists'));
export const Linguists = lazy(() => import('../screens/vendor/linguists/Linguists'));
export const JobDetail = lazy(() => import('../screens/vendor/job-detail/JobDetail'));
// export const VendorProfile = lazy(() => import('../screens/vendor/vendor-profile/VendorProfile'));
export const VendorProfile = lazy(() => import('../screens/vendor/vendor-profile-new/GeneralProfile'));
export const WithdrawAccounts = lazy(() => import('../screens/vendor/setting/tabs-data/withdraw-account/WithdrawAccounts'));
export const ManageCreditCards = lazy(() => import('../screens/vendor/setting/tabs-data/my-account/ManageCreditCards'));
export const AdvanceSetting = lazy(() => import('../screens/vendor/setting/tabs-data/my-account/AdvanceSetting'));
export const AccountsSettings = lazy(() => import('../screens/vendor/setting/tabs-data/my-account/AccountsSettings'));
export const VendorDashboard = lazy(() => import('../screens/global/dashboard/Dashboard'));
// export const MyProfile = lazy(() => import('../screens/vendor/vendor-profile-new/MyProfile'));


// linguist screens
export const LinguistsProfile = lazy(() => import('../screens/linguist/linguists-profile/linguistsProfile'));
export const Profile = lazy(() => import('../screens/linguist/profile/profile'));
export const LinguistsRates = lazy(() => import('../screens/linguist/rates/Rates'));
export const LinguistsRatesGeneralTab = lazy(() => import('../screens/linguist/ratesNew/GeneralRates'));
export const LinguistsRatesClientTab = lazy(() => import('../screens/linguist/ratesNew/AllRates'));
export const LinguistsRatesVendorTab = lazy(() => import('../screens/linguist/ratesNew/VendorRates'));
export const LinguistsAddRates = lazy(() => import('../screens/linguist/ratesNew/add-rates/AddRates'));
export const LinguistServices = lazy(() => import('../screens/linguist/services/Services'));
export const EditLinguistServices = lazy(() => import('../screens/linguist/services/edit-services/EditServices'));
export const LinguistTranslation = lazy(() => import('../screens/linguist/linguist-translation/LinguistTranslation'));
export const LinguistProjects = lazy(() => import('../screens/linguist/assigned-projects/AssignedProjects'));

export const PaymentsPreLoader = lazy(() => import('../components/redirect/Redirect'));