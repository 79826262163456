/* eslint-disable */
import React, { useEffect, useState } from 'react';
import useStyles from './EditRateStyles';
import TextField from '@mui/material/TextField';

import {
    MBox,
    MButton,
    MDrawer,
    MForm,
    MFormik,
    MFormLabel,
    MGrid,
    MIconButton,
    MCircularProgress,
    MTextField
} from 'src/app/material-ui';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FormErrorMessage, SourceLanguageSelect, TargetLanguageSelect } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { showAlert } from "../../../../store";
import { Autocomplete } from "@mui/material";
import * as Yup from "yup";
import { FormattedMessage, useIntl } from "react-intl";

interface IProps {
    isDrawer: boolean,
    editRateId: any,
    setIsDrawer: (value: any) => void,
    getVendorsRateDetails: Function,
}

// const USER_EMAILS_GQL = gql`
//     query userEmails($email: String, $moduleName: String) {
//         userEmails(email: $email,moduleName: $moduleName) {
//             id
//             username
//         }
//     }
// `;

const VENDOR_RATE_ID = gql`
    query getVendorRates($rateId: String, $role:String, $orgId: Int) {
        getVendorRates(rateId:$rateId, role:$role, orgId: $orgId) {
            id
            price
            subject
            packageName
            packageId
            clients {
                id
                username
            }
            sourceLanguage {
                id
                name
                code
            }
            targetLanguages {
                id
                name
                code
            }
        }
    }
`;
const ADD_VENDORS_RATE = gql`
    mutation setVendorRates($rateId: Int, $packageId: Int!,$slId: Int!,$price: String,$subject: String,$tlIds: String,$clientIds: String,$role: String){
        setVendorRates(rateId: $rateId, packageId:$packageId,slId:$slId,price:$price,subject:$subject,tlIds:$tlIds,clientIds:$clientIds,role:$role)
    }
`;

const GET_PROJECT_PACKAGE_DETAILS = gql`
    query getAllPackages($role:String, $orgId: Int){
        getAllPackages(role:$role, orgId: $orgId){
        packages{
            id
            name
            }
        }
    }
    `;
const GET_ACTIVE_INDUSTRIES = gql`
{
    getIndustries {
        id
        name
        
    }
 } 
`;

export default function RateEditModel({ isDrawer, setIsDrawer, editRateId, getVendorsRateDetails }: IProps) {
    const dispatch = useDispatch();

    const classes = useStyles({});
    // const [DataUserEmails, setDataUserEmails]: any = useState([]);
    const [targetLanguages, setTargetLanguages]: any = useState([]);
    const [defaultTargetLanguages, setDefaultTargetLanguages]: any = useState([]);
    const [packageName, setPackageName]: any = useState([]);
    const [activeIndustries, setActiveIndustries]: any = useState([]);
    const [packageId, setpackageId]: any = useState([]);
    const [rate, setRate]: any = useState([]);
    const [email, setEmail]: any = useState([]);
    // const [tab, setTab]: any = useState('');
    const [slId, setSlId] = useState();
    const [vendorIdRateData, setVendorIdRateData]: any = useState([]);
    const appUserRole = useSelector((store: any) => store.userRole.userRole);
    const isOwner = useSelector((store: any) => store.orgs.isOwner);
    const isAdmin = useSelector((store: any) => store.orgs.isAdmin);
    const selectedOrgId: any = useSelector((store: any) => store.orgs.selectedOrgId);
    const VendorRateId = editRateId;
    // const [emailsQueryData] = useLazyQuery(USER_EMAILS_GQL, {
    //     fetchPolicy: 'no-cache',
    //     onCompleted: (data: any) => {
    //         if (data && data.userEmails && data.userEmails.length > 0) {
    //             // setDataUserEmails(data && data.userEmails);
    //         }
    //     }
    // });

    useQuery(GET_ACTIVE_INDUSTRIES, {
        fetchPolicy: 'no-cache',
        onCompleted: (data: any) => {
            setActiveIndustries(data.getIndustries)
        },
    });
    useQuery(GET_PROJECT_PACKAGE_DETAILS, {
        variables: { ...(appUserRole === "ROLE_TRANSLATOR" ? ({ role: 'linguist' }) : (!isOwner && isAdmin && ({ orgId: +selectedOrgId }))) },
        fetchPolicy: 'no-cache',
        onCompleted: (data: any) => {
            setPackageName(data.getAllPackages.packages)
        },
    });
    const [vendorRateData] = useLazyQuery(VENDOR_RATE_ID, {
        fetchPolicy: 'no-cache',
        variables: { rateId: VendorRateId.toString(), ...(appUserRole === "ROLE_TRANSLATOR" ? ({ role: 'linguist' }) : (!isOwner && isAdmin && ({ orgId: +selectedOrgId }))) },
        onCompleted: (data: any) => {
            if (data.getVendorRates && data.getVendorRates[0]) {
                setpackageId(data.getVendorRates[0].packageId);
                setTargetLanguages(data.getVendorRates[0].targetLanguages);
                setDefaultTargetLanguages(data.getVendorRates[0].targetLanguages);
                setRate(data.getVendorRates[0]);
                setSlId(data.getVendorRates[0].sourceLanguage.id);
                setVendorIdRateData(data.getVendorRates[0] && data.getVendorRates[0]);
            }
        }
    });
    const [addVendorsRate, { loading: addLoading }] = useMutation(ADD_VENDORS_RATE, {
        onCompleted: () => {
            vendorRateData();
            getVendorsRateDetails(appUserRole === "ROLE_TRANSLATOR" ? ({ variables: { moduleName: "client", page: 1 } }) : (!isOwner && isAdmin && { variables: { orgId: +selectedOrgId } }));
            dispatch(showAlert({
                message: "Rate Added Successfully",
                messageType: 'success',
                showAlertMessage: true
            }));
            setIsDrawer(false);

        }
    });

    useEffect(() => {
        if (rate && rate.clients) {
            setEmail(rate && rate.clients)
        }
    }, [vendorIdRateData])
    useEffect(() => {
        vendorRateData();
    }, [vendorRateData]);
    const handleTargetLanguageChange = (values: string[]) => {
        setTargetLanguages(values);
    };

    const intl = useIntl();
    return (
        <MDrawer
            open={isDrawer}
            variant="temporary"
            anchor='right'
            className={classes.updateModal}
        >
            <AppBar className='drawerAppBar'>
                <Toolbar>
                    <MBox display="inline-block" mr={1}>
                        <MIconButton onClick={() => setIsDrawer(false)}>
                            <ArrowBackIcon />
                        </MIconButton>
                    </MBox>
                    <MBox width="700px" fontSize={18} fontFamily="Poppins">
                        <FormattedMessage id="update.rate" />
                    </MBox>
                </Toolbar>

            </AppBar>
            <MBox pb={5}>
                <MBox className={classes.modalForm}>
                    <MFormik
                        validateOnChange
                        enableReinitialize="true"
                        initialValues={{
                            targetLanguages: targetLanguages && targetLanguages,
                            sourceLanguage: rate && rate.sourceLanguage,
                            services: rate && rate.packageName,
                            ratePerWord: rate && rate.price,
                            subject: rate && rate.subject,
                            clientEmail: email && email.length > 0 ? email[0].username : '',
                        }}
                        validationSchema={Yup.object({
                            targetLanguages: Yup.string().required('Target Languages is a required field'),
                            services: Yup.string().required('Services is a required field'),
                            ratePerWord: Yup.number().required(intl.formatMessage({ id: "rate.price.required.field" })).moreThan(0, intl.formatMessage({ id: "greater.than.zero" })).max(1, intl.formatMessage({ id: "cannot.greater.one" })),
                            subject: Yup.string().required('Subject is a required field'),
                            sourceLanguage: Yup.string().required('Source Language is a required field'),
                        })}
                        onSubmit={(values: any) => {
                            // if (email.length > 0) {
                            //     setTab('client')
                            // }
                            const emailIdArr: any = [];
                            email.forEach((i: any) => {
                                emailIdArr.push(i.id)
                            });
                            const data: any = {
                                packageId: +packageId,
                                slId: slId,
                                price: String(values.ratePerWord),
                                tlIds: targetLanguages.map((value: any) => value.code).join(','),
                                subject: values.subject,
                                clientIds: values.clientEmail,
                                ...(appUserRole === "ROLE_TRANSLATOR" && { role: 'linguist' })
                            };
                            // if(VendorRateId){
                            data.rateId = Number(VendorRateId);
                            // }
                            addVendorsRate({
                                variables: data
                            });
                        }}>

                        {({ touched, errors, setFieldValue, values }: any) => (
                            <MForm>

                                <MBox mb={3}>
                                    <MBox mb={1}>
                                        <MBox mb={1}>
                                            <MFormLabel className={classes.formLabel} component="legend">
                                                {appUserRole === 'ROLE_TRANSLATOR'
                                                    ?
                                                    <>
                                                        <FormattedMessage id="client" />/<FormattedMessage id="vendor" />
                                                    </>
                                                    :
                                                    <FormattedMessage id="client" />
                                                }
                                            </MFormLabel>
                                        </MBox>
                                        <MTextField
                                            name="clientEmail"
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            className={classes.inputWidth}
                                            disabled={true}
                                            placeholder="Email"
                                        />

                                        <FormErrorMessage message={touched.client ? errors.client : ''} />
                                    </MBox>
                                    <MGrid container direction="row" justifyContent="flex-start" alignItems="center">

                                    </MGrid>
                                </MBox>
                                <MBox mb={4} mt={3} position="relative">
                                    <MBox mb={1}>
                                        <MFormLabel className={classes.formLabel} required component="legend">
                                            <FormattedMessage id="services" />
                                        </MFormLabel>
                                    </MBox>
                                    <Autocomplete
                                        autoComplete={false}
                                        limitTags={1}
                                        id="services"
                                        size="small"
                                        className={classes.inputWidth}
                                        options={packageName}
                                        // defaultValue={{ name: values.services ? values.services : "" }}
                                        value={{ name: values.services ? values.services : "" }}
                                        getOptionLabel={(option: any) => option.name}
                                        onChange={(event: any, value: any) => {
                                            if (value && value) {
                                                setpackageId(value.id);
                                                setFieldValue('services', value.id)
                                            } else {
                                                setpackageId([]);
                                                setFieldValue('services', '')
                                            }

                                        }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                color="secondary"
                                                placeholder={intl.formatMessage({ id: "service.name" })}
                                                autoComplete='off'

                                            />
                                        )}

                                    />
                                    <FormErrorMessage message={touched.services ? errors.services : ''} />
                                </MBox>

                                <MBox mb={3}>
                                    <MBox mb={1}>
                                        <MFormLabel className={classes.formLabel} component="legend" required>
                                            <FormattedMessage id="source.language" />
                                        </MFormLabel>
                                    </MBox>
                                    {values && values.sourceLanguage && <SourceLanguageSelect
                                        label={intl.formatMessage({ id: "select.source.language" })}
                                        setFieldValue={setFieldValue}
                                        className={classes.inputWidth}
                                        targetLanguages={targetLanguages}
                                        defaultValue={values && values.sourceLanguage}
                                        sourceLangObj={values && values.sourceLanguage}
                                        setSlId={setSlId}
                                        returnId="object"
                                    />}

                                </MBox>
                                <MBox mb={1}>
                                    <MFormLabel className={classes.formLabel} component="legend" required>
                                        <FormattedMessage id="target.languages" />
                                    </MFormLabel>
                                </MBox>
                                {/*{defaultTargetLanguages && JSON.stringify(defaultTargetLanguages)}*/}
                                <MBox mb={3} position="relative" sx={{ width: '100%!important' }}>
                                    {defaultTargetLanguages && defaultTargetLanguages.length > 0 && <TargetLanguageSelect
                                        // returnInstance={'object'}
                                        filterLanguages={[values.sourceLanguage]}
                                        returnInstance="object"
                                        // size="propsInputWidth"
                                        defaultLanguage={targetLanguages}
                                        isUseValue={1}
                                        // targetlanguages={targetLanguages}
                                        setTargetLanguages={handleTargetLanguageChange}
                                    />}
                                </MBox>
                                <MBox mb={4} position="relative">
                                    <MBox mb={1}>
                                        <MFormLabel className={classes.formLabel} component="legend" required>
                                            <FormattedMessage id="subject" />
                                        </MFormLabel>
                                    </MBox>
                                    <Autocomplete
                                        autoComplete={false}
                                        limitTags={1}
                                        id="subject"
                                        size="small"
                                        className={classes.inputWidth}
                                        options={activeIndustries}
                                        defaultValue={{ name: values.subject }}
                                        value={{ name: values.subject ? values.subject : "" }}
                                        getOptionLabel={(option: any) => option.name}
                                        onChange={(event: any, value: any) => {
                                            if (value && value) {
                                                setFieldValue('subject', value.name)
                                            }
                                        }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                color="secondary"
                                                placeholder='Subject Name'
                                                autoComplete='off'
                                            />
                                        )}
                                    />
                                    <FormErrorMessage message={touched.subject ? errors.subject : ''} />
                                </MBox>
                                <MBox mb={4}>
                                    <MBox mb={1}>
                                        <MFormLabel className={classes.formLabel} component="legend" required>
                                            <FormattedMessage id="rate.per.word" />
                                        </MFormLabel>
                                    </MBox>
                                    <MTextField
                                        name="ratePerWord"
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        type="number"
                                        className={classes.inputWidth}
                                        disabled={false}
                                        placeholder="$0.02"
                                        onKeyDown={(evt: any) => {
                                            const value = evt.target.value;
                                            const decimalPart = value.toString().split(".")[1];
                                            if (evt.key === 'Backspace') return;
                                            // Prevent typing when the decimal part length is already four
                                            if (decimalPart && decimalPart.length >= 4) {
                                                evt.preventDefault();
                                            }
                                        }}
                                    />
                                </MBox>

                                <MBox display="flex" mt={3} mb={12}>
                                    <MBox className={classes.footerBottom}>

                                        <MButton
                                            variant="outlined"
                                            className={classes.cancelBtn}
                                            onClick={() => setIsDrawer(false)}
                                        >
                                            <FormattedMessage id="cancel" />
                                        </MButton>
                                        <MButton
                                            color="primary"
                                            variant="contained"
                                            disabled={addLoading}
                                            className={classes.updateBtn}
                                            type="submit"
                                        > {addLoading && <MCircularProgress color="secondary" size={24} />}
                                            <FormattedMessage id="update" />
                                        </MButton>

                                    </MBox>
                                </MBox>

                            </MForm>
                        )}
                    </MFormik>

                </MBox>
            </MBox>
        </MDrawer >
    );
}
