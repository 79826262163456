import React, { useState, MouseEvent, useEffect } from 'react'
import useStyles from './SidebarStyles'
import {
    MBox,
    MList,
    MListItem,
    MAvatar,
    MListItemText,
    MListItemIcon,
    MMenu,
    MGrid,
    MIconButton,
    MSkeleton,
    MTooltip
} from 'src/app/material-ui';
import { MDashboardOutlinedIcon, MKeyboardArrowRightSharpIcon, MPersonOutlineIcon, MMonetizationOnIcon } from 'src/app/material-ui/icons';
import { NavLink, withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import { showAlert } from 'src/app/store';
import Zoom from '@mui/material/Zoom';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import MemoryOutlinedIcon from '@mui/icons-material/MemoryOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import { FormattedMessage, useIntl } from "react-intl";
import { selectedProjectName } from 'src/app/store';
import { setSidebarType, setUserRole } from "../../../store";
import { useRoleRedirect } from "../../../hooks";

// import Tooltip from '@mui/material/Tooltip'; 

const PROJECT_DETAIL = gql`
    query ProjectDetail($orgId:ID!,$projectId:ID!) {
        projectDetail(orgId:$orgId, projectId:$projectId) {
            name
            services {
                id
                name
            }
        }
    }
`;

const ALL_PROJECT_SERVICE = gql`
    query projectDetail($orgId:ID!, $projectId:ID!) {
        projectDetail(orgId:$orgId, projectId:$projectId) {
             services {
                    id
                    name
                    priority
                }
        }
    }`;

function ProjectSidebar(props: any) {

    const classes = useStyles({});
    const dispatch = useDispatch();
    const selectedOrgId = useSelector((store: any) => store.orgs.selectedOrgId);
    const projectId = useSelector((store: any) => store.projects.selectedProjectId);
    // const [showFullWorkflow, setShowFullWorkflow] = useState(false);
    // const currentRole = useSelector((store: any) => store.userRole.userRole);
    const refreshSideBarProjects = useSelector((store: any) => store.projects.refreshSideBarProjects);
    const isGraphqlError = useSelector((store: any) => store.alert.showAlertMessage);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    // const organizations = useSelector((store: any) => store.orgs.organizations);
    const currentUser = useSelector((store: any) => store.auth.currentUser);
    const isAdmin = useSelector((store: any) => store.orgs.isAdmin);
    const isOwner = useSelector((store: any) => store.orgs.isOwner);
    const [setProjectService]: any = useState()
    const orgs = useSelector((store: any) => store.orgs.organizations);
    const [organizations, setOrganization]: any = useState();
    const appUserRole = useSelector((store: any) => store.userRole.userRole);

    const { handelRedirect } = useRoleRedirect(props);
    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const switchRole = (role: string) => {
        setAnchorEl(null);
        if (role !== '') {
            dispatch(setUserRole(role));
            dispatch(setSidebarType(role));
            // localStorage.setItem('user_role',role);
            handelRedirect(role)
        }
    };
    const [getProjectDetail, { data: detail, loading: projectDetailLoading }] = useLazyQuery(PROJECT_DETAIL, {
        fetchPolicy: 'no-cache',
        onCompleted: () => {
            dispatch(selectedProjectName(detail.projectDetail.name))
        }
    });
    const [getAllProjectServices] = useLazyQuery(ALL_PROJECT_SERVICE, {
        fetchPolicy: 'no-cache',
        onCompleted: (data: any) => {

            setProjectService(data.projectDetail.services);


        },
    });

    const logout = () => {

        const token = localStorage.getItem('access_token');
        localStorage.clear();

        window.location.href = "" + process.env.REACT_APP_SSO_LOGOUT_URL + token;

        dispatch(showAlert({ message: 'You are signing out.', messageType: 'error', showAlertMessage: true }))

        // localStorage.clear()
        // dispatch(resetState());
        // props.history.push('/')
    };

    useEffect(() => {
        if (selectedOrgId && (projectId !== 0)) {
            getAllProjectServices({
                variables: { orgId: selectedOrgId, projectId: projectId },
            })
        }
    }, [getAllProjectServices, refreshSideBarProjects, selectedOrgId, projectId]);

    useEffect(() => {
        if (orgs && (orgs.length > 0)) {
            setOrganization(orgs)
        }
    }, [orgs])
    useEffect(() => {
        if (selectedOrgId && (projectId !== 0)) {
            getProjectDetail({
                variables: { orgId: selectedOrgId, projectId: projectId },
            });
        }
    }, [selectedOrgId, projectId, getProjectDetail])

    const intl = useIntl();

    return (
        <Zoom in={props.drawerState} unmountOnExit>
            <MBox className={classes.sidebarWrap}>
                <MBox px={1.2} pb={1.5} key={1}>
                    <MBox px={3} mb={2}>
                        {(appUserRole === "ROLE_CLIENT")
                            ?
                            <MGrid item className={classes.logoBox}>
                                <NavLink to={(organizations && organizations.length > 0) ? "/projects" : "/create-organization"}>
                                    <img className="logoFull" src="/assets/images/marshub-logo.svg" alt="logo" width="140px" />
                                    <img className="logoSmall" src="/assets/images/logo-sm.svg" alt="logo" width="140px" />
                                </NavLink>
                            </MGrid>
                            :
                            <MGrid item className={classes.logoBox}>
                                <NavLink to={((appUserRole === "ROLE_TRANSLATOR") ? "/linguist/profile" : "/vendor/open-jobs")}>
                                    <img className="logoFull" src="/assets/images/marshub-logo.svg" alt="logo" width="140px" />
                                    <img className="logoSmall" src="/assets/images/logo-sm.svg" alt="logo" width="140px" />
                                </NavLink>
                            </MGrid>
                        }

                    </MBox>
                    <MBox display="flex" mb={0.5} ml={1.25}>
                        <MBox ml="14px">
                            {((!selectedOrgId || projectDetailLoading || !detail) && !isGraphqlError) && <MSkeleton width={100} height={20} />}
                        </MBox>
                    </MBox>


                    <MBox className="generalLinks">
                        <MList className={classes.sideBarLinks}>
                            <MListItem button component={NavLink} to={`/projects/file/` + projectId}>
                                <MListItemIcon sx={{ color: '#6b778c' }}>
                                    <MDashboardOutlinedIcon />
                                </MListItemIcon>
                                <MListItemText primary={intl.formatMessage({ id: "project" })} />
                                <MKeyboardArrowRightSharpIcon />

                            </MListItem>
                            <MListItem button component={NavLink} to={`/manage-admins`}>
                                <MListItemIcon sx={{ color: '#6b778c' }}>
                                    <DescriptionOutlinedIcon />
                                </MListItemIcon>
                                <MListItemText primary={intl.formatMessage({ id: "menu.people" })} />
                            </MListItem>
                            <MListItem button component={NavLink} to={`/vendors`}>
                                <MListItemIcon sx={{ color: '#6b778c' }}>
                                    <MPersonOutlineIcon />
                                </MListItemIcon>
                                <MListItemText primary={intl.formatMessage({ id: "vendor" })} />
                            </MListItem>
                            <MListItem button component={NavLink} to={`/rate-request`}>
                                <MListItemIcon sx={{ color: '#6b778c' }}>
                                    <MMonetizationOnIcon />
                                </MListItemIcon>
                                <MListItemText primary={intl.formatMessage({ id: "rate.request" })} />
                            </MListItem>
                            {/* <MListItem button component={NavLink} to={`/client/project-dashboard/`+projectId}>
                                <MListItemIcon sx={{color:'#6b778c'}}><TimelineOutlinedIcon/></MListItemIcon>
                                <MListItemText primary="Activities"/>
                            </MListItem> */}
                        </MList>
                    </MBox>

                </MBox>

                <MBox key={2}>
                    <MBox key={101} className={classes.generalLinks} px={1.2} pb={12}>
                        <MBox>
                            <FormattedMessage id="org.resources" />
                        </MBox>
                        <MList className={classes.sideBarLinks}>
                            <MListItem button component={NavLink} to={`/projects/files/` + projectId}>
                                <MListItemIcon sx={{ color: '#6b778c' }}>
                                    <DescriptionOutlinedIcon />
                                </MListItemIcon>
                                <MListItemText primary={<FormattedMessage id="files" />} />
                            </MListItem>
                            <MListItem button component={NavLink} to={`/projects/tm/` + projectId}>
                                <MListItemIcon sx={{ color: '#6b778c' }}>
                                    <MemoryOutlinedIcon />
                                </MListItemIcon>
                                <MListItemText primary={<FormattedMessage id="translation.memory" />} />
                            </MListItem>
                            <MListItem button component={NavLink} to={`/projects/glossary/` + projectId}>
                                <MListItemIcon sx={{ color: '#6b778c' }}>
                                    <MenuBookOutlinedIcon />
                                </MListItemIcon>
                                <MListItemText primary={<FormattedMessage id="glossary" />} />
                            </MListItem>
                            <MListItem button component={NavLink} to={`/projects/style-guides/` + projectId}>
                                <MListItemIcon sx={{ color: '#6b778c' }}>
                                    <FeedbackOutlinedIcon />
                                </MListItemIcon>
                                <MListItemText primary={<FormattedMessage id="style.guides" />} />
                            </MListItem>
                        </MList>
                    </MBox>
                    <MBox key={102} className={classes.projectSidebarFooter + ' userDropdown'}>
                        <MBox display="flex" alignItems='center'>
                            <MAvatar className={'footerAvatar'} src={currentUser && currentUser.profilePicture ? currentUser.profilePicture : ""} />
                            <MBox ml={1.5}>
                                {
                                    currentUser &&
                                        (parseInt(currentUser?.firstName?.length + currentUser?.lastName?.length) > 10) ?
                                        <MTooltip title={currentUser.firstName + ' ' + currentUser.lastName}>
                                            <MBox sx={{ fontSize: '16', fontFamily: "Poppins", color: "#3a3a3a", fontWeight: 'bold', textTransform: 'capitalize' }}>

                                                {currentUser.firstName + " " + currentUser.lastName.slice(0, 9) + "..."}
                                            </MBox>
                                        </MTooltip>
                                        :
                                        <MBox fontSize={16} fontFamily="Poppins" color="#3a3a3a" sx={{ textTransform: 'capitalize' }}>
                                            {currentUser.firstName + ' ' + currentUser.lastName}
                                        </MBox>


                                }

                                <MBox fontSize={14} color="#A2A4B9">
                                    {appUserRole === "ROLE_VENDOR" && 'Vendor'}
                                    {appUserRole === "ROLE_CLIENT" && 'Client'}
                                    {appUserRole === "ROLE_TRANSLATOR" && 'Linguist'}
                                </MBox>
                            </MBox>
                        </MBox>
                        <MBox mr={-2}>
                            <MIconButton aria-controls="notificationMenu" aria-haspopup="true" color="inherit"
                                onClick={handleClick}>
                                <MoreVertIcon sx={{ fontSize: 22, color: '#8d8d8d' }} />
                            </MIconButton>
                        </MBox>

                        <MMenu
                            id="notificationMenu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                            className={classes.userDropdown}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                        >
                            <NavLink className={classes.menuLink}
                                to={((appUserRole === "ROLE_VENDOR"
                                    ?
                                    '/vendor/open-jobs'
                                    :
                                    (appUserRole === "ROLE_TRANSLATOR")
                                        ?
                                        '#'
                                        :
                                        '/projects'
                                ))
                                }>
                                <MenuItem onClick={handleClose}>
                                    <FormattedMessage id="dashboard" />
                                </MenuItem>
                            </NavLink>
                            <MenuItem onClick={handleClose}>
                                <NavLink className={classes.menuLink} to="/my-account">
                                    <FormattedMessage id="my.account" />
                                </NavLink>
                            </MenuItem>
                            {(isAdmin || isOwner) && (appUserRole === "ROLE_CLIENT") &&
                                <NavLink className={classes.menuLink} to="/transactions">
                                    <MenuItem onClick={handleClose} className={classes.menuLink}>
                                        <FormattedMessage id="transactions" />
                                    </MenuItem>
                                </NavLink>
                            }
                            <NavLink className={classes.menuLink}
                                to={((appUserRole === "ROLE_VENDOR"
                                    ?
                                    '/vendor/settings'
                                    :
                                    (appUserRole === "ROLE_TRANSLATOR")
                                        ?
                                        '/linguist/profile'
                                        :
                                        '/settings'))}
                            >
                                <MenuItem onClick={handleClose} className={classes.menuLink}>
                                    <FormattedMessage id="settings" />
                                </MenuItem>
                            </NavLink>
                            {(isOwner || isOwner === "")
                                ?
                                <MBox mt={1} mb={.75} pb={1} className={classes.roleWrap}>
                                    <MBox mt={1.5} ml={1.25} mb={.5} fontSize={12} color="#6b778c">
                                        <FormattedMessage id="switch.role" />
                                    </MBox>
                                    <MenuItem className={(appUserRole === "ROLE_CLIENT") ? classes.selectedRole : ''} onClick={() => switchRole('ROLE_CLIENT')}>
                                        <FormattedMessage id="client" />
                                    </MenuItem>
                                    <MenuItem className={appUserRole === "ROLE_VENDOR" ? classes.selectedRole : ''} onClick={() => switchRole('ROLE_VENDOR')}>
                                        <FormattedMessage id="vendor" />
                                    </MenuItem>
                                    <MenuItem className={appUserRole === "ROLE_TRANSLATOR" ? classes.selectedRole : ''} onClick={() => switchRole('ROLE_TRANSLATOR')}>
                                        <FormattedMessage id="linguist.side" />
                                    </MenuItem>

                                </MBox>
                                :
                                <MBox sx={{ marginTop: 10, marginBottom: 5, borderTop: "1px solid #d8d9db" }}></MBox>
                            }
                            <MenuItem onClick={logout} className={`${classes.logoutLink}`}>
                                <span className={`${classes.menuLink} ${classes.logoutLink}`}>
                                    <FormattedMessage id="logout" />
                                </span>
                            </MenuItem>

                        </MMenu>

                    </MBox>
                </MBox>

            </MBox>
        </Zoom>
    )
}

export default withRouter(ProjectSidebar);