import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';
// import {customColors} from '../utilities/colors/custmColors'

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
const useStyles = makeStyles(() =>
// const colors = customColors;
  createStyles({
    filesWrapper:{
      // minHeight: 'calc(100vh - 144px)',
    },
    dialogWrapper:{
      '& .MuiDialog-paper':{
        width: '100%',
      },
      '& #dialog-title':{
        borderBottom:'1px solid #dbdbdb',
        '& h2':{
          fontSize:15,
          color:'#3a3a3a',
          fontWeight:'bold'
        }
      }
    },
    dialogContent:{
      // overflowY: 'hidden',
      padding: 0,
      "& .scrollBar":{
        minHeight: 365,
      },
    },
    dialogBtn:{
      borderTop: '1px solid #dbdbdb',
    },

  }));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;